'use strict';

angular.module('gmpApp')
  /**
   * 2017-12-11 ally
   * 그룹상품 추가, 수정
   * */
  .controller('onlineGroupProdCtrl', function ($scope, $q, $uibModalInstance, commonSVC, workSVC, errorSVC, data, groupProdList, $timeout) {
    let sendData = []; // 작업에 등록할 데이터
    let originData = {};

    $scope.mode = data.mode;
    $scope.allCheck = { prod: false, group_prod: false };
    $scope.searchWord = '', $scope.searchLabel = '전체'; $scope.multiSearchWord = '';
    $scope.groupInfo = {};
    $scope.siteInfo = { shop_name: '', shop_cd: '', shop_id: '' };

    //search 검색키
    $scope.bodySearchKey = { prod: [{ value: ['shop_sale_name', 'shop_sale_no', 'c_sale_cd'], label: '전체' }, { value: ['shop_sale_name'], label: '쇼핑몰 상품명' }, { value: ['shop_sale_no'], label: '상품코드' }, { value: ['c_sale_cd'], label: '판매자 관리 코드' }],
      group: [{ value: ['group_cd', 'group_name'], label: '전체' }, { value: ['group_cd'], label: '그룹코드' }, { value: ['group_name'], label: '그룹명' }] };

    // 레퍼런스가 달라 active가 동작하지 않아 수정
    $scope.searchKey = $scope.mode === 'add' ? $scope.bodySearchKey.group[0].value : $scope.bodySearchKey.prod[0].value; //검색 값

    // 멀티검색 키
    $scope.multiSearchKey = [
      { value: 'c_sale_cd', label: '판매자 관리 코드' },
      { value: 'shop_sale_no', label: '상품코드' }
    ];

    // 정렬 키
    $scope.sortKey = [
      { value: 'default', label: '정렬' },
      { value: 'shop_sale_no', label: '쇼핑몰 상품코드' },
      { value: 'c_sale_cd', label: '판매자 관리 코드' },
      { value: 'shop_sale_name', label: '상품명' },
      { value: 'sale_status', label: '상품상태' },
      { value: 'shop_cate_cd', label: '카테고리' },
      { value: 'sale_price', label: '판매가' },

    ];
    //table body
    $scope.bodyList = { prod: [], group: [], group_prod: [] };
    $scope.bodyRowCss = { prod: [], group: [], group_prod: [] };

    init();

    // 배송유형 상세보기 클릭시
    $scope.deliDetail = function() {
      const tData = $scope.groupInfo.group_deli.split(',');

      const resolve = {};

      resolve.data = {
        template_no: tData[0],
        template_sub_type: tData[1],
        shop_cd: tData[2],
        shop_id: tData[3],
        shop_name: tData[4],
        pa_shop_cd: tData[5],
        tab: 2,
        isEdit: false
      };
      // view 페이지 주소가 통합으로 변경되어 수정함 2018-06-18 rony
      // commonSVC.openModal('full', resolve, 'setForm'+resolve.data.shop_cd+'TemplateCtrl', 'views/online/setForm/' + resolve.data.shop_cd + '.html');
      commonSVC.openModal('full', resolve, 'setFormTemplateCtrl', 'views/online/setForm/setform_detail.html');
    };

    // 검색키 선택
    $scope.selectSearchKey = function(value, label) {
      $scope.searchLabel = label;
      $scope.searchKey = value;
    };

    // 정렬키 선택
    $scope.selectSortKey = (value, label) => {
      if (value != $scope.sortValue) {
        $scope.sortLabel = label;
        $scope.sortValue = value;

        $scope.searchDo('prod');
      }
    };

    // 멀티검색키 선택
    $scope.selectMultiSearchKey = (value, label) => {
      $scope.multiSearchValue = value;
      $scope.multiSearchLabel = label;
    };

    //검색
    $scope.searchDo = function(loca) {
      const filter = _.filter(originData[loca], function(data) {
        if ($scope.bodyList.group_prod.length > 0) {
          if (!$scope.bodyList.group_prod.every(({ ol_shop_no }) => ol_shop_no !== data.ol_shop_no)) {
            return false;
          }
        }
        const re = [];

        _.each($scope.searchKey, function(k) {
          const tmp = data[k] ? data[k].toString().indexOf($scope.searchWord) > -1 : false;

          // 멀티검색시에 타는 로직
          // 기본 검색어로 우선 탐색 + 멀티검색어로 두번째 탐색
          if (tmp && $scope.multiSearchActive) {
            _.each($scope.multiSearchWord.split('\n'), (sWord) => {
              _.each($scope.searchKey, function(k) {
                let tmp = false;

                if (k === $scope.multiSearchValue) {
                  tmp = data[k] ? data[k].toString().indexOf(sWord) > -1 : false;
                }

                re.push(tmp);
              });
            });
          } else {
            re.push(tmp);
          }
        });

        return re.indexOf(true) > -1;
      });

      // 정렬로직
      if ($scope.sortValue !== 'default') {
        $scope.bodyList[loca] = _.sortBy(filter, [(a) => a[$scope.sortValue]]);
      } else {
        $scope.bodyList[loca] = filter;
      }

      if (loca === 'group') {
        $scope.groupInfo = {};
        $scope.bodyList.group_prod = [];
      }

      initRow();
    };

    //초기화
    $scope.resetDo = function(loca) {
      $scope.searchWord = '';
      $scope.multiSearchWord = '';
      $scope.searchLabel = '전체';
      $scope.searchKey = $scope.mode === 'add' ? $scope.bodySearchKey.group[0].value : $scope.bodySearchKey.prod[0].value; //검색 값

      $scope.bodyList = angular.copy(originData);
      if (loca === 'group') {
        $scope.groupInfo = {};
        $scope.bodyList.group_prod = [];
      }
      if (loca === 'prod') {
        $scope.bodyList.group_prod = $scope.groupInfo.list;
        $scope.searchDo('prod');
      }

      initRow();
    };

    // row 클릭시
    $scope.checked = function(loca, index) {
      if (!_.isUndefined($scope.bodyList[loca][index].cb)) {
        $scope.bodyList[loca][index].cb = !$scope.bodyList[loca][index].cb;
        $scope.bodyRowCss[loca][index] = $scope.bodyList[loca][index].cb;
      } else {
        _.each($scope.bodyRowCss[loca], function (v, index) {
          $scope.bodyRowCss[loca][index] = false;
        });
        $scope.bodyRowCss[loca][index] = true;
      }

      $scope.allCheck[loca] = _.filter($scope.bodyRowCss[loca]).length == $scope.bodyRowCss[loca].length;
      //추가인 경우 그룹 및 상품 데이터 변경해줘야함
      if ($scope.mode === 'add' && loca === 'group') {
        $scope.groupInfo = $scope.bodyList[loca][index];
        $scope.bodyList.group_prod = $scope.groupInfo.list;
        _.each($scope.bodyList.group_prod, function(prod, index) {
          //체크박스 insert
          prod.cb = false;
          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
          //row style
          $scope.bodyRowCss.group_prod[index] = false;
        });
      }
    };

    // 전체 선택시
    $scope.check_all = function(loca) {
      if (!_.isUndefined($scope.bodyList[loca][0].cb)) {
        _.each($scope.bodyList[loca], function(tr, index) {
          tr.cb = $scope.allCheck[loca];
          $scope.bodyRowCss[loca][index] = $scope.allCheck[loca];
        });
      }
    };

    //순서 이동시
    $scope.change_seq = function(calcul, position) {
      const checkedList = _.filter($scope.bodyList.group_prod, 'cb');
      const checkedIdx = [];

      _.each(checkedList, function(prod) {
        const index = _.findIndex($scope.bodyList.group_prod, { ol_shop_no: prod.ol_shop_no });

        if (index > -1) {
          checkedIdx.push(index);
        }
      });

      let ori = angular.copy($scope.bodyList.group_prod);

      if (checkedIdx.indexOf(0) > -1 && calcul === 'up' || checkedIdx.indexOf(ori.length - 1) > -1 && calcul === 'down') {
        return false;
      }

      checkedIdx.sort();
      if (calcul === 'down' || position === 'end') {
        checkedIdx.reverse();
      }
      if (position !== 'end') {
        _.each(checkedIdx, function(i) {
          const sign = calcul === 'up' ? i - 1 : i + 1;
          const tmp = ori[sign];

          ori[sign] = ori[i];
          ori[i] = tmp;
          $scope.bodyRowCss.group_prod[i] = false;
          $scope.bodyRowCss.group_prod[sign] = true;
        });
      } else {
        const tmpProdList = [];

        _.each(checkedIdx, function(i, idx) {
          const tmp = ori.splice(i, 1);

          tmpProdList.push(tmp[0]);

          const cssIdx = calcul === 'up' ? idx : $scope.bodyList.group_prod.length - idx - 1;

          $scope.bodyRowCss.group_prod[i] = false;
          $scope.bodyRowCss.group_prod[cssIdx] = true;
        });
        tmpProdList.reverse();
        if (calcul === 'up') {
          ori = _.concat(tmpProdList, ori);
        } else {
          ori = _.concat(ori, tmpProdList);
        }

        ori = _.flatten(ori);
      }

      $scope.bodyList.group_prod = ori;

      // 그룹상품 생성시에도 순서바꿔서 전송가능하게 작업함 - 2018-08-28 Alvin
      if ($scope.mode !== 'add') {
        $scope.groupInfo.list = $scope.bodyList.group_prod;
      }
      makeWorkSendData();

      //수정시에는 순서만 바꾸고 저장 가능
      // 2018-08-28 Alvin - 위에 그룹상품 생성시에도 순서바꿔서 전송가능하게 해서 주석처리함
      // if($scope.mode === 'edit'){
      //   $scope.groupInfo.list = $scope.bodyList.group_prod;
      //   makeWorkSendData();
      // }
    };

    //선택한 그룹에 추가
    $scope.addGroup = function() {
      let select_prod = _.filter($scope.bodyList.prod, 'cb');

      if ($scope.mode == 'add') {
        select_prod = angular.copy($scope.bodyList.prod);
      }

      if (!select_prod.length) {
        commonSVC.showMessage('', '그룹에 추가할 상품을 선택해 주세요.');

        return false;
      }

      if (!Object.keys($scope.groupInfo).length) {
        commonSVC.showMessage('', '추가할 그룹을 선택해 주세요.');

        return false;
      }

      // if((select_prod.length + $scope.groupInfo.list.length) < 2 && $scope.groupInfo.group_cd === ''){
      //   commonSVC.showMessage("","신규그룹 생성시 추가할 상품의 개수는 최소 2개 입니다.");
      //   return false;
      // }

      // if(!$scope.groupInfo.group_name){
      //   commonSVC.showMessage("","그룹명을 입력해주세요.");
      //   return false;
      // }

      if ($scope.mode === 'add') {
        let tmp;

        if ($scope.groupInfo.group_cd === '') {
          tmp = _.concat($scope.groupInfo.list, select_prod);
        } else {
          tmp = _.concat(select_prod, $scope.groupInfo.list);
        }

        $scope.bodyList.group_prod = tmp;
        initRow(false);
      } else {
        if ($scope.groupInfo.group_cd === '') {
          $scope.groupInfo.list = _.concat($scope.groupInfo.list, select_prod);
        } else {
          $scope.groupInfo.list = _.concat(select_prod, $scope.groupInfo.list);
        }

        $scope.bodyList.group_prod = $scope.groupInfo.list;
        $scope.bodyList.prod = _.differenceBy($scope.bodyList.prod, select_prod, 'ol_shop_no');
        initRow();
      }

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
      makeWorkSendData();
    };

    $scope.addInclude = () => $scope.bodyList.group_prod.some(({ ol_shop_no }) => ol_shop_no === $scope.bodyList.prod[0].ol_shop_no);

    //해당상품 그룹에서 제외
    $scope.delGroup = function() {
      const select_prod = _.filter($scope.bodyList.group_prod, 'cb');

      if (!select_prod.length) {
        commonSVC.showMessage('', '그룹에서 삭제할 상품을 선택해 주세요.');

        return false;
      }

      // if(($scope.groupInfo.list.length - select_prod.length) < 2){
      //   commonSVC.showMessage("","그룹 상품의 개수는 최소 2개 입니다.");
      //   return false;
      // }

      const txt = `${select_prod.length} 개의 상품을 그룹에서 삭제하시겠습니까?.`;

      commonSVC.showConfirm('상품 삭제', txt, function () {
        $scope.bodyList.group_prod = _.differenceBy($scope.bodyList.group_prod, select_prod, 'ol_shop_no');
        $scope.bodyList.prod = _.concat($scope.bodyList.prod, select_prod);
        if ($scope.mode !== 'add') {
          $scope.groupInfo.list = $scope.bodyList.group_prod;
          initRow();
        } else {
          initRow(false);
        }

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
        makeWorkSendData();
      });
    };

    /**
     * 전송
     */
    $scope.send = function() {
      const filter = _.filter(sendData, function(group_list) {
        return group_list.length <= 1;
      });

      if (filter.length) {
        commonSVC.showMessage('', '그룹상품의 개수는 최소 2개 입니다.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      if (Object.keys($scope.groupInfo).length === 0) {
        commonSVC.showMessage('', '그룹을 선택해 주세요.');
        $timeout(function() { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      if (!$scope.groupInfo.group_name) {
        commonSVC.showMessage('', '그룹명을 입력해주세요.');
        $timeout(function() { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      if ($scope.mode === 'add' && !$scope.addInclude()) {
        commonSVC.showMessage('', '상품을 추가해주세요.');
        $timeout(function() { $scope.waitBtnDisableFlag = false; });

        return false;
      }

      if ($scope.mode === 'create') {
        commonSVC.showConfirm('전송하시겠습니까 ?', '설정한 그룹상품을 쇼핑몰에 전송합니다. 생성된 그룹 리스트는 작업이 완료된 후 새로고침하여 확인하시기 바랍니다.', function (re) {
          if (re) {
            addWork();
          } else {
            $timeout(function() { $scope.waitBtnDisableFlag = false; });
          }
        });
      } else {
        const target_group = $scope.mode === 'add' ? $scope.bodyList.group_prod : $scope.groupInfo.list;
        const ori_group = _.map(originData.group_prod, 'ol_shop_no'), new_group = _.map(target_group, 'ol_shop_no');

        // 2018-08-16 Alvin - ori_group 과 new_group의 순서를 비교하는게 없어서 _.isEqual 추가함
        if ($scope.groupInfo.group_name !== originData.group_prod[0].group_name || $scope.groupInfo.group_img !== originData.group_prod[0].group_img ||
          _.difference(ori_group, new_group).length || _.difference(new_group, ori_group).length || !_.isEqual(ori_group, new_group)) {
          commonSVC.showConfirm('전송하시겠습니까 ?', '설정한 그룹상품을 쇼핑몰에 전송합니다. 생성된 그룹 리스트는 작업이 완료된 후 새로고침하여 확인하시기 바랍니다.', function (re) {
            if (re) {
              const tmp = _.map(target_group, function (prod, idx) {
                return {
                  state: '', //엔진 데이터 담는 변수
                  msg: '', //엔진 데이터 담는 변수
                  group_cd: $scope.groupInfo.group_cd,         // 신규생성이면 '' , 수정이면 원래 그룹코드
                  group_no: $scope.groupInfo.group_no,         // 신규생성이면 null , 수정이면 원래 그룹넘버
                  group_name: $scope.groupInfo.group_name,     //그룹이름
                  group_img: $scope.groupInfo.group_img,       //그룹보기방법 (img :이미지형 , list :리스트형 )
                  // std_ol_group_seq: prod.std_ol_group_seq,
                  std_ol_group_seq: idx,                        // 그룹내 상품 순번 ( 순번을 DB 값으로 넣으면 신규 추가되는 값은 0 으로 들어가므로 index 로 잡는다. 2018-05-24 rony)
                  shop_cate_cd: prod.shop_cate_cd,              //싱품카테고리코드
                  ol_shop_no: prod.ol_shop_no,                  //싱품로그번호
                  sale_no: prod.sale_no,                        //상품번호
                  shop_sale_no: (prod.shop_cd === 'A112' && prod.std_ol_yn === 1 && (prod.shop_sale_no_sub === 'null' || prod.shop_sale_no_sub === null || prod.shop_sale_no_sub === '' || prod.shop_sale_no_sub === undefined)) ? prod.shop_sale_no : prod.shop_sale_no_sub, //쇼핑몰상품번호(서브)
                  slave_reg_code: prod.shop_sale_no,
                  slave_reg_code_sub: prod.shop_sale_no_sub
                };
              });

              sendData = [tmp];
              addWork();
            } else {
              $timeout(function() { $scope.waitBtnDisableFlag = false; });
            }
          });
        } else {
          commonSVC.showMessage('', '수정사항이 없습니다.');
          $timeout(function() { $scope.waitBtnDisableFlag = false; });

          return false;
        }
      }
    };

    /**
     * 취소
     */
    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.multiSearchClick = () => {
      $scope.multiSearchActive = !$scope.multiSearchActive;
    };

    function init() {
      //그룹상품 추가시 오른쪽 탭 전환용 변수
      $scope.tabActive = 1;
      //그룹상품 생성시 왼쪽 탭 멀티검색 열었다 닫았다 하는 변수, 멀티서치 기본 라벨
      $scope.multiSearchActive = false;
      $scope.multiSearchLabel = '판매자 관리 코드';
      $scope.multiSearchValue = 'c_sale_cd';
      // 그룹에 추가될 수 있는 상품 정렬
      $scope.sortLabel = '정렬';
      $scope.sortValue = 'default';

      //bodyList 가공
      if ($scope.mode === 'create') {
        //선택한 기준상품을 추가 할 수 있는 그룹
        $scope.bodyList.group_prod = groupProdList.data.result.group_list[0].list || [];
        $scope.groupInfo = groupProdList.data.result.group_list[0];

        //선택한 기준상품과 같이 그룹을 만들 수 있는 상품들
        $scope.bodyList.prod = [];
        _.each(groupProdList.data.result.prod_list, function(prod, index) {
          //체크박스 insert
          prod.cb = false;
          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
          if (prod.ol_shop_no === data.selectData.ol_shop_no) {
            $scope.groupInfo.list.push(prod);
            $scope.bodyList.group_prod = $scope.groupInfo.list;
            if ($scope.bodyList.group_prod.length) {
              $scope.siteInfo = { shop_name: $scope.bodyList.group_prod[0].shop_name, shop_cd: $scope.bodyList.group_prod[0].shop_cd, shop_id: $scope.bodyList.group_prod[0].shop_id };
            }
          } else {
            //row style
            $scope.bodyRowCss.prod[index] = false;
            $scope.bodyList.prod.push(prod);
          }
        });
        makeWorkSendData();
      } else if ($scope.mode === 'edit') {

        //선택한 기준상품을 추가 할 수 있는 그룹
        $scope.bodyList.group_prod = groupProdList.data.result.group_list[0].list || [];
        _.each($scope.bodyList.group_prod, function(prod, index) {
          if (index === 0) {
            $scope.siteInfo = { shop_name: prod.shop_name, shop_cd: prod.shop_cd, shop_id: prod.shop_id };
          }
          //체크박스 insert
          prod.cb = false;
          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
          //row style
          $scope.bodyRowCss.group_prod[index] = false;
        });

        //선택한 기준상품과 같이 그룹을 만들 수 있는 상품들
        $scope.bodyList.prod = _(groupProdList.data.result.prod_list || []).filter(({ shop_sale_no }) => !$scope.bodyList.group_prod.some(({ shop_sale_no: s }) => s == shop_sale_no)).value();

        _.each($scope.bodyList.prod, function(prod, index) {
          //체크박스 insert
          prod.cb = false;
          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
          //row style
          $scope.bodyRowCss.prod[index] = false;
        });
        $scope.groupInfo = groupProdList.data.result.group_list[0];
      } else if ($scope.mode === 'add') {
        // 그룹에 추가할 상품
        // 2020-02-20 Boris
        // 그룹상품 추가는 추가할 상품이 1개만 존재하기 때문에 0번째 값을 직접 넣도록 처리
        $scope.bodyList.prod = data.selectData;

        $scope.allCheck.prod = true;
        $scope.siteInfo = { shop_name: $scope.bodyList.prod[0].shop_name, shop_cd: $scope.bodyList.prod[0].shop_cd, shop_id: $scope.bodyList.prod[0].shop_id };

        //이미지 insert
        $scope.bodyList.prod[0].img = $scope.bodyList.prod[0].sale_img_url ? $scope.bodyList.prod[0].sale_img_url + $scope.bodyList.prod[0].sale_img1 : '/assets/images/noimage.png';

        //선택한 기준 상품을 추가 할 수 있는 그룹
        $scope.bodyList.group = groupProdList.data.result.group_list || [];
        _.each($scope.bodyList.group, function(group, index) {
          //row style
          $scope.bodyRowCss.group[index] = index === 0;
          if (index === 0) {
            //선택한 그룹 (init시 첫번째가 자동 선택)
            $scope.groupInfo = group;
            $scope.bodyList.group_prod = group.list;
            _.each($scope.bodyList.group_prod, function(prod, index) {
              //체크박스 insert
              prod.cb = false;
              //이미지 insert
              prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
              //row style
              $scope.bodyRowCss.group_prod[index] = false;
            });
          }
        });
      }

      originData = angular.copy($scope.bodyList);
    }

    //row cb, select 초기화
    function initRow(noGroup = true) {
      _.each($scope.bodyList.prod, function(r) {
        r.cb = false;
      });
      _.each($scope.bodyList.group_prod, function(r) {
        r.cb = false;
      });
      if (noGroup) {
        _.each($scope.bodyRowCss.group, function(r, i) {
          $scope.bodyRowCss.group[i] = false;
        });
      }
      _.each($scope.bodyRowCss.prod, function(r, i) {
        $scope.bodyRowCss.prod[i] = false;
      });
      _.each($scope.bodyRowCss.group_prod, function(r, i) {
        $scope.bodyRowCss.group_prod[i] = false;
      });
      $scope.allCheck = { prod: false, group_prod: false };
    }

    function makeWorkSendData() {
      const groupList = $scope.groupInfo.list;
      const workData = [];

      _.each(groupList, function(prod, idx) {
        const tmp = {
          state: '', //엔진 데이터 담는 변수
          msg: '', //엔진 데이터 담는 변수
          group_cd: $scope.groupInfo.group_cd,  // 신규생성이면 '' , 수정이면 원래 그룹코드
          group_no: $scope.groupInfo.group_no,  // 신규생성이면 null , 수정이면 원래 그룹넘버
          group_name: $scope.groupInfo.group_name, //그룹이름
          group_img: $scope.groupInfo.group_img, //그룹보기방법 (img :이미지형 , list :리스트형 )
          std_ol_group_seq: idx, // 그룹내 상품 순번
          shop_cate_cd: prod.shop_cate_cd, //싱품카테고리코드
          ol_shop_no: prod.ol_shop_no, //싱품로그용번호
          sale_no: prod.sale_no, //상품번호
          shop_sale_no: (prod.shop_cd === 'A112' && prod.std_ol_yn === 1 && (prod.shop_sale_no_sub === 'null' || prod.shop_sale_no_sub === null || prod.shop_sale_no_sub === '' || prod.shop_sale_no_sub === undefined)) ? prod.shop_sale_no : prod.shop_sale_no_sub, //쇼핑몰상품번호(서브)
          slave_reg_code: prod.shop_sale_no,
          slave_reg_code_sub: prod.shop_sale_no_sub
        };

        workData.push(tmp);
      });
      if ($scope.mode === 'add') {
        const sendGroup = _.filter(_.flatMap(sendData), function(g) {
          return g.group_cd !== $scope.groupInfo.group_cd;
        });

        sendData = [_.concat(sendGroup, workData)];
      } else {
        sendData = [workData];
      }
    }

    function addWork(isDel) {
      const reCnt = {
        success: 0,
        error: 0,
        errSite: [],
        errReason: []
      };

      const promiseArr = [];

      _.each(sendData, function(group) {
        const param = {
          site_code: $scope.siteInfo.shop_cd,
          site_id: $scope.siteInfo.shop_id,
          group_list: group,
          std_ol_yn: 1
        };

        _.each(group, function(g) {
          g.group_name = $scope.groupInfo.group_name; g.group_img = $scope.groupInfo.group_img;
        });
        const workType = !isDel ? group[0].group_cd ? 'EditGroupProd' : 'RegistGroupProd' : 'DeleteGroupProd';

        const anHttpPromise = workSVC.addWork(workType, param)
          .then(function(result) {
            if (result.status == 200) {
              reCnt.success++;
            } else {
              reCnt.error++;
              reCnt.errSite.push(`${$scope.siteInfo.shop_name}(${$scope.siteInfo.shop_id})`);
              reCnt.errReason.push(result.data.error || '');
            }

            return result;
          })
          .catch(function(err) {
            reCnt.error++;
            reCnt.errSite.push(`${$scope.siteInfo.shop_name}(${$scope.siteInfo.shop_id})`);
            reCnt.errReason.push('');

            return err;
          });

        promiseArr.push(anHttpPromise);
      });

      $q.all(promiseArr)
        .then(async re => {
          if (!reCnt.success) {
            throw re;
          } else {
            const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
            $scope.close();
          }
        })
        .catch(function(err) {
          const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
          commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
          $timeout(function() { $scope.waitBtnDisableFlag = false; });

          return err;
        });
    }
  })

  /**
   * 2018-03-12 ally
   * 그룹상품 동기화
   */
  .controller('onlineGroupProdSyncCtrl', function ($scope, $uibModalInstance, workSVC, data, $rootScope, SettingsService, siteInfo, commonSVC, $timeout) {
    $scope.total_count = data.data.result;
    $scope.selectedAll = true;

    $scope.mall = {};

    SettingsService.getShopInfo()  // 2018-10-30 Jacob 쇼핑몰 계정 추가후 바로 작업시 siteinfo 갱신이 되지않는 문제 수정
      .then(function (re) {        // https://playautogmpproject.atlassian.net/browse/GMPKR-5757
        siteInfo = re.data;
      });

    const saveMallType = 'groupProdSync';

    // 마지막 선택값 기억.2019-04-08 rony
    $scope.saveMalls = JSON.parse(localStorage.getItem(saveMallType));
    if (!$scope.saveMalls) {
      $scope.saveMalls = {
        use_yn: false,
        malls: []
      };
    }

    function init() {
      // 사용중인 쇼핑몰, 아이디 가져오기
      const channelList = angular.copy($rootScope.useChannelList({ site_action: 'SyncProdState' }));

      // STEP 1. 조회된 쇼핑몰 정보 담기.
      // 단일 상품지원 쇼핑몰만 리턴해줌
      $scope.mall = channelList.filter(mall => mall.std_ol_avail_yn === 1 && !['B719', 'B502', 'P020', 'A112', 'A092', 'A093', 'A077', 'X094'].includes(mall.pa_shop_cd));
      let mastchMalls;

      // STEP 2. 마지막 선택값 기억 사용시 체크되었던 사이트 선택.
      _.each($scope.mall, function (m) {
        mastchMalls = [];
        if ($scope.saveMalls && $scope.saveMalls.use_yn) {
          mastchMalls = _.filter($scope.saveMalls.malls, function(o) {
            return o.shop_cd === m.shop_cd && o.shop_id === m.shop_id;
          });
        }

        if (mastchMalls.length) {
          m.selected = mastchMalls[0].checked;
        } else {
          m.selected = true;
        }
      });

      // STEP 3. 전체사이트 여부 체크.
      const unSelectedOrder = _.filter($scope.mall, function(o) {
        return o.selected === false;
      });

      $scope.selectedAll = !unSelectedOrder.length;
    }
    init();

    $scope.checkboxClick = function(type) {
      if (type === 'all') {
        angular.forEach($scope.mall, function (mall) {
          mall.selected = $scope.selectedAll;
        });
      } else {
        if ($scope.selectedAll && !($scope.mall[type].selected)) {
          $scope.selectedAll = false;
        } else {
          let flag = true;

          for (const i in $scope.mall) {
            if (!$scope.mall[i].selected) {
              flag = false;

              return;
            }
          }
          $scope.selectedAll = flag;
        }
      }
    };

    $scope.ok = function () {

      const idx = _.findIndex($scope.mall, { selected: true });

      if (idx < 0) {
        commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

        $timeout(() => {
          $scope.waitBtnDisableFlag = false;
        });

        return false;
      }

      // 선택값을 담기위해 몰정보 초기화.
      $scope.saveMalls.malls = [];
      const selected_mall = [];

      angular.forEach($scope.mall, function(v) {
        if (v.selected == true) {
          selected_mall.push({
            site_id: v.shop_id,
            site_code: v.shop_cd,
            prod_list: [],
          });
        }

        $scope.saveMalls.malls.push({ shop_cd: v.shop_cd, shop_id: v.shop_id, checked: v.selected });
      });
      workSVC.addWorkSet('SYNC_GROUP_PROD', selected_mall);
      // 선택 사이트 정보 로컬스토리지 저장.

      localStorage.setItem(saveMallType, JSON.stringify($scope.saveMalls));
      $scope.saveMalls = JSON.parse(localStorage.getItem(saveMallType));

      $uibModalInstance.close();
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
