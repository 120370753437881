/**
 * 일반상품 추가 컨트롤러
 */
'use strict';

angular.module('gmpApp')
  .controller('GeneralProductAddCtrl', GeneralProductAddCtrl);

function GeneralProductAddCtrl($scope, $timeout, data, channelUseList, templateGroupList, $uibModalInstance, systemModel, templateModel, onlineProductModel, onlineProductSVC, commonSVC, errorSVC) {
  const master_prod = data.result.map((prod) => {
    return {
      ol_shop_no: prod.ol_shop_no,
      sale_no: prod.sale_no,
      opt_type: prod.opt_type,
      shop_id: prod.shop_id,
      shop_cd: prod.shop_cd,
      sol_cate_no: prod.sol_cate_no,
      // 일반 상품이므로 0
      add_single_prod: 0
    };
  });

  $scope.templateListModal = false;
  $scope.channelSelectAll = true;
  $scope.templateGroupList = templateGroupList.data.results || [];
  $scope.template_group_name = '';
  $scope.use_engines = channelUseList.filter(({ pa_shop_cd, std_ol_avail_yn }) => (['A112', 'A077'].includes(pa_shop_cd) || !std_ol_avail_yn)); // 사용중인 채널 리스트
  $scope.prodCount = master_prod.length;
  $scope.placeholder = '템플릿 그룹을 선택하세요. 그룹에 지정된 템플릿이 일괄 반영됩니다.';

  // 등록 쇼핑몰 선택시 해줘야하는 작업
  $scope.$watch('$viewContentLoaded', function () {
    $('.select-fixed-multiple').select2({
      minimumResultsForSearch: Infinity,
      width: '100%',
      placeholder: '쇼핑몰(계정)을 선택하세요.',
      closeOnSelect: false
    });

    // select2 placeholder 값 출력을 위해 스타일 수정
    $('input.select2-search__field').css('width', '970px');
    // 쇼핑몰 선택 시 액션
    $('.reg-channel-select').on('change.select2', function () {
      let va = $(this).val() || [],
          site_list = [];

      if (va[0] == 'all') {
        $scope.channelSelectAll = false;
        va = [];
        for (let i = 0; i < $scope.use_engines.length; i++) {
          va.push(i);
        }
        $(this).val(va).trigger('change');
        $(this).select2('close');

        return;
      }

      if (va[0] == 'del') {
        $scope.channelSelectAll = true;
        $scope.site_list = [];
        $('.reg-channel-select').val([]).trigger('change');
        $(this).select2('close');

        return;
      }

      _.forEach(va, function (v) {
        const idx = parseInt(v);
        const row = $scope.use_engines[idx];
        const siteIndex = _.findIndex($scope.site_list, { shop_cd: row.shop_cd, shop_id: row.shop_id });

        if (siteIndex < 0) {
          const image_limit = onlineProductSVC.sub_img_objects[row.pa_shop_cd];
          site_list.push({
            shop_cd: row.shop_cd,
            pa_shop_cd: row.pa_shop_cd,
            shop_id: row.shop_id,
            shop_name: row.shop_name,
            image_limit: image_limit ? image_limit.length : undefined
          });
        } else {
          site_list.push($scope.site_list[siteIndex]);
        }
      });

      $scope.site_list = site_list;
      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    });
  });

  /**
     * 온라인 템플릿 리스트 가져오기
     */
  $scope.onlineTemplate = function (index, openFlag) {
    const site = $scope.site_list[index];

    site.search = '';

    $scope.templateList = [];
    $scope.templateList2 = [];

    //템플릿 수정후 펼침상태 유지
    if (openFlag == undefined) {
      $scope.templateListModal = !$scope.templateListModal;
    }

    // 2017-08-29 chris 템플릿 조회 오픈시에만 템플릿 리스트 가져오게 수정
    if (!$scope.templateListModal) {
      return;
    }

    $scope.openTemplate = `${site.shop_cd},${site.shop_id}`;
    $scope.openTemplateIdx = index;

    //선택된 템플릿 매칭해주기위한 데이터
    $scope.shop_params = { shop_cd: site.shop_cd, shop_id: site.shop_id };

    const param = {
      shop_cd: site.shop_cd,
      shop_id: site.shop_id,
      bookmark: 'all'
    };

    templateModel.templateOnlineProduct(param)
      .then(function (res) {

        const allTemplate = [];
        const bookmarkGroup = _.groupBy(res.data.results, 'bookmark_yn');

        _.each(bookmarkGroup, function (list, yn) {
          if (parseInt(yn)) {
            $scope.templateList = list;
          }

          // 전체 템플릿 리스트 노출위해 값 담음 2018-07-09 rony
          _.forEach(list, function(v) {
            allTemplate.push(v);
          });
        });
        $scope.templateList2 = allTemplate;

        if (!$scope.templateList.length) {
          // 없을경우 전체탭으로이동
          $(`#category${index}-1`).removeClass('active');
          $(`#category${index}-2`).addClass('active');

          $(`#bookmarkTab${index}`).removeClass('active');
          $(`#allTab${index}`).addClass('active');
        }
      })
      .catch(function (err) {
        commonSVC.showToaster('error', '', err);
      });
  };

  $scope.templateListVisible = function(index) {
    const site = $scope.site_list[index];

    return $scope.templateListModal && $scope.openTemplate === `${site.shop_cd},${site.shop_id}`;
  };

  /**
   * 검색 필터
   */
  $scope.search = function(element) {
    if (!$scope.site_list[$scope.openTemplateIdx]?.search) {
      return true;
    }

    return !!JSON.stringify(element.template_no + element.template_name).match(new RegExp(`(${$scope.site_list[$scope.openTemplateIdx].search})`, 'i'));
  };

  /**
     * 템플릿 선택
     */
  $scope.templateNameAdd = function (site, data) {
    if (data) {
      site.template_name = data.template_name;
      site.template_no = data.template_no;
      $scope.templateListModal = false;
    }
  };

  /**
   *  템플릿 리스트 닫기
   */
  $scope.listModal = function() {
    $scope.templateListModal = false;
  };

  /**
   * 온라인 템플릿 상세모달
   */
  $scope.onlineTemplateDetail = function (template_no, siteCode, siteId, shopName) {
    onlineProductSVC.onlineTemplateDetail(template_no, siteCode, siteId, shopName, $scope.openTemplateIdx, $scope.onlineTemplate);
  };

  /**
   *  템플릿 등록으로 이동
   */
  $scope.template_add = function(pa_shop_cd, shop_cd, shop_id, shop_name) {
    onlineProductSVC.onlineTemplateDetail(0, { shop_cd, pa_shop_cd }, shop_id, shop_name, $scope.openTemplateIdx, $scope.onlineTemplate);
  },

  // 템플릿 그룹 관리 모달
  $scope.manageTemplateGroup = function () {
    let template_group_no;
    const resolve = {
      data: {},
      systemList: function () {
        return systemModel.load();
      }
    };
    const modal = commonSVC.openModal('lg', resolve, 'ManageTemplateGroupCtrl', 'views/online/product/modals/manage_template_group.html');

    modal.result
      .then(function (re) {
        template_group_no = re[0];

        return templateModel.listGroup();
      })
      .then(function (re) {
        $scope.templateGroupList = re.data.results;
        $scope.template_group_no = `${template_group_no}`;
      });
  },

  // 템플릿 그룹을 드롭다운에서 선택하면 템플릿 그룹에 저장되어있는 템플릿을 자동 설정해줌
  $scope.setTemplateGroup = async (template_group_no) => {
    if (template_group_no) {
      const results = await templateModel.loadGroup({ template_group_no });
      const templateGroup = results.data.results;

      _.forEach($scope.site_list, function (site) {
        $scope.templateNameAdd(site, _.find(templateGroup, { shop_cd: site.shop_cd, shop_id: site.shop_id }));
      });
    }
  };

  // 템플릿 그룹 번호가 바뀔 때 템플릿 설정 함수 호출
  $scope.$watch('template_group_no', $scope.setTemplateGroup);

  /**
   * 등록
   */
  $scope.onSubmit = async () => {
    if (!$scope.site_list?.length) {
      commonSVC.showMessage('', '쇼핑몰을 선택해주시기 바랍니다.');
      $timeout(function () { $scope.waitBtnDisableFlag = false; });

      return false;
    }

    try {
      const response = await onlineProductModel.addSlaveToMaster(master_prod, $scope.site_list);

      if (response.data.results === 'success') {
        if (response.data.prods.length) {
          commonSVC.showToaster('success', '성공', '쇼핑몰 상품이 추가되었습니다.');
          $uibModalInstance.close(data.isMaster ? data.result : 'refresh');
        } else {
          throw 'duplicated online product';
        }
      }
    } catch (err) {
      const errMsg = errorSVC.getError('prod', err.data ? err.data.messages[0] : err, '쇼핑몰 상품 추가에 실패하였습니다.');

      commonSVC.showToaster('error', '실패', errMsg);

      if (errMsg === '쇼핑몰 상품 등록 DB 한도 초과') {
        commonSVC.showMessage('LITE버전 쇼핑몰 상품 DB 한도 초과', '쇼핑몰 상품은 5,000개 이하로 관리해 주세요.\n초과되는 상품은 삭제 또는 버전 업그레이드 후 이용하실 수 있습니다.\n(업그레이드 시 더 이상 무료 버전은 이용할 수 없습니다.)');
      }
    } finally {
      $timeout(function () { $scope.waitBtnDisableFlag = false; });
    }
  };

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };
}
