'use strict';

angular.module('gmpApp')
  .controller('StockWarehouseAddCtrl', function ($uibModalInstance, data, $scope, $timeout, $state, commonModel, warehouseModel, shipmentModel, settings, warehouseSVC, commonSVC, shipmentSVC, userInfo, gettextCatalog, $rootScope, systemModel, errorSVC) {

    //------------------------
    // 변수 설정
    //------------------------
    $scope.setDefault = data.depotListLength == 0; // 2018-09-06 Jacob 모달열릴때 배송처리스트 수를 받아 0이면 등록될 배송처가 기본배송처로 등록
    $scope.charge_name_list = [];
    $scope.charge_name = '';
    $scope.charge_name_self = '';
    $scope.nation = userInfo.user.sol_country;
    $scope.name = '창고';
    $scope.view = 'views/stock/warehouse/add_modal_body.html';
    $scope.ebayEtcInfo = {};

    //------------------------
    // 이벤트 맵핑
    //------------------------
    /*
    $scope.$watch('$viewContentLoaded', function(event) {
      $('.select-fixed-multiple').select2({
        minimumResultsForSearch: Infinity,
        width: "100%",
        placeholder: "계정 선택",
        "language": {
          "noResults": function(){
            return "담당자 없음";
          }
        },
      });
      $('.reg-channel-select').change(function () {
        var va = $(this).val();
        $scope.warehouse.charge_no = va;

        $scope.$apply();
      });
    });
    */
    // $uibModalInstance.opened.then(function(){
    //   console.log("asdasdsa");
    //
    //   $('.select2-search__field').css("width","150px");
    //   // Fixed width. Multiple selects
    //   $('.select-fixed-multiple').select2({
    //     minimumResultsForSearch: Infinity,
    //     width: "100%",
    //     placeholder: "쇼핑몰(계정)선택"
    //   });
    //
    //
    //   $('.reg-channel-select').change(function () {
    //
    //     var va = $(this).val() || [];
    //     var site_list = [];
    //     _.each(va, function (v, k) {
    //
    //       var idx = parseInt(v);
    //       var row = $scope.use_engines[idx];
    //
    //     });
    //
    //     $scope.$apply();
    //
    //   });
    // });

    // $scope.$on('$viewContentLoaded', function(event) {
    //
    // });

    /**
     * 2018-06-26 Daniel
     * ESM Master ID 계정확인
     */
    $scope.esmLoginCheck = false;
    $scope.result = {};

    $scope.esmMasterLoginCheck = function() {
      $scope.esmLoginCheck = true;

      const id = $scope.warehouse.ebaydepot_id;
      const pw = $scope.warehouse.ebaydepot_pwd;

      if (!id || !pw) {
        $('#id,#pw').effect('shake');

        $scope.esmLoginCheck = false;
        $scope.result = {
          state: 'danger',
          msg: 'ID 또는 PW 를 입력해주십시오.',
          icon: 'times-circle'
        };

        return false;
      }

      warehouseModel.checkEsmMasterId(id, pw, 0, 'add')
        .then(function(res) {
          if (res.status !== 200) {
            let msg = '';

            switch (status) {
              case 5005:
                msg = '현재 ESMPLUS 자체 사유로 로그인이 불가능합니다.';
                break;

              default:
                msg = res.message;
                break;
            }

            $scope.result = {
              state: 'danger',
              msg,
              icon: 'times-circle'
            };
          } else {
            if (res.data.engine_result.has_error) {
              commonSVC.showMessage('확인 실패', res.data.engine_result.results[0].site_error);

              $scope.result = {
                state: 'danger',
                msg: '아이디와 비밀번호를 확인해 주십시오.',
                icon: 'times-circle'
              };
            } else {
              $scope.result = {
                state: 'success',
                msg: '사용 가능한 계정입니다',
                icon: 'check-circle'
              };

              $scope.ebayEtcInfo = res.data.engine_result;

            }
          }
        })
        .catch(function(err) {
          if (err) {
            commonSVC.showMessage('확인 실패', err.data.error || err.data.messages[0]);
          }
        })
        .finally(function () {
          $scope.esmLoginCheck = false;
        });
    };

    init();
    /**
     * 등록
     */
    $scope.onSubmit = function() {
      // 지급주기 치환위한 변수
      const depot_sett_period = {
        0: '7일',
        1: '15일',
        2: '1개월',
      };

      $scope.warehouse.depot_sett_period = depot_sett_period[$scope.warehouse.depot_sett_period];

      if ($scope.form1.$valid) {
        if ($scope.warehouse.ebaydepot_yn == 1 && $scope.result.state !== 'success') {
          commonSVC.showMessage('등록 실패', 'ESM Master ID 연동을 진행해주세요');

          return false;
        }

        $scope.warehouse.charge_flag = $scope.charge_name;
        $scope.warehouse.charge_name = $scope.charge_name === '-1' ? $scope.charge_name_self : $scope.charge_name;
        $scope.warehouse.setDefault = $scope.setDefault;  // 2018-09-06 Jacob setDefault 여부에 따라 기본배송처 등록 여부 결정

        if ($scope.warehouse.ebaydepot_yn == 1) {
          $scope.warehouse.etc = $scope.ebayEtcInfo;
        }

        if ($scope.warehouse.ebaydepot_yn === '') {
          $scope.warehouse.ebaydepot_yn = 0;
        }

        warehouseModel.insert($scope.warehouse, function (state, data) {
          if (data.results === 'success') {
            const result = {
              result: 'success',
            };

            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('배송처 등록에 성공하였습니다.'));
            if ($scope.setDefault) {
              result.warehouse_code = data.warehouse_code;
            }

            $uibModalInstance.close(result);
          } else {
            $scope.submitDisabled = false;
            commonSVC.showToaster('error', '실패', errorSVC.getError('depot', data.data?.message));
          }
        });
      }
    };

    /**
     * 초기 셋팅
     */
    function init() {
      $scope.warehouse = angular.copy(warehouseSVC.defaultVdata);
      $scope.location_list = warehouseSVC.location_list;  // 창고위치 리스트 (해외)
      $scope.warehouse_type = warehouseSVC.warehouse_type.filter(a => a);  // 물류서비스 리스트
      commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/stock/depot/user/list`, {}, function(state, data) {
        $scope.charge_name_list = data.results;
      });
      $scope.state_list = $scope.nation === 'JP' ? shipmentSVC.jpCityList : []; //도시리스트
      angular.forEach(data.userList, function(value) {
        if (value.auth_type == '배송처' && value.depot_no == null) {
          $scope.charge_name_list.push(value);
        }
      });
    }

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 모든 모달 닫기 명령이 들어온경우 처리.
     */
    $scope.$on('openedModalClose', function () {
      $uibModalInstance.dismiss('cancel');
    });

  });
