/**
 * Created by ally on 2017. 8. 28..
 */
'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentClaimListCtrl',
    function (
      // common
      $state, $rootScope, $scope, $compile, $timeout, $filter, gettextCatalog, userInfo,
      // info
      settings,
      // SVC
      commonSVC, shipmentSVC, onlineProductSVC, columnSVC, tagSVC,
      // Model
      shipmentModel, commonModel, columnModel, atalkModel,
      // List
      warehouseList, giftList, systemList, gridInfo, solCarrList, globalCarrList, countryList, tagList, memoTemplateList
    ) {
      $scope.deliveryList = $rootScope.deliveryInfoList;
      $scope.memoTemplateList = memoTemplateList.data.results || [];

      // 컬럼 설정 정보
      const menu_data = gridInfo.menu_data ? gridInfo.menu_data : false;

      $scope.loadDataTable = gridInfo;

      $scope.list_name = 'claim';
      let orderby; // 리스트 정렬기준 (주문서인쇄 정렬용)
      let bundle_group = {}; // 묶음 코드
      $scope.loadShopData = {
        domestic: false,
        global: false
      };

      const channelList = angular.copy($rootScope.use_channel_list);

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(channelList, function (chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      $scope.countList = { cancel_req: 0, cancel_com: 0, return_req: 0, return_pick: 0, return_com: 0, exchange_req: 0, exchange_pick: 0, reconfirm: 0, direct_exchange_com: 0, direct_exchange_req: 0 };
      $scope.selectCount = 'total';
      let orderList = {};
      let prodList = [];    // SKU상품정보
      let addProdList = []; // 추가구매옵션정보

      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.claim);
      $scope.selectedShopType = systemList.data.main_use_mode === '국내' ? 'domestic' : 'global';

      if (!$rootScope.user_profile.sol_stock) {
        $scope.excelFieldList = _.filter($scope.excelFieldList, function (f) {
          return ['건별출고수량', 'SKU코드', 'SKU상품명', '매입처', '바코드', '총출고수량'].indexOf(f.header) === -1;
        });
      }

      // 대시보드에서 넘어오는 경우 설정이 제대로 안되는 경우가 있음
      if ($rootScope.side_search.page === 'claim' && $rootScope.side_search.search) {
        $scope.selectedShopType = $rootScope.side_search.selectedShopType;
      }

      /* 검색 및 버튼 관련 변수 */
      const domesticSearchTemplate = {
        searchForm: {
          search_key: '',
          search_word: '',
          search_type: 'partial',
          shopType: '',
          date_type: 'ord_status_mdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          site_id: '',
          site_code: '',
          status: ['취소요청', '취소완료', '반품요청', '반품진행', '반품회수완료', '반품완료', '교환요청', '교환진행', '교환회수완료', '교환완료', '맞교환요청', '맞교환완료', '주문재확인', '삭제'],
          ord_status_stat: ['취소요청', '취소완료', '반품요청', '반품진행', '반품회수완료', '반품완료', '교환요청', '교환진행', '교환회수완료', '교환완료', '맞교환요청', '맞교환완료', '주문재확인', '삭제'],
          // status: ['취소요청', '취소완료', '반품요청', '반품회수완료', '반품완료', '교환요청', '교환완료', '주문재확인', '삭제'],
          // ord_status_stat: ['취소요청', '취소완료', '반품요청', '반품회수완료', '반품완료', '교환요청', '교환완료', '주문재확인', '삭제'],
          recent_month: 1,
          page: 'claim',
          delivery_vendor: '',        // 배송처
          // to_country_code: '',     // 수령자 국가
          gift_prod_use_yn: '',       // 규칙사은품 사용여부
          gift_prod_name: '',         // 규칙적용사은품
          delay_status: '',           // 배송지연
          multi_search_word: '',      // 멀티서치워드,
          multi_type: 'shop_sale_no', //멀티서치 타입
          // excel_down_yn: '',
          memo_yn: '', // 메모여부
          multi_shop_id: ''
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectCount: 0,       // 검색전체 건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('반송장번호'), value: 'return_invoice_no' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
            { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
            { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
            { label: gettextCatalog.getString('주소'), value: 'to_addr' },
            { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
            { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
            { label: gettextCatalog.getString('배송메시지'), value: 'ship_msg' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
            { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
            { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
            { label: gettextCatalog.getString('반품/교환접수요청일'), value: $rootScope.osse_sol ? 'claim_time' : 'return_wdate' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
            { label: gettextCatalog.getString('배송 희망일'), value: 'ship_hope_time' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'ship_plan_date' },
            { label: gettextCatalog.getString('주문 확인일'), value: 'ord_confirm_time' },
            { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
            { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' },
            { label: gettextCatalog.getString('송장 전송일'), value: 'invoice_send_time' },
            { label: gettextCatalog.getString('배송 완료일'), value: 'ship_com_time' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('운송장 번호'), value: 'invoice_no' },
            { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('사용자태그'), value: 'tag_no' }
          ],
          search_date_key_names: commonSVC.searchKeyNames[systemList.data.search_date] || '1MONTH'
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            filter: function(option) {
              // 글로벌 쇼핑몰 여부 확인
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X');
            },
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            filter: function(option) {
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            },
            add_class: 'select-search'
          },
          // {
          //   title: gettextCatalog.getString('주문 상태 선택'),
          //   search_name: 'status',
          //   item_list: [
          //     { key: '취소요청', name: '취소요청' },
          //     { key: '취소완료', name: '취소완료' },
          //     { key: '반품요청', name: '반품요청' },
          //     { key: '반품회수완료', name: '반품회수완료' },
          //     { key: '반품완료', name: '반품완료' },
          //     { key: '교환요청', name: '교환요청' },
          //     { key: '교환완료', name: '교환완료' },
          //     { key: '교환재발송', name: '교환재발송' },
          //     { key: '주문재확인', name: '주문재확인' }
          //   ],
          //   item_key: 'key',
          //   item_value: 'name',
          //   select_value: ''
          // },
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: '',
            add_class: 'select-search'

          },
          // {
          //   // 국가
          //   title: gettextCatalog.getString('국가 선택'),
          //   search_name: 'to_country_code',
          //   item_list: countryList.data || [],
          //   item_key: 'ctry_name',
          //   item_value: 'ctry_cd',
          //   select_value: ''
          // },
          {
            // 규칙사은품규칙 구분
            title: gettextCatalog.getString('규칙사은품 사용여부'),
            search_name: 'gift_prod_use_yn',
            item_list: [{ text: '전체', value: 'all' }, { text: '사용', value: '1' }, { text: '미사용', value: '0' }],
            item_key: 'text',
            item_value: 'value',
            select_value: $scope.searchForm ? $scope.searchForm.gift_prod_use_yn : ''
          },
          {
            // 규칙적용 사은품
            title: gettextCatalog.getString('규칙적용사은품 선택'),
            search_name: 'gift_prod_name',
            item_list: giftList.data.results || [],
            item_key: 'gift_rule_name',
            item_value: 'gift_no',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              if ($scope.searchForm.gift_prod_use_yn === 'all') {
                return true;
              } else {
                return option.use_yn === Number($scope.searchForm.gift_prod_use_yn) && $scope.searchForm.gift_prod_use_yn;
              }
            }
          },
          // {
          //   // 엑셀다운 O/X
          //   title: gettextCatalog.getString('엑셀다운 O/X'),
          //   search_name: 'excel_down_yn',
          //   item_list: [{ key: 1, value: '엑셀다운 O' }, { key: 0, value: '엑셀다운 X' }],
          //   item_key: 'value',
          //   item_value: 'key',
          //   select_value: ''
          // },
          {
            // 배송지연 여부
            title: gettextCatalog.getString('배송지연여부'),
            search_name: 'delay_status',
            item_list: [{ key: 1, value: '배송지연 O' }, { key: 0, value: '배송지연 X' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 메모여부
            title: gettextCatalog.getString('메모여부'),
            search_name: 'memo_yn',
            item_list: [{ key: 1, value: '메모 있음' }, { key: 0, value: '메모 없음' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 합포장여부
            title: gettextCatalog.getString('합포장 여부'),
            search_name: 'multi_bundle_yn',
            item_list: [{ key: 1, value: '합포장' }, { key: 0, value: '합포장 아님' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 사용자태그여부
            title: gettextCatalog.getString('사용자태그 여부'),
            search_name: 'tag_yn',
            item_list: [{ key: 1, value: '사용자태그 적용' }, { key: 0, value: '사용자태그 미적용' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(channelList).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: '',
            filter: function(option) {
              return $scope.selectedShopType === 'domestic' ? !option.pa_shop_cd?.startsWith('X') : option.pa_shop_cd?.startsWith('X') && !option.shop_cd.startsWith('X');
            }
          },
          {
            title: gettextCatalog.getString('사용자태그 선택'),
            search_name: 'multi_tag',
            item_list: tagList.data.results || [],
            item_key: 'tag_name',
            item_value: 'tag_no',
            search_word: ''
          }
        ]
      };

      const globalSearchTemplate = angular.copy(domesticSearchTemplate);

      // 글로벌 서치바의 경우 2.0과 다른부분이 있어 별도처리
      globalSearchTemplate.searchData.search_key_items = [
        { label: gettextCatalog.getString('전체'), value: '' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호1'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호2'), value: 'misc13' },
        { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
        { label: gettextCatalog.getString('패키지번호'), value: 'pa_bundle_no' },
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('상품명'), value: 'shop_sale_name' },
        { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name,attri' },
        { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
        { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
        { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
        { label: gettextCatalog.getString('주문자, 수령자 이메일'), value: 'order_email,to_email' },
        { label: gettextCatalog.getString('주문자, 수령자 주소'), value: 'to_addr1,to_addr2' },
        { label: gettextCatalog.getString('메모'), value: 'memo' },
        { label: gettextCatalog.getString('배송메세지'), value: 'ship_msg' },
        { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
      ];

      globalSearchTemplate.searchData.search_date_type = [
        { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
        { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
        { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
        { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
        { label: gettextCatalog.getString('출고 지시일'), value: 'out_order_time' },
        { label: gettextCatalog.getString('출고 완료일'), value: 'out_time' },
        { label: gettextCatalog.getString('송장 전송일'), value: 'invoice_send_time' },
        { label: gettextCatalog.getString('반품/교환접수요청일'), value: 'return_wdate' }
      ];

      globalSearchTemplate.searchData.search_multi_items = [
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
        { label: gettextCatalog.getString('운송장(트래킹)번호'), value: 'invoice_no' },
        { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' },
        { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
        { label: gettextCatalog.getString('배송처'), value: 'depot' },
        { label: gettextCatalog.getString('사용자태그'), value: 'tag_no' }
      ];

      globalSearchTemplate.searchDetail.splice(3, 0, ...[
        {
          title: gettextCatalog.getString('국내택배사 선택'),
          search_name: 'carr_no',
          item_list: solCarrList.data.results.length ? solCarrList.data.results : $scope.deliveryList,
          item_key: 'carr_name',
          item_value: 'carr_no',
          select_value: ''
        },
        {
          title: gettextCatalog.getString('해외택배사 선택'),
          search_name: 'global_carr_no',
          item_list: globalCarrList,
          item_key: 'carr_view_name',
          item_value: 'carr_no',
          select_value: '',
          filter: function (option) {
            return option.carr_no !== -1;
          },
        },
        {
          title: gettextCatalog.getString('송장 출력여부'),
          search_name: 'invoice_print_time_yn',
          item_list: [
            { name: '출력완료', code: 1 },
            { name: '출력대기', code: 0 },
          ],
          item_key: 'name',
          item_value: 'code',
          select_value: '',
        },
      ]);

      globalSearchTemplate.searchDetail = globalSearchTemplate.searchDetail.filter(item => !['gift_prod_use_yn', 'gift_prod_name', 'delay_status', 'ship_method'].includes(item.search_name));

      globalSearchTemplate.searchDetailMulti.push({
        title: gettextCatalog.getString('배송처 선택'),
        search_name: 'multi_deliver_vendor',
        item_list: warehouseList.data.result || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        search_word: ''
      });

      let domesticSearch = angular.copy(domesticSearchTemplate), globalSearch = angular.copy(globalSearchTemplate);

      function setSearch(search) {
        $scope.searchData = search.searchData;
        $scope.searchForm = search.searchForm;
        $scope.searchDetail = search.searchDetail;
        $scope.searchDetailMulti = search.searchDetailMulti;
        $scope.searchDetailMultiSelect = search.searchDetailMultiSelect;
      }

      function resetSearch() {
        const showCount = $scope.searchData?.showCount;
        let search;
        if ($scope.selectedShopType === 'global') {
          search = globalSearch = angular.copy(globalSearchTemplate);
        } else {
          search = domesticSearch = angular.copy(domesticSearchTemplate);
        }
        if (showCount) {
          search.searchData.showCount = showCount;
        }
      }

      // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
      if ($rootScope.user_profile.auth_type === '배송처') {
        const searchIndex = _.findIndex(domesticSearchTemplate.searchDetail, { search_name: 'delivery_vendor' });

        if (searchIndex > -1) {
          domesticSearchTemplate.searchDetail.splice(searchIndex, 1);
        }
      }

      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="icon-sync worksvc-spinner-orderSync order"></i><span> ${gettextCatalog.getString('주문 동기화')}`,
            small_label: '주문동기화',
            test_id: 'btn-order-async',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-12',
            perm_only: ['order.claim+write'],
            action: function() {
              $scope.orderSync();
            }
          }
        ],
        table_actions: [
          // {
          //   label: '상태변경',
          //   btn_type: 'dropdown',
          //   add_class: 'btn-primary',
          //   item_list: []
          // },
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            test_id: 'drop-work',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('주문복사'),
                test_id: 'btn-order-copy',
                perm_only: ['order.claim+write'],
                action: function () {
                  $scope.copyOrder();
                }
              }, {
                label: gettextCatalog.getString('교환재발송'),
                test_id: 'btn-send-change',
                perm_only: ['order.claim+write'],
                action: function () {
                  $scope.resendExchangeOrder();
                },
                ngIfFunc: function () {
                  // 오쎄의 경우에는 상태변경 OR 주문동기화 시 자동으로 사본생성해줘서 해당 버튼 미노출 처리
                  return ($scope.selectedShopType === 'global' || $rootScope.osse_sol) ? 'n' : 'y';
                }
              }, {
                label: gettextCatalog.getString('주문삭제'),
                test_id: 'btn-order-del',
                perm_only: ['order.claim+admin'],
                action: function () {
                  $scope.deleteOrder();
                }
              }, {
                label: gettextCatalog.getString('SMS 발송'),
                test_id: 'btn-send-sms',
                action: function () {
                  $scope.sendModal('sms');
                },
                perm_only: ['order.order+admin'],
                ngIfFunc: function () {
                  return $scope.selectedShopType === 'global' ? 'n' : 'y';
                }
              }, {
                label: gettextCatalog.getString('알림톡 발송'),
                action: function () {
                  $scope.altalkSend();
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              }, {
                label: gettextCatalog.getString('알림톡 그룹 회원 추가'),
                action: function () {
                  $scope.altalkGroupMemAdd();
                },
                ngIfFunc: () => {
                  return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
                }
              },
            ]
          }, {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            test_id: 'drop-excel',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
                menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
                action: function () {
                  $scope.downloadIntegratedExcel();
                },
                test_id: 'btn-excel-intergrate',
                perm_only: ['order.unstoring+write']
              }
            ]
          }
        ],
        table_actions_btn: [
          {
            label: gettextCatalog.getString('주문서인쇄'),
            test_id: 'btn-order-print',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.orderPrint();
            },
            perm_only: ['order.unstoring']
          },
          {
            label: gettextCatalog.getString('반품접수'),
            test_id: 'btn-return-delivery',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.claimInvoice('return');
            },
            ngIfFunc: () => ('return_req' === $scope.selectCount && $scope.selectedShopType === 'domestic') ? 'y' : 'n',
            perm_only: ['order.unstoring+write']
          },
          {
            label: gettextCatalog.getString('교환접수'),
            test_id: 'btn-exchange-delivery',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.claimInvoice('exchange');
            },
            ngIfFunc: () => ('exchange_req' === $scope.selectCount && $scope.selectedShopType === 'domestic') ? 'y' : 'n',
            perm_only: ['order.unstoring+write']
          },
          {
            label: gettextCatalog.getString('반품접수취소'),
            test_id: 'btn-return-delivery',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.cancelClaimInvoice('return');
            },
            ngIfFunc: () => ('return_proc' === $scope.selectCount && $scope.selectedShopType === 'domestic') ? 'y' : 'n',
            perm_only: ['order.unstoring+write']
          },
          {
            label: gettextCatalog.getString('교환접수취소'),
            test_id: 'btn-exchange-delivery',
            add_class: 'a-btn-blue',
            action: function () {
              $scope.cancelClaimInvoice('exchange');
            },
            ngIfFunc: () => ('exchange_proc' === $scope.selectCount && $scope.selectedShopType === 'domestic') ? 'y' : 'n',
            perm_only: ['order.unstoring+write']
          },
        ]
      };

      $scope.searchFn = {
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },
        getOrderList: function () {
          // 주문 요약정보 매칭 정보
          _.forEach(orderList, function (order) {
            if (prodList[order.uniq]) {
              order.sku_pack = prodList[order.uniq][0].sku_cd;
              order.prod_name_pack = prodList[order.uniq][0].prod_name;
            }
          });

          return orderList;
        }
      };

      $scope.customContext = [{
        isSubMenu: true,
        line: true,
        subMemuName: '상태 수동변경',
        contextList: [
          {
            label: gettextCatalog.getString('취소완료로 상태변경'),
            action: function() { $scope.userChangeStatus('취소완료'); },
            filter: function(row) {
              return ['주문재확인', '취소요청'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('반품회수완료로 상태변경'),
            action: function() { $scope.userChangeStatus('반품회수완료'); },
            filter: function(row) {
              return ['주문재확인', '반품요청', '반품진행'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('반품완료로 상태변경'),
            action: function() { $scope.userChangeStatus('반품완료'); },
            filter: function(row) {
              return ['주문재확인', '반품요청', '반품진행', '반품회수완료', '교환회수완료', '교환완료'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('교환요청으로 상태변경'),
            action: function() { $scope.userChangeStatus('교환요청'); },
            filter: function(row) {
              return ['주문재확인', '반품회수완료', '반품요청'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('교환회수완료로 상태변경'),
            action: function() { $scope.userChangeStatus('교환회수완료'); },
            filter: function(row) {
              return ['주문재확인', '교환요청', '교환진행', '맞교환요청'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('교환완료로 상태변경'),
            action: function() { $scope.userChangeStatus('교환완료'); },
            filter: function(row) {
              return ['주문재확인', '교환요청', '교환진행', '교환회수완료'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('맞교환요청으로 상태변경'),
            action: function() { $scope.userChangeStatus('맞교환요청'); },
            filter: function(row) {
              return ['주문재확인', '교환요청'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('맞교환완료로 상태변경'),
            action: function() { $scope.userChangeStatus('맞교환완료'); },
            filter: function(row) {
              return ['주문재확인', '맞교환요청'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('배송중으로 상태변경'),
            action: function() { $scope.userChangeStatus('배송중'); },
            filter: function(row) {
              return ['주문재확인', '출고완료'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('구매결정으로 상태변경'),
            action: function() { $scope.userChangeStatus('구매결정'); },
            filter: function(row) {
              return ['주문재확인', '출고완료', '배송중', '배송완료'].indexOf(row.ord_status) > -1;
            }
          }, {
            label: gettextCatalog.getString('판매완료로 상태변경'),
            action: function() {
              $scope.userChangeStatus('판매완료');
            },
            filter: function(row) {
              return ['주문재확인'].includes(row.ord_status);
            }
          }, {
            label: gettextCatalog.getString('취소요청으로 상태변경'),
            action: function() { $scope.userChangeStatus('취소요청'); },
            filter: function(row) {
              return ['주문재확인', '출고완료', '배송중'].includes(row.ord_status);
            }
          }, {
            label: gettextCatalog.getString('반품요청으로 상태변경'),
            action: function() { $scope.userChangeStatus('반품요청'); },
            filter: function(row) {
              return ['주문재확인', '출고완료', '배송중', '배송완료', '교환요청'].includes(row.ord_status);
            }
          }, {
            label: gettextCatalog.getString('출고보류로 상태변경'),
            action: function() { $scope.userChangeStatus('출고보류'); },
            filter: function(row) {
              return ['주문재확인'].includes(row.ord_status);
            }
          }, {
            label: gettextCatalog.getString('출고대기로 상태변경'),
            action: function() { $scope.userChangeStatus('출고대기'); },
            filter: function(row) {
              return ['주문재확인'].includes(row.ord_status);
            }
          }, {
            label: gettextCatalog.getString('운송장출력 상태변경'),
            action: function() { $scope.userChangeStatus('운송장출력'); },
            filter: function(row) {
              return ['주문재확인'].includes(row.ord_status);
            }
          }, {
            label: gettextCatalog.getString('신규주문으로 상태변경'),
            action: function() { $scope.userChangeStatus('신규주문'); },
            filter: function(row) {
              return ['주문재확인'].includes(row.ord_status);
            }
          },
          {
            label: '주문보류로 상태변경',
            menu_tooltip: '출고 지시 전 준비 지연 또는 즉시 출고가 불가한 주문들을 보류한 주문의 상태입니다.',
            action: function() { $scope.userChangeStatus('주문보류'); },
            filter: function(row) {
              return ['주문재확인'].includes(row.ord_status);
            }
          }
        ]
      }];

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      /**
     *  데이터 테이블
     */
      $scope.domestic = {};
      $scope.domestic.methods = {};
      $scope.domestic.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['ord_status_mdate'],
        notMovingColumns: [],
        notVisibleColumns: ['notice_msg', 'bundle_no', 'ord_time', 'pay_time', 'ord_confirm_time', 'out_time', 'ship_com_time', 'depot_name', 'order_tel', 'order_htel', 'to_tel', 'to_htel', 'c_sale_cd', 'sku_cd', 'stock_cd', 'prod_name', 'pack_unit', 'total_cnt', 'ord_curr_cd', 'ship_method', 'shop_cost_price', 'shop_supply_price', 'last_exceldown_time', 'shop_ord_no_real', 'tag_pack', 'prod_name,attri', 'ship_hope_time'],
        addColGrid: !$rootScope.user_profile.pa_sol_no, // LG 대리점 계정은 컬럼추가 작업 불가능
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
          requestWillAction: function(data) {
            $scope.searchForm.shopType = 'domestic';

            if (userInfo.user.auth_type === '배송처' && !userInfo.user.depot_no) {
              commonSVC.showMessage('담당 배송처가 없습니다.');
            }

            if ($rootScope.order_search.page === 'claim' && $rootScope.order_search.search) {
              $scope.searchForm.search_word = $rootScope.order_search.input;
              $scope.searchForm.search_key = $rootScope.order_search.key;
              $scope.searchData.search_key_name = $rootScope.order_search.name;
              $rootScope.order_search.search = false;
            }

            if ($rootScope.side_search.page === 'claim' && $rootScope.side_search.search) {
              $scope.searchForm.date_type = $rootScope.side_search.date_type;
              $scope.searchForm.sdate = $rootScope.side_search.sdate;
              $scope.searchForm.edate = $rootScope.side_search.edate;
              $scope.searchForm.status = $rootScope.side_search.status;
              $scope.selectCount = $rootScope.side_search.selectCount;
              $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
              $scope.delay_status = $rootScope.side_search.delay_status;
              $rootScope.side_search.search = false;
            }

            data = angular.merge({}, data, $scope.searchForm);

            // 엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            // 주문서인쇄용 정렬.
            orderby = data.orderby;

            return data;
          },
          requestDidAction: function(result) {
            // SKU상품정보 처리
            prodList = {};
            addProdList = {};
            result.results_prod.forEach(function(subRow) {
              if (subRow.add_opt_yn == 1) {
                if (!addProdList[subRow.uniq]) { addProdList[subRow.uniq] = []; }

                addProdList[subRow.uniq].push(subRow);

              } else {
                if (!prodList[subRow.uniq]) { prodList[subRow.uniq] = []; }

                prodList[subRow.uniq].push(subRow);
              }
            });

            // 처음 데이터 load 시 활성화된 탭보다 나중에 응답 올 경우 값이 비정상 적으로 들어가는 경우가 존재하여 interval 다른 탭 데이터 로드 후 처리
            const timer = setInterval(async () => {
              if ($scope.selectedShopType !== 'domestic' || $scope.loadShopData.global) {
                $scope.searchData.totalCount = result.recordsTotal;
                $scope.domesticTotalCount = result.recordsTotalCount;
                orderList = result.results;

                columnModel.setOld(_.cloneDeep(result.results), 'claim');

                init(result.statusCount);

                columnSVC.addColIntSet('all', result.results);

                // 합포장 분리시 필요
                bundle_group = _.groupBy(result.results, 'bundle_no');

                $scope.loadShopData.domestic = true;
                clearInterval(timer);
                $timeout(() => {});
              }
            }, 100);

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 100,
            template: function(row) {
              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}')" >상세</button>
                <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>`;
            }
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 190,
            filter: 'isNullHyphen'
          },
          {
            key: 'shop_ord_no_real',
            title: '원주문번호',
            width: 190,
            filter: 'isNullHyphen'
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 140,
            tooltip: '스마트스토어 그룹상품인 경우 그룹 상품번호가 노출됩니다',
            template: function(row) {
              if (row.shop_cd === 'A011') {
                row.shop_sale_no = row.misc16;
              }

              let sale_no = row.shop_sale_no || '';

              if (row.shop_cd === 'B378') { // 쿠팡
                sale_no = row.misc5;
                if (row.misc9) {
                  sale_no += `?vendorItemId=${row.misc9}`;
                }
              } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
                sale_no += `?sitmNo=${row.misc17}`;
              } else if (row.pa_shop_cd === 'A077' && row.misc9) {
                sale_no = row.misc9;
              }

              if (sale_no && !onlineProductSVC.impossibeDetailView.some(shopCd => row.shop_cd.includes(shopCd))) {
                return (
                  `<span>
                  <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                  <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${
                  row.shop_cd
                  }','${
                    row.shop_id
                  }','${
                    sale_no
                  }')"></i></span>`
                );
              } else {
                return row.shop_sale_no;
              }
            }
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 300,
            notCompile: true,
            template: function(row) {
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);
              //이미지+상품명
              if (row.sale_img) {
                const img = row.sale_img || '/assets/images/upload.png';

                return (
                  `<img src='${
                    img
                  }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                    filteredShopSaleName
                  }</span>`
                );
              } else {
                return filteredShopSaleName;
              }
            }
          },
          {
            key: 'shop_opt_name',
            title: '옵션',
            width: 220,
            template: function(row) {
              return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
            }
          },
          {
            key: 'sale_cnt',
            title: '주문수량',
            width: 60,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) >= 0 && !$rootScope.osse_sol
                ? 0
                : row.sale_cnt;
            }
          },
          {
            key: 'shop_add_opt_name',
            title: '추가구매옵션',
            width: 150,
            notCompile: true,
            template: function(row) {
              // "추가구매옵션1=2;추가구매옵션2=3"
              let return_value = '';
              const retVals = [];

              if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

                _(addProdList[row.uniq])
                  .groupBy('ord_opt_seq')
                  .map(arr => {
                    retVals.push(
                      `${arr[0].ord_opt_name}-${['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(row.ord_status) > -1 && !$rootScope.osse_sol ? '0' : arr[0].opt_sale_cnt}개`
                    );
                  })
                  .value();

                return_value = retVals.join(', ');
              }

              return return_value;
            }
          },
          {
            key: 'order_name',
            title: '주문자(ID)',
            width: 150,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.order_name;

              if (row.order_id) {
                return_value += `(${row.order_id})`;
              }

              return return_value;
            }
          },
          {
            key: 'to_name',
            title: '수령자명',
            width: 100
          },
          {
            key: 'ord_status_msg',
            title: '클레임 사유',
            width: 300
          },
          {
            key: 'ship_msg',
            title: '배송메세지',
            width: 200
          },
          {
            key: 'notice_msg',
            title: '기타메세지',
            width: 200
          },
          {
            key: 'to_addr1',
            title: '주소',
            width: 300,
            notCompile: true,
            template: function(row) {
              //속성
              let return_value = row.to_addr1;

              if (return_value && return_value.charAt(0) != '[' && row.to_zipcd) {
                return_value = `[${row.to_zipcd}]${return_value}`;
              }
              if (row.to_addr2) {
                return_value = `${return_value} ${row.to_addr2}`;
              }

              return return_value;
            }
          },
          {
            key: 'carr_name',
            title: '택배사',
            width: 120
          },
          {
            key: 'invoice_no',
            title: '운송장번호',
            width: 150,
            template: function(row) {
              const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
              const carr_no = row.carr_no;

              if (
                carr_no &&
                invoice_no &&
                $rootScope.possibeTrackingView.includes(carr_no)
              ) {
                return (
                  `<span>${
                    invoice_no
                  }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                    carr_no
                  }','${
                    invoice_no.replace(/-/gi, '')
                  }')"></i></span>`
                );
              } else {
                return row.invoice_no;
              }
            }
          },
          {
            key: 'invoice_send_time',
            title: '송장 전송일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'return_invoice_no',
            title: '반송장번호',
            width: 150,
            template: function(row) {
              const invoice_no = row.return_invoice_no;
              const carr_no = row.return_carr_no;

              if (
                carr_no &&
                invoice_no &&
                $rootScope.possibeTrackingView.includes(carr_no)
              ) {
                return (
                  `<span>${
                    invoice_no
                  }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                    carr_no
                  }','${
                    invoice_no.replace(/-/gi, '')
                  }')"></i></span>`
                );
              } else {
                return row.return_invoice_no;
              }
            }
          },
          {
            key: 'return_wdate',
            title: '반품/교환접수요청일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'sales',
            title: '금액',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.sales, '', 0);
            }
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) >= 0 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.ship_cost, '', 0);
            }
          },
          {
            key: 'ord_bundle_gift_terms_prod',
            title: '규칙적용사은품',
            tooltip: '솔루션에서 설정한 규칙이 적용된 사은품입니다.\n묶음 주문은 적용된 규칙 사은품이 각 주문 별로 모두 출력됩니다.',
            name: 'bundle_code',
            width: 100,
            template: function (row) {
              // 사은품명1#SKU코드1#재고코드1#속성1#개수1#사은품명1(sku명X)#사은품규칙명1#바코드1|사은품명2#SKU코드2#재고코드2#속성2#개수2#사은품명2(sku명X)#사은품규칙명2#바코드2
              let gift_prod_name = '';

              if (row.gift_pack) {
                const regex = /([^|]*?(?:[^#]*#){7}[^#]*?)(?=\|)/g;

                // 사은품명에 특수문자 '|' 포함한 경우가 있어서 #가 7번 반복된 이후 나오는 '|' 기준으로 사은품 쪼개기
                const giftList = [];
                let match;
                let prevIdx = 0;
                let idx = 0;

                while ((match = regex.exec(row.gift_pack))) {
                  giftList.push(idx ? match[0].substring(1) : match[0]);
                  prevIdx = regex.lastIndex;
                  idx++;
                }

                giftList.push(prevIdx ? row.gift_pack.substring(prevIdx).substring(1) : row.gift_pack);

                gift_prod_name = giftList.map(gift => {
                  const vals = gift.split('#'); // [0]: 사은품명, [1]: SKU코드, [4]: 사은품출고개수

                  return `${vals[0]}-${vals[4]}개`;
                }).join(', ');
              }

              return gift_prod_name;
            }
          },
          {
            key: 'gift_name',
            title: '사은품',
            width: 100
          },
          {
            key: 'ship_delay_yn',
            title: '배송지연여부',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.ship_delay_yn
                ? '<i class="icon-primitive-dot text-success-600"></i>'
                : '<i class="icon-primitive-dot text-grey-100"></i>';
            }
          },
          {
            key: 'multi_bundle_yn',
            title: '묶음주문여부',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.multi_bundle_yn
                ? '<i class="icon-primitive-dot text-success-600"></i>'
                : '<i class="icon-primitive-dot text-grey-100"></i>';
            }
          },
          {
            key: 'ship_plan_date',
            title: '발송예정일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'bundle_no',
            title: '묶음번호',
            width: 170
          },
          {
            key: 'ord_time',
            title: '주문일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_status_mdate',
            title: '상태변경일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_confirm_time',
            title: '주문확인일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'out_time',
            title: '출고완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ship_com_time',
            title: '배송완료일',
            tooltip: `[주문 동기화] 기능 또는 직접 상태를 변경하여 주문의 상태가 “배송완료”로 변경된 일시입니다.
실제로 배송이 완료된 시점과 다를 수 있으니 참고용으로 활용해 주세요.`,
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'gprivate_no',
            title: '개인통관번호',
            width: 150
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 150
          },
          {
            key: 'order_tel',
            title: '주문자 전화번호',
            width: 150
          },
          {
            key: 'order_htel',
            title: '주문자 휴대폰번호',
            width: 150
          },
          {
            key: 'to_tel',
            title: '수령자 전화번호',
            width: 150
          },
          {
            key: 'to_htel',
            title: '수령자 휴대폰번호',
            width: 150
          },
          {
            key: 'tag_pack',
            title: '사용자태그',
            tooltip: '설정한 조건에 맞게 적용한 사용자태그입니다.',
            width: 130,
            template: function(row) {
              // 각 행의 color 속성에 해당하는 컬러 코드를 찾아서 이름에 해당 컬러를 적용
              return row.tag_pack ? $rootScope.getColorName(row.tag_pack).join(', ') : '';
            }
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 150
          },
          {
            key: 'sku_cd',
            title: 'SKU코드',
            requireStock: true,
            width: 150,
            template: function(row) {
              if (row.set_no && prodList[row.uniq]) {
                return `<button class="btn btn-xxs btn-success" ng-click="grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq].length > 1 ?
                  `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                  : prodList[row.uniq][0].sku_cd;
              } else {
                return '미매칭 출고';
              }
            }
          },
          {
            key: 'stock_cd',
            title: '재고관리코드',
            tooltip: '세트상품의 경우 대표로 지정된 SKU의 재고관리코드만 항목에 노출됩니다.',
            requireStock: true,
            width: 150,
            template: (row) => {
              if (row.set_no && prodList[row.uniq]) {
                const findList = prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

                return findList ? findList.stock_cd : '';
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq][0].stock_cd;
              } else {
                return '';
              }
            }
          },
          {
            key: 'prod_name',
            title: 'SKU상품명',
            requireStock: true,
            width: 130,
            notCompile: true,
            template: function(row) {
              if (row.set_no) {
                return row.set_name;
              } else {
                if (prodList[row.uniq]) {
                  const prod_name = (prodList[row.uniq][0].prod_name || '');

                  return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
                }

                return '';
              }
            }
          },
          {
            key: 'prod_name,attri',
            title: 'SKU상품명_속성',
            requireStock: true,
            width: 130,
            notCompile: true,
            template: function(row) {
              if (row.set_no) {
                return row.set_name;
              } else {
                if (prodList[row.uniq]) {
                  const prod_name = (prodList[row.uniq][0].prod_name || '') + (prodList[row.uniq][0].attri ? (`_${prodList[row.uniq][0].attri.split('_').join()}`) : '');

                  return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
                }

                return '';
              }
            }
          },
          {
            key: 'pack_unit',
            title: '건별출고수량',
            tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
            requireStock: true,
            width: 110,
            notCompile: true,
            template: row => ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : (row.pack_unit || 0)
          },
          {
            key: 'total_cnt',
            title: '총 출고수량',
            tooltip: '건별출고수량 X 주문수량\n*추가옵션제외',
            width: 100,
            template: (row) => {
              if (['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol) {
                return 0;
              } else {
                return row.out_cnt;
              }
            }
          },
          {
            key: 'ord_curr_cd',
            title: '결제통화',
            width: 100
          },
          {
            key: 'ship_method',
            title: '배송방법',
            width: 120
          },
          {
            key: 'shop_cost_price',
            title: '원가',
            tooltip: $rootScope.userProfileCheck('sol_stock', 1) ? '주문에 매칭된 SKU상품 혹은 온라인상품에 입력된 원가 정보입니다. (입력된 원가*출고수량*주문수량)' : '해당 온라인상품에 입력된 원가 정보입니다. (입력된 원가*주문수량)',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.shop_cost_price, '', 0);
            }
          },
          {
            key: 'shop_supply_price',
            title: '공급가',
            width: 100,
            notCompile: true,
            template: function(row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.shop_supply_price, '', 0);
            }
          },
          {
            key: 'last_exceldown_time',
            title: '엑셀 다운일시',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ship_hope_time',
            title: '희망배송일',
            width: 130,
            filter: 'dateValid'
          },
        ],
        initShowCount: function(showCount) {
          setTimeout(() => {
            domesticSearch.searchData.showCount = showCount;
          }, 100);
          if ($scope.selectedShopType !== 'domestic') {
            setTimeout(() => {
              $scope.searchData.showCount = globalSearch.searchData.showCount;
            }, 200);
          }
        }
      };

      $scope.global = angular.copy($scope.domestic);
      $scope.global.options.notVisibleColumns = ['shop_add_opt_name', 'notice_msg', 'ord_time', 'pay_time', 'ord_status_mdate', 'gprivate_no', 'order_tel', 'order_htel', 'to_tel', 'to_email', 'stock_cd', 'prod_name', 'attri', 'pack_unit', 'total_cnt', 'ship_method', 'shop_supply_price', 'last_exceldown_time', 'order_msg', 'bundle_avail_yn', 'supp_name', 'misc10', 'misc14', 'misc15', 'barcode', 'out_time', 'madein', 'pa_bundle_no', 'weight', 'order_email', 'tag_pack', 'prod_name,attri'];
      $scope.global.options.externalRequestOptions = {
        requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
        requestWillAction: function(data) {
          $scope.searchForm.shopType = 'global';

          if (userInfo.user.auth_type === '배송처' && !userInfo.user.depot_no) {
            commonSVC.showMessage('담당 배송처가 없습니다.');
          }

          if ($rootScope.order_search.page === 'claim' && $rootScope.order_search.search) {
            $scope.searchForm.search_word = $rootScope.order_search.input;
            $scope.searchForm.search_key = $rootScope.order_search.key;
            $scope.searchData.search_key_name = $rootScope.order_search.name;
            $rootScope.order_search.search = false;
          }

          if ($rootScope.side_search.page === 'claim' && $rootScope.side_search.search) {
            $scope.searchForm.date_type = $rootScope.side_search.date_type;
            $scope.searchForm.sdate = $rootScope.side_search.sdate;
            $scope.searchForm.edate = $rootScope.side_search.edate;
            $scope.searchForm.status = $rootScope.side_search.status;
            $scope.selectCount = $rootScope.side_search.selectCount;
            $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
            $scope.delay_status = $rootScope.side_search.delay_status;
            $rootScope.side_search.search = false;
          }

          data = angular.merge({}, data, $scope.searchForm);

          // 엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          // 주문서인쇄용 정렬.
          orderby = data.orderby;

          return data;
        },
        requestDidAction: function(result) {
          // SKU상품정보 처리
          prodList = {};
          addProdList = {};
          result.results_prod.forEach(function(subRow) {
            if (subRow.add_opt_yn == 1) {
              if (!addProdList[subRow.uniq]) { addProdList[subRow.uniq] = []; }

              addProdList[subRow.uniq].push(subRow);

            } else {
              if (!prodList[subRow.uniq]) { prodList[subRow.uniq] = []; }

              prodList[subRow.uniq].push(subRow);
            }
          });

          // 처음 데이터 load 시 활성화된 탭보다 나중에 응답 올 경우 값이 비정상 적으로 들어가는 경우가 존재하여 interval 다른 탭 데이터 로드 후 처리
          const timer = setInterval(async () => {
            if ($scope.selectedShopType !== 'global' || $scope.loadShopData.domestic) {
              $scope.searchData.totalCount = result.recordsTotal;
              $scope.globalTotalCount = result.recordsTotalCount;
              orderList = result.results;

              columnModel.setOld(_.cloneDeep(result.results), 'claim');

              init(result.statusCount);

              columnSVC.addColIntSet('all', result.results);

              // 합포장 분리시 필요
              bundle_group = _.groupBy(result.results, 'bundle_no');

              $scope.loadShopData.global = true;
              clearInterval(timer);
              $timeout(() => {});
            }
          }, 100);

          return result.results;
        }
      };

      $scope.global.options.columns = [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}', true)" >상세</button>
              <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>`;
          }
        },
        {
          key: 'ord_status',
          title: '주문상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            return $filter('statusColor')(row.ord_status);
          }
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '직접입력';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                ${shop_info[1]}
              </span>`;
            }

            return img;
          }
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호1',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'misc13',
          title: '쇼핑몰 주문번호2',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 140,
          template: function(row) {
            if (row.shop_cd === 'A011') {
              row.shop_sale_no = row.misc16;
            }

            let sale_no = row.shop_sale_no || '';

            if (row.shop_cd === 'B378') { // 쿠팡
              sale_no = row.misc5;
              if (row.misc9) {
                sale_no += `?vendorItemId=${row.misc9}`;
              }
            } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
              sale_no += `?sitmNo=${row.misc17}`;
            }

            if (sale_no && !onlineProductSVC.impossibeDetailView.some(shopCd => row.shop_cd.includes(shopCd))) {
              return (
                `<span>
                <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${
                row.shop_cd
                }','${
                  row.shop_id
                }','${
                  sale_no
                }')"></i></span>`
              );
            } else {
              return row.shop_sale_no;
            }
          }
        },
        {
          key: 'shop_sale_name',
          title: '온라인 상품명',
          width: 300,
          notCompile: true,
          template: function(row) {
            //이미지+상품명
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);
            if (row.sale_img) {
              const img = row.sale_img || '/assets/images/upload.png';

              return (
                `<img src='${
                  img
                }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                  filteredShopSaleName
                }</span>`
              );
            } else {
              return filteredShopSaleName;
            }
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'misc17',
          title: '옵션코드',
          width: 200,
          filter: 'isNullHyphen'
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60,
          notCompile: true,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) >= 0 && !$rootScope.osse_sol
              ? 0
              : row.sale_cnt;
          }
        },
        {
          key: 'shop_add_opt_name',
          title: '추가구매옵션',
          width: 150,
          notCompile: true,
          template: function(row) {
            // "추가구매옵션1=2;추가구매옵션2=3"
            let return_value = '';
            const retVals = [];

            if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

              _(addProdList[row.uniq])
                .groupBy('ord_opt_seq')
                .map(arr => {
                  retVals.push(
                    `${arr[0].ord_opt_name}-${['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(row.ord_status) > -1 && !$rootScope.osse_sol ? '0' : arr[0].opt_sale_cnt}개`
                  );
                })
                .value();

              return_value = retVals.join(', ');
            }

            return return_value;
          }
        },
        {
          key: 'weight',
          title: '무게(g)',
          width: 80,
          template: function(row) {
            return row.misc16;
          }
        },
        {
          key: 'order_name',
          title: '주문자(ID)',
          width: 150,
          notCompile: true,
          template: function(row) {
            //속성
            let return_value = row.order_name;

            if (row.order_id) {
              return_value += `(${row.order_id})`;
            }

            return return_value;
          }
        },
        {
          key: 'to_name',
          title: '수령자명',
          width: 100
        },
        {
          key: 'ord_status_msg',
          title: '클레임 사유',
          width: 300
        },
        {
          key: 'ship_msg',
          title: '배송메세지',
          width: 200
        },
        {
          key: 'notice_msg',
          title: '기타메세지',
          width: 200
        },
        {
          key: 'to_addr1',
          title: '주소',
          width: 300,
          notCompile: true,
          template: function(row) {
            //속성
            let return_value = row.to_addr1;

            if (return_value && return_value.charAt(0) != '[' && row.to_zipcd) {
              return_value = `[${row.to_zipcd}]${return_value}`;
            }
            if (row.to_addr2) {
              return_value = `${return_value} ${row.to_addr2}`;
            }

            return return_value;
          }
        },
        {
          key: 'global_carr_name',
          title: '해외택배사',
          width: 140
        },
        {
          key: 'global_invoice_no',
          title: '트래킹번호',
          width: 140
        },
        {
          key: 'carr_name',
          title: '국내택배사',
          width: 120
        },
        {
          key: 'invoice_no',
          title: '국내운송장번호',
          width: 150,
          template: function(row) {
            const invoice_no = row.carr_no === 956 ? String(row.invoice_no).padStart(12, '0') : row.invoice_no || '';
            const carr_no = row.carr_no;

            if (
              carr_no &&
                invoice_no &&
                $rootScope.possibeTrackingView.includes(carr_no)
            ) {
              return (
                `<span>${
                  invoice_no
                }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                  carr_no
                }','${
                  invoice_no.replace(/-/gi, '')
                }')"></i></span>`
              );
            } else {
              return row.invoice_no;
            }
          }
        },
        {
          key: 'global_invoice_print_time',
          title: '송장 출력여부',
          width: 80,
          template: function(row) {
            return row.global_invoice_print_time ? '<span class="text-grey-300">출력완료</span>' : '<span class="text-success-600">출력대기</span>';
          }
        },
        {
          key: 'invoice_send_time',
          title: '송장 전송일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'return_wdate',
          title: '반품/교환접수요청일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'claim_refund_sales',
          title: '환불예상금액',
          width: 100,
          notCompile: true,
          template: function(row) {
            return $filter('currency')(row.claim_refund_sales, '', 0);
          }
        },
        {
          key: 'sales',
          title: '금액',
          width: 100,
          notCompile: true,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : Number(row.sales).toFixed(2);
          }
        },
        {
          key: 'ship_cost',
          title: '배송비',
          width: 100,
          notCompile: true,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) >= 0 && !$rootScope.osse_sol
              ? 0
              : Number(row.ship_cost).toFixed(2);
          }
        },
        {
          key: 'multi_bundle_yn',
          title: '묶음주문여부',
          width: 100,
          notCompile: true,
          template: function(row) {
            return row.multi_bundle_yn
              ? '<i class="icon-primitive-dot text-success-600"></i>'
              : '<i class="icon-primitive-dot text-grey-100"></i>';
          }
        },
        {
          key: 'misc15',
          title: '제조국(원산지)',
          width: 80
        },
        {
          key: 'misc14',
          title: 'HS코드',
          width: 80
        },
        {
          key: 'ship_plan_date',
          title: '발송예정일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'misc10',
          title: '발송기한',
          width: 130,
          filter: 'dateValid',
          template: function(row) {
            return moment(row.misc10).format('YYYY-MM-DD hh:mm:ss');
          }
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          width: 170
        },
        {
          key: 'ord_time',
          title: '주문일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'pay_time',
          title: '결제완료일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_status_mdate',
          title: '상태변경일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'ord_curr_cd',
          title: '기준통화',
          width: 50,
        },
        {
          key: 'out_time',
          title: '출고완료일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'gprivate_no',
          title: '개인통관번호',
          width: 150
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 150
        },
        {
          key: 'order_tel',
          title: '주문자 전화번호',
          width: 150
        },
        {
          key: 'order_htel',
          title: '주문자 휴대폰번호',
          width: 150
        },
        {
          key: 'order_email',
          title: '주문자 이메일',
          width: 150
        },
        {
          key: 'to_tel',
          title: '수령자 전화번호',
          width: 150
        },
        {
          key: 'to_htel',
          title: '수령자 휴대폰번호',
          width: 150
        },
        // {
        //   key: 'tag_pack',
        //   title: '사용자태그',
        //   tooltip: '설정한 조건에 맞게 적용한 사용자태그입니다.',
        //   width: 130,
        //   template: function(row) {
        //     // 각 행의 color 속성에 해당하는 컬러 코드를 찾아서 이름에 해당 컬러를 적용
        //     return row.tag_pack ? $rootScope.getColorName(row.tag_pack).join(', ') : '';
        //   }
        // },
        {
          key: 'to_email',
          title: '수령자 이메일',
          width: 150
        },
        {
          key: 'to_ctry_cd',
          title: '수령자 국가',
          width: 50,
          template: function(row) {
            return countryList.find(country => country.ctry_cd === row.to_ctry_cd)?.ctry_name || '';
          }
        },
        {
          key: 'barcode',
          title: '바코드',
          width: 120
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          requireStock: true,
          width: 150,
          template: function(row) {
            if (row.set_no && prodList[row.uniq]) {
              return `<button class="btn btn-xxs btn-success" ng-click="grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
            } else if (prodList[row.uniq]) {
              return prodList[row.uniq].length > 1 ?
                `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                : prodList[row.uniq][0].sku_cd;
            } else {
              return '미매칭 출고';
            }
          }
        },
        {
          key: 'stock_cd',
          title: '재고관리코드',
          tooltip: '세트상품의 경우 대표로 지정된 SKU의 재고관리코드만 항목에 노출됩니다.',
          requireStock: true,
          width: 150,
          template: (row) => {
            if (row.set_no && prodList[row.uniq]) {
              const findList = prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

              return findList ? findList.stock_cd : '';
            } else if (prodList[row.uniq]) {
              return prodList[row.uniq][0].stock_cd;
            } else {
              return '';
            }
          }
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          requireStock: true,
          width: 130,
          notCompile: true,
          template: function(row) {
            if (row.set_no) {
              return row.set_name;
            } else {
              if (prodList[row.uniq]) {
                const prod_name = (prodList[row.uniq][0].prod_name || '');

                return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
              }

              return '';
            }
          }
        },
        {
          key: 'prod_name,attri',
          title: 'SKU상품명_속성',
          requireStock: true,
          width: 130,
          notCompile: true,
          template: function(row) {
            if (row.set_no) {
              return row.set_name;
            } else {
              if (prodList[row.uniq]) {
                const prod_name = (prodList[row.uniq][0].prod_name || '') + (prodList[row.uniq][0].attri ? (`_${prodList[row.uniq][0].attri.split('_').join()}`) : '');

                return prodList[row.uniq].length > 1 ? prod_name + ` 외 ${prodList[row.uniq].length - 1}건` : prod_name;
              }

              return '';
            }
          }
        },
        {
          key: 'pack_unit',
          title: '건별출고수량',
          tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
          requireStock: true,
          width: 110,
          notCompile: true,
          template: row => ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(row.ord_status) >= 0 ? 0 : (row.pack_unit || 0)
        },
        {
          key: 'total_cnt',
          title: '총 출고수량',
          tooltip: '건별출고수량 X 주문수량\n*추가옵션제외',
          width: 100,
          template: (row) => {
            if (['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol) {
              return 0;
            } else {
              return row.out_cnt;
            }
          }
        },
        {
          key: 'ship_method',
          title: '배송방법',
          width: 120,
          template: function (row) {
            return row.misc12;
          }
        },
        {
          key: 'sales_price',
          title: '쇼핑몰 판매금액',
          tooltip: '할인금액 * 수량 (Voucher 등의 장바구니 할인 제외)',
          template: function(row) {
            return Number(row.sales_unit * row.sale_cnt).toFixed(2);
          },
          width: 120,
        },
        {
          key: 'sales_unit',
          title: '쇼핑몰 판매단가',
          tooltip: '개당 할인금액 (Voucher 등의 장바구니 할인 제외)',
          width: 120,
          template: function(row) {
            return Number(row.sales_unit).toFixed(2);
          }
        },
        {
          key: 'shop_supply_price',
          title: '공급가',
          width: 100,
          notCompile: true,
          template: function(row) {
            return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : Number(row.shop_supply_price).toFixed(2);
          }
        },
        {
          key: 'last_exceldown_time',
          title: '엑셀 다운일시',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'bundle_avail_yn',
          title: '합포장 가능여부',
          width: 100,
          template: function (row) {
            return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
          }
        },
        {
          key: 'order_msg',
          title: '추가메세지',
          width: 200
        },
        {
          key: 'pa_bundle_no',
          title: '패키지번호',
          width: 130
        },
        {
          key: 'supp_name',
          title: '매입처',
          width: 130,
          notCompile: true,
          template: row => {
            return prodList[row.uniq] ? (prodList[row.uniq].map(prod => prod.supp_no ? prod.supp_name : '')).filter(item => !!item?.trim()) : '';
          }
        },
      ];

      $scope.global.options.initShowCount = function(showCount) {
        setTimeout(() => {
          globalSearch.searchData.showCount = showCount;
        }, 100);
        if ($scope.selectedShopType !== 'global') {
          setTimeout(() => {
            $scope.searchData.showCount = domesticSearch.searchData.showCount;
          }, 200);
        }
      };

      // 초기 탭 그리드 세팅
      $scope.grid = $scope[$scope.selectedShopType];

      if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $rootScope.user_profile.pa_sol_no) {
        addColumnSet();
      }
      resetSearch();
      setSearch($scope.selectedShopType === 'domestic' ? domesticSearch : globalSearch);

      // LG custom -> sub user 인경우 노출항목 추가.
      if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
        columnSVC.addColumns_LG($scope.grid.options);
      }

      async function addColumnSet() {
        const domesticParams = {
          gridOption: $scope.domestic.options,
          readOnly: false,
          addcolType: 'ord',
          search_key_items: domesticSearchTemplate.searchData.search_key_items
        };
        const globalParams = {
          gridOption: $scope.global.options,
          readOnly: false,
          addcolType: 'ord',
          search_key_items: globalSearchTemplate.searchData.search_key_items
        };

        // LG 대리점 사용계정인 경우 추가처리
        if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
          // 읽기만 가능한 컬럼으로 추가
          domesticParams.readOnly = true;
          globalParams.readOnly = true;
          // enum의 첫번째 값 디폴트로 사용
          domesticParams.useEnumFirstValDefault = true;
          globalParams.useEnumFirstValDefault = true;
        }

        columnSVC.columnsSet(domesticParams);
        columnSVC.columnsSet(globalParams);

        // LG 대리점 사용계정인 경우 커스텀컬럼 작업 불가능하게 처리
        if ($rootScope.affName !== 'LG전자' || !$rootScope.user_profile.pa_sol_no) {
          await columnSVC.saveBtnSet({
            grid: $scope.domestic,
            table_actions: $scope.searchBtn.table_actions,
            searchDo: function (refresh, noDelay, callback) {
              $scope.domestic.methods.reloadData(function () {
                if (callback) {
                  callback();
                }
              }, refresh, noDelay);
            },
            page: domesticSearchTemplate.searchForm.page,
            ngIfFunc: () => {
              return $scope.selectedShopType === 'domestic' ? 'y' : 'n';
            },
          });
          await columnSVC.saveBtnSet({
            grid: $scope.global,
            table_actions: $scope.searchBtn.table_actions,
            searchDo: function (refresh, noDelay, callback) {
              $scope.global.methods.reloadData(function () {
                if (callback) {
                  callback();
                }
              }, refresh, noDelay);
            },
            page: globalSearchTemplate.searchForm.page,
            ngIfFunc: () => {
              return $scope.selectedShopType === 'global' ? 'y' : 'n';
            },
          });
        }
      }

      /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
      $scope.$on('OnColumnChange', function(event, data) {
        _.each(data, function(row) {
          $compile(row)($scope);
        });
      });

      /**
     * 검색
     */
      $scope.searchDo = function (refresh, noDelay, callback) {
        if (typeof $scope.searchForm.status == 'string') {
          if ($scope.searchForm.status) {
            switch ($scope.searchForm.status) {
              case '취소요청':
                $scope.selectCount = 'cancel_req';
                break;
              case '취소완료':
                $scope.selectCount = 'cancel_com';
                break;
              case '반품요청':
                $scope.selectCount = 'return_req';
                break;
              case '반품진행':
                $scope.selectCount = 'return_proc';
                break;
              case '반품회수완료':
                $scope.selectCount = 'return_pick';
                break;
              case '반품완료':
                $scope.selectCount = 'return_com';
                break;
              case '교환요청':
                $scope.selectCount = 'exchange_req';
                break;
              case '교환진행':
                $scope.selectCount = 'exchange_proc';
                break;
              case '교환완료':
                $scope.selectCount = 'exchange_pick';
                break;
              case '맞교환요청':
                $scope.selectCount = 'direct_exchange_req';
                break;
              case '맞교환완료':
                $scope.selectCount = 'direct_exchange_com';
                break;
              case '주문재확인':
                $scope.selectCount = 'reconfirm';
                break;
              default:
                $scope.selectCount = 'total';
                break;
            }
            $scope.searchForm.status = [$scope.searchForm.status];
          } else {
            $scope.selectCount = 'total';
          }
        }
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
     * 검색 초기화
     */
      $scope.resetDo = function() {
        resetSearch();
        setSearch($scope.selectedShopType === 'domestic' ? domesticSearch : globalSearch);
        $scope.selectCount = 'total';
        $scope.searchDo(true, true);
      };

      /**
     * 데이터테이블 pageLength 조절
     */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
     * 상세페이지 보여주기
     */
      $scope.showDetail = function(uniq, isGlobal) {
        const resolve = {
          data: {
            fromPage: domesticSearchTemplate.searchForm.page,
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };
        let modal;
        if (isGlobal) {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailGlobalCtrl', 'views/order/shipment/detailGlobal.html');
        } else {
          modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');
        }
        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      // $scope.isDetailShow = show;
      // var selectRow = $scope.isDetailShow && rowIndex > -1 ? rowIndex : null; // 상세보기 누른 row index
      // $scope.grid.methods.ChangeColMode(show, [ 0, 2, 4, 7 ], selectRow);
      // if(show){
      //   $state.go("main.order_shipment_claim_list.detail", {fromPage: 'claim', rowIndex: rowIndex , uniq: uniq, ord_date: ord_date});
      //   $scope.grid.methods.autoSelect(true);
      // }else{
      //   $scope.grid.methods.autoSelect(false);
      // }
      };

      $scope.showSetDetail = function (setNo) {
        const resolve = {
          data: { from: '수정', set_no: setNo }
        };

        commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
      };

      $scope.showMultiSKUDetail = function (uniq) {
        const resolve = {
          data: { prodList: prodList[uniq] }
        };

        commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
      };

      // SMS, EMAIL 모달
      $scope.sendModal = async (type) => {
        let controller = '';

        switch (type) {
          case 'sms':
            controller = 'ConfigsSms';
            break;

          case 'email':
            controller = 'ConfigsEmail';
            break;
        }

        const rows = $scope.grid.methods.selectedData('all');
        const t = angular.copy($scope.pageData);

        delete t.start;
        t.length = 1000000;

        const totalRows = (await shipmentModel.listSms(t)).data.results;

        const resolve = {
          data: {
            rows: rows,
            totalRows: totalRows,
            totalCount: $scope.searchData.totalCount,
            searchForm: $scope.pageData
          }
        };

        commonSVC.openModal('lg', resolve, controller, `views/alimtalk/modals/${type}.html`);
      };

      // btn event..
      /**
     * 카운팅에 맞는 검색 결과
     */
      $scope.countSearch = function(type) {
      // if($scope.countList[type] !== 0) {
        // var sdate = $scope.searchForm.sdate, edate = $scope.searchForm.edate;
        // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
        // $scope.searchForm = angular.copy(search.searchForm);
        // $scope.searchDetail = angular.copy(search.searchDetail);
        // $scope.searchForm.sdate = sdate;
        // $scope.searchForm.edate = edate;

        switch (type) {
          case 'total':
            $scope.searchForm.status = ['취소요청', '취소완료', '반품요청', '반품진행', '반품회수완료', '반품완료', '교환요청', '교환진행', '교환회수완료', '교환완료', '주문재확인', '삭제'];
            // $scope.searchForm.status = ['취소요청', '취소완료', '반품요청', '반품회수완료', '반품완료', '교환요청', '교환완료', '맞교환요청', '맞교환완료', '주문재확인', '삭제'];
            break;
          case 'cancel_req':
            $scope.searchForm.status = ['취소요청'];
            break;
          case 'cancel_com':
            $scope.searchForm.status = ['취소완료'];
            break;
          case 'return_req':
            $scope.searchForm.status = ['반품요청'];
            break;
          case 'return_proc':
            $scope.searchForm.status = ['반품진행'];
            break;
          case 'return_pick':
            $scope.searchForm.status = ['반품회수완료'];
            break;
          case 'return_com':
            $scope.searchForm.status = ['반품완료'];
            break;
          case 'exchange_req':
            $scope.searchForm.status = ['교환요청'];
            break;
          case 'exchange_proc':
            $scope.searchForm.status = ['교환진행'];
            break;
          case 'exchange_pick':
            $scope.searchForm.status = ['교환회수완료'];
            break;
          case 'exchange_com':
            $scope.searchForm.status = ['교환완료'];
            break;
          case 'reconfirm':
            $scope.searchForm.status = ['주문재확인'];
            break;
          case 'direct_exchange_req':
            $scope.searchForm.status = ['맞교환요청'];
            break;
          case 'direct_exchange_com':
            $scope.searchForm.status = ['맞교환완료'];
            break;
        }
        $scope.searchDo(true, true);
        $scope.selectCount = type;
      // }
      };

      /**
     * 주문복사
     * */
      $scope.copyOrder = function() {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          selectList: selectList,
          selectedShopType: $scope.selectedShopType
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderCopy', 'views/order/shipment/modals/order_copy.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
            commonSVC.showMessage(gettextCatalog.getString("복사된 주문건은 '신규주문' 메뉴에서 확인 가능합니다"));
          }
        });
      };

      /**
       * 교환재발송
       * */
      $scope.resendExchangeOrder = function() {
        const selectList = $scope.grid.methods.selectedData('all');
        const isClaim = ['교환요청', '교환진행', '교환완료', '맞교환요청', '맞교환완료', '교환회수완료'];
        const invalidShopList = _.filter(channelList, function (c) {

          return c.copy_exchange_status === 0;
        });

        let isInvalid = false;

        if (selectList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        _.forEach(invalidShopList, function (c) {
          if (_.find(selectList, function (ord) { return ord.shop_cd === c.shop_cd; })) {
            isInvalid = true;

            return false;
          }
        });

        if (isInvalid) {
          commonSVC.showMessage('실패', `${'해당 쇼핑몰은 교환 요청 시 신규 주문을 생성해 주는 곳으로 교환재발송 주문 생성을 지원하지 않습니다.'
          + '주문 수집 작업으로 교환주문을 수집해 주시기 바랍니다. \n\n 미지원 쇼핑몰: '}${
            _.uniq(_.map(invalidShopList, 'shop_name')).join(', ')
          }은 교환재발송 기능을 지원하지 않습니다.`);

          return false;
        }

        if (_.filter(selectList, function (o) { return isClaim.indexOf(o.ord_status) === -1; }).length) {
          commonSVC.showMessage('실패', '교환요청, 교환진행, 교환완료, 맞교환요청, 맞교환완료 상태에 있는 주문건만 교환재발송 처리를 할 수 있습니다.');

          return false;
        }

        const params = {
          selectList: selectList,
          isResend: true,
          selectedShopType: $scope.selectedShopType
        };

        let modal;

        shipmentModel.checkHasCopy({ uniqList: _.map(selectList, 'uniq') })
          .then(function (res) {
            const re = res.data.results;

            if (re && re.length > 0) {
              commonSVC.showConfirm('이미 사본이 있는 주문건이 있습니다.', `\n 주문번호: ${_.map(re, 'shop_ord_no').join(', ')}`)
                .then(function (flag) {
                  if (flag) {
                    modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderCopy', 'views/order/shipment/modals/order_copy.html');

                    modal.result.then(function (re) {
                      if (re === 'success') {
                        $scope.searchDo(true);
                        commonSVC.showMessage(gettextCatalog.getString("복사된 주문건은 '신규주문' 메뉴에서 확인 가능합니다"));
                      }
                    });
                  } else {
                    return false;
                  }
                });
            } else {
              modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderCopy', 'views/order/shipment/modals/order_copy.html');

              modal.result.then(function (re) {
                if (re === 'success') {
                  $scope.searchDo(true);
                  commonSVC.showMessage(gettextCatalog.getString("복사된 주문건은 '신규주문' 메뉴에서 확인 가능합니다"));
                }
              });
            }
          });
      };

      /**
     * 주문삭제
     * */
      $scope.deleteOrder = function(uniq) {

        // 삭제권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.deleteOrder', userInfo.permission) === false) {
          return false;
        }

        let param = [];

        if (uniq) {
          param = [uniq];
        } else {
          param = $scope.grid.methods.selectedData('uniq');
        }

        if (param.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          uniqList: param,
          fromPage: 'claim'
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderDelete', 'views/order/shipment/modals/order_delete.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 통합 엑셀 다운로드
       */

      $scope.downloadIntegratedExcel = function () {
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }
        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const selectedData = $scope.grid.methods.selectedData('all');
        const resolve = {};

        resolve.data = {
          excelFieldList: $scope.excelFieldList,
          title: '클레임 관리 주문',
          url: '/app/order/excel/downIntegrated',
          searchForm: $scope.pageData,
          page: 'claim',
          visibleTable: visibleTable,
          isGlobal: $scope.selectedShopType === 'global'
        };

        if (!selectedData.length) {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
          resolve.data.type = 'all';
        } else {
          const uniq = $scope.grid.methods.selectedData('uniq');

          resolve.data.type = 'select';
          resolve.data.uniq = uniq;
          resolve.data.count = uniq.length;
          resolve.data.isAll = false;
        }

        commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
      };

      /**
       * 주문서 인쇄
       */
      $scope.orderPrint = function () {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return;
        }

        const selectBundle = _.uniqBy(selectList.map(o => o.bundle_no));
        const isBundle = !!selectBundle.find(bundle_no => bundle_group[bundle_no].length > 1);

        const data = {
          isBundle: isBundle,
          ordCount: selectBundle.length,
          orderby: orderby,
          uniqList: $scope.grid.methods.selectedData('uniq')
        };

        commonSVC.openModal('', { data: data }, 'OrderBundlePrintCtrl', 'views/order/shipment/modals/order_bundle_print.html');
      };

      /**
     * 주문 동기화
     */
      $scope.orderSync = function() {
      //  sync2: GMP (sync: EBAY)
        commonSVC.openModal('md', { data: { work_type: 'sync2' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
      };

      /**
     * 주문 상태 강제변경
     * */
      $scope.userChangeStatus = function (status) {
        let detailMessage = '';

        if (status === '취소완료') {
          detailMessage = 'SKU상품에 매칭된 주문은 [취소완료]로 변경 시 차감 되었던 재고 수량이 복구되며, 주문 동기화 시 쇼핑몰의 상태와 더이상 동기화 되지 않습니다.\n';
        } else if (status === '주문보류') {
          detailMessage = '출고 지시 전 준비 지연 또는 즉시 출고가 불가한 주문들을 보류한 주문의 상태입니다.\n';
        }

        //유니코드로 마지막 글자의 받침 여부 확인
        const isConsonant = (status.charCodeAt(status.length - 1) - 44032) % 28;

        commonSVC.showConfirm('상태를 변경하시겠습니까?', `${detailMessage}${status}${isConsonant ? '으로' : '로'} 상태를 강제로 변경 하시겠습니까?${status === '취소완료' ? '\n* [주문재확인] 상태로는 변경될 수 있습니다.' : ''}`, function (re) {
          if (re) {
            shipmentModel.setStatus({ status: status, uniqList: $scope.grid.methods.selectedData('uniq'), bundle_codes: $scope.grid.methods.selectedData('bundle_no') }, function (state, res) {
              if (state == 'success' && res === 'success') {
                commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 상태변경에 성공하였습니다'));
                $scope.searchDo(false);
              } else {
                const errorMessage = res.data?.error || '';
                const displayMessage = errorMessage.length > 200 ? `${errorMessage.substring(0, 200)}...` : errorMessage;

                commonSVC.showToaster('error', '실패', `주문 상태변경에 실패하였습니다.\n${displayMessage}`);
              }
            });
          }
        });
      };

      /**
       * 상품코드 클릭시 쇼핑몰 상품 관리로 이동
       */
      $scope.goOlProdList = (shop_sale_no) => {
        $rootScope.order_search.page = 'online';
        $rootScope.order_search = {
          page: 'online',
          search: true,
          search_key: 'shop_sale_no',
          search_word: shop_sale_no
        };

        $state.go('main.online_product_list');
        $rootScope.$broadcast('ordListSearch');
      };

      /**
     * 테이블에서 선택 변경 시
     */
      // $scope.$on('OnSelectChange', function(event, data) {
      //   if($scope.isDetailShow){
      //     $state.go("main.order_shipment_claim_list.detail", {fromPage: 'claim', rowIndex: data.selectIndex ,  uniq: data.selectData.uniq, number: data.selectData.number});
      //   }
      // });

      /**
     * 상세보기 닫기 눌렀을 시에
     */
      // $scope.$on('OnCloseDetail', function(event, data) {
      //   $scope.showDetail(null, false);
      //   // 2017-03-07 MatthewKim $state.go 옵션을 추가해서 파라미터 상속 false, 알림 false 로 이동하여 화면 이동을 시키게 함
      //   $state.go("main.order_shipment_claim_list", null, { inherit: false, notify: false } );
      // });
      /**
     * 주문 전체보기에서 주문코드 눌렀을 때
     */
      $scope.$on('integratedSearch_claim', function() {
        $scope.searchDo(true, true);
      });

      /**
     * 주문 전체보기에서 주문코드 눌렀을 때
     */
      $scope.$on('integratedSearch_claim', function() {
        $scope.searchDo();
      });

      /**
     * 사이드바 집계 클릭시
     */
      $scope.$on('sidebarSearch_claim', function() {
        let tabIdx = 1;
        $timeout(() => {
          if ($rootScope.side_search.selectedShopType === 'domestic') {
            tabIdx = 1;
          } else {
            tabIdx = 2;
          }

          $(`a[href='#content-tabs-${tabIdx}']`).trigger('click');
          $scope.searchDo(true, true);
        });
      });

      /**
     * 주문 요약정보 열기
     */
      // $scope.$on('openOrderInfoDetail', function(event, vdata) {
      // // 주문 요약정보 매칭 정보
      //   _.forEach($scope.orderList, function (order) {
      //     if (prodList[order.uniq]) {
      //       order.sku_pack = prodList[order.uniq][0].sku_cd;
      //       order.prod_name_pack = prodList[order.uniq][0].prod_name;
      //     }
      //   });

      //   vdata.row.child($compile('<detail-preview number=' + vdata.row[0][0] + ' type="order"></detail-preview>')($scope)).show();
      // });

      /**
     * 테이블 컬럼 사이즈 확인
     */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_integrated_list') {
          const $wrapper = $('#claim_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      /**
       * 운송장 출력
       * 재출력 추가 (printType === 'reprint')
       */

      $scope.claimInvoice = async (claimType) => {

        if (commonSVC.checkPermission('shipping.roles.claimInvoice', userInfo.permission) === false) {
          return false;
        }

        const selected = $scope.grid.methods.selectedData('all');

        if (!solCarrList.data.results.length) {
          commonSVC.showConfirm('택배사 연동정보가 없습니다.', '운송장 출력기능은 택배사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 택배사 관리 페이지로 이동합니다.', function () {
            $state.go('main.settings_delivery');
          });

          return;
        }

        const resolve = {
          data: {
            solCarrList: solCarrList.data.results,
            claimType
          }
        };

        if (selected.length === 0) {

          commonSVC.showMessage('실패', '주문을 선택해주세요.');

          return false;

        } else {
          // 재출력은 다음의 택배사인경우에만 지원.
          const checkCarr = selected.filter((o) => !(claimType === 'return' && o.carr_no === 4) && ![8, 956].includes(o.carr_no));

          if (checkCarr.length) {
            commonSVC.showMessage(`${claimType === 'return' ? '반품' : '교환'}접수는 다음의 택배사만 가능합니다.`, claimType === 'return' ? 'CJ대한통운, 롯데택배, 카카오T당일배송' : '롯데택배, 카카오T당일배송');

            return false;
          }

          resolve.totalCount = selected.length;

          // 송장재출력 유효성 확인

          const carr_no = _.map(selected, 'carr_no');
          const invoice_no = _.map(selected, 'invoice_no');

          if (_.uniq(carr_no).length > 1) {
            commonSVC.showMessage('실패', '택배사가 다른 주문이 있습니다.');

            return false;
          } else if (!_.compact(carr_no).length || !_.compact(invoice_no)) {
            commonSVC.showMessage('실패', '택배사 정보가 없습니다.');
          }

          resolve.data.carr_no = _.compact(carr_no)[0];
          resolve.data.invoice_no = _.compact(invoice_no)[0];

          // ---
          resolve.data.type = 'select';
          resolve.selectList = function () {
            return { data: { results: selected, recordsTotal: selected.length } };
          };
          resolve.solCarrList = solCarrList;
        }

        const modal = commonSVC.openModal('lg', resolve, 'claimInvoiceCtrl', 'views/order/shipment/modals/claim_invoice.html', false, true, false);

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      $scope.cancelClaimInvoice = (claimType) => {
        if (commonSVC.checkPermission('shipping.roles.claimInvoice', userInfo.permission) === false) {
          return false;
        }

        const selected = $scope.grid.methods.selectedData('all');

        if (solCarrList.data.results.length === 0) {
          commonSVC.showConfirm('택배사 연동정보가 없습니다.', '운송장 출력기능은 택배사 연동 후 이용할 수 있습니다. [확인] 버튼을 누르면 택배사 관리 페이지로 이동합니다.', function () {
            $state.go('main.settings_delivery');
          });

          return;
        }

        if (selected.length === 0) {

          commonSVC.showMessage('실패', '주문을 선택해주세요.');

          return false;

        } else {

          // 반송송장취소는 다음의 택배사인경우에만 지원.
          const checkCarr = selected.filter((o) => {
            return ![956].includes(o.carr_no);
          });

          if (checkCarr.length) {
            commonSVC.showMessage(`${claimType === 'return' ? '반품접수' : '교환접수'}취소는 다음의 택배사만 가능합니다.`, '카카오T당일배송');

            return false;
          }

          // 반송송장취소 유효성 확인

          const carr_no = selected.map(sel => sel.carr_no);
          const carr_id = selected.map(sel => sel.return_carr_id);
          const invoice_no = selected.map(sel => sel.invoice_no);

          if (_.uniq(carr_no).length > 1 || _.uniq(carr_id).length > 1) {
            commonSVC.showMessage('실패', '택배사가 다른 주문이 있습니다.');

            return false;
          } else if (!_.compact(carr_no).length || !_.compact(invoice_no)) {
            commonSVC.showMessage('실패', '택배사 정보가 없습니다.');
          }

          const resolve = {
            data: {
              cancelList: selected.map(sel => ({ uniq: sel.uniq, bundle_no: sel.bundle_no })),
              claimType,
              carrInfo: {
                carr_no: carr_no[0],
                carr_id: carr_id[0]
              }
            }
          };

          const modal = commonSVC.openModal('lg', resolve, 'cancelClaimInvoiceCtrl', 'views/order/shipment/modals/cancel_claim_invoice.html', false, true, false);

          modal.result.then(function (re) {
            if (re === 'success') {
              $scope.searchDo(false);
            }
          });
        }
      };

      /**
       * 알림톡 발송
       */
      $scope.altalkSend = async () => {
        const plusIdList = await atalkModel.accountList({ name: $rootScope.user_profile.sol_no });
        const resolve = {
          data: {
            open: 'ord',
            type: 'direct',
            plusIdList: plusIdList.data?.result,
            ord: $scope.domestic.methods.selectedData() || []
          }
        };
        commonSVC.openModal('x3g', resolve, 'AltalkSendCtrl', 'views/alimtalk/modals/altalk_send.html');
      };

      /**
       * 알림톡 그룹 회원 추가
       */
      $scope.altalkGroupMemAdd = () => {
        if (!$scope.domestic.methods.selectedData().length) {
          return commonSVC.showMessage('추가할 회원이 없습니다.', '주문리스트에서 주문을 먼저 선택해 주세요.');
        }
        commonSVC.openModal('lg', { data: { type: 'add_mem', ord: $scope.domestic.methods.selectedData() || [] } }, 'AltalkSendGroupCtrl', 'views/alimtalk/modals/altalk_send_group.html');
      };

      /**
       * 국내, 해외 선택
       */
      $scope.selectShopType = (shopType) => {
        if (shopType === 'domestic') {
          setSearch(domesticSearch);
        } else {
          setSearch(globalSearch);
        }

        $scope.selectedShopType = shopType;
        $scope.grid = $scope[$scope.selectedShopType];
        $scope.countSearch('total');
      };

      // 리스트와 별개로 초기에 세팅되어야하는 값
      function init(data) {
        $scope.countList = {
          total: data['클레임_전체'] ? data['클레임_전체'][0].cnt : 0,
          cancel_req: data['취소요청'] ? data['취소요청'][0].cnt : 0,
          cancel_com: data['취소완료'] ? data['취소완료'][0].cnt : 0,
          return_req: data['반품요청'] ? data['반품요청'][0].cnt : 0,
          return_proc: data['반품진행'] ? data['반품진행'][0].cnt : 0,
          return_pick: data['반품회수완료'] ? data['반품회수완료'][0].cnt : 0,
          return_com: data['반품완료'] ? data['반품완료'][0].cnt : 0,
          exchange_req: data['교환요청'] ? data['교환요청'][0].cnt : 0,
          exchange_proc: data['교환진행'] ? data['교환진행'][0].cnt : 0,
          exchange_pick: data['교환회수완료'] ? data['교환회수완료'][0].cnt : 0,
          exchange_com: data['교환완료'] ? data['교환완료'][0].cnt : 0,
          direct_exchange_req: data['맞교환요청'] ? data['맞교환요청'][0].cnt : 0,
          direct_exchange_com: data['맞교환완료'] ? data['맞교환완료'][0].cnt : 0,
          reconfirm: data['주문재확인'] ? data['주문재확인'][0].cnt : 0
        }; // 우측 아이콘 카운트

        $rootScope.listColorObj.claim = {};
        $scope.selectRowUniqList = [];
      }

      // 초기 선택이 안된 탭에도 검색기간 초기화 적용
      setTimeout(() => {
        const destSearchForm = $scope.selectedShopType === 'global' ? domesticSearch.searchForm : globalSearch.searchForm;
        destSearchForm.search_key = $scope.searchForm.search_key || $scope.searchData.search_key_items[0].value;
        destSearchForm.change_sdate = false;
        destSearchForm.change_edate = false;
        destSearchForm.selectDate = angular.copy($scope.searchForm.selectDate);
      });

      // 2018-04-06 Daniel
      // 재고관리제외버전 추가
      // stockExceptionSVC.scopeExcept('claim_list', $scope);
    });