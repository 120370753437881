'use strict';
/**
 *  state 프로바이더 분리
 *  2017-01-19 MatthewKim
 */
angular.module('gmpApp')
  .config(['$stateProvider', '$urlRouterProvider', 'userInfo', function($stateProvider, $urlRouterProvider, userInfo) {
    // 2017-02-07 MatthewKim
    const InvalidRedirectTo = {
      state: 'main.home',
      params: {}
    };

    let subMenuName = '';

    switch (userInfo.user.aff_no) {
      case 800:
        subMenuName = '대리점';
        break;
      case 1063:
      case 1062:
        subMenuName = '부품사';
        break;
      default:
        subMenuName = '';
        break;
    }

    // 2017-02-03 MatthewKim
    // 권한 관리 모듈의 알려진 이부분의 무한루프 버그 때문에 otherwise 처리를 아래와 같이 바꿈
    // https://github.com/Narzerus/angular-permission/wiki/Installation-guide-for-ui-router
    $urlRouterProvider

      // 2017-11-12 Daniel 로그인시 파라미터를 통해 이 URL로 들어오면 1:1문의 스테이트로 변경
      .when('/qustion/list', ['$injector', '$state', function($injector, $state) {
        $state.go('main.question_list');
      }])

      // 2017-11-24 Daniel 로그인시 파라미터를 통해 이 URL로 들어오면 공지사항 스테이트로 변경
      .when('/notice/list', ['$injector', '$state', function ($injector, $state) {
        $state.go('main.notice_list');
      }])

      .otherwise(function($injector) {
        const $state = $injector.get('$state');

        if (userInfo.user.company_name) {
          $state.go(InvalidRedirectTo.state);
        } else {
          // 2017-06-02 chris 기본 설정 페이지
          $state.go('main.home');
        }
      });

    $stateProvider

      /**
       * 최상위 state
       */
      .state('main', {

        url: '',
        templateUrl: '/views/main.html',
        controller: 'MainController',

        // 2017-03-03 MatthewKim data 변수 밑으로 선언한 키들은, 하위 뷰에서 선언하지 않으면 상속 된다.
        // 상속되는 변수는 resolve 한 내용과 data 변수 하위의 값들임
        // https://github.com/angular-ui/ui-router/wiki/Nested-States-and-Nested-Views#what-do-child-states-inherit-from-parent-states
        data: {
          // 사이드바
          sidebars: {
            main: {
              show: true
            },
            left: {
              show: false
            },
            right: {
              show: false
            }
          },
          // 앵귤러 탭 자동 숨김 기능을 위한 기본값
          // 하위 뷰에서 변경이 필요하면, 하위뷰의 data: {parallel: false} 등으로 처리하면 그 뷰에서만 바뀜
          parallel: true, // 병렬 뷰 사용 (다른 뷰 갔다 와도 뷰 유지)
          deepStateRedirect: true
        },
        // 2017-11-08 chris angular-gettext 설정 타이밍이 안맞는 경우 일부 텍스트가 번역이 안된걸로 보임
        // resolve 추가로 언어설정 후 페이지 로딩으로 변경
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
          //사용중인 채널 리스트
          channelList: function (shopAccountModel) {
            return shopAccountModel.shopAccountUseList({ init_channel: true });
          },
          // 개별요금 조회
          indvPayInfo: function (userModel) {
            return userModel.getIndvPayAmtAndCntLimit();
          }
        }
      })

      /**
       * 홈페이지
       */
      .state('main.home', {
        url: '/home',
        views: {
          'main.home': {
            templateUrl: 'views/home/dashboard.html',
            controller: 'DashboardCtrl',
          }
        },
        data: {
          pageTitle: '<i class="picon-home" aria-hidden="true"></i>'
        },
        resolve: {
          systemList: function(systemModel) {
            return systemModel.load();
          },
          //사용중인 채널 리스트
          channelList: function(shopAccountModel) {
            return shopAccountModel.shopAccountUseList();
          }
        }
      })

      /**
       * 결제관리 테스트 페이지
       */
      .state('main.pay_list', {
        url: '/pay_list/:tab',
        views: {
          'main.pay_list': {
            templateUrl: 'views/pay/pay_list.html',
            controller: 'PayCtrl',
          }
        },
        data: {
          pageTitle: '결제관리',
          permissions: {
            only: ['pay.pay_list'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      /**
       * 통계
       */
      .state('main.summary', {
        url: '/summary',
        views: {
          'main.summary': {
            templateUrl: 'views/summary/summary.html',
            controller: 'SummaryCtrl',
          }
        },
        data: {
          pageTitle: '판매통계'
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })
      .state('main.aggregation', {
        url: '/aggregation',
        views: {
          'main.aggregation': {
            templateUrl: 'views/summary/aggregation.html',
            controller: 'AggregationCtrl'
          }
        },
        data: {
          pageTitle: '주문집계'
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
          //사용중인 채널 리스트
          channelList: function(shopAccountModel) {
            return shopAccountModel.shopAccountUseList();
          },
          // 창고 리스트 조회
          warehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 매입처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 커스텀 컬럼 조회
          addColumnList: function(columnModel) {
            return columnModel.addColumnList({ addcol_type: 'product' });
          },
          // 커스텀 컬럼 집계 데이터 조회
          addColumnAggregationList: function($q, columnModel) {
            return columnModel.addColumnList({ addcol_type: 'product' }).then(response => {
              const addColNos = response.data.results.map(col => col.addcol_no);

              return columnModel.getCustomColumnData({ type: 'product', addcol_nos: addColNos });
            });
          }
        }
      })

      .state('main.calendar', {
        url: '/home/calendar',
        views: {
          'main.calendar': {
            templateUrl: 'views/home/calendar.html',
            controller: 'HomeCalendarCtrl',
          }
        },
        data: {
          pageTitle: '일정 관리',
          permissions: {
            only: ['common.calendar'],
            redirectTo: InvalidRedirectTo
          }
        }
      })
      .state('main.work', {
        url: '/etc/work',
        views: {
          'main.work': {
            templateUrl: 'views/etc/work.html',
            controller: 'EtcWorkCtrl'
          }
        },
        data: {
          pageTitle: '작업 관리',
          pageIcon: '<i class="icon-sync worksvc-spinner-toggle"></i>',
          sidebars: {
            main: {
              show: true
            },
            left: {
              show: false
            },
            right: {
              show: false
            }
          },
          permissions: {
            only: ['common.work'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          systemList: function (systemModel) {
            return systemModel.load();
          },
          // G마켓 물류서비스 계정
          ebaydepotAccounts: function (shopAccountModel) {
            return shopAccountModel.getEbaydepotAccounts();
          }
        }
      })

      /**
       * 메모 리스트
       */
      .state('main.memo_list', {
        url: '/etc/memo_list',
        views: {
          'main.memo_list': {
            templateUrl: 'views/etc/memo_list.html',
            controller: 'MemoListCtrl',
          }
        },
        data: {
          pageTitle: '메모관리'
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: '' });
          }
        }
      })

      /**
       * 회사 정보 관리
       */
      .state('main.configs_admin', {
        url: '/configs/admin/:flag',
        views: {
          'main.configs_admin': {
            templateUrl: 'views/configs/admin.html',
            controller: 'ConfigsAdminCtrl',
          }
        },
        data: {
          pageTitle: '회사 정보 관리',
          sidebars: {
            main: {
              show: true
            },
            left: {
              show: false
            },
            right: {
              show: false
            }
          },
          permissions: {
            only: ['configs.admin'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      /**
       * 내 정보 관리
       */
      .state('main.configs_master_edit', {
        url: '/configs/master_edit',
        views: {
          'main.configs_master_edit': {
            templateUrl: 'views/configs/master_edit.html',
            controller: 'ConfigsMasterEditCtrl'
          }
        },
        data: {
          pageTitle: '내 정보 관리',
          permissions: {
            only: ['configs.master_KR_edit'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          $uibModalInstance: function() {
            return '';
          }
        }
      })

      .state('main.configs_user', {
        url: '/configs/user',
        views: {
          'main.configs_user': {
            templateUrl: 'views/configs/user.html',
            controller: 'ConfigsUserCtrl',
          }
        },
        data: {
          pageTitle: '사용자 관리',
          permissions: {
            only: ['configs.user+admin'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          warehouseList: function(warehouseModel) {
            return warehouseModel.warehouseList();
          }
        }
      })

      .state('main.configs_sub_user', {
        url: '/configs/subuser',
        views: {
          'main.configs_sub_user': {
            templateUrl: 'views/configs/sub_user.html',
            controller: 'ConfigsSubUserCtrl',
          }
        },
        data: {
          pageTitle: `${subMenuName}관리`,
          permissions: {
            only: ['configs.user+admin'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          warehouseList: function(warehouseModel) {
            return warehouseModel.warehouseList();
          }
        }
      })

      .state('main.configs_system', {
        url: '/configs/system',
        views: {
          'main.configs_system': {
            templateUrl: 'views/configs/system.html',
            controller: 'ConfigsSystemCtrl',
          }
        },
        data: {
          pageTitle: '환경설정',
          sidebars: {
            main: {
              show: true
            },
            left: {
              show: false
            },
            right: {
              show: false
            }
          },
          permissions: {
            only: ['configs.system'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          warehouseList: function(warehouseModel) {
            return warehouseModel.warehouseList();
          },
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 기본설정
       */
      .state('main.settings_shop_account', {
        url: '/settings/shop_account?pageFrom',
        views: {
          'main.settings_shop_account': {
            templateUrl: 'views/settings/shopAccount/list.html',
            controller: 'SettingsShopAccountListCtrl'
          }
        },
        data: {
          pageTitle: '쇼핑몰 계정 관리',
          permissions: {
            only: ['settings.channel'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      .state('main.settings_shop_account.channelSub', {
        url: '/:mode/:shop_cd/:pa_shop_cd/:shop_id/:shop_id_sub/:ctry_cd/{shop_name:.*}/:shop_type/:domain',
        views: {
          'main.settings_shop_account.channelSub': {
            templateUrl: function ($stateParams) {
              return `views/settings/shopAccount/${$stateParams.shop_cd === 'P059' ? $stateParams.shop_cd : $stateParams.pa_shop_cd}.html`;
            },
            controllerProvider: function($stateParams) {

              return $stateParams.shop_cd.startsWith('X') || $stateParams.pa_shop_cd === 'X095' ? 'SettingsGlobalShopDetailCtrl' : 'SettingsShopDetailCtrl';
            }
          }
        },

        data: {
          pageTitle: '쇼핑몰 계정 상세',
          permissions: {
            only: ['settings.channel'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      .state('main.settings_category', {
        url: '/settings/category',
        views: {
          'main.settings_category': {
            templateUrl: 'views/settings/category/main.html',
            controller: 'CategoryMainCtrl',
          }
        },
        data: {
          pageTitle: '카테고리 템플릿 관리',
          permissions: {
            only: ['settings.category'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      .state('main.settings_category.categoryDetailPanel', {
        url: '/:id/{title:.*}/:parents_id/{parents_name:.*}/:hscode/:bookmark/:p_cate_cd',
        templateUrl: function () {
          return 'views/settings/category/cateInfoDetail.html';
        },
        controller: 'CategoryInfoDetailCtrl',
        data: {
          pageTitle: '카테고리 상세',
          permissions: {
            only: ['settings.category'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      /**
       * 카테고리 상세 쇼핑몰 매칭정보
       */
      .state('main.settings_category.categoryDetailPanel.shopMatching', {
        // url: '/:site_code',
        url: '/:site_code/:site_id/:site_name',
        views: {
          'main.settings_category.categoryDetailPanel.shopMatching': {
            templateUrl: function ($stateParams) {
              return `views/settings/category/shopDetail/${$stateParams.site_code}.html`;
            },
            controllerProvider: function($stateParams) {
              return `CategoryShopInfoDetail-${$stateParams.site_code}-Ctrl`;
            }
            // controller: site_code+'TemplateCtrl'
          }
        },
        data: {
          permissions: {
            only: ['online.template'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      .state('main.settings_supplier_list', {
        url: '/settings/supplier/list',
        views: {
          'main.settings_supplier_list': {
            templateUrl: 'views/settings/supplier/list.html',
            controller: 'SettingsSupplierListCtrl'
          }
        },
        data: {
          pageTitle: '매입처 관리',
          permissions: {
            only: ['settings.supplier'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

      .state('main.settings_delivery', {
        url: '/settings/delivery',
        views: {
          'main.settings_delivery': {
            templateUrl: 'views/settings/delivery/delivery.html',
            controller: 'SettingsDeliveryCtrl'
          }
        },
        data: {
          pageTitle: '택배 관리',
          permissions: {
            only: ['settings.delivery'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      .state('main.settings_delivery.detail', {
        url: '/:linkType/:pageType/:carr_no/:carr_id/:carr_id_sub',
        views: {
          'main.settings_delivery.detail': {
            templateUrl: function($stateParams) {
              return `views/settings/delivery/detail/${$stateParams.carr_no}.html`;
            },
            controllerProvider: function() {
              return 'SettingsDeliveryLinkedCtrl';
            }
          }
        }
      })

      /**
       * 상품관리
       */
      .state('main.prod_product_list', {
        url: '/prod/product/list',
        views: {
          'main.prod_product_list': {
            templateUrl: 'views/prod/product/list.html',
            controller: 'ProdProductListCtrl',
          }
        },
        data: {
          pageTitle: 'SKU상품 관리',
          permissions: {
            only: ['prod.product'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // G마켓 물류서비스 계정
          ebaydepotAccounts: function (shopAccountModel) {
            return shopAccountModel.getEbaydepotAccounts();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:prod' });
          },
          // 포장형태 리스트 조회
          skuPkgList: function(productModel) {
            return productModel.prodPkgBoxList({ start: 0, length: 999999999 });
          }
        }
      })

      .state('main.sub_prod_product_list', {
        url: '/prod/product/sublist',
        views: {
          'main.sub_prod_product_list': {
            templateUrl: 'views/prod/product/sub_prod_list.html',
            controller: 'SubProdListCtrl',
          }
        },
        data: {
          pageTitle: `SKU상품 관리 (${subMenuName})`,
          permissions: {
            only: ['prod.product'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          systemList: function(systemModel) {
            return systemModel.load();
          },
          subUserList: (commonModel) => {
            return commonModel.subUserList();
          },
        }
      })

      /**
       * 세트관리
       */
      .state('main.prod_set_list', {
        url: '/prod/set/list',
        views: {
          'main.prod_set_list': {
            templateUrl: 'views/prod/set/list.html',
            controller: 'ProdSetListCtrl',
          }
        },
        data: {
          pageTitle: 'SKU세트 상품 관리',
          permissions: {
            only: ['prod.product'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true, growth_yn: false });
          },
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:depot_set' });
          }
        }
      })

      .state('main.prod_product_list.detail', {
        url: '/:detailType/:rowIndex/:sku/:number',
        templateUrl: 'views/prod/product/detail.html',
        controller: 'ProdProductDetailCtrl',
        data: {
          pageTitle: 'SKU상품 수정',
          permissions: {
            only: ['prod.product'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
          },
          // 원산지 리스트 조회
          madeinList: function(productModel) {
            return productModel.getMadein();
          },
          // 환경설정 리스트 조회
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      .state('main.prod_product_add', {
        url: '/prod/product/add',
        views: {
          'main.prod_product_add': {
            templateUrl: 'views/prod/product/detail.html',
            controller: 'ProdProductDetailCtrl'
          }
        },
        data: {
          pageTitle: 'SKU상품 등록',
          permissions: {
            only: ['prod.product'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          $uibModalInstance: function() {
            return '';
          },
          data: function() {
          },
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 원산지 리스트 조회
          madeinList: function(productModel) {
            return productModel.getMadein();
          },
          // 환경설정 리스트 조회
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 온라인채널
       */
      .state('main.online_product_list', {
        url: '/online/product/list?action&search_word',
        views: {
          'main.online_product_list': {
            templateUrl: 'views/online/product/list.html',
            controller: 'OnlineProductListCtrl',
          }
        },
        data: {
          pageTitle: '쇼핑몰 상품 관리',
          permissions: {
            only: ['online.product'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 카테고리 리스트 조회
          categoryList: function(categoryModel) {
            return categoryModel.categorySelectList();
          },
          // 템플릿 리스트
          templateList: function(templateModel) {
            return templateModel.list();
          },
          systemList: function (systemModel) {
            return systemModel.load();
          },
          // 원산지 리스트 조회
          madeinList: function(productModel) {
            return productModel.getMadein();
          },
          addcontentlistOfAppliedToOnline: function (onlineProductModel) {
            return onlineProductModel.addcontentlistOfAppliedToOnline();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ol_shop' });
          }
        }
      })

      .state('main.sub_online_product_list', {
        url: '/online/product/sublist',
        views: {
          'main.sub_online_product_list': {
            templateUrl: 'views/online/product/sub_online_list.html',
            controller: 'SubOnlineListCtrl',
          }
        },
        data: {
          pageTitle: `쇼핑몰 상품 관리 (${subMenuName})`,
          permissions: {
            only: ['online.product'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
          subUserList: (commonModel) => {
            return commonModel.subUserList();
          },
          subChannelList: function(shopAccountModel) {
            return shopAccountModel.subShopList();
          },
        }
      })
      /**
       * 온라인채널 상품 등록
       */
      .state('main.online_product_add', {
        url: '/online/product/add',
        views: {
          'main.online_product_add': {
            templateUrl: 'views/online/product/add.html',
            controller: 'OnlineProductAddCtrl'
          }
        },
        data: {
          pageTitle: '쇼핑몰 상품 등록',
          permissions: {
            only: ['online.product+write'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 원산지 리스트 조회
          madeinList: function(productModel) {
            return productModel.getMadein();
          },
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 카테고리 리스트 조회
          categoryList: function(categoryModel) {
            return categoryModel.categorySelectList();
          },
          templateGroupList: function (templateModel) {
            return templateModel.listGroup();
          }
        }
      })

      /**
       * 쇼핑몰 상품 가져오기
       */
      .state('main.prodScrap_match_list', {
        url: '/online/product/prodScrap_match_list/:shop_id/:shop_cd',
        views: {
          'main.prodScrap_match_list': {
            templateUrl: 'views/online/product/prodScrap_match_list.html',
            controller: 'OnlineProductScrapMatchCtrl'
          }
        },
        data: {
          pageTitle: '쇼핑몰 상품 가져오기',
          permissions: {
            only: ['online.scrap'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 원산지 리스트 조회
          madeinList: function(productModel) {
            return productModel.getMadein();
          },
          // 배송처 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //사용중인 채널 리스트
          channelList: function(shopAccountModel) {
            return shopAccountModel.shopAccountUseList({ site_action: 'ScrapProd', import_yn: true });
          }
        }
      })

      /**
       * SKU상품 매칭
       */
      .state('main.online_prod_match', {
        url: '/online/product/online_prod_match',
        views: {
          'main.online_prod_match': {
            templateUrl: 'views/online/product/online_prod_match.html',
            controller: 'OnlineProductMatchCtrl'
          }
        },
        data: {
          pageTitle: 'SKU상품 매칭',
          permissions: {
            only: ['online.product+write'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 원산지 리스트 조회
          madeinList: function(productModel) {
            return productModel.getMadein();
          },
          // 배송처 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 매입처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
        }
      })

      /**
       * 세트폼 템플릿관리
       */
      .state('main.setForm_template_list', {
        url: '/online/setForm/list',
        views: {
          'main.setForm_template_list': {
            templateUrl: 'views/online/setForm/list.html',
            controller: 'setFormTemplateListSubCtrl',
          }
        },
        data: {
          pageTitle: '템플릿 관리',
          permissions: {
            only: ['online.template'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
          defaultTemplateGroup: function (templateModel) {
            return templateModel.loadGroup({ template_group_no: userInfo.user.default_template_group_no });
          }
        }
      })

      /**
       * 세트폼 템플릿관리 등록 리스트
       */
      .state('main.setForm_template_add', {
        //type1 -> 온라인상품에서 등록인경우 ol
        url: '/online/setForm/add/:type1',
        views: {
          'main.setForm_template_add': {
            templateUrl: 'views/online/setForm/add.html',
            controller: 'setFormTemplateAddCtrl',
          }
        },
        data: {
          pageTitle: '템플릿 등록',
          permissions: {
            only: ['online.template'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          //사용중인 채널 리스트
          data: function($stateParams) {
            return $stateParams;
          },
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 세트폼 템플릿관리 등록 상세
       */
      .state('main.setForm_template_add.channelSub', {
        url: '/:pa_shop_cd/:shop_cd/{shop_name:.*}/:shop_id/:type/:type2',
        views: {
          'main.setForm_template_add.channelSub': {
            controllerProvider: function () {
              return 'setFormTemplateCtrl';
            }
          }
        },
        data: {
          permissions: {
            only: ['online.template'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          //사용중인 채널 리스트
          data: function($stateParams) {

            return $stateParams;
          },
          $uibModalInstance: function() {
            return '';
          }
        }
      })

      /**
       * 템플릿관리 등록 리스트
       */
      .state('main.online_template_add', {
        url: '/online/template/add',
        views: {
          'main.online_template_add': {
            templateUrl: 'views/online/template/add.html',
            controller: 'OnlineTemplateAddCtrl',
          }
        },
        data: {
          pageTitle: '온라인 템플릿 등록',
          permissions: {
            only: ['online.template'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

      /**
       * 템플릿관리 수정
       */
      .state('main.online_template_list.detail', {
        url: '/:templateCode/:siteCode/:siteId',
        templateUrl: function ($stateParams) {
          return `views/online/template/${$stateParams.siteCode}.html`;
        },
        controllerProvider: function($stateParams) {
          return `${$stateParams.siteCode}TemplateCtrl`;
        },
        data: {
          pageTitle: '템플릿 상세',
          permissions: {
            only: ['online.template'],
            redirectTo: InvalidRedirectTo
          }
        }
      })

    /**
       * 주문관리
       */

      //결제완료 & 결제대기
      .state('main.order_shipment_payment_list', {
        url: '/order/shipment/payment_list',
        views: {
          'main.order_shipment_payment_list': {
            templateUrl: 'views/order/shipment/payment_list.html',
            controller: 'OrderShipmentPaymentListCtrl'
          }
        },
        data: {
          pageTitle: '결제 완료',
          permissions: {
            only: ['order.payment'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('payment_shipment_grid');
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      //신규주문
      .state('main.order_shipment_order_list', {
        url: '/order/shipment/order_list?openScrapOrderModal',
        views: {
          'main.order_shipment_order_list': {
            templateUrl: 'views/order/shipment/order_list.html',
            controller: 'OrderShipmentOrderListCtrl'
          }
        },
        data: {
          pageTitle: '신규 주문',
          permissions: {
            only: ['order.order'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 카테고리 리스트 조회
          categoryList: function(categoryModel) {
            return categoryModel.categorySelectList();
          },
          //환경설정 리스트
          useSystemList: function(systemModel) {
            return systemModel.load();
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('order_shipment_grid');
          },
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 사용자태그 리스트 조회
          tagList: function(tagModel) {
            return tagModel.list();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      // 주문 SKU상품 매칭
      .state('main.order_shipment_mapping', {
        url: '/order/shipment/mapping',
        views: {
          'main.order_shipment_mapping': {
            templateUrl: 'views/order/shipment/prod_mapping.html',
            controller: 'OrderShipmentMappingCtrl'
          }
        },
        data: {
          pageTitle: '주문 SKU상품 매칭',
          permissions: {
            only: ['order.mapping'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // modal 용
          $uibModalInstance: function () {
            return null;
          },
          // modal 선택리스트
          selectList: function() {
            return { uniq: null };
          }
        }
      })

      // 매칭규칙 관리
      .state('main.order_shipment_mapping_rule', {
        url: '/order/shipment/mapping_rule',
        views: {
          'main.order_shipment_mapping_rule': {
            templateUrl: 'views/order/shipment/mapping_rule.html',
            controller: 'OrderShipmentMappingRuleCtrl'
          }
        },
        data: {
          pageTitle: '매칭규칙 관리',
          permissions: {
            only: ['order.mapping_rule'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          //배송처 리스트
          warehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          $uibModalInstance: function() {
            return '';
          }
        }
      })

      // 사은품규칙 관리
      .state('main.order_shipment_gift_list', {
        url: '/order/shipment/gift_list',
        views: {
          'main.order_shipment_gift_list': {
            templateUrl: 'views/order/shipment/gift_list.html',
            controller: 'OrderShipmentGiftListCtrl'
          }
        },
        data: {
          pageTitle: '사은품규칙 관리',
          permissions: {
            only: ['order.gift'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 환경설정 리스트
          useSystemList: function(systemModel) {
            return systemModel.load();
          },
          // 배송처 리스트
          useWarehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          }
        }
      })

      /**
       * 출고관리
       */
      .state('main.order_shipment_unstoring_list', {
        url: '/order/shipment/unstoring_list',
        views: {
          'main.order_shipment_unstoring_list': {
            templateUrl: 'views/order/shipment/unstoring_list.html',
            controller: 'OrderShipmentUnstoringListCtrl'
          }
        },
        data: {
          pageTitle: '출고관리',
          permissions: {
            only: ['order.unstoring'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //택배사 리스트
          solCarrList: function(deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
          // 글로벌 택배사 리스트
          globalCarrList: function(deliveryModel) {
            return deliveryModel.bookMarkdeliveryInfoList().then(data => {
              return data.data.carr_list;
            });
          },
          // 규칙적용사은품 리스트 조회
          giftList: function(giftModel) {
            return giftModel.loadList();
          },
          //환경설정 리스트
          useSystemList: function(systemModel) {
            return systemModel.load();
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('unstoring_shipment_grid');
          },
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 사용자태그 리스트 조회
          tagList: function(tagModel) {
            return tagModel.list();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      // 배송 관리
      .state('main.order_shipment_delivery_list', {
        url: '/order/shipment/delivery_list',
        views: {
          'main.order_shipment_delivery_list': {
            templateUrl: 'views/order/shipment/delivery_list.html',
            controller: 'OrderShipmentDeliveryListCtrl'
          }
        },
        data: {
          pageTitle: '배송 관리',
          permissions: {
            only: ['order.delivery'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //사용중인 배송사 리스트
          deliveryAllList: function(deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
          //택배사 리스트
          solCarrList: function(deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
          // 글로벌 택배사 리스트
          globalCarrList: function(deliveryModel) {
            return deliveryModel.bookMarkdeliveryInfoList().then(data => {
              return data.data.carr_list;
            });
          },
          // 규칙적용사은품 리스트 조회
          giftList: function(giftModel) {
            return giftModel.loadList();
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('delivery_shipment_grid');
          },
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 사용자태그 리스트 조회
          tagList: function(tagModel) {
            return tagModel.list();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      // 위탁 주문 관리
      .state('main.order_shipment_consignment_list', {
        url: '/order/shipment/consignment_list',
        views: {
          'main.order_shipment_consignment_list': {
            templateUrl: 'views/order/shipment/consignment_list.html',
            controller: 'OrderShipmentConsignmentListCtrl'
          }
        },
        data: {
          pageTitle: '위탁 주문 관리',
          permissions: {
            only: [],
            redirectTo: InvalidRedirectTo
          },
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('consignment_shipment_grid');
          },
          //택배사 리스트
          solCarrList: function(deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
        }
      })

      //전체 주문 조회
      .state('main.order_shipment_integrated_list', {
        url: '/order/shipment/integrated_list',
        views: {
          'main.order_shipment_integrated_list': {
            templateUrl: 'views/order/shipment/integrated_list.html',
            controller: 'OrderShipmentIntegratedListCtrl'
          }
        },
        data: {
          pageTitle: '전체 주문 조회',
          permissions: {
            only: ['order.integrated'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 규칙적용사은품 리스트 조회
          giftList: function(giftModel) {
            return giftModel.loadList();
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('integrated_shipment_grid');
          },
          //택배사 리스트
          solCarrList: function(deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
          // 글로벌 택배사 리스트
          globalCarrList: function(deliveryModel) {
            return deliveryModel.bookMarkdeliveryInfoList().then(data => {
              return data.data.carr_list;
            });
          },
          // 사용자태그 리스트 조회
          tagList: function(tagModel) {
            return tagModel.list();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

    //물류배송주문 조회
      .state('main.order_shipment_fulfillment_order_list', {
        url: '/order/shipment/fulfillment_order_list',
        views: {
          'main.order_shipment_fulfillment_order_list': {
            templateUrl: 'views/order/shipment/fulfillment_order_list.html',
            controller: 'OrderShipmentFulfillmentOrderListCtrl'
          }
        },
        data: {
          pageTitle: '물류배송 주문 조회',
          permissions: {
            only: ['order.integrated'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true, fulfillment_yn: true });
          },
          // 규칙적용사은품 리스트 조회
          giftList: function(giftModel) {
            return giftModel.loadList();
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('fulfillment_shipment_grid');
          },
          //택배사 리스트
          solCarrList: function(deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
          // 글로벌 택배사 리스트
          globalCarrList: function(deliveryModel) {
            return deliveryModel.bookMarkdeliveryInfoList().then(data => {
              return data.data.carr_list;
            });
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      // A/S 수선 리스트
      .state('main.order_shipment_as_list', {
        url: '/order/shipment/as_list',
        views: {
          'main.order_shipment_as_list': {
            templateUrl: 'views/order/shipment/as_list.html',
            controller: 'OrderShipmentAsListCtrl'
          }
        },
        data: {
          pageTitle: 'A/S(수선) 관리',
          permissions: {
            only: ['order.shipment'],
            redirectTo: InvalidRedirectTo

          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //택배사 리스트
          solCarrList: function (deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
          //환경설정 리스트
          systemList: function (systemModel) {
            return systemModel.load();
          },
          // 그리드 설정 정보
          gridInfo: function (commonModel) {
            return commonModel.getGridInfo('as_grid');
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:mend' });
          }
        }
      })

      //클레임 관리
      .state('main.order_shipment_claim_list', {
        url: '/order/claim/list',
        views: {
          'main.order_shipment_claim_list': {
            templateUrl: 'views/order/shipment/claim_list.html',
            controller: 'OrderShipmentClaimListCtrl'
          }
        },
        data: {
          pageTitle: '클레임 관리',
          permissions: {
            only: ['order.claim'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 규칙적용사은품 리스트 조회
          giftList: function(giftModel) {
            return giftModel.loadList();
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('claim_shipment_grid');
          },
          //택배사 리스트
          solCarrList: function (deliveryModel) {
            return deliveryModel.deliveryAllList();
          },
          // 글로벌 택배사 리스트
          globalCarrList: function(deliveryModel) {
            return deliveryModel.bookMarkdeliveryInfoList().then(data => {
              return data.data.carr_list;
            });
          },
          // 사용자태그 리스트 조회
          tagList: function(tagModel) {
            return tagModel.list();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      // G마켓 물류서비스 주문
      .state('main.order_shipment_ebaydepot_list', {
        url: '/order/shipment/ebaydepot_list',
        views: {
          'main.order_shipment_ebaydepot_list': {
            templateUrl: 'views/order/shipment/ebaydepot_list.html',
            controller: 'OrderShipmentEbaydepotListCtrl'
          }
        },
        data: {
          pageTitle: 'G마켓 물류서비스 주문',
          permissions: {
            only: ['order.unstoring'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          useWarehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          // 거래처 리스트 조회
          supplierList: function (supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 카테고리 리스트 조회
          categoryList: function (categoryModel) {
            return categoryModel.categorySelectList();
          },
          //환경설정 리스트
          useSystemList: function (systemModel) {
            return systemModel.load();
          },
          // 규칙적용사은품 리스트 조회
          giftList: function (giftModel) {
            return giftModel.loadList();
          },
          // G마켓 물류서비스 계정
          ebaydepotAccounts: function (shopAccountModel) {
            return shopAccountModel.getEbaydepotAccounts();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      // 대리점(특약점) 주문
      .state('main.order_shipment_agency_list', {
        url: '/order/shipment/agency_list',
        views: {
          'main.order_shipment_agency_list': {
            templateUrl: 'views/order/shipment/agency_list.html',
            controller: 'OrderShipmentAgencyListCtrl'
          }
        },
        data: {
          pageTitle: '대리점(특약점) 주문',
          permissions: {
            only: ['order.order'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          useSystemList: function(systemModel) {
            return systemModel.load();
          },
          // 대리점(특약점) 리스트 조회
          subUserList: function(commonModel) {
            return commonModel.subUserList();
          },
          // 대리점(특약점) 사용중인 채널 목록 조회
          subChannelList: function(shopAccountModel) {
            return shopAccountModel.subShopList();
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('order_agency_grid');
          }
        }
      })

      .state('main.order_customer_inquiry_list', {
        url: '/order/customer_inquiry/list',
        views: {
          'main.order_customer_inquiry_list': {
            templateUrl: 'views/order/customer_inquiry/list.html',
            controller: 'OrderCustomerInquiryListCtrl'
          }
        },
        data: {
          pageTitle: '문의 관리',
          permissions: {
            only: ['order.customer'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 대리점 문의리스트
       */
      .state('main.order_customer_inquiry_agency_list', {
        url: '/order/customer_inquiry/agency_list',
        views: {
          'main.order_customer_inquiry_agency_list': {
            templateUrl: 'views/order/customer_inquiry/agency_list.html',
            controller: 'OrderCustomerInquiryAgencyListCtrl'
          }
        },
        data: {
          pageTitle: '대리점(특약점) 문의관리',
          permissions: {
            only: ['order.customer'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 대리점(특약점) 리스트 조회
          subUserList: function(commonModel) {
            return commonModel.subUserList();
          },
          // 대리점(특약점) 사용중인 채널 목록 조회
          subChannelList: function(shopAccountModel) {
            return shopAccountModel.subShopList();
          },
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 재고관리
       */
      .state('main.stock_inventory_list', {
        url: '/stock/inventory/list',
        views: {
          'main.stock_inventory_list': {
            templateUrl: 'views/stock/inventory/list.html',
            controller: 'StockInventoryListCtrl',
          }
        },
        data: {
          pageTitle: 'SKU재고 현황',
          permissions: {
            only: ['stock.inventory'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:prod' });
          }
        }
      })
      /**
       * 입/출고 내역
       */
      .state('main.stock_inventory_inout', {
        url: '/stock/inventory/inout_list',
        views: {
          'main.stock_inventory_inout': {
            templateUrl: 'views/stock/inventory/inout_list.html',
            controller: 'StockInoutListCtrl',
          }
        },
        data: {
          pageTitle: '입/출고 내역',
          permissions: {
            only: ['stock.inventory_inout'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })
      .state('main.stock_inventory_list.detail', {
        url: '/:detailType/:rowIndex/:sku/:number',
        templateUrl: 'views/prod/product/detail.html',
        controller: 'ProdProductDetailCtrl',
        data: {
          pageTitle: 'SKU재고 현황 수정',
          permissions: {
            only: ['stock.inventory'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 거래처 리스트 조회
          supplierList: function(supplierModel) {
            return supplierModel.ListAll({ use_yn: true });
          },
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
          },
          // 원산지 리스트 조회
          madeinList: function(productModel) {
            return productModel.getMadein();
          },
          // 환경설정 리스트 조회
          systemList: function(systemModel) {
            return systemModel.load();
          }

        }
      })

      .state('main.stock_warehouse_list', {
        url: '/stock/warehouse/list',
        views: {
          'main.stock_warehouse_list': {
            templateUrl: 'views/stock/warehouse/list.html',
            controller: 'StockWarehouseListCtrl',
          }
        },
        data: {
          pageTitle: '배송처 관리',
          permissions: {
            only: ['stock.warehouse'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

    /**
     * 정산관리 > 정산 현황
     */
      .state('main.calculate_state_list', {
        url: '/calculate/state_list',
        views: {
          'main.calculate_state_list': {
            templateUrl: 'views/calculate/state_list.html',
            controller: 'CalculateStateListCtrl'
          }
        },
        data: {
          pageTitle: '정산 현황',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

    /**
     * 정산관리 > 상세내역
     */
      .state('main.calculate_detail_list', {
        url: '/calculate/detail_list',
        views: {
          'main.calculate_detail_list': {
            templateUrl: 'views/calculate/detail_list.html',
            controller: 'CalculateDetailListCtrl'
          }
        },
        data: {
          pageTitle: '상세내역',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

    /**
     * 정산관리 > 주문관리 정산
     */
      .state('main.calculate_order_list', {
        url: '/calculate/order_list',
        views: {
          'main.calculate_order_list': {
            templateUrl: 'views/calculate/order_list.html',
            controller: 'CalculateOrderListCtrl'
          }
        },
        data: {
          pageTitle: '주문관리 정산',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 정산관리 > SKU별 추정 매출 현황 (모듈)
       */
      .state('main.calculate_state_per_prod_list', {
        url: '/calculate/state_per_prod_list',
        views: {
          'main.calculate_state_per_prod_list': {
            templateUrl: 'views/calculate/state_per_prod_list.html',
            controller: 'CalculateStatePerProdListCtrl'
          }
        },
        data: {
          pageTitle: 'SKU별 추정 매출 현황',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
          //사용중인 채널 리스트
          channelList: function (shopAccountModel) {
            return shopAccountModel.shopAccountUseList({ init_channel: true });
          },
          // 메모 템플릿 리스트 조회
          memoTemplateList: function(memoModel) {
            return memoModel.getTemplateList({ search_key: 'all:ord' });
          }
        }
      })

      /**
       * 공지사항 통합 State
       * 2017-10-25 Pada 2017-12-22 이현제
       */
      .state('main.notice_detail', {
        url: '/notice/:noticeType/detail/:noti_no',
        views: {
          'main.notice_detail': {
            templateUrl: 'views/notice/detail.html',
            controller: 'NoticeDetailCtrl'
          }
        },
        data: {
          pageTitle: '공지사항 상세',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {

        }
      })

      /**
       * 링크를 통해 연 공지사항 상세 State
       */
      .state('main.notice_detail_link', {
        url: '/notice/:noticeType/detail/:noti_no/link',
        views: {
          'main.notice_detail_link': {
            templateUrl: 'views/notice/detail.html',
            controller: 'NoticeDetailCtrl'
          }
        },
        data: {
          pageTitle: '공지사항 상세',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {

        }
      })

      .state('main.notice_list', {
        url: '/notice/list',
        views: {
          'main.notice_list': {
            templateUrl: 'views/notice/list.html',
            controller: 'NoticeListCtrl'
          }
        },
        data: {
          pageTitle: '공지사항',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
        }
      })
      /**
       * 알림톡
       */
      .state('main.altalk_list', {
        url: '/altalk/list',
        views: {
          'main.altalk_list': {
            templateUrl: 'views/alimtalk/list.html',
            controller: 'AltalkListCtrl'
          }
        },
        data: {
          pageTitle: '알림톡 발송 관리',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          },
          // 그리드 설정 정보
          gridInfo: function(commonModel) {
            return commonModel.getGridInfo('altalk_list_grid');
          },
          // 템플릿 리스트
          templateList: function(atalkModel) {
            return atalkModel.templateList({ sol_no: userInfo.user.sol_no, talk_id: 'ALL' });
          }
        }
      })

      /**
       * 1:1 문의
       */
      .state('main.question_list', {
        url: '/question/list/:openQuestionModal/:cs_no',
        views: {
          'main.question_list': {
            templateUrl: 'views/question/list.html',
            controller: 'QuestionListCtrl'
          }
        },
        data: {
          pageTitle: '1:1문의',
          permissions: {
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          $uibModalInstance: function() {
            return '';
          }
        }
      })

      /**
       * 주문SKU매칭내역
       */
      .state('main.log_map_history', {
        url: '/log/map_history/:work_no',
        views: {
          'main.log_map_history': {
            templateUrl: 'views/log/map_history.html',
            controller: 'LogMapHistoryCtrl',
          }
        },
        data: {
          pageTitle: '주문SKU매칭내역',
          permissions: {
            only: ['log.mapping'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function(warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 2018-02-27 Daniel
       * 삭제 내역 관리
       */
      .state('main.log_del_history', {
        url: '/log/del_history',
        views: {
          'main.log_del_history': {
            templateUrl: 'views/log/del_history.html',
            controller: 'LogDelHistoryCtrl',
          }
        },
        data: {
          pageTitle: '삭제 내역 관리',
          permissions: {
            only: ['log.delete'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 2018-07-13 noah
       * 엑셀다운로드 내역 관리
       */
      .state('main.log_excel_history', {
        url: '/log/excel_history',
        views: {
          'main.log_excel_history': {
            templateUrl: 'views/log/excel_history.html',
            controller: 'LogExcelHistoryCtrl',
          }
        },
        data: {
          pageTitle: '주문 엑셀다운로드',
          permissions: {
            only: ['log.excel'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 2019-05-15 Amelia
       * 상품자동품절 내역 관리
       */
      .state('main.log_auto_soldout_history', {
        url: '/log/auto_soldout_history',
        views: {
          'main.log_auto_soldout_history': {
            templateUrl: 'views/log/auto_soldout_history.html',
            controller: 'AutoSoldoutHistoryCtrl',
          }
        },
        data: {
          pageTitle: '상품자동품절 내역',
          permissions: {
            only: ['log.auto_soldout'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
        }
      })

      /**
       * 2019-05-14 Amelia
       * 재고할당 내역 관리
       */
      .state('main.log_stock_alloc_history', {
        url: '/log/stock_alloc_history',
        views: {
          'main.log_stock_alloc_history': {
            templateUrl: 'views/log/stock_alloc_history.html',
            controller: 'StockAllocHistoryCtrl',
          }
        },
        data: {
          pageTitle: '재고할당 내역',
          permissions: {
            only: ['log.stock_alloc'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function (systemModel) {
            return systemModel.load();
          },
        }
      })

      /**
       * 바코드 출고 내역관리
       * 2019-06-14 rony
       */
      .state('main.log_barcode_history', {
        url: '/log/barcode_history',
        views: {
          'main.log_barcode_history': {
            templateUrl: 'views/log/barcode_history.html',
            controller: 'LogBarcodeHistoryCtrl',
          }
        },
        data: {
          pageTitle: '바코드 출고 내역',
          permissions: {
            only: ['log.barcode'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          // 창고 리스트 조회
          warehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 환경설정 변경 내역
       * 2019-09-17 Tim
       */
      .state('main.log_configs_history', {
        url: '/log/configs_history',
        views: {
          'main.log_configs_history': {
            templateUrl: 'views/log/configs_history.html',
            controller: 'LogConfigsHistoryCtrl',
          }
        },
        data: {
          pageTitle: '환경설정 변경 내역',
          permissions: {
            only: ['log.configs'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 사용자 접속 내역
       */
      .state('main.user_connect_history', {
        url: '/log/user_connect_history',
        views: {
          'main.user_connect_history': {
            templateUrl: 'views/log/user_connect_history.html',
            controller: 'UserConnectHistoryCtrl',
          }
        },
        data: {
          pageTitle: '사용자 접속 내역',
          permissions: {
            only: ['configs.user+admin'],
            redirectTo: InvalidRedirectTo
          }
        },
        resolve: {
          systemList: function(systemModel) {
            return systemModel.load();
          }
        }
      })

      /**
       * 이벤트 페이지
       */
      .state('main.event_page', {
        url: '/event/:event_name',
        views: {
          'main.event_page': {
            templateUrl: function ($stateParams) {
              return `views/event/${$stateParams.event_name}.html`;
            },
            controller: 'EventPageCtrl',
          }
        },
      });

  }]);