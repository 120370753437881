/**
 * Created by Ally on 2016-11-29.
 */

'use strict';

angular.module('gmpApp')
  .factory('shipmentSVC', function (commonSVC, $rootScope) {
    const commonData = {
      USState: [
        { name: 'ALABAMA', code: 'AL' },
        { name: 'ALASKA', code: 'AK' },
        { name: 'ARIZONA', code: 'AZ' },
        { name: 'ARKANSAS', code: 'AR' },
        { name: 'CALIFORNIA', code: 'CA' },
        { name: 'COLORADO', code: 'CO' },
        { name: 'CONNECTICUT', code: 'CT' },
        { name: 'DELAWARE', code: 'DE' },
        { name: 'FLORIDA', code: 'FL' },
        { name: 'GEORGIA', code: 'GA' },
        { name: 'HAWAII', code: 'HI' },
        { name: 'IDAHO', code: 'ID' },
        { name: 'ILLINOIS', code: 'IL' },
        { name: 'INDIANA', code: 'IN' },
        { name: 'IOWA', code: 'IA' },
        { name: 'KANSAS', code: 'KS' },
        { name: 'KENTUCKY', code: 'KY' },
        { name: 'LOUISIANA', code: 'LA' },
        { name: 'MAINE', code: 'ME' },
        { name: 'MARYLAND', code: 'MD' },
        { name: 'MASSACHUESTTS', code: 'MA' },
        { name: 'MICHIGAN', code: 'MI' },
        { name: 'MINNESOTA', code: 'MN' },
        { name: 'MISSISSIPPI', code: 'MS' },
        { name: 'MISSOURI', code: 'MO' },
        { name: 'MONTANA', code: 'MT' },
        { name: 'NEBRASKA', code: 'NE' },
        { name: 'NEVADA', code: 'NV' },
        { name: 'NEW HAMPSHIRE', code: 'NH' },
        { name: 'NEW JERSEY', code: 'NJ' },
        { name: 'NEW MEXICO', code: 'NM' },
        { name: 'NEW YORK', code: 'NY' },
        { name: 'NORTH CAROLINA', code: 'NC' },
        { name: 'NORTH DAKOTA', code: 'ND' },
        { name: 'OHIO', code: 'OH' },
        { name: 'OKLAHOMA', code: 'OK' },
        { name: 'OREGON', code: 'OR' },
        { name: 'PENNSYLVANIA', code: 'PA' },
        { name: 'RHODE ISLAND', code: 'RI' },
        { name: 'SOUTH CAROLINA', code: 'SC' },
        { name: 'SOUTH DAKAOTA', code: 'SD' },
        { name: 'TENNESSEE', code: 'TN' },
        { name: 'TEXAS', code: 'TX' },
        { name: 'UTAH', code: 'UT' },
        { name: 'VERMONT', code: 'VT' },
        { name: 'VIRGINIA', code: 'VA' },
        { name: 'WASHINGTON', code: 'WA' },
        { name: 'WEST VIRGINIA', code: 'WV' },
        { name: 'WISCONSIN', code: 'WI' },
        { name: 'WYOMING', code: 'WY' }
      ],
      delayDeliveryMessage: {
        info: `<b>아래 조건에 해당하는 경우에는 배송지연 처리가 적용되지 않습니다.</b>
        <br />- 대상 쇼핑몰 중 이미 배송지연 처리가 완료된 주문
        <br />  <span style="font-size:0.9em;margin-left:10px">(노출항목설정 > 배송지연여부 ON > 해당 항목에서 확인가능)</span>
        <br />- 직접 입력 주문, 복사된 주문
        <br />- 기능 미지원 쇼핑몰 (<a href="https://playapi.api.plto.com/restApi/plto/support-shop-excel?type=gmp" target="_blank">쇼핑몰별 지원 기능 정보</a>)
        <br />- 스마트스토어 '판매자 희망일 도착보장' 상품 주문
        ${$rootScope.userProfileCheck && $rootScope.userProfileCheck('sol_ser', 'cal_state_per_prod', 'like') ? '<br />- ESM+(옥션, 지마켓) 주문의 "추가구매옵션"이 원주문과 분리되어 개별 발송되는 경우' : ''}`,
        confirm: '배송지연 전송 작업이 가능한 건만 선택해두기',
        fail: '배송지연 전송 작업이 가능한 주문이 없습니다.'
      },
      shipment: {
        ord_time: commonSVC.getDate(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        pay_time: commonSVC.getDate(new Date(), 'yyyy-MM-dd HH:mm:ss'),

        order_name: '',
        order_id: '',
        order_email: '',
        order_tel: '',
        order_htel: '',

        to_name: '',
        to_name_eng: '',
        to_email: '',
        to_tel: '',
        to_htel: '',
        to_zipcd: '',
        to_addr1: '',
        to_addr2: '',
        to_ctry_cd: '',
        to_city: '',
        to_state: '',

        prod_list: [{ shop_ord_no: '', shop_sale_name: '', shop_sku_cd: '', shop_opt_name: '', sale_cnt: 0, sales: '', shop_supply_price: '', bundle_depot_no: '', convert_sales: 0, sku_list: [{ prod_no: null, depot_no: null, pal_no: null, pack_unit: 1, bundle_avail_yn: true }] }],
        shop_add_opt_name: '',
        prod_add_opt_list: [{ ord_opt_name: '', ord_opt_type: '수동', opt_sale_cnt: 1, sku_cd: '', set_cd: '', sku_list: [{ prod_no: null, depot_no: null, pal_no: null, pack_unit: 1, bundle_avail_yn: true }] }],

        ord_curr_cd: '',
        gift_name: '',
        ship_method: '',
        ship_msg: '',
        ship_cost: '',

        carr_no: '',
        sales_tax: 0,
        convert_sales: 0,
        convert_sales_curr_cd: null,
        shop_cost_price: 0,
        pay_amt: '',
        discount_amt: 0
      },
      currencyList: ['KRW', 'USD', 'JPY', 'YUAN', 'SGD', 'AUD'],
      deliMethodList: ['무료배송', '선결제', '착불', '방문수령', '퀵배송', '일반우편', '설치배송', '조건부배송', '기타'],
      // 옵션 초기화 정보
      initOpt: {
        // 기본옵션
        prod: {
          bundle_avail_yn: null,
          prod_img: null,
          prod_name: null,
          prod_name_pack: null,
          prod_no: null,
          prod_pack: '',
          set_name: null,
          set_no: null,
          set_cd: null,
          set_pack_unit: null,
          sku_cd: null,
          sku_pack: null,
          pack_unit: '',
          total_cnt: '',
          stock_cd: '',
          barcode: '',
          attri_pack: ''
        },
        // 추가구매옵션
        opt: {
          bundle_avail_yn: null,
          cost_price: 0,
          pack_unit: '',
          set_no: null,
          set_cd: null,
          set_pack_uniq: null,
          sku_cd: '',
          opt_prod_name: '',
          opt_sku_cd_list: '',
          prod_attr: '',
          stock_cd: '',
          barcode: '',
          sku_list: [
            {
              prod_no: null,
              depot_no: null,
              pal_no: null,
              pack_unit: null
            }
          ],
          total_cnt: ''
        }
      },
      // 접속 로그를 남기는 페이지
      connectLogPages: [
        'main.order_shipment_payment_list',
        'main.order_shipment_order_list',
        'main.order_shipment_unstoring_list',
        'main.order_shipment_delivery_list',
        'main.order_shipment_integrated_list',
        'main.order_shipment_as_list',
        'main.order_shipment_claim_list',
        'main.order_shipment_ebaydepot_list',
        'main.order_shipment_agency_list',
      ]
    };
    const solData = {
      integratedExcel: [
        { header: '주문상태', key: 'ord_status', select: false, variable: '#주문상태', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '출고가능여부', key: 'ship_avail_yn', select: false, variable: '#출고가능여부', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '쇼핑몰', key: 'shop_name', select: true, variable: '#쇼핑몰', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '계정', key: 'shop_id', select: true, variable: '#계정', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '별칭', key: 'seller_nick', select: true, variable: '#별칭', key_settings: '0', width: 15, isDomestic: true, isGlobal: true },
        { header: '쇼핑몰상품URL', key: 'shop_prod_url', select: true, variable: '#쇼핑몰상품URL', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '쇼핑몰주문번호', key: 'shop_ord_no', select: true, variable: '#쇼핑몰주문번호', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '쇼핑몰원주문번호', key: 'shop_ord_no_real', select: true, variable: '#쇼핑몰원주문번호', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '주문수집일', key: 'wdate', select: true, variable: '#주문수집일', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true, variable: '#쇼핑몰상품코드', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '교환여부', key: 'exchange_ord_yn', select: true, variable: '#교환여부', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '온라인상품명', key: 'shop_sale_name', select: true, variable: '#온라인상품명', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '옵션', key: 'shop_opt_name', select: true, variable: '#옵션', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '주문수량', key: 'sale_cnt', select: true, default: 1, variable: '#주문수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '추가구매옵션', key: 'add_opt_name', select: true, variable: '#추가구매옵션', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '추가구매주문수량', key: 'add_opt_sale_cnt', select: true, default: 1, variable: '#추가구매주문수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '주문자명', key: 'order_name', select: true, variable: '#주문자명', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '주문자ID', key: 'order_id', select: true, variable: '#주문자ID', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '수령자명', key: 'to_name', select: true, variable: '#수령자명', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '배송메세지', key: 'ship_msg', select: true, variable: '#배송메세지', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '기타메세지', key: 'notice_msg', select: false, variable: '#기타메세지', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '우편번호', key: 'to_zipcd', select: true, variable: '#우편번호', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '주소', key: 'to_addr', select: true, variable: '#주소', changable: false, key_settings: '0', width: 80, isDomestic: true, isGlobal: true },
        { header: '택배사', key: 'carr_name', select: true, variable: '#택배사', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '택배사코드', key: 'carr_no', select: false, variable: '#택배사코드', key_settings: '0', width: 15, isDomestic: true, isGlobal: true },
        { header: '운송장번호', key: 'invoice_no', select: true, variable: '#운송장번호', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '송장입력일', key: 'invoice_time', select: false, default: 0, variable: '#송장입력일', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '송장전송일', key: 'invoice_send_time', select: true, variable: '#송장전송일', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '금액', key: 'sales', select: true, default: 0, variable: '#금액', key_settings: '0', width: 30, isDomestic: true, isGlobal: false },
        { header: '배송비', key: 'ship_cost', select: true, default: 0, variable: '#배송비', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '규칙적용사은품', key: 'gift_pack', select: false, variable: '#규칙적용사은품', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '사은품', key: 'gift_name', select: false, variable: '#사은품', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '배송지연여부', key: 'ship_delay_yn', select: true, type: 'boolean', variable: '#배송지연여부', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '묶음주문여부', key: 'multi_bundle_yn', select: true, type: 'boolean', variable: '#묶음주문여부', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '발송예정일', key: 'ship_plan_date', select: true, variable: '#발송예정일', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '묶음번호', key: 'bundle_no', select: true, variable: '#묶음번호', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '주문일', key: 'ord_time', select: false, variable: '#주문일', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '결제완료일', key: 'pay_time', select: false, variable: '#결제완료일', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '상태변경일', key: 'ord_status_mdate', select: false, variable: '#상태변경일', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '주문확인일', key: 'ord_confirm_time', select: false, variable: '#주문확인일', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '출고완료일', key: 'out_time', select: false, variable: '#출고완료일', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '배송완료일', key: 'ship_com_time', select: false, variable: '#배송완료일', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '국가', key: 'to_ctry_cd', select: false, variable: '#국가', key_settings: '0', width: 15, isDomestic: true, isGlobal: true },
        { header: '배송처', key: 'depot_name', select: false, variable: '#배송처', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '주문자전화번호', key: 'order_tel', select: false, variable: '#주문자전화번호', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '주문자휴대폰번호', key: 'order_htel', select: false, variable: '#주문자휴대폰번호', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '수령자전화번호', key: 'to_tel', select: false, variable: '#수령자전화번호', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '수령자휴대폰번호', key: 'to_htel', select: false, variable: '#수령자휴대폰번호', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '사용자태그', key: 'tag_name', select: false, default: 0, variable: '#사용자태그', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '판매자관리코드', key: 'c_sale_cd', select: false, variable: '#판매자관리코드', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: 'SKU코드', key: 'sku_cd', select: false, variable: '#SKU코드', key_settings: '0', width: 15, isDomestic: true, isGlobal: true },
        { header: 'SKU코드및출고수량', key: 'sku_cd_total', select: false, variable: '#SKU코드및출고수량', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '추가구매SKU코드', key: 'add_opt_sku_cd', select: false, variable: '#추가구매SKU코드', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '추가구매SKU코드및출고수량', key: 'add_opt_sku_cd_total', select: false, variable: '#추가구매SKU코드및출고수량', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '재고관리코드', key: 'stock_cd', select: false, variable: '#재고관리코드', key_settings: '0', width: 15, isDomestic: true, isGlobal: true },
        { header: '추가구매재고관리코드', key: 'add_opt_stock_cd', select: false, variable: '#추가구매재고관리코드', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: 'SKU상품명', key: 'prod_name', select: false, variable: '#SKU상품명', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '추가구매SKU상품명', key: 'add_opt_prod_name', select: false, variable: '#추가구매SKU상품명', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: 'SKU상품속성', key: 'prod_attri', select: false, variable: '#SKU상품속성', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '추가구매SKU상품속성', key: 'add_opt_prod_attri', select: false, variable: '#추가구매SKU상품속성', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '건별출고수량', key: 'pack_unit', select: false, default: 0, variable: '#건별출고수량', key_settings: '0', width: 15, isDomestic: true, isGlobal: true },
        { header: '추가구매건별출고수량', key: 'add_opt_pack_unit', select: false, default: 0, variable: '#추가구매건별출고수량', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '세트상품명', key: 'set_name', select: false, default: 0, variable: '#세트상품명', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '추가구매세트상품명', key: 'add_opt_set_name', select: false, default: 0, variable: '#추가구매세트상품명', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '세트코드', key: 'set_cd', select: false, default: 0, variable: '#세트코드', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '추가구매세트코드', key: 'add_opt_set_cd', select: false, default: 0, variable: '#추가구매세트코드', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '세트건별출고수량', key: 'set_pack_unit', select: false, default: 0, variable: '#세트건별출고수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '추가구매세트건별출고수량', key: 'add_opt_set_pack_unit', select: false, default: 0, variable: '#추가구매세트건별출고수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '세트총출고수량', key: 'set_total', select: false, default: 0, variable: '#세트총출고수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '추가구매세트총출고수량', key: 'add_opt_set_total', select: false, default: 0, variable: '#추가구매세트총출고수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '결제통화', key: 'ord_curr_cd', select: false, variable: '#결제통화', key_settings: '0', width: 10, isDomestic: true, isGlobal: false },
        { header: '배송방법', key: 'ship_method', select: false, variable: '#배송방법', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '원가', key: 'shop_cost_price', select: false, default: 0, variable: '#원가', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '공급가', key: 'shop_supply_price', select: false, default: 0, variable: '#공급가', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '개인통관번호', key: 'gprivate_no', select: false, variable: '#개인통관번호', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '영문이름', key: 'to_name_eng', select: false, variable: '#영문이름', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '바코드', key: 'barcode', select: false, variable: '#바코드', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '추가구매바코드', key: 'add_opt_barcode', select: false, variable: '#추가구매바코드', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '매입처', key: 'supp_no', select: false, variable: '#매입처', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '엑셀다운일시', key: 'last_exceldown_time', select: false, variable: '#엑셀다운일시', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '클레임사유', key: 'ord_status_msg', select: false, variable: '#클레임사유', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '총출고수량', key: 'sale_cnt_total', select: false, variable: '#총출고수량', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '추가구매총출고수량', key: 'add_opt_sale_cnt_total', select: false, variable: '#추가구매총출고수량', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '메모', key: 'memo', select: false, variable: '#메모', key_settings: '0', width: 10, isDomestic: true, isGlobal: true },
        { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false, variable: '#변경전 묶음번호', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '배송번호', key: 'shop_ship_no', select: false, variable: '#배송번호', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '희망배송일', key: 'ship_hope_time', select: false, variable: '#희망배송일', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '추가메세지', key: 'order_msg', select: false, variable: '#추가메세지', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '매입처주소', key: 'supp_addrs', select: false, variable: '#매입처주소', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
        { header: '반송장번호', key: 'return_invoice_no', select: false, variable: '#반송장번호', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '반품/교환접수요청일', key: 'return_wdate', select: false, variable: '#반품/교환접수요청일', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '매칭여부', key: 'map_yn', select: false, variable: '#매칭여부', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: 'SKU상품과세여부', key: 'tax_type', select: false, variable: '#SKU상품과세여부', key_settings: '0', width: 25, isDomestic: true, isGlobal: false },
        { header: '실결제금액', key: 'pay_amt', select: false, default: 0, variable: '#실결제금액', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '할인금액', key: 'discount_amt', select: false, default: 0, variable: '#할인금액', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '쇼핑몰부담할인액', key: 'shop_discount', select: false, default: 0, variable: '#쇼핑몰부담할인액', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '판매자부담할인액', key: 'seller_discount', select: false, default: 0, variable: '#판매자부담할인액', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '쿠폰할인액', key: 'coupon_discount', select: false, default: 0, variable: '#쿠폰할인액', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '포인트할인액', key: 'point_discount', select: false, default: 0, variable: '#포인트할인액', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        ($rootScope.osse_sol && { header: '낱본수량', key: 'sum_unit_cnt', select: false, default: 0, variable: '#낱본수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: false }),
        // 여기부터는 글로벌 항목
        { header: '쇼핑몰주문번호2', key: 'misc13', select: true, variable: '#쇼핑몰상품코드2', key_settings: '0', width: 30, isDomestic: false, isGlobal: true },
        { header: '무게(g)', key: 'misc16', select: false, variable: '#무게(g)', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '발송기한', key: 'misc10', select: false, variable: '#발송기한', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '기준통화', key: 'ord_curr_cd', select: false, variable: '#기준통화', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '수령자 이메일', key: 'to_email', select: false, variable: '#수령자 이메일', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '수령자 국가', key: 'to_ctry_cd', select: false, variable: '#수령자 국가', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '금액', key: 'sales', select: false, variable: '#금액', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '쇼핑몰 판매금액', key: 'shop_sales', select: false, variable: '#쇼핑몰 판매금액', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '쇼핑몰 판매단가', key: 'sales_unit', select: false, variable: '#쇼핑몰 판매단가', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '재조국(원산지)', key: 'misc15', select: false, variable: '#재조국(원산지)', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: 'HS코드', key: 'misc14', select: false, variable: '#HS코드', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '출고지시일', key: 'out_order_time', select: false, variable: '#출고지시일', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '해외택배사', key: 'global_carr_name', select: false, variable: '#해외택배사', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '트래킹번호', key: 'global_invoice_no', select: false, variable: '#트래킹번호', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '국내택배사', key: 'carr_name', select: false, variable: '#국내택배사', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '국내운송장번호', key: 'invoice_no', select: false, variable: '#국내운송장번호', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '송장 출력여부', key: 'invoice_print_time', select: false, variable: '#송장 출력여부', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '패키지번호', key: 'pa_bundle_no', select: false, variable: '#패키지번호', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '출고완료일', key: 'out_time', select: false, variable: '#출고완료일', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '환불예상금액', key: 'claim_refund_sales', select: false, variable: '#환불예상금액', key_settings: '0', width: 25, isDomestic: false, isGlobal: true },
        { header: '배송방법', key: 'misc12', select: false, variable: '#배송방법', key_settings: '0', width: 20, isDomestic: false, isGlobal: true },
      ],
      consignmentTotalExcel: [
        { header: '배송처코드', key: 'depot_no', select: true, variable: '#배송처코드', key_settings: '0', width: 15 },
        { header: '배송처명', key: 'depot_name', select: true, variable: '#배송처명', key_settings: '0', width: 15 },
        { header: '지급주기', key: 'depot_sett_period', select: true, variable: '#지급주기', key_settings: '0', width: 15 },
        { header: '주문일', key: 'ord_time', select: true, variable: '#주문일', key_settings: '0', width: 15 },
        { header: '출고일', key: 'out_time', select: true, variable: '#출고완료일', key_settings: '0', width: 25 },
        { header: '반품완료일', key: 'claim_com_time', select: true, variable: '#반품완료일', key_settings: '0', width: 25 },
        { header: '교환완료일', key: 'exchange_com_time', select: true, variable: '#교환완료일', key_settings: '0', width: 25 },
        { header: '매출기준일', key: 'sales_standard_time', select: true, variable: '#매출기준일', key_settings: '0', width: 25 },
        { header: '지급예정일', key: 'depot_sett_plan_date', select: true, variable: '#지급예정일', key_settings: '0', width: 25 },
        { header: '쇼핑몰', key: 'shop_name', select: true, variable: '#쇼핑몰', key_settings: '0', width: 10 },
        { header: '수령자명', key: 'to_name', select: true, variable: '#수령자명', key_settings: '0', width: 20 },
        { header: '쇼핑몰주문번호', key: 'shop_ord_no', select: true, variable: '#쇼핑몰주문번호', key_settings: '0', width: 20 },
        { header: '판매구분', key: 'sales_type', select: true, variable: '#판매구분', key_settings: '0', width: 20 },
        { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true, variable: '#쇼핑몰상품코드', key_settings: '0', width: 30 },
        { header: '수량', key: 'sale_cnt', select: true, default: 1, variable: '#주문수량', key_settings: '0', width: 20 },
        { header: '원가', key: 'shop_cost_price', select: true, default: 0, variable: '#원가', key_settings: '0', width: 20 },
        { header: '배송비', key: 'ship_cost', select: true, default: 0, variable: '#배송비', key_settings: '0', width: 40 },
      ],
      consignmentAggregationExcel: [
        { header: '배송처코드', key: 'depot_no', select: true, variable: '#배송처코드', key_settings: '0', width: 15 },
        { header: '배송처명', key: 'depot_name', select: true, variable: '#배송처명', key_settings: '0', width: 15 },
        { header: '지급주기', key: 'depot_sett_period', select: true, variable: '#지급주기', key_settings: '0', width: 15 },
        { header: '원가총액', key: 'shop_cost_price', select: true, variable: '#원가총액', key_settings: '0', width: 15 },
        { header: '배송비총액', key: 'ship_cost', select: true, variable: '#배송비총액', key_settings: '0', width: 15 },
      ],
      depotSelectedCols: [
        { header: '묶음번호', key: 'bundle_no', select: true, variable: '#묶음번호', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '택배사', key: 'carr_name', select: true, variable: '#택배사', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '운송장번호', key: 'invoice_no', select: true, variable: '#운송장번호', key_settings: '0', width: 20, isDomestic: true, isGlobal: false },
        { header: '온라인상품명', key: 'shop_sale_name', select: true, variable: '#온라인상품명', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '주문수량', key: 'sale_cnt', select: true, default: 1, variable: '#주문수량', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '수령자명', key: 'to_name', select: true, variable: '#수령자명', key_settings: '0', width: 20, isDomestic: true, isGlobal: true },
        { header: '사은품', key: 'gift_name', select: false, variable: '#사은품', key_settings: '0', width: 30, isDomestic: true, isGlobal: true },
        { header: '주소', key: 'to_addr', select: true, variable: '#주소', changable: false, key_settings: '0', width: 80, isDomestic: true, isGlobal: true },
        { header: '수령자휴대폰번호', key: 'to_htel', select: false, variable: '#수령자휴대폰번호', key_settings: '0', width: 25, isDomestic: true, isGlobal: true },
        { header: '배송메세지', key: 'ship_msg', select: true, variable: '#배송메세지', key_settings: '0', width: 40, isDomestic: true, isGlobal: true },
      ],
      canDeliDelayShop: [ // 배송지연이 되는 쇼핑몰 정보
        'A006', // G마켓 (2.0)
        'A001', // 옥션 (2.0)
        'A100', // 롯데아이몰
        'A112', // 11번가
        'A027', // 인터파크
        'A077', // 스마트스토어
        'A011', // GSSHOP
        'A140', // 홈쇼핑모아(버즈니)
        // 'A017', // AK몰
        'A524', // 롯데온
        'P038', // 롯데백화점(신)
        'B513', // 베네피아
        'B688', // 카카오톡 스토어
        // 'B378', // 쿠팡
        'B394',  // 갤러리아
        'A012',  // 롯데닷컴
        'B700',  // 카카오 선물하기
        'A525',  // LFmall
        'B502',  // 홈플러스
        'A032',  // SSG통합
        'A076',  // SSG오픈마켓
        'B669',   // 머스트잇
        'A175',  // 티몬월드
        'A178',  // 위메프플러스
        'A179',  // 인팍쇼핑
      ],
      cantDelayBeforeConfirm: [ // 발주 전 배송지연이 되지 않는 쇼핑몰 정보
        'A032',  // SSG통합
        'A076'  // SSG오픈마켓
      ],
      delayCodes: [ // 발송 지연 사유
        { code: '01', value: '상품준비중' },
        { code: '02', value: '고객요청' },
        { code: '03', value: '주문제작' },
        { code: '99', value: '기타' },
      ],
      excelFieldList: {
        payment: [
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '주문상태', key: 'ord_status', select: false },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '국가', key: 'to_ctry_cd', select: false },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: true },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: 'SKU코드', key: 'sku_cd', select: false },
          { header: '사은품', key: 'gift_name', select: false },
          { header: '결제통화', key: 'ord_curr_cd', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false }
        ],
        order: [
          { header: '묶음번호', key: 'bundle_no', select: true },
          { header: '출고가능여부', key: 'ship_avail_yn', select: true, type: 'boolean' },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '교환여부', key: 'exchange_ord_yn', select: true, type: 'boolean', noneHeader: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '발송예정일', key: 'ship_plan_date', select: true },
          { header: '매칭여부', key: 'map_yn', select: true, type: 'boolean' },
          { header: '배송지연여부', key: 'ship_delay_yn', select: true, type: 'boolean' },
          { header: '묶음주문여부', key: 'multi_bundle_yn', select: true, type: 'boolean' },
          { header: '주문상태', key: 'ord_status', select: false },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '주문확인일', key: 'ord_confirm_time', select: false },
          { header: '국가', key: 'to_ctry_cd', select: false },
          { header: '배송처', key: 'depot_name', select: false },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: false },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: 'SKU코드', key: 'sku_cd', select: false },
          { header: '재고관리코드', key: 'stock_cd', select: false },
          { header: 'SKU상품명', key: 'prod_data', select: false },
          { header: '건별출고수량', key: 'pack_unit', select: false },
          { header: '사은품', key: 'gift_name', select: false },
          { header: '결제통화', key: 'ord_curr_cd', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '개인통관번호', key: 'gprivate_no', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false },
          { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false },
          { header: '배송번호', key: 'shop_ship_no', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '추가메세지', key: 'order_msg', select: false }
        ],
        unstoring: [
          { header: '묶음번호', key: 'bundle_no', select: true },
          { header: '출고가능여부', key: 'ship_avail_yn', select: true, type: 'boolean' },
          { header: '출고상태', key: 'ord_status', select: true },
          { header: '출고 지시일', key: 'out_order_time', select: true },
          { header: 'SKU코드', key: 'sku_cd', select: true },
          { header: '재고관리코드', key: 'stock_cd', select: false },
          { header: 'SKU상품명', key: 'prod_data', select: true },
          { header: '건별출고수량', key: 'pack_unit', select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '택배사코드', key: 'carr_no', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '송장입력일', key: 'invoice_time', select: false },
          { header: '발송예정일', key: 'ship_plan_date', select: true },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '교환여부', key: 'exchange_ord_yn', select: true, type: 'boolean', noneHeader: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '총출고수량', key: 'sale_cnt_total', select: false },       // 총출고수량 추가. 안국건강건으로 찬희님 요청 2018-06-15 rony
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '수령자 주소', key: 'to_addr', select: true },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: true },
          { header: '국가', key: 'to_ctry_cd', select: true },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '규칙적용사은품', key: 'gift_pack', select: true },
          { header: '사은품', key: 'gift_name', select: true },
          { header: '배송방법', key: 'ship_method', select: true },
          { header: '배송비', key: 'ship_cost', select: false },
          { header: '출고완료일', key: 'out_time', select: false },
          { header: '주문수집일', key: 'wdate', select: false },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '매칭여부', key: 'map_yn', select: true, type: 'boolean' },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '주문확인일', key: 'ord_confirm_time', select: false },
          { header: '금액', key: 'sales', select: false },
          { header: '배송지연여부', key: 'ship_delay_yn', select: false, type: 'boolean' },
          { header: '묶음주문여부', key: 'multi_bundle_yn', select: true, type: 'boolean' },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: '결제통화', key: 'ord_curr_cd', select: false },
          { header: '바코드', key: 'barcode', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '개인통관번호', key: 'gprivate_no', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false },
          { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false },
          { header: '배송번호', key: 'shop_ship_no', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '추가메세지', key: 'order_msg', select: false },
        ],
        delivery: [
          { header: '묶음번호', key: 'bundle_no', select: true },
          { header: '매칭여부', key: 'map_yn', select: true, type: 'boolean' },
          { header: '주문상태', key: 'ord_status', select: true },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '송장입력일', key: 'invoice_time', select: false },
          { header: '송장 전송일', key: 'invoice_send_time', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '규칙적용사은품', key: 'gift_pack', select: true },
          { header: '사은품', key: 'gift_name', select: true },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '주문확인일', key: 'ord_confirm_time', select: false },
          { header: '출고완료일', key: 'out_time', select: false },
          { header: '국가', key: 'to_ctry_cd', select: false },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: false },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: 'SKU코드', key: 'sku_cd', select: false },
          { header: '재고관리코드', key: 'stock_cd', select: false },
          { header: 'SKU상품명', key: 'prod_data', select: false },
          { header: '건별출고수량', key: 'pack_unit', select: false },
          { header: '결제통화', key: 'ord_curr_cd', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '배송지연여부', key: 'ship_delay_yn', select: false, type: 'boolean' },
          { header: '묶음주문여부', key: 'multi_bundle_yn', select: true, type: 'boolean' },
          { header: '발송예정일', key: 'ship_plan_date', select: false },
          { header: '교환여부', key: 'exchange_yn', select: false, type: 'boolean' },
          { header: '개인통관번호', key: 'gprivate_no', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false },
          { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false },
          { header: '배송번호', key: 'shop_ship_no', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '추가메세지', key: 'order_msg', select: false },
        ],
        integrated: [
          { header: '주문상태', key: 'ord_status', select: true },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '교환여부', key: 'exchange_ord_yn', select: true, type: 'boolean', noneHeader: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '송장입력일', key: 'invoice_time', select: false },
          { header: '송장 전송일', key: 'invoice_send_time', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '규칙적용사은품', key: 'gift_pack', select: true },
          { header: '사은품', key: 'gift_name', select: true },
          { header: '배송지연여부', key: 'ship_delay_yn', select: true, type: 'boolean' },
          { header: '묶음주문여부', key: 'multi_bundle_yn', select: true, type: 'boolean' },
          { header: '발송예정일', key: 'ship_plan_date', select: true },
          { header: '묶음번호', key: 'bundle_no', select: false },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '주문확인일', key: 'ord_confirm_time', select: false },
          { header: '출고완료일', key: 'out_time', select: false },
          { header: '국가', key: 'to_ctry_cd', select: false },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: false },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: 'SKU코드', key: 'sku_cd', select: false },
          { header: '재고관리코드', key: 'stock_cd', select: false },
          { header: 'SKU상품명', key: 'prod_data', select: false },
          { header: '건별출고수량', key: 'pack_unit', select: false },
          { header: '결제통화', key: 'ord_curr_cd', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '개인통관번호', key: 'gprivate_no', select: false },
          { header: '바코드', key: 'barcode', select: false },
          { header: '매입처', key: 'supp_no', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false },
          { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false },
        ],
        fulfillment: [
          { header: '주문상태', key: 'ord_status', select: true },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '교환여부', key: 'exchange_ord_yn', select: true, type: 'boolean', noneHeader: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '송장 전송일', key: 'invoice_send_time', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '발송예정일', key: 'ship_plan_date', select: true },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '주문확인일', key: 'ord_confirm_time', select: false },
          { header: '출고완료일', key: 'out_time', select: false },
          { header: '국가', key: 'to_ctry_cd', select: false },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '바코드', key: 'barcode', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false }
        ],
        claim: [
          { header: '주문상태', key: 'ord_status', select: true },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '송장 전송일', key: 'invoice_send_time', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '규칙적용사은품', key: 'gift_pack', select: true },
          { header: '사은품', key: 'gift_name', select: true },
          { header: '배송지연여부', key: 'ship_delay_yn', select: true, type: 'boolean' },
          { header: '묶음주문여부', key: 'multi_bundle_yn', select: true, type: 'boolean' },
          { header: '발송예정일', key: 'ship_plan_date', select: true },
          { header: '묶음번호', key: 'bundle_no', select: false },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '주문확인일', key: 'ord_confirm_time', select: false },
          { header: '출고완료일', key: 'out_time', select: false },
          { header: '국가', key: 'to_ctry_cd', select: false },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: false },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: 'SKU코드', key: 'sku_cd', select: false },
          { header: '재고관리코드', key: 'stock_cd', select: false },
          { header: 'SKU상품명', key: 'prod_data', select: false },
          { header: '건별출고수량', key: 'pack_unit', select: false },
          { header: '결제통화', key: 'ord_curr_cd', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '개인통관번호', key: 'gprivate_no', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false },
          { header: '클레임사유', key: 'ord_status_msg', select: false },
          { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false }
        ],

        // G마켓 물류
        ebaydepot: [
          { header: '묶음번호', key: 'bundle_no', select: true },
          { header: '주문상태', key: 'ord_status', select: true },
          { header: '연동상태', key: 'ebaydepot_link_status', select: true },
          { header: 'G마켓 물류 발송정보 등록일', key: 'ebaydepot_shipinfo_wdate', select: true },
          { header: 'G마켓 물류 발송요청일', key: 'ebaydepot_ship_req_date', select: true },
          { header: '매칭여부', key: 'map_yn', select: true, type: 'boolean' },
          { header: '세트코드', key: 'set_cd', select: false },
          { header: 'SKU코드', key: 'sku_cd', select: true },
          { header: 'SKU상품명', key: 'prod_data', select: false },
          { header: '재고관리코드', key: 'stock_cd', select: false },
          { header: '건별출고수량', key: 'pack_unit', select: true },
          { header: '합포장여부', key: 'bundle_avail_yn', select: true },
          { header: '쇼핑몰(계정)', key: 'shop_name_id', select: true },
          { header: 'G마켓 물류 연동 주문번호', key: 'ebaydepot_uniq', select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '추가구매옵션 수량', key: 'shop_add_opt_name', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '주문자(ID)', key: 'order_name_id', select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: true },
          { header: '우편번호', key: 'to_zipcd', select: false },
          { header: '주소', key: 'to_addr', select: true },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '규칙적용사은품', key: 'gift_pack', select: true },
          { header: '사은품', key: 'gift_name', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '발송예정일', key: 'ship_plan_date', select: false },
          { header: '배송지연여부', key: 'ship_delay_yn', select: false, type: 'boolean' },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: '배송비', key: 'ship_cost', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '바코드', key: 'barcode', select: false },
          { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false },
          { header: '배송번호', key: 'shop_ship_no', select: false },
          { header: '실결제금액', key: 'pay_amt', select: false },
          { header: '할인금액', key: 'discount_amt', select: false },
          { header: '쇼핑몰부담할인액', key: 'shop_discount', select: false },
          { header: '판매자부담할인액', key: 'seller_discount', select: false },
          { header: '쿠폰할인액', key: 'coupon_discount', select: false },
          { header: '포인트할인액', key: 'point_discount', select: false },
          { header: '원주문번호', key: 'shop_ord_no_reql', select: false }
        ],

        // G마켓물류 발송요청 양식
        ebaydepotRequest: [
          { header: '사이트', key: 'site_code', select: true },
          { header: '장바구니번호', key: 'bundle_no', select: true },
          { header: '합포장여부', key: 'bundle_avail_yn', select: true },
          { header: '상품번호', key: 'shop_sale_no', select: true },
          { header: '주문번호', key: 'ebaydepot_uniq', select: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '주문옵션', key: 'shop_opt_name', select: true },
          { header: 'SKU번호1', key: 'sku_cd1', select: true },
          { header: 'SKU수량1', key: 'sku_cnt1', select: true },
          { header: 'SKU번호2', key: 'sku_cd2', select: true },
          { header: 'SKU수량2', key: 'sku_cnt2', select: true },
          { header: 'SKU번호3', key: 'sku_cd3', select: true },
          { header: 'SKU수량3', key: 'sku_cnt3', select: true },
          { header: 'SKU번호4', key: 'sku_cd4', select: true },
          { header: 'SKU수량4', key: 'sku_cnt4', select: true },
          { header: 'SKU번호5', key: 'sku_cd5', select: true },
          { header: 'SKU수량5', key: 'sku_cnt5', select: true },
          { header: '수령인명', key: 'to_name', select: true },
          { header: '수령인 휴대폰', key: 'to_htel', select: true },
          { header: '수령인 전화번호', key: 'to_tel', select: true },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '기본주소', key: 'to_addr1', select: true },
          { header: '상세주소', key: 'to_addr2', select: true },
        ],

        // G마켓물류 이노서브 더존양식
        ebaydepotInosub: [
          { header: '송장번호', key: 'invoice_no', select: true },
          { header: '주문번호', key: 'ord_no', select: true },
          { header: '고객명', key: 'to_name', select: true },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '전화', key: 'to_tel', select: true },
          { header: '핸드폰', key: 'to_htel', select: true },
          { header: '로지스바코드', key: 'barcode', select: true },
          { header: '제품명', key: 'shop_sale_name', select: true },
          { header: '로지스수량', key: 'out_cnt', select: true },
          { header: '비고', key: 'ship_msg', select: true },
          { header: '주문사이트', key: 'shop_name_id', select: true },
          { header: '플레이오토주문번호', key: 'bundle_no', select: true },
          { header: '확정상품명', key: 'prod_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '더존입력가', key: 'douzon_input', select: true },
          { header: '고객결제금', key: 'sales', select: true },
          { header: '정산예정금', key: 'shop_supply_price', select: true },
          { header: '정산예정금합계', key: 'shop_supply_price_sum', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '구매자명', key: 'order_name', select: true },
          { header: '구매자ID', key: 'order_id', select: true },
          { header: '주문번호', key: 'shop_ord_no', select: true },
          { header: '주문내용', key: 'shop_sale_name2', select: true },
          { header: '판매일', key: 'ord_time', select: true },
          { header: '정산일', key: 'sett_date', select: true },
          { header: '택배비', key: 'ship_method', select: true },
          { header: '제품코드', key: 'c_sale_cd', select: true },
          { header: '원수량', key: 'opt_sale_cnt', select: true },
          { header: '세트분리여부', key: 'opt_sale_cnt', select: true },
          { header: '확정상품명', key: 'prod_name2', select: true },
          { header: '수집일시', key: 'wdate', select: true },
          { header: '상품코드', key: 'shop_sale_no', select: true }
        ],

        // 대리점 주문
        agency: [
          { header: '대리점(특약점)', key: 'company_name', select: true },
          { header: '주문상태', key: 'ord_status', select: true },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '모델번호', key: 'model_no', select: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
        ],
        matchingRule: [
          { header: '쇼핑몰(계정)', key: 'shop_name_shop_id', select: true },
          { header: '판매자관리코드', key: 'c_sale_cd', select: true },
          { header: '쇼핑몰상품코드', key: 'shop_sale_no', select: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '주문 옵션명', key: 'ord_opt_name', select: true },
          { header: 'SKU', key: 'sku_cd', select: true },
          { header: '매칭 상품명', key: 'map_name', select: true },
          { header: '세트코드', key: 'set_cd', select: true },
          { header: '출고수량', key: 'map_cnt', select: true },
          { header: '속성', key: 'attri', select: true },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '세트 하위상품', key: 'set_prod', select: true },
          { header: 'ESM마스터상품 여부', key: 'ebay_shop_mas_no', select: true },
        ],

        // 위탁 주문 관리
        consignment: [
          { header: '주문상태', key: 'ord_status', select: true },
          { header: '쇼핑몰(계정)', key: ['shop_name', 'shop_id'], select: true },
          { header: '쇼핑몰 주문번호', key: 'shop_ord_no', select: true },
          { header: '주문수집일', key: 'wdate', select: true },
          { header: '쇼핑몰 상품코드', key: 'shop_sale_no', select: true },
          { header: '교환여부', key: 'exchange_ord_yn', select: true, type: 'boolean', noneHeader: true },
          { header: '온라인 상품명', key: 'shop_sale_name', select: true },
          { header: '옵션', key: 'shop_opt_name', select: true },
          { header: '주문수량', key: 'sale_cnt', select: true },
          { header: '추가구매옵션', key: 'shop_add_opt_name', select: true },
          { header: '주문자(ID)', key: ['order_name', 'order_id'], select: true },
          { header: '수령자명', key: 'to_name', select: true },
          { header: '배송메세지', key: 'ship_msg', select: true },
          { header: '기타메세지', key: 'notice_msg', select: false },
          { header: '우편번호', key: 'to_zipcd', select: true },
          { header: '주소', key: 'to_addr', select: true },
          { header: '택배사', key: 'carr_name', select: true },
          { header: '운송장번호', key: 'invoice_no', select: true },
          { header: '송장 전송일', key: 'invoice_send_time', select: true },
          { header: '금액', key: 'sales', select: true },
          { header: '배송비', key: 'ship_cost', select: true },
          { header: '규칙적용사은품', key: 'gift_pack', select: true },
          { header: '사은품', key: 'gift_name', select: true },
          { header: '배송지연여부', key: 'ship_delay_yn', select: true, type: 'boolean' },
          { header: '묶음주문여부', key: 'multi_bundle_yn', select: true, type: 'boolean' },
          { header: '발송예정일', key: 'ship_plan_date', select: true },
          { header: '묶음번호', key: 'bundle_no', select: false },
          { header: '주문일', key: 'ord_time', select: false },
          { header: '결제완료일', key: 'pay_time', select: false },
          { header: '상태변경일', key: 'ord_status_mdate', select: false },
          { header: '주문확인일', key: 'ord_confirm_time', select: false },
          { header: '출고완료일', key: 'out_time', select: false },
          { header: '국가', key: 'to_ctry_cd', select: false },
          { header: '배송처', key: 'depot_name', select: true },
          { header: '주문자 전화번호', key: 'order_tel', select: false },
          { header: '주문자 휴대폰번호', key: 'order_htel', select: false },
          { header: '수령자 전화번호', key: 'to_tel', select: false },
          { header: '수령자 휴대폰번호', key: 'to_htel', select: false },
          { header: '판매자관리코드', key: 'c_sale_cd', select: false },
          { header: 'SKU코드', key: 'sku_cd', select: false },
          { header: '재고관리코드', key: 'stock_cd', select: false },
          { header: 'SKU상품명', key: 'prod_data', select: false },
          { header: '건별출고수량', key: 'pack_unit', select: false },
          { header: '결제통화', key: 'ord_curr_cd', select: false },
          { header: '희망배송일', key: 'ship_hope_time', select: false },
          { header: '배송방법', key: 'ship_method', select: false },
          { header: '원가', key: 'shop_cost_price', select: false },
          { header: '공급가', key: 'shop_supply_price', select: false },
          { header: '개인통관번호', key: 'gprivate_no', select: false },
          { header: '바코드', key: 'barcode', select: false },
          { header: '매입처', key: 'supp_no', select: false },
          { header: '엑셀 다운일시', key: 'last_exceldown_time', select: false },
          { header: '변경전 묶음번호', key: 'ori_bundle_no', select: false },
          { header: '송장입력일', key: 'invoice_time', select: false },
        ],
      },
      context: {
        payment: [],
      },
      ordStatus: [
        { status_name: '결제대기' },
        { status_name: '결제완료' },
        { status_name: '신규주문' },
        { status_name: '주문보류' },
        { status_name: '출고대기' },
        { status_name: '출고보류' },
        { status_name: '운송장출력' },
        { status_name: '출고완료' },
        { status_name: '발송완료' },
        { status_name: '배송중' },
        { status_name: '배송완료' },
        { status_name: '구매결정' },
        { status_name: '판매완료' },
        { status_name: '취소요청' },
        { status_name: '취소완료' },
        { status_name: '반품요청' },
        { status_name: '반품회수완료' },
        { status_name: '반품완료' },
        { status_name: '교환요청' },
        { status_name: '교환완료' },
        { status_name: '주문재확인' },
        { status_name: '취소' },
        { status_name: '삭제' }
      ],
      ordEbayLinkStatus: [
        { status_name: '발송정보 미등록' },
        { status_name: '발송정보 전송실패' },
        { status_name: '발송정보 수정대기' },
        { status_name: '발송요청 대기' },
        { status_name: '발송요청중' },
        { status_name: '발송요청 완료' },
        { status_name: '발송요청 실패' },
        { status_name: '발송 완료' },
        { status_name: '판매취소 완료' }
      ],
      // 오쎄 특별처리
      // 오쎄는 추가구매옵션 관련 필드 미노출 처리 및 옵션명 필수처리
      customShopExcelFields: [
        { header: '온라인 상품명', key: 'shop_sale_name', required: true, isCriteria: true, enableCriteria: true, criteriaKey: 'pname' },
        { header: '옵션명', key: 'shop_opt_name', required: $rootScope.osse_sol, isCriteria: true, enableCriteria: true, criteriaKey: 'popt' },
        { header: '판매자 관리코드', key: 'c_sale_cd', required: false, isCriteria: false, enableCriteria: false },
        { header: '쇼핑몰 상품코드', key: 'shop_sale_no', required: false, isCriteria: true, enableCriteria: true, criteriaKey: 'pcode' },
        { header: 'SKU코드(세트코드)', key: 'sku_cd_set_cd', required: false, isCriteria: false, enableCriteria: false },
        { header: '배송처 코드', key: 'depot_no', required: false, isCriteria: false, enableCriteria: false },
        { header: '건별출고수량', key: 'pack_unit', required: false, isCriteria: false, enableCriteria: false },
        { header: '주문수량', key: 'sale_cnt', required: true, isCriteria: false, enableCriteria: false },
        ...($rootScope.osse_sol ? [] : [{ header: '추가구매 옵션명', key: 'prod_add_opt_name', required: false, isCriteria: false, enableCriteria: false },
          { header: '추가구매 SKU코드(세트코드)', key: 'prod_add_opt_sku', required: false, isCriteria: false, enableCriteria: false },
          { header: '추가구매 건별출고수량', key: 'prod_add_opt_pack', required: false, isCriteria: false, enableCriteria: false },
          { header: '추가구매 주문수량', key: 'prod_add_opt_sales', required: false, isCriteria: false, enableCriteria: false }]),
        { header: '금액', key: 'sales', required: true, isCriteria: false, enableCriteria: false },
        { header: '공급가', key: 'shop_supply_price', required: false, isCriteria: false, enableCriteria: false },
        { header: '원가', key: 'shop_cost_price', required: false, isCriteria: false, enableCriteria: false, tooltip: '업로드하는 엑셀 파일에 원가 항목이 있을 경우,\n엑셀 파일의 원가 항목을 우선으로 업로드하며,\n\n엑셀 파일에 원가 항목이 없을 경우에는\n매칭된 SKU상품의 원가를 출력합니다.' },
        { header: '배송방법', key: 'ship_method', required: false, isCriteria: false, enableCriteria: false },
        { header: '배송비', key: 'ship_cost', required: false, isCriteria: false, enableCriteria: false, isShipCost: false, isOrdNo: false },
        { header: '쇼핑몰주문번호', key: 'shop_ord_no', required: true, isCriteria: true, enableCriteria: true, isShipCost: false, isOrdNo: false, criteriaKey: 'ocode' },
        { header: '실결제금액', key: 'pay_amt', required: false, isCriteria: false, enableCriteria: false },
        { header: '주문자명', key: 'order_name', required: true, isCriteria: true, enableCriteria: true, criteriaKey: 'oname' },
        { header: '주문자ID', key: 'order_id', required: false, isCriteria: false, enableCriteria: false },
        { header: '주문자휴대폰번호', key: 'order_htel', required: true, isCriteria: false, enableCriteria: false },
        { header: '주문자전화번호', key: 'order_tel', required: false, isCriteria: false, enableCriteria: false },
        { header: '수령자명', key: 'to_name', required: true, isCriteria: true, enableCriteria: true, criteriaKey: 'gname' },
        { header: '수령자영문명', key: 'to_name_eng', required: false, isCriteria: false, enableCriteria: false },
        { header: '수령자휴대폰번호', key: 'to_htel', required: true, isCriteria: false, enableCriteria: true, criteriaKey: 'gtel' },
        { header: '수령자전화번호', key: 'to_tel', required: false, isCriteria: false, enableCriteria: false },
        { header: '우편번호', key: 'to_zipcd', required: true, isCriteria: false, enableCriteria: false },
        { header: '배송메세지', key: 'ship_msg', required: false, isCriteria: false, enableCriteria: false },
        { header: '주소', key: 'to_addr1', required: true, isCriteria: false, enableCriteria: false },
        { header: '주문일', key: 'ord_time', required: false, isCriteria: true, enableCriteria: true, criteriaKey: 'odate' },
        { header: '결제완료일', key: 'pay_time', required: false, isCriteria: false, enableCriteria: true, criteriaKey: 'cdate' },
        { header: '사은품', key: 'gift_name', required: false, isCriteria: false, enableCriteria: false },
        { header: '개인통관번호', key: 'gprivate_no', required: false, isCriteria: false, enableCriteria: false },
        { header: '쇼핑몰부담할인액', key: 'shop_discount', required: false, isCriteria: false, enableCriteria: false },
        { header: '판매자부담할인액', key: 'seller_discount', required: false, isCriteria: false, enableCriteria: false },
        { header: '쿠폰할인액', key: 'coupon_discount', required: false, isCriteria: false, enableCriteria: false },
        { header: '포인트할인액', key: 'point_discount', required: false, isCriteria: false, enableCriteria: false }
      ]
    };

    const returnData = _.assignIn({}, commonData, solData);

    return returnData;
  });
