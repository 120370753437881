'use strict';

angular.module('gmpApp')
  .controller('addSetProdCtrl', function ($rootScope, $scope, settings, commonSVC, logHistorySVC, $uibModalInstance, data, $compile, $timeout, onlineProductSVC, productSVC, productModel, supplierModel, warehouseModel, systemModel, commonModel, userInfo, localStorageService) {
    $scope.warehouseList = [];
    $scope.from = data.from || '';
    $scope.searchData = angular.copy(productSVC.searchData);
    $scope.searchData.showCount = 8;
    $scope.searchForm = angular.copy(productSVC.searchForm);
    $scope.searchForm.sdate = '2001-01-01';
    $scope.searchForm.delivery_vendor = '';
    $scope.number = data.set_no || '';
    $scope.searchForm.search_key = 'all';
    $scope.tabActive = 1;
    $scope.isDepot = userInfo.user.auth_type === '배송처';

    // 노출항목 설정용
    const setInProdColumns = localStorageService.get('set_prod_table_columns') ? JSON.parse(localStorageService.get('set_prod_table_columns')) : [
      { displayName: 'SKU상품명', name: 'prod_name', visible: true },
      { displayName: '속성', name: 'attri', visible: true },
      { displayName: '재고관리코드', name: 'stock_cd', visible: true },
      { displayName: '바코드', name: 'barcode', visible: true },
      { displayName: '실재고', name: 'real_stock', visible: true },
      { displayName: '안전재고', name: 'safe_stock', visible: true },
      { displayName: '모델명', name: 'model', visible: true },
      { displayName: '판매가', name: 'sale_price', visible: true },
      { displayName: '배송처', name: 'depot_name', visible: true }
    ];

    $scope.visibleColumns = {};
    for (const column of setInProdColumns) {
      $scope.visibleColumns[column.name] = column.visible;
    }

    const initSearchDate = moment($scope.searchForm.edate).diff(moment($scope.searchForm.sdate), 'months');
    switch (initSearchDate) {
      case 1:
        $scope.searchForm.selectDate = '1MONTH';
        break;
      case 3:
        $scope.searchForm.selectDate = '3MONTH';
        break;
      case 6:
        $scope.searchForm.selectDate = '6MONTH';
        break;
      case 12:
        $scope.searchForm.selectDate = '1YEARS';
        break;
      default:
        $scope.searchForm.selectDate = 'ALL';
        break;
    }

    // 엘지 커스텀용 모델번호 노출 여부
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));

    $scope.editMappingOrderListYn = JSON.parse(localStorage.getItem('editMappingOrderListYn')); // 현재 수정될 세트상품이 매칭된 주문리스트에도 반영되는지 여부
    $scope.lastMatchOrderListYn = JSON.parse(localStorage.getItem('lastMatchOrderListYn')); // 매칭된 주문리스트에 세트상품 수정반영 여부체크를 저장해줌

    // 세트상품리스트에서 세트상품등록 모달이 열렸을때에만 SKU상품등록 버튼 노출위해 처리 2018-05-21 rony
    $scope.viewAddProd = false;
    if (!_.isUndefined(data.viewAddProd)) {
      $scope.viewAddProd = data.viewAddProd;
    }

    $scope.setProdInfo = {
      setAuto: true,
      set_cd: '',
      set_name: '',
      sale_price: '',
      sale_cnt_limit: null,
      sale_cnt_type: 0,
      main_prod_no: 0,
      set_no: data.set_no || '',
    };

    $scope.memo = {
      uniq: data.set_no || '',
    };

    $scope.setProdList = [];

    $scope.sortBy = '';
    $scope.sortAsc = '';

    let oldData; //세트 수정시 로그 비교 데이터

    if ($scope.from == '수정') {
      productModel.setDetails({ set_no: data.set_no }, function(state, re) {
        $scope.setProdInfo.set_cd = re.data.set.set_cd;
        $scope.setProdInfo.set_name = re.data.set.set_name;
        $scope.setProdInfo.sale_price = re.data.set.sale_price;
        $scope.setProdInfo.sale_cnt_limit = re.data.set.sale_cnt_limit;
        $scope.setProdInfo.main_prod_no = `${re.data.set.main_prod_no}`;
        $scope.setProdInfo.set_status = `${re.data.set.set_status}`;
        $scope.setProdList = re.data.prod;
        $scope.setProdInfo.sale_cnt_type = re.data.set.use_sale_cnt_limit_yn;
        $scope.setProdInfo.setProdList = $scope.setProdList;
        $scope.setProdInfo.depot_no = re.data.set.depot_no;
        $scope.setProdInfo.depot_name = re.data.set.depot_name;
        $scope.setProdInfo.set_img = re.data.set.set_img || '';
        if ($scope.viewModelNo) {
          $scope.setProdInfo.model_no = re.data.set.model_no;
        }
        oldData = angular.copy($scope.setProdInfo);
        // 해당 처리가 있으면 데이터 테이블의 높이가 크게 늘어나는데....... 일단 주석 2019-04-10 rony
        // setTimeout(function() {
        //   $('.modal-body').scrollTop($(document).height());
        // }, 200);
      });
    }

    warehouseModel.ListAll({ use_yn: true, growth_yn: false }, function(state, data) {
      $scope.warehouseList = data.result;
    });

    /**
     *  데이터 테이블
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      gridHeight: 389,
      pinningColumns: [],
      alignCenterColumns: ['widget', 'sku_cd', 'attri', 'real_stock', 'stock_cnt_safe', 'barcode'],
      alignRightColumns: [],
      defaultSortingColumns: ['prod_name'],
      notSortingColumns: ['widget', 'stock_cd', 'barcode', 'real_stock', 'stock_cnt_safe', 'depot_name', 'bundle_avail_yn', 'sale_price', 'model'],
      notResizingColumns: ['sku_cd'],
      notVisibleColumns: [],
      notMovingColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/status`,
        requestWillAction: function (data) {

          if (!data.orderby) {
            data.orderby = 'prod_no desc';
          }

          $scope.searchForm.orderby && delete $scope.searchForm.orderby;
          $scope.isDepot && (data.delivery_vendor = userInfo.user.depot_no);

          // 로켓그로스 제외
          $scope.searchForm.growth_yn = 'N';

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.prodList = result.results;

          return result.results;
        }
      },
      modal: true,
      columns: [
        {
          key: 'widget',
          width: 60,
          title: '도구',
          template: function(row) {
            return `<button type="button" class="btn btn-default btn-xxs" ng-click="grid.appScope.showDetail(0,0,'${row.sku_cd}','${row.prod_no}' )">상세</button>`;
          }
        },
        {
          key: 'sku_cd',
          width: 110,
          title: 'SKU코드'
        },
        {
          key: 'prod_name',
          width: 270,
          title: 'SKU상품명',
          template: function(row) {
            const img = row.prod_img || '/assets/images/noimage.png';

            return `<img src='${img}' width='25' height='25' class='mr-10'>${row.prod_name}</span>`;
          }
        },
        {
          key: 'attri',
          width: 80,
          title: '속성'
        },
        {
          key: 'stock_cd',
          width: 110,
          title: '재고관리코드'
        },
        {
          key: 'barcode',
          width: 80,
          title: '바코드'
        },
        {
          key: 'real_stock',
          width: 80,
          title: '실재고'
        },
        {
          key: 'stock_cnt_safe',
          width: 80,
          title: '안전재고',
          template: function(row) {
            return parseInt(row.stock_cnt_safe) || 0;
          }
        },
        {
          key: 'depot_name',
          width: 110,
          title: '배송처'
        },
        {
          key: 'bundle_avail_yn',
          width: 100,
          title: '합포장여부',
          template: function ({ bundle_avail_yn }) {
            return bundle_avail_yn === 1 ? '가능' : '불가(개별포장)';
          }
        },
        {
          key: 'sale_price',
          width: 80,
          title: '판매가'
        },
        {
          key: 'model',
          width: 80,
          title: '모델명'
        }
      ]
    };

    // LG 계정인 경우 모델번호 컬럼 추가
    if ($scope.viewModelNo) {
      $scope.grid.options.columns.push({
        key: 'model_no',
        title: '모델번호',
        width: 100
      });
    }

    $scope.searchDo = _.throttle(function (refresh, noDelay) {
      // // if ($scope.search_key == 'all') {
      // //   $scope.searchForm.search_key = 'all';
      // // } else {
      // //   $scope.searchForm.search_key = [$scope.search_key];
      // // }

      $scope.grid.methods.reloadData(function() { }, refresh, noDelay);
    }, 1000);

    $scope.resetDo = function() {
      $scope.searchForm.search_key = 'all';
      $scope.searchForm.search_word = '';
      $scope.searchForm.date_type = 'wdate';
      $scope.searchForm.sdate = angular.copy(productSVC.searchForm).sdate;
      $scope.searchForm.selectDate = 'ALL';
      $scope.searchForm.edate = angular.copy(productSVC.searchForm).edate;
      $scope.searchForm.delivery_vendor = '';
      $scope.searchDo(true, true);
    };

    /**
     * 날짜선택
     */
    $scope.date_change = function (data) {
      $scope.searchForm.selectDate = data;
      if (data == 'TODAY') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
        $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      } else if (data == '1MONTH') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM-dd');
        $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      } else if (data == '3MONTH') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 3), 'yyyy-MM-dd');
        $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      } else if (data == '6MONTH') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM-dd');
        $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      } else if (data == '1YEARS') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - 365), 'yyyy-MM-dd');
        $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      } else if (data == 'ALL') {
        $scope.searchForm.sdate = angular.copy(productSVC.searchForm).sdate;
        $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      }
      $scope.searchDo(true, true);
    };

    $scope.selectRow = function(idx) {
      // 대표상품은 선택해도 체크안되게 처리
      if ($scope.setProdList[idx].prod_no != $scope.setProdInfo.main_prod_no) {
        $scope.setProdList[idx].checked = !$scope.setProdList[idx].checked;
      }

      if ($scope.setProdList[idx].checked) {
        $(`.setProd${idx} td`).addClass('selected');
      } else {
        $(`.setProd${idx} td`).removeClass('selected');
      }
    };
    $('.searchKey').ready(function() {
      $('.searchKey').keyup(function(e) {
        if (e.keyCode == 13) {
          $scope.searchDo(true, true);
        }
      });
    });

    // 대표상품 선택 시 해당 상품 체크 해제 2020-01-14 Alvin
    $scope.selectMainProd = function (idx, $event) {
      $event.stopPropagation();

      $scope.setProdList[idx].checked = false;
      $scope.setProdInfo.model_no = $scope.setProdList[idx].model_no;
    };

    $scope.sortProdList = function (sort) {
      if ($scope.sortBy === sort || $scope.sortBy === '') {
        $scope.sortAsc = $scope.sortAsc !== 'asc' ? 'asc' : 'desc';
      }
      $scope.sortBy = sort;

      $scope.setProdList = _.orderBy(_.uniqBy($scope.setProdList, 'sku_cd'), [(prod) => { return prod[$scope.sortBy] || ''; }], [$scope.sortAsc]);
    };

    $scope.add_setProd = function () {
      let prodArr = [];
      const mainFlag = !$scope.setProdList.length;

      $scope.grid.methods.selectedData().forEach((row) => {
        const index = row._uiGridData.uiGridRowIndex;

        $scope.prodList[index].pack_unit = 1;
        $scope.prodList[index].set_cost_price = 0;          // (세트)원가
        $scope.prodList[index].set_supply_price = 0;        // (세트)공급가
        $scope.grid.methods.setSelectedRow(row, false);
        prodArr.push($scope.prodList[index]);
      });

      prodArr = $scope.setProdList.concat(prodArr);

      //동일 배송처인지 검사
      if (_.uniqBy(prodArr, 'depot_no').length > 1) {
        commonSVC.showMessage('추가실패', '세트상품은 동일한 배송처로 등록된 SKU상품으로만 생성 가능합니다.');

        return false;
      }

      //매칭 검사
      if (_.uniqBy(prodArr, 'prod_no').length != prodArr.length) {
        commonSVC.showMessage('추가실패', '이미 추가된 SKU상품 입니다.');

        return false;
      }

      /**
       * 2018-08-20 Daniel
       * G마켓물류주문은 패스
       */
      if (!prodArr[0].ebyadepot_yn && _.filter(prodArr, { bundle_avail_yn: 0 }).length) {
        commonSVC.showMessage('추가실패', '세트상품에 합포장 불가건을 추가할 수 없습니다. ');

        return false;
      }

      if (prodArr[0].ebaydepot_yn && prodArr.length > 30) {
        commonSVC.showMessage('추가실패', 'G마켓물류서비스 배송처 세트상품은 SKU상품을 최대 30개까지만 등록 가능 합니다.');

        return false;
      }

      if ($scope.sortBy === '') {
        $scope.setProdList = _.uniqBy(prodArr, 'sku_cd');
      } else {
        $scope.setProdList = _.orderBy(_.uniqBy(prodArr, 'sku_cd'), [(prod) => { return prod[$scope.sortBy] || ''; }], [$scope.sortAsc]);
      }

      if (mainFlag && $scope.setProdList.length) {
        $scope.setProdInfo.main_prod_no = `${$scope.setProdList[0].prod_no}`;
        $scope.setProdInfo.model_no = $scope.setProdList[0].model_no;
      }

      $('.modal-body.grey').animate({ scrollTop: $('.modal-body.grey').height() }, 'slow');

      // 테이블 리사이즈 적용.
      setResizable();
    };

    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(rowIndex, show, sku_cd, prod_number) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function() {
        // sku 상품정보 수정후 바로 조회시 수정된 정보가 반영이 안되고 수정이전의 정보로 조회될 수 있으므로 0.5초 딜레이를 준다.
        setTimeout(() => {
          productModel.detail({ sku_code: sku_cd }, function(re, data) {

            // sku상품과 세트 내 sku상품을 구분
            const setProdListIdx = $scope.setProdList.some(prod => prod.sku_cd === data.results.sku_cd);
            if (setProdListIdx) {
              $scope.setProdList[rowIndex].prod_name = data.results.prod_name;
              $scope.setProdList[rowIndex].sale_price = data.results.product_price;
              $scope.setProdList[rowIndex].real_stock = data.results.real_stock;
              $scope.setProdList[rowIndex].safe_stock = data.results.safe_stock;

              // 조회된 sku 상품의 배송처중 세트 sku 상품의 배송처와 같은 배송처가 있는지 검사한다.
              // 세트 sku 상품의 배송처중 depot_no가 0으로 되어있는 것은 세트상품의 배송처가 변경이 된것이기 떄문에 0이 아닌것만 조회해서 매칭한다.
              const matchProdDepot = data.results.prodDepotArray.find(o => parseInt(o.depot_no) === $scope.setProdList.filter(item => item.depot_no)[0].depot_no);

              // 수정된 sku 상품의 배송처 항목중 세트상품의 배송처와 일치하는 배송처가 있는경우 해당 배송처 정보를 업데이트 한다.
              if (matchProdDepot) {
                $scope.setProdList[rowIndex].depot_no = parseInt(matchProdDepot.depot_no);
                $scope.setProdList[rowIndex].depot_name = matchProdDepot.depot_name;
              } else {
                // 수정된 sku 상품의 배송처 항목중 세트상품의 배송처와 일치하는 배송처와 없는경우 depot_no는 0으로 변경하고 해당 sku 상품의 대표배송처의 배송처명을 보여준다.
                const matchMainProdDepot = data.results.prodDepotArray.find(o => o.prod_no === data.results.main_prod_no);

                $scope.setProdList[rowIndex].depot_no = 0;
                $scope.setProdList[rowIndex].depot_name = matchMainProdDepot.depot_name;

                // 세트상품 등록시 처음 설정된 배송처와 다르게 모든 sku상품을 다른 동일 배송처로 변경한경우 해당 배송처 정보를 넣어주어야 정상적으로 세트상품 생성이 가능하다.
                if (!$scope.setProdInfo.depot_no && _.uniqBy($scope.setProdList, 'depot_no').length === 1 && _.uniqBy($scope.setProdList, 'depot_no')[0].depot_no === 0) {
                  $scope.setProdList.map(o => o.depot_no = parseInt(matchMainProdDepot.depot_no));
                }
              }
            }
          });
        }, 500);
        $scope.searchDo(false);
      });
    };

    /**
     * SKU상품등록 모달열기
     * 2018-05-17 rony
     * */
    $scope.openProdAdd = function() {

      // SKU상품등록 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.addProd', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          // sku : sku_cd,
          // number : prod_number,
          detailType: 'add',
          openModal: true
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      const modal = commonSVC.openModal('full', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');

      modal.result.then(function() {
        $scope.searchDo(true);
      });
    };

    // SKU 중복체크
    $scope.overlabCheck = function () {
      const set_cd = $scope.setProdInfo.set_cd ? $scope.setProdInfo.set_cd.trim() : '';
      if (!set_cd) {
        return;
      }

      if ($scope.setProdInfo.overlabChecked) {
        return;
      }

      if ($scope.setProdInfo.setAuto) {
        $scope.setProdInfo.set_cd = '';
        $scope.setProdInfo.overlabErrorMsg = '';
      } else {
        productModel.skuOverlabCheck({ code: set_cd }, function (state, result) {
          if (result.data.result) {
            $scope.setProdInfo.overlabErrorMsg = '해당 코드로 등록된 SKU 또는 세트상품이 있습니다.';
          } else {
            $scope.setProdInfo.overlabErrorMsg = '';
            $scope.setProdInfo.overlabChecked = true;
          }
        });
      }
    };

    $scope.onsubmit = _.throttle(function() {
      const checkPriceRatio = [0, 100].includes($scope.setProdList.reduce((acc, { set_sales_per }) => acc + (Number(set_sales_per) || 0), 0));
      const hasFalsySetSalesPer = $scope.setProdList.some(({ set_sales_per }) => set_sales_per !== 0 && !set_sales_per);
      const allSetSalesPerFalsy = $scope.setProdList.every(({ set_sales_per }) => !set_sales_per);

      if (submitEnter) {
        submitEnter = false;

        return false;
      }

      if (!$scope.setProdList.length) {
        commonSVC.showMessage('세트에 추가된 상품이 없습니다.');
      } else if (_.uniqBy($scope.setProdList, 'depot_no').length > 1) {
        commonSVC.showMessage('세트상품은 동일한 배송처로 등록된 SKU상품으로만 생성 가능합니다.');
      } else if (!$scope.setProdInfo.main_prod_no) {
        commonSVC.showMessage('대표상품을 지정 해주세요.');
      } else if ($scope.setProdList.length == 1 && $scope.setProdList[0].pack_unit < 2) {
        commonSVC.showMessage('단일 상품으로 세트 구성 시 출고 수량은 2 이상 입력되어야 합니다.');
      } else if ($scope.setProdInfo.overlabErrorMsg) {
        commonSVC.showMessage($scope.setProdInfo.overlabErrorMsg);
      } else if ($scope.setProdInfo.set_name === '') {
        commonSVC.showMessage('세트상품명을 입력 해주세요.');
      } else if ($scope.setProdList.some(({ pack_unit }) => !pack_unit || pack_unit === '0')) {
        commonSVC.showMessage('', 'SKU상품의 출고 수량은 1이상 입력해 주셔야 합니다.');
      } else if ((hasFalsySetSalesPer && !allSetSalesPerFalsy) || !checkPriceRatio) {
        commonSVC.showMessage('금액비중을 확인해 주세요.', '세트 상품을 구성하는 각 SKU상품의 금액비중 합계는 100%가 되어야 합니다.');

        return false;
      } else if ($scope.form1.$valid) {
        const params = $scope.setProdInfo;
        params.setProdList = $scope.setProdList;

        if ($scope.from == '수정') {
          oldData.setInProdList = (oldData.setProdList.map(o => `${o.pack_unit}_${o.set_cost_price}_${o.set_supply_price}`)).join(', ');
          $scope.setProdInfo.setInProdList = $scope.setProdInfo.setProdList.map(o => `${o.pack_unit}_${o.set_cost_price}_${o.set_supply_price}`).join(', ');
          $scope.setProdInfo.depot_no = $scope.setProdInfo.setProdList[0].depot_no;
          oldData.setSalesPerList = (oldData.setProdList.map(({ set_sales_per }) => set_sales_per)).join(', ');
          $scope.setProdInfo.setSalesPerList = $scope.setProdInfo.setProdList.map(({ set_sales_per }) => set_sales_per).join(', ');

          //로그 정리
          const logField = {
            set_name: `세트 상품명: ${oldData.set_name} -> ${$scope.setProdInfo.set_name}`,
            sale_price: `세트 판매가: ${oldData.sale_price} -> ${$scope.setProdInfo.sale_price}`,
            sale_cnt_type: `세트 판매수량 사용여부: ${oldData.sale_cnt_type ? '사용' : '미사용'} -> ${$scope.setProdInfo.sale_cnt_type ? '사용' : '미사용'}으로 변경`,
            sale_cnt_limit: '세트 판매수량: 변경내용 생략',
            setInProdList: '세트 내 상품정보: 변경내용 생략',
            depot_no: `세트 배송처: ${$scope.setProdInfo.depot_name} -> ${$scope.setProdInfo.setProdList[0].depot_name}`,
            main_prod_no: 'SKU상품 매칭정보: 변경내용 생략',
            model_no: `세트 모델번호: ${oldData.model_no || '없음'} -> ${$scope.setProdInfo.model_no || '없음'}`,
            set_img: '세트 대표 이미지: 변경내용 생략',
            setSalesPerList: '세트 상품 금액비중 변경',
          };

          const log_contents = logHistorySVC.compare(logField, oldData, $scope.setProdInfo).split(',').filter(e => e);

          // 추가된 상품
          const add = _.differenceWith($scope.setProdInfo.setProdList, oldData.setProdList, (a, b) => (a.prod_no === b.prod_no));

          // 삭제된 상품
          const del = _.differenceWith(oldData.setProdList, $scope.setProdInfo.setProdList, (a, b) => (a.prod_no === b.prod_no));

          if (add.length) {
            log_contents.push(`세트내 상품 추가: ${$scope.setProdInfo.set_cd}(${add.map(o => o.sku_cd).join()})`);
          }
          if (del.length) {
            log_contents.push(`세트내 상품 삭제: ${$scope.setProdInfo.set_cd}(${del.map(o => o.sku_cd).join()})`);
          }

          // 변경사항 없으면 백엔드 안태우고 그냥 수정완료 토스트 + 모달닫음 (켈리요청)
          if (!log_contents.length) {
            commonSVC.showToaster('success', '', '세트상품이 수정 되었습니다.');
            $uibModalInstance.close('success');

            return false;
          }

          params.log_contents = [`\n${log_contents.join('\n')}`];

          // 세트내 상품정보 변경로그
          const prodLog = [];
          const setProdListLogs = oldData.setProdList.reduce((prev, curr) => {
            const prod = $scope.setProdInfo.setProdList.find(p => curr.prod_no == p.prod_no);

            if (prod && curr.pack_unit != prod.pack_unit) {
              prev.packUnitLogs.push(`${curr.sku_cd}, ${curr.pack_unit}개 -> ${prod.pack_unit}개`);
            }
            if (prod && curr.set_cost_price != prod.set_cost_price) {
              prev.setCostPriceLogs.push(`${curr.sku_cd}, ${curr.set_cost_price}원 -> ${prod.set_cost_price}원`);
            }
            if (prod && curr.set_supply_price != prod.set_supply_price) {
              prev.setSupplyPriceLogs.push(`${curr.sku_cd}, ${curr.set_supply_price}원 -> ${prod.set_supply_price}원`);
            }
            if (prod && curr.set_sales_per != prod.set_sales_per) {
              prev.setSalesPerLogs.push(`${curr.sku_cd}, ${curr.set_sales_per || 0}% -> ${prod.set_sales_per || 0}%`);
            }

            return prev;
          }, { packUnitLogs: [], setCostPriceLogs: [], setSupplyPriceLogs: [], setSalesPerLogs: [] });

          if (setProdListLogs.packUnitLogs.length) {
            prodLog.push(`출고 수량(${setProdListLogs.packUnitLogs.join(' / ')})`);
          }
          if (setProdListLogs.setCostPriceLogs.length) {
            prodLog.push(`(세트)원가(${setProdListLogs.setCostPriceLogs.join(' / ')})`);
          }
          if (setProdListLogs.setSupplyPriceLogs.length) {
            prodLog.push(`(세트)공급가(${setProdListLogs.setSupplyPriceLogs.join(' / ')})`);
          }

          if (setProdListLogs.setSalesPerLogs.length) {
            prodLog.push(`(세트)금액비중(${setProdListLogs.setSalesPerLogs.join(' / ')})`);
          }

          // 대표상품 변경
          if (oldData.main_prod_no != $scope.setProdInfo.main_prod_no) {
            prodLog.push(`대표 상품(기존: ${oldData.setProdList.find(element => element.prod_no == oldData.main_prod_no).sku_cd} -> 변경: ${$scope.setProdInfo.setProdList.find(element => element.prod_no == $scope.setProdInfo.main_prod_no).sku_cd})`);
          }

          if (prodLog.length) {
            params.log_contents.push(`세트 내 상품정보: ${prodLog.join(', ')}`);
          }

          // 세트판매수량 변경 여부 (사용,미사용 변경도 포함) 2019-01-31 Amelia
          params.isSaleCntLimitChanged = oldData.sale_cnt_limit !== $scope.setProdInfo.sale_cnt_limit;

          params.editMappingOrderListYn = $scope.editMappingOrderListYn;
          params.depot_no = $scope.setProdInfo.depot_no;

          localStorage.setItem('lastMatchOrderListYn', JSON.stringify($scope.lastMatchOrderListYn));
          localStorage.setItem('editMappingOrderListYn', $scope.lastMatchOrderListYn ? JSON.stringify($scope.editMappingOrderListYn) : JSON.stringify(false));

          productModel.editSet(params, function(state, res) {
            if (res.results === 'success') {
              commonSVC.showToaster('success', '', '세트상품이 수정 되었습니다.');
              $uibModalInstance.close('success');
            } else {
              commonSVC.showToaster('error', '', '세트상품 수정에 실패하였습니다.');
            }
          });
        } else {
          productModel.addSet(params, function(state, res) {
            if (res.results === 'success') {
              commonSVC.showToaster('success', '', '세트상품이 추가 되었습니다.');
              $uibModalInstance.close('success');
            } else {
              commonSVC.showToaster('error', '', '세트상품 추가에 실패하였습니다.');
            }
          });
        }
      }
    }, 1000);

    $scope.deleteSet = function() {
      // 대표상품이 아니고 선택된 모든 상품 리스트
      const selectedList = $scope.setProdList.filter(({ checked, prod_no }) => (checked && prod_no != $scope.setProdInfo.main_prod_no));

      // 선택된 상품이 없을 때 얼럿
      if (!selectedList.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      // 삭제 안된 리스트 추가
      $scope.setProdList = $scope.setProdList.filter(({ checked }) => !checked);
    };

    /**
     * 세트 대표 이미지 임시 업로드
     */
    $scope.uploadSetImgFile = function(file) {
      const files = [file];
      const imgCheck = /(\.jpg|\.jpeg|\.png)$/i;

      if (file && imgCheck.exec(file.name)) {
        // # S3 temp 업로드 작업
        commonModel.tempUpload(files)
          .then(function (res) {
            $scope.setProdInfo.set_img = res.data.results[0].url;
          });
      } else {
        commonSVC.showMessage('주의', '이미지파일을 확인하세요.');
      }
    };

    /**
     * 세트 대표 이미지 삭제
     */
    $scope.delSetImg = async () => {

      const delSetImg = await commonSVC.showConfirmCustom({
        title: '이미지 삭제 확인',
        text: `
        <div>
        <ul style="color:black; font-weight:400;">
        <li>세트 SKU상품이 온라인 상품에 연동된 경우<br />쇼핑몰 상품 관리에서도 옵션의 이미지가 삭제되며, 쇼핑몰로 수정 전송 시에도 영향이 있습니다.</li>
        <li>세트 SKU상품이 수집된 주문에 연동된 경우<br />수집된 주문의 상품명에 이미지가 노출되지 않으며, 쇼핑몰 상품에는 영향이 없습니다.</li>
        </ul>
        <h5>
          등록된 이미지를 삭제하시겠습니까?
        </h5>
      </div>`,
        confirmButtonText: '확인',
        html: true,
        customClass: 'quickCollectGuide'
      }, null, {});

      if (delSetImg) {
        $scope.setProdInfo.set_img = '';
        $timeout(() => {});
      }
    };

    // 모달 닫기
    // 세트상품 생성 모달을 닫은 후 처리가 필요하여 close 로 변경 2018-05-17 rony
    $scope.close = function () {
      // $uibModalInstance.dismiss('cancel');
      $uibModalInstance.close();
    };

    /**
   * 페이지에서 엔터 누를 경우 등록, 수정하기 방지
   */
    let submitEnter = false;

    document.addEventListener('keydown', function(event) {

      //검색 영역에 포커스가 있는경우 검색
      if (event.keyCode === 13 && _.includes(event.target.classList, 'searchKey')) {
        submitEnter = true;
        $scope.searchDo(true, true);
      } else {
      // 엔터가 아닌 클릭등의 다른 작업시에는 등록이 가능해야 하므로 false 로 변경 2018-02-05 rony
        submitEnter = false;
      }
    }, true);

    // 테이블 리사이즈
    const setResizable = () => {
      setTimeout(() => {
        $('#viewSetInfo').colResizable({
          liveDrag: true
        });
      }, 100);
    };

    // 세트상품 테이블에 컬럼리사이즈 적용.
    if ($scope.from === '수정') {
      setResizable();
    }

    // 컬럼 노출여부 설정
    $scope.setColVisibles = async () => {
      const ctrl = 'PaUiGridColVisCtrl',
            view = 'views/common/paUiGridColVis.html',
            data = {
              data: {
                columns: setInProdColumns,
                page: 'add_set_prod',
                _gridInitState: { columns: [] },
                _gridOptions: { addColGrid: false, columnDefs: [] },
                _gridObjScope: { methods: {} }
              }
            };

      const modal = commonSVC.openModal('xg', data, ctrl, view);
      const modalResult = await modal.result;

      // 적용
      if (modalResult?.type === 'apply') {
        modalResult.columns.forEach(col => {
          $scope.visibleColumns[col.name] = col.visible;
          setInProdColumns.find(o => o.name === col.name).visible = col.visible;
        });

        localStorageService.set('set_prod_table_columns', JSON.stringify(setInProdColumns));
      }
    };

    /**
     * 검색어 입력 후 엔터키 눌렀을때 파일찾기까지 열리는 경우 있어서 searchDo만 실행
     */
    $scope.searchKeyPress = function(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        $scope.searchDo(true, true);
      }
    };
  });
