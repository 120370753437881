'use strict';

angular.module('gmpApp')
  /**
   * 2018-05-17 Daniel
   * 위메프2.0 그룹상품 추가, 수정
   */
  .controller('wmpOnlineGroupProdCtrl', function ($scope, $q, $uibModalInstance, settings, commonSVC, workSVC, errorSVC, commonModel, shopAccountModel, onlineProductModel, data, groupProdList) {
    const result = groupProdList.data.result;

    groupProdList = result.rows;

    // var sendData = []; // 작업에 등록할 데이터
    let originData = {};

    $scope.mode = data.mode;

    $scope.allCheck = { prod: false, group_prod: false };
    $scope.searchWord = '', $scope.searchLabel = '전체';
    $scope.searchKey = $scope.mode === 'add' ? ['group_cd', 'group_name'] : ['shop_sale_name', 'shop_sale_no']; //검색 값

    $scope.cateList = {
      cate1: null,
      cate2: null,
      cate3: null,
      cate4: null
    };
    $scope.groupInfo = {
      dispSdate: moment().format('YYYY-MM-DD'),
      dispEdate: moment().format('YYYY-MM-DD'),
      dispYn: 'Y',
      opt_type: result.opt_yn ? '옵션사용' : '옵션미사용'
    };

    $scope.siteInfo = { shop_name: '', shop_cd: '', shop_id: '' };

    //search 검색키
    $scope.bodySearchKey = { prod: [{ value: ['shop_sale_name', 'shop_sale_no'], label: '전체' }, { value: ['shop_sale_name'], label: '쇼핑몰 상품명' }, { value: ['shop_sale_no'], label: '상품코드' }], group: [{ value: ['group_cd', 'group_name'], label: '전체' }, { value: ['group_cd'], label: '그룹코드' }, { value: ['group_name'], label: '그룹명' }] };

    //table body
    $scope.bodyList = { prod: [], group: [], group_prod: [] };
    $scope.bodyRowCss = { prod: [], group: [], group_prod: [] };

    init();

    // // 배송유형 상세보기 클릭시
    // $scope.deliDetail = function(){
    //   var tData = $scope.groupInfo.group_deli.split(',');

    //   var resolve = {};
    //   resolve.data = {
    //     template_no: tData[0],
    //     template_sub_type: tData[1],
    //     shop_cd: tData[2],
    //     shop_id: tData[3],
    //     tab : 2,
    //     isEdit : false
    //   };
    //   commonSVC.openModal('full', resolve, 'setForm'+resolve.data.shop_cd+'TemplateCtrl', 'views/online/setForm/' + resolve.data.shop_cd + '.html');
    // };

    // // 검색키 선택
    // $scope.selectSearchKey = function(value, label){
    //   $scope.searchLabel = label;
    //   $scope.searchKey = value;
    // };

    // //검색
    // $scope.searchDo = function(loca){
    //   var filter = _.filter(originData[loca], function(data){
    //     var re = [];
    //     _.each($scope.searchKey, function(k){
    //       var tmp = data[k] ? data[k].toString().indexOf($scope.searchWord) > -1 : false;
    //       re.push(tmp);
    //     });
    //     return re.indexOf(true) > -1;
    //   });

    //   console.log(filter);

    //   $scope.bodyList[loca] = filter;
    //   if(loca === 'group'){
    //     $scope.groupInfo = {};
    //     $scope.bodyList.group_prod = [];
    //   }
    //   initRow();
    // };

    // //초기화
    // $scope.resetDo = function(loca){
    //   $scope.searchWord = '';
    //   $scope.searchLabel = '전체';
    //   $scope.searchKey = $scope.mode ==='add' ? ['group_cd','group_name'] : ['shop_sale_name','shop_sale_no'] ; //검색 값

    //   $scope.bodyList = angular.copy(originData);
    //   if(loca === 'group'){
    //     $scope.groupInfo = {};
    //     $scope.bodyList.group_prod = [];
    //   }
    //   initRow();
    // };

    // row 클릭시
    $scope.checked = function(loca, index) {
      if (!_.isUndefined($scope.bodyList[loca][index].cb)) {
        $scope.bodyList[loca][index].cb = !$scope.bodyList[loca][index].cb;
        $scope.bodyRowCss[loca][index] = $scope.bodyList[loca][index].cb;
      } else {
        _.each($scope.bodyRowCss[loca], function (v, index) {
          $scope.bodyRowCss[loca][index] = false;
        });
        $scope.bodyRowCss[loca][index] = true;
      }

      $scope.allCheck[loca] = _.filter($scope.bodyRowCss[loca]).length == $scope.bodyRowCss[loca].length;
    };

    // 전체 선택시
    $scope.check_all = function(loca) {
      if (!_.isUndefined($scope.bodyList[loca][0].cb)) {
        _.each($scope.bodyList[loca], function(tr, index) {
          tr.cb = $scope.allCheck[loca];
          $scope.bodyRowCss[loca][index] = $scope.allCheck[loca];
        });
      }
    };

    //순서 이동시
    $scope.change_seq = function (calcul, position) {
      const checkedList = _.filter($scope.bodyList.group_prod, 'cb');
      const checkedIdx = [];

      _.each(checkedList, function (prod) {
        const index = _.findIndex($scope.bodyList.group_prod, { sale_no: prod.sale_no });

        if (index > -1) {
          checkedIdx.push(index);
        }
      });

      let ori = angular.copy($scope.bodyList.group_prod);

      if (checkedIdx.indexOf(0) > -1 && calcul === 'up' || checkedIdx.indexOf(ori.length - 1) > -1 && calcul === 'down') {
        return false;
      }

      checkedIdx.sort();

      if (calcul === 'down' || position === 'end') {
        checkedIdx.reverse();
      }

      if (position !== 'end') {
        _.each(checkedIdx, function (i) {
          const sign = calcul === 'up' ? i - 1 : i + 1;
          const tmp = ori[sign];

          ori[sign] = ori[i];
          ori[i] = tmp;

          $scope.bodyRowCss.group_prod[i] = false;
          $scope.bodyRowCss.group_prod[sign] = true;
        });
      } else {
        const tmpProdList = [];

        _.each(checkedIdx, function (i, idx) {
          const tmp = ori.splice(i, 1);

          tmpProdList.push(tmp[0]);

          const cssIdx = calcul === 'up' ? idx : $scope.bodyList.group_prod.length - idx - 1;

          $scope.bodyRowCss.group_prod[i] = false;
          $scope.bodyRowCss.group_prod[cssIdx] = true;
        });

        tmpProdList.reverse();

        if (calcul === 'up') {
          ori = _.concat(tmpProdList, ori);
        } else {
          ori = _.concat(ori, tmpProdList);
        }

        ori = _.flatten(ori);
      }

      $scope.bodyList.group_prod = ori;
    };

    //선택한 그룹에 추가
    $scope.addGroup = function () {
      const select_prod = _.filter($scope.bodyList.prod, 'cb');

      if (!select_prod.length) {
        commonSVC.showMessage('딜에 추가할 상품을 선택해 주세요.');

        return false;
      }

      $scope.bodyList.group_prod = _.concat($scope.bodyList.group_prod, select_prod);
      $scope.bodyList.prod = _.differenceBy($scope.bodyList.prod, select_prod, 'sale_no');

      initRow();

      // makeWorkSendData();
    };

    // //해당상품 그룹에서 제외
    // $scope.delGroup = function(){
    //   var select_prod = _.filter($scope.bodyList.group_prod,'cb');
    //   if(!select_prod.length){
    //     commonSVC.showMessage("","그룹에서 삭제할 상품을 선택해 주세요.");
    //     return false;
    //   }

    //   // if(($scope.groupInfo.list.length - select_prod.length) < 2){
    //   //   commonSVC.showMessage("","그룹 상품의 개수는 최소 2개 입니다.");
    //   //   return false;
    //   // }

    //   var txt = select_prod.length + ' 개의 상품을 그룹에서 삭제하시겠습니까?.';
    //   commonSVC.showConfirm('상품 삭제', txt, function () {
    //     $scope.bodyList.group_prod = _.differenceBy($scope.bodyList.group_prod ,select_prod, 'sale_no');
    //     $scope.groupInfo.list = $scope.bodyList.group_prod;
    //     $scope.bodyList.prod = _.concat($scope.bodyList.prod,select_prod);

    //     initRow();

    //     if (!$scope.$$phase && !$scope.$root.$$phase) {
    //       $scope.$apply();
    //     }
    //     makeWorkSendData();
    //   });
    // };

    /**
     * 전송
     */
    $scope.send = function() {
      const prods = $scope.bodyList.group_prod;
      const groupInfo = $scope.groupInfo;

      let errMsg = '';

      if (!groupInfo.templatePc || !groupInfo.templateMobile) {
        errMsg = '상품 노출 템플릿을 선택해주세요.';
      }

      if (!groupInfo.dispSdate || !groupInfo.dispEdate) {
        errMsg = '전시기간을 선택해주세요.';
      }

      if (!groupInfo.dealName) {
        errMsg = '딜명을 입력해주세요.';
      }

      if (!groupInfo.cate1 || !groupInfo.cate2 || !groupInfo.cate3 || !groupInfo.cate4) {
        errMsg = '카테고리를 선택해주세요.';
      }

      if (prods.length < 2) {
        errMsg = '딜상품의 개수는 최소 2개 입니다.';
      }

      if (errMsg) {
        commonSVC.showMessage(errMsg);
        $('.work-RegistGroupProd').attr('disabled', false);

        return false;
      }

      if ($scope.mode === 'create') {
        commonSVC.showConfirm('전송하시겠습니까 ?', '설정한 그룹상품을 쇼핑몰에 전송합니다. 생성된 그룹 리스트는 작업이 완료된 후 새로고침하여 확인하시기 바랍니다.', function (re) {
          if (re) {
            addWork();
          } else {
            $('.work-RegistGroupProd').attr('disabled', false);
          }
        });
      } else if ($scope.mode === 'edit') {
        const ori_group = _.map(originData.group_prod, 'ol_shop_no'), new_group = _.map($scope.groupInfo.list, 'ol_shop_no');

        if ($scope.groupInfo.group_name !== originData.group_prod[0].group_name || $scope.groupInfo.group_img !== originData.group_prod[0].group_img ||
          _.difference(ori_group, new_group).length || _.difference(new_group, ori_group).length) {
          commonSVC.showConfirm('전송하시겠습니까 ?', '설정한 그룹상품을 쇼핑몰에 전송합니다. 생성된 그룹 리스트는 작업이 완료된 후 새로고침하여 확인하시기 바랍니다.', function (re) {
            if (re) {
              // var tmp = _.map($scope.groupInfo.list, function (prod) {
              //   return {
              //     state: '', //엔진 데이터 담는 변수
              //     msg: '', //엔진 데이터 담는 변수
              //     group_cd: $scope.groupInfo.group_cd,  // 신규생성이면 '' , 수정이면 원래 그룹코드
              //     group_no: $scope.groupInfo.group_no,  // 신규생성이면 null , 수정이면 원래 그룹넘버
              //     group_name: $scope.groupInfo.group_name, //그룹이름
              //     group_img: $scope.groupInfo.group_img, //그룹보기방법 (img :이미지형 , list :리스트형 )
              //     std_ol_group_seq: prod.std_ol_group_seq, // 그룹내 상품 순번
              //     shop_cate_cd: prod.shop_cate_cd, //싱품카테고리코드
              //     ol_shop_no: prod.ol_shop_no, //싱품로그번호
              //     sale_no: prod.sale_no, //상품번호
              //     shop_sale_no: prod.shop_sale_no_sub //쇼핑몰상품번호(서브)
              //   };
              // });

              // sendData = [tmp];
              addWork();
            } else {
              $('.work-RegistGroupProd').attr('disabled', false);
            }
          });
        } else {
          commonSVC.showMessage('', '수정사항이 없습니다.');
          $('.work-RegistGroupProd').attr('disabled', false);

          return false;
        }
      }
    };

    /**
     * 취소
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    function init() {
      //bodyList 가공
      if ($scope.mode === 'create') {
        //선택한 기준상품과 같이 그룹을 만들 수 있는 상품들
        _.each(groupProdList, function (prod, index) {
          if (index === 0) {
            $scope.siteInfo = { shop_name: prod.shop_name, shop_cd: prod.shop_cd, shop_id: prod.shop_id, domain: prod.domain };
          }

          //체크박스 insert
          prod.cb = false;

          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';

          if (prod.ol_shop_no === data.selectData.ol_shop_no) {
            $scope.bodyList.group_prod.push(prod);
          } else {
            //row style
            $scope.bodyRowCss.prod[index] = false;
            $scope.bodyList.prod.push(prod);
          }
        });
        // makeWorkSendData();
      } else if ($scope.mode === 'edit') {
        //선택한 기준상품과 같이 그룹을 만들 수 있는 상품들
        $scope.bodyList.prod = groupProdList.data.result.prod_list || [];
        _.each($scope.bodyList.prod, function (prod, index) {
          //체크박스 insert
          prod.cb = false;
          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
          //row style
          $scope.bodyRowCss.prod[index] = false;
        });

        //선택한 기준상품을 추가 할 수 있는 그룹
        $scope.bodyList.group_prod = groupProdList.data.result.group_list[0].list || [];
        _.each($scope.bodyList.group_prod, function (prod, index) {
          if (index === 0) {
            $scope.siteInfo = { shop_name: prod.shop_name, shop_cd: prod.shop_cd, shop_id: prod.shop_id };
          }
          //체크박스 insert
          prod.cb = false;
          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
          //row style
          $scope.bodyRowCss.group_prod[index] = false;
        });
        $scope.groupInfo = groupProdList.data.result.group_list[0];
      } else if ($scope.mode === 'add') {
        // 그룹에 추가할 상품
        $scope.bodyList.prod = data.selectData;
        $scope.allCheck.prod = true;
        _.each($scope.bodyList.prod || [], function (prod, index) {
          if (index === 0) {
            $scope.siteInfo = { shop_name: prod.shop_name, shop_cd: prod.shop_cd, shop_id: prod.shop_id };
          }
          //체크박스 insert
          prod.cb = index === 0;
          //이미지 insert
          prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
          //row style
          $scope.bodyRowCss.prod[index] = index === 0;
        });

        //선택한 기준 상품을 추가 할 수 있는 그룹
        $scope.bodyList.group = groupProdList.data.result.group_list || [];
        _.each($scope.bodyList.group, function (group, index) {
          //row style
          $scope.bodyRowCss.group[index] = index === 0;
          if (index === 0) {
            //선택한 그룹 (init시 첫번째가 자동 선택)
            $scope.groupInfo = group;
            $scope.bodyList.group_prod = group.list;
            _.each($scope.bodyList.group_prod, function (prod, index) {
              //체크박스 insert
              prod.cb = false;
              //이미지 insert
              prod.img = prod.sale_img_url ? prod.sale_img_url + prod.sale_img1 : '/assets/images/noimage.png';
              //row style
              $scope.bodyRowCss.group_prod[index] = false;
            });
          }
        });
      }

      originData = angular.copy($scope.bodyList);
      categoryLoad();
    }

    //row cb, select 초기화
    function initRow() {
      _.each($scope.bodyList.prod, function (r) {
        r.cb = false;
      });
      _.each($scope.bodyList.group_prod, function (r) {
        r.cb = false;
      });
      _.each($scope.bodyRowCss.prod, function (r, i) {
        $scope.bodyRowCss.prod[i] = false;
      });
      _.each($scope.bodyRowCss.group_prod, function (r, i) {
        $scope.bodyRowCss.group_prod[i] = false;
      });
      $scope.allCheck = { prod: false, group_prod: false };
    }

    function categoryLoad() {
      // 쇼핑몰 계정 정보 가져오기
      const shop = $scope.siteInfo;
      const shop_cd = shop.shop_cd;
      const shop_id = shop.shop_id;

      const param = { shop_cd: shop_cd, shop_id: shop_id };

      // 계정정보 선 로딩후 카테고리 로딩
      shopAccountModel.load(param)
        .then(function (res) {
          $scope.userInfo = res.data.results;

          //대분류 카테고리 로드
          return requestPA('product.category.scrap', {});
        })
        .then(function (res) {
          const data = res.data.data.items;

          $scope.cate = _.groupBy(data, 'lcateName');
          cateCol($scope.cate, 0);

          $scope.cateList.cate1 = $scope.cate;
        });
    }

    /**
     * 카테고리 정리
     */
    const category = ['m', 's', 'd'];

    function cateCol(obj, count) {
      _.each(obj, function (v, k) {
        const groupName = `${category[count]}cateName`;

        obj[k] = _.groupBy(v, groupName);
        let o = obj[k];

        if (count === 2) {
          o = _.each(o, function(v, k) {
            o[k] = v[0].dcateCode;
          });
        } else {
          cateCol(o, (count + 1));
        }
      });
    }

    /**
     * 카테고리 변경
     */
    $scope.cateChange = function(index) {
      const cateList = $scope.cateList;
      const cate = `cate${index}`;
      const nextCate = `cate${index + 1}`;
      const select = $scope.groupInfo[cate];

      cateList[nextCate] = cateList[cate][select];

      cateInit(index);
    };

    function cateInit(index) {
      const si = index + 1;

      for (let i = si; i <= 4; i++) {
        $scope.groupInfo[`cate${i}`] = '';
      }
    }

    /**
     * playapi function
     */
    function requestPA(action, addData) {
      const userInfo = $scope.userInfo,
            domain = $scope.siteInfo.domain;

      return commonSVC.requestPA(userInfo, domain, action, addData, commonModel, shopAccountModel);
    }

    // function makeWorkSendData() {
    //   var groupList = $scope.groupInfo.list;
    //   var workData = [];
    //   _.each(groupList, function(prod, idx){
    //     var tmp = {
    //       state : '', //엔진 데이터 담는 변수
    //       msg : '', //엔진 데이터 담는 변수
    //       group_cd : $scope.groupInfo.group_cd,  // 신규생성이면 '' , 수정이면 원래 그룹코드
    //       group_no : $scope.groupInfo.group_no,  // 신규생성이면 null , 수정이면 원래 그룹넘버
    //       group_name : $scope.groupInfo.group_name, //그룹이름
    //       group_img : $scope.groupInfo.group_img, //그룹보기방법 (img :이미지형 , list :리스트형 )
    //       std_ol_group_seq: idx, // 그룹내 상품 순번
    //       shop_cate_cd: prod.shop_cate_cd, //싱품카테고리코드
    //       ol_shop_no: prod.ol_shop_no, //싱품로그용번호
    //       sale_no: prod.sale_no, //상품번호
    //       shop_sale_no: prod.shop_sale_no_sub //쇼핑몰상품번호(서브)
    //     };
    //     workData.push(tmp);
    //   });
    //   if($scope.mode === 'add') {
    //     var sendGroup = _.filter(_.flatMap(sendData), function(g){ return g.group_cd !== $scope.groupInfo.group_cd});
    //     sendData = [_.concat(sendGroup,workData)];
    //   }
    //   else sendData = [workData];
    // }

    function getPostData() {
      const info = $scope.groupInfo;
      const list = $scope.bodyList.group_prod;

      const prodNo = list[0].shop_sale_no;
      const prodNoList = _.map(list, function (p) {
        return p.shop_sale_no;
      });

      const data = {
        dealNo: '', // 딜 번호

        // basic
        dealName: info.dealName,              // 딜명
        dcateCode: info.cate4,                // 딜카테고리
        displayYn: info.dispYn,               // 전시여부
        displayStartDate: info.dispSdate,     // 전시 시작일
        displayEndDate: info.dispEdate,       // 전시 종료일
        templatePc: info.templatePc,          // pc 템플릿
        templateMobile: info.templateMobile,  // mobile 템플릿

        // dealProduct
        productNo: prodNo,                    // 상품번호
        productNoList: prodNoList,            // 상품리스트
      };

      return data;
    }

    function addWork() {
      const data = getPostData();

      const param = {
        site_code: $scope.siteInfo.shop_cd,
        site_id: $scope.siteInfo.shop_id,
        group_list: [data]
      };

      workSVC.addWork('RegistGroupProd', param)
        .then(function() {
          if (result.status == 200) {
            commonSVC.showToaster('success', '성공', '작업이 등록 되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');
          } else {
            throw result.data.error;
          }
          $uibModalInstance.close();
        })
        .catch(function(err) {
          const errMsg = errorSVC.getError('work', err.data.error);
          commonSVC.showToaster('error', '실패', errMsg);

          return err;
        });
    }
  })
  /**
   * 2018-03-12 ally
   * 그룹상품 동기화
   */
  .controller('onlineGroupProdSyncCtrl', function ($scope, $uibModalInstance, workSVC, data, $rootScope, SettingsService, siteInfo, commonSVC, $timeout) {
    $scope.total_count = data.data.result;
    $scope.selectedAll = true;

    $scope.mall = {};

    SettingsService.getShopInfo()  // 2018-10-30 Jacob 쇼핑몰 계정 추가후 바로 작업시 siteinfo 갱신이 되지않는 문제 수정
      .then(function (re) {        // https://playautogmpproject.atlassian.net/browse/GMPKR-5757
        siteInfo = re.data;
      });

    const saveMallType = 'groupProdSync';

    // 마지막 선택값 기억.2019-04-08 rony
    $scope.saveMalls = JSON.parse(localStorage.getItem(saveMallType));
    if (!$scope.saveMalls) {
      $scope.saveMalls = {
        use_yn: false,
        malls: []
      };
    }

    function init() {
      // 사용중인 쇼핑몰, 아이디 가져오기
      const channelList = angular.copy($rootScope.useChannelList({ site_action: 'SyncProdState' }));

      // STEP 1. 조회된 쇼핑몰 정보 담기.
      // 단일 상품지원 쇼핑몰만 리턴해줌
      $scope.mall = channelList.filter(mall => mall.std_ol_avail_yn === 1 && !['B719', 'B502', 'P020', 'A112', 'A092', 'A093', 'A077', 'X094'].includes(mall.pa_shop_cd));
      let mastchMalls;

      // STEP 2. 마지막 선택값 기억 사용시 체크되었던 사이트 선택.
      _.each($scope.mall, function (m) {
        mastchMalls = [];
        if ($scope.saveMalls && $scope.saveMalls.use_yn) {
          mastchMalls = _.filter($scope.saveMalls.malls, function(o) {
            return o.shop_cd === m.shop_cd && o.shop_id === m.shop_id;
          });
        }

        if (mastchMalls.length) {
          m.selected = mastchMalls[0].checked;
        } else {
          m.selected = true;
        }
      });

      // STEP 3. 전체사이트 여부 체크.
      const unSelectedOrder = _.filter($scope.mall, function(o) {
        return o.selected === false;
      });

      $scope.selectedAll = !unSelectedOrder.length;
    }
    init();

    $scope.checkboxClick = function(type) {
      if (type === 'all') {
        angular.forEach($scope.mall, function (mall) {
          mall.selected = $scope.selectedAll;
        });
      } else {
        if ($scope.selectedAll && !($scope.mall[type].selected)) {
          $scope.selectedAll = false;
        } else {
          let flag = true;

          for (const i in $scope.mall) {
            if (!$scope.mall[i].selected) {
              flag = false;

              return;
            }
          }
          $scope.selectedAll = flag;
        }
      }
    };

    $scope.ok = function () {

      const idx = _.findIndex($scope.mall, { selected: true });

      if (idx < 0) {
        commonSVC.showMessage('한개 이상의 쇼핑몰을 선택해주세요.');

        $timeout(() => {
          $scope.waitBtnDisableFlag = false;
        });

        return false;
      }

      // 선택값을 담기위해 몰정보 초기화.
      $scope.saveMalls.malls = [];
      const selected_mall = [];

      angular.forEach($scope.mall, function(v) {
        if (v.selected == true) {
          selected_mall.push({
            site_id: v.shop_id,
            site_code: v.shop_cd,
            prod_list: [],
          });
        }

        $scope.saveMalls.malls.push({ shop_cd: v.shop_cd, shop_id: v.shop_id, checked: v.selected });
      });
      workSVC.addWorkSet('SYNC_GROUP_PROD', selected_mall);
      // 선택 사이트 정보 로컬스토리지 저장.

      localStorage.setItem(saveMallType, JSON.stringify($scope.saveMalls));
      $scope.saveMalls = JSON.parse(localStorage.getItem(saveMallType));

      $uibModalInstance.close();
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
