'use strict';
angular.module('gmpApp')
  .controller('OnlineProductScrapMatchCtrl', function (
    $rootScope,
    $scope,
    $state,
    $stateParams,
    settings,
    $filter,
    commonSVC,
    onlineProductModel,
    shopAccountModel,
    channelList,
    warehouseList,
    systemList,
    userInfo,
    errorSVC,
    workSVC,
    $timeout) {

    /** 선택한 쇼핑몰(계정) 정보 */
    $scope.shopInfo;
    $scope.onlineList;
    $scope.matchOnlineList;

    const init = async () => {
      // 등록된 마스터 상품 개수 조회
      $scope.masterProdCount = (await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/online/master-prod-count`, {})).data?.result || 0;
      // 등록된 마스터 상품이 없거나 100000개 이상인 경우 자동매칭 작업 불가
      $rootScope.canAutoSetting = 0 < $scope.masterProdCount && $scope.masterProdCount < 100000;
    };

    init();

    /** 쇼핑몰(계정) 정보 selectbox 초기화 */
    function initSelectBox(channelList) {
      const options = (channelList || []).map(shop => {
        return {
          label: `${shop.shop_name + '(' + shop.shop_id + ')'}`,
          title: `${shop.shop_name + '(' + shop.shop_id + ')'}`,
          value: `("${shop.shop_cd}", "${shop.shop_id}")`,
        };
      });
      $('#shop_multi_select_prodScrap').multiselect({
        includeSelectAllOption: true,
        nonSelectedText: '쇼핑몰(계정)선택',
        allSelectedText: '쇼핑몰 계정 전체선택',
        buttonWidth: '200px',
      });
      $('#shop_multi_select_prodScrap').multiselect('dataprovider', options);
    }
    initSelectBox(channelList.data.results);

    // state parameter가 있을 경우
    if ($stateParams.shop_id
      && $stateParams.shop_cd
      && channelList.data.results.some(v => v.shop_id === $stateParams.shop_id && v.shop_cd === $stateParams.shop_cd)) {

      $scope.shopInfo = [`("${$stateParams.shop_cd}", "${$stateParams.shop_id}")`];

      // 해당 쇼핑몰이 체크되게 multiselect refresh 해줌.
      $timeout(function () {
        $('#shop_multi_select_prodScrap').multiselect('refresh');
      }, 100);
    }

    /** 상품 row 선택시, 미리보기 출력 및 리스트 불러오기 */
    $scope.$on('OnSelectChangeBefore', (event, data) => {
      const { id, selectData, selectDataSelected, selectedAll } = data;
      if (selectedAll) {
        return;
      }

      switch (id) {
        // 가져온 쇼핑몰 상품 데이터테이블 선택
        case 'online_grid1':
          {
            const { sale_img1, brand, model, shop_sku_cd, shop_sale_name, std_ol_yn, madein_etc, maker } = selectData;

            if (selectDataSelected) {
              $scope.online_grid1.preview = {
                prod_img: sale_img1, // 이미지
                madein_etc: madein_etc, // 원산지
                maker: maker, // 제조사
                brand: brand, // 브랜드
                model: model, // 모델명
                std_ol_yn_text: std_ol_yn === 2 ? '물류상품' : (std_ol_yn === 1 ? '단일상품' : '일반상품'), // 상품 유형
              };
            } else {
              $scope.online_grid1.preview = {};
            }

            Object.assign($scope.online_grid2.searchForm, {
              shop_c_sale_cd: shop_sku_cd || '',
              shop_sale_name: shop_sale_name,
              std_ol_yn: std_ol_yn,
            });

            $scope.online_grid2.searchFn.searchDo();
          }
          break;

        // 솔루션에 등록되어 있는 쇼핑몰(마스터) 상품 선택
        case 'online_grid2':
          {
            if (selectDataSelected) {
              const { sale_img_url, sale_img1, brand, model, madein, madein_etc, maker, opt_type } = selectData;

              $scope.online_grid2.preview = {
                prod_img: sale_img_url + sale_img1, // 이미지
                madein_etc: madein || madein_etc, // 원산지
                maker: maker, // 제조사
                brand: brand, // 브랜드
                model: model, // 모델명
                opt_type: opt_type, // 옵션타입
              };
            } else {
              $scope.online_grid2.preview = {};
            }
          }
          break;

      }
    });

    const warehouse = warehouseList.data.result || [];
    const noneSrc = '/assets/images/noimage.png';

    /** ************************************************************** 1번 스텝 - 쇼핑몰 상품 가져오기 ************************************************************** */
    /** 상품 가져오기 모달 */
    $scope.scrapProd = async () => {

      if (commonSVC.checkPermission('online.roles.scrapProd', userInfo.permission) === false) {
        // 상품 가져오기 권한 확인. 2019-01-04 rony
        return false;
      }

      const resolve = {
        data: {
          prod_scrap_date: systemList.data.prod_scrap_date,
        }
      };

      commonSVC.openModal('lg', resolve, 'ProdProductScrapCtrl', 'views/online/product/modals/product_scrap.html', false, true, true);
    };

    // 계정 선택완료 버튼
    $scope.allSearchDo = function () {
      $scope.online_grid1.searchFn.searchDo();
      $scope.online_grid3.searchFn.searchDo();
    };

    // 새로고침 버튼
    $scope.allReloadDo = async function () {
      $scope.shopInfo = null;
      const re = await shopAccountModel.shopAccountUseList({ site_action: 'ScrapProd', import_yn: true });
      initSelectBox(re.data.results);

      $scope.online_grid1.searchFn.resetDo();
      $scope.online_grid3.searchFn.searchDo();

    };

    /** ************************************************************** 2번 스텝 - 가져온 쇼핑몰 상품 ************************************************************** */
    const onlineGrid1_searchData = {
      totalCount: 0, // 검색전체 건수
      showCount: 25 // 한 페이지에 보이는 건수
    };

    const onlineGrid1_searchForm = {
      search_type: '',
      search_keyword: '',
      multi_type: 'shop_sale_no', // 멀티서치 타입
      multi_search_word: '',
      opt_yn: '',
      sale_type: ''
    };

    // 가져온 쇼핑몰 상품 그리드
    $scope.online_grid1 = {
      preview: {},
      searchData: angular.copy(onlineGrid1_searchData),
      searchForm: angular.copy(onlineGrid1_searchForm),
      searchFn: {
        searchDo: function () {
          // 미리보기 초기화
          $scope.online_grid1.preview = {};
          $scope.waitBtnDisableFlag = true;
          $scope.online_grid1.methods.reloadData(null, true, true);
          $scope.online_grid2.methods.setEmpty();
          $scope.online_grid2.preview = {};
        },
        changeLen: function (count) {
          $scope.online_grid1.searchData.showCount = count;
          $scope.online_grid1.methods.length(count);
        },
        // 검색 초기화
        resetDo: function () {
          $scope.online_grid1.searchForm = angular.copy(onlineGrid1_searchForm);
          $scope.online_grid1.searchFn.searchDo();
        }
      },
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['checkbox', 'sale_img1'],
        alignRightColumns: [],
        defaultSortingColumns: [],
        notSortingColumns: ['checkbox', 'sale_img1'],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: [],
        initPagingSize: 25,
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'fake_ebay_shop_mas_no',
          bundleUniqKey: 'import_ol_shop_no'
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/product/matching/scrap`,
          requestWillAction: function (d) {
            const data = angular.merge({}, d, $scope.online_grid1.searchForm);
            data.shopInfo = $scope.shopInfo;

            if (data.orderby.includes('shop_name')) {
              data.orderby = `seq ${data.orderby.split(' ')[1]}, ${data.orderby}`;
            }

            return data;
          },
          requestDidAction: function (json) {
            // 가져온 쇼핑몰 상품 리스트
            $scope.onlineList = angular.copy(json.results);
            $scope.online_grid1.searchData.totalCount = json.recordsTotal;

            return json.results;
          }
        },
        columns: [
          {
            title: '쇼핑몰(계정)',
            key: 'shop_name',
            width: 130,
            template: (row) => {
              return `<span uib-tooltip="${row.shop_name}(${row.shop_id})` +
              `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${
                ['A001', 'A006', 'B378'].includes(row.shop_cd) && row.ebay_shop_mas_no ? (row.shop_cd === 'A001' ? 'ESMA' : (row.shop_cd === 'A006' ? 'ESMG' : 'CPRG')) : (row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd)
              }.png" style="width: 50px;">${row.shop_id}</span>`;
            },
            hideColVis: true
          },
          {
            title: '이미지',
            key: 'sale_img1',
            width: 50,
            template: (row) => {
              return `<img src='${row.sale_img1}' width='30' height='30'>`;
            }
          },
          {
            title: '쇼핑몰 상품코드',
            key: 'shop_sale_no',
            width: 120,
            tooltip: '스마트스토어 그룹상품인 경우 그룹 상품번호가 노출됩니다',
          },
          {
            title: '판매자 관리코드',
            key: 'shop_c_sale_cd',
            width: 120,
            template: (row) => {
              return row.shop_c_sale_cd || '-';
            }
          },
          {
            title: '상품명',
            key: 'shop_sale_name',
            width: 220,
            filter: 'whiteSpace'
          },
          {
            title: '판매가',
            key: 'sale_price',
            width: 100,
            template: (row) => {
              const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

              return `${$filter('currency')(row.sale_price, '', decimalPlaces)}원`;
            }
          },
          {
            key: 'sale_cnt_limit',
            width: 70,
            title: '수량',
            template: (row) => {

              return $filter('currency')(row.sale_cnt_limit, '', 0);
            }
          },
          {
            key: 'ebay_shop_mas_no',
            width: 70,
            title: '쇼핑몰 마스터코드',
            notCompile: true,
            tooltip: 'ESM마스터상품에 노출되는 마스터상품번호 입니다.',
            template: (row) => {

              return ['A001', 'A006'].includes(row.shop_cd) && !row.ebay_shop_mas_no?.includes('TEMP') ? row.ebay_shop_mas_no : '';
            }
          }
        ],
      },
    };

    /**
     * 상품 삭제 함수
     */
    $scope.deleteData = async () => {
      const selectedRows = $scope.online_grid1.methods.selectedData('all');
      if (selectedRows.length == 0) {
        commonSVC.showMessage('선택한 상품이 없습니다.');

        return;
      }

      const params = {
        import_ol_shop_no_list: selectedRows.map(v => v.import_ol_shop_no)
      };

      const confirm = await commonSVC.showConfirm(
        '상품 삭제',
        '선택한 상품을 리스트에서 삭제합니다.\n삭제한 상품은 복구되지 않으며, 재수집해야 합니다.\n상품을 삭제 하시겠습니까?'
      );

      if (!confirm) {
        return;
      }

      try {
        await onlineProductModel.scrapProdDataDelete(params);
        commonSVC.showToaster('success', '성공', '가져온 상품이 삭제 되었습니다.');
        $timeout(function () {
          $scope.online_grid1.searchFn.searchDo();
        }, 700);
      } catch (err) {
        commonSVC.showToaster('error', '실패', err?.data?.error);
      }

    };

    /** ************************************************************** 3번 스텝 - 솔루션에 등록되어 있는 쇼핑몰(마스터) 상품 ************************************************************** */
    const onlineGrid2_searchData = {
      totalCount: 0,     // 검색전체 건수
      showCount: 10, // 한 페이지에 보이는 건수
      selectCount: 0
    };

    const onlineGrid2_searchForm = {
      search_type: '',
      search_keyword: '',
      std_ol_yn: '0',
      shop_sale_name: '',
      shop_c_sale_cd: '',
      shopInfo: [],
      import_ol_shop_no_list: [],
    };

    $scope.online_grid2 = {
      preview: {},
      searchData: angular.copy(onlineGrid2_searchData),
      searchForm: angular.copy(onlineGrid2_searchForm),
      searchFn: {
        searchDo: function (type) {
          $scope.online_grid2.preview = {};
          const selectedData = $scope.online_grid1.methods.selectedData('all');

          if (type === 'keyword' && !selectedData.length) {
            commonSVC.showMessage('선택한 상품이 없습니다.');
          }

          $scope.online_grid2.searchForm.import_ol_shop_no_list = selectedData.map(v => v.import_ol_shop_no);
          const shopInfo = Object.keys(_.groupBy(selectedData, o => `("${o.shop_cd}", "${o.shop_id}", "${o.std_ol_yn}")`));

          if (selectedData.length == 0 || (shopInfo.length !== selectedData.length)) {

            $scope.online_grid2.methods.setEmpty();
            $scope.online_grid2.searchData.totalCount = 0;

            return;
          }

          $scope.online_grid2.searchForm.shopInfo = shopInfo;
          $scope.online_grid2.methods.reloadData(null, true, true);
        },
        changeLen: function (count) {
          $scope.online_grid2.searchData.showCount = count;
          $scope.online_grid2.methods.length(count);
        },
        // 검색 초기화
        resetDo: function () {
          // 백엔드에서 std_ol_yn 필수값이라 std_ol_yn 저장해서 따로 넣어줌
          const std_ol_yn = $scope.online_grid2.searchForm.std_ol_yn;

          $scope.online_grid2.searchForm = angular.copy(onlineGrid2_searchForm);
          $scope.online_grid2.searchForm.std_ol_yn = std_ol_yn;
          $scope.online_grid2.searchFn.searchDo();
        },
      },
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['widget', 'sale_img1', 'sale_price', 'sale_cnt_limit'],
        alignRightColumns: [],
        defaultSortingColumns: [],
        notSortingColumns: ['widget', 'sale_img1', 'make_info'],
        notResizingColumns: ['widget', 'sale_img1'],
        notMovingColumns: [],
        notVisibleColumns: [],
        initPagingSize: 25,
        selectOptions: {
          multiSelect: false
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/product/matching/master`,
          requestWillAction: function (data) {
            data = angular.merge({}, data, $scope.online_grid2.searchForm);

            return data;
          },
          requestDidAction: function (result) {
            $scope.online_grid2.searchData.totalCount = result.recordsTotal;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 70,
            template: function (row) {
              return `<button class="btn btn-primary btn-xxs" ng-click="grid.appScope.onlineMatching('${row.sale_no}', '${row.c_sale_cd}');">매칭</button>`;
            }
          },
          {
            title: '이미지',
            key: 'sale_img1',
            width: 50,
            template: function (row) {
              return `<img src='${row.sale_img_url + row.sale_img1}' width='30' height='30'>`;
            }
          },
          {
            title: '판매자 관리코드',
            key: 'c_sale_cd',
            width: 120,
          },
          {
            title: '상품명',
            key: 'shop_sale_name',
            width: 220,
            filter: 'whiteSpace'
          },
          {
            title: '판매가',
            key: 'sale_price',
            width: 75,
            template: function (row) {
              const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

              return `${$filter('currency')(row.sale_price, '', decimalPlaces)}원`;
            }
          },
          {
            title: '수량',
            key: 'sale_cnt_limit',
            width: 60,
            template: function (row) {
              return $filter('currency')(row.sale_cnt_limit, '', 0);
            }
          },
        ]
      },
    };

    /**
     * 솔루션 상품 > [매칭] 버튼 클릭 이벤트
     * @param {*} sale_no
     * @param {*} c_sale_cd
     */
    $scope.onlineMatching = async (sale_no, c_sale_cd) => {
      // 선택된 쇼핑몰 상품이 있는지 체크
      const selectedRows = $scope.online_grid1.methods.selectedData('all');

      if (!selectedRows.length) {
        commonSVC.showToaster('error', '쇼핑몰 상품 매칭', '매칭할 [가져온 쇼핑몰 상품]의 체크여부를 확인하여 주시기 바랍니다.');

        return false;
      }

      const shopInfo = Object.keys(_.groupBy(selectedRows, o => o.shop_cd + o.shop_id + o.std_ol_yn));
      if (shopInfo.length !== selectedRows.length) {

        commonSVC.showToaster('error', '쇼핑몰 상품 매칭', '쇼핑몰과 계정이 동일한 상품은 하나의 마스터 상품에 매칭할 수 없습니다.');

        return false;
      }

      const import_ol_shop_no_list = selectedRows.map(v => ({ import_ol_shop_no: v.import_ol_shop_no, ebay_shop_mas_no: v.ebay_shop_mas_no }));

      const params = {
        import_ol_shop_no_list: import_ol_shop_no_list,
        sale_no: sale_no,
        c_sale_cd: c_sale_cd,
      };

      try {
        const re = await onlineProductModel.matchMaster(params);
        if (re?.data?.result?.success) {
          commonSVC.showToaster('success', '쇼핑몰 상품 매칭 성공', `${re?.data?.result?.success}건 성공 / ${re?.data?.result?.failed}건 실패`);
        } else {
          throw re;
        }

        $timeout(function () {
          $scope.online_grid1.searchFn.searchDo();
          $scope.online_grid3.searchFn.searchDo();
        }, 700);
      } catch (err) {
        const msg = err?.message || err?.data?.error || err?.data?.result?.failed_reason?.join('\n');
        commonSVC.showToaster('error', '쇼핑몰 상품 매칭 실패', msg);
      }

    };

    /** ************************************************************** 4번 스텝 - 매칭 확인 후 상품 등록 ************************************************************** */

    const onlineGrid3_searchData = {
      totalCount: 0,
      showCount: 25, // 한 페이지에 보이는 건수
    };

    // 매칭 확인 및 저장 그리드
    $scope.online_grid3 = {
      searchData: angular.copy(onlineGrid3_searchData),
      searchFn: {
        searchDo: function () {
          $scope.online_grid3.methods.reloadData(null, true, true);
        },
        changeLen: function (count) {
          $scope.online_grid3.searchData.showCount = count;
          $scope.online_grid3.methods.length(count);
        },
      },
      methods: {},
      options: {
        pinningColumns: [],
        alignCenterColumns: ['checkbox', 'sale_img1'],
        alignRightColumns: [],
        defaultSortingColumns: [],
        notSortingColumns: ['checkbox', 'shopInfo', 'sale_img1', 'shop_sale_name', 'shop_sale_no', 'std_ol_yn'],
        notResizingColumns: [],
        notMovingColumns: [],
        notVisibleColumns: [],
        initPagingSize: 25,
        familyOptions: {
          familyCountKey: 'selectStyleCnt',
          familyDataKey: 'c_sale_cd',
          parentDataKey: 'shop_cd',
          parentDataValue: 'Z000',
          parentRowTemplate: () => {}
        },
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'fake_ebay_shop_mas_no',
          bundleUniqKey: 'import_ol_shop_no'
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/product/matching/scrap-opts`,
          requestWillAction: function (data) {
            data.shopInfo = $scope.shopInfo;

            return data;
          },
          requestDidAction: function (result) {
            // 마스터 상품을 제외한 매칭 확인 후 상품등록 리스트
            $scope.matchOnlineList = angular.copy(result.results).filter(re => re.shop_cd !== 'Z000');
            $scope.online_grid3.searchData.totalCount = result.recordsTotal;

            return result.results;
          }
        },
        columns: [
          {
            title: '쇼핑몰(계정)',
            key: 'shopInfo',
            width: 120,
            template: (row) => {
              return row.shop_cd === 'Z000'
                ? row.c_sale_cd ? '마스터 상품' : '존재하지 않은 마스터 상품'
                : `<span uib-tooltip="${row.shop_name}(${row.shop_id})` +
                `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${
                  ['A001', 'A006', 'B378'].includes(row.shop_cd) && row.ebay_shop_mas_no ? (row.shop_cd === 'A001' ? 'ESMA' : (row.shop_cd === 'A006' ? 'ESMG' : 'CPRG')) : (row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd)
                }.png" style="width: 50px;">${row.shop_id}</span>`;
            }
          },
          {
            title: '이미지',
            key: 'sale_img1',
            width: 50,
            template: (row) => {
              if (row.sale_img1 || row.mas_image) {
                return `<img src='${row.shop_cd === 'Z000' ? row.mas_image : row.sale_img1}' width='30' height='30'>`;
              }
            }
          },
          {
            title: '상품명',
            key: 'shop_sale_name',
            width: 200,
            filter: 'whiteSpace'
          },
          {
            title: '상품코드',
            key: 'shop_sale_no',
            width: 120,
            notCompile: true,
            template: (row) => {
              return row.shop_cd === 'Z000' ? row.c_sale_cd : row.shop_sale_no;
            }
          },
          {
            title: '상품 유형',
            key: 'std_ol_yn',
            width: 80,
            notCompile: true,
            template: (row) => {
              if (row.shop_cd !== 'Z000') {
                return row.std_ol_yn === 2 ? '물류상품' : (row.std_ol_yn === 1 ? '단일상품' : '일반상품');
              }
            }
          }
        ],
      }
    };

    /**
     * 자동매칭
     */
    $scope.autoMatch = async () => {
      const selectedRows = $scope.online_grid1.methods.selectedData('all');

      // 가져온 상품 자체가 없을때
      if (!$scope.onlineList.length) {
        commonSVC.showToaster('error', '매칭 실패', '매칭할 상품이 존재하지 않습니다. 수집 후 다시 매칭 바랍니다.');

        return false;
      }

      const prod_list = selectedRows.map(prod => {
        return {
          import_ol_shop_no: prod.import_ol_shop_no,
          shop_c_sale_cd: prod.shop_c_sale_cd,
          pa_shop_cd: prod.pa_shop_cd,
          shop_cd: prod.shop_cd,
          shop_id: prod.shop_id,
          std_ol_yn: prod.std_ol_yn,
          sale_no: prod.sale_no,
          shop_sale_name: prod.shop_sale_name,
          brand: prod.brand,
          maker: prod.maker,
          model: prod.model,
          ebay_shop_mas_no: prod.ebay_shop_mas_no,
          image_url: prod.sale_img1
        };
      });

      const base_match_list = {
        // 판매자관리코드
        c_sale_cd: {
          checked: true
        },
        // 상품명
        prod_name: {
          checked: true,
          percent: 75
        },
        // 브랜드명
        brand: {
          checked: true,
          percent: 75
        },
        // 제조사명
        maker: {
          checked: true,
          percent: 75,
        },
        // 모델명
        model: {
          checked: true,
          percent: 75,
        },
        // 대표이미지
        image: {
          checked: true
        }
        /**
         * 잠시 보류
         * 두개 필드 추후 사용할 수도 있음
         */
        // 배송비 종류
        // ship: {
        //   checked: false,
        //   method: 'free'
        // },
        // // 판매가(근사값)
        // sale_price: {
        //   checked: false,
        // }
      };

      const params = {
        prod_list: prod_list,
        searchParams: Object.assign($scope.online_grid1.searchForm, { shopInfo: $scope.shopInfo }),
        base_match_list
      };

      const LOCALSTORAGE_KEY = 'prod_autoMatch';

      const opt_option = {
        title: '상품 자동 매칭',
        text: '마스터 상품 중 판매자 관리코드가 동일하거나 상품명, 브랜드명, 제조사명, 모델명 항목이 유사한 상품은 자동 매칭됩니다.',
      };

      // 직접 설정 체크박스 기본값 : true
      localStorage.setItem(`custom_checkbox_${LOCALSTORAGE_KEY}_${userInfo.user.m_no}`, 'true');

      const checkbox_opt = { show: true, checkbox_name: '직접 설정', key: LOCALSTORAGE_KEY,
        m_no: userInfo.user.m_no, checked: localStorage.getItem(`custom_checkbox_${LOCALSTORAGE_KEY}_${userInfo.user.m_no}`) === 'true',
        tooltip: '상품명, 브랜드명, 제조사명, 모델명 항목의 유사도를 직접 입력하여 상품 자동매칭을 설정할 수 있습니다.',
      };

      await commonSVC.showConfirmCheckboxCustom(opt_option, checkbox_opt, async function (confirm) {
        const checked = localStorage.getItem(`custom_checkbox_${LOCALSTORAGE_KEY}_${userInfo.user.m_no}`);
        if (confirm) {
          if (checked === 'true') {
            const resolve = {};
            const data = {};

            data.type = 'online_auto_match';
            data.base_match_list = base_match_list;
            data.masterProdCount = $scope.masterProdCount;
            resolve.data = data;

            const modal = commonSVC.openModal('lm', resolve, 'AutoMatchCtrl', 'views/online/product/modals/prod_match.html');

            modal.result.then(async function (re) {
              await workSVC.addSolWork('OnlineToMasterAutoMatch', { ...params, base_match_list: re });
            });
          } else {
            await workSVC.addSolWork('OnlineToMasterAutoMatch', params);
          }
        }
        if (!confirm) {
          return;
        }
      });
    };

    /**
     * 매칭 취소
     */
    $scope.cancelMatch = async () => {
      const selectedRows = $scope.online_grid3.methods.selectedData('all');
      if (selectedRows.length === 0) {
        commonSVC.showMessage('선택한 상품이 없습니다.');

        return false;
      }

      const params = {
        import_ol_shop_no_list: selectedRows.map(v => v.import_ol_shop_no),
      };

      try {
        await onlineProductModel.setScrapPhase(params);
        commonSVC.showToaster('success', '매칭 취소', '성공');
      } catch (err) {
        const msg = err?.data?.error || err?.message;
        commonSVC.showToaster('error', '매칭 취소 실패', msg);
      } finally {
        $timeout(function () {
          $scope.online_grid1.searchFn.searchDo();
          $scope.online_grid2.methods.setEmpty();
          $scope.online_grid3.searchFn.searchDo();
        }, 700);
      }
    };

    // 매칭 저장
    $scope.saveMatch = async (type) => {
      let selectedRows = type === 'match' ? $scope.online_grid3.methods.selectedData('all') : $scope.online_grid1.methods.selectedData('all');
      if (!selectedRows.length) {
        selectedRows = type === 'match' ? $scope.matchOnlineList : $scope.onlineList;
      }

      if (!selectedRows.length) {
        commonSVC.showToaster('error', `${type === 'match' ? '매칭' : ''} 등록 실패`, `등록할 ${type === 'match' ? '매칭' : ''}상품이 존재하지 않습니다. ${type === 'match' ? '매칭' : '수집'} 후 다시 등록 바랍니다.`);

        return false;
      }

      if (type === 'match' && selectedRows.some(v => !v.sale_no)) {
        commonSVC.showToaster('error', '매칭 등록 실패', '마스터상품이 존재하지 않습니다. 매칭 취소 후 다시 매칭 바랍니다.');

        return false;
      }

      const shopInfo = Object.keys(_.groupBy(selectedRows, o => o.sale_no + o.shop_cd + o.shop_id + o.std_ol_yn));
      if (type === 'match' && shopInfo.length !== selectedRows.length) {

        commonSVC.showToaster('error', '매칭 등록 실패', '쇼핑몰과 계정이 동일한 상품은 하나의 마스터 상품에 매칭 등록할 수 없습니다.');

        return false;
      }

      const params = {
        import_ol_shop_no_list: selectedRows.map(o => ({ import_ol_shop_no: o.import_ol_shop_no, ebay_shop_mas_no: o.ebay_shop_mas_no })),
        shopCds: [...new Set(selectedRows.map(o => o.shop_cd))],
        type
      };

      const confirm = await commonSVC.showConfirmHtml(
        `선택한 <span class="text-primary">${selectedRows.length}건</span>의 ${type === 'match' ? '매칭' : ''} 상품을 등록합니다.`,
        '등록 완료된 상품은 [상품 > 쇼핑몰 상품 관리]에서 확인할 수 있습니다.'
      );

      if (!confirm) {
        return;
      }

      try {
        const re = await onlineProductModel.scrapProdDataSave(params);
        if (re?.data?.result?.success) {
          commonSVC.showToaster('success', '매칭 등록', `${re?.data?.result?.success}건 성공 / ${re?.data?.result?.failed}건 실패`);
        } else {
          throw re;
        }

        $timeout(async () => {
          if (type === 'match') {
            $scope.online_grid3.searchFn.searchDo();
          } else {
            $scope.online_grid1.searchFn.searchDo();

            // 마스터 상품 추가 시 마스터 상품 개수 재조회
            // 등록된 마스터 상품 개수 조회
            $scope.masterProdCount = (await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/online/master-prod-count`, {})).data?.result || 0;
            // 등록된 마스터 상품이 없거나 100000개 이상인 경우 자동매칭 작업 불가
            $rootScope.canAutoSetting = 0 < $scope.masterProdCount && $scope.masterProdCount < 100000;
          }
        }, 700);

        const LOCALSTORAGE_KEY = 'from_product_scrap_match_list';
        const showGuide = localStorage.getItem(`not_show_${LOCALSTORAGE_KEY}_${userInfo.user.m_no}`);
        if (showGuide !== 'true' && $rootScope.user_profile.sol_stock > 0) {
          commonSVC.showConfirmCustom(
            {
              title: '매칭 등록 완료!<br>등록한 상품을 SKU상품과 매칭할 수 있어요.',
              text: '등록된 SKU상품이 있다면, 매칭하여 재고 관리를 할 수 있어요.<br>등록된 SKU상품이 없다면, 쇼핑몰 상품 옵션을 SKU상품으로 등록할 수 있습니다.',
              html: true,
              confirmButtonText: 'SKU상품 매칭하러 가기',
              cancelButtonText: '나중에 하기'
            },
            function(confirm) {
              if (confirm) {
              // SKU상품 매칭 이동
                $state.go('main.online_prod_match');
              }
            },
            { show: true, key: LOCALSTORAGE_KEY, m_no: userInfo.user.m_no }
          );
        }

      } catch (err) {
        const msg = err?.message
          || err?.data?.error
          || err?.data?.result?.errorList?.map(v => (v.shop_sale_no || v.c_sale_cd) + ': ' + errorSVC.getError('prod', v.errMsg, v.errMsg || '알수없는 오류입니다.\n계속 발생시 1:1 문의부탁드립니다.')).join('\n');
        commonSVC.showToaster('error', '매칭 등록 실패', msg);
      }

    };

  });
