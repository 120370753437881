/**
 * Form 관련 지시자만 이 파일에 모은다.
 *
 * 2016-04-27 : 남형진
 *
 */
'use strict';

angular.module('gmpApp')
  // input 최대 입력 가능 Byte 체크 2016-04-27 남형진
  .directive('maxBytes', function (commonSVC) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function ($scope, elem, attrs, ngModel) {

        function set_byte(str) {
          const max = attrs.maxBytes;
          let str_byte = str.byte2();

          // 문의의 경우 maxbyte 자르지 않음
          if (str_byte > max && !['answer_content', 'headerContent', 'footerContent'].includes(attrs.name)) {
            str = commonSVC.cutByByte(str, max);
            $(elem).val(str);
            str_byte = str.byte2();
          }

          $(elem).parent().find('.str_bytes').html(str_byte);
        }

        elem.bind('keydown keyup keypress', function() {
          const str = $(this).val();

          set_byte(str);
        });

        if (!attrs.noRender) {
          ngModel.$render = function () {
            if (ngModel.$viewValue || ngModel.$viewValue == '') {
              $(elem).val(ngModel.$viewValue);
              set_byte(ngModel.$viewValue);
            }
          };
        }
      }
    };
  })
/**
	 * input입력 글자 수 제한
	 */
  .directive('strMaxLength', function (commonSVC) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function ($scope, elem, attrs, ngModel) {

        //글자수 확인
        function set_str_length(str) {
          const max = attrs.strMaxLength;
          let str_length = str.length;

          if (str_length > max && $(elem).parent().find('.str_length').length) {
            str = commonSVC.cutByLength(str, max);
            $(elem).val(str);
            str_length = str.length;
          }
          $(elem).parent().find('.str_length').html(str_length);
        }

        // keydown keyup keypress change 이벤트 발생할 때 str 글자수 할당 함수 실행
        elem.bind('keydown keyup keypress change', function() {
          const str = $(this).val();

          set_str_length(str);
        });
        ngModel.$render = function () {
          if (ngModel.$viewValue || ngModel.$viewValue == '') {
            $(elem).val(ngModel.$viewValue);
            set_str_length(ngModel.$viewValue);
          }
        };

      }
    };
  })
  /**
   * 2020-01-22 Boris
   * 온라인상품 상품명 byte 자리수 체크용
   */
  .directive('onlineProdNameCheck', function (onlineProductSVC) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function ($scope, elem, attrs, ngModel) {
        const [pa_shop_cd, std_ol_yn, className] = attrs.onlineProdNameCheck.split(',');
        const { shop_sale_name_max_length, shop_sale_name_max_length_char_yn } = onlineProductSVC.getSaleNameLimiter(pa_shop_cd);
        const maxLength = shop_sale_name_max_length || 100;

        const set_str_lengthORbyte = (str) => {
          const isUtf8 = onlineProductSVC.utf8ShopCodes.includes(pa_shop_cd);

          const count_display = shop_sale_name_max_length_char_yn ? str.length : onlineProductSVC.byteCheck(str, isUtf8);

          $(elem).parent().parent().find(`.online-prod-name-check-${className}`).html(
            count_display > maxLength ?
              `<span style="color: red">${count_display}</span> / ${maxLength}${shop_sale_name_max_length_char_yn ? '자' : 'byte'}` :
              `${count_display} / ${maxLength}${shop_sale_name_max_length_char_yn ? '자' : 'byte'}`
          );
        };

        elem.bind('keydown keyup keypress', function() {
          const str = $(this).val();

          set_str_lengthORbyte(str);
        });

        ngModel.$render = function () {
          // 쇼핑몰코드로 제한할 max값과
          if (ngModel.$viewValue || ngModel.$viewValue == '') {
            $(elem).val(ngModel.$viewValue);
            // setTimeout안하면 엘리멘트를 못잡아서 적용이안됨
            // set_str_lengthORbyte(ngModel.$viewValue)
            setTimeout(() => set_str_lengthORbyte(ngModel.$viewValue), 500);
          }
        };

      }
    };
  })
  /**
   * 2020-01-22 Boris
   * 온라인상품 프로모션상품명 byte 자리수 체크용
   */
  .directive('onlineProdPromotionCheck', function (onlineProductSVC) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function ($scope, elem, attrs, ngModel) {
        const [shop_cd, std_ol_yn, className] = attrs.onlineProdPromotionCheck.split(',');
        const { byte: max } = onlineProductSVC.promotionProductName[`${shop_cd}${std_ol_yn === '1' ? '_single' : ''}`];

        const set_str_lengthORbyte = (str) => {
          const count_display = str.byte2();

          $('body').find(`.online-prod-promotion-check-${className}`).html(
            count_display > max ?
              `<span style="color: red">${count_display}</span> / ${max}byte` :
              `${count_display} / ${max}byte`
          );
        };

        elem.bind('keydown keyup keypress', function() {
          const str = $(this).val();

          set_str_lengthORbyte(str);
        });

        ngModel.$render = function () {
          if (ngModel.$viewValue || ngModel.$viewValue == '') {
            $(elem).val(ngModel.$viewValue);
            // setTimeout안하면 엘리멘트를 못잡아서 적용이안됨
            // set_str_lengthORbyte(ngModel.$viewValue)
            setTimeout(() => set_str_lengthORbyte(ngModel.$viewValue), 500);
          }
        };

      }
    };
  })
  /**
   * 2018-02-27 ally
   * 같은 종류의 작업은 작업완료 전까지 중복으로 안눌리게 하기 위함 workSvc에서 풀어줌
   *
   * 2019-02-11 chris
   * 적용한 컨트롤러에서 waitBtnDisableFlag값으로 로딩해제할수있도록 변경
   *
   * 2019-02-19 rony
   * submit 버튼 클릭시 click 이벤트보다 watch 이벤트가 먼저 돌기 때문에 버튼을 두번클릭해야 버튼 비활성화 되는 문제 발생.
   * 버튼 클릭시 무조건 스피너 돌도록 처리함.
   *
   * !주의
   * waitWorkBtn 디렉티브 사용하는 부분에서 ng-if를 사용하는 경우 스코프가 새로 생성돼 waitBtnDisableFlag 값이 정상작동X
   * ng-if 대신 ng-show 사용 바람
   */
  .directive('waitWorkBtn', function ($timeout) {
    return {
      restrict: 'A',
      scope: {
        notAutoSpinner: '=', // 스피너 자동으로 시작하지 않을지 여부
      },
      link: function ($scope, elem) {
        $timeout(function() {
          $scope.$parent.waitBtnDisableFlag = false;
          const btnText = elem.html();

          // 클릭 이벤트가 발생하면 무조건 스피너 돌림.
          elem.bind('click', function () {
            if (!$scope.notAutoSpinner) {
              $scope.$parent.waitBtnDisableFlag = true;
              elem.attr('disabled', true).html('<i class="icon-spinner3 spinner"></i>');
            }
          });

          $scope.$parent.$watch('waitBtnDisableFlag', function (value) {
            if (value === undefined) { return; }
            if (value) {
              elem.attr('disabled', true).html('<i class="icon-spinner3 spinner"></i>');
            } else {
              elem.attr('disabled', false).html(btnText);
              // 두번째 클릭시부터는 버튼 활성화가 안되서 추가.
              $scope.$parent.waitBtnDisableFlag = undefined;
            }
          });
        });
      }
    };
  })

  .directive('throttleFnBtn', function ($compile, $timeout) {
    return {
      restrict: 'A',
      scope: {
        fn: '&', // 실행시킬 함수 바인딩
        noAutoEnable: '=', // 버튼 자동 활성화 안할지 여부
      },
      link: function (scope, elem) {
        const btnText = elem.html();

        elem.bind('click', _.throttle(function () {
          // 클릭시 무조건 스피너 돌림
          elem.attr('disabled', true).html('<i class="icon-spinner3 spinner"></i>');

          const result = scope.fn();

          //sendURL을 지나기 전 return false를 하는 경우 스피너 복구
          if (result === false) {
            setTimeout(function () {
              elem.attr('disabled', false).html(btnText);
              $compile(elem.contents())(scope);
              $timeout(() => {
                scope.$parent.$apply();
              });
            }, 800);

            return false;
          } else if (result && typeof result.then === 'function') {
            //sendURL 이후 promise 응답만 가능
            //then(res) 또는 catch(e)에서 return false를 하는 경우 스피너 복구
            result.then(function (res) {
              if (res === false) {
                // 요청 후 쓰로틀링 걸린 시간이 0.8초라 맞춤
                setTimeout(function () {
                  elem.attr('disabled', false).html(btnText);
                  $compile(elem.contents())(scope.$parent);
                  $timeout(() => {
                    scope.$parent.$apply();
                  });
                }, 800);

                return false;
              } else {
                setTimeout(function () {
                  if (!scope.noAutoEnable) {
                    elem.attr('disabled', false);
                  }
                  elem.html(btnText);
                  $compile(elem.contents())(scope.$parent);
                  $timeout(() => {
                    scope.$parent.$apply();
                  });
                }, 800);

                return res;
              }
            });
          } else {
            setTimeout(function () {
              elem.attr('disabled', false).html(btnText);
              $compile(elem.contents())(scope.$parent);
              $timeout();
            }, 800);

            return result;
          }
          // trailing => 클릭 여러번했을 때 0.8초 후에 다시 자동 실행할 지 여부
        }, 800, { trailing: false }));
      }
    };
  })

  // 긴급배포용 임시 쓰로틀버튼
  // width 계산하는 부분이 제대로 동작하지 않아 없는 버전으로 제작
  .directive('throttleFnBtnTemp', function () {
    return {
      restrict: 'A',
      scope: {
        fn: '&' // 실행시킬 함수 바인딩
      },
      link: function (scope, elem) {
        const btnText = elem.html();

        elem.bind('click', _.throttle(function () {
          // 클릭시 무조건 스피너 돌림
          // .width(width) 제거
          elem.attr('disabled', true).html('<i class="icon-spinner3 spinner"></i>');

          const result = scope.fn();

          //sendURL을 지나기 전 return false를 하는 경우 스피너 복구
          if (result === false) {
            setTimeout(function () {
              elem.attr('disabled', false).html(btnText);
            }, 800);

            return false;
          } else if (result && typeof result.then === 'function') {
            //sendURL 이후 promise 응답만 가능
            //then(res) 또는 catch(e)에서 return false를 하는 경우 스피너 복구
            result.then(function (res) {
              if (res === false) {
                // 요청 후 쓰로틀링 걸린 시간이 0.8초라 맞춤
                setTimeout(function () {
                  elem.attr('disabled', false).html(btnText);
                }, 800);

                return false;
              } else {
                setTimeout(function () {
                  elem.attr('disabled', false).html(btnText);
                }, 800);

                return res;
              }
            });
          } else {
            setTimeout(function () {
              elem.attr('disabled', false).html(btnText);
            }, 800);

            return result;
          }
          // trailing => 클릭 여러번했을 때 0.8초 후에 다시 자동 실행할 지 여부
        }, 800, { trailing: false }));
      }
    };
  })

  .directive('waitBtn', function () {
    return {
      restrict: 'A',
      scope: {
        show: '=waitBtn',
      },
      link: function (scope, [element]) {
        let originHTML;
        let isHided = false;
        scope.$watch('show', function (value) {
          /* 최초 할당 시에는 처리하지 않음 */
          if (value === true && isHided === false) {
            originHTML = element.innerHTML;
            const span = document.createElement('span');
            span.style.marginBottom = 0;
            span.innerHTML = '<i class="icon-spinner3 spinner"></i>';
            element.innerHTML = '';
            element.appendChild(span);
            element.setAttribute('disabled', true);
            isHided = true;
          } else if (value === false && isHided === true) {
            element.innerHTML = originHTML;
            element.removeAttribute('disabled');
            isHided = false;
          }
        });
      },
    };
  })

  .directive('phoneCheck', function () {
    return {
      require: '?ngModel',
      restrict: 'A',
      link: function (scope, elm, attrs, ngModel) {
        elm.keyup(function (event) {
          const key = event.which || event.keyCode || event.charCode;

          if (key !== 8 && key !== 46) {
            if (ngModel.$viewValue.indexOf('02') === 0) {
              if (ngModel.$viewValue.length === 2 || ngModel.$viewValue.length === 6) {
                elm.val(`${ngModel.$viewValue}-`);
              } else if (ngModel.$viewValue.length === 12) {
                elm.val(`${ngModel.$viewValue.substr(0, 6) + ngModel.$viewValue.substr(7, 1)}-${ngModel.$viewValue.substr(8, 4)}`);
              }
            } else {
              if (ngModel.$viewValue.length === 3 || ngModel.$viewValue.length === 7) {
                elm.val(`${ngModel.$viewValue}-`);
              } else if (ngModel.$viewValue.length === 13) {
                elm.val(`${ngModel.$viewValue.substr(0, 7) + ngModel.$viewValue.substr(8, 1)}-${ngModel.$viewValue.substr(9, 4)}`);
              }
            }
          }
        });
      }
    };
  })

  // text Editor 2016-05-02 남형진
  // 다 지워졌을시 모델값으로 남는 버그때문에 수정 2018-09-06 Daniel
  .directive('ckEditor', function($timeout) {
    return {
      require: '?ngModel',
      link: function (scope, element, attrs, ngModel) {
        let editor;
        // 기본 모드 wysiwyg : 미리보기, source : 소스보기
        const startupmode = $(element).data('startupmode') || 'wysiwyg';

        const config = {
          allowedContent: true,
          fillEmptyBlocks: false,
          autoParagraph: false,
          height: attrs.height || '400px',
          extraPlugins: 'forms',
          startupMode: startupmode,
          font_names: `나눔고딕/나눔고딕, NanumGothic, Nanum Gothic; 맑은고딕/맑은 고딕, MalgunGothic, Malgun Gothic, malgun gothic, malgungothic; ${CKEDITOR.config.font_names}`
        };
        if (element[0].classList[0] === 'inline') {
          editor = CKEDITOR.inline(element[0], config);
        } else {
          editor = CKEDITOR.replace(element[0], config);
        }
        if (!ngModel) {
          return;
        }
        editor.on('instanceReady', function() {
          editor.setData(ngModel.$viewValue);
          $(`.${editor.id}`).on('paste', () => {
            updateModel();
          });
        });

        // 이벤트 추가
        function updateModel() {
          // $digest already in progress 에러가 발생되어 조건문 추가 2018-06-01 rony
          // $timeout 추가 2018-07-19 chris
          $timeout(function() {
            const editorData = editor.getData();

            ngModel.$setViewValue(editorData);

            if (!scope.$$phase && !scope.$root.$$phase) {
              scope.$apply();
            }
          }, 100);
        }
        editor.on('change', updateModel);
        editor.on('key', updateModel);
        // editor.on('dataReady', updateModel);

        ngModel.$render = function() {
          editor.setData(ngModel.$viewValue);
        };
      }
    };
  })
  // bootstrapSwitch 2016-06-01 김민영
  .directive('styleSwitch', function() {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function(scope, element, attrs) {
        if (attrs.value == 'true') {
          element.attr('checked', 'checked');
        }
      }
    };
  })

  /**
   * ng-enter 추가 ng-enter="searchDo()"
   * 2016-06-27 권윤학
   */
  .directive('ngEnter', function() {
    return function (scope, element, attrs) {
      element.bind('keydown keypress', function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEnter);
          });

          event.preventDefault();
        }
      });
    };
  })

  /**
   * dateRangePicker 추가
   * 2016-10-14 남형진
   */
  .directive('dateRangePicker', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        let instance;

        let time = false;
        let format = 'YYYY-MM-DD';

        if (attrs.time == true || attrs.time == 'true') {
          time = true;
          format = 'YYYY-MM-DD h:mm a';
        }

        let minDate, maxDate;
        const date = attrs.minDate;

        if (!date && date !== '') {
          minDate = new Date('2012-01-01');
        } else {
          if (date !== '') {
            minDate = new Date(attrs.minDate);
          } else {
            minDate = new Date();
          }
        }

        if (attrs.restrictToday) {
          maxDate = new Date();
        }

        // 2016-12-15 남형진 달력 테그 추가 위치 설정
        let parentEl = 'body';

        if (attrs.isModal == 'true') {
          parentEl = "div[class='modal-body']";
        }

        ngModel.$render = function() {

          $(element).val(ngModel.$viewValue);

          // Basic initialization
          $(element).daterangepicker({
            minDate: minDate,
            maxDate: maxDate,
            parentEl: parentEl,
            applyClass: 'bg-slate-600',
            cancelClass: 'btn-default',
            timePicker: time,
            autoApply: false,
            showDropdowns: true,
            locale: {
              format: format,
              separator: ' ~ ',
              customRangeLabel: 'Custom',
              // 2017-03-23 MatthewKim
              // monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
              // daysOfWeek: ["일","월","화","수","목","금","토"]
            },
            template: '<div class="daterangepicker dropdown-menu">' +
            '<div class="calendars">' +
            '<div class="calendar left">' +
            '<div class="calendar-table"></div>' +
            '<div class="daterangepicker_input">' +
            '<div class="calendar-time">' +
            '<div></div>' +
            '</div>' +
            '</div>' +
            '</div>' +

            '<div class="calendar right">' +
            '<div class="calendar-table"></div>' +
            '<div class="daterangepicker_input">' +
            '<div class="calendar-time">' +
            '<div></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +

            '<div class="ranges">' +
            '<div class="daterangepicker-inputs">' +
            '<div class="daterangepicker_input">' +
            '<span class="start-date-label">시작일</span>' +
            '<input class="form-control" type="text" name="daterangepicker_start" value="" />' +
            '<i class="icon-calendar3"></i>' +
            '</div>' +
            '<div class="daterangepicker_input">' +
            '<span class="end-date-label">종료일</span>' +
            '<input class="form-control" type="text" name="daterangepicker_end" value="" />' +
            '<i class="icon-calendar3"></i>' +
            '</div>' +
            '</div>' +
            '<div class="range_inputs">' +
            '<button class="applyBtn" disabled="disabled" type="button"></button> ' +
            '<button class="cancelBtn" type="button"></button>' +
            '</div>' +
            '</div>' +
            '</div>'
          });

          instance = element.data('daterangepicker');
        };

        /* 스코프가 제거될 때 daterangepicker 함께 제거 */
        scope.$on('$destroy', () => {
          if (instance) {
            instance.remove();
          }
        });
      }
    };
  })

  /**
   * timePicker 추가
   */
  .directive('timePicker', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        // 분(minute) 단위의 시간값을 받아서 hh:mm:ss 형식의 문자열로 반환
        function getFormattedTimeValue(minute) {
          return `${('00' + Math.floor(minute / 60)).slice(-2)}:${('00' + minute % 60).slice(-2)}:00`;
        }

        // 분(minute) 단위의 시간값을 받아서 오전/오후 hh:mm 형식의 문자열로 반환
        function getFormattedDisplayTime(minute) {
          return `${minute / 60 >= 12 && minute / 60 < 24 ? '오후' : '오전'} ${('00' + (Math.floor(minute / 60 + 11) % 12 + 1)).slice(-2)}:${('00' + minute % 60).slice(-2)}`;
        }

        const step = +attrs.timestep;
        const etime = attrs.type === 'edate' ? 10 : 0;

        // 00:00:00부터 23:59:00까지 step 분만큼 increment하면서 select 옵션 생성
        for (let i = 0; i < 24 * 60; i += step) {
          const time_option = document.createElement('option');
          time_option.value = getFormattedTimeValue(i + etime);
          time_option.innerText = getFormattedDisplayTime(i + etime);
          element.append(time_option);
        }

        const initial_display_time = ngModel.$modelValue || '00:00:00';
        element[0].value = initial_display_time;

        const unregister = scope.$watch(function () {
          return ngModel.$modelValue;
        }, initialize);

        function initialize(value) {
          const init_value = value || '00:00:00';
          element[0].value = init_value;
          // TimePicker 초기화 시 유효하지 않은 옵션이 생기는 경우가 있어서 해당 옵션 삭제 처리
          element.find('option').toArray().find(el => el.value.indexOf('?') > -1)?.remove();
          // 초깃값이 없는 경우를 대비해서 초깃값 설정
          ngModel.$setViewValue(init_value);
          unregister();
        }

        element.on('change', () => {
          ngModel.$setViewValue(element[0].value);
        });
      }
    };
  })
  /**
   * dateSinglePicker 추가
   * 2016-10-26 김민영
   */
  .directive('dateSinglePicker', function (commonSVC, $timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        isDropUp: '=?',
        searchSdate: '=?',
        searchEdate: '=?',
      },
      link: function(scope, element, attrs, ngModel) {
        let instance;

        let time = false;
        let hour = false;
        let format = attrs.format || 'YYYY-MM-DD';

        // scope.ori_sdate = attrs.ngModel;

        if (attrs.time == true || attrs.time == 'true') {
          time = true;
          format = 'YYYY-MM-DD HH:mm';

          if (attrs.hour == '24') {
            hour = true;
          }
        }

        let minDate;
        const date = attrs.minDate;

        if (!date && date !== '') {
          minDate = new Date('2012-01-01');
        } else {
          if (date !== '') {
            minDate = new Date(attrs.minDate);
          } else {
            minDate = new Date();
          }
        }

        let placeholder = 'YYYY-MM-DD';

        //2018-03-20 김승호 place holder
        if (attrs.placeholder) {
          placeholder = attrs.placeholder;
        }

        // 2016-12-15 남형진 달력 테그 추가 위치 설정
        let parentEl = 'body';

        if (attrs.isModal == 'true') {
          parentEl = "div[class='modal-body']";
        } else if (attrs.isPanel == 'true') {
          parentEl = "div[class='panel-body']";
        } else if (attrs.isPositionFix) {
          parentEl = `div[id='${attrs.isPositionFix}']`;
        }

        scope.$watchCollection('searchSdate', function(newValue) {
          if (newValue && attrs.maxSpanYears) {
            if (moment(newValue) < moment(scope.searchEdate).subtract(attrs.maxSpanYears, 'years')) {
              commonSVC.showToaster('error', '날짜선택 오류', `데이터 최대 조회기간은 ${attrs.maxSpanYears}년입니다.`);

              $timeout(() => {
                scope.searchSdate = moment(scope.searchEdate).subtract(attrs.maxSpanYears, 'years').format(format);
              });
            }
          }
        });

        ngModel.$render = function() {
          $(element).val(ngModel.$viewValue);

          //place holder
          element.attr('placeholder', placeholder);

          const opt = {
            minDate: minDate,
            parentEl: parentEl,
            singleDatePicker: true,
            applyClass: 'bg-slate-600',
            cancelClass: 'btn-default',
            timePicker: time,
            timePicker24Hour: hour,
            autoUpdateInput: false,
            autoApply: false,
            showDropdowns: true,
            drops: scope.isDropUp ? 'up' : 'down',
            locale: {
              format: format,
              separator: ' ~ ',
              customRangeLabel: 'Custom',
              // 별도로 지정하지 않으면 현재 적용중인 로케일에 맞춰서 동작함 2017-03-23 MatthewKim
              // monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
              // daysOfWeek: ["일","월","화","수","목","금","토"]
            }
          };

          if (attrs.restrictToday) {
            opt.maxDate = commonSVC.getDate(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
          }

          // Basic initialization
          $(element).daterangepicker(opt, function(chosen_date) {
            // 기본값을 없애고 선택시 들어가는 함수를 따로 추가해줌
            ngModel.$setViewValue(chosen_date.format(format));
            $(element).val(chosen_date.format(format));
          });

          instance = element.data('daterangepicker');
        };

        /* leftContent sidebar에서 스크롤시 dateSinglePicker hide 처리 */
        $('.left_content').scroll(function() {
          if (instance) {
            instance.hide();
          }
        });

        /* 스코프가 제거될 때 daterangepicker 함께 제거 */
        scope.$on('$destroy', () => {
          if (instance) {
            instance.remove();
          }
        });
      }
    };
  })

/**
   * dateSinglePicker2 추가
   * 2016-11-24 김승호
   * 시작날짜 이전 선택 불가
   */

  .directive('dateSinglePicker2', function (commonSVC, $timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        isDropUp: '=?',
        searchSdate: '=?',
        searchEdate: '=?',
        maxSpanMonths: '=?',
        maxSpanYears: '=?',
      },
      link: function(scope, element, attrs, ngModel) {
        let instance;

        let time = false;
        let format = 'YYYY-MM-DD';

        if (attrs.time == true || attrs.time == 'true') {
          time = true;
          format = 'YYYY-MM-DD h:mm a';
        }

        // 2017-12-06 Daniel 날짜포맷 변경 추가
        if (attrs.format) {
          format = attrs.format;
        }

        let placeholder = 'YYYY-MM-DD';

        if (attrs.placeholder) {
          placeholder = attrs.placeholder;
        }

        // 2016-12-15 남형진 달력 테그 추가 위치 설정
        let parentEl = 'body';

        if (attrs.isModal == 'true') {
          parentEl = "div[class='modal-body']";
        } else if (attrs.isPanel == 'true') {
          parentEl = "div[class='panel-body']";
        } else if (attrs.isPositionFix) {
          parentEl = `div[id='${attrs.isPositionFix}']`;
        }

        // 연,월 단위 날짜 제한
        scope.$watchCollection('searchEdate', function (newValue) {
          if (newValue && (+scope.maxSpanMonths || +scope.maxSpanYears)) {
            const isYearLimit = !!+scope.maxSpanYears,
                  maxSpan = isYearLimit ? +scope.maxSpanYears : +scope.maxSpanMonths;

            const maxDate = moment(scope.searchSdate).add(maxSpan, isYearLimit ? 'years' : 'months');

            if (moment(newValue) > maxDate) {
              const errorText = `${maxSpan}${isYearLimit ? '년' : '개월'}`;
              commonSVC.showToaster('error', '날짜선택 오류', `데이터 최대 조회기간은 ${errorText}입니다.`);

              $timeout(() => {
                scope.searchSdate = moment(scope.searchEdate).subtract(maxSpan, isYearLimit ? 'years' : 'months').format(format);
              });
            }
          }
        });

        scope.$watchCollection('searchSdate', function (newValue) {
          if (!newValue) {
            return;
          }
          const opt = {
            parentEl: parentEl,
            singleDatePicker: true,
            minDate: newValue,
            applyClass: 'bg-slate-600',
            cancelClass: 'btn-default',
            timePicker: time,
            autoApply: false,
            showDropdowns: true,
            drops: scope.isDropUp ? 'up' : 'down',
            locale: {
              format: format,
              separator: ' ~ ',
              customRangeLabel: 'Custom',
              // 별도로 지정하지 않으면 현재 적용중인 로케일에 맞춰서 동작함 2017-03-23 MatthewKim
              // monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
              // daysOfWeek: ["일","월","화","수","목","금","토"]
            },
          };

          if (attrs.restrictToday) {
            opt.maxDate = commonSVC.getDate(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
          }

          // 2017-06-28 Harry 시작일~종료일 날짜 제한
          if (attrs.limit) {
            const today = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
            let maxDate = new Date(newValue);

            maxDate = commonSVC.getDate(maxDate.setDate(maxDate.getDate() + attrs.limit * 1), 'yyyy-MM-dd');
            if (today < maxDate) {
              maxDate = today;
            }
            opt.maxDate = maxDate;
          }

          // 연,월 단위 날짜 제한
          if (newValue && (+scope.maxSpanMonths || +scope.maxSpanYears)) {
            const isYearLimit = !!+scope.maxSpanYears,
                  maxSpan = isYearLimit ? +scope.maxSpanYears : +scope.maxSpanMonths;

            $timeout(() => {
              const maxDate = moment(scope.searchSdate).add(maxSpan, isYearLimit ? 'years' : 'months');

              if (moment(scope.searchEdate) > maxDate) {
                const errorText = `${maxSpan}${isYearLimit ? '년' : '개월'}`;
                commonSVC.showToaster('error', '날짜선택 오류', `데이터 최대 조회기간은 ${errorText}입니다.`);

                $timeout(() => {
                  scope.searchEdate = maxDate.format(format);
                });
              }
            });
          }
          // Basic initialization
          $(element).daterangepicker(opt);

          instance = element.data('daterangepicker');
        });

        ngModel.$render = function() {
          $(element).val(ngModel.$viewValue);

          //place holder
          element.attr('placeholder', placeholder);

          const opt = {
            parentEl: parentEl,
            singleDatePicker: true,
            applyClass: 'bg-slate-600',
            cancelClass: 'btn-default',
            timePicker: time,
            autoApply: false,
            autoUpdateInput: attrs.autoUpdateInput !== 'false',
            showDropdowns: true,
            locale: {
              format: format,
              separator: ' ~ ',
              customRangeLabel: 'Custom',
              // 별도로 지정하지 않으면 현재 적용중인 로케일에 맞춰서 동작함 2017-03-23 MatthewKim
              // monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
              // daysOfWeek: ["일","월","화","수","목","금","토"]
            },
            maxDate: attrs.restrictToday ? commonSVC.getDate(new Date(new Date().getTime() - 24 * 60 * 60 * 1000)) : null,
          };

          // Basic initialization
          $(element).daterangepicker(opt, function(chosen_date) {
            // 기본값을 없애고 선택시 들어가는 함수를 따로 추가해줌
            ngModel.$setViewValue(chosen_date.format(format));
            $(element).val(chosen_date.format(format));
          });

          instance = element.data('daterangepicker');
        };

        /* leftContent sidebar에서 스크롤시 dateSinglePicker hide 처리 */
        $('.left_content').scroll(function() {
          if (instance) {
            instance.hide();
          }
        });

        /* 스코프가 제거될 때 daterangepicker 함께 제거 */
        scope.$on('$destroy', () => {
          if (instance) {
            instance.remove();
          }
        });
      }
    };
  })

  /**
   * dateRangePicker1
   * 2018-09-05 noah
   * 오늘 이후 데이터 선택 불가 / 초기 빈 값
   * 시작날짜 미만으로 종료날짜 선택 불가 / 종료날짜 이후로 시작날짜 설정 불가
   */
  .directive('dateRangePicker1', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        let instance;

        let format = 'YYYY-MM-DD';

        scope.ori_sdate = attrs.ngModel;

        if (attrs.time == true || attrs.time == 'true') {
          format = 'YYYY-MM-DD h:mm a';
        }

        // 2016-12-15 남형진 달력 테그 추가 위치 설정
        let parentEl = 'body';

        if (attrs.isModal == 'true') {
          parentEl = "div[class='modal-body']";
        }

        ngModel.$render = function() {
          $(element).val(ngModel.$viewValue);

          // Basic initialization
          $(element).daterangepicker({
            parentEl: parentEl,
            singleDatePicker: true,
            applyClass: 'bg-slate-600',
            cancelClass: 'btn-default',
            autoUpdateInput: false,
            autoApply: false,
            showDropdowns: true,
            maxDate: moment().format(format),
            locale: {
              format: format,
              separator: ' ~ ',
              customRangeLabel: 'Custom',
            }
          }, function(a) {
            ngModel.$setViewValue(moment(a).format(format));
            $(element).val(a.format(format));
          });

          instance = element.data('daterangepicker');
        };

        /* 스코프가 제거될 때 daterangepicker 함께 제거 */
        scope.$on('$destroy', () => {
          if (instance) {
            instance.remove();
          }
        });
      }
    };
  })
  /**
   * dateRangePicker2
   * 2018-09-05 noah
   * 오늘 이후 데이터 선택 불가 / 초기 빈 값
   * 시작날짜 미만으로 종료날짜 선택 불가 / 종료날짜 이후로 시작날짜 설정 불가
   */
  .directive('dateRangePicker2', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        searchSdate: '=?',
      },
      link: function(scope, element, attrs, ngModel) {
        let instance;

        const format = 'YYYY-MM-DD';
        const minDate = attrs.minDate ? moment(attrs.minDate).format(format) : moment('2000-01-01');
        const maxDate = moment().format(format);

        scope.ori_edate = attrs.ngModel;

        // 2016-12-15 남형진 달력 테그 추가 위치 설정
        let parentEl = 'body';

        if (attrs.isModal == 'true') {
          parentEl = "div[class='modal-body']";
        }

        const opt = {
          parentEl,
          singleDatePicker: true,
          applyClass: 'bg-slate-600',
          cancelClass: 'btn-default',
          showDropdowns: true,
          autoUpdateInput: false,
          autoApply: false,
          minDate,
          maxDate,
          locale: {
            format: format,
            separator: ' ~ ',
            customRangeLabel: 'Custom'
          }
        };

        scope.$watchCollection('searchSdate', (function(newValue) {
          const momentNewValue = moment(newValue);
          const momentViewValue = moment(ngModel.$viewValue);
          let start = momentNewValue, end = momentViewValue;

          if (momentNewValue.isAfter(maxDate)) {
            ngModel.$setViewValue(maxDate.format(format));
            $(element).val(maxDate.format(format));
            start = maxDate;
            end = maxDate;
          } else if (moment.duration(momentViewValue.diff(momentNewValue)).asMilliseconds() < 0) {
            ngModel.$setViewValue(momentNewValue.format(format));
            $(element).val(momentNewValue.format(format));
            start = momentNewValue;
            end = momentNewValue;
          }
          // Basic initialization
          $(element).daterangepicker(opt, function(a) {
            if (moment(a).isAfter(newValue)) {
              ngModel.$setViewValue(a.format(format));
              $(element).val(a.format(format));
            } else {
              ngModel.$setViewValue(newValue);
              $(element).val(newValue);
            }

            if (attrs.setRange) {
              element.data('daterangepicker').setStartDate(newValue);
              element.data('daterangepicker').setEndDate(a.format(format));
            }
          });

          if (attrs.setRange) {
            element.data('daterangepicker').setStartDate(start);
            element.data('daterangepicker').setEndDate(end.format(format));
          }

          instance = element.data('daterangepicker');

          if (attrs.setRange) {
            // 달력이 열릴 때 이벤트 처리 (picker 초기화 이후 이벤트 바인딩)
            $(element).on('show.daterangepicker', function(ev, picker) {
              const endDate = picker.endDate; // 현재 설정된 끝 날짜
              const endYear = endDate.year(); // 끝 날짜의 연도
              const endMonth = endDate.month(); // 끝 날짜의 월 (0부터 시작, 0: January, 11: December)

              // 연도와 월을 선택하여 달력 포커스를 이동
              const $calendar = picker.container.find('.calendar.left');

              // 연도와 월 선택기 설정
              $calendar.find('.yearselect').val(endYear).trigger('change');
              $calendar.find('.monthselect').val(endMonth).trigger('change');

            });
          }
        }), true);

        ngModel.$render = function() {
          $(element).val(ngModel.$viewValue);

          // Basic initialization
          $(element).daterangepicker(opt, function(a) {
            ngModel.$setViewValue(moment(a).format(format));
            $(element).val(a.format(format));
          });

          instance = element.data('daterangepicker');
        };

        /* 스코프가 제거될 때 daterangepicker 함께 제거 */
        scope.$on('$destroy', () => {
          if (instance) {
            instance.remove();
          }
        });

      }
    };
  })

  /**
   * dateRangePicker3
   * 오늘 이후 날짜 선택 불가
   * 종료날짜 선택이 있으면 range 설정함
   */
  .directive('dateRangePicker3', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        searchEdate: '=?',
      },
      link: function(scope, element, attrs, ngModel) {
        let instance;

        const format = 'YYYY-MM-DD';
        const maxDate = moment().format(format);

        // 2016-12-15 남형진 달력 테그 추가 위치 설정
        let parentEl = 'body';

        if (attrs.isModal == 'true') {
          parentEl = "div[class='modal-body']";
        }

        const opt = {
          parentEl,
          singleDatePicker: true,
          minDate: attrs.minDate ? moment(attrs.minDate).format(format) : moment('2000-01-01'),
          maxDate,
          applyClass: 'bg-slate-600',
          cancelClass: 'btn-default',
          autoApply: false,
          autoUpdateInput: false,
          showDropdowns: true,
          locale: {
            format: format,
            separator: ' ~ ',
            customRangeLabel: 'Custom'
          }
        };

        scope.$watchCollection('searchEdate', (function(newValue) {
          const momentViewValue = moment(ngModel.$viewValue);

          // Basic initialization
          $(element).daterangepicker(opt, function(a) {
            ngModel.$setViewValue(a.format(format));
            $(element).val(a.format(format));

            if (attrs.setRange) {
              element.data('daterangepicker').setStartDate(a.format(format));
              element.data('daterangepicker').setEndDate(newValue);
            }
          });

          if (attrs.setRange) {
            element.data('daterangepicker').setStartDate(momentViewValue);
            element.data('daterangepicker').setEndDate(newValue);
          }

          instance = element.data('daterangepicker');
        }), true);

        ngModel.$render = function() {
          const viewValue = ngModel.$viewValue;

          // 유효하지 않은 값이나 빈 값이 들어왔을 때 처리할 함수
          function validateAndSetValue(enteredValue) {
            const formats = ['YYYY-MM-DD', 'YYYY-MM-D', 'YYYY-MMDD', 'YYYYMMD', 'YYYY-MM', 'YYYY-MM-DD'];

            let validDate = null;

            // 다양한 형식으로 입력 값을 파싱 시도
            for (const format of formats) {
              const parsedDate = moment(enteredValue, format, true);
              if (parsedDate.isValid()) {
                validDate = parsedDate;
                break; // 유효한 날짜를 찾으면 반복 종료
              }
            }

            // 유효한 날짜가 없는 경우
            if (!validDate) {
              $(element).val(maxDate);
              ngModel.$setViewValue(maxDate);
            } else if (validDate && validDate.isAfter(moment(maxDate))) {
              $(element).val(maxDate);
              ngModel.$setViewValue(maxDate);
            } else {
              $(element).val(validDate.format('YYYY-MM-DD')); // 원하는 형식으로 설정
              ngModel.$setViewValue(validDate.format('YYYY-MM-DD'));
            }
          }

          // 사용자가 입력 중일 때는 바로 유효성 검사를 하지 않음
          $(element).val(viewValue);  // 일단 입력된 값을 보여줌

          // 사용자가 값을 모두 입력했을 때 유효성 검사
          $(element).off('keyup').on('keyup', function() {
            const enteredValue = $(element).val();

            // 문자열 길이를 10자로 제한
            if (enteredValue.length > 10) {
              $(element).val(enteredValue.slice(0, 10)); // 10자 이상일 경우 잘라냄
            }
          });

          $(element).off('blur').on('blur', function() {
            const enteredValue = $(element).val();

            if (enteredValue.length > 0 && scope.searchEdate) {
              validateAndSetValue(enteredValue);
            }
          });

          // Basic initialization
          $(element).daterangepicker(opt, function(a) {
            ngModel.$setViewValue(moment(a).format(format));
            $(element).val(a.format(format));
          });

          instance = element.data('daterangepicker');
        };

        /* 스코프가 제거될 때 daterangepicker 함께 제거 */
        scope.$on('$destroy', () => {
          if (instance) {
            instance.remove();
          }
        });

      }
    };
  })

  /**
  * dateSinglePickerNoDefault 추가
  * 2017-09-13 서상권
  * 기본값이 빈값인 데이트피커 추가.
  */
  .directive('dateSinglePickerNoDefault', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        isDropUp: '=?'
      },
      link: function(scope, element, attrs, ngModel) {
        let instance;

        let time = false;
        let format = 'YYYY-MM-DD';

        scope.ori_sdate = attrs.ngModel;
        if (attrs.time == true || attrs.time == 'true') {
          time = true;
          format = 'YYYY-MM-DD h:mm a';
        }

        // 2016-12-15 남형진 달력 테그 추가 위치 설정
        let parentEl = 'body';

        if (attrs.isModal == 'true') {
          parentEl = "div[class='modal-body']";
        }

        ngModel.$render = function() {
          $(element).val(ngModel.$viewValue);

          // Basic initialization
          $(element).daterangepicker({
            autoUpdateInput: false,
            parentEl: parentEl,
            singleDatePicker: true,
            applyClass: 'bg-slate-600',
            cancelClass: 'btn-default',
            timePicker: time,
            autoApply: false,
            showDropdowns: true,
            drops: scope.isDropUp ? 'up' : 'down',
            locale: {
              format: format,
              separator: ' ~ ',
              customRangeLabel: 'Custom',
              // 별도로 지정하지 않으면 현재 적용중인 로케일에 맞춰서 동작함 2017-03-23 MatthewKim
              // monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
              // daysOfWeek: ["일","월","화","수","목","금","토"]
            }
          }, function(selected_date) {
            $(element).val(selected_date.format(format));
          });

          instance = element.data('daterangepicker');
        };

        /* 스코프가 제거될 때 daterangepicker 함께 제거 */
        scope.$on('$destroy', () => {
          if (instance) {
            instance.remove();
          }
        });
      }
    };
  })

  /**
   * formValidation 추가
   * 2016-10-14 남형진
   */
  .directive('formValidation', function() {
    return {
      restrict: 'A',
      scope: {
        validTab: '=',
        reCheckClass: '=',
        validNoticeTab: '=?', // 2017-05-23 ally 벨리데이션 통과못한 항목이 있는 탭들 담는 변수
        errorClass: '@?' // ally 추가 (에러용 class가 있으면 디폴트(라벨붙여주는것) 말고 해당 class를 적용시켜준다)
      },
      link: function($scope, element, attr) {

        // 2019-01-28 Chris Custom Validation Rules
        // 10원 단위로 입력
        $.validator.addMethod('priceUnitsTen', function (value) {
          value = String(value).replace(/,/g, '');

          return !(value % 10);
        }, '10원 단위로 입력해 주세요');

        $.validator.addMethod('priceNoRequired', function (value) {
          value = String(value).replace(/,/g, '') * 1; // 문자열 -> 숫자

          return !value || value < 9999999999; // 시중가, 원가, 공급가는 비필수이므로 빈 값일 때도 true
        }, '최대 9999999999까지 입력 가능 합니다.');

        $.validator.addMethod('pattern', function(value, element, param) {
          if (this.optional(element)) {
            return true;
          }
          const pattern = new RegExp(param);

          return pattern.test(value);
        }, '형식이 올바르지 않습니다.');

        //errorMessage 재정의
        // 2018-08-22 chris timeout 추가


        setTimeout(function () {
          $(element).validate({
            // focusCleanup: true,          // 포커스 옵션 제거
            ignore: 'input[type=hidden], .select2-search__field', // ignore hidden fields0000
            errorClass: $scope.errorClass ? $scope.errorClass : 'validation-error-label',
            successClass: 'validation-valid-label',
            highlight: function (element, errorClass) {
              $(element).removeClass(errorClass);
            },
            unhighlight: function (element, errorClass) {
              $(element).removeClass(errorClass);
            },

            // Different components require proper error label placement
            errorPlacement: function (error, element) {
              if ($scope.errorClass) {
                $(element).addClass($scope.errorClass);
              } else {
                // Styled checkboxes, radios, bootstrap switch
                if (element.parents('div').hasClass('checker') || element.parents('div').hasClass('choice') || element.parent().hasClass('bootstrap-switch-container')) {
                  if (element.parents('label').hasClass('checkbox-inline') || element.parents('label').hasClass('radio-inline')) {
                    error.appendTo(element.parent().parent().parent().parent());
                  } else {
                    error.appendTo(element.parent().parent().parent().parent().parent());
                  }
                }

                // Unstyled checkboxes, radios
                else if (element.parents('div').hasClass('checkbox') || element.parents('div').hasClass('radio')) {
                  error.appendTo(element.parent().parent().parent());
                }

                // Input with icons and Select2
                else if (element.parents('div').hasClass('has-feedback') || element.hasClass('select2-hidden-accessible')) {
                  error.appendTo(element.parent());
                }

                // Inline checkboxes, radios
                else if (element.parents('label').hasClass('checkbox-inline') || element.parents('label').hasClass('radio-inline')) {
                  error.appendTo(element.parent().parent());
                }

                // Input group, styled file input
                else if (element.parent().hasClass('uploader') || element.parents().hasClass('input-group')) {
                  error.appendTo(element.parent().parent());
                } else {
                  error.insertAfter(element);
                }
              }

            },
            validClass: 'validation-valid-label',
            // success: function(label) {
            //   label.addClass("validation-valid-label").text("Success.")
            // },
            rules: {
              password: {
                minlength: 8
              },
              repeat_password: {
                equalTo: '#password'
              },
              email: {
                email: true
              },
              repeat_email: {
                equalTo: '#email'
              },
              minimum_characters: {
                minlength: 10
              },
              maximum_characters: {
                maxlength: 10
              },
              minimum_number: {
                min: 10
              },
              maximum_number: {
                max: 10
              },
              number_range: {
                range: [10, 20]
              },
              url: {
                url: true
              },
              date: {
                date: true
              },
              date_iso: {
                dateISO: true
              },
              numbers: {
                number: true
              },
              digits: {
                digits: true
              },
              creditcard: {
                creditcard: true
              },
              basic_checkbox: {
                minlength: 2
              },
              styled_checkbox: {
                minlength: 2
              },
              switchery_group: {
                minlength: 2
              },
              switch_group: {
                minlength: 2
              }
            },
            messages: {
              custom: {
                4: 'This is a custom error message'
              },
              agree: 'Please accept our policy'
            }
          });
        }, 500);

        $(element).submit(function () {
          if ($scope.validTab != null || $scope.validTab != undefined) {
            let flag = 0;
            let tabPanes = $('.tab-pane');

            if ($scope.validNoticeTab) {
              $scope.validNoticeTab = []; // submit할때마다 초기화
            }

            if (attr.id) {
              tabPanes = $(`.${attr.id}.tab-pane`);
            }

            tabPanes.each(function(tabIndex) {
              $(this).find('.validation-error-label').each(function() {
                if ($(this).css('display') != 'none') {
                  if (flag === 0) {
                    $scope.validTab = tabIndex; // 에러가 있는 제일 앞의 탭 설정
                    flag++;
                  }
                  if ($scope.validNoticeTab) {
                    $scope.validNoticeTab.push(tabIndex); // 에러를 가지고있는 탭들 저장
                  }
                }
              });
            });
            if (!$scope.$$phase && !$scope.$root.$$phase) {
              $scope.$apply();
            }
          }
        });

        // 2016-11-30 Ally 추가 (특정 className 만 다시 벨리데이션 체크)
        if ($scope.reCheckClass) {
          $scope.reCheckClass.valid = function (className) {
            setTimeout(function () {
              $(`.${className}`, element).valid();
            }, 0);
          };
        }

      }
    };
  })

  /**
   * Switchery
   * 2016-10-17 남형진
   */
  .directive('uiSwitch', ['$window', '$timeout', '$log', '$parse', '$compile', function($window, $timeout, $parse) {

    /**
     * Initializes the HTML element as a Switchery switch.
     *
     * $timeout is in place as a workaround to work within angular-ui tabs.
     *
     * @param scope
     * @param elem
     * @param attrs
     * @param ngModel
     */
    function linkSwitchery(scope, elem, attrs, ngModel) {
      if (!ngModel) {
        return false;
      }
      let options = {};

      try {
        options = $parse(attrs.uiSwitch)(scope);
      } catch (e) {
        options = {};
      }

      let switcher;

      attrs.$observe('disabled', function(value) {
        if (!switcher) {
          return;
        }

        if (value) {
          switcher.disable();
        } else {
          switcher.enable();
        }
      });

      // Watch changes
      scope.$watchCollection(function () {
        return ngModel.$modelValue;
      }, function() {
        initializeSwitch();
      });

      function initializeSwitch() {
        $timeout(function() {
          // Remove any old switcher
          if (switcher) {
            angular.element(switcher.switcher).remove();
          }
          // (re)create switcher to reflect latest state of the checkbox element
          switcher = new $window.Switchery(elem[0], options);
          const element = switcher.element;

          element.checked = scope.initValue;
          if (attrs.disabled) {
            switcher.disable();
          }

          switcher.setPosition(false);
          element.addEventListener('change', function() {
            scope.$apply(function() {
              ngModel.$setViewValue(element.checked);
            });
          });
          scope.$watchCollection('initValue', function() {
            switcher.setPosition(false);
          });

        }, 0);
      }
      initializeSwitch();

      const el = elem[0];

      el.removeAttribute('ui-switch');

    }

    return {
      require: 'ngModel',
      restrict: 'AE',
      scope: {
        initValue: '=ngModel'
      },
      link: linkSwitchery
    };
  }])

  /**
   * Switchery lite 버전
   */
  .directive('paUiSwitch', function ($timeout) {
    return {
      require: 'ngModel',
      restrict: 'E',
      scope: {
        initValue: '=ngModel',
        fn: '&',
        disabled: '=',
      },
      template: '<div class="switchery-xs"><span ng-style="{\'pointer-events\' : disabled ? \'none\' : \'auto\'}" class="switchery switchery-default" ng-class="use_yn ? disabled ? \'switchery-use-disable\' : \'switchery-use\' : \'switchery-none\'" ng-click="use_yn = !use_yn; click();"><small class="small"></small></span></div>',
      link: (scope, elem, attrs, ngModel) => {
        if (!ngModel) {
          return false;
        }

        // 부모모델과 바인딩
        ngModel.$render = function () {
          if (ngModel.$viewValue || ngModel.$viewValue === false) {
            scope.use_yn = ngModel.$viewValue;
          }
        };

        // 클릭이벤트
        scope.click = () => {
          $timeout(async function() {
            // parent ngModel 변경
            scope.$apply(function() {
              ngModel.$setViewValue(scope.use_yn);
            });
            // 받아온 함수 실행
            const re = await scope.fn();
            // 취소나 오류 등으로 false 리턴시 기존값으로 재변경
            if (re === false) {
              scope.use_yn = !scope.use_yn;
              scope.$apply(function() {
                ngModel.$setViewValue(scope.use_yn);
              });
            }
          }, 0);
        };

      }
    };
  })

  /**
   * inputsTag 추가
   * 2016-10-17 김민영
   */
  .directive('tagsInput', function ($timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: { maxTags: '@', ngModel: '=', fn: '=', removeDisabled: '@', ngFocus: '&', isobject: '<' },
      link: function (scope, element, attrs, ngModel) {
        const $element = $(element);

        function init() {
          $element.val(ngModel.$viewValue);
          const option = {
            maxTags: scope.maxTags,
            allowDuplicates: attrs.duplicates == 'allow',
            trimValue: true,
          };

          // 컨펌키 변경
          if (attrs.confirmKeys) {
            option.confirmKeys = attrs.confirmKeys.split(',').map(o => Number(o));
          }

          // 구분자 변경
          if (attrs.delimiter) {
            option.delimiter = attrs.delimiter;
          }

          // 저장하는 값이 객체일 경우
          if (scope.isobject) {
            option.itemValue = 'id';
            option.itemText = 'text';
          }

          $element.tagsinput(option);

          // itemAddedOnInit가 트리거되지 않아서 여기에 정의
          $timeout(() => {
            // ng-model에 array로 값 넣어줌
            if (attrs.valType === 'array' && ngModel.$viewValue) {
              ngModel.$setViewValue($element.tagsinput('items'));
            }
          });

          let regexTest = '';

          // 유효문자 검사 추가
          if (attrs.regex) {
            regexTest = new RegExp(attrs.regex, 'i');
          }

          // 2018-06-21 Daniel 최소글자수 추가
          let minChars = isNaN(parseInt(attrs.minChars));

          minChars = minChars ? false : parseInt(attrs.minChars);

          const removeDisabled = scope.removeDisabled;

          const bti = $element.parent().find('.bootstrap-tagsinput');
          const el = bti.find('input');

          // 추천옵션만 사용 가능한 옵션에 readonly 속성 추가
          if (attrs.tagsReadonly && attrs.tagsReadonly?.toUpperCase() === 'TRUE') {
            el.attr('readonly', attrs.tagsReadonly);
          }

          if (attrs.select) { bti.css({ height: 100, overflowY: 'scroll' }); }
          if (attrs.hidden) { bti.hide(); }
          if (attrs.select || attrs.hidden) { el.hide(); }
          if (attrs.rcmoptinput) {
            $(`#${attrs.rcmoptinput} > .bootstrap-tagsinput input[type=text]`).on('keydown keyup keypress', function(e) {
              e.preventDefault();
              $(this).val('');

              return false;
            });
            $(`#${attrs.rcmoptinput} > .bootstrap-tagsinput`).attr('style', 'width: calc(100% - 45px); !important;');
          }
          if (scope.removeDisabled === 'true') {
            $(bti.prevObject[0]).find('div > span > span').remove();
          }

          // 2018-06-19 Daniel 값 변경시 모델에 채워넣음
          $element.on({
            beforeItemAdd: function (event) {
              if (minChars && event.item.length < minChars || removeDisabled) {
                event.cancel = true;

                return;
              }

              // 유효문자 검사
              if (regexTest && !event.item.match(regexTest)) {
                event.cancel = true;

                return;
              }
              if (bti.css('display') == 'none') { bti.show(); }

              return event;
            },
            itemAdded: function () {
              if (!scope.isobject) {
                ngModel.$setViewValue(attrs.valType === 'array' ? $element.tagsinput('items') : $element.val());
              } else {
                ngModel.$setViewValue($element.tagsinput('items').map(tag => tag.text).join());
              }
            },
            itemRemoved: function () {
              if (!scope.isobject) {
                ngModel.$setViewValue(attrs.valType === 'array' ? $element.tagsinput('items') : $element.val());
              } else {
                ngModel.$setViewValue($element.tagsinput('items').map(tag => tag.text).join());
              }
              if (attrs.hidden && !ngModel.$viewValue.length && bti.css('display') !== 'none') { bti.hide(); }
            }
          });

          $element.parent().find('.bootstrap-tagsinput input[type=text]').on('focus', ($event) => {
            scope.ngFocus($event);
          });

          const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|[\u25aa-\u25ab]|\|[\u25fb-\u25fe]|\ud83c\udc04|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u219A-\u21D1]|[\u21D5-\u21ff])/g;

          $element.parent().find('.bootstrap-tagsinput input[type=text]').on('input', function () {
            const $input = $(this);
            const value = $input.val();
            const cleanedValue = value.replace(emojiRegex, '');

            // notEmoji directive가 있는 tag-inputs에서만 이모지 제거
            if (cleanedValue !== value && (attrs.notEmoji || attrs.notEmoji === '')) {
              $input.val(cleanedValue);
            }
          });

          /**
           * 2018-03-28 roy 추가
           * allowDuplicates 태그 허용 후 태그 아이템 제거시 태그 모두 지워지고, 벨류는 남아있는 부트스트랩 자체 이슈 존재
           * allowDuplicates 태그 허용할 경우 제거 대상 태그 하나만 제거하여 태그 등록함
           */

          if (attrs.duplicates == 'allow' && !scope.isobject) {
            $element.on('beforeItemRemove', function (event) {
              const tags = $element.val().split(',');
              for (let i = 0; i < tags.length; i++) {
                if (tags[i] == event.item) {
                  tags.splice(i, 1);
                  break;
                }
              }
              $element.tagsinput('removeAll');
              $element.tagsinput('add', tags.toString());
              event.cancel = true;

            });
          }
        }

        setTimeout(function () {
          // 초기화
          init();
          ngModel.$render = function () {
            $element.tagsinput('destroy');
            init();
          };
        }, 0);
      }
    };
  })

  /**
   * multiSelect 추가
   * 2016-10-24 남형진
   */
  .directive('multiSelect', function (gettextCatalog) {
    return {
      restrict: 'E',
      require: 'ngModel',
      scope: { items: '=', ngModel: '=' },
      link: function(scope, element, attrs, ngModel) {

        ngModel.$render = function() {

          const checked_value = ngModel.$modelValue == false ? [] : ngModel.$modelValue;
          // checked_value = ['날짜', '주소']
          const items = scope.items;
          // items = ['날짜', '주소', '우편번호', '휴대폰번호'] or
          // items = [{label:'날짜', value:'date'}, {label:'주소', value:'address'}]

          const options = [];

          if (_.isArray(items)) {
            items.forEach(function(v) {
              let _label = '';
              let _value = '';
              let _selected = false;

              if (_.isObject(v)) {
                _label = v.label;
                _value = v.value;
              } else {
                _label = v;
                _value = v;
              }

              if (_.indexOf(checked_value, _value) > -1) {
                _selected = true;
              }
              options.push({ label: _label, value: _value, selected: _selected });
            });
          }

          let orderCount = 0;
          let placeholder = gettextCatalog.getString('전체검색');
          let max_list_option = '3';

          if (attrs.placeholder) {
            placeholder = attrs.placeholder;
          }
          if (attrs.maxListOption) {
            max_list_option = attrs.maxListOption;
          }

          // Initialize
          $(element).multiselect({
            includeSelectAllOption: false, //17-03-08 ally 전체 선택 추가
            buttonText: function(options) {
              if (options.length == 0) {
                return placeholder;
              } else if (options.length > max_list_option) {
                return `${options.length} selected`;
              } else {
                const selected = [];

                options.each(function() {
                  selected.push([$(this).attr('label'), $(this).data('order')]);
                });

                selected.sort(function(a, b) {
                  return a[1] - b[1];
                });

                let text = '';

                for (let i = 0; i < selected.length; i++) {
                  text += `${selected[i][0]}, `;
                }

                return text.substr(0, text.length - 2);
              }
            },

            onChange: function(option, checked) {
              if (checked) {
                orderCount++;
                $(option).data('order', orderCount);
                if (_.indexOf(checked_value, $(option).val()) < 0) {
                  checked_value.push($(option).val());
                }
              } else {
                $(option).data('order', '');
                if (_.indexOf(checked_value, $(option).val()) > -1) {
                  _.pull(checked_value, $(option).val());
                }
              }
              scope.$apply(function() {
                ngModel.$setViewValue(checked_value);
              });
            },

            //17-03-08 ally 전체 선택 추가
            onSelectAll: function() {
              $.uniform.update();
              scope.$apply(function() {
                ngModel.$setViewValue('all');
              });
            }

          });

          // Option 추가
          $(element).multiselect('dataprovider', options);

          // Related plugins
          // ------------------------------
          // Styled checkboxes and radios
          $('.multiselect-container input').uniform({ radioClass: 'choice' });

        };

      }
    };
  })

  /**
   * 직접입력+다중선택+검색 가능한 select2 모듈
   */
  .directive('multiSelectCombo', function ($timeout) {
    return {
      restrict: 'A',
      require: ['ngModel', 'multiSelectCombo'],
      scope: { ngModel: '=', selectOptions: '=', selectDisabled: '=', selectReadonly: '=', enableDirectOpt: '=' },
      controller: function ($scope) {
        this.defaultOptions = {};
        this.setDefaultOptions = function (options) {
          this.defaultOptions = options;
        };

        this.initSelect2 = function ($element, options) {
          this.destroySelect2($element);
          $element.select2(Object.assign({}, this.defaultOptions, options));

          if (!$element.data('select2').$container.find('.select2-selection__choice').length) {
            $element.data('select2').$container.find('.select2-search--inline').css('width', '100%');
          }

          $element.on('change', () => {
            if ($scope.ngModelCtrl) {
              $scope.$applyAsync(() => {
                $scope.ngModelCtrl.$setViewValue($element.val());
              });
            }
          });
        };

        this.destroySelect2 = function ($element) {
          if ($element.data('select2')) {
            $element.select2('destroy');
          }
        };
      },
      link: function (scope, element, attrs, ctrls) {
        const ngModelCtrl = ctrls[0];
        const directiveCtrl = ctrls[1];
        const $element = $(element);
        const options = Object.assign({
          width: '100%',
          placeholder: attrs.placeholder || '',
          theme: 'classic',
          containerCssClass: 'check',
          allowClear: false,
          tags: !scope.enableDirectOpt,
          minimumResultsForSearch: Infinity,
          closeOnSelect: false,
          multiple: true,
          createTag: function (params) {
            const value = params.term?.trim();
            let isNewTag = true;
            if (value === '') {
              return null;
            }

            $element.find('option').each(function () {
              if ($(this).val().trim().toLowerCase() === value.toLowerCase()) {
                isNewTag = false;

                return false;
              }
            });

            return isNewTag ? { id: value, text: value, newTag: true, } : null;
          },
          ajax: {
            transport: _.debounce(function (params, success) {
              const search = params.data.term?.trim().toLowerCase();
              const $options = $element.find('option');
              const filteredResults = [];

              // 문자열 입력시에만 자동 검색
              if (params.data._type === 'query:append') {
                return;
              }

              if (!search) {
                $options.each(function () {
                  filteredResults.push({ id: $(this).val(), text: $(this).text() });
                });
              } else {
                $options.each(function () {
                  const opt = $(this).val().toLowerCase();
                  if (opt === search) {
                    filteredResults.unshift({ id: $(this).val(), text: $(this).text() });
                  } else if (opt.includes(search)) {
                    filteredResults.push({ id: $(this).val(), text: $(this).text() });
                  }
                });

                if (scope.enableDirectOpt && !filteredResults.length) {
                  filteredResults.unshift({ id: search, text: search, newTag: true });
                }
              }
              success({ results: filteredResults });
            }, 0)
          }
        }, (scope.selectOptions || {}));

        if (!directiveCtrl) {
          return;
        }

        $timeout(() => {
          directiveCtrl.setDefaultOptions(options);
          directiveCtrl.initSelect2($element, options);

          scope.$watch('selectDisabled', (flag) => {
            if ($element.data('select2')) {
              const $input = $element.data('select2').$container.find('.select2-search__field');
              $input.prop('disabled', flag);
            }
          });

          scope.$watch('selectReadonly', (flag) => {
            if ($element.data('select2')) {
              const $input = $element.data('select2').$container.find('.select2-search__field');
              $input.prop('readonly', flag);
            }
          });
          scope.$watchCollection('ngModel', function (newValue) {
            if (!newValue?.length) {
              $timeout(() => {
                $element.val([]).trigger('change');

                if ($element.data('select2')) {
                  const $input = $element.data('select2').$container.find('.select2-search__field');
                  $input.css('width', '100%');
                }
              });
            }
          });
        });

        $element.on('select2:select select2:unselect', function () {
          $timeout(() => {
            const $input = $element.data('select2').$container.find('.select2-search__field');

            $input.off('keydown').on('keydown', function (e) {
              // 한글 입력 후 방향키나 엔터 입력 시 AJAX 호출을 막기 위해 이벤트 차단
              const isKoreanInput = /ArrowUp|ArrowDown|ArrowLeft|ArrowRight|Enter/.test(e.key);

              if (isKoreanInput) {
                e.preventDefault();
              }
            });

            if ($input && $input.val()) {
              $input.val('');
              $input.trigger('input');
            }

            const $selectTag = $element.data('select2').$container.find('.select2-selection__choice');

            $element.data('select2').$container.find('.select2-search--inline').css('width', $selectTag.length ? '' : '100%');
          });
        });

        scope.$on('$destroy', () => {
          directiveCtrl.destroySelect2($element);
        });

        $element.on('change', function () {
          if (ngModelCtrl) {
            ngModelCtrl.$setViewValue($(this).val());
          }
        });

        $element.on('select2:open', function () {
          if ($element.data('select2')) {
            $element.data('select2').results.clear();
          }

          const $input = $element.data('select2').$container.find('.select2-search__field');

          $input.off('keydown').on('keydown', function (e) {
            // 한글 입력 후 방향키나 엔터 입력 시 AJAX 호출을 막기 위해 이벤트 차단
            const isKoreanInput = /ArrowUp|ArrowDown|ArrowLeft|ArrowRight|Enter/.test(e.key);

            if (isKoreanInput) {
              e.preventDefault();
            }
          });
        });
      },
    };
  })

  /**
   * url입력시 http:// 판별 및 추가
   * 2016-11-21 김승호
   */
  .directive('httpPrefix', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, controller) {
        function ensureHttpPrefix(value) {
          // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
          if (value && !/^(https?):\/\//i.test(value)
            && 'http://'.indexOf(value) === -1) {
            controller.$setViewValue(`http://${value}`);
            controller.$render();

            return `http://${value}`;
          } else {
            return value;
          }
        }
        controller.$formatters.push(ensureHttpPrefix);
        controller.$parsers.splice(0, 0, ensureHttpPrefix);
      }
    };
  })

  /**
   * 공용버튼 아이콘 처리
   * 2016-12-06 남형진
   */
  .directive('button', function() {
    return {
      restrict: 'E',
      link: function(scope, element, attrs) {
        if (attrs.class == 'btn btn-save') {
          for (let i = 0; i < element.length; i++) {
            element[i].innerHTML = `<i class="icon-file-check2 position-left"></i>${element[i].innerHTML}`;
          }
        }
      }
    };
  })

  /**
   * input 금액 입력 천단위 comma 추가
   * 2016-12-15 김승호
   */
  .directive('comma', function ($filter) {

    return {
      scope: {
        focusOutEvt: '=', // 2018-02-08 ally 실시간이아닌 포커스 아웃됐을때 변경
        allowedEmpty: '=' // 초기값 빈값으로 지정시 사용
      },
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        if (!scope.focusOutEvt) {
          ctrl.$formatters.unshift(function () {
            if (scope.allowedEmpty && !ctrl.$modelValue && ctrl.$modelValue !== 0) {
              return '';
            } else if ($filter('number')(ctrl.$modelValue) == 0) {
              // 값이 0 인경우 빈값으로 리턴되고있어 0으로 리턴되도록 수정 2018-02-26 rony
              // return '';
              return '0';
            } else {
              return $filter('number')(ctrl.$modelValue);
            }
          });

          ctrl.$parsers.unshift(function (viewValue) {
            let plainNumber = viewValue.replace(/[,.]/g, ''),
                modelValue = plainNumber;

            // 공백이나 -입력시 숫자포맷 변경하면 0으로 바뀌어서 처리함
            if (plainNumber && plainNumber !== '-') {
              modelValue = $filter('number')(plainNumber);
            }

            elem.val(modelValue);

            return plainNumber;
          });
        } else {
          elem.on('focusout', function () {
            const num = elem.val().replace(/[,]/g, '');

            elem.val($filter('number')(num));
            ctrl.$setViewValue(num);
          });
        }
      }
    };
  })

  /**
   * input 금액 입력 천단위 comma 추가 (소숫점 2자리까지 입력)
   * 2018-07-17 rony
   */
  .directive('commaPoint', function ($filter) {

    return {
      scope: {
        focusOutEvt: '=',
        decimalLength: '='
      },
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }

        if (!scope.focusOutEvt) {
          ctrl.$formatters.unshift(function () {
            if ($filter('number')(ctrl.$modelValue) == 0) {
              return '0';
            } else {
              return $filter('number')(ctrl.$modelValue);
            }
          });

          ctrl.$parsers.unshift(function (viewValue) {
            const plainNumber = viewValue.replace(/[,]/g, '');
            const splitNum = plainNumber.split('.');
            let b = plainNumber;
            const decimalLength = scope.decimalLength || 3;

            if (splitNum.length > 1) {
              if (splitNum[1].length > 0) {
                const pointCnt = splitNum[1].length > decimalLength ? decimalLength : splitNum[1].length;

                b = $filter('number')(`${splitNum[0]}.${splitNum[1].substr(0, pointCnt)}`, pointCnt);
              } else {
                b = `${$filter('number')(splitNum[0])}.`;
              }
            } else {
              b = $filter('number')(plainNumber);
            }

            elem.val(b);

            return plainNumber;
          });
        } else {
          elem.on('focusout', function () {
            const num = elem.val().replace(/[,]/g, '');

            elem.val($filter('number')(num));
            ctrl.$setViewValue(num);
          });
        }
      }
    };
  })

  /**
   * html5 form required 메세지 변경
   * 2017-05-10 ally
   */
  .directive('customRequired', ['gettextCatalog', 'gettext', function(gettextCatalog, gettext) {
    return {
      restrict: 'A',
      scope: {
        invalidMessage: '@'
      },
      link: function (scope, elem) {
        const elements = $(elem);

        elements[0].oninvalid = function(e) {
          e.target.setCustomValidity('');
          if (!e.target.validity.valid) {
            const message = gettextCatalog.getString(scope.invalidMessage);

            e.target.setCustomValidity(message);
          }
        };
        elements[0].oninput = function(e) {
          e.target.setCustomValidity('');
        };

        // pot파일에 등록하기위해 invalidMessage에 쓰일 단어들을 미리 등록해준다.
        gettext('이메일을 입력해 주세요.');
        gettext('비밀번호를 입력해 주세요.');
      }
    };
  }])

  /**
   * UTF-16 이모지 입력 불가 처리
   */
  .directive('notUtf16Emoji', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$parsers.push(function(value) {
          if (value) {
            // 이모지를 제외한 문자만 추출
            const newValue = value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '');
            if (newValue !== value) {
              ngModelCtrl.$setViewValue(newValue);
              ngModelCtrl.$render();
            }

            return newValue;
          }

          return value;
        });
      }
    };
  })

  /**
   * 이모지 입력 불가 처리
   */
  .directive('notEmoji', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$parsers.push(function(value) {
          if (value) {
            const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|[\u25aa-\u25ab]|\|[\u25fb-\u25fe]|\ud83c\udc04|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u219A-\u21D1]|[\u21D5-\u21ff])/g;
            // 이모지를 제외한 문자만 추출
            const newValue = value.replace(emojiRegex, '');
            if (newValue !== value) {
              ngModelCtrl.$setViewValue(newValue);
              ngModelCtrl.$render();
            }

            return newValue;
          }

          return value;
        });
      }
    };
  });

// /**
//  * 입력받은 문자열에서 시작 위치부터 종료위치까지 마스킹 처리
//  * 일단 라벨등... $().text() 로 진행되는 경우 처리.
//  * 2017-11-28 Rony*
//  */
// .directive('maskingByPosition', function() {
//   return {
//     restrict: 'A',
//     link: function(scope, element, attrs) {
//       var str = $.trim($(element).text());      // 대상문자열
//       var start = scope.startMaskingPosition;   // 시작점.
//       var end = str.length - 1;                 // 종료지점
//        var temp = "";
//        temp = str.substring(0, start);
//        for ( var i = start; i < end; i++ ) {
//         temp = temp + "*";
//        }
//        $(element).text(temp);
//     }
//   };
// })
