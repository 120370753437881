'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentOrderUnstoringCtrl', function ($rootScope, $scope, $uibModalInstance, data, shipmentModel, commonSVC, gettextCatalog) {
    $scope.selectCount = data.count;
    $scope.selectedShopType = data.shopType;
    $scope.isAll = data.all;
    $scope.setInfo = {
      auto_bundle: false
    };
    // 출고지시 옵션 마지막 선택값 가져오기
    $scope.saveUnstoring = JSON.parse(localStorage.getItem('orderUnstoreSettings'));

    // 저장된 값 없으면 모두 기본값 선택 처리
    if (!$scope.saveUnstoring || !$scope.saveUnstoring.use_yn) {
      $scope.saveUnstoring = {
        auto_bundle: false,
        use_yn: false
      };
    } else {
      $scope.setInfo = {
        auto_bundle: JSON.parse($scope.saveUnstoring.auto_bundle)
      };
    }
    $scope.ok = _.throttle(async () => {

      data.auto_bundle = $scope.setInfo.auto_bundle;
      const param = data;

      // 출고지시 클릭시 로딩 추가.
      $('#spinner').removeClass('hidden');

      $scope.saveUnstoring.auto_bundle = $scope.setInfo.auto_bundle;
      localStorage.setItem('orderUnstoreSettings', JSON.stringify($scope.saveUnstoring));

      shipmentModel.deliveryInstruction(param, function (state, data) {
        if (state === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문이 출고대기로 변경됩니다. <br>출고관리 메뉴에서 출고처리하시기 바랍니다.'));
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString(`${data.data.messages || '출고 지시에 실패하였습니다.'}`));
        }
        $('#spinner').addClass('hidden');
      });
    }, 1500, { trailing: false });

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
