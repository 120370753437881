'use strict';

angular.module('gmpApp')
  .controller('SuspiciousOrderCtrl', function($scope, commonSVC, settings, $compile, $rootScope, $filter, $uibModalInstance, data, $timeout, shipmentModel) {
    $scope.suspiciousOrder = data.suspiciousOrder; // 중복의심 주문
    const channelList = angular.copy($rootScope.use_channel_list);
    const useSystemList = data.useSystemList;
    const warehouseList = data.warehouseList;
    let suspiciousOrderList = {};
    let existingOrderList = {};
    // 별칭정보 담기
    const seller_nick_info = [];

    $scope.selectedDatas = {
      suspicious: [],
      existing: []
    };

    const searchTemplate = {
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectType: true,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
        selectCount: 0,      // 검색전체 건수
        selectBundleCnt: 0,  // 선택 묶음건수
        totalCount: 0,       // 검색전체 건수
      }
    };

    _.forEach(channelList, function(chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });
    $scope.isWork = data.isWork;  // 작업 진행 중 모달 열린건지 여부
    $scope.suspicious = {};
    $scope.suspicious.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true, false, 'suspicious');
      },
    };
    $scope.suspicious.searchData = angular.copy(searchTemplate.searchData);
    $scope.suspicious.methods = {};
    $scope.suspicious.options = {
      pinningColumns: ['widget'],
      defaultSortingColumns: [],
      notMovingColumns: ['shop_name', 'shop_ord_no', 'ord_status', 'shop_sale_name', 'shop_opt_name', 'sale_cnt', 'sales', 'order_name', 'to_name', 'to_htel', 'to_addr1', 'ord_time', 'wdate', 'bundle_no'],
      notSortingColumns: ['shop_name', 'shop_ord_no', 'ord_status', 'shop_sale_name', 'shop_opt_name', 'sale_cnt', 'sales', 'order_name', 'to_name', 'to_htel', 'to_addr1', 'ord_time', 'wdate', 'bundle_no'],
      notVisibleColumns: [],
      modal: true,
      gridHeight: 300,
      enablePaginationControls: false,
      bundleOptions: {
        bundleCountKey: 'selectBundleCnt',
        bundleDataKey: 'shop_ord_no',
        bundleUniqKey: 'uniq'
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/suspicious`,
        requestWillAction: function () {
          return { uniqs: $scope.suspiciousOrder };
        },
        requestDidAction: function (result) {
          result.results = result.suspiciousOrders;

          suspiciousOrderList = result.results;
          $scope.suspiciousOrderCnt = suspiciousOrderList.length;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="$event.preventDefault(); grid.appScope.showDetail('${row.uniq}',false, '${row.bundle_no}', 'suspicious')" >상세</button>`;
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '직접입력';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(useSystemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : (row.pa_shop_cd || row.shop_cd)}.png" style="width: 50px;">` }
                ${shop_info[1]}
              </span>`;
            }

            return img;
          }
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'ord_status',
          title: '주문상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            return $filter('statusColor')(row.ord_status);
          }
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 300,
          template: function(row) { //이미지+상품명
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);
            const suspiciousOrderSpan = !$rootScope.osse_sol && row.dupl_doubt_yn ? '<span class="label label-default bg-red">중복의심</span> ' : '';
            if (row.sale_img) {
              const img = row.sale_img || '/assets/images/upload.png';

              return `<span><img src='${img}' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${suspiciousOrderSpan}${filteredShopSaleName}</span>`;
            } else {
              return `<span>${suspiciousOrderSpan}${filteredShopSaleName}</span>`;
            }
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60,
          template: function(row) {
            const value = !row.sale_cnt || ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.sale_cnt;

            return `${value}`;
          }
        },
        {
          key: 'sales',
          title: '금액',
          width: 100,
          template: function(row) {
            const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(row.sales, '', 0);

            return value;
          }
        },
        {
          key: 'order_name',
          title: '주문자(ID)',
          width: 150,
          notCompile: true,
          template: function(row) { //속성
            let return_value = row.order_name;

            if (row.order_id) {
              return_value += `(${row.order_id})`;
            }

            return return_value;
          }
        },
        {
          key: 'to_name',
          title: '수령자명',
          width: 100
        },
        {
          key: 'to_htel',
          title: '수령자 휴대폰번호',
          width: 150
        },
        {
          key: 'to_addr1',
          title: '주소',
          width: 300,
          minWidth: 180,
          template: function(row) {
            let return_value = row.to_addr1 || '';

            if (return_value.charAt(0) != '[' && row.to_zipcd && return_value) {
              return_value = `[${row.to_zipcd}]${return_value}`;
            }
            if (row.to_addr2) {
              return_value = `${return_value} ${row.to_addr2}`;
            }

            return return_value;
          }
        },
        {
          key: 'ord_time',
          title: '주문일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          width: 170
        },
      ]
    };

    $scope.existing = {};
    $scope.existing.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true, false, 'existing');
      },
    };
    $scope.existing.searchData = angular.copy(searchTemplate.searchData);
    $scope.existing.methods = {};
    $scope.existing.options = {
      pinningColumns: ['widget'],
      defaultSortingColumns: [],
      notMovingColumns: ['shop_name', 'shop_ord_no', 'ord_status', 'shop_sale_name', 'shop_opt_name', 'sale_cnt', 'sales', 'order_name', 'to_name', 'to_htel', 'to_addr1', 'ord_time', 'wdate', 'bundle_no'],
      notSortingColumns: ['shop_name', 'shop_ord_no', 'ord_status', 'shop_sale_name', 'shop_opt_name', 'sale_cnt', 'sales', 'order_name', 'to_name', 'to_htel', 'to_addr1', 'ord_time', 'wdate', 'bundle_no'],
      notVisibleColumns: [],
      modal: true,
      gridHeight: 300,
      enablePaginationControls: false,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/suspicious`,
        requestWillAction: function () {
          return { uniqs: $scope.suspiciousOrder };
        },
        requestDidAction: function (result) {
          result.results = result.existingOrders;

          existingOrderList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="$event.preventDefault(); grid.appScope.showDetail('${row.uniq}',false, '${row.bundle_no}', 'existing')" >상세</button>`;
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '직접입력';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(useSystemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : (row.pa_shop_cd || row.shop_cd)}.png" style="width: 50px;">` }
                ${shop_info[1]}
              </span>`;
            }

            return img;
          }
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'ord_status',
          title: '주문상태',
          width: 100,
          notCompile: true,
          template: function(row) {

            return $filter('statusColor')(row.ord_status);
          }
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 300,
          template: function(row) { //이미지+상품명
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            if (row.sale_img) {
              const img = row.sale_img || '/assets/images/upload.png';

              return `<span><img src='${img}' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${filteredShopSaleName}</span>`;
            } else {
              return `<span>${filteredShopSaleName}</span>`;
            }
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 60,
          template: function(row) {
            const value = !row.sale_cnt || ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.sale_cnt;

            return `${value}`;
          }
        },
        {
          key: 'sales',
          title: '금액',
          width: 100,
          template: function(row) {
            const value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
              row.ord_status
            ) > -1 && !$rootScope.osse_sol
              ? 0
              : $filter('currency')(row.sales, '', 0);

            return value;
          }
        },
        {
          key: 'order_name',
          title: '주문자(ID)',
          width: 150,
          notCompile: true,
          template: function(row) { //속성
            let return_value = row.order_name;

            if (row.order_id) {
              return_value += `(${row.order_id})`;
            }

            return return_value;
          }
        },
        {
          key: 'to_name',
          title: '수령자명',
          width: 100
        },
        {
          key: 'to_htel',
          title: '수령자 휴대폰번호',
          width: 150
        },
        {
          key: 'to_addr1',
          title: '주소',
          width: 300,
          minWidth: 180,
          template: function(row) {
            let return_value = row.to_addr1 || '';

            if (return_value.charAt(0) != '[' && row.to_zipcd && return_value) {
              return_value = `[${row.to_zipcd}]${return_value}`;
            }
            if (row.to_addr2) {
              return_value = `${return_value} ${row.to_addr2}`;
            }

            return return_value;
          }
        },
        {
          key: 'ord_time',
          title: '주문일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          width: 170
        },
      ]
    };

    /**
       * 검색
       */
    $scope.searchDo = function (refresh, noDelay, callback, grid) {

      $scope[grid].methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 상세페이지 보여주기
     */
    $scope.showDetail = function(uniq, isGlobal, bundle, grid) {
      const resolve = {
        data: {
          fromPage: 'suspicious',
          uniq: uniq,
          warehouseList: warehouseList.data.result || [],
          systemList: useSystemList.data || []
        }
      };
      const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false, false, false, grid);
        }
      });
    };

    $scope.$on('OnSelectChangeBefore', function(event, data) {
      if (data.id === 'suspicious_grid') {
        $scope.selectedDatas.suspicious = angular.copy(data.selectedDatas);

        if (data.selectedAll) {
          if (data.selectedDatas.length) {
            $scope.existing.methods.selectAllRows();
          } else {
            $scope.existing.methods.clearSelectedRows();
          }
        }

        if (!data.callStop && !data.selectedAll) {
          associatedRowSelectionFunction($scope.selectedDatas.suspicious);
        }
      }
    });

    // 중복의심 / 기존주문 선택 연동 함수
    const associatedRowSelectionFunction = (rows) => {
      $timeout(() => {
        existingOrderList = existingOrderList.map((ord) => {
          const shouldBeSelected = rows.some(row => row.shop_ord_no === ord.shop_ord_no &&
                                                     row.shop_cd === ord.shop_cd &&
                                                     row.shop_id === ord.shop_id &&
                                                     row.work_no !== ord.work_no);
          $scope.existing.methods.setSelectedRow(ord, shouldBeSelected);

          return { ...ord, isSelected: shouldBeSelected };
        });

        $scope.$emit('OnSelectChangeBefore', {
          id: 'existing_grid',
          selectedDatas: existingOrderList.filter(ord => ord.isSelected),
          callStop: true
        });

      });
    };

    // 중복의심 해제 함수
    $scope.releaseOfDuplicateSuspicion = async () => {
      if ($scope.suspicious.methods.selectedData('all').length) {
        const modalInstance = await commonSVC.showConfirmHtml('', `중복출고 위험이 있으니 주문확인 후 중복의심을 해제해 주세요.<br/>주문<span class="text-danger" style="font-weight: bolder"> ${$scope.suspicious.methods.selectedData('all').length}건</span>의 중복의심을 해제하겠습니까?`);

        if (modalInstance) {
          try {
            const params = {
              uniqs: $scope.selectedDatas.suspicious.map(ord => ord.uniq),
              existingMap: {}
            };

            $scope.selectedDatas.suspicious.forEach(data => {
              params.existingMap[data.uniq] = existingOrderList.find(ord => data.shop_ord_no === ord.shop_ord_no &&
                                                                            data.shop_cd === ord.shop_cd &&
                                                                            data.shop_id === ord.shop_id &&
                                                                            data.work_no !== ord.work_no)?.bundle_no;
            });

            const result = await shipmentModel.releaseOfDuplicateSuspicion(params);

            if (result.data.results === 'success') {
              commonSVC.showToaster('success', '중복의심 해제 성공', `중복의심 해제 ${$scope.suspicious.methods.selectedData('all').length}건 성공`);
            } else {
              throw 'fail';
            }

            $scope.suspiciousOrder = $scope.suspiciousOrder.filter(u => !params.uniqs.includes(u));
            if (!$scope.suspiciousOrder.length) {
              $scope.suspiciousOrder = ['clear'];
            }
            $scope.searchDo(true, false, false, 'suspicious');
            $scope.searchDo(true, false, false, 'existing');

            $scope.selectedDatas.suspicious = $scope.selectedDatas.suspicious.filter(data => !params.uniqs.includes(data.uniq));
            $timeout(() => {});

            return;
          } catch (err) {
            return commonSVC.showToaster('error', '중복의심 해제 실패', '다시 시도해 주세요.');
          }
        }
      } else {
        commonSVC.showMessage('선택한 중복의심 주문이 없습니다.');
      }
    };

    // 중복의심 건 제외 후 작업
    $scope.exceptSuspiciousOrder = () => {
      $uibModalInstance.close({ results: 'success', suspiciousOrder: $scope.suspiciousOrder.filter(ord => ord !== 'clear') });
    };

    $scope.close = () => {
      $uibModalInstance.close();
    };
  });