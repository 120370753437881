'use strict';

angular.module('gmpApp')
  .controller('ProdProductExcelUploadTemplateCtrl', function ($scope, $uibModalInstance, $timeout, gettextCatalog, productSVC, commonSVC, productModel, data, $rootScope) {
    const disableFields = angular.copy(productSVC.excelDisableFields);

    $scope.showType = data.type;
    $scope.selectedAll = false;
    $scope.viewLgCustom = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));
    $scope.viewOsseCustom = $rootScope.osse_sol;

    // 모달 닫기
    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

    $scope.data = {
      template_name: '',
      template_data: [],
    };

    /**
     * 엘지 커스텀 사용자인경우 모델번호 출력.
     */
    init_template_data();

    // 체크박스 클릭
    $scope.checkboxClick = function (type) {
      // 전체 선택/해제
      if (type === 'all') {
        angular.forEach($scope.data.template_data, function (template) {
          if (!template.required) {
            template.use_yn = $scope.selectedAll;
          }
        });
      } else {
        $scope.selectedAll = !_.map($scope.data.template_data, 'use_yn').includes(false);
      }
    };

    /**
     * 엑셀 업로드 양식 저장
     * 2019-06-04 정상협
     */
    $scope.submit = function () {
      if (!$scope.templateForm.$valid) {
        return false;
      }

      // 문자열 escape 처리
      const template_data = $scope.data.template_data.map(function (row) {
        row.custom = row.custom !== '' ? row.custom : row.header;

        return row;
      });

      // 선택한 항목 필터
      const useTemplate = template_data.filter(function (row) {
        return row.use_yn;
      });

      const uniqTemplate = _.uniqBy(useTemplate, 'custom');
      let basicOver = false;

      // 기본양식에서 사용중인 항목명 사용하면 중복 처리
      uniqTemplate.forEach(function (row) {
        if (disableFields.includes(row.custom)) {
          basicOver = true;

          return false;
        }
      });

      // 항목명 중복
      if (useTemplate.length !== uniqTemplate.length) {
        commonSVC.showMessage('양식 등록 실패', '동일한 항목명을 중복으로 설정할 수 없습니다.');

        return false;
      }

      // 기본양식 항목명 중복
      if (basicOver) {
        commonSVC.showMessage('양식 등록 실패', '이미 기본 양식에서 사용되고있는 항목명은 중복으로 사용할 수 없습니다.');

        return false;
      }

      const params = {
        template_name: $scope.data.template_name,
        template_data: JSON.stringify(template_data),
      };

      if (data.type === 'add') {
        $scope.addTemplate(params);
      } else if ($scope.showType === 'edit') {
        params.template_no = data.template_no;

        $scope.updateTemplate(params);
      }
    };

    /**
     * 엑셀 업로드 양식 추가
     * 2019-06-04 정상협
     */
    $scope.addTemplate = function (params) {
      productModel.baseExcelTemplateAdd(params, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('업로드 양식 등록에 성공하였습니다.'));
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('업로드 양식 등록에 실패하였습니다.'));
        }
      });
    };

    /**
     * 엑셀 업로드 양식 수정
     * 2019-06-04 정상협
     */
    $scope.updateTemplate = function (params) {
      productModel.baseExcelTemplateEdit(params, function (state) {
        if (state === 'success') {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('업로드 양식 수정에 성공하였습니다.'));
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('업로드 양식 수정에 실패하였습니다.'));
        }
      });
    };

    /**
     * 템플릿 데이터 초기화
     */
    function init_template_data () {
      $scope.data.template_data = angular.copy(productSVC.excelUploadTemplate);
      $scope.limit = 12;

      if ($scope.viewLgCustom) {

        $scope.data.template_data.splice(5, 0, { header: '모델번호', key: 'model_no', custom: '모델번호', use_yn: false, required: false });
      } else if ($scope.viewOsseCustom) {
        $scope.data.template_data.splice(10, 0, { header: '포장 형태', key: 'pkg_name', custom: '포장 형태', use_yn: false, required: false });
        $scope.data.template_data.splice(11, 0, { header: !$rootScope.osse_sol ? '입고단위' : '낱본 수량', key: 'in_unit_info', custom: !$rootScope.osse_sol ? '입고단위' : '낱본 수량', use_yn: false, required: false });
      }

      const multiMadeinYnIndex = $scope.data.template_data.findIndex(d => d.key === 'multi_madein_yn');
      $scope.data.template_data.splice(multiMadeinYnIndex + 1, 0, { header: '취급주의 여부', key: 'caution_yn', custom: '취급주의 여부', use_yn: false, required: false });
    }

    function init () {
      // 초기 데이터 설정
      init_template_data();

      if ($scope.showType === 'edit') {
        const params = {
          template_no: data.template_no
        };

        productModel.baseExcelTemplateDetail(params, function (state, data) {
          if (state === 'success') {
            const result = data.result[0];
            const template_data = result.template_data ? JSON.parse(result.template_data) : '';

            // 필수 값이나 사용 기본 사용 여부는 항상 바뀔 수 있기 때문에 값만 적용시킴
            _.forEach($scope.data.template_data, function (template) {
              const tmp = _.find(template_data, { key: template.key });

              template.use_yn = tmp && tmp.use_yn ? tmp.use_yn : false;

              if (tmp && tmp.custom !== '' && tmp.use_yn) {
                template.custom = tmp.custom;
              }
            });

            $scope.data.template_name = result.template_name;
          }
        });
      }

      // 체크박스 선택여부 설정
      $timeout(function () {
        $scope.checkboxClick();
      }, 100);
    }

    init();
  });
