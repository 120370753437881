'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentEbaydepotListCtrl',
    function (
      // common
      $state, $rootScope, $scope, $timeout, $filter, gettextCatalog,
      // info
      settings, userInfo,
      // SVC
      workSVC, commonSVC, shipmentSVC, onlineProductSVC,
      // Model
      shopAccountModel, systemModel, shipmentModel, commonModel, giftModel,
      // List
      useWarehouseList, supplierList, categoryList, useSystemList, giftList, ebaydepotAccounts, systemList, memoTemplateList
    ) {
      const BUNDLE_ACTION_NOT_ALLOWED_STATUS = ['발송정보 수정대기', '발송요청 대기', '발송요청중', '발송요청 완료', '발송 완료'];
      $scope.isManageStock = $rootScope.user_profile.sol_stock > 0; // 재고관리여부
      $scope.countList = [];                                        // 우측 아이콘 카운트
      $scope.selectCount = '발송정보 미등록';
      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.ebaydepot).filter(field => [1, 3104].includes(userInfo.user.sol_no) || !['shop_discount', 'seller_discount', 'coupon_discount', 'point_discount'].includes(field.key));
      $scope.depotList = useWarehouseList.data.result || [];
      $scope.ebaydepotAccounts = ebaydepotAccounts.data.results || [];
      $scope.countryList = $rootScope.countryList;
      $scope.memoTemplateList = memoTemplateList.data.results || [];

      const channelList = angular.copy($rootScope.use_channel_list);
      // 주문금액 분할 모듈 사용여부
      const isCalStatePerProd = $rootScope.userProfileCheck('sol_ser', 'cal_state_per_prod', 'like') && $rootScope.user_profile.auth_type !== '배송처';
      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(channelList, function (chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      // 직접 입력 추가
      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      let prodList = []; // SKU상품 정보
      let orderList = {};
      let bundle_group = null; // 묶음 코드

      // G마켓 물류서비스 연동상태
      $scope.ord_ebaydepot_link_status = angular.copy(shipmentSVC.ordEbayLinkStatus);

      // 주문상태
      const ord_status = _.reject(angular.copy(shipmentSVC.ordStatus), (status) => {
        // 불필요한 상태들 노출 안함 (GMPKR-9677)
        return ['결제대기', '취소', '삭제'].indexOf(status.status_name) > -1;
      });

      // 발송정보 전송가능 개수
      let sendShipInfoAvailCount = 0;
      // 발송요청 가능 개수
      let sendDelivReqAvailCount = 0;

      // 총건수
      $scope.totalCount = 0;

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: '',
          search_word: '',
          search_type: 'partial',
          date_type: 'wdate',
          sdate: moment().subtract(useSystemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_id: '',
          shop_cd: '',
          status: '__ALL__',
          status_sub: '',
          page: 'order',
          bundle_yn: 1,
          delivery_vendor: '',                                                        //배송처
          to_country_code: '',                                                        //수령자 국가
          delay_status: '',                                                           //배송지연
          unstore_status: '',                                                         //출고가능여부
          map_yn: '',                                                                 //매칭여부
          gift_prod_use_yn: '',       // 규칙사은품 사용여부
          gift_prod_name: '',         // 규칙적용사은품
          multi_search_word: '',                                                      //멀티서치워드
          multi_type: 'shop_sale_no',                                                 //멀티서치 타입
          excel_down_yn: ''
        },
        searchData: {
          all_checked: false,                                                         // 전체선택 여부
          selectType: true,                                                            // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
          selectCount: 0,                                                             // 검색전체 건수
          selectBundleCnt: 0,                                                         // 선택 묶음건수
          totalCount: 0,                                                              // 검색전체 건수
          search_key_items: [                                                         // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('G마켓 물류 연동 주문번호'), value: 'ebaydepot_uniq' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('SKU상품명'), value: 'prod_name' },
            { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
            { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
            { label: gettextCatalog.getString('주소'), value: 'to_addr1,to_addr2' },
            { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
            { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
            { label: gettextCatalog.getString('운송장번호'), value: 'invoice_no' },
            { label: gettextCatalog.getString('바코드'), value: 'barcode' },
            { label: gettextCatalog.getString('배송메시지'), value: 'ship_msg' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' },
            { label: gettextCatalog.getString('배송번호'), value: 'shop_ship_no' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
            { label: gettextCatalog.getString('발송정보 등록일'), value: 'ebaydepot_shipinfo_wdate' },
            { label: gettextCatalog.getString('G마켓 물류 발송요청일'), value: 'ebaydepot_ship_req_date' },
            { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'ship_plan_date' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('G마켓 물류 연동 주문번호'), value: 'ebaydepot_uniq' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('묶음번호'), value: 'bundle_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' },
            { label: gettextCatalog.getString('변경전 묶음번호'), value: 'ori_bundle_no' }
          ]
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return !option.pa_shop_cd?.startsWith('X');
            },
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function (option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
            }
          },
          {
            // 주문상태
            title: gettextCatalog.getString('주문상태'),
            search_name: 'status',
            item_list: ord_status,
            item_key: 'status_name',
            item_value: 'status_name',
            select_value: ''
          },
          // {
          //   // 연동상태
          //   title: gettextCatalog.getString('연동상태'),
          //   search_name: 'status_sub',
          //   item_list: ord_ebaydepot_link_status,
          //   item_key: 'status_name',
          //   item_value: 'status_name',
          //   select_value: ''
          // },
          {
            // 전송가능
            title: gettextCatalog.getString('전송가능 여부'),
            search_name: 'ebaydepot_send_avail_yn',
            item_list: [{ key: 1, value: '가능' }, { key: 0, value: '불가' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: $scope.ebaydepotAccounts || [],
            item_key: 'depot_name',
            item_value: 'depot_no',
            select_value: '',
            add_class: 'select-search'

          },
          {
            // 매입처 선택
            title: gettextCatalog.getString('매입처 선택'),
            search_name: 'supp_vendor',
            item_list: Array.from(supplierList.data.result) || [],
            item_key: 'supp_name',
            item_value: 'supp_no',
            select_value: '',
            add_class: 'select-search'

          },
          {
            // 배송지연여부
            title: gettextCatalog.getString('배송지연여부'),
            search_name: 'delay_status',
            item_list: [{ key: 1, value: '배송지연 O' }, { key: 0, value: '배송지연 X' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          {
            // 규칙사은품규칙 구분
            title: gettextCatalog.getString('규칙사은품 사용여부'),
            search_name: 'gift_prod_use_yn',
            item_list: [{ text: '전체', value: 'all' }, { text: '사용', value: '1' }, { text: '미사용', value: '0' }],
            item_key: 'text',
            item_value: 'value',
            select_value: $scope.searchForm ? $scope.searchForm.gift_prod_use_yn : ''
          },
          {
            // 규칙적용 사은품
            title: gettextCatalog.getString('규칙적용사은품 선택'),
            search_name: 'gift_prod_name',
            item_list: giftList.data.results || [],
            item_key: 'gift_rule_name',
            item_value: 'gift_no',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              if ($scope.searchForm.gift_prod_use_yn === 'all') {
                return true;
              } else {
                return option.use_yn === Number($scope.searchForm.gift_prod_use_yn) && $scope.searchForm.gift_prod_use_yn;
              }
            }
          },
          {
            // 합포장여부
            title: gettextCatalog.getString('합포장 여부'),
            search_name: 'multi_bundle_yn',
            item_list: [{ key: 1, value: '합포장' }, { key: 0, value: '합포장 아님' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(channelList).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: ''
          }
        ]
      };

      if ($rootScope.user_profile.auth_type === '배송처') {
        let searchIndex = _.findIndex(search.searchDetail, { search_name: 'delivery_vendor' });

        if (searchIndex > -1) {
          search.searchDetail.splice(searchIndex, 1);
        }

        searchIndex = _.findIndex(search.searchDetail, { search_name: 'supp_vendor' });

        if (searchIndex > -1) {
          search.searchDetail.splice(searchIndex, 1);
        }
      }

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchDetailMulti = angular.copy(search.searchDetailMulti);
      $scope.searchDetailMultiSelect = angular.copy(search.searchDetailMultiSelect);
      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="picon-get"></i><span> ${gettextCatalog.getString('주문수집')}</span><span class="caret"></span>`,
            small_label: '주문수집',
            sub_label: '',
            btn_type: 'dropdown',
            add_class: 'col-xs-4',
            item_list: [
              {
                label: gettextCatalog.getString('주문 자동 수집'),
                hotkey: '',
                perm_only: ['order.order+write'],
                action: function () {
                  $scope.scrapOrderConfirmList();
                }
              },
              {
                label: `${gettextCatalog.getString('주문 수동 업로드')}<i class="icon-help fl-r" uib-tooltip="쇼핑몰 어드민에서 다운로드 받은 주문 엑셀을 솔루션에 업로드 합니다." tooltip-placement="right"></i>`,
                small_label: '주문 수동 업로드',
                hotkey: '',
                perm_only: ['order.order+write'],
                add_class: !userInfo.user.manualOrderShops.length ? 'hidden' : '',
                action: function () {
                  const modal = commonSVC.openModal('', '', 'shopOrderShipmentExcelUploadCtrl', 'views/order/shipment/modals/shop_excel_upload.html');

                  modal.result.then(function (re) {
                    if (re === 'success') {
                      $scope.searchDo(true);
                    }
                  });
                }
              }
            ]
          },
          {
            label: `<i class="icon-plus2"></i><span> ${gettextCatalog.getString('직접입력')}</span><span class="caret"></span>`,
            small_label: '직접입력',
            sub_label: '',
            btn_type: 'dropdown',
            add_class: 'col-xs-4',
            item_list: [
              {
                label: gettextCatalog.getString('주문 직접입력'),
                hotkey: '',
                perm_only: ['order.order+write'],
                action: function () {
                  const resolve = {
                    data: {
                      warehouseList: useWarehouseList.data.result || [],
                      categoryList: categoryList.data || [],
                      systemList: useSystemList.data || []
                    }
                  };
                  const modal = commonSVC.openModal('full', resolve, 'OrderShipmentManualSetCtrl', 'views/order/shipment/modals/manual_order.html');

                  modal.result.then(function (re) {
                    if (re === 'success') {
                      $scope.searchDo(true);
                    }
                  });
                }
              }
              , {
                label: gettextCatalog.getString('엑셀 일괄 등록'),
                hotkey: '',
                perm_only: ['order.order+write'],
                action: function () {
                  const modal = commonSVC.openModal('', { data: { mode: 'add' } }, 'OrderShipmentExcelUploadCtrl', 'views/order/shipment/modals/excel_upload.html');

                  modal.result.then(function (re) {
                    if (re === 'success') {
                      $scope.searchDo(true);
                    }
                  });
                }
              }
            ]
          },
          {
            label: `<i class="icon-sync worksvc-spinner-orderScrap order"></i><span> ${gettextCatalog.getString('동기화')}</span><span class="caret"></span>`,
            small_label: '동기화',
            sub_label: '',
            btn_type: 'dropdown',
            add_class: 'col-xs-4',
            item_list: [
              {
                label: gettextCatalog.getString('발송요청상태 동기화'),
                btn_type: 'button',
                perm_only: ['order.order+write'],
                action: function () {
                  $scope.syncDelivReqState();
                }
              },
              {
                label: gettextCatalog.getString('운송장정보 동기화'),
                btn_type: 'button',
                perm_only: ['order.order+write'],
                action: function () {
                  $scope.syncDelivInfo();
                }
              },
              {
                label: gettextCatalog.getString('주문 동기화'),
                sub_label: '* 건 * 시간전 수집',
                btn_type: 'button',
                perm_only: ['order.order+write'],
                action: function () {
                  $scope.orderSync();
                }
              },
            ]
          }
        ],

        table_actions: [
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('주문복사'),
                action: function () {
                  $scope.copyOrder();
                },
                perm_only: ['order.order+write']
              }, {
                label: gettextCatalog.getString('주문삭제'),
                action: function () {
                  $scope.deleteOrder();
                },
                perm_only: ['order.order+admin']
              }, {
                label: `${gettextCatalog.getString('주문분할')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="묶음 주문을 한 건씩 일괄 분할 합니다." tooltip-placement="right">`,
                menu_tooltip: '묶음 주문을 한 건씩 일괄 분할 합니다.',
                test_id: 'btn-order-split',
                action: function () {
                  $scope.divideBundle();
                },
                perm_only: ['order.order+write'],
              }, {
                label: `${gettextCatalog.getString('주문 선택분할')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="묶음 안에 주문건들을 선택하여 분리할수 있습니다." tooltip-placement="right">`,
                menu_tooltip: '묶음 안에 주문건들을 선택하여 분리할수 있습니다.',
                test_id: 'btn-order-select-split',
                action: function () {
                  $scope.divideBundleSelect();
                },
                perm_only: ['order.order+write'],
              }, {
                label: gettextCatalog.getString('합포장'),
                action: function () {
                  $scope.modifyBundle();
                },
                perm_only: ['order.order+write']
              }, {
                label: `${gettextCatalog.getString('판매금액 복구')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)" tooltip-placement="right">`,
                menu_tooltip: `판매금액 관리 기능이 적용된 주문 선택 후 규칙으로 수정된 금액을
규칙 적용 전 금액으로 복구합니다.
(특정 항목만 복구 불가)`,
                action: function () {
                  $scope.restoreSupplyPrice();
                },
                perm_only: ['order.order+write']
              }, {
                label: gettextCatalog.getString('SMS 발송'),
                action: function () {
                  $scope.sendModal('sms');
                },
                perm_only: ['order.order+admin']
              }
            ]
          }, {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('전체엑셀 다운로드'),
                action: function () {
                  $scope.excelDown('all');
                },
                perm_only: ['order.order']
              },
              {
                label: gettextCatalog.getString('선택엑셀 다운로드'),
                action: function () {
                  $scope.excelDown('select');
                },
                perm_only: ['order.order']
              }
            ]
          },
          {
            label: gettextCatalog.getString('운송장 전송'),
            add_class: 'a-btn-blue',
            action: function () {
              $scope.invoiceSender();
            },
            perm_only: ['order.order+write']
          },
          {
            label: gettextCatalog.getString('출고 보류'),
            add_class: 'a-btn-blue',
            action: function () {
              $scope.orderHold();
            },
            ngIfFunc: () => {
              return ['전체', '발송정보 미등록', '발송정보 전송실패', '판매취소 완료'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.order+write']
          },
          {
            label: gettextCatalog.getString('배송 지연 전송'),
            add_class: 'a-btn-blue',
            action: function () {
              $scope.delayedOrder();
            },
            perm_only: ['order.order+write']
          },
          {
            label: gettextCatalog.getString('|'),
            btn_type: 'partition'
          },
          {
            label: gettextCatalog.getString('SKU상품 매칭'),
            add_class: 'a-btn-blue',
            action: function () {
              $scope.mappingProd();
            },
            perm_only: ['order.order+write'],
            ngIfFunc: function () {
              return $scope.isManageStock && ['전체', '발송정보 미등록', '발송정보 전송실패', '판매취소 완료', '발송정보 수정대기', '발송요청 대기', '발송요청 실패', '발송 완료'].includes($scope.selectCount) ? 'y' : 'n';
            }
          },
          {
            label: gettextCatalog.getString('판매금액 관리'),
            add_class: 'a-btn-blue',
            action: function () {
              $scope.supplyPriceManager();
            },
            perm_only: ['order.order+write'],
          },
          {
            label: '<span>규칙 사은품</span>' + '<span class="caret"></span>',
            btn_type: 'dropdown',
            add_class: 'a-btn-blue',
            item_list: [
              {
                label: `${gettextCatalog.getString('전체 분배')}<i class="icon-help fl-r" uib-tooltip='규칙 적용 방식을 [수동]으로 설정했거나, 추가된 규칙이 있어서 전체 사은품 규칙 검사를 다시 진행하고 싶을 때 사용해 주세요.' tooltip-placement="right"></i>`,
                action: function () {
                  $scope.matchingGift('all');
                },
                perm_only: ['order.unstoring+write']
              },
              {
                label: `${gettextCatalog.getString('선택 분배')}<i class="icon-help fl-r" uib-tooltip='사은품 규칙을 선택한 일부 주문 건에만 적용하고 싶을 때 사용해 주세요.\n이미 규칙 사은품이 분배된 주문건에 수정한 사은품 규칙으로 다시 적용하려면 [규칙 사은품 > 선택 삭제] 후 분배를 진행해 주세요.' tooltip-placement="right"></i>`,
                action: function () {
                  $scope.matchingGift('select');
                },
                perm_only: ['order.unstoring+write']
              },
              {
                label: gettextCatalog.getString('선택 삭제'),
                action: function () {
                  $scope.matchingDeleteGift();
                },
                perm_only: ['order.unstoring+write']
              }
            ],
            ngIfFunc: () => {
              return ['전체', '발송정보 미등록', '발송정보 전송실패'].includes($scope.selectCount) ? 'y' : 'n';
            }
          }
        ],
        table_actions_top: [
          {
            label: gettextCatalog.getString('발송정보\r\n전송/수정'),
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-unarchive',
            action: function () {
              $scope.ebaydepotSendShipInfo();
            },
            ngIfFunc: () => {
              return ['전체', '발송정보 미등록', '발송정보 전송실패', '판매취소 완료', '발송정보 수정대기'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.order+write']
          },
          {
            label: gettextCatalog.getString('발송정보 삭제'),
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-archive_cancel',
            action: function () {
              $scope.ebaydepotDeleteShipInfo();
            },
            ngIfFunc: () => {
              return ['전체', '발송정보 수정대기', '발송요청 대기', '발송요청 실패'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.order+write']
          },
          {
            label: gettextCatalog.getString('발송요청'),
            icon_class: 'pui-archive',
            action: function () {
              $scope.ebaydepotSendDelivRequest();
            },
            ngIfFunc: () => {
              return ['전체', '발송요청 대기'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.order+write']
          },
          {
            label: gettextCatalog.getString('판매취소'),
            icon_class: 'pui-remove_shopping_cart',
            action: function () {
              $scope.ebaydepotCancel();
            },
            ngIfFunc: () => {
              return ['전체', '발송요청 완료'].includes($scope.selectCount) ? 'y' : 'n';
            },
            perm_only: ['order.order+write']
          }
        ]
      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function () {
          $scope.searchDo(true, true);
        },
        resetDo: function () {
          $scope.resetDo();
        },
        changeLen: function (count) {
          $scope.changeCount(count);
        },
        getOrderList: function () {
          // 주문 요약정보 매칭 정보
          _.forEach(orderList, function (order) {
            if (prodList[order.uniq]) {
              order.sku_pack = prodList[order.uniq][0].sku_cd;
              order.prod_name_pack = prodList[order.uniq][0].prod_name;
            }
          });

          return orderList;
        }
      };

      $scope.customContext = [
        {
          isSubMenu: true,
          line: true,
          subMemuName: '상태 수동변경',
          contextList: [
            {
              label: gettextCatalog.getString('취소요청으로 상태변경'),
              action: function () {
                $scope.userChangeStatus('취소요청');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '출고완료', '신규주문', '출고대기', '출고보류', '운송장출력'], row.ord_status) && !_.includes(['발송요청중', '발송요청 완료'], row.ebaydepot_link_status);
              }
            },
            {
              label: gettextCatalog.getString('취소완료로 상태변경'),
              action: function () {
                $scope.userChangeStatus('취소완료');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '취소요청'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('반품요청으로 상태변경'),
              action: function () {
                $scope.userChangeStatus('반품요청');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '출고완료', '배송중', '배송완료'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('반품회수완료로 상태변경'),
              action: function () {
                $scope.userChangeStatus('반품회수완료');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '반품요청'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('반품완료로 상태변경'),
              action: function () {
                $scope.userChangeStatus('반품완료');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '반품요청', '반품회수완료', '교환완료'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('교환요청으로 상태변경'),
              action: function () {
                $scope.userChangeStatus('교환요청');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '출고완료', '배송중', '배송완료', '반품회수완료'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('교환회수완료로 상태변경'),
              action: function () {
                $scope.userChangeStatus('교환회수완료');
              },
              filter: function (row) {
                return ['주문재확인', '교환요청', '맞교환요청'].includes(row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('교환완료로 상태변경'),
              action: function () {
                $scope.userChangeStatus('교환완료');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '교환요청'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('배송중으로 상태변경'),
              action: function () {
                $scope.userChangeStatus('배송중');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '출고완료'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('구매결정으로 상태변경'),
              action: function () {
                $scope.userChangeStatus('구매결정');
              },
              filter: function (row) {
                return _.includes(['주문재확인', '출고완료', '배송중', '배송완료'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('판매완료로 상태변경'),
              action: function() {
                $scope.userChangeStatus('판매완료');
              },
              filter: function(row) {
                return ['주문재확인', '배송중', '배송완료', '구매결정'].includes(row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('신규주문으로 상태변경'),
              action: function () {
                $scope.userChangeStatus('신규주문');
              },
              filter: function (row) {
                return _.includes(['출고보류', '주문재확인'], row.ord_status)
                  // 더스킨 업체 상태변경 추가
                  || (($rootScope.user_profile.sol_no === 16044 || $rootScope.user_profile.sol_no === 596) && _.includes(['취소요청', '취소완료'], row.ord_status) && row.ebaydepot_link_status === '발송정보 미등록');
              }
            },
            {
              label: gettextCatalog.getString('출고보류로 상태변경'),
              action: function () {
                $scope.userChangeStatus('출고보류');
              },
              filter: function (row) {
                return _.includes(['발송정보 미등록', '판매취소 완료'], row.ebaydepot_link_status) && row.ord_status !== '출고보류';
              }
            },
            {
              label: gettextCatalog.getString('출고대기로 상태변경'),
              action: function () {
                $scope.userChangeStatus('출고대기');
              },
              filter: function (row) {
                return _.includes(['운송장출력', '주문재확인'], row.ord_status);
              }
            },
            {
              label: gettextCatalog.getString('출고완료로 상태변경'),
              action: function () {
                $scope.userChangeStatus('출고완료');
              },
              filter: function (row) {
                return row.ord_status !== '배송중' && row.ord_status !== '출고완료' && row.ebaydepot_link_status !== '발송 완료';
              }
            }
          ]
        },
        {
          isSubMenu: true,
          line: false,
          subMemuName: '연동 상태 수동변경',
          contextList: [
            {
              label: '신규주문, 발송정보 미등록으로 상태변경',
              action: function () {
                updateEbaydepotStatus('신규주문,발송정보 미등록', 'USER');
              },
              filter: function (row) {
                // 더스킨팩토리 노출조건 수정
                if (userInfo.user.sol_no === 16044) {
                  return row.ebaydepot_link_status === '발송정보 전송실패' ? row.ord_status === '취소요청' : row.ebaydepot_link_status === '발송요청 완료';
                } else {
                  return row.ord_status === '취소요청' && row.ebaydepot_link_status === '발송요청 완료';
                }
              }
            },

            /**
             * 2022-08-23 Denver
             * 더스킨팩토리 복사 주문 건 한정 배송 완료로 수동 변경 가능 & 수동 변경 시 G마켓 물류 주문 상태 발송 완료로 업데이트
             * 디버그 모드 노출, 다른 업체 미노출
             * https://playautogmpproject.atlassian.net/browse/PA20-5417
             */
            ...($rootScope.adminMode || userInfo.user.sol_no === 16044 ? [{
              label: gettextCatalog.getString('배송완료, 발송 완료로 상태변경'),
              action: function () {
                updateEbaydepotStatus('배송완료,발송 완료', 'USER');
              },
              filter: function (row) {
                return !row.ord_status.includes('배송완료');
              }
            },
            {
              label: gettextCatalog.getString('교환완료, 발송 완료로 상태변경'),
              action: function () {
                updateEbaydepotStatus('교환완료,발송 완료', 'USER');
              },
              filter: function (row) {
                return !['교환완료', '반품완료'].includes(row.ord_status) && row.shop_ord_no.includes('_copy');
              }
            },
            {
              label: gettextCatalog.getString('반품완료, 발송 완료로 상태변경'),
              action: function () {
                updateEbaydepotStatus('반품완료,발송 완료', 'USER');
              },
              filter: function (row) {
                return !['교환완료', '반품완료'].includes(row.ord_status) && row.shop_ord_no.includes('_copy');
              }
            }
            ] : [])
          ]
        }
      ];

      // 관리자 접속인경우 주문상태변경 기능 적용.
      if ($rootScope.adminMode) {
        $scope.customContext.push({
          isSubMenu: true,
          line: false,
          subMemuName: '연동 상태 수동변경(DEV)',
          contextList: [
            {
              label: '발송정보 미등록으로 상태변경(DEV)',
              action: function () {
                updateEbaydepotStatus('발송정보 미등록', 'SYSTEM');
              }
            },
            {
              label: '발송요청 대기로 상태변경(DEV)',
              action: function () {
                updateEbaydepotStatus('발송요청 대기', 'SYSTEM');
              }
            },
            {
              label: '발송요청 완료로 상태변경(DEV)',
              action: function () {
                updateEbaydepotStatus('발송요청 완료', 'SYSTEM');
              }
            },
            {
              label: '발송요청 실패로 상태변경(DEV)',
              action: function () {
                updateEbaydepotStatus('발송요청 실패', 'SYSTEM');
              }
            },
            {
              label: '발송 완료로 상태변경(DEV)',
              action: function () {
                updateEbaydepotStatus('발송 완료', 'SYSTEM');
              }
            },
            {
              label: '판매취소 완료로 상태변경(DEV)',
              action: function () {
                updateEbaydepotStatus('판매취소 완료', 'SYSTEM');
              }
            },
            {
              label: '발송정보 전송실패로 상태변경(DEV)',
              action: function () {
                updateEbaydepotStatus('발송정보 전송실패', 'SYSTEM');
              }
            },
          ]
        });
      }

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      // 리스트 액션
      $scope.listActions = [

      ];

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notVisibleColumns: ['notice_msg', 'ship_delay_yn', 'ord_time', 'pay_time', 'order_tel', 'order_htel', 'to_tel', 'c_sale_cd', 'ship_cost', 'ship_method', 'shop_cost_price', 'shop_supply_price', 'barcode', 'shop_ship_no', 'prod_name', 'bundle_avail_yn', 'shop_ord_no_real'],
        // 만일 일원화를 원래대로 돌아가려면 notSortingColumns를 삭제하면 됨
        notSortingColumns: [
          'widget',                     // 도구
          'prod_name',                  // SKU상품명(세트인경우 세트상품명)
          'shop_opt_name',              // 옵션
          // 'ship_msg',                   // 배송메세지로 정렬하던 업체가 있어서 재설정
          'notice_msg',                 // 기타메세지
          'pack_unit',                  // 건별출고수량
          'total_cnt',                  // 총 출고수량
        ],
        bundleOptions: {
          bundleCountKey: 'selectBundleCnt',
          bundleDataKey: 'bundle_no',
          bundleUniqKey: 'uniq'
        },
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/ebaydepot/list`,
          requestWillAction: function(data) {
            if ($rootScope.order_search.page === 'order' && $rootScope.order_search.search) {
              $scope.searchForm.search_word = $rootScope.order_search.input;
              $scope.searchForm.search_key = $rootScope.order_search.key;
              $scope.searchData.search_key_name = $rootScope.order_search.name;
              $rootScope.order_search.search = false;
              $scope.selectCount = '전체';
            }

            if ($rootScope.side_search.page === 'order' && $rootScope.side_search.search) {
              $scope.searchForm.date_type = $rootScope.side_search.date_type;
              $scope.searchForm.sdate = $rootScope.side_search.sdate;
              $scope.searchForm.edate = $rootScope.side_search.edate;
              $scope.searchForm.status = $rootScope.side_search.status;
              $scope.selectCount = $rootScope.side_search.selectCount;
              $scope.searchForm.delay_status = $rootScope.side_search.delay_status;
              $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
              $rootScope.side_search.search = false;
            }

            // 주문상태 별도처리
            // $scope.searchForm.status = !$scope.searchForm.status ? ['__ALL__'] : (typeof $scope.searchForm.status === 'object' ? $scope.searchForm.status : [$scope.searchForm.status]);

            // // 연동상태 별도처리
            // $scope.searchForm.status_sub = !$scope.searchForm.status_sub ? ['발송정보 미등록'] : (typeof $scope.searchForm.status_sub === 'object' ? $scope.searchForm.status_sub : [$scope.searchForm.status_sub]);
            $scope.searchForm.status_sub = !$scope.searchForm.status_sub ? '발송정보 미등록' : $scope.searchForm.status_sub;

            // 배송처 별도처리
            // G마켓물류 배송처를 전부 ','로 합쳐서 보내면 알아서 그 배송처들인 주문들을 가져옴 / 프로시저 처리
            // $scope.searchForm.delivery_vendor = !$scope.searchForm.delivery_vendor ? _.map($scope.ebaydepotAccounts, 'depot_no').join(',') : $scope.searchForm.delivery_vendor;

            data = angular.merge({}, data, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            if ($rootScope.user_profile.auth_type === '배송처') {
              data.delivery_vendor = $rootScope.user_profile.depot_no;
            }

            return data;
          },
          requestDidAction: function (result) {
            $scope.searchData.totalCount = result.recordsTotalCount;
            $scope.totalCount = result.recordsTotalCount;
            $scope.bundleCnt = result.recordsTotal;

            prodList = [];
            result.results_sub.forEach(function (subRow) {
              if (subRow.add_opt_yn != 1) {
                if (!prodList[subRow.uniq]) {
                  prodList[subRow.uniq] = [];
                }
                prodList[subRow.uniq].push(subRow);
              }
            });

            // 합포장 분리시 필요
            bundle_group = _.groupBy(result.results, 'bundle_no');
            orderList = result.results;

            // 발송정보 전송가능 개수
            sendShipInfoAvailCount = result.availCount.ebaydepot_send_avail_cnt;
            // 발송요청 가능 개수
            sendDelivReqAvailCount = result.availCount.ebaydepot_ship_avail_cnt;

            //상단 카운팅
            countList(result.statusSubCount);

            return result.results;
          },
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 170,
            template: function (row) {
              if (!bundle_group[row.bundle_no]) {
                return;
              }

              const divide =
                bundle_group[row.bundle_no].length > 1 && !BUNDLE_ACTION_NOT_ALLOWED_STATUS.includes(row.ebaydepot_link_status)
                  ? `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.divideBundle('${row.bundle_no}','${row.uniq}')">주문분할</button>`
                  : '<button class="btn btn-default btn-xxs btn-default-no" disabled>주문분할</button>';

              return `${'' +
                '<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(\''}${row.uniq}')">상세</button>` +
                `<button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>${divide}`;
            }
          },
          {
            key: 'bundle_no',
            title: '묶음번호',
            width: 170
          },
          {
            key: 'ship_unable_reason',
            title: '출고가능여부',
            width: 90,
            notCompile: true,
            template: function (row) {
              let dot = '<span class="text-success-600">●</span>';

              if (!row.ship_avail_yn) {
                let reason = row.ship_unable_reason || '';

                // 주문수집 및 매칭과정에서의 오류로 매칭정보는 있으나 배송처정보가 없는 경우가 발생해서 해당 주문은 재매칭하게 안내 2018-11-14 Amelia
                if (!row.depot_no && prodList[row.uniq][0].sku_cd) {
                  reason = '배송처 오류(매칭 재시도 요망)';
                }

                // 묶음주문상태확인 메세지는 묶여있는 다른 주문의 재고부족등의 이슈로 해당 주문은 출고가능이기 때문에 조건 처리함 2018-10-31 rony
                if (reason !== '묶음주문 상태확인') {
                  dot = `<span class="text-danger">불가(${reason})</span>`;
                }
              }

              return dot;
            }
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function (row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'ebaydepot_link_status',
            title: '연동상태',
            width: 100,
            notCompile: true,
            template: function (row) {
              return $filter('statusColorEbaydepot')(row.ebaydepot_link_status);
            }
          },
          {
            key: 'ebaydepot_send_avail_yn',
            title: '전송가능',
            tooltip: 'G마켓 물류서비스로 발송정보 전송 가능여부',
            width: 90,
            template: function (row) {
              if (row.ebaydepot_send_avail_yn) {
                return '<i class="icon-primitive-dot text-success-600"></i>';
              } else {
                return `<span uib-tooltip="${row.ebaydepot_send_unable_reson}" tooltip-append-to-body="true" tooltip-placement="right"> <i class="icon-primitive-dot text-grey-100"></i></span>`;
              }
            }
          },
          {
            key: 'ebaydepot_shipinfo_wdate',
            title: 'G마켓 물류 발송정보 등록일',
            width: 170,
            filter: 'dateValid'
          },
          {
            key: 'ebaydepot_ship_req_date',
            title: 'G마켓 물류 발송요청일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'map_yn',
            title: '매칭여부',
            requireStock: true,
            width: 80,
            template: function (row) {
              let btnClass = 'btn btn-xxs mr-5',
                  text,
                  event = `ng-click="grid.appScope.mappingProd('${row.uniq}','${row.wdate}')"`,
                  tooltip = '';

              if (row.map_yn) {
                btnClass += ' btn-success';
                text = '수정';
              } else {
                btnClass += ' btn-warning';
                text = '미매칭';
              }

              if (['발송요청중', '발송요청 완료', '발송 완료'].includes(row.ebaydepot_link_status)) {
                btnClass += ' disabled';
                event = '';
                tooltip = '연동상태가 발송요청중, 발송요청완료, 발송 완료인 주문은 주문정보 수정이 불가합니다.';
              }

              return `<button class="${btnClass}"${event} uib-tooltip="${tooltip}" tooltip-append-to-body="true" tooltip-placement="bottom">${text}</button>`;
            }
          },
          {
            key: 'sku_cd',
            title: 'SKU코드',
            requireStock: true,
            width: 160,
            template: function (row) {
              if (row.set_no) {
                return `<button class="btn btn-xxs btn-success" ng-click="grid.appScope.showSetDetail(${row.set_no})">세트</button> ${row.set_cd}`;
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq].length > 1 ?
                  `<button class="btn bg-white border-success-400 text-success-400 btn-xxs" ng-click="grid.appScope.showMultiSKUDetail('${row.uniq}')">다중</button> ${prodList[row.uniq][0].sku_cd} 외 ${prodList[row.uniq].length - 1}건`
                  : prodList[row.uniq][0].sku_cd;
              } else {
                return '미매칭 출고';
              }
            }
          },
          {
            key: 'prod_name',
            title: 'SKU상품명',
            requireStock: true,
            width: 160,
            template: function (row) {
              if (row.set_no) {
                const skuNames = row.prod_name?.split(';');
                const skuCodes = row.sku_cd?.split(';');
                const tds = [];

                for (let idx = 0; idx < skuNames?.length; idx++) {
                  const skuName = skuNames[idx]?.split('=');
                  const skuCode = skuCodes[idx]?.split('=');
                  tds.push(`
                    <tr>
                      <td><center>${skuCode[0]}</center></td>
                      <td><center>${skuName[0]}</center></td>
                      <td width=\\'20px\\'><center>${skuName[1]}</center></td>
                    </tr>
                  `);
                }
                const toolTipMsg = `
                  '<table class=\\'table\\'>
                    <tr>
                      <th><center>${row.set_cd}</center></th>
                      <th colspan=2>${row.set_name}</th>
                    </tr>
                    ${tds.join(' ')}
                  </table>'
                `;

                return `<span uib-tooltip-html="${toolTipMsg}" tooltip-append-to-body="true" tooltip-placement="auto">${row.set_name}</span>`;
              } else if (row.sku_cd) {
                return row.prod_name.split('=')[0];
              } else {
                return '미매칭 출고';
              }
            }
          },
          {
            key: 'stock_cd',
            title: '재고관리코드',
            tooltip: '세트상품의 경우 대표로 지정된 SKU의 재고관리코드만 항목에 노출됩니다.',
            requireStock: true,
            width: 160,
            template: (row) => {
              if (row.set_no && prodList[row.uniq]) {
                const findList = prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

                return findList ? findList.stock_cd : '';
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq][0].stock_cd;
              } else {
                return '';
              }
            }
          },
          {
            key: 'pack_unit',
            title: '건별출고수량',
            tooltip: '주문 1건당 출고되는 수량 (기본옵션)',
            width: 110,
            notCompile: true,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].includes(row.ord_status) && !$rootScope.osse_sol ? 0 : row.pack_unit;
            }
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function (row) {
              let img = '직접입력';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(useSystemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'ebaydepot_uniq',
            title: 'G마켓 물류 연동 주문번호',
            tooltip: 'ESM 발송정보등록 시 해당 항목이 [주문번호]로 전송됩니다.',
            width: 190,
            notCompile: true,
            template: function (row) {
              return row.ebaydepot_uniq || '-';
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 190,
            notCompile: true,
            template: function (row) {
              return row.shop_ord_no || '-';
            }
          },
          {
            key: 'shop_ord_no_real',
            title: '원주문번호',
            width: 190,
            notCompile: true,
            template: function (row) {
              return row.shop_ord_no_real || '-';
            }
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 140,
            template: function (row) {
              if (row.shop_cd === 'A011') {
                row.shop_sale_no = row.misc16;
              }

              let sale_no = row.shop_sale_no || '';
              const shop_cd = row.shop_cd;
              const shop_id = row.shop_id;

              if (sale_no && !onlineProductSVC.impossibeDetailView.some(shopCd => row.shop_cd.includes(shopCd))) {
                if (shop_cd === 'B378' && row.misc9) {
                  sale_no += `?vendorItemId=${row.misc9}`;
                }

                return `<span>
                  <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                  <i class="picon-link2 ml-5 mr-5 cursor-pointer text-grey" ng-click="grid.appScope.shopDetailView('${shop_cd}','${shop_id}','${sale_no}')" style="font-size: 11px"></i></span>`;
              } else {
                return sale_no;
              }
            }
          },
          {
            key: 'shop_sale_name',
            title: '상품명',
            width: 300,
            notCompile: true,
            template: function (row) {
              const exchangeSpan = row.exchange_ord_yn == 1 || row.exchange_yn == 1 ? '<span class="label label-default bg-blue">교환주문</span> ' : '';
              let giftSpan = '';
              let arrivalGuaranteed = '';

              // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가 및 도착보장 플래그 추가
              if (['A077', 'A000'].includes(row.pa_shop_cd)) {
                giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
                arrivalGuaranteed = ['true', 'is_arrival_guaranteed'].includes(row.misc17) ? '<span><img ng-src="/assets/images/naver_flagX2.png" width="75" height="25" class="mr-5" alt="" src="/assets/images/naver_flagX2.png"></span>' : '';
              }
              // 클레임 주문에 대한 사본주문은 플래그 노출
              const claimCopyOrderSpan = row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-red">사본</span> ' : '';
              const dividedOrderSpan = $rootScope.osse_sol && row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') && !Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_claim_copy') ? '<span class="label label-default bg-yellow">분할</span> ' : '';
              const originOrderSpan = $rootScope.osse_sol && !row.ori_uniq && row.misc_etc && Array.isArray(row.misc_etc) && Object.prototype.hasOwnProperty.call(row.misc_etc?.[0] || {}, 'is_origin_order') ? '<span class="label label-default bg-black">원주문</span> ' : '';
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

              if (row.sale_img) {
                const img = row.sale_img || '/assets/images/upload.png';

                return `<img src='${img}' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${exchangeSpan}${arrivalGuaranteed}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}</span>`;
              } else {
                return `<span>${exchangeSpan}${arrivalGuaranteed}${giftSpan}${originOrderSpan}${dividedOrderSpan}${claimCopyOrderSpan}${filteredShopSaleName}</span>`;
              }
            }
          },
          {
            key: 'shop_opt_name',
            title: '옵션',
            width: 220,
            template: function(row) {
              return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
            }
          },
          {
            key: 'sale_cnt',
            title: '수량',
            width: 60,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : row.sale_cnt;
            }
          },
          {
            key: 'add_opt_sku_cd',
            title: '추가구매옵션',
            width: 150,
            notCompile: true,
            template: function (row) {
              if (row.add_opt_sku_cd) {
                const names = row.shop_add_opt_name.split(',');
                const cds = row.add_opt_sku_cd.split(';');

                return _.slice(names, 0, cds.length).join(', ');
              } else {
                return '-';
              }
            }
          },
          {
            key: 'add_opt_pack_unit',
            title: '추가구매옵션 수량',
            width: 150,
            notCompile: true,
            template: function (row) {
              if (row.add_opt_sku_cd) {
                const cds = row.add_opt_sku_cd.split(';');

                return _.map(cds, function (cd) {
                  return cd.split('=')[1];
                }).join(', ');
              } else {
                return '-';
              }
            }
          },
          {
            key: 'sales',
            title: '금액',
            width: 100,
            notCompile: true,
            template: function (row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.sales, '', 0);
            }
          },
          {
            key: 'pay_amt',
            title: '실결제금액',
            tooltip: '쇼핑몰에서 수집한 상품의 실결제금액(해당 값을 제공하지 않는 경우 공란)',
            width: 100,
            template: function(row) {
              let value = '';

              if (row.pay_amt !== null) {
                value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                  row.ord_status
                ) > -1 && !$rootScope.osse_sol
                  ? 0
                  : $filter('currency')(row.pay_amt, '', 0);
              }

              return value;
            }
          },
          {
            key: 'discount_amt',
            title: '할인금액',
            tooltip: '쇼핑몰에서 수집한 상품의 할인금액(해당 값을 제공하지 않는 경우 공란)',
            width: 100,
            template: function(row) {
              let value = '';

              if (row.discount_amt !== null) {
                value = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                  row.ord_status
                ) > -1 && !$rootScope.osse_sol
                  ? 0
                  : $filter('currency')(row.discount_amt, '', 0);
              }

              return value;
            }
          },
          ...([1, 3104].includes(userInfo.user.sol_no) ? [
            {
              key: 'shop_discount',
              title: '쇼핑몰부담할인액',
              width: 100,
              template: (row) => $filter('currency')(row.shop_discount, '', 0)
            },
            {
              key: 'seller_discount',
              title: '판매자부담할인액',
              width: 100,
              template: (row) => $filter('currency')(row.seller_discount, '', 0)
            },
            {
              key: 'coupon_discount',
              title: '쿠폰할인액',
              width: 100,
              template: (row) => $filter('currency')(row.coupon_discount, '', 0)
            },
            {
              key: 'point_discount',
              title: '포인트할인액',
              width: 100,
              template: (row) => $filter('currency')(row.point_discount, '', 0)
            }, ] : []),
          {
            key: 'order_name',
            title: '주문자(ID)',
            width: 150,
            notCompile: true,
            template: function (row) {
              let return_value = row.order_name;

              if (row.order_id) {
                return_value += `(${row.order_id})`;
              }

              return return_value;
            }
          },
          {
            key: 'to_name',
            title: '수령자명',
            width: 100
          },
          {
            key: 'ship_msg',
            title: '배송메세지',
            width: 200
          },
          {
            key: 'notice_msg',
            title: '기타메세지',
            width: 200
          },
          {
            key: 'to_htel',
            title: '수령자 휴대폰번호',
            width: 200
          },
          {
            key: 'to_addr1',
            title: '주소',
            width: 300,
            notCompile: true,
            template: function(row) {
              return row.to_addr1 + (` ${row.to_addr2}` || '');
            }
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 150
          },
          {
            key: 'ord_bundle_gift_terms_prod',
            title: '규칙적용사은품',
            tooltip: '솔루션에서 설정한 규칙이 적용된 사은품입니다.\n묶음 주문은 적용된 규칙 사은품이 각 주문 별로 모두 출력됩니다.',
            width: 120,
            template: function (row) {
              // 사은품명1#SKU코드1#재고코드1#속성1#개수1#사은품명1(sku명X)#사은품규칙명1#바코드1|사은품명2#SKU코드2#재고코드2#속성2#개수2#사은품명2(sku명X)#사은품규칙명2#바코드2
              let gift_prod_name = '';

              if (row.gift_pack) {
                const regex = /([^|]*?(?:[^#]*#){7}[^#]*?)(?=\|)/g;

                // 사은품명에 특수문자 '|' 포함한 경우가 있어서 #가 7번 반복된 이후 나오는 '|' 기준으로 사은품 쪼개기
                const giftList = [];
                let match;
                let prevIdx = 0;
                let idx = 0;

                while ((match = regex.exec(row.gift_pack))) {
                  giftList.push(idx ? match[0].substring(1) : match[0]);
                  prevIdx = regex.lastIndex;
                  idx++;
                }

                giftList.push(prevIdx ? row.gift_pack.substring(prevIdx).substring(1) : row.gift_pack);

                gift_prod_name = giftList.map(gift => {
                  const vals = gift.split('#'); // [0]: 사은품명, [1]: SKU코드, [4]: 사은품출고개수

                  return `${vals[0]}-${vals[4]}개`;
                }).join(', ');
              }

              return gift_prod_name;
            }
          },
          {
            key: 'gift_name',
            title: '사은품',
            width: 100
          },
          {
            key: 'carr_name',
            title: '택배사',
            width: 100
          },
          {
            key: 'invoice_no',
            title: '운송장번호',
            width: 120,
            template: function (row) {
              const invoice_no = row.invoice_no;
              const carr_no = row.carr_no;

              if (carr_no && invoice_no && $rootScope.possibeTrackingView.includes(carr_no)) {
                return `<span>${invoice_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${carr_no}','${invoice_no}')"></i></span>`;
              } else {
                return row.invoice_no;
              }
            }
          },
          {
            key: 'ship_plan_date',
            title: '발송예정일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ship_delay_yn',
            title: '배송지연여부',
            width: 100,
            notCompile: true,
            template: function(row) {
              return row.ship_delay_yn ? '<i class="icon-primitive-dot text-success-600"></i>' : '<i class="icon-primitive-dot text-grey-100"></i>';
            }
          },
          {
            key: 'ord_time',
            title: '주문일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'order_tel',
            title: '주문자 전화번호',
            width: 150
          },
          {
            key: 'order_htel',
            title: '주문자 휴대폰번호',
            width: 150
          },
          {
            key: 'to_tel',
            title: '수령자 전화번호',
            width: 150
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 150
          },
          {
            key: 'shop_ship_no',
            title: '배송번호',
            width: 140
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 100,
            notCompile: true,
            template: function (row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.ship_cost, '', 0);
            }
          },
          {
            key: 'ship_method',
            title: '배송방법',
            width: 120
          },
          {
            key: 'shop_cost_price',
            title: '원가',
            width: 100,
            notCompile: true,
            template: function (row) {
              return $filter('currency')(row.shop_cost_price, '', 0);
            }
          },
          {
            key: 'shop_supply_price',
            title: '공급가',
            width: 100,
            notCompile: true,
            template: function (row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.shop_supply_price, '', 0);
            }
          },
          {
            key: 'barcode',
            title: '바코드',
            width: 15,
            template: (row) => {
              const matchingOrder = prodList[row.uniq] &&
                prodList[row.uniq].find(p => p.prod_no === row.main_prod_no);

              if (row.set_no && prodList[row.uniq] && matchingOrder) {
                return matchingOrder.barcode;
              } else if (prodList[row.uniq]) {
                return prodList[row.uniq][0].barcode;
              } else {
                return '';
              }
            }
          },
          {
            key: 'bundle_avail_yn',
            title: '합포장 가능여부',
            width: 100,
            template: function (row) {
              return row.bundle_avail_yn ? '가능' : '불가(개별배송)';
            }
          }
        ]
      };

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function () {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchData.search_key_name = '전체';
        $scope.selectCount = '발송정보 미등록';
        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
			 * 이벤트 처리
			 */
      $scope.listEvent = function(event) {
        if (event) {
          $scope[event]();
        }
      };

      /**
       * 2018-07-02 Daniel
       * G마켓 물류 배송현황관리 바로가기
       */
      $scope.ebayShipScm = function (account) {
        const mall = {
          shop_cd: 'ESMP',
          pa_shop_cd: 'ESMP',
          shop_id: account.ebaydepot_id,
          shop_pwd: account.ebaydepot_pwd,
          admin_url: 'https://www.esmplus.com/Member/SignIn/LogOn?ReturnUrl=%2FHome%2FHome%23HTDM366',
          scm_login_script: '$("#Id").val("{{shop_id}}");$("#Password").val("{{shop_pwd}}");$("#btnLogOn")[0].click();'
        };

        $rootScope.sellerCenter_login(mall);
      };

      /**
       * 2018-02-12 ally
       * SKU상품 매칭
       * */
      $scope.mappingProd = function (uniq, wdate) {

        // 주문재고매칭권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('shipping.roles.mappingProd', userInfo.permission) === false) {
          return false;
        }

        const selected = $scope.grid.methods.selectedData('all');

        if (!selected.length && !uniq) {
          commonSVC.showMessage('선택된 주문이 없습니다.');

          return false;
        }

        if (selected.length && _.filter(selected, row => ['발송요청중', '발송요청 완료', '발송 완료'].includes(row.ebaydepot_link_status)).length) {
          commonSVC.showMessage('SKU상품 매칭이 불가능한 주문이 있습니다.', '연동상태가 발송요청중 ,발송요청완료, 발송 완료인 주문은 주문정보 수정이 불가합니다.');

          return false;
        }

        const uniqList = uniq ? [uniq] : _.map(selected, 'uniq');

        const firstWdate = wdate ? [wdate] : _.sortBy($scope.grid.methods.selectedData('wdate'), function (o) {
          return new moment(o);
        });

        const resolve = {
          // 창고 리스트 조회
          warehouseList: function (warehouseModel) {
            return warehouseModel.ListAll({ use_yn: true });
          },
          //환경설정 리스트
          systemList: function (systemModel) {
            return systemModel.load();
          },
          //선택한 uniqList
          selectList: { uniq: uniqList, wdate: firstWdate[0] }
        };

        const redata = commonSVC.openModal('full', resolve, 'OrderShipmentMappingCtrl', 'views/order/shipment/modals/mapping.html');

        redata.result.then(function(isMapping) {
          if (isMapping) {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 출고보류
       */
      $scope.orderHold = function () {
        const selected = $scope.grid.methods.selectedData('all');

        if (!selected.length) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        // 출고보류 가능 연동상태 : 발송정보 미등록, 판매취소완료
        // 위의 상태가 아닌 주문건이 있는경우 에러. 2018-02-22 rony
        const chkStatus = _.filter(selected, function (o) {
          return _.indexOf(['발송정보 미등록', '판매취소 완료'], o.ebaydepot_link_status) < 0;
        });

        if (chkStatus.length) {
          commonSVC.showMessage('출고보류가 불가능한 상태의 주문건이 포함되어있습니다.', '연동상태가 발송정보 미등록 또는 판매취소완료인 주문만 출고보류 상태로 변경이 가능합니다.\n해당 상태의 주문건만 선택하여 주신 후 작업을 재시도해 주십시오.');

          return false;
        }

        const resolve = {};

        resolve.data = {
          selectList: selected
        };

        const modal = commonSVC.openModal('', resolve, 'orderHoldCtrl', 'views/order/shipment/modals/order_hold.html');

        modal.result
          .then(function (re) {
            if (re === 'success') {
              $scope.searchDo(false);
            }
          });
      };

      /**
       * 2018-07-05 Daniel
       * 발송정보 전송/수정
       */
      $scope.ebaydepotSendShipInfo = async () => {
        const selected = $scope.grid.methods.selectedData('all'),
              possibleCount = sendShipInfoAvailCount;

        if (selected.length) {
          if (_.filter(selected, { ebaydepot_send_avail_yn: 0 }).length) {
            commonSVC.showMessage('오류', '전송이 불가능한 주문이 있습니다.\n전송가능 필드에서 사유를 확인후 재요청 바랍니다.');

            return false;
          }
        } else {
          if (!possibleCount) {
            commonSVC.showMessage('작업등록 실패', '발송정보 전송/수정 작업이 가능한 주문이 없습니다.');

            return false;
          }
        }

        try {
          const { data: { order_cnt } } = await shipmentModel.checkBundleNo({ orderList: selected.map(({ uniq, bundle_no }) => ({ uniq, bundle_no })) });

          if (selected.length !== order_cnt) {
            commonSVC.showMessage('오류', '묶음번호가 변경된 주문이 있습니다.\n새로고침 후 재확인 바랍니다.');

            return false;
          }

          const resolve = {
            ebaydepotAccounts: shopAccountModel.getEbaydepotAccounts(),
            data: {
              selected: selected,
              possibleCount: possibleCount,
              searchData: $scope.pageData
            }
          };

          commonSVC.openModal('lg', resolve, 'OrderShipmentEbaydepotSendShipInfoCtrl', 'views/order/shipment/modals/ebaydepot_send_ship_info.html');
        } catch (error) {
          commonSVC.showMessage('오류', '묶음번호 확인이 실패되었습니다.\n새로고침 후 재확인 바랍니다.');
        }
      };

      /**
       * 2018-07-10 Daniel
       * 발송정보 삭제
       */
      $scope.ebaydepotDeleteShipInfo = function () {
        const selected = $scope.grid.methods.selectedData('all');

        const resolve = {
          ebaydepotAccounts: shopAccountModel.getEbaydepotAccounts(),
          data: {
            selected: selected
          }
        };

        if (!selected.length) {
          commonSVC.showMessage('주문을 선택해주세요.');

          return false;
        }

        // 공통 함수로 검사
        const checkValue = commonSVC.checkRulesAndConfirm(
          'ebaydepotSendShipInfo',
          '발송정보 삭제',
          $scope.grid.methods,
          selected,
          $scope.searchData.totalCount,
          'ebaydepot_link_status',
          ['발송정보 수정대기', '발송요청 대기', '발송요청 실패'],
          [],
          true
        );

        if (!checkValue.isOpen) {
          return false;
        }

        commonSVC.openModal('lg', resolve, 'OrderShipmentEbaydepotDeleteShipInfoCtrl', 'views/order/shipment/modals/ebaydepot_delete_ship_info.html');
      };

      /**
       * 2018-07-10 Daniel
       * 발송요청
       */
      $scope.ebaydepotSendDelivRequest = function () {
        // G마켓 물류 요청으로 임시 해제
        // if (parseInt(moment().format('HHmmss')) > 160000) {
        //   commonSVC.showMessage('G마켓 물류서비스를 이용한 주문건의 당일 출고를 위하여 발송요청은 00시 ~ 16시까지만 가능합니다.');
        //   return false;
        // }

        const selected = $scope.grid.methods.selectedData('all');
        const possibleCount = sendDelivReqAvailCount; // 발송요청 대기, 발송요청 실패 상태 카운트더함
        const resolve = {
          ebaydepotAccounts: shopAccountModel.getEbaydepotAccounts(),
          data: {
            selected: selected,
            possibleCount: possibleCount,
            searchData: $scope.pageData
          }
        };

        if (selected.length) {
          if (selected.find(o => o.ord_status === '판매완료')) {
            commonSVC.showMessage('오류', '판매완료 상태의 주문건은 물류센터 발송요청이 불가능합니다.\n판매완료 상태의 주문건을 제외 후 재시도해주십시오.');

            return false;
          }

          // 공통 함수로 검사
          const checkValue = commonSVC.checkRulesAndConfirm(
            'ebaydepotSendDelivRequest',
            '발송요청',
            $scope.grid.methods,
            selected,
            $scope.searchData.totalCount,
            'ebaydepot_link_status',
            ['발송요청 대기', '발송요청 실패'],
            [],
            true
          );

          if (!checkValue.isOpen) {
            return false;
          }
        } else {
          if (!possibleCount) {
            commonSVC.showMessage('발송요청 작업이 가능한 주문이 없습니다.');

            return false;
          }
        }

        commonSVC.openModal('lg', resolve, 'OrderShipmentEbaydepotSendDelivRequestCtrl', 'views/order/shipment/modals/ebaydepot_send_deliv_request.html');
      };

      /**
       * 2018-07-10 Daniel
       * 판매취소
       */
      $scope.ebaydepotCancel = function () {
        const selected = $scope.grid.methods.selectedData('all');

        const resolve = {
          ebaydepotAccounts: shopAccountModel.getEbaydepotAccounts(),
          data: {
            selected: selected
          }
        };

        if (!selected.length) {
          commonSVC.showMessage('주문을 선택해주세요.');

          return false;
        }

        // 공통 함수로 검사
        let checkValue = commonSVC.checkRulesAndConfirm(
          'ebaydepotSendDelivRequest',
          '판매취소',
          $scope.grid.methods,
          selected,
          $scope.searchData.totalCount,
          'ebaydepot_link_status',
          ['발송요청 완료'],
          [],
          true
        );

        if (!checkValue.isOpen) {
          return false;
        }

        checkValue = _.filter(selected, function (ord) {
          return _.indexOf(['출고완료', '구매결졍'], ord.ord_status) >= 0;
        });

        if (checkValue.length) {
          commonSVC.showMessage('오류', '출고완료된 주문은 판매취소가 불가합니다');

          return false;
        }

        commonSVC.openModal('lg', resolve, 'OrderShipmentEbaydepotCancelCtrl', 'views/order/shipment/modals/ebaydepot_cancel.html');
      };

      /**
       * 운송장 전송
       */
      $scope.invoiceSender = function () {
        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length) {
          // 묶음건중에 하나라도 출고건이 있는경우 전송되어야 하므로 groupBy 로 조건 변경함 2018-07-11 rony
          // var dataNone = _.filter($scope.grid.methods.selectedData('all'), function (d) {
          const dataNone = _(selected)
            .groupBy('bundle_no')
            .filter(function (arr_d) {
              const imposCnt = _(arr_d)
                .filter(function (o) {
                  return o.ord_status !== '출고완료' || o.shop_cd === 'A000' || o.shop_cd.startsWith('U') || o.ori_uniq || (o.misc_etc?.[0]?.is_addOpt_order && !selected.find(s => s.uniq === o.ori_uniq));
                })
                .size();

              // return d.ord_status != '출고완료' || d.shop_cd === 'A000' || d.shop_ord_no.indexOf('_copy') > -1;
              return arr_d.length === imposCnt;
            })
            .value();

          if (dataNone.length) {
            // 주문금액 분할 모듈 켜져 있을 경우 안내문구 추가
            const opt = {
              title: '운송장 전송 실패',
              text: `쇼핑몰에서 수집한 주문건 중 '출고완료' 상태 및 직접입력, 복사되지 않은 주문만 전송이 가능합니다.${isCalStatePerProd ? '\nESM+(옥션, 지마켓) 주문의 "추가구매옵션"이 원주문과 분리되어 개별 발송되는 경우, 추가구매옵션의 운송장번호는 쇼핑몰로 전송되지 않습니다.' : ''}`,
              confirmButtonText: '운송장 전송 가능 상태 주문만 조회'
            };

            commonSVC.showConfirmCustom(opt, function () {
              const reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
                return r.ord_status === '출고완료' && r.shop_cd !== 'A000' && !r.shop_cd.startsWith('U') && !r.ori_uniq && !r.misc_etc?.[0]?.is_addOpt_order;
              }, true);

              if (!reselected_indexes.length) {
                commonSVC.showToaster('error', gettextCatalog.getString('자동 선택 불가'), gettextCatalog.getString('운송장 전송이 가능한 건이 없습니다.'));
              }
            });

            return false;
          } else {
            // 넘기는 파람값이 잘못되고있어 수정 2018-10-26 rony
            commonSVC.showConfirmHtml('운송장 전송', `<b> 선택한 출고 완료 주문 (${selected.length})건의 운송장 정보를 쇼핑몰로 전송 합니다. </b> ` + '<br>전송 후 \'배송중\' 상태로 변경되며, 수정전송은 불가능하므로 주의바랍니다.', function (state) {
              if (state) {
                // 묶음건중 출고완료건만 전송해야 하기때문에 출고완료건만 걸러냄 2018-07-11 rony
                const uniqs = _(selected)
                  .filter(function (o) {
                    return o.ord_status === '출고완료' && !o.misc_etc?.[0]?.is_addOpt_order;
                  })
                  .map('uniq')
                  .value();

                const params = { numbers: uniqs };
                workSVC.addWorkSelect('SEND_INVOICE', params);
              }
            });
          }
        } else {
          const params = {
            sdate: $scope.pageData.sdate,
            edate: $scope.pageData.edate,
            ebaydepotYn: 1
          };

          shipmentModel.sendDelivNoShopList(params)
            .then(function (result) {
              const shopList = result.data;

              if (!shopList.length) {
                commonSVC.showMessage(gettextCatalog.getString('운송장 전송 가능한 주문이 없습니다.'));

                return false;
              }

              const resolve = {
                data: {
                  shopList: shopList
                }
              };

              commonSVC.openModal('lg', resolve, 'OrderShipmentSendInvoiceCtrl', 'views/order/shipment/modals/send_invoice.html');
            });
        }
      };

      /**
       * 판매금액 관리 매니저
       */
      $scope.supplyPriceManager = () => {
        const modal = commonSVC.openModal('full', { data: { uniqList: $scope.grid.methods.selectedData('uniq'), shop_id_view_type: systemList.data.shop_id_view_type } }, 'SupplyPriceManagerCtrl', 'views/order/shipment/modals/supply_price_manager.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 판매금액 관리 규칙 적용복구
       */
      $scope.restoreSupplyPrice = async () => {
        const selectList = $scope.grid.methods.selectedData('bundle_no');

        if (!selectList.length) {
          commonSVC.showMessageHtml('선택된 주문이 없습니다.', '<ul style="margin-top:20px;"><li>복구할 주문을 선택하신 후 다시 진행해 주세요.</li></ul>');

          return false;
        }

        const confirm = await commonSVC.showConfirm('판매금액을 복구 하시겠습니까?',
          '· 판매금액 복구는 규칙을 적용 이 전의 상태로 되돌려 놓습니다.\n'
      + '· 판매금액 복구는 규칙이 적용된 주문에만 적용이 가능하며,\n'
      + '  특정항목만 복구는 불가합니다.(ex. 공급가만 복구 불가)\n'
      + '· 복구된 주문은 다시 규칙을 적용할 수 있으나, 단순히 복구 이 전의 상태로\n'
      + '  되돌려 놓는것은 불가능하니 적용 전에 충분히 고려해 주세요.\n'
      + '· 판매금액 관리 규칙 내 조건이 삭제되었거나 규칙자체가 삭제된 주문은 복구되지 않으며, 이런 경우 주문 [상세]에서 직접 수정할 수 있습니다.');

        if (confirm) {
          try {
            const re = await shipmentModel.restoreSupplyPriceRule({ bundleNoList: $scope.grid.methods.selectedData('bundle_no') });

            if (re.data.results === 'success') {
              $scope.searchDo(true, true);
              commonSVC.showToaster('success', '', '데이터 복구가 완료되었습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          } catch (err) {
            if (err.data.messages[0] === 'no restore data') {
              commonSVC.showToaster('error', '', '복구 가능한 데이터가 없습니다.');
            } else {
              commonSVC.showToaster('error', '', '데이터 복구에 실패하였습니다.');
            }
          }
        }
      };

      /**
       * 상세페이지 보여주기
       */
      $scope.showDetail = function (uniq) {
        const resolve = {
          data: {
            fromPage: search.searchForm.page,
            uniq: uniq,
            warehouseList: useWarehouseList.data.result || [],
            systemList: useSystemList.data || []
          }
        };
        const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');

        modal.result.then(function (re) {
          if (re === 'success' || re === 'update') {
            $scope.searchDo(false);
          }
        });
      };

      $scope.showSetDetail = function(setNo) {
        const resolve = {
          data: { from: '수정', set_no: setNo }
        };

        commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
      };

      // 다중 매칭된 SKU 조회 모달
      $scope.showMultiSKUDetail = function (uniq) {
        const resolve = {
          data: { prodList: prodList[uniq] }
        };

        commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
      };

      // btn event..
      /**
       * 카운팅에 맞는 검색 결과
       */
      $scope.countSearch = function (type) {
        const sdate = $scope.searchForm.sdate,
              edate = $scope.searchForm.edate;

        // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
        // $scope.searchForm = angular.copy(search.searchForm);
        // $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchForm.sdate = sdate;
        $scope.searchForm.edate = edate;

        if (type === '전체') {
          $scope.searchForm.status_sub = '__ALL__';
        } else {
          $scope.searchForm.status_sub = type;
        }

        $scope.searchDo(true, true);
        $scope.selectCount = type;
      };

      /**
       * 주문수집
       */
      $scope.scrapOrderConfirmList = function () {
        // 주문수집 권한 체크 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.scrapOrder', userInfo.permission) === false) {
          return false;
        }
        commonSVC.openModal('md', { data: { work_type: 'scrap' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
      };

      /**
       * 주문 동기화
       */
      $scope.orderSync = function () {
        //  sync2: GMP (sync: EBAY)
        commonSVC.openModal('md', { data: { work_type: 'sync2' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
      };

      /**
       * 발송요청상태 동기화
       */
      $scope.syncDelivReqState = function () {
        const resolve = {
          ebaydepotAccounts: shopAccountModel.getEbaydepotAccounts()
        };

        commonSVC.openModal('lg', resolve, 'OrderShipmentEbaydepotSyncDelivRequestStateCtrl', 'views/order/shipment/modals/ebaydepot_sync_deliv_request_state.html');
      };

      /**
       * 운송장정보 동기화
       */
      $scope.syncDelivInfo = function () {
        const resolve = {
          ebaydepotAccounts: shopAccountModel.getEbaydepotAccounts()
        };

        commonSVC.openModal('lg', resolve, 'OrderShipmentEbaydepotSyncDelivInfoCtrl', 'views/order/shipment/modals/ebaydepot_sync_deliv_info.html');
      };

      /**
       * 규칙 사은품 분배
       */
      $scope.matchingGift = async (type) => {
        let bundleNoList = [];

        if (type == 'select') {
          let data = $scope.grid.methods.selectedData('all');

          if ($rootScope.osse_sol) {
            data = data.filter(d => !d.ori_uniq || d.ori_uniq && !Object.prototype.hasOwnProperty.call(d.misc_etc[0], 'is_origin_order'));
          }

          if (data.length === 0) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'), '');

            return;
          }

          bundleNoList = _.uniqBy(data.map(o => o.bundle_no));
        }

        const confirm = await commonSVC.showConfirm('규칙 사은품 분배', '사은품 분배가 안된 주문 건이 있거나 추가된 사은품 규칙에 대한 사은품 분배가 진행됩니다.');
        if (confirm) {
          try {
            const res = await giftModel.matching({ bundleNoList });

            if (res.data.result === 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙 사은품 분배에 성공하였습니다'));
              $scope.searchDo(false);
            } else if (res.data.result === 'not exist') {
              commonSVC.showMessage(gettextCatalog.getString('적용 가능한 신규 버전 사은품 규칙이 없습니다.'), '');

              return;
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙 사은품 분배에 실패하였습니다'));
            }
          } catch (error) {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙 사은품 분배에 실패하였습니다'));
          }
        }
      };

      /**
       * 규칙 사은품 삭제
       */
      $scope.matchingDeleteGift = function () {
        const bundle_code = $scope.grid.methods.selectedData('bundle_no');

        if (bundle_code.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'), '');

          return;
        }

        const findNone = $scope.grid.methods.selectedData('ebaydepot_link_status').find(ebaydepot_link_status => BUNDLE_ACTION_NOT_ALLOWED_STATUS.includes(ebaydepot_link_status));

        if (findNone) {
          commonSVC.showMessage('사은품 삭제 실패', `${findNone} 주문은 사은품 삭제가 불가합니다.`);

          return false;
        }

        // confirm 창 추가 2017-09-04 서상권
        commonSVC.showConfirm('', '설정한 사은품규칙에 의해\n 해당 주문에 적용된 규칙 사은품 내역을 삭제하시겠습니까?', function () {
          shipmentModel.matchingDeleteGift({ bundleNoList: _.uniqBy(bundle_code) }, function (state, data) {
            if (state === 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙 사은품 삭제에 성공하였습니다'));
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙 사은품 삭제에 실패하였습니다'));
            }
          });
        });
      };

      /**
       * 배송지연
       * */
      $scope.delayedOrder = function () {
        const selected = $scope.grid.methods.selectedData('all');

        if (selected.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const dataNone = selected.reduce((aur, d) => {
          if (d.ship_delay_yn || !shipmentSVC.canDeliDelayShop.includes(d.pa_shop_cd) || d.shop_ord_no.indexOf('_copy') > -1 || (d.misc_etc?.[0]?.is_addOpt_order && !selected.find(s => s.uniq === d.ori_uniq))) {
            aur.push(d);
          }

          return aur;
        }, []);

        if (dataNone.length > 0 && selected.length !== dataNone.length) {
          commonSVC.showConfirmCustom({
            title: gettextCatalog.getString('배송지연 전송'),
            text: shipmentSVC.delayDeliveryMessage.info,
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#5c90d2',
            confirmButtonText: shipmentSVC.delayDeliveryMessage.confirm,
            cancelButtonText: gettextCatalog.getString('취소'),
            animation: false,
          }, function () {

            // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
            // 2017-03-13 MatthewKim
            const reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
              return !r.ship_delay_yn && shipmentSVC.canDeliDelayShop.includes(r.pa_shop_cd) && r.shop_ord_no.indexOf('_copy') < 0 && !r.misc_etc?.[0]?.is_addOpt_order;
            }, true);

            if (reselected_indexes.length == 0) {
              commonSVC.showToaster('error', '자동 선택 불가', shipmentSVC.delayDeliveryMessage.fail);
            }
          });

          return false;
        } else if (dataNone.length > 0 && selected.length === dataNone.length) {
          commonSVC.showMessageHtml(shipmentSVC.delayDeliveryMessage.fail, shipmentSVC.delayDeliveryMessage.info);

          return false;
        }

        const resolve = {
          systemList: function () {
            return systemModel.load();
          }
        };

        resolve.data = {
          fromPage: 'order',
          selectList: selected
        };

        commonSVC.openModal('', resolve, 'delayDeliveryCtrl', 'views/order/shipment/modals/delay_delivery.html');
      };

      /**
       * 주문삭제
       * */
      $scope.deleteOrder = function (uniq) {

        // 삭제권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('shipping.roles.deleteOrder', userInfo.permission) === false) {
          return false;
        }

        let param = [];

        if (uniq) {
          param = [uniq];
        } else {
          param = $scope.grid.methods.selectedData('uniq');
        }

        if (param.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        commonSVC.showConfirmHtml(`선택한 주문 <b class='text-warning'>${param.length}</b> 건을 삭제 하시겠습니까?`, '<div class="sweet-alert-body">' +
          '<ul class="textbox">' +
          '<li>솔루션에서만 주문이 삭제됩니다.<br>또한, G마켓 물류서비스에 등록/요청된 발송정보는 삭제/취소되지 않습니다.</li>' +
          '<li>쇼핑몰의 주문이 배송 전 상태일 경우 \'주문수집\' 작업 시 재수집됩니다.</li>' +
          '</ul>'
          + '</div>', async function () {
          try {
            await shipmentModel.delete({ uniqList: param });
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 삭제에 성공하였습니다'));
            $scope.searchDo(true);
          } catch (error) {
            commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('주문 삭제에 실패하였습니다'));
          }
        });
      };

      /**
       * 주문복사
       * */
      $scope.copyOrder = function () {
        const selectList = $scope.grid.methods.selectedData('all');

        if (selectList.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          selectList: selectList,
          ebaydepot_yn: true
        };

        const modal = commonSVC.openModal('', { data: params }, 'OrderShipmentOrderCopy', 'views/order/shipment/modals/order_copy.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 합포장
       */
      $scope.modifyBundle = function () {
        const selectList = $scope.grid.methods.selectedData('all');

        const selectBundle = _.uniqBy(_.map(selectList, 'bundle_no'));

        if (selectBundle.length < 2) {
          commonSVC.showMessage('주문 합포장 실패', '두개 이상의 주문을 선택해주세요.');

          return false;
        }

        const findNone = $scope.grid.methods.selectedData('ebaydepot_link_status').find(ebaydepot_link_status => BUNDLE_ACTION_NOT_ALLOWED_STATUS.includes(ebaydepot_link_status));

        if (findNone) {
          if (['발송정보 수정대기', '발송요청 대기'].includes(findNone)) {
            commonSVC.showMessageHtml('안내', '<div style="font-size: 15px;">발송정보 전송이 완료된 주문은 이중 출고를 최소화하기 위해 묶음 기준 변경이 제한됩니다. <br><br>&nbsp;&nbsp;&nbsp; 연동상태: 발송정보 수정대기, 발송요청 대기인 주문 <br><br>주문 분할 또는 합포장 작업이 필요하신 경우<br>[발송정보 삭제] 작업으로 전송된 데이터를 삭제 하신 후 <br>[발송정보 전송/수정] 작업으로 재등록 해주세요.</div>');

            return false;
          } else {
            commonSVC.showMessage('주문 합포장 실패', `${findNone} 주문은 합포장이 불가합니다.`);

            return false;
          }
        }

        const selectDonotBundle = _.filter(selectList, { ebaydepot_link_status: '발송요청 완료' });

        if (selectDonotBundle.length) {
          commonSVC.showMessage('주문 합포장 실패', '발송요청 완료 주문은 합포장이 불가합니다.');

          return false;
        }

        if (selectList.filter(o => !o.bundle_avail_yn).length) {
          commonSVC.showMessage('주문 합포장 실패', '합포장이 불가능한 SKU상품이 매칭된 주문이 존재합니다.');

          return false;
        }

        const params = {
          uniqList: _.map(selectList, 'uniq'),
        };

        const grouping = _.groupBy(selectList, function (o) {
          return o.to_name + o.to_zipcd + o.to_addr1 + o.to_htel;
        });

        commonSVC.showConfirmHtml('주문을 합포장 하시겠습니까?', '주문에 적용된 사은품 규칙, 사용자태그가 삭제 후 재적용됩니다. <br><br>합포장은 연동상태가 [발송정보 미등록, 발송정보 전송실패]인 경우에만 가능합니다.', function () {
          if (_.size(grouping) === 1) {
            // 합포장 시 수령지 정보 같은 경우 수령지 정보 선택은 미출력되어야함.
            params.standardData = selectList[0].uniq;

            shipmentModel.makeBundle(params, function (state, result) {
              if (state == 'success') {
                commonSVC.showToaster('success', gettextCatalog.getString('배송 관리'), gettextCatalog.getString('주문 합포장 성공'));
                $scope.searchDo(false);
              } else {
                commonSVC.showToaster('error', '', result);
              }
            });
          } else {
            const modal = commonSVC.openModal('', { data: { ...params, selectList: selectList } }, 'makeBundleOrderCtrl', 'views/order/shipment/modals/make_bundle_order.html');

            modal.result.then(function (re) {
              if (re === 'success') {
                $scope.searchDo(false);
              }
            });
          }
        });
      };

      /**
       * 주문분리
       * */
      $scope.divideBundle = function (bundle_no, uniq) {
        const divideList = [];

        if (!bundle_no) { //주문 분할 버튼 눌렀을 때
          const selectList = $scope.grid.methods.selectedData('all');

          if (selectList.length < 1) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

            return false;
          }

          const findNone = $scope.grid.methods.selectedData('ebaydepot_link_status').find(ebaydepot_link_status => BUNDLE_ACTION_NOT_ALLOWED_STATUS.includes(ebaydepot_link_status));

          if (findNone) {
            if (['발송정보 수정대기', '발송요청 대기'].includes(findNone)) {
              commonSVC.showMessageHtml('안내', '<div style="font-size: 15px;">발송정보 전송이 완료된 주문은 이중 출고를 최소화하기 위해 묶음 기준 변경이 제한됩니다. <br><br>&nbsp;&nbsp;&nbsp; 연동상태: 발송정보 수정대기, 발송요청 대기인 주문 <br><br>주문 분할 또는 합포장 작업이 필요하신 경우<br>[발송정보 삭제] 작업으로 전송된 데이터를 삭제 하신 후 <br>[발송정보 전송/수정] 작업으로 재등록 해주세요.</div>');

              return false;
            } else {
              commonSVC.showMessage('주문 분할 실패', `${findNone} 주문은 주문 분할이 불가합니다.`);

              return false;
            }
          }

          _.each(selectList, function (order, idx) {
            if (bundle_group[order.bundle_no].length > 1) {
              divideList.push([{ bundle_no: order.bundle_no, uniq: order.uniq, ebaydepot_link_status: order.ebaydepot_link_status, ...!idx && { main: true } }]);
            }
          });
        } else { //(개별)분할 아이콘 눌를때
          if (bundle_group[bundle_no].length === 2) { // 합이 2개면 둘다 분할 처리해야하니까
            _.each(bundle_group[bundle_no], function (v) {
              divideList.push([{ bundle_no: bundle_no, uniq: v.uniq, ebaydepot_link_status: v.ebaydepot_link_status }]);
            });
          } else {
            const ord = _.find(bundle_group[bundle_no], { uniq: uniq });

            divideList.push([{ bundle_no: bundle_no, uniq: uniq, ebaydepot_link_status: ord.ebaydepot_link_status }]);
          }
        }

        if (!divideList.length) {
          commonSVC.showMessage('주문 분할', '묶음 해제 할 묶음 주문건이 없습니다');

          return false;
        }

        if (_.filter(divideList.flat(), { ebaydepot_link_status: '발송요청 완료' }).length) {
          commonSVC.showMessage('주문 분할 실패', '발송요청 완료 주문은 주문 분할이 불가합니다.');

          return false;
        }

        commonSVC.showConfirmHtml('주문을 분할 하시겠습니까?', `선택한 ${divideList.length}건의 주문을 현재 묶음 배송 주문에서 개별 배송 주문으로 분리합니다. <br> - 주문에 적용된 사은품 규칙이 삭제됩니다. <br>&nbsp;&nbsp;&nbsp;주문 분할/합포장 처리 후 사은품 규칙을 재적용 해주세요. <br> - 주문 분할 시 묶음번호가 변경되니 이중 출고에 유의해 주세요. <br><br>주문 분할은 연동상태가 [발송정보 미등록, 발송정보 전송실패]인 경우에만 가능합니다.`, async function () {
          const send_data = {
            divideList: divideList
          };

          shipmentModel.divideBundle(send_data, function (state, data) {
            if (state === 'success') {
              commonSVC.showToaster('success', '', '합포장 분할해제에 성공하였습니다.');
              $scope.searchDo();
            } else {
              commonSVC.showToaster('error', '', `합포장 분할해제에 실패 했습니다.\n${data.data?.message || ''}`);
            }
          });
        });
      };

      /**
       * 주문 선택 분할
       */
      $scope.divideBundleSelect = () => {
        const selectList = $scope.grid.methods.selectedData('all');

        const findNone = $scope.grid.methods.selectedData('ebaydepot_link_status').find(ebaydepot_link_status => BUNDLE_ACTION_NOT_ALLOWED_STATUS.includes(ebaydepot_link_status));

        if (findNone) {
          if (['발송정보 수정대기', '발송요청 대기'].includes(findNone)) {
            commonSVC.showMessageHtml('안내', '<div style="font-size: 15px;">발송정보 전송이 완료된 주문은 이중 출고를 최소화하기 위해 묶음 기준 변경이 제한됩니다. <br><br>&nbsp;&nbsp;&nbsp; 연동상태: 발송정보 수정대기, 발송요청 대기인 주문 <br><br>주문 분할 또는 합포장 작업이 필요하신 경우<br>[발송정보 삭제] 작업으로 전송된 데이터를 삭제 하신 후 <br>[발송정보 전송/수정] 작업으로 재등록 해주세요.</div>');

            return false;
          } else {
            commonSVC.showMessage('주문 분할 실패', `${findNone} 주문은 주문 분할이 불가합니다.`);

            return false;
          }
        }

        if (selectList.length < 1) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        if (_.uniqBy(selectList, 'bundle_no').length === selectList.length) {
          commonSVC.showMessage(gettextCatalog.getString('주문 선택분할'), '묶음 분할 할 묶음 주문 건이 없습니다.');

          return false;
        }

        if (_.uniqBy(selectList, 'bundle_no').length > 1) {
          commonSVC.showMessage(gettextCatalog.getString('주문 선택분할'), '묶음 주문 1건만 선택해 주세요.');

          return false;
        }

        if (_.filter(selectList, { ebaydepot_link_status: '발송요청 완료' }).length) {
          commonSVC.showMessage('주문 분할 실패', '발송요청 완료 주문은 주문 분할이 불가합니다.');

          return false;
        }
        const resolve = {
          data: {
            ordList: selectList
          }
        };

        const modal = commonSVC.openModal('lg', resolve, 'DivideBundleCtrl', 'views/order/shipment/modals/divide_bundle_select.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(false);
          }
        });
      };

      /**
       * 엑셀 다운 모달창
       * */
      $scope.excelDown = function (type) {

        // 엑셀권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('shipping.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const resolve = {};

        resolve.data = {
          type: type,
          excelFieldList: $scope.excelFieldList,
          visibleTable: $scope.grid.methods.getColumnsVisible(), // 활성화된 필드 데이터
          title: '주문',
          url: '/app/order/excelDown',
          searchForm: $scope.pageData,
          page: 'order',
          ebaydepotYn: true
        };

        if (type === 'all') {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
        } else {
          const uniqList = $scope.grid.methods.selectedData('uniq');

          if (uniqList.length === 0) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

            return false;
          } else {
            resolve.data.uniq = uniqList;
            resolve.data.count = uniqList.length;
            resolve.data.isAll = false;
          }
        }

        const modal = commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            // 엑셀 다운로드 후 새로고침 안함 (https://playautogmpproject.atlassian.net/browse/GMPKR-5462)
            // 2018-10-24 rony
            // $scope.searchDo(false);
          }
        });
      };

      /**
       * 주문 전체보기에서 주문코드 눌렀을 때
       */
      $scope.$on('integratedSearch_order', function () {
        $scope.searchDo(true, true);
      });

      /**
       * 사이드바 집계 클릭시
       */
      $scope.$on('sidebarSearch_order', function () {
        $scope.searchDo(true, true);
      });

      /**
       * 상품코드 클릭시 쇼핑몰 상품 관리로 이동
       */
      $scope.goOlProdList = (shop_sale_no) => {
        $rootScope.order_search.page = 'online';
        $rootScope.order_search = {
          page: 'online',
          search: true,
          search_key: 'shop_sale_no',
          search_word: shop_sale_no
        };

        $state.go('main.online_product_list');
        $rootScope.$broadcast('ordListSearch');
      };

      /**
       * 주문 요약정보 열기
       */
      // $scope.$on('openOrderInfoDetail', function (event, vdata) {
      //   // 주문 요약정보 매칭 정보
      //   _.forEach($scope.orderList, function (order) {
      //     order.sku_pack = prodList[order.uniq][0].sku_cd;
      //     order.prod_name_pack = prodList[order.uniq][0].prod_name;
      //   });

      //   vdata.row.child($compile('<detail-preview number=' + vdata.row[0][0] + ' type="order"></detail-preview>')($scope)).show();
      // });

      /**
       * 테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function (evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_ebaydepot_list') {
          const $wrapper = $('#order_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      // SKU상품 매칭 후 신규주문 메뉴 새로고침 필요로 추가
      $scope.$on('$reloadTableForMappingResultGlobal', function () {
        $scope.searchDo(true);
      });

      // 2018-01-11 chris 등록 성공시 리스트 새로고침 추가
      $scope.$on('reloadShipmentOrderList', function () {
        $scope.searchDo(true);
      });

      // SMS, EMAIL 모달
      $scope.sendModal = async (type) => {
        let controller = '';

        switch (type) {
          case 'sms':
            controller = 'ConfigsSms';
            break;

          case 'email':
            controller = 'ConfigsEmail';
            break;
        }

        const rows = $scope.grid.methods.selectedData('all');
        const t = angular.copy($scope.pageData);

        delete t.start;
        t.length = 1000000;

        const totalRows = (await shipmentModel.ebaydepotListSms(t)).data.results;

        const resolve = {
          data: {
            rows: rows,
            totalRows: totalRows,
            totalCount: $scope.searchData.totalCount,
            searchForm: $scope.pageData,
            page: 'ebayDepot'
          }
        };

        commonSVC.openModal('lg', resolve, controller, `views/alimtalk/modals/${type}.html`);
      };

      /**
       * 주문 상태 강제변경
       * */
      $scope.userChangeStatus = function (status) {
        if (status === '취소완료') {
          //유니코드로 마지막 글자의 받침 여부 확인
          const isConsonant = (status.charCodeAt(status.length - 1) - 44032) % 28;

          commonSVC.showConfirm('상태를 변경하시겠습니까?',
            `SKU상품이 매칭된 주문은 [취소완료]로 변경 시 차감 되었던 재고 수량이 복구되며, 주문 동기화 시 쇼핑몰의 상태와 더이상 동기화 되지 않습니다.\n${status}${isConsonant ? '으로' : '로'} 상태를 강제로 변경 하시겠습니까?`, function (re) {
              if (re) {
                shipmentModel.setStatus({ status: status, uniqList: $scope.grid.methods.selectedData('uniq') }, function (state, res) {
                  if (state == 'success' && res === 'success') {
                    commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 상태변경에 성공하였습니다'));
                    $scope.searchDo(false);
                  } else {
                    commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('주문 상태변경에 실패하였습니다'));
                  }
                });
              }
            });
        }
        else {
          //유니코드로 마지막 글자의 받침 여부 확인
          const isConsonant = (status.charCodeAt(status.length - 1) - 44032) % 28;

          let confirmMsg = `${status}${isConsonant ? '으로' : '로'} 상태를 강제로 변경 하시겠습니까?`;
          let customStatus = false;

          if (status === '신규주문') {
            const data = $scope.grid.methods.selectedData('all');

            const checkAction = data.filter((row) => {
              return ($rootScope.user_profile.sol_no === 16044 || $rootScope.user_profile.sol_no === 596)
                && _.includes(['취소요청', '취소완료'], row.ord_status)
                && row.ebaydepot_link_status === '발송정보 미등록';
            });

            if (checkAction.length) {
              confirmMsg = `
                취소요청 또는 취소완료 상태의 주문을 신규주문 상태로 변경합니다. <br />
                <span style="font-weight: bold;">묶음 주문은 모든 상품의 상태가 일괄 변경됩니다.</span> <br />
                주문상태를 [신규주문]으로 변경 하시겠습니까? <br /> <br />
                <span style="color: red;">
                  * 주의 : 쇼핑몰 상에서 취소 상태로 조회되는 경우 주문 상태 동기화 시 다시 취소 상태로 변경될 수 있습니다. <br />
                    수동으로 상태 변경 후 ESM+ 로 중복 전송되지 않도록 유의해주세요. <br />
                    이로 인한 이중 출고는 플토에서 책임지지 않습니다.
                </span>
              `;
              customStatus = true;
            }
          }

          commonSVC.showConfirmHtml('상태를 변경하시겠습니까?', confirmMsg, function (re) {
            if (re) {
              shipmentModel.setStatus({ status: status, uniqList: $scope.grid.methods.selectedData('uniq'), customStatus }, function (state, res) {
                if (state == 'success' && res === 'success') {
                  commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('주문 상태변경에 성공하였습니다'));
                  $scope.searchDo(false);
                } else {
                  commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('주문 상태변경에 실패하였습니다'));
                }
              });
            }
          });
        }
      };

      $scope.checkSendRequest = async function (account) {
        const payload = {
          company_id: userInfo.user.sol_no,
          company_type: 'GMP',
          job_data: {
            account: {
              seller_id: account.ebaydepot_id,
              seller_pw: account.ebaydepot_pwd
            }
          },
          req_id: `com.esmplus${Date.now()}`,
          req_code: 'esmplus.com',
          req_action: 'scrap.send_info',
          proc_opts: {
            debug_mode: true,
            slowMo: 0,
            use_hover: false,
            trace: false,
            force_require: false,
            use_private_user_dir: false,
            dumpio: true,
            block_images: true
          }
        };

        const result = await commonModel.pa4(payload, false);

        if (result && result.data && result.data.data) {
          if (result.data.data.length) {
            $scope.searchForm.multi_type = 'bundle_no';
            $scope.searchForm.multi_search_word = _.map(result.data.data, 'OuterCartNo').join('\r\n');
            $scope.searchDo(false);
          } else {
            commonSVC.showMessage('실패', '조회된 묶음번호가 존재하지 않습니다.');
          }
        } else {
          commonSVC.showMessage('실패', '조회 실패');
        }
      };

      $scope.$on('OnSelectChangeBefore', function (event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-04-27 MatthewKim todo. 이거는 좀더 빠르게 바꿀 필요가 있음
        // 2017-05-10 MatthewKim $timeout 이용하지 않고 다이렉트로 처리함 (속도 개선)
        // 2017-05-10 MatthewKim 선택 발생시 타겟 전체를 클래스로 받아 빠르게 처리함
        $(data.targetClasses).addClass('selected');
      });

      /**
       * 테이블에서 선택해제시
       */
      $scope.$on('OnDeSelected', function (event, data) {
        // 2017-04-14 chris 합포장 주문건인경우 동일하 묶음번호인 주문건 모두 selected 처리
        // 2017-05-10 MatthewKim $timeout 제거 하고 처리 위치 제일 먼저 위로 올림
        // 2017-05-10 MatthewKim 선택 해제 발생시 타겟 전체를 클래스로 받아 처리함
        $(data.targetClasses).removeClass('selected');
      });

      // 주문 갯수 카운팅 세팅
      function countList(data) {
        const countList = [
          {
            status: '전체',
            count: 0
          }
        ];

        _.each($scope.ord_ebaydepot_link_status, function(v) {
          const status = v.status_name;
          const count = data[status] ? data[status][0].cnt : 0;

          countList.push({
            status: status,
            count: count
          });

          countList[0].count = countList[0].count + count;
        });

        $scope.countList = countList;
      }

      // 리스트와 별개로 초기에 세팅되어야하는 값
      function init() {
        $scope.selectRowUniqList = [];

        // 2018-04-06 Daniel
        // 재고관리제외버전 추가
        // stockExceptionSVC.scopeExcept(_.last($state.current.url.split('/')), $scope);
      }

      /**
       * G마켓물류 상태변경
       */
      const updateEbaydepotStatus = async (status, worker) => {

        let showMsg = worker === 'USER' ? `발송 요청이 완료된 주문을 발송정보 미등록 상태로 변경합니다.<br/>
묶음 주문은 모든 상품의 상태가 일괄 변경됩니다.<br/><br/>

주문상태를 [신규주문]으로 변경 하고,<br/>
연동상태를 [발송정보 미등록] 상태로 변경하시겠습니까?<br/><br/>

<font style="color:red; font-weight:bold;">
※ 주의 : 반드시 ESM+ 에서 판매취소를 완료하신 후 상태를 변경해 주세요.<br/>
          ESM+에서 판매취소 하지 않은 채로 상태 변경 후 재전송 시 주문은 중복으로 등록됩니다.<br/>
          이로 인한 이중 출고는 플토에서 책임지지 않습니다.
</font>` : `변경할 상태 -> <b>[${status}]</b>`;

        if (status === '배송완료,발송 완료') {
          showMsg = `변경할 상태 -> <b>[${status}]</b>`;
        }

        const confirm = await commonSVC.showConfirmHtml('연동상태를 변경하시겠습니까?', showMsg);

        if (confirm) {
          try {
            const re = await shipmentModel.updateEbaydepotStatus({
              status,
              uniqList: $scope.grid.methods.selectedData('uniq'),
              worker
            });

            if (re.data.result === 'success') {
              commonSVC.showToaster('success', '성공', '연동상태 변경에 성공하였습니다');
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', '실패', '연동상태 변경에 실패하였습니다');
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '연동상태 변경에 실패하였습니다');
          }
        }
      };

      init();

      // 메모 첫 변경시 버튼 색상 변경
      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

    });

