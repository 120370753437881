'use strict';

angular.module('gmpApp')
  .controller('FirstShopRegistCtrl', function ($scope, $rootScope, $state, $timeout, $uibModalInstance, commonSVC, userInfo, systemModel, shopAccountModel, localStorageService, settings) {
    $scope.allShops = []; // 쇼핑몰 리스트 전체

    $scope.shops = []; // 모달에 노출시켜줄 쇼핑몰 리스트
    $scope.selectedShops = userInfo.user.first_add_shop_cds?.split(',').filter(Boolean) || []; // 선택된 쇼핑몰
    $scope.showAllShopYn = !$scope.selectedShops.length;

    async function init() {
      try {
        const re = await shopAccountModel.shopAccountNewAddList({ sol_type: userInfo.user.sol_type, from: 'firstAddShop' });

        if (re.status === 200) {
          $scope.allShops = re.data.results.filter(shop => (
            (['TEST', 'QA'].includes(userInfo.user.sol_sub_type) || shop.sol_type.includes('GMP') || shop.shop_type === 'ORD_M')
              && (
                !shop.pa_shop_cd.startsWith('Z') && !['X095', 'X097', 'X098', 'X099'].includes(shop.pa_shop_cd)
              )
          ));
          $scope.shops = $scope.showAllShopYn ? $scope.allShops.slice(0, 16) : $scope.allShops.filter(({ shop_cd }) => $scope.selectedShops.includes(shop_cd));

          // 각 쇼핑몰 별 등록여부 체크
          for (const shop of $scope.allShops) {
            shop.complete = $rootScope.use_channel_list.some(({ pa_shop_cd }) => pa_shop_cd === shop.pa_shop_cd);
            shop.selected = $scope.selectedShops.includes(shop.pa_shop_cd);
          }

          $timeout(() => {});
        } else {
          commonSVC.showToaster('error', '쇼핑몰 리스트를 가져오는데 실패했습니다.');
        }
      } catch (err) {
        commonSVC.showToaster('error', '쇼핑몰 리스트를 가져오는데 실패했습니다.');
      }
    }

    /**
     * 쇼핑몰 선택
     */
    $scope.selectShop = shop => {
      // 재접속해서 일부 쇼핑몰만 보여주는 경우 체크해제 불가
      if (!$scope.showAllShopYn) {
        return;
      }

      shop.selected = !shop.selected;
    };

    /**
     * 모달 노출시킬 쇼핑몰 리스트 추가
     */
    $scope.loadMore = () => {
      $scope.shops = $scope.allShops;
    };

    /**
     * 선택완료
     */
    $scope.done = async () => {
      let isRegistAllDone = false; // 선택한 쇼핑몰 모두 계정등록 완료 여부
      $scope.selectedShops = $scope.allShops.filter(shop => shop.selected).map(shop => shop.pa_shop_cd);

      if (!$scope.selectedShops.length) {
        commonSVC.showMessage('쇼핑몰을 1개 이상 선택해주세요.');

        return;
      }

      // 계정등록 완료된 쇼핑몰만 선택되었는지 체크
      if ($scope.allShops.filter(shop => shop.selected).every(shop => shop.complete)) {
        isRegistAllDone = true;
      }

      try {
        const re = await systemModel.editFirstAddShop({ shop_cds: $scope.selectedShops });

        if (re.status === 200) {
          userInfo.user.first_add_shop_cds = $scope.selectedShops.join();
          if (!isRegistAllDone) {
            // 쇼핑몰계정관리 탭이 열려있으면 컨트롤러가 실행되지 않아서 강제로 닫기
            $rootScope.$emit('$closeTab', 'tab_settings_shop_account');

            $timeout(() => {
              $state.go('main.settings_shop_account', { pageFrom: 'firstModal' });
              // 탭이 열려있으면 컨트롤러가 실행되지 않아서 강제로 reload
              $state.reload();
            }, 100);

            $uibModalInstance.close();
          } else {
            $uibModalInstance.dismiss('cancel');
            await commonSVC.showMessage('계정 등록을 모두 완료한 쇼핑몰 입니다.');
          }
        } else {
          commonSVC.showToaster('error', '선택 쇼핑몰 저장에 실패했습니다.');
        }
      } catch (err) {
        commonSVC.showToaster('error', '선택 쇼핑몰 저장에 실패했습니다.');
      }
    };

    /**
     * 전체 쇼핑몰 노출
     */
    $scope.showAllShops = () => {
      $scope.showAllShopYn = true;
      $scope.shops = $scope.allShops;
    };

    /**
     * 창 닫기
     */
    $scope.close = () => {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * 쇼핑몰 입점 홈페이지 이동
     */
    $scope.openHomePageEntry = function() {
      const sendInfo = localStorageService.get('sendInfo');
      const info = atob(decodeURIComponent(sendInfo));
      const url = `${settings.wwwUrl}/member/AdminLogin/?info=${encodeURIComponent(info)}&ret_url=entry/EntryStore`;

      window.open(url);
    };

    init();
  });
