'use strict';

angular.module('gmpApp')

  .controller('OnlineProductEditCtrl', function ($uibModalInstance, $rootScope, $scope, onlineProductSVC, productSVC, errorSVC, $q,
    categoryModel, commonModel, commonSVC, data, madeinList, systemList, templateModel, shopAccountModel, productModel, onlineProductModel,
    prodDetail, siteInfo, $timeout, $compile, categorySVC, localStorageService, userInfo, settings) {
    $scope.ol_shop_no = data.ol_shop_no;
    $scope.sol_stock_auth = userInfo.user.sol_stock > 0 && (userInfo.user.auth_type === '총괄관리자' || userInfo.permission?.stock?.use_yn);
    $scope.madeinList = madeinList.data.result || [];                  // 원산지 리스트
    $scope.madein_group_list = [];                                         // 원산지 그룹
    $scope.madein_name_list = [];                                          // 원산지 지역
    $scope.detailType = 'edit';
    $scope.warehouseList = data.warehouseList || [];          // 배송처 리스트
    $scope.prod_auto_set_yn = systemList.data.prod_auto_set_yn == 'Y' ? 1 : 0; // SKU상품 대표 자동세팅 여부
    $scope.shop_id_view_type = systemList.data.shop_id_view_type;

    // G마켓물류 배송처
    $scope.ebayDepots = $scope.warehouseList.filter(o => o.ebaydepot_yn == 1).map(o => o.code) || [];

    // 알리 쇼핑몰 작업용 분기 타입
    const ali_type = prodDetail.data.results.pa_shop_cd === 'A093' ? 'express' : prodDetail.data.results.pa_shop_cd === 'A092' ? 'baba' : 'express_global';

    //esm 옵션
    $scope.attr1 = false;
    $scope.attr2 = false;
    $scope.attr3 = false;
    //ESM 옵션 정보 로딩 완료 여부
    $scope.ESM_loaded = true;
    // 알리 쇼핑몰 옵션 정보 로딩 완료 여부
    $scope.Aliexpress_loaded = true;
    $scope.Alibaba_loaded = true;
    $scope.NaverSingle_loaded = true;
    $scope.Aliexpress_Global_loaded = true;
    $scope.data = {};
    $scope.pattern = {
      validateModelBrand: '[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\\s`~!@#$%^&*()_+-=[\\]\\{}|:;\'",.<>/?\\\\]{0,40}',
      validateMaker: '[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\\s`~!@#$%^&*㈜()_+-=[\\]\\{}|:;\'",.<>/?\\\\]{0,40}',
      validatefiftyChar: '[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\\s`~!@#$%^&*()_+-=[\\]\\{}|:;\'",.<>/?\\\\]{0,50}'
    };
    $scope.OPT_LIST_LIMIT = 100;   // 더보기 버튼 출력 제한 개수 변수(더보기 가능한 숫자 계산에도 사용)
    $scope.initOptListCnt = 1;   // 더보기 횟수 초기화 변수
    $scope.selectOptList = []; // esm 추천옵션 리스트

    // 에픽카 전용
    $scope.efcCheck = {
      oemChecked: false, // OEM 부품번호 비활성화 여부
      modelChecked: false, // 차대번호 비활성화 여부
      text: $rootScope.affName === '에픽카' // 에픽카 전용 문구 출력 여부
    };

    // 카테고리번호 원본값
    const oriCateInfo = {
      shop_cate_no: 0,
      sol_shop_cate_no: 0,
    };

    $scope.checked = {
      addSingleProd: false,
      esm_opt_size: false, //ESM2.0 옵션 사용 여부
      esm_opt_color: false, //ESM2.0 옵션 사용 여부
      opt_size_flag: true, //ESM2.0 카테고리 변경 후 옵션 지원 여부
      opt_color_flag: true, //ESM2.0 카테고리 변경 후 옵션 지원 여부
      batchType: prodDetail.data.results.std_ol_yn === 2 ? 'add_price' : 'sale_cnt'
    };

    $scope.disabled = {
      esm_opt_size: false,
      esm_opt_color: false,
      esm_opt: false,
      aliexpress_opt: false,
      aliexpress_opt_not_color1: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      aliexpress_opt_not_color2: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      aliexpress_opt_not_color3: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      alibaba_opt: false,
      alibaba_opt_not_color1: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      alibaba_opt_not_color2: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      alibaba_opt_not_color3: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      aliexpress_global_opt: false,
      aliexpress_global_opt_not_color1: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      aliexpress_global_opt_not_color2: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
      aliexpress_global_opt_not_color3: ['A092', 'A093', 'X094'].includes(prodDetail.data.results.pa_shop_cd),
    };

    // 체크 플래그
    $scope.flag = {
      esmMatch: false,     // ESM 상품등록 매칭 전체선택
      esmAdd: false,       // ESM 상품등록 신규 전체선택
      esmOpt: false,       // ESM 옵션 테이블 전체선택
      addOpt: false,       // 추가구매 옵션
      addOptTable: false,  // 추가구매 옵션 테이블
      isOpen: localStorageService.get('optOpen') || 'true',       // 등록 상품 선택 열기 닫기
      isAddOptOpen: localStorageService.get('addOptOpen') || 'true'  // 추가구매 옵션 테이블 열기 닫기
    };

    $scope.prodOpt = {
      name: '' // 상품명
      , promotion: '' // 홍보문구
      , matchStockList: [{ prod_img: '/assets/images/upload.png', coupang: { sku_cd: '', prod_status: '정상' }, rocket: { cnt_limit: 0, sku_cd: '', prod_status: '정상' } }] // 매칭된 SKU상품 리스트
      , addStockList: [{ skuAuto: true, skuCheck: false, sku_cd: '' }] // 신규생성 SKU상품 리스트
      , opt: angular.copy(onlineProductSVC.default_opt)
      , options_table: [] // 옵션 생성 테이블
      , cnt_limit_all: ''
      , esm_opt: []
      , esm_checked_opt: []
      , add_opt: [{}]
      , add_opt_table: []
      , aliexpress_opt: []
      , aliexpress_checked_opt: []
      , alibaba_opt: []
      , alibaba_checked_opt: []
      , aliexpress_global_opt: []
      , aliexpress_global_checked_opt: []
    };

    $scope.optNames = {
      option_name1: '',
      option_name2: '',
      option_name3: '',
      edit_name1: false,
      edit_name2: false,
      edit_name3: false,
    };

    // 기본 노출 탭
    $scope.tabActive = prodDetail.data.results.shop_cd === 'Z000' ? 2 : 1;

    // ESM 물류 상품인경우 쇼핑몰판매수량 99999 로 고정
    if (prodDetail.data.results.std_ol_yn === 2) {
      prodDetail.data.results.sale_cnt_limit = 99999;
    }

    // ESM 마스터 상품 옥션은 일부 수정 불가 (지마켓 수정 시 옥션에 반영됨)
    $scope.esmMasterFlag = !!(['A001', 'A006'].includes(prodDetail.data.results.shop_cd) && prodDetail.data.results.ebay_shop_mas_no); // ESM 마스터 상품 여부

    // 저장 버튼 활성화 여부
    $scope.submitDisabled = false;

    //카테고리 선택모달창 유무
    $scope.categoryListModal = false;
    //선택한 카테고리 이름
    $scope.categoryName = '';

    $scope.stock = {};

    // 대표상품 카테고리정보
    $scope.category_name = '';
    // $scope.category_code = "";

    //오픈되어있는 템플릿 정보
    $scope.openTemplate = '';
    //템플릿 변수
    $scope.templateCode = $scope.templateName = '';
    $scope.templateListModal = false; //템플릿 선택
    $scope.template_summary = true;  //템플릿 요약정보 출력
    $scope.templateTemp = []; // 템플릿 임시저장소

    // 상품정보고시 정보
    let temp_list_new = [];

    // 옵션 리스트 인덱싱
    let currentRow;

    // 기존 옵션값 저장용
    let ori_opt_type;

    // 카테고리 옵션 변경여부
    $scope.isChange = false;

    // 엘지 커스텀용 모델번호 노출 여부
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));
    $scope.leadProdInfoModelno = {};
    $scope.modelProdMatch = {
      checked: false
    };

    //안전인증유형
    $scope.safeCertifyList = onlineProductSVC.safeCertifyList;

    //면제유형 2018-07-13 alvin
    $scope.ExemptionList = angular.copy(onlineProductSVC.ExemptionList);

    // 인증유형 데이터 초기화
    $scope.certDataList = [];
    $scope.certDataList.push({
      cert_cd: '',       //인증유형
      cert_exc_type: '',  //면제유형
      cert_ministry: '',      //인증기관
      cert_no: '',            //인증번호
      cert_model: '',         //인증모델
      cert_cname: '',         //인증상호
      cert_date: '',          //인증일
      cert_expire_date: '',   //만료일
      dateChk: false     // 인증일 체크
    });

    //카테고리 검색
    $scope.categorySearchKey = '';

    let inValidTab = [true, true, true, true, true];

    //인증정보 로드
    onlineProductModel.getCert(prodDetail.data.results.ol_shop_no, function(state, data) {
      if (state == 'success') {
        $scope.certDataList = data.results.length > 0 ? data.results : $scope.certDataList;
        // 2018-08-08 Alvin
        // 인증일이나 인증만료일은 저장을 하지않으면 0000-00-00 형식으로 들어가서 date picker 이 이상하게 보임 그래서 0000-00-00 일때는 빈 문자열 넣어줌
        angular.forEach($scope.certDataList, function(certData, key) {
          $scope.certDataList[key].cert_date = certData.cert_date == '0000-00-00' ? '' : certData.cert_date;
          $scope.certDataList[key].cert_expire_date = certData.cert_expire_date == '0000-00-00' ? '' : certData.cert_expire_date;
        });
      }
    });

    //추가항목 안전인증유형형
    $scope.certlist = [];

    // directive(form) 용 추가 2018-03-13 rony
    $scope.reCheckClass = {};

    // 기타상세설명
    $scope.detailDescEtc = angular.copy(onlineProductSVC.detailDescEtc);

    // SKU상품 검색 폼 초기화. 2017-10-18 서상권
    const searchForm = {
      start: '0',
      length: '99999',
      orderby: 'wdate desc',
      date_type: 'wdate',
      search_key: 'all',
      search_word: '',
      supplier_vendor: 'all',
      delivery_vendor: 'all',
      brand_code: '',
      category: 'all',
      linkage_flag: 'all',
      tax_type: 'all'
    };

    $scope.searchForm = angular.copy(searchForm);

    $scope.expirationMonths = _.range(1, productSVC.expirationMonth); //유통기한 개월수 리스트(1~50)

    $scope.promotion_name = '';

    /**
     * 지금 날짜 시간 구하기위한 초기화
     */
    $scope.date = new Date();

    const models = ['model', 'brand', 'maker'];
    /**
     * 5대 마켓 필터
     */
    $scope.marketFilterFunction = function() {
      return onlineProductSVC.marketFilterFunction($scope.data.site_list);
    };

    $scope.removeModelItem = function() {
      _.forEach(models, function (type) {
        $scope.data[`search_${type}_yn`] = 0;
      });

      // 모델명, 브랜드, 제조사를 set_info에서 모두 삭제
      delete $scope.data.site_list[0].set_info.key_brand;
      delete $scope.data.site_list[0].set_info.key_brandNo;
      delete $scope.data.site_list[0].set_info.key_brand_Cd;
      delete $scope.data.site_list[0].set_info.key_brand_Nm;
      delete $scope.data.site_list[0].set_info.key_brand_no;
      delete $scope.data.site_list[0].set_info.key_brandCode;
      delete $scope.data.site_list[0].set_info.key_brand_code;
      delete $scope.data.site_list[0].set_info.key_brand_name;
      delete $scope.data.site_list[0].set_info.key_brand_tool_no;

      delete $scope.data.site_list[0].set_info.key_maker;
      delete $scope.data.site_list[0].set_info.key_makerNo;
      delete $scope.data.site_list[0].set_info.key_maker_Cd;
      delete $scope.data.site_list[0].set_info.key_maker_Nm;
      delete $scope.data.site_list[0].set_info.key_mnf_name;
      delete $scope.data.site_list[0].set_info.key_mnf_code;

      delete $scope.data.site_list[0].set_info.modelId;
      delete $scope.data.site_list[0].set_info.modelName;
      delete $scope.data.site_list[0].set_info.brandName;
      delete $scope.data.site_list[0].set_info.manufactureName;
      delete $scope.data.site_list[0].set_info.key_mnf_code;

      $scope.data.site_list[0].set_info.brand = { code: '', name: '' };
      $scope.data.site_list[0].set_info.manufacturer = { code: '', name: '' };
      $scope.data.site_list[0].set_info.model = { code: '', name: '' };

      $scope.data.search_model = angular.copy(onlineProductSVC.models);
      $scope.data.BrandSeq = $scope.data.MakerSeq = $scope.data.ProductBrandSeq = 0;
    };

    //2018-07-19 alvin - 인증유형 추가, 삭제
    $scope.calculateOPT = function (calMode, idx) {
      if (calMode === 'plus') {
        const maxCount = 10; //10개까지 추가 가능

        if ($scope.certDataList.length < maxCount) {
          $scope.certDataList.push({
            cert_cd: '',
            cert_exc_type: '',
            cert_ministry: '',
            cert_no: '',
            cert_model: '',
            cert_cname: '',
            cert_date: '',
            cert_expire_date: '',
            dateChk: false,
          });
        }
      } else {
        $scope.certDataList.splice(idx, 1);
      }
    };

    //2018-07-19 alvin - 조건에 따른 면제유형 비활성화 처리
    $scope.defaultCertCheck = function(idx) {
      const certData = _.find($scope.safeCertifyList, function(o) {
        return o.value == $scope.certDataList[idx].cert_cd;
      });

      const bool = certData && !!((certData.text.indexOf('생활용품') == -1 && certData.text.indexOf('전기용품') == -1));

      $scope.certDataList[idx].cert_exc_type = bool ? '' : $scope.certDataList[idx].cert_exc_type;

      return bool;
    };

    $scope.onChangeLotteOnAttrs = function(row) {
      const attrs = row.attr.split(',');

      if (attrs.length) {
        row.selectList.forEach(item => {
          item.checked = false;

          if (attrs.includes(item.attr_val_nm) === true) {
            item.checked = true;
          }
        });
      } else {
        row.selectList.forEach(item => item.checked = false);
      }
    };

    $scope.optionDisabled = function() {
      if ($scope.esmMasterFlag && $scope.data.shop_cd === 'A001') {
        return true;
      }

      return false;
    };

    // 2018-08-08 Alvin 인증일 인증만료일 설정
    $scope.certDateSet = function(idx) {
      const cert_date = $scope.certDataList[idx].cert_date;
      const cert_expire_date = $scope.certDataList[idx].cert_expire_date;

      if (cert_date && cert_expire_date) {
        $scope.certDataList[idx].dataChk = !!(cert_date > cert_expire_date);
      }
    };

    /**
     * 모델명/브랜드/제조사 검색
     */
    $scope.searchModels = function() {
      let vir_vend_code;
      let pumbun_no;
      let category_code;

      const tmp = $scope.data.shop_cate_no_add_info && typeof $scope.data.shop_cate_no_add_info === 'string' ? JSON.parse($scope.data.shop_cate_no_add_info) : $scope.data.shop_cate_no_add_info;

      // 솔루션카테고리에 매칭된 쇼핑몰 카테고리
      if (tmp && tmp.category_code) {
        category_code = tmp.category_code;
        vir_vend_code = tmp.key_vend_code;
        pumbun_no = tmp.key_pumbun_no;
      } else if ($scope.data.sol_cate_shop_add_info && $scope.data.sol_cate_shop_add_info.category_code) {
        category_code = $scope.data.sol_cate_shop_add_info.category_code;
        vir_vend_code = $scope.data.sol_cate_shop_add_info.key_vend_code;
        pumbun_no = $scope.data.sol_cate_shop_add_info.key_pumbun_no;
      }

      const resolve = {};
      const site_list = [{
        shop_cd: $scope.data.shop_cd,
        pa_shop_cd: $scope.data.pa_shop_cd,
        shop_name: $scope.data.shop_name,
        shop_id: $scope.data.shop_id,
        shop_pwd: $scope.data.shop_pwd,
        etc1: $scope.data.etc1 || '',
        etc2: $scope.data.etc2 || '',
        etc3: $scope.data.etc3 || '',
        etc7: $scope.data.etc7 || '',
        domain: $scope.data.domain,
        cate_info: {
          vir_vend_code,
          pumbun_no,
          category_code
        }
      }];

      resolve.data = {
        site_list: site_list,
        esm: $scope.data.std_ol_yn
      };

      const modal = commonSVC.openModal('lg', resolve, 'modelSearchCtrl', 'views/online/product/modals/models_search.html');

      modal.result.then(function (re) {
        const result = re[0];

        //초기화
        //$scope.data.model = $scope.data.brand = $scope.data.maker = $scope.data.product_brand = '';
        $scope.data.search_model = angular.copy(onlineProductSVC.models);
        $scope.data.search_model.flag = true;

        if ($scope.data.pa_shop_cd === 'B394' && result.brand_key) {
          $scope.data.search_model.product_brand = result.brand;
        } else if (result.ProductBrandName) {
          $scope.data.search_model.product_brand = result.ProductBrandName;
        }

        _.forEach(models, function (type) {
          // type중 몇개는 주지 않는 쇼핑몰이 존재해서 앞의 두개가 전부 undefined 이면 빈 문자열 들어가게 처리 - 2020-01-23 Alvin
          $scope.data.search_model[type] = result[`${type}_value`] || result[type] || '';

          if ($scope.data.search_model[type]) {
            $scope.data[`search_${type}_yn`] = 1;
          }
        });

        const set_info = $scope.data.site_list[0].set_info;

        if ($scope.data.pa_shop_cd == 'A001') {
          if (result.CatalogId) {
            $scope.data.CatalogId = result.CatalogId;
            $scope.data.BrandSeq = 0;
            $scope.data.MakerSeq = 0;
            $scope.data.ProductBrandSeq = 0;
          } else {
            $scope.data.BrandSeq = result.BrandSeq;
            $scope.data.MakerSeq = result.MakerSeq;
            $scope.data.ProductBrandSeq = result.ProductBrandSeq;
          }
          $scope.data.ctlgNo = 'null';
        } else if ($scope.data.pa_shop_cd == 'A006') {
          if (result.ctlgNo) {
            $scope.data.ctlgNo = result.ctlgNo;
            $scope.data.CatalogId = 'null';
          } else {
            $scope.data.BrandSeq = result.BrandSeq;
            $scope.data.MakerSeq = result.MakerSeq;
            $scope.data.ProductBrandSeq = result.ProductBrandSeq;
            $scope.data.brand = result.brand;
            $scope.data.maker = result.maker;
            $scope.data.ProductBrandName = result.ProductBrandName;
          }
        } else if ($scope.data.pa_shop_cd === 'A112') {
          $scope.data.ctlgNo = result.ctlgNo;
        } else if ($scope.data.pa_shop_cd === 'B394') {
          set_info.key_brand_tool_no = result.brand_key;
        } else if ($scope.data.pa_shop_cd == 'A011') {
          set_info.key_brand_code = result.brand_key;
          set_info.key_brand_name = result.brand;

          // GS 브랜드키 수정이벤트
          $rootScope.$broadcast('infoEdit');
        } else if ($scope.data.pa_shop_cd == 'A004') {
          set_info.key_maker_Cd = result.maker_code;
          set_info.key_brand_Cd = result.brand_code;

        } else if ($scope.data.pa_shop_cd == 'B616') {
          set_info.key_brand_name = result.brand;
          set_info.key_brand_code = result.brand_code;
          set_info.key_mnf_name = result.maker;
          set_info.key_mnf_code = result.maker_code;
        } else if ($scope.data.pa_shop_cd == 'B688') {
          set_info.modelId = result.modelId;
          set_info.modelName = result.model;
          set_info.brandName = result.brand;
          set_info.manufactureName = result.maker;
        } else if ($scope.data.pa_shop_cd == 'B691') {
          set_info.key_brand_Nm = result.brand;
          set_info.key_brand_Cd = result.brand_key;
        } else if ($scope.data.pa_shop_cd == 'B603' || $scope.data.pa_shop_cd == 'B614') {
          set_info.key_brand_Nm = result.brand;
          set_info.key_brand_Cd = result.brand_code;
          set_info.key_maker_Nm = result.maker;
          set_info.key_maker_Cd = result.maker_code;
        } else if ($scope.data.pa_shop_cd == 'B719') {
          set_info.key_brand = result.brand || set_info.key_brand || '';
          set_info.key_brandNo = result.brand_code || set_info.key_brandNo || '';
          set_info.key_maker = result.maker || set_info.key_maker || '';
          set_info.key_makerNo = result.maker_code || set_info.key_makerNo || '';
        } else if (['B877', 'B594', 'A064', 'B878', 'B687'].includes($scope.data.pa_shop_cd)) {
          set_info.key_brand = result.brand;
          set_info.key_brandCode = result.brand_code;
        } else if ($scope.data.pa_shop_cd == 'A524') {
          set_info.key_brand = result.brand;
          set_info.key_brand_no = result.brand_key;
        } else if ($scope.data.pa_shop_cd === 'A017') {
          set_info.key_brand = {
            code: result.brand_code,
            name: result.brand,
          };
        } else {
          const { model_search_avail_yn, brand_search_avail_yn, maker_search_avail_yn } = siteInfo[$scope.data.pa_shop_cd];
          // 모델 지원
          if (model_search_avail_yn) {
            set_info.model = {
              code: result.model_code,
              name: result.model
            };
          }
          // 브랜드 지원
          if (brand_search_avail_yn) {
            set_info.brand = {
              code: result.brand_code,
              name: result.brand
            };
          }
          // 제조사
          if (maker_search_avail_yn) {
            set_info.manufacturer = {
              code: result.maker_code,
              name: result.maker
            };
          }
        }
      });
    };

    /**
     * input file clear
     */
    $scope.fileClear = function ($event) {
      const $target = $($event.currentTarget);
      const $input = $target.siblings('input');

      $input.val(null);
    };

    $scope.changeCostPrice = function() {
      $scope.$broadcast('changeCostPrice', { cost: $scope.data.prodall.cost_price });
    };

    /**
     * 템플릿 설정 닫기
     */
    $scope.templateInit = async function() {
      const site = $scope.data.site_list[0];

      //템플릿 데이터 초기화
      $scope.templateList = [];
      $scope.templateLoadingMsg = '로딩중';
      $scope.templateList2 = [];
      $scope.templateListModal = false;

      //템플릿 요약테이블 데이터 가져오기
      const param = {
        shop_cd: site.shop_cd,
        shop_id: site.shop_id,
        bookmark: 'all'
      };

      templateModel.templateOnlineProduct(param)
        .then(function (res) {
          $scope.templateTemp = res.data.results;

          _.each($scope.templateTemp, function (v) {
            if (v.template_no === site.template_no) {

              //요약정보 한번에 담기
              site.summary_data = JSON.parse(v.template_data).data;
              site.site_code = v.shop_cd;
              site.site_id = v.shop_id;
              site.template_no = v.template_no;
            }
          });
        })
        .catch(function(err) {
          commonSVC.showToaster('error', '', err);
        });
    };

    /**
   * 판매수량 공통입력
   */
    $scope.submitCnt = function(arr, type) {
      let flag = false;

      angular.forEach(arr, function(row) {
        if (row.checked) {
          if (type == 'sale_cnt') {

            if ($scope.esmMasterFlag) {
              row.A001_cnt_limit = Number($scope.prodOpt.cnt_limit_all);
              row.A006_cnt_limit = Number($scope.prodOpt.cnt_limit_all);
            } else if ($scope.data.checkGrowth.available) {
              row.coupang.cnt_limit = Number($scope.prodOpt.cnt_limit_all);
            } else {
              row.cnt_limit = Number($scope.prodOpt.cnt_limit_all);
            }
          } else if (type == 'add_price') {
            if ($scope.data.checkGrowth.available) {
              for (const shop of ['coupang', 'rocket']) {
                row[shop].opt_add_price = Number($scope.prodOpt.cnt_limit_all);
                row[shop].opPrice = Number($scope.prodOpt.cnt_limit_all); //추가구매 옵션
              }
            } else {
              row.opt_add_price = Number($scope.prodOpt.cnt_limit_all);
              row.opPrice = Number($scope.prodOpt.cnt_limit_all); //추가구매 옵션
            }

          } else if (type == 'prod_status') {
            if ($scope.prodOpt.cnt_limit_all == '') {
              commonSVC.showMessage('일괄입력 실패', '[정상] 또는 [품절] 중의 하나의 상태를 선택해 주시면 일괄 변경이 가능합니다.');
            } else {
              if ($scope.data.checkGrowth.available) {
                row.coupang.prod_status = $scope.prodOpt.cnt_limit_all;
                row.rocket.prod_status = $scope.prodOpt.cnt_limit_all;
              } else {
                row.prod_status = $scope.prodOpt.cnt_limit_all;

              }
            }
          }
          flag = true;
        }
      });

      if (!flag) {
        commonSVC.showMessage('실패', '일괄입력 하실 옵션 상품을 선택해주세요.');
      }
    };

    /**
     * 바이트, 글자수 체크
     */
    $scope.byteCheck = onlineProductSVC.byteCheck;
    $scope.characterCountCheck = onlineProductSVC.characterCountCheck;
    $scope.preByteCheck = (row) => {
      let str = row.shop_promotion_name;

      if (['A001', 'A006'].includes(row.pa_shop_cd)) {
        str += row.shop_sale_name;
      }

      let return_value = $scope.byteCheck(str);

      if (return_value > row.promotion_name_byte) {
        return_value = row.promotion_name_byte;
      }

      return return_value;
    };

    /**
     * 홍보문구 바이트 검사
     */
    $scope.promotionNameCheck = function() {
      if ($scope.data.std_ol_yn && ($scope.data.pa_shop_cd === 'A001' || $scope.data.pa_shop_cd === 'A006')) {
        // esm2.0 단일상품 합산을 위해 특별처리 상품명 + 프로모션명 100바이트 제한
        if ($scope.byteCheck($scope.data.shop_sale_name) + $scope.byteCheck($scope.data.shop_promotion_name) > 100) {
          const otherStrByte = $scope.byteCheck($scope.data.shop_sale_name);

          $scope.data.shop_promotion_name = commonSVC.cutByByte($scope.data.shop_promotion_name, (100 - otherStrByte));
        }
      } else {
        const { byte: max, str: maxStr } = onlineProductSVC.promotionProductName[`${$scope.data.pa_shop_cd}${$scope.data.std_ol_yn ? '_single' : ''}`];
        // maxStr이 존재할 경우 글자수, 없을 경우 byte로 자름
        if (maxStr) {
          $scope.data.shop_promotion_name = commonSVC.cutByLength($scope.data.shop_promotion_name, maxStr);
        } else {
          if ($scope.byteCheck($scope.data.shop_promotion_name) > max) {
            $scope.data.shop_promotion_name = commonSVC.cutByByte($scope.data.shop_promotion_name, max);
          }
        }
      }

      // 여길 지나면 무조건 제한조건 아래라서 경고알림 켜져있는걸 무조건 끔
      $scope.prod_promotion_check = false;
    };

    /**
     * 온라인상품명 글자수체크
     */
    $scope.onlineProdNameCheck = function() {
      if ($scope.data.std_ol_yn && ($scope.data.pa_shop_cd === 'A001' || $scope.data.pa_shop_cd === 'A006')) {
        // esm2.0 단일상품 합산을 위해 특별처리 상품명 + 프로모션명 100바이트 제한
        if ($scope.byteCheck($scope.data.shop_sale_name) + $scope.byteCheck($scope.data.shop_promotion_name) > 100) {
          $scope.prod_name_check = true;

          return;
        }
      } else {
        const { shop_sale_name_max_length, shop_sale_name_max_length_char_yn } = onlineProductSVC.getSaleNameLimiter($scope.data.pa_shop_cd);
        const maxLength = shop_sale_name_max_length || 100;
        const isUtf8 = onlineProductSVC.utf8ShopCodes.includes($scope.data.pa_shop_cd);

        if (shop_sale_name_max_length_char_yn) {
          if ($scope.characterCountCheck($scope.data.shop_sale_name) > maxLength) {
            $scope.prod_name_check = true;

            return;
          }
        } else {
          if ($scope.byteCheck($scope.data.shop_sale_name, isUtf8) > maxLength) {
            $scope.prod_name_check = true;

            return;
          }
        }
      }

      // 여길 지나면 무조건 제한조건 아래라서 경고알림 켜져있는걸 무조건 끔
      $scope.prod_name_check = false;
    };

    /**
     * 세트상품 매칭 제거
     */
    $scope.delStock = function() {
      const ob = $scope.data.match_opt_type == '매칭' ? $scope.prodOpt.matchStockList : $scope.prodOpt.addStockList;
      let cnt = 0;

      for (const i in ob) {
        if (ob[i].checked) {
          cnt++;
        }
      }
      if (!cnt) {
        commonSVC.showMessage('실패', '삭제하실 세트상품을 하나 이상 선택 해주세요.');

        return;
      }
      if (cnt < 5 && ob.length != 1) {
        for (let i2 = 0; i2 < ob.length; i2++) {
          if (ob[i2].checked == true) {
            ob.splice(i2, 1);
            i2 = i2 - 1;
          }
        }
      } else {
        commonSVC.showMessage('실패', '세트상품 추가 매칭은 최소 1개 이상 등록 해주세요.');
      }
    };

    /**
     * 세트상품 전체 선택 및 해제
     */
    $scope.stockAllCheck = function(ob, flag) {
      for (const i in ob) {
        ob[i].checked = flag;
      }
    };

    //전체 체크박스 확인
    $scope.checkboxClick = function (ob, cbflag) {
      for (const i in ob) {
        if (!ob[i].checked) {
          $scope.flag[cbflag] = false;

          return;
        }
      }
      $scope.flag[cbflag] = true;
    };

    //옵션 순서이동 함수
    $scope.moveOpt = function (type, upward, position) {
      let prodType = '';

      switch (type) {
        case 1:
          prodType = 'esm_';
          break;

        case 2:
          prodType = 'cpang_';
          break;

        case 3:
          prodType = 'wmp_';
          break;
        case 5:
          prodType = 'hplus_';
          break;
        case 6:
          prodType = 'galleria_';
          break;
      }

      const opt_table = $scope.prodOpt[`${prodType}options_table`];
      const obj = {
        upward: upward,
        position: position,
        opt_table: opt_table,
        add: false
      };

      const resultOpt =	onlineProductSVC.optMoveSet(obj);

      if (resultOpt.state) {
        $scope.prodOpt[`${prodType}options_table`] = resultOpt.data;
      }
    };

    /**
		 * 추가구매 옵션 순서 변경
		 */
    $scope.moveAddOpt = function(upward, position) {
      const obj = {
        upward: upward,
        position: position,
        opt_table: $scope.prodOpt.add_opt_table,
        add: false,
      };
      const resultOpt =	onlineProductSVC.optMoveSet(obj);

      if (resultOpt.state) {
        $scope.prodOpt.add_opt_table = resultOpt.data;
      }
    };

		 /**
     * SKU상품 매칭시 대표상품 변경
     **/
    $scope.prodAutoSet = function () {
      const ob = _.find($scope.sku_list, function(row) { return row.prod_no == $scope.data.main_prod_no; });
      let optConfirm = $q.resolve(true);
      let product_data = {};

      if (!$scope.data.main_prod_no) {
        $scope.data.main_prod_no = '';

        return false;
      } else if (!ob) {
        return false;
      }
      optConfirm = commonSVC.showConfirm('주의', '대표 SKU상품 변경 시 해당 SKU상품의 정보로 일부 항목이 자동 변경됩니다. (환경설정 대표 SKU상품정보 자동세팅항목 참고) 변경하시겠습니까?');

      optConfirm
        .then(function(confirm) {
          if (confirm) {
            //세트상품인경
            if (ob.set_no) {
              const params = {
                set_no: ob.set_no
              };

              productModel.setDetails(params, function (state, re) {
                if (re.results === 'success') {
                  $scope.modelProdMatch.checked = false;
                  //세트상품의 대표 SKU상품정보 사용
                  product_data = re.data.prod.find(({ prod_no }) => prod_no == re.data.set.main_prod_no);
                  if ($scope.viewModelNo) {
                    product_data.set_name = re.data.set.set_name;
                  }

                  product_data.cost_price = product_data.set_cost_price || product_data.cost_price;
                  product_data.supply_price = product_data.set_supply_price || product_data.supply_price;

                  info_setting(product_data);
                } else {
                  commonSVC.showToaster('error', '', '세트상품 상세조회에 실패 했습니다.');
                }
              });
              //세트상품아닌경우
            } else {
              productModel.detail({ sku_code: ob.sku_cd }, function (state, data) {
                if (state === 'success') {
                  $scope.modelProdMatch.checked = false;

                  product_data = data.results;
                  info_setting(product_data);

                } else {
                  commonSVC.showToaster('error', '', 'SKU상품 상세조회에 실패 했습니다.');
                }
              });
            }
          }
        });
    };

    /**
     * 모델번호에서 대표상품 설정을 하는경우
     */
    $scope.prodAutoSetForModelno = async () => {

      if ($scope.leadProdInfoModelno.prod_no && $scope.modelProdMatch.checked) {
        $scope.data.model_prod_no = '';

        return false;
      } else if (!Object.keys($scope.leadProdInfoModelno).length || $scope.modelProdMatch.checked) {
        return false;
      }

      let product_data = {};
      const optConfirm = await commonSVC.showConfirm('주의', '대표 SKU상품 변경 시 해당 SKU상품의 정보로 일부 항목이 자동 변경됩니다. (환경설정 대표 SKU상품정보 자동세팅항목 참고) 변경하시겠습니까?');

      if (optConfirm) {
        //세트상품인경
        if ($scope.leadProdInfoModelno.set_no) {
          const params = {
            set_no: $scope.leadProdInfoModelno.set_no,
            sol_no: $rootScope.user_profile.pa_sol_no
          };

          productModel.setDetails(params, function (state, re) {
            if (re.results === 'success') {
              _.forEach(re.data.prod, function(n) {
                //세트상품의 대표 SKU상품정보 사용
                if (re.data.set.main_prod_no == n.prod_no) {
                  product_data = n;

                  product_data.cost_price = n.set_cost_price || n.cost_price;
                  product_data.supply_price = n.set_supply_price || n.supply_price;

                  info_setting(product_data, 'model_match');
                }
              });
            } else {
              commonSVC.showToaster('error', '', '세트상품 상세조회에 실패 했습니다.');
            }
          });
          //세트상품아닌경우
        } else {
          productModel.detail({ sku_code: $scope.leadProdInfoModelno.sku_cd, sol_no: $rootScope.user_profile.pa_sol_no }, function (state, data) {
            if (state === 'success') {
              product_data = data.results;
              info_setting(product_data, 'model_match');

            } else {
              commonSVC.showToaster('error', '', 'SKU상품 상세조회에 실패 했습니다.');
            }
          });
        }
      } else {
        $scope.modelProdMatch.checked = false;
        $timeout();
      }
    };

    //대표 SKU상품 정보 세팅
    function info_setting(product_data, type = '') {
      if (type === 'model_match') {
        $scope.data.main_prod_no = '';
        $scope.data.model_prod_no = String(product_data.prod_no);
      } else {
        $scope.data.main_prod_no = String(product_data.prod_no);
        $scope.data.model_prod_no = '';
        // 모델 매칭이 아닐경우 모델번호 상품정보도 변경
        $scope.leadProdInfoModelno = {};
      }

      //제조일자 유효일자 매칭
      if (!product_data.made_date || product_data.made_date == '0000-00-00') {
        $scope.data.made_date = '';
      } else {
        $scope.data.made_date = product_data.made_date;
      }

      if (!product_data.expire_date || product_data.expire_date == '0000-00-00') {
        $scope.data.expiration_month = '52';
        $scope.data.expire_date = '';
      } else {
        $scope.data.expiration_month = '';
        $scope.data.expire_date = product_data.expire_date;
      }

      //대표상품과 동일하게 원산지 자동매칭
      if (product_data.madein_etc != 'null' && product_data.madein_etc) {
        $scope.data.madein_type = '기타';
        $scope.data.etcMadein = product_data.madein_etc;
      } else {
        $scope.data.madein_type = product_data.madein_type;
        $scope.madein_type_change();
        $scope.data.madein_group = product_data.madein_group;
        $scope.madein_country_change();
        $scope.data.madein_name = product_data.madein_name;
      }
      $scope.data.multi_madein_yn = product_data.multi_madein_yn;

      //공급가
      $scope.data.supply_price = product_data.supply_price;

      //원가
      $scope.data.cost_price = product_data.cost_price;

      //과세여부 매칭 - sku는 과세가 일반,간이로 분리돼서 해당 처리 추가
      $scope.data.tax_type = '과세';

      if (product_data.tax_type && !['일반과세', '간이과세'].includes(product_data.tax_type)) {
        $scope.data.tax_type = product_data.tax_type;
      }

      //ISBN코드
      $scope.data.isbn = product_data.isbn ? product_data.isbn : '';

      //HS코드
      $scope.data.hscd = product_data.hscd ? product_data.hscd : '';

      //바코드
      if (product_data.barcode != 'null') {
        $scope.data.barcode = product_data.barcode;
      } else {
        $scope.data.barcode = '';
      }

      $scope.data.global_barcode = product_data.global_barcode;
      $scope.data.global_barcode_type = product_data.global_barcode_type;

      // 모델번호
      $scope.data.model_no = product_data.set_name || product_data.model_no;
      // if (type === 'model_match') {
      //   $scope.data.model_no = product_data.model_no;
      // }

      //대표 SKU상품 정보 자동세팅
      //해제:공급가, 원산지, 과세여부, 바코드, UPC/EAN코드, HS코드, ISBN코드
      //설정시 브랜드, 모델명, 제조사, 대표 이미지, 상세설명, 상품정보제공고시 추가 설정
      if ($scope.prod_auto_set_yn) {

        //일단 이미지 처리 빼놓음
        //대표상품 이미지 지정
        if (product_data.prod_img && product_data.prod_img !== '/assets/images/upload.png') {
          $scope.data.img_objects[0].files = product_data.prod_img;
          $scope.data.matchImg = product_data.prod_img;
        }

        //대표상품과 동일하게 모델명,브랜드,제조사 매칭
        $scope.data.search_model_yn = 0;
        $scope.data.typing_model.model = product_data.model;
        $scope.data.typing_model.brand = product_data.brand;
        $scope.data.typing_model.maker = product_data.maker;

        //상세설명
        $scope.data.detail_desc = product_data.contents;

        // 헤더 푸터 템플릿
        if (product_data.mas_addcontent_no) {
          $scope.data.mas_addcontent_no = product_data.mas_addcontent_no;
          $scope.data.mas_addcontent_name = product_data.addcontent_name;
          $scope.data.mas_header_content = product_data.header_content;
          $scope.data.mas_footer_content = product_data.footer_content;
          $scope.data.sub_off_avail_yn = product_data.sub_off_avail_yn;
          $scope.renderHtml('mas_header_content');
          $scope.renderHtml('mas_footer_content');

          // 해제 가능 여부
          $scope.header_template_release = ($rootScope.userProfileCheck('sol_ser', 'super_user', 'like') && $rootScope.affName === 'LG전자') // 슈퍼계정
          || $scope.data.sub_off_avail_yn == 1;
        } else {
          $scope.addcontentDelete();
        }

        // 신 상품상세정보고시
        if (product_data.prod_noti_data && Object.keys(product_data.prod_noti_data).length) {

          if ((+product_data.prod_noti_data.infoCode > 0 && +product_data.prod_noti_data.infoCode < 40) || ['20A', '20B', '20C'].includes(product_data.prod_noti_data.infoCode)) {
            return false;
          }
          const prod_info = product_data.prod_noti_data;
          const prod_noti_data_new = {};

          if (_.size(prod_info)) {
            const filter = _.filter(onlineProductSVC.newTypes, { code: prod_info.infoCode });

            $scope.data.prod_info_opt_new = filter[0] ? filter[0].name : '';
          }
          prod_noti_data_new.infoName = $scope.data.prod_info_opt_new;
          prod_noti_data_new.infoDetail = prod_info.infoDetail;
          prod_noti_data_new.infoCode = prod_info.infoCode;
          $scope.data.prod_noti_data_new = [prod_noti_data_new, {}, {}];
          temp_list_new = angular.copy($scope.data.prod_noti_data_new);
        } else {
          $scope.data.prod_info_opt_new = '';
          $scope.data.prod_noti_data_new = [{}, {}, {}];
          temp_list_new = [];
        }
      }
      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    }

    function promotionNameCheck() {
      const check_shop = `${$scope.data.pa_shop_cd}${[1, 2].includes($scope.data.std_ol_yn) ? '_single' : ''}`;

      if (onlineProductSVC.promotionProductName[check_shop] && (check_shop !== 'B378' || (check_shop === 'B378' && $scope.checkRocketGrowth($scope.data.shop_id)))) {
        $scope.promotion_name = onlineProductSVC.promotionProductName[check_shop].name;
        $scope.promotion_name_str = onlineProductSVC.promotionProductName[check_shop].str;

        if (!$scope.promotion_name_str) {
          $scope.promotion_name_byte = onlineProductSVC.promotionProductName[check_shop].byte;
        }
      }

      if ($scope.data.pa_shop_cd === 'P051') {
        $scope.efcCheck.oemChecked = $scope.data.shop_promotion_name === 'OEM 부품번호 알 수 없음';
        $scope.efcCheck.modelChecked = $scope.data.model_no === '차대번호 알 수 없음';
      }
    }

    /**
     * 로켓그로스 여부 확인
     */
    $scope.checkRocketGrowth = (shop_id) => {
      if ($rootScope.useChannelCheck('B378', 'growth')) {
        return $rootScope.use_channel_list_scm.find(channel => channel.pa_shop_cd === 'B378' && channel.etc6 === 'true' && channel.shop_id === shop_id);
      } else {
        return false;
      }
    };

    /**
     * SKU상품 선택 (추가구매 옵션)
     */
    $scope.stockMatchAddOpt = function(result, idx) {
      // 2018-05-24 Amelia 동일한 SKU상품 매칭 가능하게 처리
      // // 동일한 SKU코드로 매칭된게 있는지 확인
      // var flag = _.filter($scope.add_opt_table_arr, function(row){ return row.sku_cd == result.sku_cd; });
      // if(flag.length > 0){
      //   commonSVC.showMessage("실패","이미 매칭된 SKU코드 입니다.");
      //   return false;
      // }

      let stock_status = '판매가능';

      if (result.stock_status != '정상') {
        stock_status = '재고부족';
      }
      $scope.add_opt_table_arr[idx].sku_cd = result.sku_cd;     // SKU코드
      $scope.add_opt_table_arr[idx].stock_status = stock_status;  // 재고상태
      $scope.add_opt_table_arr[idx].stock_cnt = result.stock_cnt;// 실재고

      $scope.add_opt_table_arr[idx].prod_no = result.prod_no;     // 상품 번호
      $scope.add_opt_table_arr[idx].depot_no = result.depot_no;   // 창고 번호
    };

    /**
     * SKU상품 매칭
     */
    $scope.prodProductSearch = function (idx, list, esm, add, confirmflag, mainProd) {
      // ESM 물류 상품인경우 G마켓 물류 배송처가 설정된 기초상품만 설정가능하므로 스마일배송처만 넘겨줌.
      const resolve = {
        data: {
          opt_type: $scope.data.opt_type, // 2018-08-27 Alvin 조합형, 독립형 옵션만 배송처 비교하기때문에 옵션타입 추가
          warehouseList: $scope.data.std_ol_yn === 2 ? $scope.warehouseList.filter(o => o.ebaydepot_yn == 1) : $scope.warehouseList.filter(o => o.ebaydepot_yn != 2),
          modal_type: $scope.data.std_ol_yn === 2 ? 'smileProd' : ''
        },
      };
      const redata = commonSVC.openModal('xg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

      redata.result.then(function (re) {
        list[idx].opt_img = '';
        //세트상품인 경우
        if (re.isSet) {
          setProdMatch(re.data, idx, list, esm, add, confirmflag, mainProd);
        }
        //SKU상품인 경우
        else {
          productMatch(re, idx, list, esm, add, confirmflag, mainProd);
        }
        prodListSet();
      });
    };

    //로켓그로스 SKU 매칭 모달 (쿠팡 & 로켓그로스 테이블 전용)
    // rocket_options_table 내 쿠팡, 로켓그로스 매칭 정보가 각각 coupang, rocket 필드에 저장됨
    $scope.rocketProdProductSearch = function (idx, list, type, shop) {
      if (!list[idx][shop]) {
        list[idx][shop] = {};
      }

      let warehouseList = $scope.warehouseList.filter(o => o.ebaydepot_yn !== 2);

      if (shop === 'rocket') {
        warehouseList = $scope.warehouseList.filter(o => o.ebaydepot_yn === 2);
      }

      // ESM 물류 상품인경우 G마켓 물류 배송처가 설정된 기초상품만 설정가능하므로 스마일배송처만 넘겨줌.
      const resolve = {
        data: {
          warehouseList,
          type: type,
          modal_type: '',
          shop
        }
      };
      const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

      redata.result.then(function (re) {
        list[idx].opt_img = '';

        //세트상품인 경우
        if (re.isSet) {
          $scope.noneOptionMatchedSet = true;

          let main_product;

          //세트상품의 대표상품 구함
          angular.forEach(re.data.prod, function (row) {
            if (row.prod_no == re.data.set.main_prod_no) {
              main_product = row;
            }
          });

          //세트상품의 대표상품 정보로 옵션 리스트에 등록
          angular.extend(list[idx][shop], main_product);
          list[idx][shop].prod_no = main_product.prod_no;
          list[idx][shop].prod_name = re.data.set.set_name;
          list[idx].prod_img = re.data.set.set_img ? re.data.set.set_img : (main_product.prod_img || '/assets/images/upload.png');
          list[idx][shop].pack_unit = re.data.set.set_pack_unit;
          // list[idx][shop].prod_weight = $scope.prod_auto_set_yn ? _(result.prod).map('prod_weight').sum() : null;
          list[idx][shop].set_no = re.data.set.set_no;
          list[idx][shop].set_cd = list[idx][shop].sku_cd = re.data.set.set_cd;
          list[idx][shop].prod_status = '정상';
          list[idx][shop].checked = false;
          list[idx][shop].set_prod_list = re.data.prod; //세트에 묶인 상품 리스트, 상품 등록시 쓰임
        }
        //SKU상품인 경우
        else {
          $scope.noneOptionMatchedSet = false;
          // 원소 할당 안된경우 오브젝트 할당 2018-05-03 roy 추가
          if (list[idx][shop] === undefined) {
            list[idx][shop] = {};
          }

          angular.extend(list[idx][shop], re);
          list[idx][shop].sku_cd = re.sku_cd;
          list[idx].prod_img = re.prod_img || '/assets/images/upload.png';
          list[idx][shop].safe_stock = re.stock_cnt_safe;
          list[idx][shop].pack_unit = re.pack_unit;
          list[idx][shop].prod_status = '정상';             //result.stock_status == '재고부족' ? '품절' : '정상';
          // list[idx][shop].prod_weight = $scope.prod_auto_set_yn ? result.prod_weight : null;
          list[idx][shop].checked = false;
          list[idx][shop].set_no = 0;
          list[idx][shop].set_cd = null;
        }

        prodListSet();

        $(`#${shop}_sku_cd_${idx}`).focus();
      });
    };

    // 매칭된 SKU상품 삭제
    $scope.prodProductRemove = async function (row, shop) {
      commonSVC.showConfirmCustom({
        title: 'SKU상품 매칭 해제 안내',
        text: '해당 옵션에 매칭된 SKU상품의 정보를 삭제합니다.\n매칭을 해제하는 경우 옵션의 이미지도 함께 삭제되며,\n검색 버튼 클릭으로 다른 SKU상품을 매칭할 수 있습니다',
        showCancelButton: true,
        confirmButtonText: '삭제',
        cancelButtonText: '취소'
      }).then((confirm) => {
        if (confirm) {
          row.prod_img = '/assets/images/upload.png';
          row.opt_img = '';
          row.sku_cd = null;
          row.set_no = null;
          row.set_cd = null;
          row.depot_no = null;
          row.depot_name = null;
          row.prod_no = null;
          row.prod_name = null;
          row.set_name = null;
          row.sale_price = null;
          row.attri = null;
          row.stock_cnt = null;
          row.safe_stock = null;

          if (shop) {
            row[shop].prod_img = '/assets/images/upload.png';
            row[shop].sku_cd = null;
            row[shop].set_no = null;
            row[shop].set_cd = null;
            row[shop].depot_no = null;
            row[shop].depot_name = null;
            row[shop].prod_no = null;
            row[shop].prod_name = null;
            row[shop].set_name = null;
            row[shop].sale_price = null;
            row[shop].attri = null;
            row[shop].stock_cnt = null;
            row[shop].safe_stock = null;
          }

          $timeout(() => {});
        }
      });
    };

    /**
     * LG 전자 모델번호 SKU상품 매칭.
     */
    $scope.modelProductSearch = function () {
      const redata = commonSVC.openModal('xxg', { data: { modal_type: 'lg_custom' } }, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

      redata.result.then(function (re) {
        $scope.leadProdInfoModelno = re;
        $scope.data.model_no = re.model_no || '';

        if ($scope.data.model_prod_no && $scope.data.model_prod_no !== re.prod_no) {
          $scope.modelProdMatch.checked = false;
        } else {
          $scope.prodAutoSetForModelno();
          $scope.modelProdMatch.checked = true;
        }
        $timeout();
      });
    };

    //세트상품 옵션 테이블에 매칭
    function setProdMatch(result, idx, list) {
      // 동일 세트번호 매칭 가능하게 수정
      // //동일한 세트번호로 매칭된게 있는지 확인
      // var flag = _.filter(list, function(row) {return row.set_no == result.set.set_no});
      // if(flag.length > 0){
      //   commonSVC.showMessage("실패", "이미 매칭된 세트번호 입니다.");
      //   return false;
      // }

      let main_product;

      //세트상품의 대표상품 구함
      angular.forEach(result.prod, function(row) {
        if (row.prod_no === result.set.main_prod_no) {
          main_product = row;
        }
      });

      //세트상품의 대표상품 정보로 옵션 리스트에 등록
      angular.extend(list[idx], main_product);
      list[idx].prod_no = main_product.prod_no;
      list[idx].prod_name = result.set.set_name;
      list[idx].prod_img = result.set.set_img ? result.set.set_img : (main_product.prod_img || '/assets/images/upload.png');
      list[idx].pack_unit = result.set.set_pack_unit;
      // list[idx].prod_weight = $scope.prod_auto_set_yn ? _(result.prod).map('prod_weight').sum() : null;
      list[idx].set_no = result.set.set_no;
      list[idx].set_cd = list[idx].sku_cd = result.set.set_cd;
      list[idx].prod_status = '정상';
      list[idx].checked = false;
      list[idx].set_prod_list = result.prod; //세트에 묶인 상품 리스트, 상품 등록시 쓰임
      list[idx].sale_price = result.set.sale_price;   // 세트 대표상품 가격 => 세트 가격으로 변경 2019-04-29 rony

    }

    function productMatch(result, idx, list) {
      // 2018-05-24 Amelia 동일한 세트상품 매칭 가능하게 처리
      // // 동일한 SKU코드로 매칭된게 있는지 확인
      // var flag = _.filter(list, function (row) { return row.sku_cd == result.sku_cd; });
      // if (flag.length > 0) {
      //   commonSVC.showMessage("실패", "이미 매칭된 SKU코드 입니다.");
      //   return false;
      // }

      angular.extend(list[idx], result);
      list[idx].prod_img = result.prod_img || '/assets/images/upload.png';
      list[idx].safe_stock = result.stock_cnt_safe;
      list[idx].pack_unit = result.pack_unit || 1;
      list[idx].prod_status = '정상';       //result.stock_status == '재고부족' ? '품절' : '정상';
      // list[idx].prod_weight = $scope.prod_auto_set_yn ? result.prod_weight : null;
      list[idx].checked = false;
      list[idx].set_no = 0;
      list[idx].set_cd = null;
    }

    // 알리익스 & 알리바바 옵션명 삭제 함수
    $scope.deleteAliOptName = (opt_idx) => {
      $scope.prodOpt.opt[opt_idx].name = '';
    };

    //상세 옵션 타입 변경시 옵션 초기화
    $scope.optChange = async (type, is_cate_change = false) => {
      // 옵션 타입 변경 시 확인 모달 출력
      // 카테고리가 변경 되었을 경우 해당 처리 하지 않고 넘어감
      if (ori_opt_type === '간편옵션' && !is_cate_change && $scope.prodOpt.options_table.length && !await commonSVC.showConfirm('옵션 유형 변경 안내', '옵션의 유형을 변경하시면 입력된 값이 모두 초기화 됩니다.\n입력된 정보를 초기화 하고 변경하시겠습니까?')) {

        $scope.data.opt_type = ori_opt_type;
        $scope.$apply();

        return false;
      }

      if (type === '옵션없음') {
        $scope.prodOpt.options_table = [];
        if (!$scope.data.std_ol_yn && $scope.data.pa_shop_cd !== 'B719') {
          $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
        } else {
          _.forEach($scope.prodOpt.esm_opt, function(row) {
            row.attr = '';
          });
          $('.attrs').tagsinput('removeAll');
          $scope.prodOpt.esm_checked_opt = [];

          if ($scope.data.pa_shop_cd === 'A077') {
            $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
            $scope.prodOpt.naver_checked_opt = [];

            _.forEach($scope.prodOpt.opt, function(row) {
              row.selected_attribute = [];
              row.sub_group[0].attribute.forEach(attr => attr.selected = false);
              if (row.name === '사이즈') {
                row.selected_sub_group_name = row.sub_group[0].sub_group_name;
                row.selected_sub_group = row.sub_group[0].attribute;
              }
            });
          }
        }

      } else {
        if ($scope.data.pa_shop_cd === 'B394') {
          $scope.data.galleria_options = $scope.data.galleria_options.map(i => ({ ...i, checked: false }));
        }

        $scope.prodOpt.matchStockList = !$scope.data.checkGrowth.available ? [{ prod_img: '/assets/images/upload.png' }] : [{ prod_img: '/assets/images/upload.png', coupang: { sku_cd: '', prod_status: '정상' }, rocket: { cnt_limit: 0, sku_cd: '', prod_status: '정상' } }];   // 옵션없음 로켓그로스 SKU상품 리스트
      }

      if (type === '간편옵션' || $scope.data.set_info.is_standard_option_category === 'Y') {
        $scope.prodOpt.options_table = [];
        $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
        $scope.prodOpt.naver_checked_opt = [];
        if (type === '간편옵션') {
          getNaverCategory();
          naverOptSetting(0);
          naverOptSetting(1);
        }
      }
      if ($scope.data.pa_shop_cd === 'A077') {
        $scope.data.set_info.is_standard_option_category = type === '간편옵션' ? 'Y' : 'N';
      }
      ori_opt_type = $scope.data.opt_type;
      prodListSet();
    };

    // 생성하려는 옵션이 30개가 넘을 경우 알림 메시지를 주기 위한 스코프 함수
    $scope.optCnt = function () {
      let checkedOpt = [];

      if ($scope.data.std_ol_yn && $scope.data.pa_shop_cd !== 'B719') {
        checkedOpt = $scope.prodOpt.esm_checked_opt;
      }

      const prodOpt = ($scope.data.std_ol_yn && $scope.data.pa_shop_cd !== 'B719') ? checkedOpt : $scope.prodOpt.opt;

      return prodOpt.reduce((prev, curr) => {
        const sumLength = curr?.attr ? (['A001', 'A006'].includes($scope.data.pa_shop_cd) && typeof curr.attr !== 'string' ? curr.attr.length : curr.attr.split(',').length) : 0;

        return prev * sumLength;
      }, 1) > 30;
    };

    /**
     * 간편 옵션조합 확인
     */
    $scope.simpleOptFn1 = () => {
      if (!$scope.prodOpt.opt[0].attr || !$scope.prodOpt.opt[1].attr) {
        const arr = !$scope.prodOpt.opt[0].attr ? ['선택된 색상이 없습니다.', '색상을 선택해 주세요.\n(한가지 색상만 선택하셔도 됩니다.)']
          : ['선택된 사이즈가 없습니다.', '사이즈를 선택해 주세요.\n(한가지 사이즈만 선택하셔도 됩니다.)'];

        commonSVC.showMessage(...arr);

        return false;
      }

      if ($scope.prodOpt.options_table.length) {
        const attr1 = _.uniq($scope.prodOpt.options_table.map(o => o.attr1)).join();
        const attr2 = `${$scope.prodOpt.options_table[0].name2_cd}${_.uniq($scope.prodOpt.options_table.map(o => o.attr2)).join()}`;

        const newAttrSize = `${$scope.prodOpt.opt[1].name_cd}${$scope.prodOpt.opt[1].attr}`;

        if (attr1 === $scope.prodOpt.opt[0].attr && attr2 === newAttrSize) {
          commonSVC.showMessage('변경사항이 없습니다.');

          return false;
        }
        commonSVC.showConfirmCustom({
          title: '옵션 변경 안내',
          text: '이미 생성된 옵션과 다른 항목이 있을 경우 [옵션생성] 후 필수값을 추가로 입력해 주셔야 합니다.\n설정하신 색상과 사이즈로 변경하시겠습니까?',
          confirmButtonText: '예',
          cancelButtonText: '아니오'
        }, async (confirm) => {
          if (confirm === true) {
            await $scope.optFn1();
          } else {

            const attr1_cd = _.uniq($scope.prodOpt.options_table.map(o => o.attr1_cd));
            const attr2_cd = _.uniq($scope.prodOpt.options_table.map(o => o.attr2_cd));

            // 색상 복구
            $scope.prodOpt.opt[0].selected_attribute = [];
            $scope.prodOpt.opt[0].sub_group[0].attribute.forEach(att => att.selected = false);
            attr1_cd.forEach(cd => {
              const att = $scope.prodOpt.opt[0].sub_group[0].attribute.find(a => a.attributeValueId === cd);
              att.selected = true;
              $scope.prodOpt.opt[0].selected_attribute.push(att);
            });
            $scope.prodOpt.opt[0].attr = $scope.prodOpt.opt[0].selected_attribute.map(a => a.attributeValueName).join();
            $scope.prodOpt.opt[0].attr_cd = $scope.prodOpt.opt[0].selected_attribute.map(a => a.attributeValueId).join();

            // 사이즈 복구
            $scope.prodOpt.opt[1].selected_attribute = [];
            $scope.prodOpt.opt[1].selected_sub_group_name = $scope.prodOpt.opt[1].sub_group.find(s => s.sub_group_id === $scope.prodOpt.options_table[0].name2_cd).sub_group_name;
            $scope.changeNaverSizeList();

            $scope.prodOpt.opt[1].selected_sub_group.forEach(att => att.selected = false);
            attr2_cd.forEach(cd => {
              const att = $scope.prodOpt.opt[1].selected_sub_group.find(a => a.attributeValueId === cd);
              att.selected = true;
              $scope.prodOpt.opt[1].selected_attribute.push(att);
            });
            $scope.prodOpt.opt[1].attr = $scope.prodOpt.opt[1].selected_attribute.map(a => a.attributeValueName).join();
            $scope.prodOpt.opt[1].attr_cd = $scope.prodOpt.opt[1].selected_attribute.map(a => a.attributeValueId).join();

          }
          $scope.$apply();
        });
      } else {
        $scope.optFn1();
      }
    };

    /**
     * 옵션조합
     */
    $scope.optFn1 = function () {
      const prodOpt = ($scope.data.std_ol_yn && !['B719', 'A001', 'A006', 'A112', 'A092', 'A093', 'A077', 'X094'].includes($scope.data.pa_shop_cd)) ? $scope.prodOpt.esm_checked_opt : $scope.prodOpt.opt;
      const optPropChk = prodOpt.find(opt => ((!opt.name && opt.attr & opt.attr.length) || (opt.name && !(opt.attr && opt.attr.length))) && (opt.checked !== undefined ? opt.checked : true));
      const emptyOpt = prodOpt.findIndex(opt => (!opt.name));
      const optSize = prodOpt.filter(opt => opt.name).length;

      // 옵션명이나 옵션값 둘중 하나만 입력한 라인이 있는경우 옵션생성 실패처리
      if (optPropChk) {
        commonSVC.showMessage('옵션생성 실패', '옵션명과 옵션값을 모두 입력해주세요');

        return false;
      }

      // 옵션 입력창 중간에 빈 값이 있을 경우 옵션 생성 실패 처리
      if (emptyOpt !== -1 && emptyOpt < optSize) {
        commonSVC.showMessage('옵션생성 실패', '옵션값은 빈칸 없이 위에서 차례로 입력해 주세요.');

        return false;
      }

      if ($scope.prodOpt.esm_checked_opt.some(opt => $scope.data.pa_shop_cd === 'A112' && $scope.data.std_ol_yn && !opt.stdOpt && opt.typeCd === 'N')) {
        commonSVC.showMessage('옵션생성 실패', '단위 종류를 선택해 주세요');

        return false;
      }

      _.forEach($scope.prodOpt.opt, (option) => {
        if (option.unit) {
          const optionAttrSpread = [];

          _.forEach(option.attr.split(','), (attr) => {
            const re = new RegExp(`${option.unit}$`, 'g');
            const is_match = attr.match(re);

            if (!is_match) {
              optionAttrSpread.push(attr + option.unit);
            } else {
              optionAttrSpread.push(attr);
            }
          });
          option.attr = optionAttrSpread.join();
        }

        // ESM 2.0 옵션코드 초기화.
        if ($scope.data.esm_options) {
          option.name_cd = $scope.data.esm_options.find(o => o.name === option.name)?.name_cd || '';
        }
      });

      // 2018-09-18 chris 동일한 옵션은 입력값 유지하기위해 옵션 테이블 정보 백업
      const options_table_bak = {};

      _.each(angular.copy($scope.prodOpt.options_table), function(opt) {
        if ($scope.data.opt_type == '조합형' || $scope.data.opt_type == '간편옵션') {
          options_table_bak[opt.name1 + opt.name2 + opt.name3 + opt.attr1 + opt.attr2 + opt.attr3] = opt;
        } else {
          options_table_bak[opt.name + opt.attr] = opt;
        }
      });

      // 옵션명으로 동일한 옵션인지 체크후 동일한 옵션이면 백업한 옵션정보로 넣어줌
      const data = { opt: angular.copy(prodOpt) };

      for (const opt of data.opt) {
        if (opt.typeCd === 'N') {
          const cateOp = opt.cateOpAttrList.find(op => op.stdOptValNo === opt.stdOpt);
          const optList = opt.attr.split(',');

          opt.attr_text = optList.map(opt => `${opt}${cateOp.stdOptValNm}`).join();
        }
        if (opt.attr && ['A001', 'A006', 'A077'].includes($scope.data.pa_shop_cd) && typeof opt.attr !== 'string') {
          opt.attr = opt.attr.join();
        }
        // 알리 쇼핑몰은 attr 값이 변해도 attr_cd 값이 필요함
        if (opt.attr && ['A092', 'A093', 'X094'].includes($scope.data.pa_shop_cd)) {
          const attrCdList = opt.attr.split(',').map(v => opt.cateOpAttr.find(({ ObjOptEssenName }) => ObjOptEssenName === v)?.ObjOptClaseEssenNo);
          opt.attr_cd = attrCdList.reduce((pre, curr) => pre && !!curr, true) ? attrCdList.join(',') : '';
        }
      }

      const new_options_table = onlineProductSVC.setOptionsTable({ ...data, std_ol_yn: $scope.data.std_ol_yn }, $scope.data.opt_type);

      _.each(new_options_table, function(opt, key) {
        let k = '';

        if ($scope.data.opt_type == '조합형' || $scope.data.opt_type == '간편옵션') {
          k = opt.name1 + opt.name2 + opt.name3 + opt.attr1 + opt.attr2 + opt.attr3;
        } else {
          k = opt.name + opt.attr;
        }

        if (options_table_bak[k] && $scope.data.std_ol_yn) {
          new_options_table[key] = options_table_bak[k];
        }

        // 물류상품이고 ESM 마스터 상품인경우 재고 고정 등록
        if ($scope.esmMasterFlag && $scope.data.std_ol_yn === 2) {
          new_options_table[key].A006_cnt_limit = 99999;
          new_options_table[key].A001_cnt_limit = 99999;
        }

        if ($scope.data.checkGrowth.available) {
          new_options_table[key].coupang = angular.copy({ sku_cd: '', prod_status: '정상' });
          new_options_table[key].rocket = angular.copy({ cnt_limit: 0, sku_cd: '', prod_status: '정상' });
        }
      });

      $scope.prodOpt.options_table = [...new_options_table];
      $scope.orginProdOptionTable = [...new_options_table]; // 원본 옵션 데이터 변경

      $scope.data.main_prod_no = '';
      $scope.data.main_sale_opt_seq = null;

      optionAttrCheck();

      angular.forEach($scope.prodOpt.options_table, function (row, k) {
        row.sale_opt_seq = (parseInt(k) + 1);
      });

      // 로그용 옵션생성 여부 남김
      $scope.data.optMakeYn = true;

      // 알리 옵션명이 color가 아니면 disabled.ali_opt_not_color = true 처리
      if ($scope.data.pa_shop_cd === 'A092') {
        if (prodOpt.length) {
          const indexList = prodOpt.reduce((pre, { name }, index) => name?.toUpperCase() === 'COLOR' ? [...pre, index] : pre, []);
          indexList.forEach(ind => {
            $scope.disabled[`alibaba_opt_not_color${ind + 1}`] = false;
          });
        }
      } else if ($scope.data.pa_shop_cd === 'A093') {
        for (let i = 1; i < 4; i++) {
          $scope.disabled[`aliexpress_opt_not_color${i}`] = true;
        }

        if (prodOpt.length) {
          const indexList = prodOpt.reduce((pre, { name }, index) => ['Color', 'Metal Color', 'Scent Type'].includes(name) ? [...pre, index] : pre, []);
          indexList.forEach(ind => {
            $scope.disabled[`aliexpress_opt_not_color${ind + 1}`] = false;
          });
        }
      } else if ($scope.data.pa_shop_cd === 'X094') {
        for (let i = 1; i < 4; i++) {
          $scope.disabled[`aliexpress_global_opt_not_color${i}`] = true;
        }

        if (prodOpt.length) {
          const indexList = prodOpt.reduce((pre, { name }, index) => ['Color', 'Metal Color', 'Scent Type'].includes(name) ? [...pre, index] : pre, []);
          indexList.forEach(ind => {
            $scope.disabled[`aliexpress_global_opt_not_color${ind + 1}`] = false;
          });
        }
      }
    };

    function optionAttrCheck() {
      // 옵션이 다시 조합되는경우 옵션구분 갯수체크를 다시 해줘야 함 2018-09-20 rony
      if ($scope.prodOpt.options_table.length > 0) {
        $scope.attr1 = !!$scope.prodOpt.options_table[0].attr1;
        $scope.attr2 = !!$scope.prodOpt.options_table[0].attr2;
        $scope.attr3 = !!$scope.prodOpt.options_table[0].attr3;
      }
    }

    /**
     * 옵션추가
     */
    $scope.add_sub_opt = function(type, ob) {
      let maxCount = 10;
      $scope.loadOptList(false, true, true);
      if (type == 'opt' && $scope.data.opt_type == '독립형') {
        commonSVC.showMessage('실패', '독립형 옵션에 옵션값을 추가 하고자 하는 경우에는 상위 <옵션생성>을 통해 추가해 주셔야 합니다.');

        return false;
      }

      // 옵션추가는 제한없음
      if (type == 'opt') {
        maxCount = 99999;
      }
      if (ob.length < maxCount) {
        ob.push({
          prod_img: '/assets/images/upload.png',
          parent: -1,
          // opt_name1: $scope.prodOpt.opt[0].name,
          // opt_name2: $scope.prodOpt.opt[1].name,
          // opt_name3: $scope.prodOpt.opt[2].name,
          name1: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[0].name : '',
          name2: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[1].name : '',
          name3: $scope.prodOpt.opt[2] ? $scope.prodOpt.opt[2].name : '',
          name1_cd: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[0].name_cd : '',
          name2_cd: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[1].name_cd : '',
          name3_cd: $scope.prodOpt.opt[2] ? $scope.prodOpt.opt[2].name_cd : '',
          opt_img: null,
          // skuCheck: false,
          // opt_name1_cd: "",
          // opt_name2_cd: "",
          // opt_name3_cd: "",
          opt_add_price: 0,
          prod_status: '정상',
          new_opt_yn: true,
          ...($scope.data.checkGrowth.available && { prod_img: '/assets/images/upload.png', coupang: { sku_cd: '', prod_status: '정상' }, rocket: { cnt_limit: 0, sku_cd: '', prod_status: '정상' } }),
          ...($scope.data.std_ol_yn === 2 && { cnt_limit: 99999 })
        });

        $scope.orginProdOptionTable.push({
          prod_img: '/assets/images/upload.png',
          parent: -1,
          // opt_name1: $scope.prodOpt.opt[0].name,
          // opt_name2: $scope.prodOpt.opt[1].name,
          // opt_name3: $scope.prodOpt.opt[2].name,
          name1: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[0].name : '',
          name2: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[1].name : '',
          name3: $scope.prodOpt.opt[2] ? $scope.prodOpt.opt[2].name : '',
          name1_cd: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[0].name_cd : '',
          name2_cd: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[1].name_cd : '',
          name3_cd: $scope.prodOpt.opt[2] ? $scope.prodOpt.opt[2].name_cd : '',
          opt_img: null,
          // skuCheck: false,
          // opt_name1_cd: "",
          // opt_name2_cd: "",
          // opt_name3_cd: "",
          opt_add_price: 0,
          prod_status: '정상',
          new_opt_yn: true,
          ...($scope.data.checkGrowth.available && { coupang: { sku_cd: '', deal_type: '기초상품', prod_status: '정상' }, rocket: { cnt_limit: 0, sku_cd: '', deal_type: '3자배송', prod_status: '정상' } }),
          ...($scope.data.std_ol_yn === 2 && { cnt_limit: 99999 })
        });
      }
      // $scope.reCheckClass.valid('recheck');
    };
    /**
     * 옵션삭제
     */
    $scope.del_sub_opt = function (ob) {
      for (let i = 0; i < ob.length; i++) {
        if (ob[i].checked === true) {
          //대표상품 지정인 경우 해제
          if (ob[i].prod_no === $scope.data.main_prod_no) {
            $scope.data.main_prod_no = '';
          }

          ob.splice(i, 1);
          $scope.orginProdOptionTable.splice(i, 1);

          i = i - 1;
        }
      }
      $scope.loadOptList(false, true);
    };

    /**
     * 세트 상세보기
     */
    $scope.setShowDetail = function(set_no) {
      const resolve = {
        data: { from: '수정', set_no: set_no }
      };

      commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
    };

    /**
     * 추가구매 옵션 테이블 생성
     */
    $scope.create_opt = function() {
      const new_opt_table = [];

      for (const i in $scope.prodOpt.add_opt) {
        const opName = $scope.prodOpt.add_opt[i].opName ? $scope.prodOpt.add_opt[i].opName.split(',') : [];
        const opPrice = $scope.prodOpt.add_opt[i].opPrice ? $scope.prodOpt.add_opt[i].opPrice.split(',') : [];

        //추가 옵션명, 추가 금액의 길이가 같지 않는 경우
        if (opName.length != opPrice.length) {
          commonSVC.showMessage('실패', '추가 옵션명과 추가 금액을 맞춰주세요.');

          return;
        }
        for (let j = 0; j < opName.length; j++) {
          const optData = {
            name: $scope.prodOpt.add_opt[i].name,
            opName: opName[j],
            opPrice: Number(opPrice[j]),
            prod_status: '정상'
          };

          if (isNaN(opPrice[j])) {
            commonSVC.showMessage('실패', '추가 금액은 숫자만 입력 해주세요.');
            $scope.prodOpt.add_opt_table = [];

            return;
          }
          if (optData.name) {
            new_opt_table.push(optData);
          }
        }
      }

      angular.forEach($scope.prodOpt.add_opt_table, function(row) {
        angular.forEach(new_opt_table, function(row2) {
          if (row.opName == row2.opName && row.opPrice == row2.opPrice && row.name == row2.name) {
            row2 = Object.assign(row2, row);
          }
        });
      });

      $scope.prodOpt.add_opt_table = new_opt_table;
      $scope.data.addOptMakeYn = true;
    };

    //원산지 국가 선택
    $scope.madein_type_change = function() {
      $scope.madein_group_list = $scope.madeinList[$scope.data.madein_type];
      $scope.data.madein_group = '';
      $scope.data.madein_name = '';
      $scope.data.etcMadein = '';
    };

    //원산지 도시 선택
    $scope.madein_country_change = function() {
      $scope.madein_name_list = $scope.data.madein_group ? $scope.madeinList[$scope.data.madein_type][$scope.data.madein_group] : null;
      $scope.data.etcMadein = '';
    };

    /**
     *상품등록 옵션 타입 변경시 초기화
     */
    $scope.$watchCollection('data.opt_type', function (nv, ov) {
      if ((nv == '독립형' && ov == '조합형') || (nv == '조합형' && ov == '독립형')) {
        $scope.prodOpt.options_table = [];
        $scope.data.main_prod_no = '';
      }
    });

    /**
     * 쇼핑몰 카테고리 매칭정보 open
     **/
    $scope.infoOpen = async () => {
      const resolve = {};
      //2018-02-23 roy
      //ESM2.0 카테고리 변경시 단일상품 분리 등록을 위해 추가
      const esm = {
        flag: ($scope.data.pa_shop_cd === 'A001' || $scope.data.pa_shop_cd === 'A006') && $scope.data.std_ol_yn && ($scope.data.opt_type != '옵션없음'),
        esm_opt_size: $scope.disabled.esm_opt_size,
        esm_opt_color: $scope.disabled.esm_opt_color
      };

      resolve.data = {
        shop_cd: $scope.data.shop_cd,
        pa_shop_cd: $scope.data.pa_shop_cd,
        shop_id: $scope.data.shop_id,
        shop_pwd: $scope.data.shop_pwd,
        category_code: $scope.data.sol_cate_no,
        esm: esm,
        cateEdit: false,
        sale_status: $scope.data.sale_status,
        path: 'ol_edit'
      };

      if (_.isEmpty($scope.data.shop_cate_no_add_info)) {

        try {
          if ($scope.data.sol_cate_no) {
            const res = await categoryModel.shopInfoDetailDataLoad({
              categoryId: $scope.data.sol_cate_no,
              shop_cd: $scope.data.shop_cd,
              shop_id: $scope.data.shop_id
            });

            if (res.data.data && res.data.data.length) {
              resolve.data.shop_cate_no_add_info = res.data.data[0].sol_cate_shop_add_info;
            } else {
              resolve.data.shop_cate_no_add_info = '';
            }
          } else {
            resolve.data.shop_cate_no_add_info = '';
          }

        } catch (err) {
          commonSVC.showMessage('실패', '카테고리 로딩 실패');
        }
      } else {
        resolve.data.shop_cate_no_add_info = $scope.data.shop_cate_no_add_info;
      }

      // JSON.stringify 중복 처리 방지
      if (typeof resolve.data.shop_cate_no_add_info === 'string' && ['B877', 'B394', 'B594', 'A064', 'B878'].includes(resolve.data.pa_shop_cd)) {
        if (resolve.data.shop_cate_no_add_info.length && typeof JSON.parse(resolve.data.shop_cate_no_add_info) === 'string') {
          resolve.data.shop_cate_no_add_info = JSON.parse(resolve.data.shop_cate_no_add_info);
        }
      }

      // 매칭된 카테고리가 없는경우 카테고리 정보 제거.
      if (!$scope.data.match_cate_name) {
        resolve.data.shop_cate_no_add_info = {};
      }

      if ($scope.data.pa_shop_cd === 'A077' && $scope.data.std_ol_yn) {
        resolve.data.std_ol_yn = true;
      }

      const redata = commonSVC.openModal('full', resolve, 'OnlineCategoryMatchCtrl', 'views/online/product/modals/category_match.html');

      redata.result.then(async function (result) {
        $scope.data.shop_cate_cd = result.category_code;
        $scope.data.match_cate_name = result.category_names;
        $scope.data.shop_cate_no_add_info = typeof result.detailData !== 'string' ? JSON.stringify(result.detailData) : result.detailData;

        if ($scope.data.pa_shop_cd === 'A112') {
          $scope.data.shop_cate_key_selPrdTypCd = !result.detailData.key_selPrdTypCd;
        }

        // 결과값에 ESM 카테고리가 있는경우 값 담아줌. 2018-06-18 rony
        if (!_.isUndefined(result.detailData.catename_esm) || result.detailData.catename_esm !== '') {
          $scope.data.shop_cate_esm_match_cate_name = result.detailData.catename_esm;
        }

        //shop_cate_no가 없을 경우
        if (result.shop_cate_no) {
          $scope.data.shop_cate_no = result.shop_cate_no;
        } else {
          const sData = {
            pa_shop_cd: $scope.data.pa_shop_cd,
            shop_cd: $scope.data.shop_cd,
            shop_id: $scope.data.shop_id,
            sol_cate_no: $scope.data.sol_cate_no || '',
            cate_code: result.category_code.replace(/>/g, '_'),
            cate_name: result.category_names
          };

          categoryModel.shopCategoryAdd(sData, function (state, data) {
            $scope.data.shop_cate_no = data.result;
          });
        }

        await categoryOptions(false, (result.opt_size && result.opt_color), result.isChange);
        $scope.data.coupang_cate = result.category_code;

        if ($scope.data.pa_shop_cd === 'B394') {
          commonSVC.showMessage('카테고리 변경', '카테고리를 변경하시면 입력 가능한 추천 옵션, 브랜드 값이 달라질 수 있습니다.\n카테고리 변경 후 기본정보 탭에서 브랜드와 옵션 정보를 수정 후 상품 전송 해주세요.');

          if ($scope.data.opt_type !== '옵션없음') {
            $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
          }
          $scope.prodOpt.options_table = [];
          getGalleriaCategory();

          return false;
        }

        if ($scope.data.pa_shop_cd === 'A077' && !$scope.data.std_ol_yn) {
          getNaverCategory();
          if ($scope.data.opt_type == '간편옵션') {
            $scope.data.opt_type = '옵션없음';
            $scope.optChange('옵션없음', true);
          }
        }

        if ($scope.data.pa_shop_cd === 'A092') {
          $scope.disabled.alibaba_opt = true;
        } else if ($scope.data.pa_shop_cd === 'A093') {
          $scope.disabled.aliexpress_opt = true;
        } else if ($scope.data.pa_shop_cd === 'X094') {
          $scope.disabled.aliexpress_global_opt = true;
        }
        //2018-02-23 roy
        //ESM2.0 이전 카테고리와 다른 옵션인 경우 단일상품으로 분리
        if (result.isChange) {
          $scope.isChange = result.isChange;
          $scope.checked.opt_size_flag = result.opt_size;
          $scope.checked.opt_color_flag = result.opt_color;
        } else {
          $scope.disabled.esm_opt_size = result.opt_size;
          $scope.disabled.esm_opt_color = result.opt_color;
        }
      });
    };

    // 개별 수정 카테고리 삭제(수정 시 반영됨)
    $scope.deleteIndividualCategory = function () {
      $scope.data.shop_cate_no = 0;
      $scope.data.shop_cate_no_add_info = {};
      $scope.data.match_cate_name = '';
      $scope.data.shop_cate_esm_match_cate_name = '';
      $scope.data.shop_cate_no = 0;
      $scope.data.sol_shop_cate_no = oriCateInfo.sol_shop_cate_no;

      if ($scope.data.pa_shop_cd === 'B394') {
        commonSVC.showMessage('카테고리 변경', '카테고리를 변경하시면 입력 가능한 추천 옵션, 브랜드 값이 달라질 수 있습니다.\n카테고리 변경 후 기본정보 탭에서 브랜드와 옵션 정보를 수정 후 상품 전송 해주세요.');
        if ($scope.data.opt_type !== '옵션없음') {
          $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
        }
        getGalleriaCategory();
      }

    };

    $scope.checkNaverCateReset = () => {
      commonSVC.showConfirmCustom({
        title: '경고',
        text: '기존 등록된 카테고리와 옵션 지원 내용이 다른 카테고리입니다.\n간편옵션을 사용하는 경우 기존에 상품정보에 입력하셨던 옵션이 초기화 됩니다.\n카테고리를 수정하시겠습니까?',
        confirmButtonText: '예',
        cancelButtonText: '아니오'
      }, async (confirm) => {
        if (confirm === true) {
          await $scope.deleteIndividualCategory();
          if ($scope.data.opt_type !== '옵션없음') {
            $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
          }

          $scope.data.shop_cate_no = 0;
          $scope.data.sol_shop_cate_no = oriCateInfo.sol_shop_cate_no;
          getNaverCategory();
        }
      });

      return;
    };

    /**
     * 쇼핑몰별 이미지 설정
     * @type {boolean}
     */
    $scope.centerAnchor = true;
    $scope.toggleCenterAnchor = function () {
      $scope.centerAnchor = !$scope.centerAnchor;
    };
    // $scope.onDropComplete=function(data,evt,idx){
    //
    //   if(!data.files) return false;
    //   var sub_img_objects = $scope.data.site_list[idx].sub_img_objects;
    //   var sub_img_name = $scope.data.site_list[idx].sub_img_name;
    //
    //   var index = sub_img_objects.indexOf(data);
    //   if (index == -1) {
    //     var img_name = sub_img_objects.length < 1 ? "기본이미지":"추가이미지"+sub_img_objects.length;
    //     sub_img_objects.push(data);
    //     sub_img_name.push(img_name);
    //   }
    // };

    // 상세설명 이미지 업로드
    $scope.detailImageUpload = async (file) => {
      if (!file) {
        return;
      }

      const { data: { results: [{ url }] } } = await commonModel.tempUpload([file]);

      $scope.data.desc_img_url = url;
    };

    /**
     * 쇼핑몰별 개별 이미지 등록을 위한 S3 temp 업로드 작업
     */
    $scope.uploadFiles = function (files, name, img, site) {
      let start, end;
      const url_results = [];

      commonSVC.resizingImg(files, systemList.data.img_resize)
        .then(function(files) {
          let i = 0;

          start = Number(img.code.slice(8));
          end = files.length + start;
          if (files && files.length > 0) {
            if (name !== 'default') {
              for (i = start; i < end; i++) {
                if (i < site.sub_img_objects.length) {
                  site.sub_img_objects[i].files = files[i - start];
                }
              }
            } else {
              //기본이미지 설정인 경우 대표상품으로 매칭된 이미지 제거
              if (site === 0) {
                $scope.data.matchImg = '';
              }
              for (i = start; i < end; i++) {
                if (i < $scope.data.img_objects.length) {
                  $scope.data.img_objects[i].files = files[i - start];
                }
              }
            }
          } else {
            for (i = start; i < end; i++) {
              if (i < $scope.data.img_objects.length) {
                $scope.data.img_objects[i].files = files[i - start];
              }
            }
          }

          const re_files = [];

          if (name !== 'default') {
            _.forEach(site.sub_img_objects, function(v) {
              if (typeof v.files === 'string' && v.files) {
                // url_results.push(v.files);
              } else {
                re_files.push(v.files);
              }
            });
          } else {
            for (i = start; i < end; i++) {
              if (i < $scope.data.img_objects.length) {
                re_files.push($scope.data.img_objects[i].files);
              }
            }
          }

          return commonModel.tempUpload(re_files);
        })
        .then(function (res) {
          const results = res.data.results;
          let i = 0;

          for (i = 0; i < results.length; i++) {
            if (results[i]) {
              url_results.push(results[i].url);
            }
          }
          if (name !== 'default') {
            for (i = 0; i < url_results.length; i++) {
              if (url_results[i]) {
                site.sub_img_objects[start + i].files = url_results[i];
                site.sub_img_objects[start + i].sub_img_url = url_results[i];
              }
            }
          } else if (name === 'default') {
            for (i = 0; i < url_results.length; i++) {
              if (url_results[i]) {
                $scope.data.img_objects[start + i].files = url_results[i];
                $scope.data.img_objects[start + i].sub_img_url = url_results[i];
              }
            }
          }
        });
    };

    /**
     * 2018-04-12 Daniel
     * 옵션 이미지 임시 업로드
     */
    $scope.uploadOptFile = function (file, row) {
      const files = [file];
      const imgCheck = /(\.jpg|\.jpeg|\.png)$/i;

      if (file && imgCheck.exec(file.name)) {
        // # S3 temp 업로드 작업
        commonModel.tempUpload(files)
          .then(function (res) {
            row.opt_img = res.data.results[0].url;
          });
      } else {
        commonSVC.showMessage('주의', '이미지파일을 확인하세요.');
      }

    };

    /**
     *  입력형 옵션 삭제
     */
    $scope.insert_opt_del = function(idx) {
      // 마지막 입력형 옵션을 삭제할경우 다시 추가하고 싶어도 페이지 새로고침을 하지않는 한 추가가 불가능하기 때문에 삭제하지않고 값을 지워주는 것으로 처리.
      if ($scope.data.insert_opt_arr.length > 1) {
        $scope.data.insert_opt_arr.splice(idx, 1);
      } else {
        $scope.data.insert_opt_arr[0].insert_opt = '';
        $scope.data.insert_opt = false;
      }
    };

    /**
     * 2018-05-02 roy
     * 쇼핑몰별 추가 이미지 업로드
     */
    $scope.imageUpload = function(image, file) {
      if (!image.files || _.isEmpty(file)) {
        uploadImageErrMsg(image.en_name);

        return false;
      }
      const files = [image.files];
      // S3 temp 업로드 후 임시 url 등록
      commonModel.tempUpload(files)
        .then(function(res) {
          Object.prototype.hasOwnProperty.call(image, 'image_url') ? image.image_url = res.data.results[0].url : image.url = res.data.results[0].url;
        });
    };

    /**
     * 2019-09-03 liam
     * @param {string} fileType - 업로드 파일 유형
     */
    function uploadImageErrMsg (fileType) {
      switch (fileType) {
        case 'mobile_detail_img': {
          commonSVC.showMessage('1.5MB 미만의 jpeg, jpg 확장자만 등록 가능합니다.');
        }
      }
    }

    /**
     * 2018-05-02 roy
     * 쇼핑몰별 추가 이미지 제거
     */
    $scope.imageDelete = function(image) {
      image.del_list?.push(image.url || image.old_files);
      image.files = '';
      Object.prototype.hasOwnProperty.call(image, 'image_url') ? image.image_url = '' : image.url = '';
    };

    /**
     * 이미지파일 원본보기 모달.
     * @param {이미지파일} img
     */
    $scope.imgModal = function (img) {
      const resolve = {
        data: {
          file: typeof img === 'object' ? img : { files: img }
        }
      };
      commonSVC.openModal('lg', resolve, 'olImgModal', 'views/online/product/olProdImg.html');
    };

    /**
     * 쇼핑몰별 이미지 삭제
     */
    $scope.delShopImg = function (idx) {
      // 삭제할 이미지
      if ($scope.data.img_objects[idx].files !== '') {
        $scope.data.img_del_obj.push($scope.data.img_objects[idx].files);
      }

      $scope.data.img_objects[idx] = {
        code: $scope.data.img_objects[idx].code,
        files: '',
        name: $scope.data.img_objects[idx].name,
        old_files: $scope.data.img_objects[idx].old_files,
        is_del: 'y'
      };

    };

    /**
     * 옵션 이미지 삭제
     */
    $scope.delOptImg = (row) => {
      row.opt_img = '';
    };

    /**
     * hscode 검색
     **/
    $scope.searchHscode = function() {
      const resolve = {};

      resolve.data = {
        name: $scope.data.hscd
      };
      const redata = commonSVC.openModal('lg', resolve, 'CategorySearchHscodeCtrl', 'views/settings/category/searchHscode.html');

      redata.result.then(function (hscode) {
        $scope.data.hscd = hscode;
      });
    };

    $scope.validTab = function(idx) {
      return !inValidTab[idx - 1];
    };

    /**
     * 수정하기 버튼만 이용하도록 처리(엔터 불가)
     */
    $scope.submitListner = function() {
      const esmDupSet = new Set();
      const esmDupArr = [];

      $scope.prodOpt.esm_opt.forEach(opt => {
        if (opt.name) {
          if (esmDupSet.has(opt.name)) {
            esmDupArr.push(opt.name);
          } else {
            esmDupSet.add(opt.name);
          }
        }
      });

      // 독립형 옵션은 esm 추천옵션 중복이 불가하므로 해당 처리 추가
      if ($scope.data.opt_type === '독립형' && esmDupArr.length) {
        commonSVC.showMessage('독립형 옵션은 중복옵션 설정이 불가능합니다.');

        return;
      }

      if ($scope.data.pa_shop_cd === 'A077' && $scope.data.std_ol_yn && $scope.data.opt_type === '옵션없음') {
        commonSVC.showMessage('옵션 정보를 설정해주세요', '스마트스토어 단일(그룹)상품은 옵션 1개 이상 필수입니다.');
        $scope.changeTab(2);

        return false;
      }

      // 옵션 모델번호 기준 상품 모델번호 자동입력
      if ($scope.viewModelNo && !$scope.data.model_no) {
        if ($scope.data.opt_type === '옵션없음' && $scope.prodOpt.matchStockList[0]?.model_no) {
          $scope.data.model_no = $scope.prodOpt.matchStockList[0].model_no;
        } else if (['조합형', '독립형'].includes($scope.data.opt_type) && $scope.prodOpt.options_table[0]?.model_no) {
          $scope.data.model_no = $scope.prodOpt.options_table[0].model_no;
        }
      }

      if ($scope.prodOpt.options_table.some(item => !item.cnt_limit)) {
        $scope.prodOpt.options_table = $scope.prodOpt.options_table.map(item => {
          if (item.cnt_limit === '') {
            return { ...item, cnt_limit: null };
          }

          return item;
        });
        $scope.flag.isOpen = 'true';
      }

      if ($scope.prodOpt.add_opt_table.some(item => !item.cnt_limit)) {
        $scope.prodOpt.add_opt_table = $scope.prodOpt.add_opt_table.map(item => {
          if (item.cnt_limit === '') {
            return { ...item, cnt_limit: null };
          }

          return item;
        });
        $scope.flag.isAddOptOpen = 'true';
      }
      $timeout(() => {
        $scope.reCheckClass.valid('recheck');
        $('#prodform_edit').submit();
        $scope.productSubmit();
      });
    };

    // 상품명, 프로모션 상품명, 이름 바이트, 글자수 체크해서 valid처리해주는 함수
    const prodNameValid = (first = false) => {
      let return_value = false;

      if ($scope.data.std_ol_yn && ($scope.data.pa_shop_cd === 'A001' || $scope.data.pa_shop_cd === 'A006')) {
        if (first) {
          $scope.prod_name_check_msg = '상품명 + 프로모션명은 100byte를 초과할 수 없습니다.';
        }
        // esm2.0 단일상품 합산을 위해 특별처리 상품명 + 프로모션명 100바이트 제한
        if ($scope.byteCheck($scope.data.shop_sale_name) + $scope.byteCheck($scope.data.shop_promotion_name) > 100) {
          $scope.prod_name_check = true;
          inValidTab[1] = false;

          return_value = 'name';
        }
      } else {
        const { shop_sale_name_max_length, shop_sale_name_max_length_char_yn } = onlineProductSVC.getSaleNameLimiter($scope.data.pa_shop_cd);
        const maxLength = shop_sale_name_max_length || 100;

        if (first) {
          $scope.prod_name_check_msg = `상품명은 ${maxLength}${shop_sale_name_max_length_char_yn ? '자' : 'byte'}를 초과할 수 없습니다.`;
        }
        if (shop_sale_name_max_length_char_yn) {
          if ($scope.characterCountCheck($scope.data.shop_sale_name) > maxLength) {
            $scope.prod_name_check = true;
            inValidTab[1] = false;

            return_value = 'name';
          }
        } else {
          if ($scope.byteCheck($scope.data.shop_sale_name) > maxLength) {
            $scope.prod_name_check = true;
            inValidTab[1] = false;

            return_value = 'name';
          }
        }

        // 프로모션 상품명까지 검사해야 하는 경우
        if (['A112', 'A536', 'A537', 'A176'].includes($scope.data.pa_shop_cd)) {
          const { name: name2_name, byte: promotion_max_byte, str: promotion_max_length } = onlineProductSVC.promotionProductName[`${$scope.data.pa_shop_cd}${$scope.data.std_ol_yn ? '_single' : ''}`];
          const promotion_max = promotion_max_length || promotion_max_byte;
          const promotion_max_standard = promotion_max_length ? '자' : 'byte';

          if (first) {
            $scope.prod_promotion_check_msg = `${name2_name}은 ${promotion_max}${promotion_max_standard}를 초과할 수 없습니다.`;
          }

          const promotion_vaildate = promotion_max_standard === '자' ? $scope.characterCountCheck($scope.data.shop_promotion_name) : $scope.byteCheck($scope.data.shop_promotion_name);
          if (promotion_vaildate > promotion_max) {
            $scope.prod_promotion_check = true;
            inValidTab[1] = false;

            return_value = return_value || 'promotion';
          }
        }
      }
      if (!return_value) {
        // 위를 무사히 통과하면 문제없는것
        $scope.prod_name_check = false;
        $scope.prod_promotion_check = false;
      }

      return return_value || 'success';
    };

    /**
     * 상품수정
     */
    $scope.productSubmit = async function() {
      // 이름 검사가 있는 상품들 저장 직전에 마지막 검사
      const check_value = prodNameValid();
      if (check_value === 'name') {
        commonSVC.showMessage('상품 수정 실패', $scope.prod_name_check_msg);

        return false;
      } else if (check_value === 'promotion') {
        commonSVC.showMessage('상품 수정 실패', $scope.prod_promotion_check_msg);

        return false;
      }

      // 판매자관리코드 수정인경우 중복확인여부 체크.
      if ($scope.data.edit_c_sale_cd && $scope.overlapResult != 'y') {
        commonSVC.showMessage('상품 수정 실패', '판매자관리코드 중복확인을 해주세요.');
        $scope.overlapResult = 'n';
        $scope.alerts = { msg: '판매자관리코드 중복확인을 진행해 주세요.' };
        $scope.submitDisabled = false;

        return false;
      }

      //카테고리 수정 정보
      if (_.isString($scope.data.shop_cate_no_add_info)) {
        $scope.data.shop_cate_no_add_info = JSON.parse($scope.data.shop_cate_no_add_info);
      }

      // 등록될 원산지 번호 정리
      if ($scope.data.madein_type !== '기타' && $scope.data.madein_type !== '원양산') {
        $scope.data.madein_etc = '';
        const madein_list = $scope.madeinList[$scope.data.madein_type][$scope.data.madein_group];

        angular.forEach(madein_list, function(row) {
          if (row.detail == $scope.data.madein_name) {
            $scope.data.madein_no = row.number;
          }
        });
      } else if ($scope.data.madein_type === '원양산') {
        const madein_list = $scope.madeinList[$scope.data.madein_type];

        angular.forEach(madein_list, function(row) {
          if (row.group == $scope.data.madein_group) {
            $scope.data.madein_no = row.number;
          }
        });
        $scope.data.etcMadein = `${$scope.data.madein_type}=${$scope.data.madein_group}`;
      } else {
        $scope.data.madein_no = '';
      }

      if ($scope.data.add_opt_type == 'N') {
        $scope.prodOpt.add_opt_table = [];
        $scope.originAddOptTable = [];
      }

      // 인증유형 등록일 경우에만 인증정보 저장
      $scope.data.certDataList = $scope.data.cert_info.cert_type == '인증유형등록' ? $scope.certDataList : [];

      //아래의 테이블로 옵션 추출
      if ($scope.data.opt_type !== '옵션없음' && !$scope.optGetTables()) {
        return false;
      }

      if ($scope.data.add_opt_use_yn && $scope.prodOpt.add_opt_table.some((row, index, arr) => arr.some((opt, optIndex) => index !== optIndex && row.name === opt.name && row.opName === opt.opName))) {
        commonSVC.showMessage('상품 수정 실패', '추가구매 옵션 중복확인 해주세요.');
        $scope.changeTab(2);

        return false;
      }

      if ($scope.data.add_opt_type !== 'N' && !$scope.prodOpt.add_opt_table.length) {
        commonSVC.showMessage('추가구매옵션 생성이 되어있지 않습니다.', '추가구매옵션이 없는 상품인 경우 "없음" 으로 체크해 주시고,\n추가구매옵션이 있는 상품인 경우 "추가 항목명, 추가 옵션명, 추가 금액" 확인 후 [옵션생성] 버튼을 눌러주세요.');
        $scope.changeTab(2);
        $scope.flag.isAddOptOpen = 'true';

        return false;
      }
      //옵션 코드값 확인
      if ($scope.data.std_ol_yn == 1 && $scope.data.pa_shop_cd !== 'B719') {
        const setOpts = ['A001', 'A006'].includes($scope.data.pa_shop_cd) ? $scope.prodOpt.esm_opt : ['A092', 'A093', 'X094'].includes($scope.data.pa_shop_cd) ? $scope.prodOpt.opt : $scope.prodOpt.esm_checked_opt;

        if ($scope.data.opt_type === '독립형') {
          onlineProductSVC.setIndependentOptionCode($scope.prodOpt.options_table, setOpts);
        } else {
          onlineProductSVC.setOptionCode($scope.prodOpt.options_table, setOpts, $scope.data.pa_shop_cd);
        }
      }

      if (['A001', 'A006'].includes($scope.data.pa_shop_cd) && $scope.data.std_ol_yn) {
        const items = await getCategoryOptions();

        // 옵션구분 추천옵션코드가 없는경우 넣어줌
        if (['A001', 'A006'].includes($scope.data.pa_shop_cd) && items.length && $scope.prodOpt.opt.length) {
          $scope.prodOpt.opt.map(o => {
            if (o.name) {
              const recommendOption = items.find(item => item.ObjOptClaseName === o.name);

              if (recommendOption) {
                o.name_cd = recommendOption.ObjOptNo;
              } else {
                o.name_cd = 0;
              }
            }
          });
        }

        if (
          items &&
          $scope.tabActive !== 2 &&
          ([
            $scope.data.opt_sort1_cd,
            $scope.data.opt_sort2_cd,
            $scope.data.opt_sort3_cd,
          ].some(
            (optCd) =>
              optCd &&
                optCd !== 'undefined' &&
                !items
                  .map(item => item.ObjOptNo)
                  .includes(+optCd)
          ) || [$scope.data.opt_sort1_cd, $scope.data.opt_sort2_cd, $scope.data.opt_sort3_cd].filter(Boolean).length && $scope.data.opt_type === '옵션없음')
        ) {
          $scope.changeTab(2);

          return false;
        }
      }

      // 간편옵션 사이즈 리스트 변경 후 테이블 생성 안한 경우 사이즈 코드만 변경되는 문제가 발생 해 옵션 테이블의 사이즈 코드로 저장
      if ($scope.data.pa_shop_cd === 'A077' && $scope.data.opt_type === '간편옵션') {
        $scope.prodOpt.opt[1].name_cd = $scope.prodOpt.options_table[0].name2_cd;
      }

      const flag = onlineProductSVC.checkValue($scope.data, $scope.checked, 'edit', $scope.prodOpt, $scope.form1);

      inValidTab = flag.inValidTab;

      // 물류상품인경우 매칭된 SKU가 G마켓물류 배송처인지 체크필요.
      if ($scope.data.std_ol_yn === 2 && flag.result == true && $scope.form1.$valid && $scope.prodOpt.options_table.some(o => (o.set_no || o.sku_cd) && !$scope.ebayDepots.includes(o.depot_no))) {
        inValidTab[1] = false;
        flag.result = false;
      }

      if (flag.result == true && $scope.form1.$valid) {
        $scope.submitDisabled = true;
        // 쇼핑몰별 등록될 상품 옵션 리스트 정리

        makeProdList();
        productUpdate();

      } else {
        $scope.submitDisabled = false;
        for (const i in inValidTab) {
          if (!inValidTab[i]) {
            $scope.changeTab(Number(i) + 1);
            break;
          }
        }
        if ($scope.form1.$valid) {
          commonSVC.showMessage('상품 수정 실패', flag.msg || '입력값을 확인해주세요.');
          $scope.submitDisabled = false;
        }
      }
    };

    async function productUpdate() {
      // 추가항목 설정 정보
      $scope.data.set_info = $scope.data.site_list[0].set_info;
      // 추가항목 양식 저장 여부
      if ($scope.data.site_list[0].saveAsTemplateYn) {
        $scope.data.set_info.saveAsTemplateYn = $scope.data.site_list[0].saveAsTemplateYn;
      }
      // 추가항목 기본양식 설정 여부
      if ($scope.data.site_list[0].default_yn) {
        $scope.data.set_info.default_yn = $scope.data.site_list[0].default_yn;
      }
      $scope.prodOpt.options_table = ($scope.orginProdOptionTable && ($scope.orginProdOptionTable.length > $scope.OPT_LIST_LIMIT) && $scope.prodOpt.options_table.length !== $scope.orginProdOptionTable.length) ? $scope.prodOpt.options_table.concat($scope.orginProdOptionTable.slice($scope.prodOpt.options_table.length)) : ($scope.prodOpt.options_table || []);

      //옵션 테이블
      $scope.data.options_table = $scope.data.opt_type === '옵션없음' ? $scope.prodOpt.matchStockList : $scope.prodOpt.options_table;
      $scope.data.add_opt_table = ($scope.originAddOptTable && ($scope.originAddOptTable.length > $scope.OPT_LIST_LIMIT) && $scope.prodOpt.add_opt_table.length !== $scope.originAddOptTable.length) ? $scope.prodOpt.add_opt_table.concat($scope.originAddOptTable.slice($scope.prodOpt.add_opt_table.length)) : ($scope.prodOpt.add_opt_table || []);

      $scope.data.opt_unit1 = $scope.prodOpt.esm_checked_opt[0] && $scope.prodOpt.esm_checked_opt[0].stdOpt;
      $scope.data.opt_unit2 = $scope.prodOpt.esm_checked_opt[1] && $scope.prodOpt.esm_checked_opt[1].stdOpt;
      $scope.data.opt_unit3 = $scope.prodOpt.esm_checked_opt[2] && $scope.prodOpt.esm_checked_opt[2].stdOpt;

      if ($scope.data.pa_shop_cd === 'B378') {
        let key_siteOptNmData2 = '',
            key_optNmData2 = '';
        const searchOpt = $scope.data.set_info.key_siteOptObject || {};

        //카테고리별 검색옵션이 있을경우만 사용
        if ($scope.data.site_list[0].attributes) {
          $scope.data.site_list[0].attributes.forEach(function(opt) {
            key_siteOptNmData2 += `${opt.attributeTypeName}|`;
            if (opt.attributeTypeName in searchOpt) {
              key_optNmData2 += searchOpt[opt.attributeTypeName];
            }
            key_optNmData2 += '|';
          });
        }
        $scope.data.set_info.key_siteOptNmData2 = key_siteOptNmData2;
        $scope.data.set_info.key_optNmData2 = key_optNmData2;

        if ($scope.data.etc6 === 'true') {
          $scope.data.growthProdType = $scope.data.checkGrowth.coupang && $scope.data.checkGrowth.rocket ? 'ALL' : ($scope.data.checkGrowth.coupang ? 'CP' : 'RG');

          const ebayShopMasNo = $scope.data.ebay_shop_mas_no?.split('†')[0];

          if (['ALL', 'RG'].includes($scope.data.growthProdType)) {
            $scope.data.ebay_shop_mas_no = `${ebayShopMasNo ? ebayShopMasNo : `TEMP${Math.floor(1000000000 + Math.random() * 9000000000)}`}†${$scope.data.growthProdType}`;
          } else {
            $scope.data.ebay_shop_mas_no = '';
          }
        }
      }

      // 템플릿 코드
      $scope.data.template_no = $scope.data.site_list[0].template_no;

      // 추가항목 이미지
      $scope.data.set_info_add_img = $scope.data.site_list[0].set_info_add_img;

      // 29cm의 경우 상세설명 이미지, 상품 추가 설명을 추가항목에 저장
      if ($scope.data.pa_shop_cd === 'P015' && $scope.data.set_info.add_desc_info?.content_flag === 'F') {
        $scope.data.set_info.add_desc_info.content_info = [{}];
      }

      // 키워드가 null 로 들어가는 경우 빈값처리. 2018-01-18 rony
      if (_.isNull($scope.data.keyword)) {
        $scope.data.keyword = '';
      }

      $scope.data.prod_stock = $scope.data.sale_cnt_limit;
      const oldData = commonSVC.replaceNull(angular.copy(onlineProductModel.getOld()));
      const newData = commonSVC.replaceNull(angular.copy($scope.data));

      $scope.data.oldData = onlineProductModel.getOld();
      $scope.data.detailDescEtc = $scope.detailDescEtc;

      let updateProm;

      try {
        if ($scope.data.sale_status === '판매중' && newData.match_cate !== oldData.match_cate) {
          updateProm = commonSVC.showConfirm('주의', '쇼핑몰에 이미 등록된 상품의 카테고리는 수정 전송 되지 않습니다.\n상품을 수정대기 상태로 변경하시겠습니까?');
        } else if ($scope.data.shop_cd === 'Z000') {
          // 마스터 상품과 함꼐 수정할 온라인 상품 리스트 구하기
          const resolve = {
            slaveProdList: function () {
              return onlineProductModel.getSlaveLinkedMaster($scope.data.sale_no);
            },
            systemList: systemList,
            data: {
              edit_single_prod: true
            }
          };

          const modal = commonSVC.openModal('md2', resolve, 'EditMasterProdCtrl', 'views/online/product/modals/edit_master_prod.html', false, true, false);

          updateProm = modal.result;
        } else { // 일반 수정 시

          // ESM 마스터 상품 지마켓 수정 시 연동 수정으로 공통 필드 옥션도 수정
          if ($scope.data.shop_cd === 'A006' && $scope.data.ebay_shop_mas_no) {
            const slaveProdList = (await onlineProductModel.getSlaveLinkedMaster($scope.data.sale_no))?.data.results;
            const matchEditProd = slaveProdList.find(ol => ol.shop_cd === 'A001' && ol.ebay_shop_mas_no === $scope.data.ebay_shop_mas_no);

            if (matchEditProd) {
              const addParams = {
                add_editOption: [ 'shop_sale_name', 'shop_promotion_name', 'opt', 'addOpt', 'madein', 'tax_type', 'ship', 'model_brand_maker', 'adult_yn', 'prod_noti_data', 'made_date', 'expire_date', 'cert_info', 'gift_name', 'img', 'detail_desc', 'isbn'],
                add_ol_shop_no: [matchEditProd.ol_shop_no],
                add_sale_status: [matchEditProd.sale_status],
                add_std_ol_yn: [1],
                add_selected_shop: [matchEditProd],
                add_shop_cd: [matchEditProd.shop_cd],
                add_shop_id: [matchEditProd.shop_id],
                isEsmProdEdit: 1
              };
              updateProm = $q.resolve(addParams);
            } else {
              updateProm = $q.resolve(true);
            }
          } else {
            updateProm = $q.resolve(true);

          }
        }

        const re = await updateProm;

        if (!re) {
          throw '';
        } else if (re.add_ol_shop_no) {
          $scope.data.add_ol_shop_no = re.add_ol_shop_no;
          $scope.data.add_shop_cd = re.add_shop_cd;
          $scope.data.add_shop_id = re.add_shop_id;
          $scope.data.add_std_ol_yn = re.add_std_ol_yn;
          $scope.data.add_sale_status = re.add_sale_status;
          $scope.data.add_editOption = re.add_editOption;
          $scope.data.add_selected_shop = re.add_selected_shop;
          $scope.data.isEsmProdEdit = re.isEsmProdEdit;
        }

        const res = await onlineProductModel.edit($scope.data);

        if (res.status === 200 && res.data.results === 'success') {
          // 로컬 스토리지에 번역 데이터 있을 경우 수정 성공시 삭제
          if (localStorageService.get(`olShop_TrnslData_${$scope.data.ol_shop_no}`)) {
            localStorageService.remove(`olShop_TrnslData_${$scope.data.ol_shop_no}`);
          }

          // 상품이 판매중인경우에만 해당 팝업 출력. 2017-09-05 서상권
          if ($scope.data.sale_status === '판매중') {
            commonSVC.showToaster('success', '성공', '상품이 수정대기 상태로 변경되었습니다.<br>수정전송 후 쇼핑몰에 반영됩니다.');
          } else {
            commonSVC.showToaster('success', '성공', '상품정보가 수정 되었습니다.');
          }

          // 2018-05-16 amelia 수정후 페이지 유지 위해 주석처리
          // 2018-01-11 chris 리스트 새로고침을 위한 이벤트 추가
          // $rootScope.$broadcast('reloadOlProductList');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showToaster('error', '실패', '상품정보 수정에 실패하였습니다.');
        }
      } catch (err) {
        if (err !== 'cancel' && err !== 'escape key press') {
          const errMsg = errorSVC.getError('prod', err.data?.messages[0], err.data?.messages[0] || err.message || JSON.stringify(err.stack || ''));
          commonSVC.showToaster('error', '실패', errMsg);
        }
      } finally {
        $scope.submitDisabled = false;
      }
    }

    /**
     * 닫기
     */
    $scope.cancel = function () {
      // 메모입력된경우 새로고침
      if ($scope.data.memoUpdate) {
        $uibModalInstance.close('success');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    $scope.changeTab = function(idx) {
      $scope.tabActive = idx;
    };

    /**
     * 온라인 템플릿 리스트 가져오기
     */
    $scope.onlineTemplate = function(index, openFlag) {
      const data = $scope.data;
      const site = data.site_list[index];

      //수정 상세모달은 하나의 사이트만
      $scope.site_idx = 0;
      //템플릿 검색 input 초기화
      site.search = '';

      //템플릿 수정후 펼침상태 유지
      if (openFlag == undefined) {
        $scope.templateListModal = !$scope.templateListModal;
      }

      $scope.openTemplate = index;

      //선택된 템플릿 매칭해주기위한 데이터
      $scope.shop_params = { shop_cd: site.shop_cd, shop_id: site.shop_id };

      const allTemplate = [];
      const bookmarkGroup = _.groupBy($scope.templateTemp, 'bookmark_yn');

      _.each(bookmarkGroup, function(list, yn) {
        if (parseInt(yn)) {
          $scope.templateList = list;
        } else {
          $scope.templateList = [];
        }

        // 전체 템플릿 리스트 노출위해 값 담음 2018-07-09 rony
        _.forEach(list, function(v) {
          allTemplate.push(v);
        });
      });

      $scope.templateList2 = allTemplate;
      if (!$scope.templateList.length) {
        // 없을경우 전체탭으로이동
        $(`#category${index}-1`).removeClass('active');
        $(`#category${index}-2`).addClass('active');

        $(`#bookmarkTab${index}`).removeClass('active');
        $(`#allTab${index}`).addClass('active');
      }

      $scope.templateLoadingMsg = '선택 가능한 템플릿이 없습니다. 템플릿 등록 후 선택할 수 있습니다.';
    };

    $scope.categorySearchFilter = function(c) {
      const searchKey = new RegExp($scope.categorySearchKey, 'i');

      return searchKey.test(String(c.id)) || searchKey.test(c.name) || c.name === '';
    };

    // 카테고리 새로고침
    $scope.cateReload = function () {
      $scope.categorySearchKey = '';
      $scope.cateReloadYn = true;

      // 카테고리 리스트 조회
      categoryModel.categorySelectList()
        .then(function (data) {
          $scope.categoryList = data.data;
        })
        .catch(function () {
          commonSVC.showMessage('카테고리 불러오기 실패', '일시적 서버 불안정 일수 있으므로, 재시도 후 안될 시 본사로 문의 부탁드립니다.');
        })
        .finally(function () {
          $scope.cateReloadYn = false;
        });
    };

    // 마스터 상품만 솔루션 카테고리 수정 가능
    $scope.categoryChangeModal = function () {
      if ($scope.data.shop_cd === 'Z000') {
        if (!$scope.categoryListModal) {
          $scope.cateReload();
        }

        $scope.categoryListModal = !$scope.categoryListModal;
      }
    };

    // 카테고리 이름 설정
    $scope.setCategory = function(cate) {
      commonSVC.showConfirm('주의', '해당 마스터상품에 연동된 쇼핑몰상품의 카테고리가 솔루션 카테고리에 매칭된 정보로 모두 수정됩니다. (단일상품 및 쇼핑몰 상품 상세에서 개별 카테고리 수정한 상품 제외)\n카테고리를 수정하시겠습니까? (이미 쇼핑몰에 등록된 상품은 카테고리 수정 전송이 되지 않습니다.)', function (confirm) {
        if (confirm) {
          $scope.categoryName = cate.name;
          $scope.data.sol_cate_no = cate.id;
          $scope.categoryListModal = false;

          // 카테고리 선택 후 반영이 바로 안되고있어 추가
          $scope.$apply();
        }
      });
    };

    /**
     * 온라인 템플릿 상세모달
     * 2020-03-26 중복 계정을 생성할 수 있는 쇼핑몰의 경우 올바른 쇼핑몰 코드를 보내도록 수정
     */
    $scope.onlineTemplateDetail = function(template_no, siteCode, siteId, shopName, shopCd) {
      const isExistingTemplate = !template_no && $scope.data.template_info;
      const resolve = {
        data: {
          type: 'olProd',
          template_no: template_no,
          shop_cd: shopCd,
          shop_name: shopName,
          shop_id: siteId,
          pa_shop_cd: siteCode,
          isEdit: false
        }
      };

      // 기존템플릿인경우 정보 넘겨줌.
      if (isExistingTemplate) {
        resolve.data.registed_template_info = $scope.data.template_info;
        resolve.data.ol_shop_no = $scope.data.ol_shop_no;
      }

      const modal = commonSVC.openModal('full', resolve, 'setFormTemplateCtrl', 'views/online/setForm/setform_detail.html');

      modal.result.then(function (param) {
        if (isExistingTemplate) {
          $scope.data.site_list[0].template_name = param.template_name;
          $scope.data.site_list[0].template_no = param.template_no;
        } else {
          if (param != 'success') {
            commonSVC.showToaster('error', '실패', '처리 실패');
          } else {
            //수정 완료시 데이터 새로고침
            $scope.onlineTemplate($scope.openTemplate, 'false');
          }
        }
      });

    };

    /**
     *  템플릿 리스트 닫기
     */
    $scope.listModal = function(param) {
      if (param == 'false') {
        $scope.templateListModal = false;
      }
    };

    /**
     *  갤러리아몰, 롯데ON 선택옵션
     */
    $scope.select_opt = (shop, item, $event) => {
      const item_checked = !item.checked;

      if (shop !== 'galleria') {
        // 필수옵션 제외 모두 제거
        for (const i in $scope.prodOpt.opt) {
          if (!$scope.prodOpt.opt[i].isRequired) {
            $scope.prodOpt.opt[i].name = $scope.prodOpt.opt[i].attr = '';

            if ($scope.prodOpt.opt[i].unit !== undefined) {
              $scope.prodOpt.opt[i].unit = '';
            }

            if ($scope.prodOpt.opt[i].usableUnits !== undefined) {
              $scope.prodOpt.opt[i].usableUnits = [];
            }

            $(`.attrs${i}`).tagsinput('removeAll');
          }

        }
      }

      let tmp = [];

      if (shop == 'galleria') {
        tmp = $scope.data[`${shop}_options`];
        for (let i = 0; i < 3; i += 1) {
          if ($scope.prodOpt.opt[i].name === item.name) {
            $scope.prodOpt.opt.splice(i, 1);
            $scope.prodOpt.opt.push(angular.copy(onlineProductSVC.default_opt)[0]);
            break;
          } else if (!$scope.prodOpt.opt[i].name) {
            $scope.prodOpt.opt[i] = $.extend($scope.prodOpt.opt[i], angular.copy(item));
            $scope.prodOpt.opt[i].name = $scope.prodOpt.opt[i].name || (item.attributeTypeName || item.name);
            $scope.prodOpt.opt[i].custom_able = item.custom_able;
            break;
          }
        }
      }
      else {
        item.checked = item_checked;

        tmp = $scope.data[`${shop}_options`].optional;
        // 선택된 선택옵션 추가
        tmp.forEach(function(opt) {
          if (opt.checked) {
            for (let i2 = 0; i2 < 3; i2++) {
              if (!$scope.prodOpt.opt[i2] || !$scope.prodOpt.opt[i2].name) {
                $scope.prodOpt.opt[i2] = $.extend($scope.prodOpt.opt[i2], angular.copy(opt));
                $scope.prodOpt.opt[i2].name = $scope.prodOpt.opt[i2].name || (opt.attributeTypeName || opt.name);

                break;
              }
            }
          }
        });

        $scope.prodOpt.options_table = [];
      }
      item.checked = item_checked;

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    };

    /**
     * 유효일자 직접입력시 초기값 설정
     */
    $scope.initExpire = function() {
      if ($scope.data.expiration_month == '') {
        $scope.data.expire_date = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
      }
    };
    /**
     * 모달 열기
     * */
    let isEdit = false;

    /**
     * 상품정보제공고시 선택 모달 오픈
     */
    $scope.openProdNotiInfoModal = function (tabActive) {
      const resolve = {};

      resolve.data = {
        temp_list: temp_list_new,
        type: isEdit,
        tabActive
      };

      const modal = commonSVC.openModal('lg', resolve, 'OnlineProductProdInfoCtrl', 'views/online/product/prod_info.html');

      modal.result.then(function (re) {

        temp_list_new = angular.copy(re);

        isEdit = 'add';
        for (const i in re) {
          if (re[i].infoCode) {
            const array_result = {};

            $scope.data.prod_info_opt_new = re[i].infoName;
            //체인지 이벤트 발생 안하기 때문에 직접 처리
            $('#prod_info_opt_new-error').remove();

            array_result.infoCode = re[i].infoCode;
            array_result.infoName = re[i].infoName;
            array_result.infoDetail = {};

            re[i].infoDetail.forEach(list => {
              list.detail.map(v => {
                array_result.infoDetail[v.code] = v.value;
              });
            });

            $scope.data.prod_noti_data_new[i] = array_result;

          } else {
            $scope.data.prod_noti_data_new[i] = '';
          }
        }
      });
    };

    /**
     * 검색 필터
     */
    $scope.search = function(element) {
      if ($scope.data.site_list[$scope.openTemplate].search === '') {
        return true;
      }

      return !!JSON.stringify(element).match(new RegExp(`(${$scope.data.site_list[$scope.openTemplate].search})`, 'i'));
    };

    $scope.changeCostPrice = function() {
      $scope.$broadcast('changeCostPrice', { cost: $scope.data.cost_price });
    };
    /**
     * 데이터 초기화
     */
    $scope.init = async () => {
      /**
       * 데이터 로드
       */
      // $scope.number = data.number;
      let row_data = angular.copy(onlineProductSVC.defaultVdata);
      const sol_cate_shop_add_info = (prodDetail.data.results.sol_cate_shop_add_info && prodDetail.data.results.sol_cate_shop_add_info !== '{}') ? prodDetail.data.results.sol_cate_shop_add_info : {},
            shop_cate_no_add_info = (prodDetail.data.results.shop_cate_no_add_info && prodDetail.data.results.shop_cate_no_add_info !== '{}') ? prodDetail.data.results.shop_cate_no_add_info : {},
            main_set_no = 0;

      row_data = angular.extend(row_data, prodDetail.data.results);
      // 복수 쇼핑몰 개별 이미지
      const paShopCd = row_data.pa_shop_cd;
      if (onlineProductSVC.sub_img_objects[paShopCd]) {
        row_data.img_objects = onlineProductSVC.sub_img_objects[paShopCd];
      }

      const tmpData = localStorageService.get(`olShop_TrnslData_${row_data.ol_shop_no}`);

      if (tmpData?.translate_data && Object.keys(tmpData.translate_data)) { // 로컬 스토리지에 저장된 번역 데이터가 있을 경우 반영 컨펌 모달
        const confirm = await commonSVC.showConfirmCustom({
          title: '번역 임시 저장 데이터 저장 안내',
          text: `
          <div class="mb-30">
            번역이 완료된 후 저장되지 않은 데이터가 있습니다.
            <ul>
              <li>${Object.keys(tmpData.translate_data).map(key => onlineProductSVC.trnslFileds[key]).join(', ')}</li>
            </ul>
            저장하지 않은 정보는 상품 정보에 반영되지 않습니다.
          </div>
          [확인] 클릭 시 하단의 [수정] 버튼을 눌러 저장할 수 있습니다.<br />
          [삭제] 클릭 시 번역된 정보만 삭제 후 원본은 유지됩니다.`,
          html: true,
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '삭제'
        });

        if (confirm) {
          await applyTrnslData(tmpData, row_data);
        } else {
          localStorageService.remove(`olShop_TrnslData_${row_data.ol_shop_no}`);
          commonSVC.showToaster('success', '성공', '임시저장된 번역 정보 삭제 완료');
        }
      }

      $scope.data = angular.copy(row_data);

      // ESM 1.0 지원 종료로 상세모달 진입 시 수정버튼 비활성화
      if ($scope.data.std_ol_yn === 0 && ['A001', 'A006'].includes($scope.data.pa_shop_cd || $scope.data.shop_cd)) {
        $scope.submitDisabled = true;
      }
      // 카테고리번호 원본값 저장
      oriCateInfo.shop_cate_no = $scope.data.shop_cate_no;
      oriCateInfo.sol_shop_cate_no = $scope.data.sol_shop_cate_no;

      // 옵션 생성 여부(로그용)
      $scope.data.optMakeYn = false;
      $scope.data.addOptMakeYn = false;

      $scope.data.edit_c_sale_cd = false;     // 판매자관리코드 수정여부 2018-10-02 rony
      $scope.data.shop_cate_no_add_info = shop_cate_no_add_info;
      $scope.data.shop_cate_no = prodDetail.data.results.shop_cate_no || 0;
      $scope.data.curr_cd = prodDetail.data.results.curr_cd;

      // 로켓그로스 확인용
      $scope.data.checkGrowth = {
        available: prodDetail.data.results.pa_shop_cd === 'B378' && prodDetail.data.results.etc6 === 'true',
        coupang: true,
        growth: false
      };

      // 대리점모듈일 경우 내일배송 변수 기본값 세팅
      if ($scope.viewModelNo) {
        $scope.data.nextday_ship_ol_yn = $scope.data.nextday_ship_ol_yn || 0;
        $scope.data.nextday_ship_avail_yn = $scope.data.nextday_ship_avail_yn || 0;
      }

      $scope.header_template_set = $rootScope.userProfileCheck('sol_ser', 'super_user', 'like') && $rootScope.affName === 'LG전자';     // 슈퍼계정
      // || ($rootScope.user_profile.pa_sol_no && !$scope.prodData.ori_prod_no);                     // 가져온 상품이 아닐때 적용

      // 템플릿 해제 조건
      $scope.header_template_release = ($rootScope.userProfileCheck('sol_ser', 'super_user', 'like') && $rootScope.affName === 'LG전자') // 슈퍼계정
        || $scope.data.sub_off_avail_yn == 1;                                                       // 가져온 상품 해제여부
      // || ($rootScope.user_profile.pa_sol_no && !$scope.prodData.ori_prod_no);                    // 가져온 상품이 아닐때

      $scope.solCateEsmExistYn = false;
      $scope.shopCateEsmExistYn = false;

      // 알리익스는 조합형 || 옵션없음 뿐임
      if (['A093'].includes($scope.data.pa_shop_cd) && $scope.data.opt_type === '조합형') {
        const indexList = [$scope.data.opt_sort1, $scope.data.opt_sort2, $scope.data.opt_sort3].reduce((pre, name, index) => ['Color', 'Metal Color', 'Scent Type'].includes(name) ? [...pre, index] : pre, []);
        indexList.forEach(ind => {
          $scope.disabled[`aliexpress_opt_not_color${ind + 1}`] = false;
        });
      }

      // 알리익스프레스(글로벌)은 조합형 || 옵션없음 뿐임
      if (['X094'].includes($scope.data.pa_shop_cd) && $scope.data.opt_type === '조합형') {
        const indexList = [$scope.data.opt_sort1, $scope.data.opt_sort2, $scope.data.opt_sort3].reduce((pre, name, index) => ['Color', 'Metal Color', 'Scent Type'].includes(name) ? [...pre, index] : pre, []);
        indexList.forEach(ind => {
          $scope.disabled[`aliexpress_global_opt_not_color${ind + 1}`] = false;
        });
      }

      if (['A001', 'A006'].includes($scope.data.pa_shop_cd) && $scope.data.sol_cate_shop_add_info !== null && $scope.data.sol_cate_shop_add_info.category_code !== undefined) {
        const { data: solCateEsmExistYn } = await categoryModel.getEsmCategoryExistYn({
          match_cate_code: $scope.data.sol_cate_shop_add_info.category_code,
          shop_cd: $scope.data.shop_cd,
        });

        $scope.solCateEsmExistYn = solCateEsmExistYn.result;

      }

      if (['A001', 'A006'].includes($scope.data.pa_shop_cd) && $scope.data.shop_cate_no_add_info !== null && $scope.data.shop_cate_no_add_info.category_code !== undefined) {
        const { data: shopCateEsmExistYn } = await categoryModel.getEsmCategoryExistYn({
          match_cate_code: $scope.data.shop_cate_no_add_info.category_code,
          shop_cd: $scope.data.shop_cd,
        });

        $scope.shopCateEsmExistYn = shopCateEsmExistYn.result;
      }

      if ($scope.data.pa_shop_cd === 'B719') {
        $scope.data.std_ol_yn = 1;
      }

      // 추천옵션 쇼핑몰은 아무 카테고리도 없는 임시상품이면 모달 열때 경고창
      if (['B378', 'B394'].includes($scope.data.pa_shop_cd) && Object.keys(sol_cate_shop_add_info).length === 0 && Object.keys(shop_cate_no_add_info).length === 0) {
        commonSVC.showMessage('상품에 카테고리가 지정되지 않았습니다.', '카테고리 탭에서 카테고리 설정 후 수정하시기 바랍니다.');
      }

      // ESM단일/11번가단일은 카테고리 없으면 얼랏 띄워줌
      if (['A001', 'A006', 'A112'].includes($scope.data.pa_shop_cd)
        && Object.keys(shop_cate_no_add_info).length === 0 && $scope.data.std_ol_yn) {
        commonSVC.showMessage('상품에 카테고리가 지정되지 않았습니다.', '카테고리 탭에서 카테고리 설정 후 수정하시기 바랍니다.');
      }

      if ($scope.data.shop_cd === 'Z000') {
        $scope.prod_type = '마스터';
        $scope.modal_title = $scope.data.c_sale_cd;
      } else {
        $scope.prod_type = '쇼핑몰';
        $scope.modal_title = `${$scope.data.shop_name} - ${$scope.data.shop_id}`;
      }

      $scope.modal_title_sub = $scope.data.shop_sale_name.substr(0, 100);
      $scope.data.isbn = row_data.isbn || '';
      $scope.data.isEdit = true;

      $scope.data.minor = $scope.data.adult_yn;
      if ($scope.data.made_date === null || $scope.data.made_date === '0000-00-00') {
        $scope.data.made_date = '';
      }

      if ($scope.data.expire_add_month) {
        // expire_add_month 가 52 인 경우 없음 인데 직접입력건도 해당값이 52로 들어오므로 expire_date 값이 있으면 직접입력으로 처리하기 위해 값 비움.
        if ($scope.data.expire_add_month && $scope.data.expire_date) {
          $scope.data.expiration_month = '';
        } else {
          $scope.data.expiration_month = String($scope.data.expire_add_month);
        }
      } else if ($scope.data.expiration_month) {
        $scope.data.expiration_month = '';
        // 유효일자가 모두 null 인경우에는 유효일자 없음으로 셋팅 2018-05-24 rony
        if ($scope.data.expire_add_month === null && $scope.data.expire_date === null) {
          $scope.data.expiration_month = '52';
        }
      } else {
        if ($scope.data.expire_date) {
          $scope.data.expiration_month = '';
          // $scope.data.expire_date = $scope.data.expire_date;
        } else {
          $scope.data.expiration_month = String($scope.data.expire_add_month);
        }
      }

      $scope.data.search_model = angular.copy(onlineProductSVC.models);
      $scope.data.typing_model = angular.copy(onlineProductSVC.models);

      angular.forEach(row_data.opt_sub_table, function(opt_prod) {

        opt_prod.opt_name1 = opt_prod.opt_name1 ? String(opt_prod.opt_name1).trim() : '';
        opt_prod.opt_name2 = opt_prod.opt_name2 ? String(opt_prod.opt_name2).trim() : '';
        opt_prod.opt_name3 = opt_prod.opt_name3 ? String(opt_prod.opt_name3).trim() : '';

        // if ($scope.data.main_prod_no == opt_prod.prod_no) {
        //   //대표상품을 가진 세트상품 구하기
        //   if (opt_prod.set_no) {
        //     main_set_no = opt_prod.set_no;
        //   }
        // }
      });
      $scope.data.main_prod_no = String($scope.data.main_prod_no);

      if ($scope.data.model_prod_no) {
        $scope.leadProdInfoModelno.prod_no = $scope.data.model_prod_no;
        $scope.modelProdMatch.checked = true;
      }

      // $scope.data.main_prod_no = String(main_set_no || $scope.data.main_prod_no);

      // DB에 저장되어있는 main_sale_opt_seq 값이 있다면 그대로 씀
      _.forEach(models, function (type) {
        let dataKey = '';

        if (_.includes($scope.data.search_input_type, type)) {
          dataKey = 'search_model';
          $scope.data[`search_${type}_yn`] = 1;
        } else {
          dataKey = 'typing_model';
          $scope.data[`search_${type}_yn`] = 0;
        }

        $scope.data[dataKey][type] = $scope.data[type];
      });

      if ($scope.data.search_input_type) {
        $scope.data.search_model.flag = true;
        $scope.data.search_model.product_brand = $scope.data.product_brand;
      }

      // 종합몰 브랜드
      if (row_data.pa_shop_cd === 'A011') {
        $scope.flag.marketFlag = true;
      }

      // 카테고리 이름 셋팅
      $scope.categoryName = $scope.data.sol_cate_name;

      // 상세설명
      if ($scope.data.detail_desc_img_yn) {
        $scope.data.desc_img_url = $scope.data.detail_desc;
        $scope.desc_img = $scope.data.detail_desc;
        $scope.data.detail_desc = '';
      }

      // 카테고리
      // 위메프의 경우 shop_cate2_cd ~ shop_cate5_cd가 없어 조건 추가
      const noneCateCd = ['B719', 'B579', 'B394', 'B700'].includes(row_data.pa_shop_cd);
      $scope.data.match_cate_name = row_data.shop_cate1;
      $scope.data.match_cate_name += (row_data.shop_cate2 && (noneCateCd || row_data.shop_cate2_cd)) ? ` > ${row_data.shop_cate2}` : '';
      $scope.data.match_cate_name += (row_data.shop_cate3 && (noneCateCd || row_data.shop_cate3_cd)) ? ` > ${row_data.shop_cate3}` : '';
      $scope.data.match_cate_name += (row_data.shop_cate4 && (noneCateCd || row_data.shop_cate4_cd)) ? ` > ${row_data.shop_cate4}` : '';
      $scope.data.match_cate_name += (row_data.shop_cate5 && (row_data.pa_shop_cd === 'B719' || row_data.pa_shop_cd === 'B394' || row_data.shop_cate5_cd)) ? ` > ${row_data.shop_cate5}` : '';
      $scope.data.match_sol_cate_name = _(row_data.sol_cate_shop_names).split(',').compact().join(' > ') || '매칭 카테고리 없음';

      // 다중 카테고리 지원 쇼핑몰들은 카테고리 구분자가 조금 틀림
      if (categorySVC.mutiCategoryShops.includes($scope.data.pa_shop_cd)) {
        const match_sol_cate = row_data.shop_cate_shop_add_info || row_data.sol_cate_shop_add_info;

        $scope.data.match_sol_cate_name = match_sol_cate && match_sol_cate.hasOwnProperty('category_names') ? match_sol_cate.category_names : '매칭 카테고리 없음';
        $scope.data.match_cate_name = row_data.shop_cate_no_add_info && row_data.shop_cate_no_add_info.hasOwnProperty('category_names') ? row_data.shop_cate_no_add_info.category_names : '';
      }

      if ($scope.data.match_cate_name === '임시') {
        $scope.data.match_cate_name = '';
      }

      if ($scope.data.pa_shop_cd === 'A112') {
        $scope.data.sol_cate_key_selPrdTypCd = !sol_cate_shop_add_info.key_selPrdTypCd;
        $scope.data.shop_cate_key_selPrdTypCd = !shop_cate_no_add_info.key_selPrdTypCd;
      }

      if ($scope.data.pa_shop_cd === 'A001' || $scope.data.pa_shop_cd === 'A006') {
        $scope.data.sol_cate_esm_match_cate_name = sol_cate_shop_add_info.catename_esm;
        $scope.data.shop_cate_esm_match_cate_name = shop_cate_no_add_info.catename_esm;
      }

      //해외배송
      if (row_data.foreign_ship_avail_yn) {
        $scope.data.over_delivery = 'Y';
        $scope.data.prod_weight = row_data.prod_weight;
      }

      $scope.data.prod_weight = row_data.prod_weight || undefined;

      //원산지
      if (row_data.madein_no) {
        if (row_data.madein_type === '원양산' && !['A077', 'Z000'].includes($scope.data.pa_shop_cd)) {
          $scope.data.madein_type = '';
          inValidTab[1] = false;
        }
        else {
          $scope.data.madein_type = row_data.madein_type;
          $scope.madein_group_list = $scope.madeinList[$scope.data.madein_type];
          $scope.data.madein_group = row_data.madein_group;
          $scope.madein_name_list = $scope.madeinList[$scope.data.madein_type][$scope.data.madein_group];
        }
        $scope.data.madein_name = row_data.madein_name;
      } else {
        $scope.data.madein_type = '기타';
        $scope.data.etcMadein = row_data.madein_etc;
      }

      // 세트상품 아닌 옵션 추출
      const opt_list = _.filter(row_data.opt_sub_table, { set_no: null });

      // 세트상품 옵션 추출
      // 세트넘버로 묶어서 메인상품에 나머지 옵션상품 정보모아서 합침
      const set_opt_list = _(row_data.opt_sub_table)
        .filter('set_no')
        .groupBy('sale_opt_seq')
        .map(function(setOptList) {
          //세트상품의 대표상품이 변경될 경우 오류
          let mainOpt = _.find(setOptList, function(opt) { return opt.prod_no === opt.main_prod_no; });

          // 세트상품 잘못매칭된 케이스 온라인 상품 상세 열리기는 해야하므로 임시처리
          if (!mainOpt) {
            mainOpt = setOptList[0];
          }

          mainOpt.prod_name = mainOpt.set_name || mainOpt.prod_name;
          // $scope.data.options.prodList의 원소에서 set_prod_list 순환참조 에러 발생하여 임시 주석함.
          // 백엔드에서 사용하지 않는 것으로 보이지만 다니엘이 수정하신 내용이라 추후 확인 필요할 듯.
          mainOpt.set_prod_list = angular.copy(setOptList);
          mainOpt.sku_cd = mainOpt.set_cd;
          mainOpt.prod_img = mainOpt.set_img || mainOpt.prod_img;

          return mainOpt;
        })
        .value();

      //상품 옵션 리스트 정리
      //위의 세트상품 옵션 추출~상품 옵션 리스트 정리 처리때문에 순서가 바뀜 -> sale_opt_seq 기준으로 오름차순 정렬
      row_data.opt_sub_table = _(opt_list)
        .concat(set_opt_list)
        .sortBy('sale_opt_seq')
        .value();

      // 세트상품 아닌 추가구매옵션 추출
      const add_opt_list = _.filter(row_data.add_opt, { set_no: null });

      // 세트상품 추가구매옵션 추출
      // 세트넘버로 묶어서 메인상품에 나머지 옵션상품 정보모아서 합침
      const set_add_opt_list = _(row_data.add_opt)
        .filter('set_no')
        .groupBy('sale_opt_seq')
        .map(function(setOptList, seq) {
          //세트상품은 sku 대신 set_no 사용
          const uniq_add_set = _.find(setOptList, { sale_opt_seq: parseInt(seq) });

          uniq_add_set.set_prod_list = angular.copy(setOptList);
          uniq_add_set.sku_cd = uniq_add_set.set_cd;

          return uniq_add_set;
        })
        .value();

      //상품 추가구매옵션 리스트 정리
      row_data.add_opt = _.concat(add_opt_list, set_add_opt_list);

      //위의 세트상품 옵션 추출~상품 옵션 리스트 정리 처리때문에 순서가 바뀜 -> sale_opt_seq 기준으로 오름차순 정렬
      row_data.add_opt.sort(function(a, b) {
        return b.sale_opt_seq < a.sale_opt_seq ? 1 : -1;
      });

      //세트상품이 대표상품인 경우
      if (main_set_no) {
        _.forEach(row_data.opt_sub_table, function(opt_prod) {
          if (opt_prod.set_no == main_set_no) {
            opt_prod.prod_no = $scope.data.main_prod_no;
          }
        });
      }

      $scope.data.checkGrowth.coupang = row_data.opt_sub_table.some(opt => opt.deal_type === '기초상품');
      $scope.data.checkGrowth.rocket = row_data.opt_sub_table.some(opt => opt.deal_type === '3자배송');

      //SKU상품 옵션
      if ($scope.data.opt_type == '옵션없음') {
        // 로켓 그로스 상품일 경우 쿠팡, 로켓그로스의 각각 매칭정보를 coupang, rocket에 담아줌
        if ($scope.data.checkGrowth.available) {
          // 로켓그로스만 등록인 경우 옵션 데이터 deal_type = 3자배송 밖에 없기 때문에 해당 값으로 옵션 테이블 만들어줌
          const optData = {};
          let prodImg = '/assets/images/upload.png';

          ['coupang', 'rocket'].forEach(shop => {
            optData[shop] = row_data.opt_sub_table.find(opt => opt.deal_type === (shop === 'coupang' ? '기초상품' : '3자배송')) || {};

            optData[shop].deal_type = optData[shop].deal_type || '기초상품';
            optData[shop].safe_stock = optData[shop].stock_cnt_safe;
            optData[shop].cnt_limit = optData[shop].sale_opt_cnt_limit;
            optData[shop].prod_weight = optData[shop].opt_weight;
            optData[shop].prod_status = optData[shop].opt_status || '정상';

            if (optData[shop].prod_img) {
              prodImg = optData[shop].prod_img;
            }

          });
          optData.prod_img = prodImg;

          $scope.prodOpt.matchStockList[0] = angular.copy(optData);
        } else {
          // 3자배송인 경우 옵션 제외
          for (const i in row_data.opt_sub_table.filter(opt => opt.deal_type === '기초상품')) {
            const opt_sub_table = row_data.opt_sub_table[i];

            $scope.prodOpt.matchStockList[i] = opt_sub_table;
            $scope.prodOpt.matchStockList[i].stock_cnt = opt_sub_table.stock_cnt;
            $scope.prodOpt.matchStockList[i].safe_stock = opt_sub_table.stock_cnt_safe;
            $scope.prodOpt.matchStockList[i].cnt_limit = opt_sub_table.sale_opt_cnt_limit;
            $scope.prodOpt.matchStockList[i].prod_weight = opt_sub_table.opt_weight;
            $scope.prodOpt.matchStockList[i].prod_status = opt_sub_table.opt_status || '정상';
            $scope.prodOpt.matchStockList[i].prod_name = opt_sub_table.prod_name;
            if (opt_sub_table.set_no) {
              // 세트상품가격이 있는경우 세트상품가격 출력 2019-04-29 rony
              $scope.prodOpt.matchStockList[i].sale_price = opt_sub_table.set_sale_price || '';
            } else {
              $scope.prodOpt.matchStockList[i].sale_price = opt_sub_table.sale_price || '';
            }
          }
        }

        await categoryOptions(function () {
          $scope.ESM_loaded = true;
          $scope.Aliexpress_loaded = true;
          $scope.Alibaba_loaded = true;
          $scope.Aliexpress_Global_loaded = true;
          if (!$scope.sku_list) {
            prodListSet();
          }
          makeDisabledFields();
        }, false);

        if ($scope.data.pa_shop_cd === 'B394') {
          getGalleriaCategory();
        }

        if ($scope.data.pa_shop_cd === 'A077' && !$scope.data.std_ol_yn) {
          getNaverCategory();
        }

      } else { //조합 혹은 독립
        $scope.prodOpt.opt[0].name = row_data.opt_sort1 || '';
        $scope.prodOpt.opt[1].name = row_data.opt_sort2 || '';
        $scope.prodOpt.opt[2].name = row_data.opt_sort3 || '';
        $scope.prodOpt.opt[0].attr = row_data.opt_sort1_desc || '';
        $scope.prodOpt.opt[1].attr = row_data.opt_sort2_desc || '';
        $scope.prodOpt.opt[2].attr = row_data.opt_sort3_desc || '';
        $scope.prodOpt.opt[0].name_cd = row_data.opt_sort1_cd || '';
        $scope.prodOpt.opt[1].name_cd = row_data.opt_sort2_cd || '';
        $scope.prodOpt.opt[2].name_cd = row_data.opt_sort3_cd || '';
        $scope.prodOpt.opt[0].unit = row_data.opt_unit1 || '';
        $scope.prodOpt.opt[1].unit = row_data.opt_unit2 || '';
        $scope.prodOpt.opt[2].unit = row_data.opt_unit3 || '';

        //ESM인 경우 추천옵션 가져오기
        categoryOptions(function() {
          const prodOpt = ($scope.data.std_ol_yn && !['B719', 'A001', 'A006', 'A112', 'A092', 'A093', 'A077', 'X094'].includes($scope.data.pa_shop_cd)) ? _.filter($scope.prodOpt.esm_opt, 'checked') : $scope.prodOpt.opt,
                data = { opt: angular.copy(prodOpt) };

          $timeout(function() {
            // 옵션 생성 테이블에 옵션 값이 있는경우
            //상품 상세 옵션 기본값
            for (const opt of data.opt) {
              if (opt.typeCd === 'N') {
                const cateOp = opt.cateOpAttrList.find(op => Number(op.stdOptValNo) === Number(opt.stdOpt));
                const optList = opt.attr.split(',');
                opt.attr_text = optList.map(opt => `${opt}${cateOp.stdOptValNm}`).join();
              }
            }

            let optSubTable = row_data.opt_sub_table;

            if ($scope.data.checkGrowth.available) {
              // 로켓그로스만 등록인 경우 옵션 데이터 deal_type = 3자배송 밖에 없기 때문에 해당 값으로 옵션 테이블 만들어줌
              if ($scope.data.checkGrowth.coupang) {
                optSubTable = optSubTable.filter(opt => opt.deal_type === '기초상품');
              } else if ($scope.data.checkGrowth.rocket) {
                optSubTable = optSubTable.filter(opt => opt.deal_type === '3자배송');
              }
            }

            $scope.prodOpt.options_table = onlineProductSVC.initOptionsTable({ ...data, std_ol_yn: $scope.data.std_ol_yn }, $scope.data.opt_type, optSubTable);

            if ($scope.data.checkGrowth.available) {
              $scope.prodOpt.options_table.forEach(opt => {
                const matchOpts = row_data.opt_sub_table.filter(o => o.opt_name1 === opt.attr1 && o.opt_name2 === opt.attr2 && o.opt_name3 === opt.attr3);

                for (const optData of matchOpts) {
                  optData.prod_status = optData.opt_status || '정상';
                  optData.prod_img = optData.prod_img || '/assets/images/upload.png';
                  optData.cnt_limit = optData.sale_opt_cnt_limit;
                  optData.safe_stock = optData.stock_cnt_safe || data.safe_stock_default;
                  optData.prod_weight = optData.opt_weight;

                  opt[optData.deal_type === '기초상품' ? 'coupang' : 'rocket'] = angular.copy(optData);
                }

                if (!opt.coupang) {
                  opt.coupang = { prod_img: '/assets/images/upload.png', sku_cd: '', prod_status: '정상' };
                  // coupang이 없다는건 매칭된 sku가 없다는거라 null로 넣음
                  opt.set_no = null;
                  opt.sku_cd = null;
                  opt.prod_no = null;
                }
                if (!opt.rocket) {
                  opt.rocket = { prod_img: '/assets/images/upload.png', sku_cd: '', cnt_limit: 0, prod_status: '정상' };
                }
              });
            }

            $scope.prodOpt.options_table.forEach(item => item.initTable = true);
            $scope.orginProdOptionTable = angular.copy($scope.prodOpt.options_table);
            $scope.totalLoadCnt = Math.ceil($scope.orginProdOptionTable.length / $scope.OPT_LIST_LIMIT);
            // view 에서 옵션 노출을 위해 옵션 구분 갯수 설정 2018-09-13 rony
            $scope.optTypeCnt = 0;
            optionAttrCheck();

            // 테이블을 다 그려주고 로딩이 끝나야 하기 때문에 ESM_loaded = true를 여기에 넣어줌
            $scope.ESM_loaded = true;

            if (!$scope.sku_list) {
              prodListSet();
            }

            $scope.data.options_table = angular.copy($scope.orginProdOptionTable);
            $scope.loadOptList(true);
            onlineProductModel.setOld(angular.copy($scope.data));

            if (['A001', 'A006'].includes($scope.data.pa_shop_cd) && $scope.data.opt_type !== '옵션없음') {
              for (let i = 0; i < 3; i++) {
                if ($scope.prodOpt.opt[i].name_cd && $scope.prodOpt.esm_opt[i]?.cateOpAttr?.filter(o => o.ObjOptEssenName)?.length) {
                  $scope.prodOpt.opt[i].attr = $scope.prodOpt.opt[i].attr.split(',');
                  const cateOpt = $scope.prodOpt.esm_opt[i].cateOpAttr.filter(o => o.ObjOptEssenName);
                  $scope.selectOptList[i] = [
                    ...cateOpt,
                    ...$scope.prodOpt.opt[i].attr
                      .filter(value => !cateOpt.some(item => item.ObjOptEssenName === value))
                      .map(opt => { return { ObjOptEssenName: opt }; })
                  ];
                }
              }
            }

            const trnslData = localStorageService.get(`olShop_TrnslData_${$scope.data.ol_shop_no}`);

            if (trnslData) { // 로컬 스토리지에 저장된 번역 데이터가 있을 경우 반영
              applyTrnslData(trnslData);
            }
          }, 0);

          makeDisabledFields();
        }, false);

        if ($scope.data.pa_shop_cd === 'B394') {
          getGalleriaCategory();
        }

        if ($scope.data.pa_shop_cd === 'A077' && !$scope.data.std_ol_yn) {
          getNaverCategory();
        }
      }

      if ($rootScope.user_profile.sol_stock < 1) {
        if ($scope.data.depot_no) {
          // 재고 미사용의 경우 배송처 사용여부 확인
          if ($scope.warehouseList.find(o => o.code === $scope.data.depot_no)) {
            $scope.data.depot_no = String($scope.data.depot_no);
          } else {
            $scope.data.depot_no = '';
          }
        }
      }

      // 쇼핑몰 정보
      $scope.data.shop_name = siteInfo[$scope.data.shop_cd].name;
      $scope.data.add_opt_use_yn = !!siteInfo[$scope.data.shop_cd].add_opt_use_yn;                  // 추가구매 옵션 사용여부
      $scope.data.input_opt_use_yn = !!siteInfo[$scope.data.shop_cd].input_opt_use_yn;              // 입력형 옵션 사용여부
      $scope.data.independent_opt_use_yn = !!siteInfo[$scope.data.shop_cd].independent_opt_use_yn;  // 독립형 옵션 사용여부
      $scope.data.model_search_avail_yn = !!siteInfo[$scope.data.shop_cd].model_search_avail_yn;    // 모델 검색 지원여부
      $scope.data.brand_search_avail_yn = !!siteInfo[$scope.data.shop_cd].brand_search_avail_yn;    // 브랜드 검색 지원여부
      $scope.data.maker_search_avail_yn = !!siteInfo[$scope.data.shop_cd].maker_search_avail_yn;    // 제조사 검색 지원여부

      // 2017. 10. 19 박현도
      // 쇼핑몰 상품 관리 - 상세 - 메모 기능 추가를 위한 객체
      $scope.data.memo = {
        uniq: $scope.data.ol_shop_no,
      };

      // 메모수정여부
      $scope.data.memoUpdate = false;

      // 판매수량
      $scope.data.prod_stock = row_data.sale_cnt_limit;

      // 홍보문구 규칙 적용
      $scope.data.shop_promotion_name_role = onlineProductSVC.promotionNameRole($scope.data.pa_shop_cd);

      // 상품정보 고시
      const prod_info = [];
      const prod_info_new = [];

      prod_info[0] = $scope.data.prod_noti_data1;
      prod_info[1] = $scope.data.prod_noti_data2;
      prod_info[2] = $scope.data.prod_noti_data3;

      prod_info_new[0] = $scope.data.prod_noti_data1_new;
      prod_info_new[1] = $scope.data.prod_noti_data2_new;
      prod_info_new[2] = $scope.data.prod_noti_data3_new;

      temp_list_new = angular.copy(prod_info_new);
      $scope.data.prod_noti_data = prod_info;
      $scope.data.prod_noti_data_new = prod_info_new;
      //상품정보제공고시 확인(없어도 상세열리게)
      if (prod_info[0]) {
        $scope.data.prod_info_opt = prod_info[0].infoName || '';
      }
      if (prod_info_new[0]) {
        $scope.data.prod_info_opt_new = prod_info_new[0].infoName || '';
      }

      //인증정보
      $scope.data.cert_info.cert_type = row_data.cert_type;
      $scope.data.cert_info.cert_ministry = row_data.cert_ministry;
      $scope.data.cert_info.cert_no = row_data.cert_no;
      $scope.data.cert_info.cert_model = row_data.cert_model;
      $scope.data.cert_info.cert_cname = row_data.cert_cname;
      $scope.data.cert_info.default_cert = row_data.cert_cd;
      $scope.data.cert_info.cert_date = row_data.cert_date;
      $scope.data.cert_info.cert_expire_date = row_data.cert_expire_date;

      // 이미지 정리
      // var ii = 0;
      angular.forEach($scope.data.img_objects, function(v, k) {
        // v.name = $scope.data['img_name'+k] || "추가이미지"+ii;
        v.files = $scope.data[`sale_img${k + 1}`] ? $scope.data.sale_img_url + $scope.data[`sale_img${k + 1}`] : '';
        v.old_files = $scope.data[`sale_img${k + 1}`] ? $scope.data.sale_img_url + $scope.data[`sale_img${k + 1}`] : '';
        // ii++;
      });

      /**
       * 2018-05-02 roy
       * 쇼핑몰별 추가이미지 서비스로 뺌
       */
      $scope.data.additional_images = onlineProductSVC.sub_img_box[$scope.data.pa_shop_cd] ? onlineProductSVC.sub_img_box[$scope.data.pa_shop_cd]($scope.data) : [];

      $scope.input_opt_max = onlineProductSVC.inputOptLength($scope.data.pa_shop_cd, $scope.data.std_ol_yn);
      // 옵션 입력형
      if ($scope.data.input_opt_name) {
        const input_opt_name = JSON.parse($scope.data.input_opt_name);

        // 2018-11-23 chris 위메프 2.0은 입력형 옵션 사용
        if (!$scope.data.std_ol_yn || ['A112', 'B719', 'B502', 'P020', 'A001', 'A006'].includes($scope.data.pa_shop_cd)) {
          $scope.data.insert_opt = true;
          $scope.data.insert_opt_arr = input_opt_name;
        }
      }

      /**
       * 100바이트 조건이 아니라 저장할때 검사하기 위한 변수, 메세지
       *
       * 2020-01-09기준 걸려야하는 조건들
       * 0: esm2.0 상품명 + 프로모션명 100바이트
       * 1: esm 일반 상품명 50바이트
       * 2: 위메프 2.0 상품명 70자
       */
      $scope.prod_name_check = false;
      $scope.prod_promotion_check = false;
      $scope.prod_name_check_msg = '';
      $scope.prod_promotion_check_msg = '';

      /**
       * 추가구매옵션 값정리
       */
      $scope.prodOpt.add_opt = [];
      if (row_data.add_opt.length) {
        $scope.data.add_opt_type = 'Y';

        for (const k in row_data.add_opt) {
          const add_opt_data = {};
          const add_options = {};
          let over_flag = -1;

          //추가 항목명, 추가 옵션명, 추가 금액 테이블 정리
          angular.forEach($scope.prodOpt.add_opt, function(row, key) {
            if (row.name == row_data.add_opt[k].add_item_name) {
              over_flag = key;

              return false;
            }
          });

          if (over_flag > -1) {
            $scope.prodOpt.add_opt[over_flag].opPrice += `,${row_data.add_opt[k].opt_add_price}`;
            $scope.prodOpt.add_opt[over_flag].opName += `,${row_data.add_opt[k].add_item_opt_name}`;
          } else {
            add_options.name = row_data.add_opt[k].add_item_name;
            add_options.opName = row_data.add_opt[k].add_item_opt_name;
            add_options.opPrice = String(row_data.add_opt[k].opt_add_price) || '0';
          }
          //추가 항목명, 추가 옵션명, 추가 금액 테이블 정리 끝

          add_opt_data.prod_no = row_data.add_opt[k].prod_no;
          add_opt_data.name = row_data.add_opt[k].add_item_name;
          add_opt_data.opName = row_data.add_opt[k].add_item_opt_name;
          add_opt_data.sku_cd = row_data.add_opt[k].sku_cd;
          add_opt_data.opPrice = row_data.add_opt[k].opt_add_price || 0;
          add_opt_data.pack_unit = row_data.add_opt[k].pack_unit;
          add_opt_data.stock_cnt = row_data.add_opt[k].stock_cnt;
          add_opt_data.safe_stock = row_data.add_opt[k].stock_cnt_safe;
          add_opt_data.depot_no = row_data.add_opt[k].depot_no;
          add_opt_data.depot_name = row_data.add_opt[k].depot_name;
          add_opt_data.cnt_limit = row_data.add_opt[k].sale_opt_cnt_limit;
          add_opt_data.A006_cnt_limit = row_data.add_opt[k].A006_cnt_limit;
          add_opt_data.A001_cnt_limit = row_data.add_opt[k].A001_cnt_limit;
          add_opt_data.prod_weight = row_data.add_opt[k].opt_weight;
          add_opt_data.prod_status = row_data.add_opt[k].opt_status || '정상';
          add_opt_data.set_no = row_data.add_opt[k].set_no;
          add_opt_data.set_cd = row_data.add_opt[k].set_cd;
          add_opt_data.set_prod_list = row_data.add_opt[k].set_prod_list;
          add_opt_data.sale_opt_seq = row_data.add_opt[k].sale_opt_seq;
          add_opt_data.model_no = row_data.add_opt[k].model_no || null;

          $scope.prodOpt.add_opt_table.push(add_opt_data);
          if (add_options.name) {
            $scope.prodOpt.add_opt.push(add_options);
          }
        }
      } else {
        $scope.data.add_opt_type = 'N';
      }
      $scope.originAddOptTable = [...$scope.prodOpt.add_opt_table];

      // 머리말/꼬리말
      $scope.data.addcontent_no = row_data.addcontent_no;
      $scope.data.addcontent_name = `[${row_data.addcontent_type}]${row_data.addcontent_name}`;
      $scope.data.shop_sale_name_addcontent_no = row_data.shop_sale_name_addcontent_no;
      $scope.data.shop_sale_name_addcontent_name = `[${row_data.shop_sale_name_addcontent_type}]${row_data.shop_sale_name_addcontent_name}`;

      // 상세설명 이미지 제한사항
      $scope.data.desc_img_limit = onlineProductSVC.descImgShopMap[$scope.data.pa_shop_cd];

      // 쇼핑몰 정보
      // 기본값 추가 2018-02-01 chris
      $scope.data.set_info = JSON.parse($scope.data.set_info) || {};

      if ($scope.data.pa_shop_cd === 'A077' && $scope.data.set_info.is_standard_option_category === 'Y') {
        if ($scope.data.opt_type !== '옵션없음') {
          $scope.data.opt_type = (row_data.opt_sort1_cd && row_data.opt_sort2_cd) ? '간편옵션' : '조합형';
        }

        $scope.data.set_info.is_standard_option_category = $scope.data.opt_type === '간편옵션' ? 'Y' : 'N';
      }

      // 29cm의 경우 상세설명 이미지 설정이 필요한데 빈 배열로 들어가는 경우가 있어 수정시 설정 보이도록 추가해줌
      if ($scope.data.pa_shop_cd === 'P015' && !$scope.data.set_info.add_desc_info?.image_info?.length) {
        if (!$scope.data.set_info.add_desc_info) {
          $scope.data.set_info.add_desc_info = {
            content_flag: 'F',
            content_info: [{}],
            image_info: [{}]
          };

        } else {
          $scope.data.set_info.add_desc_info.image_info = [{}];
        }
      }

      ori_opt_type = $scope.data.opt_type;
      // 템플릿 API 초기화
      templeteLoad();

      if ($scope.data.shop_cd !== 'Z000') {
        await $scope.templateInit();
      }

      // 템플릿 코드
      $scope.data.template_no = angular.copy($scope.data.template_no);

      //템플릿 요약정보 가져오기
      // template_table($scope.data.shop_cd, $scope.data.template_no, userInfo.user.sol_no);
      // templeteLoad($scope.data.shop_cd, json_data.site_id);
      // tooltip
      $timeout(function() {
        $('[data-popup="tooltip"]').tooltip();

        // 원본데이터에 초기 옵션테이블 데이터 세팅(로그용)
        $scope.data.options_table = $scope.data.opt_type === '옵션없음' ? angular.copy($scope.prodOpt.matchStockList) : angular.copy($scope.prodOpt.options_table);
        $scope.data.add_opt_table = angular.copy($scope.originAddOptTable);

        // 빈값이 디비에서는 '0000-00-00'로 오고있어서 초기값 저장시 빈 문자열로 치환해줌
        _.forEach($scope.data.certDataList, (cert) => {
          cert.cert_date = cert.cert_date === '0000-00-00' ? '' : cert.cert_date;
          cert.cert_expire_date = cert.cert_expire_date === '0000-00-00' ? '' : cert.cert_expire_date;
        });

        onlineProductModel.setOld(angular.copy($scope.data));

        prodNameValid(true);
      }, 100);

      $scope.data.site_list[0].match_cate_code = sol_cate_shop_add_info.category_codes;

      // 독립형 옵션인데 독립형 옵션을 사용할 수 없는 쇼핑몰인 경우 안내문 출력
      if ($scope.data.opt_type === '독립형') {
        const { data: shopList } = await commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/shop/not-available-independ-opt`);

        if (shopList.includes($scope.data.pa_shop_cd)) {
          commonSVC.showMessage('주의', '해당 쇼핑몰은 독립형 옵션을 사용할 수 없는 쇼핑몰입니다.');
        }
      }

      //프로모션, 홍모문구등 있는지 확인
      promotionNameCheck();
    };

    // 번역모달 오픈
    $scope.openTranslateModal = async () => {
      const data = {
        shop_sale_name: $scope.data.shop_sale_name,
        options: $scope.prodOpt.options_table,
        model: $scope.data.typing_model.model,
        brand: $scope.data.typing_model.brand,
        made: $scope.data.typing_model.maker,
        detail_desc: $scope.data.detail_desc
      };

      const trnslRes = await commonSVC.openModal('', { data }, 'prodTranslate', 'views/online/product/modals/prod_translate.html').result;

      if (trnslRes.success) {
        await applyTrnslData({ translate_data: trnslRes.result });

        const opt1_names = [],
              opt2_names = [],
              opt3_names = [];

        // 옵션 매칭 테이블 데이터도 수정된 옵션 테이블에 맞춰서 매칭
        for (const opt of $scope.prodOpt.options_table) {
          if (opt.attr1_title && !opt1_names.includes(opt.attr1_title)) {
            opt1_names.push(opt.attr1_title);
          }
          if (opt.attr2_title && !opt2_names.includes(opt.attr2_title)) {
            opt2_names.push(opt.attr2_title);
          }
          if (opt.attr3_title && !opt3_names.includes(opt.attr3_title)) {
            opt3_names.push(opt.attr3_title);
          }
        }

        $scope.prodOpt.opt[0].attr = opt1_names.join(',');
        $scope.prodOpt.opt[1].attr = opt2_names.join(',');
        $scope.prodOpt.opt[2].attr = opt3_names.join(',');

        const tmpData = {
          translate_data: trnslRes.result,
          options_table: angular.copy($scope.prodOpt.options_table),
          opt: $scope.prodOpt.opt
        };

        tmpData.options_table.forEach(opt => {
          opt.opt_name1 = opt.attr1;
          opt.opt_name2 = opt.attr2;
          opt.opt_name3 = opt.attr3;
          opt.opt_name1_cd = opt.attr1_cd;
          opt.opt_name2_cd = opt.attr2_cd;
          opt.opt_name3_cd = opt.attr3_cd;
          opt.sale_opt_cnt_limit = opt.cnt_limit;
          opt.opt_weight = opt.prod_weight;
          opt.opt_status = opt.prod_status;
        });

        localStorageService.set(`olShop_TrnslData_${$scope.data.ol_shop_no}`, tmpData);

        commonSVC.showToaster((trnslRes.fail ? 'error' : 'success'), (trnslRes.fail ? '실패' : '성공'), trnslRes.state_msg || '번역이 완료되었습니다.<br>하단의 [수정]버튼을 클릭하여 상품 정보를 저장해 주세요.');
      } else {
        commonSVC.showToaster('error', '실패', trnslRes.state_msg);
      }
    },

    /**
     * 프로모션명 타이틀
     */
    $scope.getPromotionTitle = () => onlineProductSVC.getPromotionTitle($scope.data.pa_shop_cd);

    function prodListSet() {
      const prodOpt = angular.copy($scope.prodOpt);

      if ($scope.data.checkGrowth.available) {
        for (const table of ['matchStockList', 'options_table']) {
          let seq = 1;

          prodOpt[table] = ['coupang', 'rocket'].flatMap(shop => {
            return prodOpt[table].flatMap((opt) => {
              const optData = _.omit({ ...Object.assign({}, opt, opt[shop]), deal_type: shop === 'coupang' ? '기초상품' : '3자배송' }, ['coupang', 'rocket']);

              if (
                ($scope.data.checkGrowth.coupang && optData.deal_type === '기초상품') ||
                    ($scope.data.checkGrowth.rocket && optData.deal_type === '3자배송')
              ) {
                optData.sale_opt_seq = seq++;

                return optData;
              }
            });
          }).filter(Boolean);
        }

      }

      const all_prod_list = _.concat(prodOpt.matchStockList, prodOpt.options_table),
            sku_list = _(all_prod_list)
              .filter(function (f_row) {
                return f_row && f_row.sku_cd && f_row.sku_cd != '';
              })
              .uniqBy('sku_cd')
              .value();

      if (!_.find(sku_list, { prod_no: parseInt($scope.data.main_prod_no) })) {
        $scope.data.main_prod_no = '';
      }
      $scope.sku_list = sku_list;
    }
    /**
     * 기존 선택된 추천 옵션 필터링
     */
    $scope.isSelected = function(idx, attr) {
      const attArr = $scope.prodOpt.opt[idx].attr.split(',');
      const attArr2 = [];

      if (_.isArray(attArr) && attArr.length > 0) {
        _.each(attArr, function(row) {
          attArr2.push(row.trim());
        });
      }

      return !!(attArr2.indexOf(attr) != -1);
    };

    /**
     * 옵션 초기화
     */
    $scope.optReset = async () => {
      $scope.data.main_prod_no = '-1';
      $scope.prodOpt.options_table = [];
      $scope.orginProdOptionTable = [];

      if ($scope.data.std_ol_yn && !['B719', 'A001', 'A006', 'A112', 'A092', 'A093', 'A077', 'X094'].includes($scope.data.pa_shop_cd)) {
        $scope.prodOpt.opt[0].attr = '';
        $scope.prodOpt.opt[1].attr = '';
        $('.attrs').tagsinput('removeAll');
        $('.esm_opt').val('').trigger('change');
      } else if ($scope.data.pa_shop_cd == 'B394') {
        for (const i in $scope.prodOpt.opt) {
          if (!$scope.prodOpt.opt[i].isRequired) {
            $scope.prodOpt.opt[i].name = $scope.prodOpt.opt[i].attr = $scope.prodOpt.opt[i].unit = '';
            $(`.attrs${i}`).tagsinput('removeAll');
          }
        }
        _.each($scope.data.galleria_options, function(row) {
          row.checked = false;
        });
      } else {
        $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
        $('.attrs').tagsinput('removeAll');
        $('.attrs0').tagsinput('removeAll');
        $('.attrs1').tagsinput('removeAll');
        $('.attrs2').tagsinput('removeAll');
        if ($scope.data.pa_shop_cd == 'A077' && $scope.possible_naver_opt && !$scope.data.std_ol_yn) {
          getNaverCategory();
        }

        // ESM 2.0, 알리 옵션 초기화
        if ($scope.data.std_ol_yn && ['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
          $scope.prodOpt.esm_opt = angular.copy(onlineProductSVC.default_opt);
        } else if ($scope.data.pa_shop_cd === 'A092') {
          $scope.prodOpt.alibaba_opt = angular.copy(onlineProductSVC.default_opt);
        } else if ($scope.data.pa_shop_cd === 'A093') {
          $scope.prodOpt.aliexpress_opt = angular.copy(onlineProductSVC.default_opt);
        } else if ($scope.data.pa_shop_cd === 'A077') {
          $scope.prodOpt.naver_single_opt = angular.copy(onlineProductSVC.default_opt);
        } else if ($scope.data.pa_shop_cd === 'X094') {
          $scope.prodOpt.aliexpress_global_opt = angular.copy(onlineProductSVC.default_opt);
        }
      }
    };

    $scope.optionSelect = function (option, row, $event) {
      const target = $($event.target);
      let mode;

      if (target.hasClass('option-div-clicked')) {
        mode = 'remove';
        target.removeClass('option-div-clicked');
      } else {
        mode = 'add';
        target.addClass('option-div-clicked');
      }

      $(`#${$.escapeSelector(`opt${row}`)}`).tagsinput(mode, option);
    };

    $scope.isCheckOpt = function (text, row) {
      const optVal = $(`#${$.escapeSelector(`opt${row}`)}`).val() || '';

      return optVal.split(',').includes(text);
    };

    $scope.optionTable = function (row, $event) {
      if ($('#popup-div')[0]) {
        $('#popup-div').remove();
        if (currentRow === row.name) {
          return;
        }
      }
      currentRow = row.name;

      const optArr = [];
      const appendBody = $($event.target.parentElement);
      const templete = `
        <div id="popup-div" style="width: 520px; height: 340px; background-color: lightgray; position: absolute; z-index: 99999; box-shadow: 0px 1px 3px 0px;">
          <div class="col-xs-12" style="text-align: left; line-height: 42px">
            <a style="color: black; font-size: 18px">구매 옵션 예시(최대 50개 선택 가능)</a>
          </div>  
          <div class="col-xs-12" style="height: 250px; background-color: white; overflow-y: auto;">
            
          <table class="table mt-5 mb-5">
            <tbody>
              <tr ng-repeat="line in optMarr" height="40">
                <td ng-repeat="text in line" ng-class="{'option-div-clicked': isCheckOpt(text, '${$scope.prodOpt.esm_checked_opt.findIndex(e => e.name === currentRow)}')}" ng-click="optionSelect(text, '${$scope.prodOpt.esm_checked_opt.findIndex(e => e.name === currentRow)}', $event)" class="option-div">
                  {{text}}
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <div class="col-xs-12 mt-9" style="text-align: left; line-height: 15px">
            <a style="color: black;">*옵션값은 최대 50byte (한글기준 25자, 영문/숫자 기준 50자)까지 등록 가능합니다.</a>
            </br>
            <a style="color: black;">*등록된 옵션값은 관리자에 의해 모니터링 됩니다.</a>
          </div>
        </div>
          `;

      for (const opt of row.cateOpAttrList) {
        optArr.push(opt.stdOptValNm);
      }

      const optMArr = [[]];
      let arrLength = 0;

      for (let i = 0; i < optArr.length; i++) {
        if (i && i % 4 === 0) {
          optMArr.push(
            []
          );
          arrLength++;
        }
        optMArr[arrLength].push(optArr[i]);
      }

      $scope.optMarr = optMArr;

      $($($event.target.parentElement)).append($compile(templete)($scope));

      const margin = (appendBody[0].offsetWidth - appendBody.children('button')[0].offsetWidth) / 2;
      const popupDiv = $('#popup-div');

      popupDiv.css('left', popupDiv.position().left - (popupDiv.width() - $($event.target.parentElement).width() - margin));
    };

    /**
     * 현재 옵션 추출
     */
    $scope.optGetTables = function() {
      const options_table = angular.copy(($scope.orginProdOptionTable && ($scope.orginProdOptionTable.length > $scope.OPT_LIST_LIMIT) && $scope.prodOpt.options_table.length !== $scope.orginProdOptionTable.length) ? $scope.prodOpt.options_table.concat($scope.orginProdOptionTable.slice($scope.prodOpt.options_table.length)) : ($scope.prodOpt.options_table || []));
      const opt_value = ($scope.data.std_ol_yn == 1 && !['B719', 'A001', 'A006', 'A112', 'A092', 'A093', 'A077', 'X094'].includes($scope.data.pa_shop_cd)) ? $scope.prodOpt.esm_opt : $scope.prodOpt.opt;

      if ($scope.data.opt_type !== '옵션없음' && !options_table.length) {
        commonSVC.showMessage('옵션 생성이 되어있지 않습니다.', '옵션이 없는 상품인 경우 "옵션 없음" 으로 체크해 주시고,\n옵션이 있는 상품인 경우 "옵션명"과 "옵션값" 확인 후 [옵션생성] 버튼을 눌러주세요.');
        $scope.changeTab(2);
        $scope.flag.isOpen = 'true';

			  return false;
      }

      if (['조합형', '간편옵션'].includes($scope.data.opt_type)) {
        // 중복 옵션 확인
        if (options_table.some((row, index, arr) => arr.some((opt, optIndex) => index !== optIndex
          && row.attr1 === opt.attr1
          && (row.name2 ? row.attr2 === opt.attr2 : true)
          && (row.name3 ? row.attr3 === opt.attr3 : true)
        ))) {
          commonSVC.showMessage('중복 옵션이 있습니다. 옵션을 수정해주세요.');
          $scope.changeTab(2);
          $scope.flag.submit = false;

          return false;
        }

        if ($scope.data.pa_shop_cd === 'B394') {
          // 갤러리아 옵션추출 불가능할때 특수처리
          const selectableOptions = [...new Set($scope.data.galleria_options.map(({ name }) => name))];
          const indices = ['name1', 'name2', 'name3'].map((name) => selectableOptions.includes(options_table[0][name]) ? selectableOptions.indexOf(options_table[0][name]) : -1);

          // 옵션명이 있는데 선택가능한 옵션명이 아닐경우 실패
          if (indices.some((idx, i) => options_table[0][`name${i + 1}`] && idx === -1)) {
            commonSVC.showMessage('옵션 추출 실패', '선택된 카테고리에서 지원되지 않는 옵션명이 존재합니다.\n상단의 선택 가능 옵션을 확인하시어 재생성 해주세요.');

            return;
          } else {
            indices.forEach((idx) => {
              if (idx > -1) {
                $scope.data.galleria_options[idx].checked = true;
                $scope.prodOpt.opt[0].custom_able = $scope.data.galleria_options[idx].custom_able;
              }
            });
          }
        }

        // 옵션 테이블에서 옵션 정보 추출
        const opt_obj = options_table.reduce((acc, row) => {
          const A112SingleProd = $scope.data.std_ol_yn && $scope.data.pa_shop_cd === 'A112';
          const attr1 = (A112SingleProd && !isNaN(parseInt(row.attr1))) ? parseInt(row.attr1) : row.attr1;
          const attr2 = (A112SingleProd && !isNaN(parseInt(row.attr2))) ? parseInt(row.attr2) : row.attr2;
          const attr3 = (A112SingleProd && !isNaN(parseInt(row.attr3))) ? parseInt(row.attr3) : row.attr3;

          if (row.attr1 && !acc.opt1.includes(attr1)) {
            acc.opt1.push(attr1);
          }
          if (row.attr2 && !acc.opt2.includes(attr2)) {
            acc.opt2.push(attr2);
          }
          if (row.attr3 && !acc.opt3.includes(attr3)) {
            acc.opt3.push(attr3);
          }

          return acc;
        }, { opt1: [], opt2: [], opt3: [] });

        // 선택한 추천옵션으로 select2 세팅
        const settingSelect2 = (index, optType, optionList = []) => {
          $scope.prodOpt.opt[index].name = options_table[0][`name${index + 1}`] || '';

          if (optionList.length) {
            $scope.prodOpt.opt[index].cateOpAttr = optionList;

            $timeout(() => {
              const select2 = angular.element(document.querySelector(`#${optType}_opt_value${index}`));

              if (select2) {
                select2.controller('multiSelectCombo').destroySelect2(select2);
              }

              $scope.prodOpt.opt[index].attr = opt_obj[`opt${index + 1}`]?.length ? [ ...opt_obj[`opt${index + 1}`] ] : '';
              $scope.selectOptList[index] = [ ...$scope.prodOpt.opt[index].cateOpAttr,
                ...(optType === 'naver_single' ? $scope.prodOpt.opt[index].attr
                  .filter(value => !$scope.prodOpt.opt[index].cateOpAttr.some(item => item.name === value))
                  .map(opt => ({ name: opt })) : [])
              ];

              $timeout(() => {
                select2.controller('multiSelectCombo').initSelect2(select2, {});
              }, 0);
            });
          } else {
            $scope.prodOpt.opt[index].cateOpAttr = [];
            $scope.prodOpt.opt[index].attr = opt_obj[`opt${index + 1}`].join(',');
          }
        };

        if ($scope.data.std_ol_yn == 1 && !['B719', 'A001', 'A006', 'A112', 'A092', 'A093', 'A077', 'X094'].includes($scope.data.pa_shop_cd)) {
          $scope.prodOpt.esm_checked_opt = [];
          for (let i = 1; i < 4; i++) {
            if (options_table[0][`name${i}`]) {
              const opt_findIdx = _.findIndex(opt_value, function(row) {

                return options_table[0][`name${i}`] == row.name && options_table[0][`name${i}_cd`] == row.name_cd;
              });

              if (opt_findIdx !== -1) {
                opt_value[opt_findIdx].attr = opt_obj[`opt${i}`].join(',');
                opt_value[opt_findIdx].checked = true;
                if ($scope.data.std_ol_yn == 1 && $scope.data.pa_shop_cd !== 'B719') {
                  $scope.prodOpt.esm_checked_opt.push(opt_value[opt_findIdx]);
                }
              }
            }
          }
        } else if (['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
          for (let i = 0; i < 3; i++) {
            const recommendOption = $scope.data.esm_options?.find(opt => opt.name === options_table[0][`name${i + 1}`]);

            settingSelect2(i, 'esm', recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName));
          }
        } else if ($scope.data.std_ol_yn === 1 && $scope.data.pa_shop_cd === 'A077') {
          const naversingleOptions = $scope.data.naver_single_options.find(opt => opt.guideId === $scope.data.naver_single_guide_id);

          for (const optIdx in naversingleOptions.cateOptList) {
            const i = Number(optIdx)
                  , recommendOption = naversingleOptions.cateOptList[optIdx];

            settingSelect2(i, 'naver_single', recommendOption?.optionValues?.map(options => ({
              ...options,
              name: options.valueName
            })));
          }
        } else {
          $scope.prodOpt.opt[0].name = options_table[0].name1 || '';
          $scope.prodOpt.opt[1].name = options_table[0].name2 || '';
          $scope.prodOpt.opt[2].name = options_table[0].name3 || '';
          $scope.prodOpt.opt[0].attr = opt_obj.opt1.join(',');
          $scope.prodOpt.opt[1].attr = opt_obj.opt2.join(',');
          $scope.prodOpt.opt[2].attr = opt_obj.opt3.join(',');
        }
      } else {
        const opt = [], opt_name = [];

        if (options_table.length > 0) {
          // 중복 옵션 확인
          if (options_table.some((row, index, arr) => arr.some((opt, optIndex) => index !== optIndex && row.attr === opt.attr && row.name === opt.name))) {
            commonSVC.showMessage('중복 옵션이 있습니다. 옵션을 수정해주세요.');
            $scope.changeTab(2);
            $scope.flag.submit = false;

            return false;
          }

          // 옵션 테이블에서 옵션 정보 추출
          options_table.forEach(row => {
            if (!opt[row.name]) {
              opt[row.name] = [];
            }
            if (row.name && !_.includes(opt_name, row.name)) {
              opt_name.push(row.name);
            }
            if (row.attr && !_.includes(opt[row.name], row.attr)) {
              opt[row.name].push(row.attr);
            }
          });
        }

        for (let i = 0; i < 3; i++) {
          const optionsName = opt_name[i] || '';
          $scope.prodOpt.opt[i].name = optionsName;

          if (['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
            const recommendOption = $scope.data.esm_options.find(opt => opt.name === optionsName);

            if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
              $scope.prodOpt.opt[i].cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName);

              $timeout(() => {
                const select2 = angular.element(document.querySelector(`#esm_opt_value${i}`));

                if (select2) {
                  select2.controller('multiSelectCombo').destroySelect2(select2);
                }

                $scope.prodOpt.opt[i].attr = opt[optionsName]?.length ? [ ...opt[optionsName] ] : '';
                $scope.selectOptList[i] = [ ...$scope.prodOpt.opt[i].cateOpAttr ];

                $timeout(() => {
                  select2.controller('multiSelectCombo').initSelect2(select2, {});
                }, 0);
              });
            } else {
              $scope.prodOpt.opt[i].cateOpAttr = [];
              $scope.prodOpt.opt[i].attr = opt[optionsName]?.join(',') || '';
            }
          } else {
            $scope.prodOpt.opt[i].attr = opt[optionsName]?.join(',') || '';
          }
        }
      }

      return true;
    };

    /**
     * 카테고리 옵션 가져오기
     */
    const getCategoryOptions = async () => {
      const esmDirectOptionDisableCategorys = ['A001', 'A006'].includes($scope.data.pa_shop_cd) ? await categoryModel.getEsmDirectOptionDisableCate() : [];
      let category_code = $scope.data.shop_cate_cd.split('_');

      if ($scope.data.pa_shop_cd === 'A001') {
        //매칭된 카테고리의 옥션, 지마켓
        if (category_code[category_code.length - 1].length < 8) {
          category_code = $scope.data.shop_cate_cd.replace(/_/g, '').padEnd(8, '0');
        } else {
          category_code = category_code[category_code.length - 1];
        }

      } else if ($scope.data.pa_shop_cd === 'A006') {
        category_code = category_code[category_code.length - 1];

      } else {
        category_code = _.last(category_code) != 0 ? _.last(category_code) : category_code[category_code.length - 2];
      }

      if (!category_code) {
        return false;
      }

      const res = await categoryModel.getScrapCategoryOption({
        shopList: {
          [$scope.data.shop_cd]: [category_code]
        }
      });

      // 옵션 직접 등록 가능한 카테고리인지 확인.
      if (['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
        $scope.data.esmDirectOptionEnable = (!(new Set(esmDirectOptionDisableCategorys.data.result)).has($scope.data.pa_shop_cd === 'A001' ? category_code : $scope.data.shop_cate_cd.replace(/_/g, '')))
        || res.data.result.some(cate => cate.shop_cd === $scope.data.pa_shop_cd && cate.etc.options.find(opt => opt.ObjOptNo === 0));
      }

      return res.data.result.flatMap(({ shop_cd, etc }) => (
        shop_cd !== 'A077' ?
          etc.options.filter(option => !Object.prototype.hasOwnProperty.call(option, 'ObjOptNo') || (option.ObjOptNo || option.ObjOptClaseName === '직접입력')).map(option => ({
            ...option,
            shop_cd
          })) : (etc.useOptionYn ? etc.optionGuides.map(option => ({
            ...option,
            shop_cd
          })) : [])
      ));
    };

    /**
     * ESM 추천옵션 가져오기
     * 추천 옵션 로딩 후 옵션 테이블 생성을 위해 콜백사용
     */
    const categoryOptions = async (next, isReset, isChange) => {
      try {
        const data = $scope.data;
        const disabled = $scope.disabled;
        const prodOpt = $scope.prodOpt;

        if (data.std_ol_yn && ['A001', 'A006'].includes(data.pa_shop_cd)) {
          if ($scope.esmMasterFlag && data.pa_shop_cd === 'A001') {
            next ? next() : $scope.ESM_loaded = true;

            return false;
          }

          const originEsmDirectOptionEnable = $scope.data.esmDirectOptionEnable;
          // 2018-11-27 lucas
          // 옵션테이블 그려줄 때만 로딩바 그려주기 때문에
          // 우선은 콜백함수 있는 경우만 ESM_loaded = false로 설정함
          const items = await getCategoryOptions();

          if (!items) {
            return false;
          }

          // ESM 2.0 추천옵션과 자율옵션여부가 기존과 동일하면 카테고리 조회 안함.
          if (['A001', 'A006'].includes(data.pa_shop_cd) && !next) {
            const newRecommendOption = items.filter(opt => opt.ObjOptClaseName && opt.TextUseYn !== 'Y').map(opt => opt.ObjOptClaseName);
            const originRecommendOption = data.esm_options?.map(opt => opt.name) || [];

            if (commonSVC.arraysAreEqual(newRecommendOption, originRecommendOption) && originEsmDirectOptionEnable === $scope.data.esmDirectOptionEnable) {
              return false;
            }
          }

          //카테고리 변경 안한 경우만
          prodOpt.esm_input_list = [];
          if (!isReset && (!isChange && isReset)) {
            prodOpt.options_table = [];
          }

          const esmOptionsFlag = items.filter(row => row.ObjOptClaseName !== '직접입력' && (row.ObjOptClaseName || row.stdOptNm) && row.TextUseYn !== 'Y');
          const inputOptionsFlag = items.filter(row => row.ObjOptClaseName && row.TextUseYn === 'Y');

          // 카테고리 변경에 의해 옵션이 매칭되지 않을 경우 알럿으로 유도하도록 처리 추가
          $scope.$watch('tabActive', () => {
            if (
              [1, 2].includes($scope.tabActive) &&
              ['A001', 'A006'].includes(data.pa_shop_cd) &&
              (
                [data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].some(
                  (optCd) =>
                    optCd &&
                    optCd !== 'undefined' &&
                    !items
                      .map(item => item.ObjOptNo)
                      .includes(+optCd)
                ) ||
                ([data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].filter(Boolean).length &&
                  $scope.data.opt_type === '옵션없음')
              )
            ) {
              commonSVC.showMessage(
                '',
                '카테고리 정보 변경으로 인해 추천 옵션 정보가 업데이트 되었습니다.\n 상품의 옵션을 확인하시어 재설정 후 상품을 수정 저장해 주세요.'
              );

              // ESM 2.0 옵션초기화 해주어야 함.
              if (['A001', 'A006'].includes(data.pa_shop_cd)) {
                $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
                $scope.prodOpt.esm_opt = $scope.prodOpt.opt;
              }
            }
          });

          // ESM 2.0 이고 자율옵션 가능인경우에는 추천옵션 없어도 옵션불가 처리 안함.
          const isEsm = ['A001', 'A006'].includes($scope.data.pa_shop_cd);
          const isEsmDirectOptionDisabled = isEsm && !$scope.data.esmDirectOptionEnable;
          const isConditionsMet = !(items.length && !items.some(item => item.ObjOptClaseName !== '직접입력')) && !esmOptionsFlag.length && !isReset;

          if (items.length && !items.some(item => item.ObjOptClaseName !== '직접입력')) {
            $scope.prodOpt.esm_opt = [];
          }

          if ((!isEsm || isEsmDirectOptionDisabled) && isConditionsMet) {
            disabled.esm_opt = true;
            data.opt_type = '옵션없음';
          } else {
            disabled.esm_opt = false;
            // prodOpt.esm_opt = [];
            const esm_opt_list = [];

            for (const row of esmOptionsFlag) {
              const className = row.ObjOptClaseName || row.stdOptNm;
              const optionNo = row.ObjOptNo || row.ObjOptNo === 0 ? row.ObjOptNo : row.stdOptNo;

              const esm_opt = {
                name: className,
                title: className
              };

              for (let i = 1; i <= 3; i++) {
                let name_cd_check = true;

                if (['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
                  name_cd_check = $scope.data[`opt_sort${i}_cd`] == optionNo;
                }
                if ($scope.data[`opt_sort${i}`] == className && name_cd_check) {
                  esm_opt.checked = true;
                }
              }

              if (!_.isUndefined(row.ObjOptNo) || !esm_opt.name_cd || esm_opt.name_cd == '') {
                esm_opt.name_cd = row.ObjOptNo || row.ObjOptNo === 0 ? row.ObjOptNo : row.stdOptNo;
              }

              if (['A001', 'A006'].includes(data.pa_shop_cd)) {
                esm_opt.cateOpAttr = row.DirectInsEnableYn === 'N';

                if (esm_opt.name_cd) {
                  const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: data.shop_cd, opt_cd: esm_opt.name_cd });

                  if (res.data.result && res.data.result.shop_cate_opt_detail && res.data.result.shop_cate_opt_detail.length) {
                    esm_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
                  }
                }
                if ($scope.prodOpt.opt.find(o => o.name === esm_opt.name) && esm_opt.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
                  $scope.prodOpt.opt.find(o => o.name === esm_opt.name).cateOpAttr = esm_opt.cateOpAttrList.filter(o => o.ObjOptEssenName).map(options => {
                    return {
                      ...options,
                      name: options.ObjOptEssenName
                    };
                  });
                }
              }

              esm_opt.shop_cd = data.shop_cd;
              esm_opt.flag = true;

              esm_opt_list.push(esm_opt);
            }

            // esm 추천옵션 중복 설정 시 중복으로 설정된 옵션태그의 경우 옵션선택 버튼이 생성되지 않아서 해당 처리 추가
            let shouldContinue = false;
            const uniqueNames = new Set();

            while (
              ['A001', 'A006'].includes(data.pa_shop_cd) &&
              Object.keys($scope.data.shop_cate_no_add_info).length &&
              $scope.prodOpt.opt.some(
                opt =>
                  opt.name &&
                  ![null, undefined, 0, '0', 'null', 'undefined', ''].includes(opt.name_cd) &&
                  opt.attr &&
                  !opt.cateOpAttr.length
              )
            ) {
              // 중복 옵션명 있는지 체크
              const hasDuplicateNames = $scope.prodOpt.opt.some((opt, index, array) => {
                return (
                  opt.name &&
                  !uniqueNames.has(opt.name) &&
                  array.slice(0, index).some(prevOpt => prevOpt.name === opt.name)
                );
              });

              // 중복 옵션명이 없거나 이전 루프에서 shouldContinue가 true인 경우 루프 종료
              if (!hasDuplicateNames || shouldContinue) {
                break;
              }

              // 필터링된 첫 번째 요소를 기반으로 다른 요소들 업데이트
              $scope.prodOpt.opt.forEach(opt => {
                if (opt.name && ![null, undefined, 0, '0', 'null', 'undefined'].includes(opt.name_cd)) {
                  if (uniqueNames.has(opt.name)) { return; }

                  // 옵션명이 같은 객체 필터링
                  const matchingObjs = $scope.prodOpt.opt.filter(o => o.name === opt.name);

                  // 추천옵션 표본 생성
                  let cateOpAttr = [];
                  // 추천옵션(cateOpAttr) 추출
                  for (const obj of matchingObjs) {
                    if (obj.cateOpAttr.length > 0) {
                      cateOpAttr = obj.cateOpAttr;
                      break;
                    }
                  }

                  if (cateOpAttr.length > 0) {
                    // 옵션명이 동일한 객체들을 추천옵션(cateOpAttr) 동일하게 업데이트
                    matchingObjs.forEach(obj => {
                      obj.cateOpAttr = cateOpAttr;
                    });
                    shouldContinue = true; // 루프 종료 flag
                  }

                  // 처리 완료된 옵션명 추가
                  uniqueNames.add(opt.name);
                }
              });
            }

            _.forEach(esm_opt_list, function(cur) {
              const find = _.find(prodOpt.esm_opt, function(pre) {
                if ($scope.data.pa_shop_cd == 'A001' || $scope.data.pa_shop_cd == 'A006') {
                  return pre.name == cur.name && pre.name_cd == cur.name_cd;
                } else {
                  return pre.name == cur.name;
                }
              });

              if (!find || esm_opt_list.length !== prodOpt.esm_opt.length) {
                $scope.data.esm_opt_type = '옵션없음';
                prodOpt.esm_opt = esm_opt_list;
                prodOpt.options_table = [];
                $('.attrs').tagsinput('removeAll');

                return false;
              }
            });

            //최초 선택된 옵션값 세팅
            _.forEach($scope.prodOpt.opt, function(optData) {
              const find_opt_name_idx = _.findIndex($scope.prodOpt.esm_opt, function(row) {
                if ($scope.data.pa_shop_cd == 'A001' || $scope.data.pa_shop_cd == 'A006') {
                  return optData.name == row.name && optData.name_cd == row.name_cd;
                } else {
                  return optData.name == row.name;
                }
              });

              if (find_opt_name_idx !== -1) {
                $scope.prodOpt.esm_opt[find_opt_name_idx].name = optData.name;
                $scope.prodOpt.esm_opt[find_opt_name_idx].naflagme = optData.flag;
                $scope.prodOpt.esm_opt[find_opt_name_idx].attr = optData.attr;
              }
            });

            //옵션 순서 설정
            const temp_esm_opt = [];

            _.forEach($scope.prodOpt.opt, function(row) {
              if (row) {
                const findidx_esm = _.findIndex($scope.prodOpt.esm_opt, function(esmopt) {
                  if (['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
                    return esmopt?.name == row.name && esmopt?.name_cd == row.name_cd;
                  } else {
                    return esmopt?.name == row.name;
                  }
                });

                if (findidx_esm !== -1) {
                  temp_esm_opt.push($scope.prodOpt.esm_opt.splice(findidx_esm, 1)[0]);
                }
              }
            });

            if (['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
              $scope.data.esm_options = temp_esm_opt.concat($scope.prodOpt.esm_opt);
              $scope.prodOpt.esm_opt = $scope.prodOpt.opt;
            } else {
              $scope.prodOpt.esm_opt = temp_esm_opt.concat($scope.prodOpt.esm_opt);
              $scope.prodOpt.esm_checked_opt = _.filter($scope.prodOpt.esm_opt, 'checked');
            }

            if (data.pa_shop_cd === 'A112' && data.std_ol_yn) {
              const esmOptFilterByType = $scope.prodOpt.esm_opt.reduce((prev, curr) => {
                if (curr.typeCd === 'N') {
                  prev.push(curr.name);
                }

                return prev;
              }, []);

              // 11번가 단일 상품의 경우 단위 데이터 표출을 위해 하기와 같이 처리함 - Lucas
              $scope.prodOpt.opt.forEach(v => {
                if (esmOptFilterByType.includes(v.name)) {
                  for (const opt of $scope.prodOpt.esm_checked_opt) {
                    if (opt.name === v.name) {
                      opt.stdOpt = String(v.unit);
                    }
                  }
                }
              });
            }
          }

          // 옵션 입력형
          if (inputOptionsFlag.length) {
            _.each(inputOptionsFlag, function (row) {
              const className = row.ObjOptClaseName || row.stdOptNm;

              if (className !== '사이즈' && className !== '색상') {
                prodOpt.esm_input_list.push(className);
                prodOpt.esm_input_list = _.uniq(prodOpt.esm_input_list);
              }
            });
            $('.esm_input_opt').select2({
              minimumResultsForSearch: Infinity,
              tags: false,
              width: '100%',
              placeholder: '콤마(,) 또는 엔터(enter) 구분',
              closeOnSelect: false
            });
            $('.esm_input_opt').change(function () {
              const va = $(this).val() || [];

              prodOpt.esm_input_name = va;
            });

            if (data.input_opt_name && prodOpt.esm_input_list.length) {
              const input_opt_name = JSON.parse(data.input_opt_name);

              if (data.std_ol_yn) {
                data.esm_insert_opt = true;
                const esm_opt_name = [];

                _.each(input_opt_name, function (row) {
                  esm_opt_name.push(row.insert_opt);
                });
                $timeout(function () {
                  $('.esm_input_opt').val(esm_opt_name).trigger('change');
                }, 500);
              }
            }
          }

          next ? next() : $scope.ESM_loaded = true;
        } else if (data.pa_shop_cd === 'A092') {
          next ? next() : $scope.Ali_loaded = true;

          const items = await getCategoryOptions();

          if (!items) {
            return false;
          }

          // 추천옵션과 자율옵션여부가 기존과 동일하면 카테고리 조회 안함.
          if (!next) {
            const newRecommendOption = items.map(opt => opt.ObjOptClaseName);
            const originRecommendOption = data.alibaba_options?.map(opt => opt.name) || [];

            if (commonSVC.arraysAreEqual(newRecommendOption, originRecommendOption)) {
              return false;
            }
          }

          //카테고리 변경 안한 경우만
          prodOpt.alibaba_input_list = [];
          if (!isReset && (!isChange && isReset)) {
            prodOpt.options_table = [];
          }

          // 카테고리 변경에 의해 옵션이 매칭되지 않을 경우 알럿으로 유도하도록 처리 추가
          $scope.$watch('tabActive', () => {
            if (
              [1, 2].includes($scope.tabActive) &&
              data.pa_shop_cd === 'A092' &&
              (
                [data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].some(
                  (optCd) =>
                    optCd &&
                    optCd !== 'undefined' &&
                    !items
                      .map(item => item.ObjOptNo)
                      .includes(+optCd || optCd)
                ) ||
                ([data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].filter(Boolean).length &&
                  $scope.data.opt_type === '옵션없음')
              )
            ) {
              commonSVC.showMessage(
                '',
                '카테고리 정보 변경으로 인해 추천 옵션 정보가 업데이트 되었습니다.\n 상품의 옵션을 확인하시어 재설정 후 상품을 수정 저장해 주세요.'
              );

              // 알리 옵션초기화 해주어야 함.
              if (data.pa_shop_cd === 'A092') {
                $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
                $scope.prodOpt.alibaba_opt = $scope.prodOpt.opt;
              }
            }
          });

          if (!items.length) {
            disabled.alibaba_opt = true;
            data.opt_type = '옵션없음';
          } else {
            disabled.alibaba_opt = false;
            const alibaba_opt_list = [];

            for (const row of items) {
              const className = row.ObjOptClaseName;
              const optionNo = row.ObjOptNo;

              const alibaba_opt = {
                name: className,
                title: className
              };

              for (let i = 1; i <= 3; i++) {
                if ($scope.data[`opt_sort${i}`] == className && $scope.data[`opt_sort${i}_cd`] == optionNo) {
                  alibaba_opt.checked = true;
                }
              }

              if (!_.isUndefined(row.ObjOptNo) || !alibaba_opt.name_cd || alibaba_opt.name_cd == '') {
                alibaba_opt.name_cd = row.ObjOptNo;
              }

              alibaba_opt.cateOpAttr = row.DirectInsEnableYn === 'N';

              if (alibaba_opt.name_cd) {
                const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: data.shop_cd, opt_cd: alibaba_opt.name_cd });

                if (res.data.result && res.data.result.shop_cate_opt_detail && res.data.result.shop_cate_opt_detail.length) {
                  alibaba_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
                }
              }
              if ($scope.prodOpt.opt.find(o => o.name === alibaba_opt.name) && alibaba_opt.cateOpAttrList?.length) {
                $scope.prodOpt.opt.find(o => o.name === alibaba_opt.name).cateOpAttr = alibaba_opt.cateOpAttrList.map(options => {
                  return {
                    ...options,
                    name: options.ObjOptEssenName
                  };
                });
              }

              alibaba_opt.shop_cd = data.shop_cd;
              alibaba_opt.flag = true;

              alibaba_opt_list.push(alibaba_opt);
            }

            // esm 추천옵션 중복 설정 시 중복으로 설정된 옵션태그의 경우 옵션선택 버튼이 생성되지 않아서 해당 처리 추가
            let shouldContinue = false;

            // 중복 옵션명 있는지 체크
            const hasDuplicateNames = $scope.prodOpt.opt.some((opt, index, array) => {
              return opt.name && array.slice(0, index).some(prevOpt => prevOpt.name === opt.name);
            });

            while (
              hasDuplicateNames &&
              data.pa_shop_cd === 'A092' &&
              Object.keys($scope.data.shop_cate_no_add_info).length &&
              $scope.prodOpt.opt.some(
                opt =>
                  opt.name &&
                  ![null, undefined, 0, '0', 'null', 'undefined', ''].includes(opt.name_cd) &&
                  opt.attr &&
                  !opt.cateOpAttr.length
              )
            ) {
              if (shouldContinue) {
                // 이전 루프에서 shouldContinue가 true로 변경된 경우 루프 종료
                break;
              }
              const uniqueNames = new Set();

              // 필터링된 첫 번째 요소를 기반으로 다른 요소들 업데이트
              $scope.prodOpt.opt.forEach(opt => {
                if (opt.name && ![null, undefined, 0, '0', 'null', 'undefined'].includes(opt.name_cd)) {
                  // 옵션명을 키로 사용하여 Set에 추가
                  uniqueNames.add(opt.name);
                }
              });

              // Set에서 중복 제거한 name을 순회하면서 작업
              uniqueNames.forEach(name => {
                // 옵션명이 같은 객체 필터링
                const matchingObjs = $scope.prodOpt.opt.filter(opt => opt.name === name);

                // 추천옵션 표본 생성
                let cateOpAttr = [];

                // 추천옵션(cateOpAttr) 추출
                for (const obj of matchingObjs) {
                  if (obj.cateOpAttr.length > 0) {
                    cateOpAttr = obj.cateOpAttr;
                    break;
                  }
                }

                if (cateOpAttr.length > 0) {
                  // 옵션명이 동일한 객체들을 추천옵션(cateOpAttr) 동일하게 업데이트
                  matchingObjs.forEach(obj => {
                    obj.cateOpAttr = cateOpAttr;
                  });
                  shouldContinue = true; // 루프 종료 flag
                }
              });
            }

            _.forEach(alibaba_opt_list, function(cur) {
              const find = _.find(prodOpt.alibaba_opt, function(pre) {
                return pre.name == cur.name && pre.name_cd == cur.name_cd;
              });

              if (!find || alibaba_opt_list.length !== prodOpt.alibaba_opt.length) {
                $scope.data.alibaba_opt_type = '옵션없음';
                prodOpt.alibaba_opt = alibaba_opt_list;
                prodOpt.options_table = [];
                $('.attrs').tagsinput('removeAll');

                return false;
              }
            });

            //최초 선택된 옵션값 세팅
            _.forEach($scope.prodOpt.opt, function(optData) {
              const find_opt_name_idx = _.findIndex($scope.prodOpt.alibaba_opt, function(row) {
                return optData.name == row.name && optData.name_cd == row.name_cd;
              });

              if (find_opt_name_idx !== -1) {
                $scope.prodOpt.alibaba_opt[find_opt_name_idx].name = optData.name;
                $scope.prodOpt.alibaba_opt[find_opt_name_idx].naflagme = optData.flag;
                $scope.prodOpt.alibaba_opt[find_opt_name_idx].attr = optData.attr;
              }
            });

            //옵션 순서 설정
            const temp_alibaba_opt = [];

            _.forEach($scope.prodOpt.opt, function(row) {
              if (row) {
                const findidx_alibaba = _.findIndex($scope.prodOpt.alibaba_opt, function(alibabaopt) {
                  if ($scope.data.pa_shop_cd === 'A092') {
                    return alibabaopt?.name == row.name && alibabaopt?.name_cd == row.name_cd;
                  } else {
                    return alibabaopt?.name == row.name;
                  }
                });

                if (findidx_alibaba !== -1) {
                  temp_alibaba_opt.push($scope.prodOpt.alibaba_opt.splice(findidx_alibaba, 1)[0]);
                }
              }
            });

            $scope.data.alibaba_options = temp_alibaba_opt.concat($scope.prodOpt.alibaba_opt);
            $scope.prodOpt.alibaba_opt = $scope.prodOpt.opt;
          }

          next ? next() : $scope.Ali_loaded = true;
        } else if (data.pa_shop_cd === 'A093') {
          next ? next() : $scope.Aliexpress_loaded = true;

          const items = await getCategoryOptions();

          if (!items) {
            return false;
          }

          // 추천옵션과 자율옵션여부가 기존과 동일하면 카테고리 조회 안함.
          if (!next) {
            const newRecommendOption = items.map(opt => opt.ObjOptClaseName);
            const originRecommendOption = data.aliexpress_options?.map(opt => opt.name) || [];

            if (commonSVC.arraysAreEqual(newRecommendOption, originRecommendOption)) {
              return false;
            }
          }

          //카테고리 변경 안한 경우만
          prodOpt.aliexpress_input_list = [];
          if (!isReset && (!isChange && isReset)) {
            prodOpt.options_table = [];
          }

          // 카테고리 변경에 의해 옵션이 매칭되지 않을 경우 알럿으로 유도하도록 처리 추가
          $scope.$watch('tabActive', () => {
            if (
              [1, 2].includes($scope.tabActive) &&
              data.pa_shop_cd === 'A093' &&
              (
                [data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].some(
                  (optCd) =>
                    optCd &&
                    optCd !== 'undefined' &&
                    !items
                      .map(item => item.ObjOptNo)
                      .includes(+optCd || optCd)
                ) ||
                ([data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].filter(Boolean).length &&
                  $scope.data.opt_type === '옵션없음')
              )
            ) {
              commonSVC.showMessage(
                '',
                '카테고리 정보 변경으로 인해 추천 옵션 정보가 업데이트 되었습니다.\n 상품의 옵션을 확인하시어 재설정 후 상품을 수정 저장해 주세요.'
              );

              // 알리 옵션초기화 해주어야 함.
              if (data.pa_shop_cd === 'A093') {
                $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
                $scope.prodOpt.aliexpress_opt = $scope.prodOpt.opt;
              }
            }
          });

          if (!items.length) {
            disabled.aliexpress_opt = true;
            data.opt_type = '옵션없음';
          } else {
            disabled.aliexpress_opt = false;
            const aliexpress_opt_list = [];

            for (const row of items) {
              const className = row.ObjOptClaseName;
              const optionNo = row.ObjOptNo;

              const aliexpress_opt = {
                name: className,
                title: className
              };

              for (let i = 1; i <= 3; i++) {
                if ($scope.data[`opt_sort${i}`] == className && $scope.data[`opt_sort${i}_cd`] == optionNo) {
                  aliexpress_opt.checked = true;
                }
              }

              if (!_.isUndefined(row.ObjOptNo) || !aliexpress_opt.name_cd || aliexpress_opt.name_cd == '') {
                aliexpress_opt.name_cd = row.ObjOptNo;
              }

              aliexpress_opt.cateOpAttr = row.DirectInsEnableYn === 'N';

              if (aliexpress_opt.name_cd) {
                const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: data.shop_cd, opt_cd: aliexpress_opt.name_cd });

                if (res.data.result && res.data.result.shop_cate_opt_detail && res.data.result.shop_cate_opt_detail.length) {
                  aliexpress_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
                }
              }
              if ($scope.prodOpt.opt.find(o => o.name === aliexpress_opt.name) && aliexpress_opt.cateOpAttrList?.length) {
                $scope.prodOpt.opt.find(o => o.name === aliexpress_opt.name).cateOpAttr = aliexpress_opt.cateOpAttrList.map(options => {
                  return {
                    ...options,
                    name: options.ObjOptEssenName
                  };
                });
              }

              aliexpress_opt.shop_cd = data.shop_cd;
              aliexpress_opt.flag = true;

              aliexpress_opt_list.push(aliexpress_opt);
            }

            // esm 추천옵션 중복 설정 시 중복으로 설정된 옵션태그의 경우 옵션선택 버튼이 생성되지 않아서 해당 처리 추가
            let shouldContinue = false;

            // 중복 옵션명 있는지 체크
            const hasDuplicateNames = $scope.prodOpt.opt.some((opt, index, array) => {
              return opt.name && array.slice(0, index).some(prevOpt => prevOpt.name === opt.name);
            });

            while (
              hasDuplicateNames &&
              data.pa_shop_cd === 'A093' &&
              Object.keys($scope.data.shop_cate_no_add_info).length &&
              $scope.prodOpt.opt.some(
                opt =>
                  opt.name &&
                  ![null, undefined, 0, '0', 'null', 'undefined', ''].includes(opt.name_cd) &&
                  opt.attr &&
                  !opt.cateOpAttr.length
              )
            ) {
              if (shouldContinue) {
                // 이전 루프에서 shouldContinue가 true로 변경된 경우 루프 종료
                break;
              }
              const uniqueNames = new Set();

              // 필터링된 첫 번째 요소를 기반으로 다른 요소들 업데이트
              $scope.prodOpt.opt.forEach(opt => {
                if (opt.name && ![null, undefined, 0, '0', 'null', 'undefined'].includes(opt.name_cd)) {
                  // 옵션명을 키로 사용하여 Set에 추가
                  uniqueNames.add(opt.name);
                }
              });

              // Set에서 중복 제거한 name을 순회하면서 작업
              uniqueNames.forEach(name => {
                // 옵션명이 같은 객체 필터링
                const matchingObjs = $scope.prodOpt.opt.filter(opt => opt.name === name);

                // 추천옵션 표본 생성
                let cateOpAttr = [];

                // 추천옵션(cateOpAttr) 추출
                for (const obj of matchingObjs) {
                  if (obj.cateOpAttr.length > 0) {
                    cateOpAttr = obj.cateOpAttr;
                    break;
                  }
                }

                if (cateOpAttr.length > 0) {
                  // 옵션명이 동일한 객체들을 추천옵션(cateOpAttr) 동일하게 업데이트
                  matchingObjs.forEach(obj => {
                    obj.cateOpAttr = cateOpAttr;
                  });
                  shouldContinue = true; // 루프 종료 flag
                }
              });
            }

            _.forEach(aliexpress_opt_list, function(cur) {
              const find = _.find(prodOpt.aliexpress_opt, function(pre) {
                return pre.name == cur.name && pre.name_cd == cur.name_cd;
              });

              if (!find || aliexpress_opt_list.length !== prodOpt.aliexpress_opt.length) {
                $scope.data.aliexpress_opt_type = '옵션없음';
                prodOpt.aliexpress_opt = aliexpress_opt_list;
                prodOpt.options_table = [];
                $('.attrs').tagsinput('removeAll');

                return false;
              }
            });

            //최초 선택된 옵션값 세팅
            _.forEach($scope.prodOpt.opt, function(optData) {
              const find_opt_name_idx = _.findIndex($scope.prodOpt.aliexpress_opt, function(row) {
                return optData.name == row.name && optData.name_cd == row.name_cd;
              });

              if (find_opt_name_idx !== -1) {
                $scope.prodOpt.aliexpress_opt[find_opt_name_idx].name = optData.name;
                $scope.prodOpt.aliexpress_opt[find_opt_name_idx].naflagme = optData.flag;
                $scope.prodOpt.aliexpress_opt[find_opt_name_idx].attr = optData.attr;
              }
            });

            //옵션 순서 설정
            const temp_aliexpress_opt = [];

            _.forEach($scope.prodOpt.opt, function(row) {
              if (row) {
                const findidx_aliexpress = _.findIndex($scope.prodOpt.aliexpress_opt, function(aliexpressopt) {
                  if (data.pa_shop_cd === 'A093') {
                    return aliexpressopt?.name == row.name && aliexpressopt?.name_cd == row.name_cd;
                  } else {
                    return aliexpressopt?.name == row.name;
                  }
                });

                if (findidx_aliexpress !== -1) {
                  temp_aliexpress_opt.push($scope.prodOpt.aliexpress_opt.splice(findidx_aliexpress, 1)[0]);
                }
              }
            });

            $scope.data.aliexpress_options = temp_aliexpress_opt.concat($scope.prodOpt.aliexpress_opt);
            $scope.prodOpt.aliexpress_opt = $scope.prodOpt.opt;
          }

          next ? next() : $scope.Aliexpress_loaded = true;
        } else if (data.std_ol_yn && data.pa_shop_cd === 'A077') {
          next ? next() : $scope.NaverSingle_loaded = true;

          const items = await getCategoryOptions();

          if (!items.length) {
            return false;
          }

          // 추천옵션과 자율옵션여부가 기존과 동일하면 카테고리 조회 안함.
          if (!next) {
            const newRecommendOption = items.map(item => item.standardPurchaseOptions.map(opt => opt.optionName).join()),
                  originRecommendOption = data.naver_single_options?.map(opt => opt.name) || [];

            if (commonSVC.arraysAreEqual(newRecommendOption, originRecommendOption)) {
              return false;
            }
          }

          //카테고리 변경 안한 경우만
          if (!isReset && (!isChange && isReset)) {
            prodOpt.options_table = [];
          }

          // 카테고리 변경에 의해 옵션이 매칭되지 않을 경우 알럿으로 유도하도록 처리 추가
          $scope.$watch('tabActive', () => {
            if (
              [1, 2].includes($scope.tabActive) &&
              data.pa_shop_cd === 'A077' &&
              (
                !items.some(({ standardPurchaseOptions }) => standardPurchaseOptions.map(opt => opt.optionId).join() === [data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].filter(Boolean).join())
                  || ([data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].filter(Boolean).length && $scope.data.opt_type === '옵션없음')
              )
            ) {
              commonSVC.showMessage(
                '',
                '카테고리 정보 변경으로 인해 추천 옵션 정보가 업데이트 되었습니다.\n 상품의 옵션을 확인하시어 재설정 후 상품을 수정 저장해 주세요.'
              );

              // 스마트스토어 단일상품 옵션초기화 해주어야 함.
              if (data.pa_shop_cd === 'A077') {
                $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
                $scope.prodOpt.naver_single_opt = $scope.prodOpt.opt;
                $scope.prodOpt.options_table = [];
              }
            }
          });

          if (!items.length) {
            disabled.naver_single_opt = true;
            data.opt_type = '옵션없음';
          } else {
            disabled.naver_single_opt = false;
            const naver_single_opt_list = [];

            items.forEach(item => {
              naver_single_opt_list.push(
                {
                  name: item.standardPurchaseOptions.map(opt => opt.optionName).join(),
                  shop_cd: 'A077',
                  TextUseYn: true,
                  cateOptList: item.standardPurchaseOptions,
                  guideId: item.guideId
                }
              );
            });

            $scope.data.naver_single_options = naver_single_opt_list;

            const selectedOpt = items.find(({ standardPurchaseOptions }) =>
              standardPurchaseOptions.map(opt => opt.optionId).join() === [$scope.data.opt_sort1_cd, $scope.data.opt_sort2_cd, $scope.data.opt_sort3_cd].filter(Boolean).join()
            );

            if (selectedOpt) {
              $scope.data.naver_single_guide_id = selectedOpt.guideId;

              for (let i = 0; i < 3; i++) {
                $scope.prodOpt.opt[i].checked = true;
                $scope.prodOpt.opt[i].guideId = selectedOpt.guideId;

                if (selectedOpt.standardPurchaseOptions[i]?.optionValues?.length) {
                  $scope.prodOpt.opt[i].cateOpAttr = selectedOpt.standardPurchaseOptions[i].optionValues.map(options => (
                    {
                      ...options,
                      name: options.valueName
                    }));

                  $timeout(() => {
                    const select2 = angular.element(document.querySelector(`#naver_single_opt_value${i}`));

                    if (select2) {
                      select2.controller('multiSelectCombo').destroySelect2(select2);
                    }

                    $scope.prodOpt.naver_single_opt[i].attr = $scope.prodOpt.naver_single_opt[i].attr ? $scope.prodOpt.naver_single_opt[i].attr.split(',') : [];
                    $scope.selectOptList[i] = [ ...$scope.prodOpt.naver_single_opt[i].cateOpAttr,
                      ...$scope.prodOpt.naver_single_opt[i].attr
                        .filter(value => !$scope.prodOpt.opt[i].cateOpAttr.some(item => item.name === value))
                        .map(opt => ({ name: opt }))
                    ];

                    $timeout(() => {
                      select2.controller('multiSelectCombo').initSelect2(select2, {});
                    }, 0);
                  });
                } else {
                  $scope.prodOpt.opt[i].cateOpAttr = [];
                }
              }

              $scope.prodOpt.naver_single_opt = $scope.prodOpt.opt;
            }
          }

          next ? next() : $scope.NaverSingle_loaded = true;
        } else if (data.pa_shop_cd === 'X094') {
          next ? next() : $scope.Aliexpress_Global_loaded = true;

          const items = await getCategoryOptions();

          if (!items) {
            return false;
          }

          // 추천옵션과 자율옵션여부가 기존과 동일하면 카테고리 조회 안함.
          if (!next) {
            const newRecommendOption = items.map(opt => opt.ObjOptClaseName);
            const originRecommendOption = data.aliexpress_global_options?.map(opt => opt.name) || [];

            if (commonSVC.arraysAreEqual(newRecommendOption, originRecommendOption)) {
              return false;
            }
          }

          //카테고리 변경 안한 경우만
          prodOpt.aliexpress_global_input_list = [];
          if (!isReset && (!isChange && isReset)) {
            prodOpt.options_table = [];
          }

          // 카테고리 변경에 의해 옵션이 매칭되지 않을 경우 알럿으로 유도하도록 처리 추가
          $scope.$watch('tabActive', () => {
            if (
              [1, 2].includes($scope.tabActive) &&
              data.pa_shop_cd === 'X094' &&
              (
                [data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].some(
                  (optCd) =>
                    optCd &&
                    optCd !== 'undefined' &&
                    !items
                      .map(item => item.ObjOptNo)
                      .includes(+optCd || optCd)
                ) ||
                ([data.opt_sort1_cd, data.opt_sort2_cd, data.opt_sort3_cd].filter(Boolean).length &&
                  $scope.data.opt_type === '옵션없음')
              )
            ) {
              commonSVC.showMessage(
                '',
                '카테고리 정보 변경으로 인해 추천 옵션 정보가 업데이트 되었습니다.\n 상품의 옵션을 확인하시어 재설정 후 상품을 수정 저장해 주세요.'
              );

              // 알리 옵션초기화 해주어야 함.
              if (data.pa_shop_cd === 'X094') {
                $scope.prodOpt.opt = angular.copy(onlineProductSVC.default_opt);
                $scope.prodOpt.aliexpress_global_opt = $scope.prodOpt.opt;
              }
            }
          });

          if (!items.length) {
            disabled.aliexpress_global_opt = true;
            data.opt_type = '옵션없음';
          } else {
            disabled.aliexpress_global_opt = false;
            const aliexpress_global_opt_list = [];

            for (const row of items) {
              const className = row.ObjOptClaseName;
              const optionNo = row.ObjOptNo;

              const aliexpress_global_opt = {
                name: className,
                title: className
              };

              for (let i = 1; i <= 3; i++) {
                if ($scope.data[`opt_sort${i}`] == className && $scope.data[`opt_sort${i}_cd`] == optionNo) {
                  aliexpress_global_opt.checked = true;
                }
              }

              if (!_.isUndefined(row.ObjOptNo) || !aliexpress_global_opt.name_cd || aliexpress_global_opt.name_cd == '') {
                aliexpress_global_opt.name_cd = row.ObjOptNo;
              }

              aliexpress_global_opt.cateOpAttr = row.DirectInsEnableYn === 'N';

              if (aliexpress_global_opt.name_cd) {
                const res = await categoryModel.getScrapCategoryOptionDetail({ shop_cd: data.shop_cd, opt_cd: aliexpress_global_opt.name_cd });

                if (res.data.result && res.data.result.shop_cate_opt_detail && res.data.result.shop_cate_opt_detail.length) {
                  aliexpress_global_opt.cateOpAttrList = res.data.result.shop_cate_opt_detail;
                }
              }
              if ($scope.prodOpt.opt.find(o => o.name === aliexpress_global_opt.name) && aliexpress_global_opt.cateOpAttrList?.length) {
                $scope.prodOpt.opt.find(o => o.name === aliexpress_global_opt.name).cateOpAttr = aliexpress_global_opt.cateOpAttrList.map(options => {
                  return {
                    ...options,
                    name: options.ObjOptEssenName
                  };
                });
              }

              aliexpress_global_opt.shop_cd = data.shop_cd;
              aliexpress_global_opt.flag = true;

              aliexpress_global_opt_list.push(aliexpress_global_opt);
            }

            // esm 추천옵션 중복 설정 시 중복으로 설정된 옵션태그의 경우 옵션선택 버튼이 생성되지 않아서 해당 처리 추가
            let shouldContinue = false;

            // 중복 옵션명 있는지 체크
            const hasDuplicateNames = $scope.prodOpt.opt.some((opt, index, array) => {
              return opt.name && array.slice(0, index).some(prevOpt => prevOpt.name === opt.name);
            });

            while (
              hasDuplicateNames &&
              data.pa_shop_cd === 'X094' &&
              Object.keys($scope.data.shop_cate_no_add_info).length &&
              $scope.prodOpt.opt.some(
                opt =>
                  opt.name &&
                  ![null, undefined, 0, '0', 'null', 'undefined', ''].includes(opt.name_cd) &&
                  opt.attr &&
                  !opt.cateOpAttr.length
              )
            ) {
              if (shouldContinue) {
                // 이전 루프에서 shouldContinue가 true로 변경된 경우 루프 종료
                break;
              }
              const uniqueNames = new Set();

              // 필터링된 첫 번째 요소를 기반으로 다른 요소들 업데이트
              $scope.prodOpt.opt.forEach(opt => {
                if (opt.name && ![null, undefined, 0, '0', 'null', 'undefined'].includes(opt.name_cd)) {
                  // 옵션명을 키로 사용하여 Set에 추가
                  uniqueNames.add(opt.name);
                }
              });

              // Set에서 중복 제거한 name을 순회하면서 작업
              uniqueNames.forEach(name => {
                // 옵션명이 같은 객체 필터링
                const matchingObjs = $scope.prodOpt.opt.filter(opt => opt.name === name);

                // 추천옵션 표본 생성
                let cateOpAttr = [];

                // 추천옵션(cateOpAttr) 추출
                for (const obj of matchingObjs) {
                  if (obj.cateOpAttr.length > 0) {
                    cateOpAttr = obj.cateOpAttr;
                    break;
                  }
                }

                if (cateOpAttr.length > 0) {
                  // 옵션명이 동일한 객체들을 추천옵션(cateOpAttr) 동일하게 업데이트
                  matchingObjs.forEach(obj => {
                    obj.cateOpAttr = cateOpAttr;
                  });
                  shouldContinue = true; // 루프 종료 flag
                }
              });
            }

            _.forEach(aliexpress_global_opt_list, function(cur) {
              const find = _.find(prodOpt.aliexpress_global_opt_opt, function(pre) {
                return pre.name == cur.name && pre.name_cd == cur.name_cd;
              });

              if (!find || aliexpress_global_opt_list.length !== prodOpt.aliexpress_global_opt.length) {
                $scope.data.aliexpress_global_opt_type = '옵션없음';
                prodOpt.aliexpress_global_opt = aliexpress_global_opt_list;
                prodOpt.options_table = [];
                $('.attrs').tagsinput('removeAll');

                return false;
              }
            });

            //최초 선택된 옵션값 세팅
            _.forEach($scope.prodOpt.opt, function(optData) {
              const find_opt_name_idx = _.findIndex($scope.prodOpt.aliexpress_global_opt, function(row) {
                return optData.name == row.name && optData.name_cd == row.name_cd;
              });

              if (find_opt_name_idx !== -1) {
                $scope.prodOpt.aliexpress_global_opt[find_opt_name_idx].name = optData.name;
                $scope.prodOpt.aliexpress_global_opt[find_opt_name_idx].naflagme = optData.flag;
                $scope.prodOpt.aliexpress_global_opt[find_opt_name_idx].attr = optData.attr;
              }
            });

            //옵션 순서 설정
            const temp_aliexpress_global_opt = [];

            _.forEach($scope.prodOpt.opt, function(row) {
              if (row) {
                const findidx_aliexpress_global = _.findIndex($scope.prodOpt.aliexpress_global_opt, function(aliexpressglobalopt) {
                  if (data.pa_shop_cd === 'X094') {
                    return aliexpressglobalopt?.name == row.name && aliexpressglobalopt?.name_cd == row.name_cd;
                  } else {
                    return aliexpressglobalopt?.name == row.name;
                  }
                });

                if (findidx_aliexpress_global !== -1) {
                  temp_aliexpress_global_opt.push($scope.prodOpt.aliexpress_global_opt.splice(findidx_aliexpress_global, 1)[0]);
                }
              }
            });

            $scope.data.aliexpress_global_options = temp_aliexpress_global_opt.concat($scope.prodOpt.aliexpress_global_opt);
            $scope.prodOpt.aliexpress_global_opt = $scope.prodOpt.opt;
          }

          next ? next() : $scope.Aliexpress_Global_loaded = true;
        } else if (next) {
          next();
        }
      } catch (err) {
        commonSVC.showConfirmCustom({ title: '단일상품 옵션정보 호출에 실패했습니다.', text: '재시도 버튼을 클릭하시어 카테고리 호출을 다시 시도해 주세요.', confirmButtonText: '재시도' }, async function (Confirm) {
          if (Confirm) {
            await categoryOptions(next, isReset, isChange);
          }
        });
      }
    };

    function getGalleriaCategory () {
      const data = $scope.data;
      let match_cate_code;
      let vend_code;
      let no_add_info = data.shop_cate_no_add_info;

      if (_.isString(no_add_info)) {
        no_add_info = JSON.parse(no_add_info);
      }

      // 솔루션카테고리에 매칭된 쇼핑몰 카테고리
      if (no_add_info && no_add_info.category_code) {
        match_cate_code = no_add_info.category_code;
        vend_code = no_add_info.key_vend_code;
      } else if (data.sol_cate_shop_add_info && data.sol_cate_shop_add_info.category_code) {
        match_cate_code = data.sol_cate_shop_add_info.category_code;
        vend_code = data.sol_cate_shop_add_info.key_vend_code;
      }

      //매칭된 카테고리가 없을경우 리턴
      if (!match_cate_code) {
        return;
      }
      const userInfo = {
        shop_id: data.shop_id,
        shop_pwd: data.shop_pwd,
        pa_shop_cd: data.pa_shop_cd,
        etc7: data.etc7 // 실섭 배포시 퇴근(dev, app 구분용)
      };

      commonSVC.requestPA(userInfo, 'gvs.galleria.co.kr', 'etc.category_option', {
        vir_vend_no: vend_code,
        category_no: match_cate_code,
      }, commonModel, shopAccountModel)
        .then(res => {
          const list = res.data.data.message;

          let opt_list = [];

          if ($scope.prodOpt.opt && $scope.prodOpt.opt[0].name.length > 0) {
            opt_list = [ $scope.prodOpt.opt[0].name, $scope.prodOpt.opt[1].name, $scope.prodOpt.opt[2].name ];
          }
          const custom_able_dict = {};

          $scope.data.galleria_options = list.map(opt => {
            custom_able_dict[`${opt.opt_nm}`] = {
              custom_able: opt.manual_poss_yn === 'Y',
              opt_value: opt.optvalinfo
            };

            return {
              name: opt.opt_nm,
              opt_value: opt.optvalinfo,
              checked: opt_list.includes(opt.opt_nm),
              custom_able: opt.manual_poss_yn === 'Y'
            };
          });

          $scope.prodOpt.opt = $scope.prodOpt.opt.map(a => ({
            ...a,
            opt_value: custom_able_dict[`${a.name}`] ? custom_able_dict[`${a.name}`].opt_value : [],
            custom_able: custom_able_dict[`${a.name}`] ? custom_able_dict[`${a.name}`].custom_able : true
          }));
        })
        .catch(err => {
          commonSVC.showMessage('갤러리아몰', `옵션 정보 조회 실패 \n${err.message || err}`);
        });
    }

    async function getNaverCategory() {
      try {
        $scope.possible_naver_opt = false;
        const data = $scope.data;

        let shopCate = $scope.data.shop_cate_no_add_info;

        if (_.isString(shopCate)) {
          shopCate = JSON.parse($scope.data.shop_cate_no_add_info);
        }
        const cate = (shopCate?.category_code?.split('_')) ||
          ($scope.data.sol_cate_shop_add_info && $scope.data.sol_cate_shop_add_info.category_code.split('_')) ||
          [];
        const lastCate = cate[cate.length - 1];

        if (cate.length) {
          // 간편옵션 조회
          const response = await commonModel.pa5('/channel_accounts/{uuid}/etc/check_is_standard_option_category/sync', { category_code: lastCate }, data.shop_cd, data.shop_id);
          if (!response.data.results[0]?.data?.length) { throw 'none standard_option'; }

          const groups = response.data.results[0].data;

          if ($scope.data.opt_type === '간편옵션') {
            $scope.prodOpt.opt[0] = {};
            $scope.prodOpt.opt[1] = { ...($scope.prodOpt.opt[1].name_cd && { name_cd: $scope.prodOpt.opt[1].name_cd }) };
          }

          $scope.prodOpt.opt.forEach(opt => {
            opt.sub_group = [];
            opt.sub_group_name_list = [];
            opt.selected_attribute = [];
          });

          groups.forEach(group => {
            if (group.standardOptionAttributes.find(att => !att.attributeValueId || att.attributeValueId.toString() === '0')) {
              throw 'none option_code';
            }
            const idx = group.groupName === '색상' ? 0 : 1;
            const params = {
              sub_group_name: group.attributeName,
              sub_group_id: group.attributeId.toString(),
              attribute: group.standardOptionAttributes
            };

            const color_opt_style = angular.copy(onlineProductSVC.naverColorOptStyle);
            params.attribute.forEach(att => {
              att.selected = false;
              if (group.groupName === '색상') {
                att.styleId = color_opt_style.find(style => style.AttributeValueId === att.attributeValueId).styleId;
              }
            });

            $scope.prodOpt.opt[idx].sub_group.push(params);
            $scope.prodOpt.opt[idx].sub_group_name_list.push(group.attributeName);
          });
          $scope.prodOpt.opt[0].name_cd = $scope.prodOpt.opt[0].sub_group[0].sub_group_id;
          $scope.prodOpt.opt[1].name_cd = $scope.prodOpt.opt[1].name_cd || $scope.prodOpt.opt[1].sub_group[0].sub_group_id;
          $scope.prodOpt.opt[1].selected_sub_group = $scope.prodOpt.opt[1].sub_group[0].attribute;
          $scope.prodOpt.opt[1].selected_sub_group_name = $scope.prodOpt.opt[1].sub_group_name_list[0];
          $scope.possible_naver_opt = true;

          // 기본정보 > 옵션 > 간편옵션 체크 시 getNaverCategory 호출하면서 해당 로직으로 빠짐
          if ($scope.data.opt_type === '간편옵션') {
            $scope.prodOpt.opt[0].name = '색상';
            $scope.prodOpt.opt[1].name = '사이즈';
            const opt_attr = [[], []];

            $scope.prodOpt.options_table.forEach(opt => {
              opt_attr[0].push(`${opt.attr1_cd}:${opt.attr1}`);
              opt_attr[1].push(`${opt.attr2_cd}:${opt.attr2}`);
            });

            opt_attr[0] = _.uniq(opt_attr[0]);
            opt_attr[1] = _.uniq(opt_attr[1]);

            $scope.prodOpt.opt.forEach((opt, i) => {
              if (i > 1) { return false; }
              const sel_sub_group = opt.sub_group.find(sub => sub.sub_group_id === opt.name_cd);
              opt.selected_sub_group = sel_sub_group.attribute;
              opt.selected_sub_group_name = sel_sub_group.sub_group_name;

              if (opt.selected_sub_group) {
                opt_attr[i].forEach(attr => {
                  const selected = attr.split(':');
                  const selected_attr = opt.selected_sub_group.find(sub_attr => sub_attr.attributeValueId === selected[0]);
                  if (selected_attr) {
                    selected_attr.attributeValueName = selected[1];
                    selected_attr.selected = true;
                    opt.selected_attribute.push(selected_attr);
                  }
                });

                naverOptSetting(i);
              }
            });

            if (!$scope.$$phase && !$scope.$root.$$phase) {
              $scope.$apply();
            }
          }
        }
      } catch (err) {
        if (err !== 'none standard_option') {
          let addMsg = err !== 'none option_code' ? err.data.message : '';

          if (!$rootScope.use_channel_list.find(({ shop_cd, shop_id }) => shop_cd === $scope.data.shop_cd && shop_id === $scope.data.shop_id).uuid) {
            addMsg = '쇼핑몰 계정관리에서 계정을 다시 확인해주세요.';
          }
          commonSVC.showMessage('스마트스토어', `${err === 'none option_code' ? '옵션코드' : '간편옵션'} 조회에 실패했습니다.\n` + addMsg);
        }
      }
    }

    function templeteLoad() {
      const data = $scope.data;

      const site_list = data.site_list;

      const pa_shop_cd = data.pa_shop_cd,
            shop_cd = data.shop_cd,
            shop_id = data.shop_id,
            shop_name = data.shop_name,
            set_info = data.set_info,
            template_no = data.template_no,
            template_name = data.template_name;

      const siteInfo = {
        pa_shop_cd: pa_shop_cd,
        shop_cd: shop_cd,
        shop_name: shop_name,
        shop_id: shop_id,
        selected: true,
        set_info: set_info,
        set_info_add_img: {},
        template_no: template_no,
        template_name: template_name,
        seller_nick: data.seller_nick
      };

      site_list[0] = siteInfo;

      //템플릿정보 요약 테이블 표시여부
      if (data.site_list[0].template_no) {
        data.site_list[0].template_summary = true;
      }

      switch (pa_shop_cd) {
        case 'A112': {
          const vdata = {
            shop_cd: shop_cd,
            shop_id: shop_id,
            mode: 'load_init'
          };

          templateModel.templateApi(vdata, function (state, data) {
            site_list[0].hscode = data.hscode || '';
            site_list[0].nickName = data.nickname || '';
          });
          break;
        }
        case 'A004':
          if (data.shop_cate_cd) {
            data.site_list[0].md_category = data.shop_cate_cd.split('_')[3];
          } else if (data.sol_cate_shop_add_info) {
            const cateCode = data.sol_cate_shop_add_info.category_codes || data.sol_cate_shop_add_info.category_code;

            data.site_list[0].md_category = cateCode.split('_')[3];
          }
          break;
      }
    }

    async function applyTrnslData(data, init_data) {
      const ori_data = init_data || $scope.data;

      Object.keys(data.translate_data).forEach(key => {
        switch (key) {
          case 'shop_sale_name':
            ori_data.shop_sale_name = data.translate_data.shop_sale_name; break;
          case 'model':
            ori_data.typing_model.model = data.translate_data.model; break;
          case 'brand':
            ori_data.typing_model.brand = data.translate_data.brand; break;
          case 'made':
            ori_data.typing_model.maker = data.translate_data.made; break;
          case 'detail_desc':
            ori_data.detail_desc = data.translate_data.detail_desc; break;
          case 'options': {
            if (init_data) {
              ori_data.opt_sub_table = data.options_table;
              ori_data.opt_sort1 = data.opt[0]?.name;
              ori_data.opt_sort2 = data.opt[1]?.name;
              ori_data.opt_sort3 = data.opt[2]?.name;
              ori_data.opt_sort1_desc = data.opt[0]?.attr;
              ori_data.opt_sort2_desc = data.opt[2]?.attr;
              ori_data.opt_sort3_desc = data.opt[2]?.attr;
              ori_data.opt_sort1_cd = data.opt[0]?.name_cd;
              ori_data.opt_sort2_cd = data.opt[1]?.name_cd;
              ori_data.opt_sort3_cd = data.opt[2]?.name_cd;
              ori_data.opt_unit1 = data.opt[0]?.unit;
              ori_data.opt_unit2 = data.opt[1]?.unit;
              ori_data.opt_unit3 = data.opt[2]?.unit;
            } else {
              data.translate_data.options.forEach((opt, idx) => {
                if (!Object.keys(opt).length) {

                  return;
                }

                Object.keys(opt).forEach(key => {
                  $scope.prodOpt.options_table[idx][`attr${key}`] = opt[key];
                  $scope.prodOpt.options_table[idx][`attr${key}_title`] = opt[key];
                });
              });
            }
          }
        }
      });
    }

    //채널 추가항목 모달
    $scope.openChannelhModal = async (row, openTemplate) => {
      const templatePath = 'views/online/product/channel/channel_modal.html',
            controller = `olProdShopDetail-${row.pa_shop_cd}-Ctrl`;

      try {
        const result = await onlineProductModel.getSetInfo({ shop_cd_arr: [{ shop_cd: $scope.data.shop_cd, pa_shop_cd: $scope.data.pa_shop_cd }] });

        // 추가항목 기본값
        $scope.data.setInfoDefault = result.data.results[0].set_info;
      } catch (err) {
        commonSVC.showToaster('error', '', '추가항목 기본값 조회에 실패했습니다.');

        return false;
      }

      const resolve = {
        data: {
          shop_data: row,
          openTemplate: openTemplate,
          madeinList: $scope.madeinList,
          data: { ...$scope.data, detailType: $scope.detailType },
        },
        systemList: systemList.data || {},
        templateList: function() {
          return onlineProductModel.setInfoTemplateList({
            shortListYn: true,
            shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
            std_ol_yn: $scope.data.std_ol_yn,
            shop_cd: row.shop_cd,
            pa_shop_cd: row.pa_shop_cd,
          });
        },
      };

      if (['A001', 'A006'].includes(row.pa_shop_cd)) {
        resolve.data.esmMasterFlag = $scope.esmMasterFlag; // ESM 마스터 상품 여부
      } else if (['B378', 'B666', 'P044'].includes(row.pa_shop_cd)) {
        resolve.data.prodOpt = $scope.prodOpt;
      } else if (['P015', 'P018', 'B669', 'P028', 'P037', 'A004'].includes(row.pa_shop_cd)) {
        const cate_cd = $scope.data.shop_cate_cd ? $scope.data.shop_cate_cd : $scope.data.sol_cate_shop_add_info.category_code || $scope.data.sol_cate_shop_add_info.category_codes;
        resolve.data.data = { ...resolve.data.data, cate_cd };
      } else if (['A092'].includes(row.pa_shop_cd)) {
        // switch문 쓰려니까 break 없다고 lint 오류나서 그냥 else if 추가
        resolve.data.prodOpt = $scope.prodOpt;
        const cate_cd = $scope.data.shop_cate_cd ? $scope.data.shop_cate_cd : $scope.data.sol_cate_shop_add_info.category_code || $scope.data.sol_cate_shop_add_info.category_codes;
        resolve.data.data = { ...resolve.data.data, cate_cd };
      }

      const check_fresh_cate = $scope.data.match_cate_name || $scope.data.match_sol_cate_name;

      if (['A032'].includes(row.pa_shop_cd) && check_fresh_cate !== '매칭 카테고리 없음' && check_fresh_cate.split(' > ')[1].includes('신선')) {
        resolve.data.fresh = true;
      }
      const modal = commonSVC.openModal('xxg', resolve, controller, templatePath);

      modal.result.then(function(re) {
        if (re && re !== 'cancel') {
          // 추가항목 템플릿 저장할 카테고리번호
          if (re.saveAsTemplateYn) {
            $scope.data.template_cate_no = $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no;
          }
          $scope.data.site_list[openTemplate] = re;
        }
      });
    };
    /**
     * 입력한 상품, 옵션들 사이트별 등록 상품 리스트 취합
     */
    function makeProdList() {
      const prodList = []; // 일반옵션 옵션 상품 리스트
      // var esmProdList = []; // esm2.0, 11번가 옵션 상품 리스트
      const singleProdList = { // esm2.0, 11번가 단일상품
        opt: angular.copy(onlineProductSVC.default_opt),
        prodInfo: [],
        op_flag: 0
      };
      const addOptList = []; // 추가옵션 상품 리스트
      let sale_opt_seq = 1;
      const options_table = angular.copy($scope.prodOpt.options_table);

      $scope.data.opt = angular.copy(onlineProductSVC.default_opt);
      if ($scope.data.opt_type == '조합형' && $scope.data.std_ol_yn && !['B719', 'A001', 'A006', 'A112', 'A092', 'A093', 'A077', 'X094'].includes($scope.data.pa_shop_cd)) {
        $scope.prodOpt.esm_opt = $scope.prodOpt.esm_checked_opt;
        $scope.prodOpt.opt = _.filter($scope.prodOpt.esm_opt, 'checked');

        for (let i = $scope.prodOpt.opt.length; i < 3; i++) {
          $scope.prodOpt.opt.push(onlineProductSVC.default_opt[0]);
        }
      }

      $scope.data.opt = $scope.prodOpt.opt;

      //매칭된 SKU상품 리스트 생성
      if ($scope.data.opt_type === '옵션없음') {
        for (const j in $scope.prodOpt.matchStockList) {
          const prod = $scope.prodOpt.matchStockList[j];

          prod.sale_opt_seq = parseInt(j) + 1;
          prodList.push(prod);
        }
      } else {
        const optionTables = ($scope.orginProdOptionTable && ($scope.orginProdOptionTable.length > $scope.OPT_LIST_LIMIT) && options_table.length !== $scope.orginProdOptionTable.length) ? options_table.concat($scope.orginProdOptionTable.slice(options_table.length)) : (options_table || []);

        for (const j2 in optionTables) {
          const row = optionTables[j2];

          row.isNew = !row.sale_opt_seq;
          row.ori_sale_opt_seq = row.sale_opt_seq;
          row.sale_opt_seq = sale_opt_seq;
          row.opt_add_price = row.opt_add_price || 0;

          if ($scope.data.opt_type == '조합형' || $scope.data.opt_type == '간편옵션') {
            row.opt_name1 = $scope.data.opt_type != '옵션없음' ? row.attr1 ? row.attr1 : '' : '';
            row.opt_name2 = $scope.data.opt_type != '옵션없음' ? row.attr2 ? row.attr2 : '' : '';
            row.opt_name3 = $scope.data.opt_type != '옵션없음' ? row.attr3 ? row.attr3 : '' : '';
          } else {
            row.opt_name1 = row.name ? row.name : '';
            row.opt_name2 = row.attr ? row.attr : '';
            row.opt_name3 = '';
          }

          row.attr1_cd = $scope.data.opt_type != '옵션없음' ? !_.isUndefined(row.attr1_cd) && row.attr1_cd != null ? row.attr1_cd : '' : '';
          row.attr2_cd = $scope.data.opt_type != '옵션없음' ? !_.isUndefined(row.attr2_cd) && row.attr2_cd != null ? row.attr2_cd : '' : '';

          prodList.push(row);
          sale_opt_seq++;
        }
      }
      let addOptTable = $scope.prodOpt.add_opt_table || [];
      //추가구매 옵션 리스트 생성 $scope.prodOpt.add_opt_table
      if ($scope.originAddOptTable && ($scope.originAddOptTable.length > $scope.OPT_LIST_LIMIT) && $scope.originAddOptTable.length !== $scope.prodOpt.add_opt_table) {
        addOptTable = $scope.prodOpt.add_opt_table.concat($scope.originAddOptTable.slice($scope.prodOpt.add_opt_table.length));
      }

      for (const j3 in addOptTable) {
        const prod2 = addOptTable[j3];

        prod2.isNew = !prod2.sale_opt_seq;
        prod2.opt_add_price = prod2.opPrice;
        prod2.add_item_name = prod2.name;
        prod2.add_item_opt_name = prod2.opName;
        addOptList.push(prod2);
      }
      $scope.data.options = {
        prodList: prodList,
        addOptList: ($scope.originAddOptTable && ($scope.originAddOptTable.length > $scope.OPT_LIST_LIMIT) && addOptList.length !== $scope.originAddOptTable.length) ? addOptList.concat($scope.originAddOptTable.slice(addOptList.length)) : (addOptList || []),
        singleProdList: singleProdList
      };

      if ($scope.data.checkGrowth.available) {
        $scope.data.options.prodList.forEach((opt) => {
          opt.coupang.deal_type = '기초상품';
          opt.rocket.deal_type = '3자배송';

          if (opt.opt_img) {
            opt.coupang.opt_img = opt.opt_img;
            opt.rocket.opt_img = opt.opt_img;
          }

          // 옵션없음일 경우 판매자배송 판매수량 -> 쇼핑몰 판매수량으로 변경
          if ($scope.data.opt_type === '옵션없음') {
            opt.coupang.cnt_limit = $scope.data.sale_cnt_limit;
          }
        });
      }

      $scope.data.input_opt_name = '';
      if ($scope.data.insert_opt) {
        // 일반 입력형 옵션
        // 2018-11-23 chris 위메프 2.0은 입력형 롭션 사용
        if (!$scope.data.std_ol_yn || ['B719', 'A112', 'B502', 'P020', 'A001', 'A006'].includes($scope.data.pa_shop_cd)) {
          const opt_info = [];

          for (const idx in $scope.data.insert_opt_arr) {
            if ($scope.data.insert_opt_arr[idx].insert_opt !== '') {
              const data = { insert_opt: $scope.data.insert_opt_arr[idx].insert_opt };

              opt_info.push(data);
            }
          }
          $scope.data.input_opt_name = opt_info.length ? JSON.stringify(opt_info) : '';
        }
      }
    }

    /**
     * 판매자관리코드 변경사항이 있을때 중복체크 초기화.
     */
    $scope.$watch('data.c_sale_cd', function () {
      $scope.overlapResult = null;
    });

    /**
     * 판매자관리코드 중복체크
     */
    $scope.overlapResult = null;
    $scope.adminCodeCheck = async function () {
      $scope.overlapResult = null;
      if (!$scope.data.c_sale_cd) {
        $scope.alerts = { msg: '판매자관리코드를 입력해 주세요.' };
        $scope.overlapResult = 'blank';

        return false;
      }

      const site_info = [
        {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          std_ol_yn: $scope.data.std_ol_yn,
          ebay_shop_mas_no: $scope.data.ebay_shop_mas_no
        }
      ];

      const { c_sale_cd: old_c_sale_cd } = commonSVC.replaceNull(angular.copy(onlineProductModel.getOld()));

      const sendParams = {
        old_c_sale_cd,
        new_c_sale_cd: $scope.data.c_sale_cd,
        site_info,
        type: site_info[0].shop_cd === 'Z000' ? 'match' : 'edit'
      };

      await onlineProductModel.checkDuplicateMasterCd(sendParams, function (state, res) {
        if (state === 'success') {
          const { isChangeMasterCode, totalOldProducts } = res.results;

          $scope.alerts = { msg: isChangeMasterCode ? '사용 가능한 판매자관리코드 입니다.' : '이미 등록되어있는 판매자관리코드 입니다.' };
          $scope.overlapResult = isChangeMasterCode ? 'y' : 'n';

          if (isChangeMasterCode && totalOldProducts <= 2 && $scope.data.shop_cd !== 'Z000') {
            commonSVC.showConfirmHtml(
              '마스터 상품 삭제 안내',
              '<div class="sweet-alert-body">' +
            '해당 상품의 판매자관리코드를 수정하여 저장하시면<br>' +
            '연동된 쇼핑몰 상품이 더 이상 존재하지 않아 마스터 상품이 삭제됩니다.<br><br>' +
            '마스터 상품의 데이터를 유지하고자 하시는 경우에는<br>' +
            '마스터 상품을 복사해 두신 후 작업을 진행해 주세요.<br>' +
            '</div>'
            );
          }
        } else {
          commonSVC.showToaster('error', '실패', '판매자관리코드 중복체크에 실패했습니다.');
        }
      });
    };

    /**
     * 판매자관리코드 수정시 경고 팝업 출력
     * 2018-10-02 rony
     */
    $scope.checkNotice = function() {
      let msg = '';

      if ($scope.data.shop_cd === 'Z000') {
        msg = '<div class="sweet-alert-body">' +
          '◆ 상품관리<br>' +
          '<ol>' +
          '<li>해당 마스터 상품에 연동된 모든 쇼핑몰 상품의 판매자관리코드가 함께 수정됩니다.</li>' +
          '<li>수정대기 → 상품전송 시 변경된 판매자관리코드로 판매중인 상품에 적용됩니다.</li>' +
          '<li>내부 관리코드를 사용해야 하는 쇼핑몰로 상품전송 시 본 코드 변경으로<br>' +
          '상품 정보 수정, 상태 변경 등의 작업이 실패될 가능성이 있습니다.</li><br>' +
          '</ol>' +
          '◆ 주문관리<br>' +
          '<ol>' +
          '<li>주문데이터에 매핑된 판매자관리코드는 변경되지 않습니다.<br>' +
          '(매핑 당시 판매자관리코드 유지)</li>' +
          '</ol>' +
          '※ 부득이한 경우가 아니라면 판매자관리코드 변경은 시스템에 많은 혼선을 줄 수 있으므로 자제하여 주십시오.<br><br>' +
          '모든 사항을 숙지해 주시고 변경을 원하시는 경우 [확인]을 눌러주세요.' +
          '</div>';
      } else {
        msg = '<div class="sweet-alert-body">' +
          '◆ 상품관리<br>' +
          '<ol>' +
          '<li>해당 쇼핑몰 상품의 판매자관리코드만 수정합니다.</li>' +
          '<li>판매자관리코드 수정 시 현재 마스터 상품 그룹에서 해당 쇼핑몰 상품은 분리되며,<br>' +
          '입력하신 코드가 이미 존재하는 경우 해당 그룹으로 쇼핑몰 상품을 이동합니다.<br>' +
          '(단, 이동할 상품 그룹에 동일한 쇼핑몰(계정)의 상품이 존재할 경우는 이동불가.)</li>' +
          '<li>본 상품에 매칭된 ‘쇼핑몰상품 카테고리’가 이동할 마스터 상품의 카테고리와 다른 경우<br>' +
          '이동할 마스터상품에 매핑된 카테고리로 반영되어 카테고리가 변경됩니다.<br>' +
          '카테고리에 따라 추천 옵션을 제공하는 쇼핑몰인 경우 유의해주세요.</li>' +
          '<li>수정대기 → 상품전송 시 변경된 판매자관리코드로 판매중인 상품에 적용됩니다.<br>' +
          '(판매자관리코드 미지원 쇼핑몰은 해당사항없음)</li>' +
          '<li>내부 관리코드를 사용해야 하는 쇼핑몰로 상품전송 시 본 코드 변경으로<br>' +
          '상품 정보 수정, 상태 변경 등의 작업이 실패될 가능성이 있습니다.</li><br>' +
          '</ol>' +
          '◆ 주문관리<br>' +
          '<ol>' +
          '<li>주문데이터에 매핑된 판매자관리코드는 변경되지 않습니다.<br>' +
          '(매핑 당시 판매자관리코드 유지)</li><br>' +
          '</ol>' +
          '※ 부득이한 경우가 아니라면 판매자관리코드 변경은 시스템에 많은 혼선을 줄 수 있으므로 자제하여 주십시오.<br><br>' +
          '모든 사항을 숙지해 주시고 변경을 원하시는 경우 [확인]을 눌러주세요.' +
          '</div>';
      }

      if ($scope.data.edit_c_sale_cd) {
        commonSVC.showConfirmHtml('판매자관리코드 수정 시 주의사항', msg, function (Confirm) {
          if (!Confirm) {
            $timeout(function() {
              $scope.data.edit_c_sale_cd = false;
            }, 500);
          }
        });
      }
    };

    /*
     * 2018-10-04 Daniel
     * 머리말/꼬리말 적용모달
     */
    $scope.addcontentApply = function (type) {
      if (type === 'mas') {
        const params = {
          data: {
            type: 'ol_shop',
            returnYn: true,
          }
        };

        const modal = commonSVC.openModal('full', params, 'MasAddcontentCtrl', 'views/online/product/modals/mas_addcontent.html', false);

        modal.result.then(function (result) {
          $scope.data.mas_addcontent_no = result.no;
          $scope.data.mas_addcontent_name = result.name;
          $scope.data.mas_header_content = result.header_content;
          $scope.data.mas_footer_content = result.footer_content;
          $scope.renderHtml('mas_header_content');
          $scope.renderHtml('mas_footer_content');
        });
      } else {
        const params = {
          data: {
            returnYn: true,
            warehouseList: $scope.warehouseList,
            target: type
          }
        };

        const modal = commonSVC.openModal('full', params, 'OnlineProductAddcontentCtrl', 'views/online/product/modals/addcontent.html', false);

        modal.result.then(function (result) {
          if (result.target == '상세설명') {
            $scope.data.addcontent_no = result.no;
            $scope.data.addcontent_name = result.name;
          } else {
            $scope.data.shop_sale_name_addcontent_no = result.no;
            $scope.data.shop_sale_name_addcontent_name = result.name;
          }
        });
      }
    };

    $scope.addcontentDelete = async (type = 'mas') => {
      if (type == 'mas') {
        $scope.data.mas_addcontent_no = '';
        $scope.data.mas_addcontent_name = '';
        $scope.data.mas_header_content = '';
        $scope.data.mas_footer_content = '';
        $scope.renderHtml('mas_header_content');
        $scope.renderHtml('mas_footer_content');
      } else {
        const { addcontent_no: old_addcontent_no, shop_sale_name_addcontent_no: old_shop_sale_name_addcontent_no } = onlineProductModel.getOld();

        if (type == '상세설명' ? old_addcontent_no : old_shop_sale_name_addcontent_no) {

          const confirm = await commonSVC.showConfirmHtml(
            '해당 상품에 적용된 머리말/꼬리말을 <span class="text-primary">해제</span>하시겠습니까?',
            '머리말/꼬리말 해제 후 수정 버튼을 클릭하여야 변경사항이 저장됩니다.<br><br>' +
            '머리말/꼬리말 해제 후 상품상태가 아래와 같이 변경되며 쇼핑몰 반영을 위해서는 전송작업(상품전송/부분전송/상품재판매)을 진행하시기 바랍니다.<br><br>' +
            '[판매중, 수정대기, 종료대기] -> <span class="text-primary">[수정대기]</span><br>' +
            '[반려, 판매대기, 승인대기, 판매중지, 일시품절] -> <span class="text-primary">[상태유지]</span>'
          );

          if (confirm) {
            $timeout(() => {
              if (type == '상세설명') {
                $scope.data.addcontent_no = null;
                $scope.data.addcontent_name = '';
              } else {
                $scope.data.shop_sale_name_addcontent_no = null;
                $scope.data.shop_sale_name_addcontent_name = '';
              }
            });
          }
        } else {
          if (type == '상세설명') {
            $scope.data.addcontent_no = null;
            $scope.data.addcontent_name = '';
          } else {
            $scope.data.shop_sale_name_addcontent_no = null;
            $scope.data.shop_sale_name_addcontent_name = '';
          }
        }
      }
    };

    $scope.renderHtml = (id) => {
      $(`#${id}`).html($scope.data[id]);
    };

    //옵션테이블 옵션명 변경
    $scope.opt_name_edit = function(idx, options_table) {
      $scope.optNames[`edit_name${idx}`] = true;
      $scope.optNames[`option_name${idx}`] = angular.copy(options_table[0][`name${idx}`]);
    };

    $scope.opt_name_save = function(idx, options_table) {
      if ($scope.optNames[`option_name${idx}`]) {
        _.forEach(options_table, function(row) {
          row[`name${idx}`] = $scope.optNames[`option_name${idx}`];
        });

        $scope.opt_name_cancel(idx);
      } else {
        commonSVC.showMessage('주의', '수정할 옵션명을 입력하세요.');
      }
    };

    /**
     * 쇼핑몰 판매수량을 판매가능재고로 변경
     * 세트상품 및 sku 매칭 안되어 있는건 제외
     */
    $scope.apply_stock_cnt = (is_option, options_table) => {
      let base_table = angular.copy(options_table);
      let target_table = $scope.prodOpt.options_table;

      // 옵션 수 100건이 넘어 접혀있을 경우 orginProdOptionTable 데이터를 변경해줘야 함
      if ($scope.orginProdOptionTable.length > $scope.OPT_LIST_LIMIT && ($scope.initOptListCnt != $scope.totalLoadCnt)) {
        base_table = angular.copy($scope.orginProdOptionTable);
        target_table = $scope.orginProdOptionTable;
      }

      if (is_option) {
        base_table.forEach((option, index) => {
          if (option.prod_no && !option.set_no) {
            if ($scope.esmMasterFlag && $scope.data.shop_cd === 'A006') {
              target_table[index].A001_cnt_limit = option.stock_cnt;
              target_table[index].A006_cnt_limit = option.stock_cnt;
            } else {
              target_table[index].cnt_limit = option.stock_cnt;

              if ($scope.data.checkGrowth.available) {
                target_table[index].coupang.cnt_limit = option.stock_cnt;
              }
            }
          }
        });
      } else {
        if (options_table.matchStockList[0]?.stock_cnt && !options_table.matchStockList[0]?.set_no && $scope.data.std_ol_yn !== 2) {
          $scope.data.sale_cnt_limit = options_table.matchStockList[0]?.stock_cnt;
        }
      }
    };

    $scope.opt_name_cancel = function(idx) {
      $scope.optNames[`edit_name${idx}`] = false;
      $scope.optNames[`option_name${idx}`] = '';
    };

    $scope.disabledOptCheckbox = function(row, opt) {
      const checkedOpt = _.filter(opt, 'checked');
      const requiredOpt = _.filter(checkedOpt, 'required');

      if (row.checked) {
        if (checkedOpt.length < 3 + Number(data.shop_cd === 'A112' && $scope.data.std_ol_yn)) {
          if (requiredOpt.length === 3) {
            commonSVC.showMessage('추천 옵션은 2개까지 사용가능합니다');
            row.checked = false;

            return;
          }
          $scope.prodOpt.esm_checked_opt.push(row);

          row.checked = true;
          //사이즈 옵션 직접입력 불가
        } else {
          commonSVC.showMessage(`필수 옵션은 ${2 + Number(data.shop_cd === 'A112' && $scope.data.std_ol_yn)}개까지 사용가능합니다`);
          row.checked = false;
        }
      } else {
        $scope.prodOpt.esm_checked_opt = checkedOpt;
      }
    };

    /**
     * 옵션 설정 모달
     */
    $scope.openOptionModal = function(data) {
      const resolve = {
        data: {
          data: {
            ...data,
            shop_cd: $scope.data.pa_shop_cd,
            enableDirectOptions: $scope.data.pa_shop_cd === 'A092' && data.cateOpAttr.find(opt => opt.ObjOptEssenNo === '-1')
          }
        }
      };

      const modal = commonSVC.openModal('lm', resolve, 'CategoryFixedOptionCTRL', 'views/online/product/option/shop_category_opt_modal.html');

      modal.result.then(function (re) {
        data.attr = re.result.attr;
      });
    };

    $scope.openLotteOnOptModal = function(data) {
      const resolve = {};
      const param = {};

      param.cateOpAttr = _.cloneDeep(data.selectList).map((v) => {
        v.name = v.attr_val_nm;

        return v;
      });

      resolve.data = {
        data: {
          cateOpAttr: param.cateOpAttr,
          attr: param.cateOpAttr.filter(item => item.checked).map(item => item.name).join()
        }
      };

      const modal = commonSVC.openModal('lm', resolve, 'CategoryFixedOptionCTRL', 'views/online/product/option/shop_category_opt_modal.html');

      modal.result.then(function (re) {
        lotteOnOptionThen(data, re);
      });
    };

    function lotteOnOptionThen(data, re) {
      data.attr = re.checkeds.map((v) => v.attr_val_nm).join(',');
      data.attr_cd = re.checkeds.map((v) => v.opt_cd).join(',');

      for (let i = 0; i < data.selectList.length; i++) {
        data.selectList[i].checked = false;
      }

      re.checkeds.forEach((obj) => {
        for (let i = 0; i < data.selectList.length; i++) {
          if (obj.opt_cd === data.selectList[i].opt_cd) {
            data.selectList[i].checked = true;
          }
        }
      });

      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    }

    $scope.openGalleriaOptModal = function(data) {
      const resolve = {};

      resolve.data = {
        data: {
          ...data,
          cateOpAttr: data.opt_value.map(({ opt_val_nm }) => ({ name: opt_val_nm }))
        }
      };
      const modal = commonSVC.openModal('lm', resolve, 'CategoryFixedOptionCTRL', 'views/online/product/option/shop_category_opt_modal.html');

      modal.result.then(function (re) {
        data.attr = re.result.attr;
      });
    };

    //옵션 확인 모달
    $scope.openEsmOptCheck = function() {
      const resolve = {};

      resolve.data = {
        data: $scope.data.esm_options || $scope.prodOpt.esm_opt, 											  //ESM2.0, 11번가 여부
      };
      commonSVC.openModal('lm', resolve, 'esmOptCheck', 'views/online/product/option/esm_opt_check.html');
    };

    //추가항목 여부 확인
    $scope.checkChannelhModal = (pa_shop_cd) => {
      let resultFlag = false;

      switch (pa_shop_cd) {
        case 'A528':                    // 패션플러스
        case 'P018':                    // 지그재그
        case 'P039':                    // 롯데묘미
        case 'P042':                    // 올웨이즈
        case 'P058':                    // 셀러허브 스토어
        case 'A526':                    // 셀러허브 이지셀
          resultFlag = true;
          break;
        case 'A032':
        case 'A076':
        case 'A085': {
          const ableNotiCode = ['Wear2023', 'Shoes2023', 'Bag2023', 'FashionItems2023', 'SleepingGear2023', 'Furniture2023', 'ImageAppliances2023', 'HomeAppliances2023', 'SeasonAppliances2023', 'OfficeAppliances2023', 'OpticsAppliances2023', 'MicroElectronics2023', 'CellPhone2023', 'Navigation2023', 'CarArticles2023', 'MedicalAppliances2023', 'KitchenUtensils2023', 'Cosmetic2023', 'Food2023', 'ProcessedFood2023', 'HealthFunctionalFood2023', 'Kids2023', 'MusicalInstrument2023', 'SportsEquipment2023', 'Books2023', 'Etc2023', 'HouseHoldChemical2023', 'Biodical2023', 'Jewelry2023', 'RentalHa2023', 'RentalEtc2023'];

          // SSG오픈마켓,SSG종합몰의 경우 품목고시에 따라 추가항목 유무 결정
          if ($scope.data.prod_noti_data_new.every(data => !ableNotiCode.includes(data?.infoCode))) {
            resultFlag = true;
          }

          if (['A032'].includes(pa_shop_cd) && $scope.data.match_sol_cate_name.split(' > ')[1]?.includes('신선')) {
            resultFlag = false;
          }
          break;
        }
        case 'B719': {                  //위메프2.0
          //렌탈 품목 고시만 추가항목 존재
          if ($scope.data.prod_noti_data_new.every(data => !['RentalHa2023', 'RentalEtc2023'].includes(data?.infoCode))) {
            resultFlag = true;
          }
        }
      }

      return resultFlag;
    };

    /**
     * 추가항목 필수 확인
     */
    $scope.checkRequired = function(row) {
      let resultFlag = false;

      switch (row.pa_shop_cd) {
        case 'B378':										//쿠팡
        case 'B851':										//티몬
        case 'B956':										//티몬
        case 'A017':										//AK몰
        case 'A004':                    //CJ온스타일
        case 'A076':                    //SSG오픈마켓
        case 'A032':                    //SSG통합
        case 'A085':                    //신세계백화점(SSG)
        case 'A131':                    //HMall
        case 'B957':										//W컨셉
        case 'B696':                    //하이마트
        case 'P028':                    //GS프레시
        case 'B690':                    //K쇼핑
        case 'P037':                    //컴퓨존
        case 'A124':                    //한샘
        case 'A530':                    //공구마켓/할인중독/심쿵할인
        case 'P044':                    //한섬
        case 'P020':                    //롯데슈퍼
        case 'P048':                    //뚝딱
        case 'A093':                    //알리익스프레스
        case 'A092':                    //알리바바
        case 'B693':                    //도매꾹
        case 'B730':                    //오너클랜
        case 'X094':                    //알리익스프레스 글로벌
          resultFlag = true;
          break;
        case 'P045': {                  //위메프2.0
          //렌탈 품목 고시만 추가항목 존재
          if ($scope.data.prod_noti_data_new.every(data => !['RentalHa2023', 'RentalEtc2023'].includes(data?.infoCode))) {
            resultFlag = true;
          }
          break;
        }
        case 'A112': {                  // 11번가
          // 수입쇠고기 카테고리 선택시에만 필수 항목 존재
          if ($scope.shop_data?.match_cate_code?.includes('1001481') || $scope.shop_data?.match_cate_name?.includes('수입쇠고기') || $scope.data?.match_cate_name?.includes('수입쇠고기') || $scope.data?.match_sol_cate_name?.includes('수입쇠고기')) {
            resultFlag = true;
          }
          break;
        }
        case 'P054': {                   // SSF

          const cateData = Object.keys($scope.data.shop_cate_no_add_info).length ? $scope.data.shop_cate_no_add_info : $scope.data.sol_cate_shop_add_info;
          const detail_data = cateData.detailData;

          if (detail_data) {
            // 필수 KC 인증서류 및 카테고리 인증서류가 필요한 경우
            if (detail_data.kcCrtfcTgtCd !== 'TGT_SAFE_STDR' && detail_data.stdCtgryQltyDcmtList.find(data => data.essntlYn === 'Y')) {
              resultFlag = true;
            }
            // 유통기한관리 필수인 경우
            if (detail_data.distbTmlmtManageYn === 'Y') {
              resultFlag = true;
            }
          }
          break;
        }
      }

      return resultFlag;
    };

    //추가구매옵션 템플릿
    $scope.addOptTemplate = function () {
      const modal = commonSVC.openModal('xg', {}, 'addOptTemplate', 'views/online/product/option/add_opt_template.html');

      modal.result.then(function (re) {
        $scope.prodOpt.add_opt = re.add_opt;
        $scope.prodOpt.add_opt_table = re.add_opt_table;
        $scope.originAddOptTable = angular.copy($scope.prodOpt.add_opt_table);
        if ($scope.esmMasterFlag) {
          $scope.prodOpt.add_opt_table.forEach(opt => {
            opt.A001_cnt_limit = opt.cnt_limit;
            opt.A006_cnt_limit = opt.cnt_limit;
          });
        }

        $rootScope.$broadcast('addOptTemplateApplyModalClosed', $scope.prodOpt.add_opt);
      });
    };

    //옵션 불러오기
    $scope.importOpt = function (type) {
      const resolve = {};
      let currentOpt = '';

      if ($scope.data.pa_shop_cd === 'B719') {
        currentOpt = 'wmp_';
      } else if ($scope.data.pa_shop_cd === 'A112' && $scope.data.std_ol_yn) {
        currentOpt = 'std_';
      }

      resolve.data = {
        currentOpt: currentOpt,
        type: type,
        warehouseList: $scope.warehouseList,
        systemList,
        c_sale_cd: $scope.data.c_sale_cd
      };

      const modal = commonSVC.openModal('xg', resolve, 'importOpt', 'views/online/product/option/import_option.html');

      modal.result.then(function (re) {
        re.optData = re.optData.filter(op => op.deal_type !== '3자배송');
        // 해당 모달에서 상세를 열었을 시 oldData가 옵션 불러오기 내 상세 모달의 데이터로 들어가서
        // 모달 종료 시 다시 넣어줌
        onlineProductModel.setOld(angular.copy($scope.data));

        // 세트상품 아닌 옵션 추출
        if (type !== '추가구매') {
          $scope.data.opt_type = re.opt_type;

          const optData = onlineProductSVC.initOpt(re, $scope.prodOpt, '');

          if (re.opt_type === '옵션없음') {
            $scope.prodOpt.matchStockList = angular.copy(optData.matchStockList);
          } else {
            $scope.prodOpt.opt = angular.copy(optData.opt);
          }

          const data = { opt: angular.copy($scope.prodOpt.opt) };

          $timeout (() => {
            // esm 상품은 추천옵션 포함한 경우 추천옵션 정보 설정해야 함
            if (['A001', 'A006'].includes($scope.data.pa_shop_cd)) {
              $scope.prodOpt.opt.forEach((opt, i) => {
                const recommendOption = $scope.data.esm_options.find(cateOpt => opt.name === cateOpt.name);

                if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
                  opt.cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName);

                  $timeout (() => {
                    const select2 = angular.element(document.querySelector(`#esm_opt_value${i}`));

                    if (select2) {
                      select2.controller('multiSelectCombo').destroySelect2(select2);
                    }

                    opt.attr = opt.attr.split(',');
                    $scope.selectOptList[i] = [
                      ...opt.cateOpAttr,
                      ...opt.attr
                        .filter(value => !opt.cateOpAttr.some(item => item.ObjOptEssenName === value))
                        .map(opt => { return { ObjOptEssenName: opt }; })
                    ];

                    $timeout(() => {
                      select2.controller('multiSelectCombo').initSelect2(select2, {});
                    }, 0);
                  });
                } else {
                  opt.cateOpAttr = [];
                }
              });
            }

            $scope.prodOpt.options_table = onlineProductSVC.initOptionsTable({ ...data, std_ol_yn: $scope.data.std_ol_yn }, $scope.data.opt_type, optData.optData);
            $scope.orginProdOptionTable = angular.copy($scope.prodOpt.options_table);
            optionAttrCheck();
            prodListSet();

            if ($scope.esmMasterFlag) {
              $scope.prodOpt.options_table.forEach(opt => {
                opt.A001_cnt_limit = opt.cnt_limit;
                opt.A006_cnt_limit = opt.cnt_limit;
              });
            }
          });
        } else {
          const addOptData = onlineProductSVC.initAddOpt(re.optData);
          $scope.prodOpt.add_opt = addOptData.add_opt;
          $scope.prodOpt.add_opt_table = addOptData.add_opt_table;
          $scope.originAddOptTable = angular.copy($scope.prodOpt.add_opt_table);
          if ($scope.esmMasterFlag) {
            $scope.prodOpt.add_opt_table.forEach(opt => {
              opt.A001_cnt_limit = opt.cnt_limit;
              opt.A006_cnt_limit = opt.cnt_limit;
            });
          }
        }
      });
    };

    /**
     * 2018-10-04 Daniel
     * 머리말/꼬리말 상세
     */
    $scope.addcontentRule = (type) => {
      const size = 'lg-md';
      let ctrl = 'OnlineProductAddcontentRuleCtrl',
          html = 'views/online/product/modals/addcontent_rule.html',
          resolve = {
            data: {
              addcontentNo: type == '상세설명' ? $scope.data.addcontent_no : $scope.data.shop_sale_name_addcontent_no
            }
          };

      if (type === 'mas') {
        ctrl = 'MasAddcontentRuleCtrl',
        html = 'views/online/product/modals/mas_addcontent_rule.html',
        resolve = {
          data: {
            addcontentNo: $scope.data.mas_addcontent_no
          }
        };
      }

      const modal = commonSVC.openModal(size, resolve, ctrl, html, false);
      modal.result.then((result) => {
        if (type === 'mas') {
          $scope.data.mas_addcontent_no = result.no;
          $scope.data.mas_addcontent_name = result.name;
          $scope.data.mas_header_content = result.headerContent;
          $scope.data.mas_footer_content = result.footerContent;
          $scope.renderHtml('mas_header_content');
          $scope.renderHtml('mas_footer_content');
        }

        $scope.searchDo(true);
      });
    };

    // --- 쿠팡 ---------------------------
    $scope.popup_coupang_show = {
      name: [false, false, false],
      value: [false, false, false]
    };
    $scope.opt_popup_coupang_style = {
      name: {
        maxWidth: 250
      },
      value: {
      }
    };

    const coupang_popup_close = (e) => {
      $timeout(() => {
        const type = $scope.popup_coupang_detail.type;
        const idx = $scope.popup_coupang_detail.idx;

        if (!$(e.target).closest('td').is($(`#cpang_opt_${type}${idx}`).closest('td'))) {
          $scope.popup_coupang_show[type][idx] = false;
          $('html').off('mousedown', coupang_popup_close);
        }
      });
    };

    $scope.optionCoupangNameRefresh = (idx) => {
      $timeout(() => {
        if ($scope.popup_coupang_show.name[idx] && $scope.data.cpang_options.every(({ attributeTypeName }) => $scope.prodOpt.opt.map(({ name }) => name).includes(attributeTypeName))) {
          $scope.popup_coupang_show.name[idx] = false;
          $('html').off('mousedown', coupang_popup_close);
        } else if (!$scope.popup_coupang_show.name[idx] && !$scope.data.cpang_options.every(({ attributeTypeName }) => $scope.prodOpt.opt.map(({ name }) => name).includes(attributeTypeName))) {
          $scope.popup_coupang_show.name[idx] = true;
          $scope.popup_coupang_detail = { type: 'name', idx };
          $('html').mousedown(coupang_popup_close);
        }
      });
    };

    $scope.optionCoupangPopUp = (idx, type) => {
      $timeout(() => {
        if (!$scope.data.cpang_options) {
          return;
        }

        if (type === 'value') {
          if (!$scope.prodOpt.opt[idx].name || !$scope.getCoupangUsableUnits(idx).length) {
            return;
          }
          $scope.popup_target = $(`#cpang_opt_${type}${idx}`).prev('.bootstrap-tagsinput');
        } else if (type === 'name') {
          if ($scope.data.cpang_options.every(({ attributeTypeName }) => $scope.prodOpt.opt.map(({ name }) => name).includes(attributeTypeName))) {
            return;
          }
          $scope.popup_target = $(`#cpang_opt_${type}${idx}`);
        }
        $scope.popup_coupang_detail = { type, idx };
        $scope.popup_coupang_show[type][idx] = true;
        $scope.opt_popup_coupang_style[type].minWidth = $scope.popup_target[0].offsetWidth;
        $('html').mousedown(coupang_popup_close);
      });
    };

    $scope.coupangRecommendOptSelect = (p0) => {
      $timeout(() => {
        const idx = $scope.popup_coupang_detail.idx;

        $scope.prodOpt.opt[idx].name = $scope.data.cpang_options[p0].attributeTypeName;
        $scope.prodOpt.opt[idx].usableUnits = $scope.data.cpang_options[p0].usableUnits;
        $scope.popup_coupang_show = {
          name: [false, false, false],
          value: [false, false, false]
        };
      });
    };

    $scope.coupangAttrInclude = (p0) => $scope.prodOpt.opt.some(({ name }) => name === p0);
    $scope.getCoupangUsableUnits = (p0) => {
      const tmp = $scope.data.cpang_options && $scope.data.cpang_options.find(({ attributeTypeName }) => attributeTypeName == $scope.prodOpt.opt[p0].name);

      return tmp ? tmp.usableUnits : [];
    };

    // 쿠팡 로켓그로스 selectbox 선택
    $scope.changeCoupangRocket = (type) => {

      if (!$scope.data.checkGrowth[type]) {
        const otherShop = type === 'rocket' ? 'coupang' : 'rocket';

        if (!$scope.data.checkGrowth[otherShop]) {
          $scope.data.checkGrowth[otherShop] = true;

        }
      }

      if ($scope.data.checkGrowth.rocket) {
        let msg = '';

        if ($scope.data.pa_shop_cd === 'B378' && $scope.data.etc6 !== 'true') {
          msg = '로켓그로스를 사용할 수 없는 계정이 있습니다.\n[설정 > 쇼핑몰 계정관리]에서 쿠팡계정 선택 후 로켓그로스 상품 사용여부를 확인해 주세요.';
        } else if ($scope.data.pa_shop_cd === 'B378' && !$scope.data.is_possible_growth_cate) { // 카테고리 확인
          msg = '로켓그로스에서 사용 불가한 카테고리입니다.\n해당 카테고리는 판매자배송 상품만 선택 가능합니다.';
        }

        if (msg) {
          $scope.data.checkGrowth.rocket = false;
          $scope.data.checkGrowth.coupang = true;
          commonSVC.showMessage(msg, '');

          return false;
        }
      }

      $timeout(() => {});

      return true;
    };
    // --- 쿠팡 ---------------------------

    // --- ESM ---------------------------
    $scope.popup_esm_show = {
      name: [false, false, false],
      value: [false, false, false]
    };
    $scope.opt_popup_esm_style = {
      name: {
        maxWidth: 250
      },
      value: {
      }
    };

    const esm_popup_close = (e) => {
      $timeout (() => {
        if (!$scope.popup_esm_detail) {
          $('html').off('mousedown', esm_popup_close);

          return;
        }
        const type = $scope.popup_esm_detail.type;
        const idx = $scope.popup_esm_detail.idx;

        if (!$(e.target).closest('td').is($(`#esm_opt_${type}${idx}`).closest('td'))) {
          $scope.popup_esm_show[type][idx] = false;
          $('html').off('mousedown', esm_popup_close);

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        }
      });
    };

    /**
     * ESM 2.0 선택한 옵션값에 따른 추천옵션 갱신
     */
    $scope.optionEsmNameRefresh = (idx) => {
      $timeout (() => {
        const recommendOption = $scope.data.esm_options.find(option => option.name === $scope.prodOpt.esm_opt[idx].name);

        if (recommendOption?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
          $scope.prodOpt.esm_opt[idx].cateOpAttr = recommendOption.cateOpAttrList.filter(o => o.ObjOptEssenName).map(options => {
            return {
              ...options,
              name: options.ObjOptEssenName
            };
          });

          $timeout (() => {
            const select2 = angular.element(document.querySelector(`#esm_opt_value${idx}`));

            if (select2) {
              select2.controller('multiSelectCombo').destroySelect2(select2);
            }
            $scope.prodOpt.esm_opt[idx].attr = '';
            $scope.selectOptList[idx] = [ ...$scope.prodOpt.esm_opt[idx] ];

            $timeout(() => {
              select2.controller('multiSelectCombo').initSelect2(select2);
            }, 0);
          }, 0);
        } else {
          $scope.prodOpt.esm_opt[idx].cateOpAttr = [];
        }

        $scope.prodOpt.opt[idx] = $scope.prodOpt.esm_opt[idx];
      });
    };

    /**
     * ESM 2.0 추천옵션 선택 모달
     */
    $scope.optionEsmPopUp = (idx, type) => {
      $timeout (() => {
        if (!$scope.data.esm_options) {
          return;
        }

        // esm 추천옵션 중복 설정 요청으로 주석처리 - 독립형은 중복 설정 불가 처리
        if ($scope.data.esm_options.every(({ name }) => $scope.prodOpt.esm_opt.map(({ name }) => name).includes(name)) && $scope.data.opt_type === '독립형') {
          return;
        }

        $scope.popup_target = $(`#esm_opt_${type}${idx}`);
        $scope.popup_esm_detail = { type, idx };
        $scope.popup_esm_show[type][idx] = true;
        $scope.opt_popup_esm_style[type].minWidth = $scope.popup_target[0].offsetWidth;
        $('html').mousedown(esm_popup_close);
      });
    };

    /**
     * ESM 2.0 추천옵션 선택
     */
    $scope.esmRecommendOptSelect = async (idx, optIdx) => {
      $timeout (() => {
        $scope.prodOpt.esm_opt[optIdx].name = $scope.data.esm_options[idx].name;
        $scope.prodOpt.esm_opt[optIdx].name_cd = $scope.data.esm_options[idx].name_cd;

        if ($scope.data.esm_options[idx]?.cateOpAttrList?.filter(o => o.ObjOptEssenName)?.length) {
          $scope.prodOpt.esm_opt[optIdx].cateOpAttr = $scope.data.esm_options[idx].cateOpAttrList.filter(o => o.ObjOptEssenName).map(options => {
            return {
              ...options,
              name: options.ObjOptEssenName
            };
          });

          $timeout (() => {
            const select2 = angular.element(document.querySelector(`#esm_opt_value${optIdx}`));

            if (select2) {
              select2.controller('multiSelectCombo').destroySelect2(select2);
            }

            $scope.prodOpt.esm_opt[optIdx].attr = '';
            $scope.selectOptList[optIdx] = [ ...$scope.prodOpt.esm_opt[optIdx].cateOpAttr];

            $timeout(() => {
              select2.controller('multiSelectCombo').initSelect2(select2, {});
            }, 0);
          });
        } else {
          $scope.prodOpt.esm_opt[optIdx].cateOpAttr = [];
        }

        $scope.prodOpt.opt[optIdx] = $scope.prodOpt.esm_opt[optIdx];
      });

      $scope.popup_esm_show = {
        name: [false, false, false],
        value: [false, false, false]
      };
      $scope.popup_esm_detail = null;
      $scope.popup_target = null;
    };

    // esm 추천옵션 중복 설정 가능해서 주석처리 - 독립형 중복 설정 불가 처리
    $scope.esmAttrInclude = (p0) => $scope.prodOpt.esm_opt.some(({ name }) => $scope.data.opt_type === '독립형' && name === p0);

    // --- ESM ---------------------------

    // --- 알리 쇼핑몰 --------------------
    $scope[`popup_ali${ali_type}_show`] = {
      name: [false, false, false],
      value: [false, false, false]
    };
    $scope[`opt_popup_ali${ali_type}_style`] = {
      name: {
        maxWidth: 250
      },
      value: {
      }
    };

    const ali_popup_close = (e) => {
      $timeout (() => {
        if (!$scope[`popup_ali${ali_type}_detail`]) {
          $('html').off('mousedown', ali_popup_close);

          return;
        }
        const type = $scope[`popup_ali${ali_type}_detail`].type;
        const idx = $scope[`popup_ali${ali_type}_detail`].idx;

        if (!$(e.target).closest('td').is($(`#ali${ali_type}_opt_${type}${idx}`).closest('td'))) {
          $scope[`popup_ali${ali_type}_show`][type][idx] = false;
          $('html').off('mousedown', ali_popup_close);

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        }
      });
    };

    /**
     * 알리 선택한 옵션값에 따른 추천옵션 갱신
     */
    $scope[`optionAli${ali_type}NameRefresh`] = (idx) => {
      $timeout (() => {
        const setRecommendOpt = $scope.data[`ali${ali_type}_options`].find(option => option.name === $scope.prodOpt[`ali${ali_type}_opt`][idx].name);
        if (setRecommendOpt) {
          $scope.prodOpt[`ali${ali_type}_opt`][idx].cateOpAttr = setRecommendOpt.cateOpAttrList.map(options => {
            return {
              ...options,
              name: options.ObjOptEssenName
            };
          });
        } else {
          $scope.prodOpt[`ali${ali_type}_opt`][idx].cateOpAttr = [];
        }
      });
    };

    /**
     * 알리 추천옵션 선택 모달
     */
    $scope[`optionAli${ali_type}PopUp`] = (idx, type) => {
      $timeout (() => {
        if (!$scope.data[`ali${ali_type}_options`]) {
          return;
        }

        $scope.popup_target = $(`#ali${ali_type}_opt_${type}${idx}`);
        $scope[`popup_ali${ali_type}_detail`] = { type, idx };
        $scope[`popup_ali${ali_type}_show`][type][idx] = true;
        $scope[`opt_popup_ali${ali_type}_style`][type].minWidth = $scope.popup_target[0].offsetWidth;
        $('html').mousedown(ali_popup_close);
      });
    };

    /**
     * 알리 추천옵션 선택
     */
    $scope[`ali${ali_type}RecommendOptSelect`] = (idx, prevIndex) => {
      $timeout (() => {
        const detail_idx = $scope[`popup_ali${ali_type}_detail`].idx;
        const prevNameCd = $scope.prodOpt.opt[prevIndex].name_cd;
        $scope.prodOpt[`ali${ali_type}_opt`][detail_idx].name = $scope.data[`ali${ali_type}_options`][idx].name;
        $scope.prodOpt[`ali${ali_type}_opt`][detail_idx].name_cd = $scope.data[`ali${ali_type}_options`][idx].name_cd;

        // 기존 선택 추천옵션을 다른 추천옵션으로 변경 시 기존 선택 옵션 태그 초기화 처리
        if ($scope.prodOpt[`ali${ali_type}_opt`][detail_idx].name_cd !== +prevNameCd) {
          $scope.prodOpt[`ali${ali_type}_opt`][detail_idx].attr = '';
        }
        if ($scope.data[`ali${ali_type}_options`][idx].cateOpAttrList.length) {
          $scope.prodOpt[`ali${ali_type}_opt`][detail_idx].cateOpAttr = $scope.data[`ali${ali_type}_options`][idx].cateOpAttrList.map(options => {
            return {
              ...options,
              name: options.ObjOptEssenName
            };
          });
        } else {
          $scope.prodOpt[`ali${ali_type}_opt`][detail_idx].cateOpAttr = [];
        }

        $scope[`popup_ali${ali_type}_show`] = {
          name: [false, false, false],
          value: [false, false, false]
        };
        $scope[`popup_ali${ali_type}_detail`] = null;
        $scope.popup_target = null;

        // 값 담아주어야 함.
        $scope.prodOpt.opt[detail_idx] = $scope.prodOpt[`ali${ali_type}_opt`][detail_idx];
      });
    };

    // 추천옵션 중복 설정 불가 처리
    $scope[`ali${ali_type}AttrInclude`] = (p0) => $scope.prodOpt[`ali${ali_type}_opt`].some(({ name }) => name === p0);

    // --- 알리 쇼핑몰 --------------------

    // 스마트스토어 단일옵션
    $scope.popup_naver_single_show = {
      name: [false, false, false],
      value: [false, false, false]
    };
    $scope.opt_popup_naver_single_style = {
      name: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 250
      },
      value: {
      }
    };

    const naver_single_popup_close = (e) => {
      $timeout (() => {
        if (!$scope.popup_naver_single_detail) {
          $('html').off('mousedown', naver_single_popup_close);

          return;
        }
        const type = $scope.popup_naver_single_detail.type;
        const idx = $scope.popup_naver_single_detail.idx;

        if (!$(e.target).closest('td').is($(`#naver_single_opt_${type}${idx}`).closest('td'))) {
          $scope.popup_naver_single_show[type][idx] = false;
          $('html').off('mousedown', naver_single_popup_close);

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        }
      });
    };

    /**
     * 스마트스토어 단일옵션 추천옵션 선택 모달
     */
    $scope.optionNaversinglePopUp = (idx, type) => {
      $timeout (() => {
        if (!$scope.data.naver_single_options) {
          return;
        }

        $scope.popup_target = $(`#naver_single_opt_${type}${idx}`);
        $scope.popup_naver_single_detail = { type, idx };
        $scope.popup_naver_single_show[type][idx] = true;
        $scope.opt_popup_naver_single_style[type].minWidth = $scope.popup_target[0].offsetWidth;
        $('html').mousedown(naver_single_popup_close);
      });
    };

    /**
     * 스마트스토어 단일옵션 추천옵션 선택
     */
    $scope.naversingleRecommendOptSelect = (idx) => {
      $timeout (() => {
        $scope.data.naver_single_guide_id = $scope.data.naver_single_options[idx].guideId;

        if (!$scope.prodOpt.naver_single_opt.length) {
          $scope.prodOpt.naver_single_opt = angular.copy(onlineProductSVC.default_opt);
        }
        for (let optIdx = 0; optIdx < 3; optIdx++) {
          const cateOpt = $scope.data.naver_single_options[idx].cateOptList[optIdx];

          // 값이 없는경우 기본값 추가.
          if (!cateOpt) {
            $scope.prodOpt.naver_single_opt[optIdx] = { name: '', name_cd: '', attr: '', attr_cd: '', flag: false, cateOpAttr: [], custom_able: false };
          } else {
            $scope.prodOpt.naver_single_opt[optIdx].name = $scope.data.naver_single_options[idx].cateOptList[optIdx].optionName;
            $scope.prodOpt.naver_single_opt[optIdx].name_cd = $scope.data.naver_single_options[idx].cateOptList[optIdx].optionId;
            $scope.prodOpt.naver_single_opt[optIdx].guideId = $scope.data.naver_single_guide_id;
          }

          if (cateOpt?.optionValues?.length) {
            $scope.prodOpt.naver_single_opt[optIdx].cateOpAttr = cateOpt.optionValues.map(options => ({
              ...options,
              name: options.valueName
            }));

            $timeout(() => {
              const select2 = angular.element(document.querySelector(`#naver_single_opt_value${optIdx}`));

              if (select2) {
                select2.controller('multiSelectCombo').destroySelect2(select2);
              }
              $scope.prodOpt.naver_single_opt[optIdx].attr = '';
              $scope.selectOptList[optIdx] = [ ...$scope.prodOpt.naver_single_opt[optIdx].cateOpAttr ];

              $timeout(() => {
                select2.controller('multiSelectCombo').initSelect2(select2, {});
              }, 0);
            });
          } else {
            $scope.prodOpt.naver_single_opt[optIdx].cateOpAttr = [];
          }

          $scope.prodOpt.opt[optIdx] = $scope.prodOpt.naver_single_opt[optIdx];
        }
      });

      $scope.popup_naver_single_show = {
        name: [false, false, false],
        value: [false, false, false]
      };
      $scope.popup_naver_single_detail = null;
      $scope.popup_target = null;
    };
    // 스마트스토어 단일옵션

    // ESM 마스터 옥션 상품의 경우 일부만 수정이 가능하므로 수정 가능 영역 이외 비활성화 툴팁
    function makeDisabledFields () {
      $timeout(() => {
        // 수정 불가 시 전부 비활성화 툴팁
        if ($scope.esmMasterFlag && $scope.data.site_list[0].shop_cd === 'A001') {

          $('input[disabled], select[disabled], button[disabled], textarea[readonly="readonly"], div[disabled]').each(function () {
            const elm = $(this);
            if (elm.attr('name') !== 'c_sale_cd') {
              elm.addClass('menu-tooltip');
              elm.attr('tooltip-value', '공통 설정 항목은 지마켓 상품 상세에서 수정가능합니다.');
            }

          });

          // uib-tooltip으로 처리 못해서 커스텀 툴팁 사용
          $(function() {
            let title_;

            $('.menu-tooltip').hover(function(e) {      // <a> hover 시 : mouseEnter
              title_ = $(this).attr('tooltip-value');     // tooltip-value 변수에 저장
              $(this).attr('tooltip-value', '');           // tooltip-value 속성 삭제( 기본 툴팁 기능 방지 )

              if (title_) {
                $('body').append("<div id='tip'></div>");   // body 내부에 div#tip 생성

                $('#tip').css('width', 'auto');
                $('#tip').css('height', 'auto');
                $('#tip').text(title_);

                // 툴팁이 현재 hover 한 요소의 상단에 생성되도록 설정
                const pageX = e.pageX + 20;
                const pageY = e.pageY - 10;

                $('#tip').css({ left: pageX + 'px', top: pageY + 'px' }).fadeIn(1);
                $('#tip').css('z-index', '10000');
              }

            }, function() {                         // <a> hover 시 : mouseLeave

              $(this).attr('tooltip-value', title_);      // tooltip-value 속성 반환
              $('#tip').remove();                 // div#tip 삭제
            });
          });
        }
      }, 100);
    }

    // 데이터 초기화
    $scope.init();

    /**
     * 템플릿 등록
     */
    $scope.template_add = function(index) {
      onlineProductSVC.onlineTemplateDetail(0, { shop_cd: $scope.data.shop_cd, pa_shop_cd: $scope.data.pa_shop_cd }, $scope.data.shop_id, $scope.data.shop_name, $scope.openTemplate, $scope.complateAddTemplate);
    };

    /**
     * 템플릿 등록 완료 후 갱신
     * onlineTemplateDetail 내부에서 호출됨.
     */
    $scope.complateAddTemplate = function (openTemplate, flag) {
      const site = $scope.data.site_list[0];
      const param = {
        shop_cd: site.shop_cd,
        shop_id: site.shop_id,
        bookmark: 'all'
      };

      templateModel.templateOnlineProduct(param)
        .then(function (res) {
          $scope.templateTemp = res.data.results;

          _.each($scope.templateTemp, function (v) {
            if (v.template_no === site.template_no) {

              //요약정보 한번에 담기
              site.summary_data = JSON.parse(v.template_data).data;
              site.site_code = v.shop_cd;
              site.site_id = v.shop_id;
              site.template_no = v.template_no;
            }
          });

          $scope.onlineTemplate(openTemplate, flag);
        });
    };

    $scope.naverOptSelect = (type, data) => {
      if (data.selected) {
        const attrIdx = $scope.prodOpt.opt[type].selected_attribute.findIndex(attr => attr.attributeValueId === data.attributeValueId);

        $scope.prodOpt.opt[type].selected_attribute.splice(attrIdx, 1);
      } else {
        $scope.prodOpt.opt[type].selected_attribute.push(data);
      }
      data.selected = !data.selected;
      naverOptSetting(type);
    };

    $scope.changeNaverSizeList = () => {
      const new_sub_group = $scope.prodOpt.opt[1].sub_group.find(group => group.sub_group_name === $scope.prodOpt.opt[1].selected_sub_group_name);
      new_sub_group.attribute.forEach(attr => attr.selected = false);

      $scope.prodOpt.opt[1].name_cd = new_sub_group.sub_group_id;
      $scope.prodOpt.opt[1].selected_sub_group = new_sub_group.attribute;
      $scope.prodOpt.opt[1].selected_attribute = [];
      naverOptSetting(1);
    };

    $scope.openChangeColorNameModal = () => {
      const resolve = {
        data: {
          color: $scope.prodOpt.opt[0]
        }
      };
      commonSVC.openModal('lm', resolve, 'NaverChangeColorCtrl', 'views/online/product/modals/naver_opt_change_color_modal.html').result.then(() => {
        naverOptSetting(0);
      });
    };

    const naverOptSetting = (type) => {
      if ($scope.prodOpt.opt[type].selected_attribute) {
        $scope.prodOpt.opt[type].attr = $scope.prodOpt.opt[type].selected_attribute.map(selected => selected.attributeValueName).join();
        $scope.prodOpt.opt[type].attr_cd = $scope.prodOpt.opt[type].selected_attribute.map(selected => selected.attributeValueId).join();
      }
    };
    // 29cm 상세설명 리스트 추가
    $scope.clickEvent = (index, type, form) => {
      if (type === 'del') {
        if (form.length > 1) {
          form.splice(index, 1);
        } else {
          form = [];
        }
      } else {
        form.push({});
      }
    };

    // 29cm 상세 설명 이미지 타입 변경 시 초기화
    $scope.changeDescImg = function(image_type, site, index) {
      site[index] = { image_type: image_type };
    };

    $scope.optFlagChange = () => {
      $scope.flag.isOpen = $scope.flag.isOpen === 'true' ? 'false' : 'true';
      localStorageService.set('optOpen', $scope.flag.isOpen);
    };

    $scope.addOptFlagChange = () => {
      $scope.flag.isAddOptOpen = $scope.flag.isAddOptOpen === 'true' ? 'false' : 'true';
      localStorageService.set('addOptOpen', $scope.flag.isAddOptOpen);
    };

    const addContentChangedListener = $rootScope.$on('addContentChanged', function(event, data) {
      if ($scope.data.addcontent_no === data.addContentNo) {
        $scope.data.addcontent_name = null;
        $scope.data.addcontent_no = null;
        $scope.data.addcontent_target = null;
        $scope.data.addcontent_type = null;
      } else if ($scope.data.shop_sale_name_addcontent_no === data.addContentNo) {
        $scope.data.shop_sale_name_addcontent_name = null;
        $scope.data.shop_sale_name_addcontent_no = null;
        $scope.data.shop_sale_name_addcontent_type = null;
      } else if ($scope.data.mas_addcontent_no === data.addContentNo) {
        $scope.data.mas_addcontent_name = null;
        $scope.data.mas_addcontent_no = null;
        $scope.data.mas_addcontent_type = null;
      }

    });

    /**
     * 옵션 테이블 더보기 버튼 클릭 함수
     */
    $scope.loadOptList = (init, isAll, isAdd) => {
      // 현재 더보기 진행 횟수와 총 더보기 가능횟수랑 같으면 return
      if ($scope.initOptListCnt === $scope.totalLoadCnt) {
        return;
      }

      $scope.initOptListCnt += init ? 0 : 1;

      if (init) {
        $scope.prodOpt.options_table = [];
      }

      const start = $scope.prodOpt.options_table.length;
      const end = $scope.initOptListCnt * $scope.OPT_LIST_LIMIT;

      $scope.prodOpt.options_table = $scope.prodOpt.options_table.concat(isAll ? $scope.orginProdOptionTable?.slice(start) : $scope.orginProdOptionTable?.slice(start, end));

      if (isAll) {
        $scope.initOptListCnt = Math.ceil($scope.orginProdOptionTable.length / $scope.OPT_LIST_LIMIT);
      }

      if (isAdd) {
        $scope.prodOpt.options_table.push({
          prod_img: '/assets/images/upload.png',
          parent: -1,
          // opt_name1: $scope.prodOpt.opt[0].name,
          // opt_name2: $scope.prodOpt.opt[1].name,
          // opt_name3: $scope.prodOpt.opt[2].name,
          name1: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[0].name : '',
          name2: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[1].name : '',
          name3: $scope.prodOpt.opt[2] ? $scope.prodOpt.opt[2].name : '',
          name1_cd: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[0].name_cd : '',
          name2_cd: $scope.prodOpt.opt[1] ? $scope.prodOpt.opt[1].name_cd : '',
          name3_cd: $scope.prodOpt.opt[2] ? $scope.prodOpt.opt[2].name_cd : '',
          opt_img: null,
          // skuCheck: false,
          // opt_name1_cd: "",
          // opt_name2_cd: "",
          // opt_name3_cd: "",
          opt_add_price: 0,
          prod_status: '정상',
          new_opt_yn: true
        });
      }

      makeDisabledFields();
      if ($scope.data.pa_shop_cd === 'B394') {
        getGalleriaCategory();
      }

      if ($scope.data.pa_shop_cd === 'A077' && !$scope.data.std_ol_yn) {
        getNaverCategory();
      }
    };

    // 컨트롤러 종료 시 머리말/꼬리말 리스너 제거
    $scope.$on('$destroy', function() {
      addContentChangedListener();
    });

    /**
     * 이미지호스팅 모달 열기
     */
    $scope.openImageHostingModal = (type, model = 'main', imgIdx = 0, image = null) => {
      const modal = $rootScope.openImageHosting(type);

      modal.result.then((hostImages) => {
        if (hostImages) {
          if (type === 'html') {
            if (model === 'main') {
              $scope.data.detail_desc = ($scope.data.detail_desc || '') + '\n<br />\n' + hostImages.join('\n<br />\n');
            } else {
              $scope.data[model] = ($scope.data[model] || '') + '\n<br />\n' + hostImages.join('\n<br />\n');
            }
          } else {
            // 파일명이 한글인경우 정상조회가 안되는 경우가 있어 encodeURI 적용.
            if (model === 'default') {
              $scope.data.img_objects[imgIdx].files = encodeURI(hostImages[0]);
            } else {
              // $scope.data.additional_images;
              if (Object.prototype.hasOwnProperty.call(image, 'image_url')) {
                image.image_url = encodeURI(hostImages[0]);
              } else {
                image.url = encodeURI(hostImages[0]);
              }
              image.files = encodeURI(hostImages[0]);
            }
          }
        }
      });
    };
  });
