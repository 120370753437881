'use strict';

angular.module('gmpApp')
  .controller('ProdSetListCtrl', function ($scope, $compile, $timeout,
    commonSVC, settings, productSVC,
    warehouseList,
    productModel, warehouseModel,
    gettextCatalog, $rootScope, systemList, userInfo, memoTemplateList) {
    $scope.countList = { total: 0, status: 0, not_stock: 0, not_used: 0, sold_out: 0 };
    $scope.selectCount = 'total';
    $scope.prodLogInfo = {};
    $scope.prodList = {};
    $scope.currentTab = 0;
    $scope.memoTemplateList = memoTemplateList.data.results || [];

    // 엘지 커스텀용 모델번호 노출 여부
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = angular.copy(productSVC.searchData_set);
    $scope.searchForm = angular.copy(productSVC.searchForm);

    const wareHouseList = angular.copy(warehouseList.data.result);

    wareHouseList.unshift({ code: 'null', warehouse_name: '배송처없음' });

    // 설정에서 검색 기간 변수 읽어서 강제로 sdate에 넣어줌 - service 수정 전까지는 이렇게 해야 할 듯
    $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    const searchDetail = [
      {
        title: gettextCatalog.getString('배송처 선택'),
        search_name: 'delivery_vendor',
        item_list: wareHouseList || [],
        item_key: 'warehouse_name',
        item_value: 'code',
        select_value: '',
        add_class: 'select-search'
      }
    ];

    // 배송처일 때, 배송처 검색 조건 제거 #gmpkr-7591 2019-09-18 Jacob
    if ($rootScope.user_profile.auth_type === '배송처') {
      const searchIndex = _.findIndex(searchDetail, { search_name: 'delivery_vendor' });

      if (searchIndex > -1) {
        searchDetail.splice(searchIndex, 1);
      }
    }

    // 상세 조건검색 리스트
    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchBtn = {      // 상세조건 버튼
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          test_id: 'btn-work',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('세트 삭제'),
              test_id: 'dropdown-delete-set',
              action: function () {
                $scope.deleteSets();
              },
              perm_only: ['prod.product+admin']
            }
          ]
        }, {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          test_id: 'btn-excel',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              test_id: 'dropdown-excel-all',
              action: function () {
                $scope.excelDown('all');
              },
              perm_only: ['prod.product']
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              test_id: 'dropdown-excel-select',
              action: function () {
                $scope.excelDown('select');
              },
              perm_only: ['prod.product']
            }
          ]
        },
        {
          label: gettextCatalog.getString('바코드 출력'),
          add_class: 'a-btn-blue',
          test_id: 'btn-fix-safetyproduct',
          action: function() {
            $scope.printBarcode();
          },
          perm_only: ['prod.product+write']
        }
      ],
      actions_right: [
        {
          label: `<i class="icon-plus2"></i><span> ${gettextCatalog.getString('세트 상품 등록')}</span><span class="caret"></span>`,
          small_label: 'SKU세트등록',
          sub_label: '',
          btn_type: 'dropdown',
          add_class: 'col-xs-12',
          item_list: [
            {
              label: gettextCatalog.getString('세트 상품 등록'),
              test_id: 'btn-create-setproduct',
              perm_only: ['prod.product+write'],
              action: function() {
                // 세트상품 생성 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.addSet', userInfo.permission)) {
                  const modal = commonSVC.openModal('full', { data: { viewAddProd: true } }, 'addSetProdCtrl', 'views/prod/set/modals/add_set_prod.html');

                  modal.result.then(function () {
                    $scope.searchDo();
                  });
                }
              }
            }, {
              label: gettextCatalog.getString('엑셀 일괄 등록'),
              test_id: 'btn-add-excel',
              perm_only: ['prod.product+write'],
              action: function() {
                // 세트상품 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.addSet', userInfo.permission)) {
                  $scope.excelUpload('add');
                }
              }
            }, {
              label: gettextCatalog.getString('엑셀 일괄 수정'),
              hotkey: '',
              test_id: 'btn-update-excel',
              perm_only: ['prod.product+write'],
              action: function() {
                // 세트상품 권한 확인. 2019-01-04 rony
                if (commonSVC.checkPermission('stock.roles.addSet', userInfo.permission)) {
                  $scope.excelUpload('edit');
                }
              }
            }
          ]
        }
      ],
      actions_right_bottom: []
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.selectRowUniqList = [];

    $scope.isMemoDisabled = (set_no) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(set_no);

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ set_no }) => set_no);
      $scope.$apply();
    }, 200));

    /**
     *  데이터 테이블 탭1
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'set_status', 'sale_cnt_limit', 'set_prod_cnt', 'link_yn', 'link_ord_yn'],
      alignRightColumns: ['sale_price'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'sale_cnt_limit', 'set_prod_cnt', 'delivery_vendor_name'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],

      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/set/list`,
        requestWillAction: function (data) {
          if ($rootScope.memo_etc_search.page === 'prod_set' && $rootScope.memo_etc_search.search) {
            const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

            $scope.searchForm = {
              ...$scope.searchForm,
              date_type,
              sdate,
              edate,
              search_key,
              search_word,
              search_type: 'exact'
            };
            $rootScope.memo_etc_search.search = false;
          }
          data = angular.merge({}, data, $scope.searchForm);

          // 엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          if ($rootScope.user_profile.auth_type === '배송처') {
            data.delivery_vendor = $rootScope.user_profile.depot_no;
          }

          return data;
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          // 집계카운트
          $scope.countList = {
            total: result.total,
            status: result.normal,
            not_stock: result.not_stock,
            sold_out: result.sold_out,
            not_used: result.not_used
          };

          return result.results;
        }
      },
      columns:
      [
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: function(row) {
            // 메모가 있는경우 primary 컬러로 변경
            return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetails('${row.set_no}', '${row.depot_no}')" >상세</button>
              <button ng-disabled="grid.appScope.isMemoDisabled(${row.set_no})" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do"
                ng-click="grid.appScope.leftTabAction(2)">메모</button>`;
          }
        },
        {
          key: 'set_cd',
          title: '세트코드',
          width: 60
        },
        {
          key: 'wdate',
          title: '생성일',
          width: 130,
          filter: 'dateValid'
        },
        {
          key: 'set_name',
          title: '세트 상품명',
          width: 280
        },
        {
          key: 'set_status',
          title: '상태',
          width: 60,
          notCompile: true,
          tooltip: {
            text: '재고부족: 세트에 등록된 SKU상품 중 한개라도 재고부족/품절 상태인 경우\n품절 : 세트의 판매수량이 0개인 경우\n사용불가: 배송처가 다르거나 합포장이 불가한 상품으로 구성된 경우',
            appendToBody: true
          },
          template: function(row) {
            if (row.not_used) {
              return '사용불가';
            } else {
              return row.stock_status ? '재고부족' : row.set_status;
            }
          }
        },
        {
          key: 'sale_price',
          title: '세트 판매가',
          width: 100
        },
        {
          key: 'sale_cnt_limit',
          title: '판매수량',
          width: 100,
          notCompile: true,
          template: function(row) {
            if (!row.use_sale_cnt_limit_yn) {
              return '미사용';
            } else {
              return row.sale_cnt_limit;
            }
          }
        },
        {
          key: 'set_prod_cnt',
          title: '세트구성 SKU상품',
          width: 100
        },
        {
          key: 'delivery_vendor_name',
          title: '배송처',
          width: 100,
          notCompile: true,
          template: function(row) {
            return row.delivery_vendor_name || '없음';
          }
        },
        {
          key: 'mdate',
          title: '수정일',
          width: 100,
          filter: 'dateValid'
        },
        {
          key: 'link_yn',
          title: '연동상품',
          width: 70,
          template: function(row) {
            const linkIcon = row.link_yn
              ? `<a ng-click="grid.appScope.linkageModalOpen('${row.set_no}', '${row.main_prod_no}', '${row.set_cd}', '${row.delivery_vendor_name}')">보기</a>`
              : '<a class="text-grey-100">보기</a>';

            return linkIcon;
          }
        },
        {
          key: 'link_ord_yn',
          title: '연동주문',
          width: 70,
          template: function(row) {
            const linkIcon = row.link_ord_yn
              ? `<a ng-click="grid.appScope.openOrderList('${row.set_no}', '${row.set_cd}', '${row.delivery_vendor_name}')">보기</a>`
              : '<a class="text-grey-100">보기</a>';

            return linkIcon;
          }
        },
      ]
    };

    // LG 계정인 경우 모델번호 컬럼 추가
    if ($scope.viewModelNo) {
      $scope.grid.options.columns.push({
        key: 'model_no',
        title: '모델번호',
        width: 100
      });
    }

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {

      // SKU상품 엑셀 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('stock.roles.excelStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(productSVC.setExcelFieldList),
        title: '세트상품',
        url: '/app/stock/set/excel-down',
        searchForm: $scope.pageData,
        page: 'set_new'
      };

      // LG 계정인 경우 모델번호 컬럼 추가
      if ($scope.viewModelNo) {
        resolve.data.excelFieldList.push({ header: '모델번호', key: 'model_no', select: true });
      }

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;

        if (!resolve.data.count) {
          commonSVC.showMessage('조회된 상품이 없습니다.');

          return false;
        }
      } else {
        const setNoList = $scope.grid.methods.selectedData('set_no');

        if (setNoList.length == 0) {
          commonSVC.showMessage('선택된 상품이 없습니다.');

          return false;
        } else {
          resolve.data.select_list = setNoList;
          resolve.data.count = setNoList.length;
          resolve.data.isAll = false;
          resolve.data.multi_search_word = '';
        }
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 엑셀 업로드 모달창
     * */
    $scope.excelUpload = function (mode) {
      const resolve = {
        data: {
          mode: mode,
        }
      };
      const modal = commonSVC.openModal('lg', resolve, 'SetProductExcelUploadCtrl', 'views/prod/set/modals/excel_upload.html');

      modal.result.then(function () {
        $scope.searchDo(true);
      });
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      if ($scope.currentTab === 0) {
        $scope.grid.methods.reloadData(null, refresh, noDelay);
      } else if ($scope.currentTab === 1) {
        $scope.gatherGrid.methods.reloadData(null, refresh, noDelay);
      }
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function() {
      const style_no_yn = $scope.searchForm.style_no_yn;

      $scope.searchForm = angular.copy(productSVC.searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.selectCount = 'total';
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchForm.style_no_yn = style_no_yn;
      $scope.searchDo(true, true);
    };

    $scope.$on('setListReset', function() {
      $scope.resetDo();
    });

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 세트 삭제
     */
    $scope.deleteSets = function () {

      // SKU상품삭제 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('stock.roles.deleteStock', userInfo.permission) === false) {
        return false;
      }

      const selectedList = $scope.grid.methods.selectedData('all');

      if (selectedList.length == 0) {
        commonSVC.showMessage('선택된 세트상품이 없습니다.');

        return false;
      }

      // 삭제하려는 세트상품이 출고전 상태에 있는지 확인 2018-11-13 rony
      let isMapping = false;

      _.forEach(selectedList, function(prod) {
        isMapping = prod.mapping_prod > 0;

        return !(isMapping);
      });

      if (isMapping) {
        commonSVC.showMessage(gettextCatalog.getString('실패'), gettextCatalog.getString('선택한 세트상품이 매칭된 주문이 출고 전 상태에 있어 SKU상품 삭제가 불가합니다.해당 주문이 모두 출고완료된 이후 세트상품 삭제가 가능합니다.'));

        return false;
      }

      commonSVC.showConfirm('선택한 세트상품을 삭제하시겠습니까?', '- 해당 세트가 적용된 온라인 상품은 재고 자동 연동이 지원되지 않습니다. \n - 해당 세트가 매칭된 주문은 세트 매칭 해제되어  재고가 복구되니 주의하시기 바랍니다. (신규주문, 출고대기, 출고보류, 운송장 출력 상태 주문에 한함)', function () {
        productModel.deleteSet({ set_nos: $scope.grid.methods.selectedData('set_no') }, function (state, result) {
          if (result.results === 'success') {
            commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('상품 삭제 {{count}}건에 성공하였습니다', { count: selectedList.length }));
            $scope.searchDo(false);
          } else if (result.message === '대표상품 에러') {
            commonSVC.showToaster('error', '실패', '선택한 세트 상품이 대표상품으로 지정된 온라인 상품이 존재합니다. 대표상품 지정을 해제 후 삭제가 가능합니다.');

            return false;
          } else {
            commonSVC.showToaster('error', '실패', '삭제에 실패하였습니다.');

            return false;
          }
        });
      });
    };

    /***
     * 상품관리 보기 모드 변경
     * */
    $scope.changeProdViewMode = function(mode) {
      $scope.selectRowUniqList = [];
      $scope.searchForm.set_status = mode;
      $scope.searchDo(true, true);
    };

    /**
     * 세트 상세보기
     */
    $scope.showDetails = function(set_no, depot_no) {
      // 세트상품 수정시도 생성권한으로 보기
      if (commonSVC.checkPermission('stock.roles.addSet', userInfo.permission)) {
        const resolve = {
          data: { from: '수정', set_no: set_no, depot_no: depot_no }
        };

        const modal = commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');

        modal.result.then(function () {
          $scope.grid.methods.reloadData(function() {}, false);
        });
      }
    };

    /**
     * 2018-12-20 Jacob
     * 연동상품
     */
    $scope.linkageModalOpen = function (set_no, prod_no, set_cd, delivery_vendor_name) {
      const resolve = {
        data: {
          code: set_cd,
          fromSet: true,
          prod_no: prod_no,
          set_no: set_no,
          depot_name: delivery_vendor_name
        }
      };

      resolve.warehouseList = function () {
        return warehouseModel.ListAll({ use_yn: true });
      };
      commonSVC.openModal('xxg', resolve, 'ProdProductLinkageCtrl', 'views/prod/product/modals/product_linkage.html');
    };

    /**
     * 2018-12-20 Jacob
     * 연동주문
     */
    $scope.openOrderList = function (set_no, set_cd, delivery_vendor_name) {
      const templatePath = 'views/stock/inventory/order_list.html';
      const resolve = {
        resolve: {
          set_no,
          set_cd,
          fromSet: true,
          depot_name: delivery_vendor_name
        }
      };

      resolve.warehouseList = function () {
        return warehouseModel.ListAll({ use_yn: true, growth_yn: false });
      };
      resolve.systemList = function (systemModel) {
        return systemModel.load();
      };

      commonSVC.openModal('full', resolve, 'StockInventoryOrderListCtrl', templatePath);
    };

    // 테이블 컬럼 사이즈 확인
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.prod_product_list') {
        const $wrapper = $('#prod_prodlist_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    // 메모 첫 변경시 메뉴 버튼 색상 변경
    $scope.setMemoBtnActive = function (key, value, off) {
      const results = $scope.grid.methods.getResultDatas();
      const index = _.findIndex(results, (obj) => {
        return obj[key] == value;
      });

      results[index].memo_yn = off ? 0 : 1;

      $scope.grid.methods.reDraw();
    };

    // 메모 전부완료시 메뉴 버튼 색상 변경
    $scope.setMemoCompleteActive = function (key, value, off) {
      const results = $scope.grid.methods.getResultDatas();
      const index = _.findIndex(results, (obj) => {
        return obj[key] == value;
      });

      results[index].memo_complete_yn = off ? 0 : 1;
      $scope.grid.methods.reDraw();
    };

    // 메모관리에서 클릭시 검색
    $scope.$on('memoEtcLink_prod_set', function() {
      const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_etc_search;

      $scope.searchForm = {
        ...productSVC.searchForm,
        date_type,
        sdate,
        edate,
        search_key,
        search_word,
        search_type: 'exact'
      };
      $rootScope.memo_etc_search.search = false;
      $scope.searchDo(true, true);
    });

    /**
     * 바코드 출력 모달
     */
    $scope.printBarcode = function() {
      const selected = $scope.grid.methods.selectedData('set_cd');

      if (!selected.length) {
        commonSVC.showMessage('바코드 출력할 상품을 선택해 주세요.');

        return false;
      }

      const resolve = {
        data: {
          prodList: selected,
          type: 'set',
        }
      };

      commonSVC.openModal('xg', resolve, 'PrintBarcodeCtrl', 'views/prod/product/modals/print_barcode.html');

    };
  });