/**
 * 상품명 정리 매니저
 * 2018-12-12 Alvin
 */
'use strict';

angular.module('gmpApp')
  .controller('ProdNameManagerCtrl', function ($scope, data, commonSVC, $rootScope, $timeout, $uibModalInstance, localStorageService, systemList, settings, gettextCatalog, errorSVC, shipmentModel) {
    // 별칭정보 담기
    const seller_nick_info = [],
          shop_name_info = {};
    let selectedRule; // 여러건 선택시 규칙 저장

    // 커스텀 규칙 전체 적용시 검색 기준
    let applySearchData = {
      search_key: 'all',
      search_word: '',
      select_shop: ''
    };
    let isApplied = false;
    $scope.targetState = data.targetState;

    $scope.channelList = [{ shop_name: '직접 입력한 주문', shop_cd: 'A000' }, ...$rootScope.use_channel_list].filter(shop => !shop.shop_cd.startsWith('X'));

    const options = $scope.channelList.map(shop => {
      return {
        label: `${shop.shop_cd === 'A000' ? shop.shop_name : shop.shop_name + '(' + shop.shop_id + ')'}`,
        title: `${shop.shop_cd === 'A000' ? shop.shop_name : shop.shop_name + '(' + shop.shop_id + ')'}`,
        value: `("${shop.shop_cd || ''}", "${shop.shop_id || ''}")`,
      };
    });

    $timeout(() => {
      $('#shop_multi_select_prodName').multiselect({
        includeSelectAllOption: true,
        nonSelectedText: '쇼핑몰(계정)선택',
        buttonWidth: '326px',
      });
      options.unshift({ label: '전체쇼핑몰', title: '전체쇼핑몰', value: '("", "")', shop_name: '전체 쇼핑몰', shop_cd: '' });
      $('#shop_multi_select_prodName').multiselect('dataprovider', options);
    });

    $scope.showDesc = localStorageService.get('prodNameShowDescYn') || 'Y'; // Description 칸
    $scope.showDetail = false; // 규칙상세
    $scope.isEdit = false;
    $scope.eraseSelected = false; // 변경문자 검색어삭제

    // 규칙 기본data
    const ruleData = {
      shop_idx: '',
      search_type1: '부분일치',
      search_type2: 'shop_sale_name',
      mod_type: '부분변경',
      tar_str: '',
      mod_str: '',
      site_list: []
    };

    // 적용범위
    $scope.search_type2 = {
      shop_sale_name: '상품명',
      shop_opt_name: '옵션명',
      ord_opt_name: '추가구매 옵션명'
    };

    $scope.ruleData = angular.copy(ruleData);

    // chnnellist 위에 추가해야함
    $rootScope.use_channel_list.forEach(chRow => {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;

      // 쇼핑몰 이름 더해줌 ex) { 'A001': '옥션', ... }
      if (!shop_name_info[chRow.shop_cd]) {
        shop_name_info[chRow.shop_cd] = chRow.shop_name;
      }
    });

    /* 검색 및 버튼 관련 변수 */
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        select_shop: ''
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectCount: 0,       // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
        viewCount: 1,        // 몇번째 부터 시작인지 보여줌
        showCount: 25,
        search_key_items: [  // 검색영역 키값
          { label: gettextCatalog.getString('전체'), value: 'all' },
          { label: gettextCatalog.getString('검색 문자'), value: 'tar_str' },
          { label: gettextCatalog.getString('변경 문자'), value: 'mod_str' },
          { label: gettextCatalog.getString('쇼핑몰(ID)'), value: 'shop_cd' },
        ]
      }
    };

    $scope.searchData = angular.copy(search.searchData);
    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchBtn = {
      table_actions: [
        {
          label: '추가',
          add_class: 'a-btn-blue mr-3',
          action: () => {
            $scope.showDetail = true;
            $scope.isEdit = false;
            $timeout(() => {
              detailInit();
              setSelet2();
              $('#shopListMulti').trigger('focus');
            });
          },
          perm_only: ['order.unstoring+write']
        },
        {
          label: '복사{{searchData.selectCount ? \'(\' + searchData.selectCount + \')\' : \'\'}}',
          add_class: 'a-btn-blue mr-3',
          disable: () => {
            return !$scope.searchData.selectCount;
          },
          action: () => {
            $scope.copyRule();
          },
          perm_only: ['order.unstoring+write']
        },
        {
          label: '삭제{{searchData.selectCount ? \'(\' + searchData.selectCount + \')\' : \'\'}}',
          add_class: 'a-btn-blue',
          disable: () => {
            return !$scope.searchData.selectCount;
          },
          action: () => {
            $scope.removeRule();
          },
          perm_only: ['order.unstoring+write']
        },
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('엑셀 업로드'),
              action: function () {
                $scope.excelUpload();
              },
              perm_only: ['order.order+write']
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              action: function () {
                $scope.excelDownload();
              },
              perm_only: ['order.order+write']
            }
          ]
        },
      ]
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      isNavigatorCenter: true,
      // gridHeight: 400,
      pinningColumns: [],
      alignCenterColumns: [
        'ord_name_rule_no', 'shop_cd', 'search_type1', 'search_type2', 'mod_type', 'tar_str', 'mod_str', 'use_yn'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['ord_name_rule_no'],
      notSortingColumns: ['widget'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/productNameRule/list`,
        requestWillAction: function (data) {
          const assignData = Object.assign(data, $scope.searchForm);

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(assignData);

          if (data.orderby.includes('shop_cd')) {
            data.orderby = `seq ${data.orderby.split(' ')[1]}, a.${data.orderby}`;
          }

          return Object.assign(assignData, $scope.searchForm);
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.totalCount;
          $scope.searchData.viewCount = result.rowsTotal;

          // Description 영역때문에 테이블 크기 줄어들어서 min 줌
          $('#prod_name_manager_grid > div').css({ 'min-height': '521px' });

          return result.results;
        }
      },
      columns: [
        {
          key: 'ord_name_rule_no',
          title: '규칙 코드',
          width: 55,
          template: function (row) {
            return row.ord_name_rule_no || '';
          }
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 180,
          template: function (row) {
            const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

            if (row.shop_cd) {
              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                return `
                  <span uib-tooltip="${shop_name_info[row.shop_cd]}(${shop_info[0]})" tooltip-placement="right">
                    ${row.shop_cd.startsWith('U') ? `[${shop_name_info[row.shop_cd]}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                    ${shop_info[1]}
                  </span>`;
              } else {
                return '직접 입력한 주문';
              }
            } else {
              return '전체 쇼핑몰';
            }
          }
        },
        {
          key: 'search_type1',
          title: '검색조건',
          width: 75,
          template: function (row) {
            return row.search_type1 || '';
          }
        },
        {
          key: 'search_type2',
          title: '적용범위',
          width: 120,
          template: function (row) {
            return $scope.search_type2[row.search_type2];
          }
        },
        {
          key: 'mod_type',
          title: '변경방법',
          width: 75,
          template: function (row) {
            return row.mod_type || '';
          }
        },
        {
          key: 'tar_str',
          title: '검색 문자',
          width: 164,
          template: function (row) {
            return row.tar_str || '';
          }
        },
        {
          key: 'mod_str',
          title: '변경 문자',
          width: 165,
          template: function (row) {
            return row.mod_str || '';
          }
        },
        {
          key: 'use_yn',
          width: 75,
          title: '사용여부',
          template: function(row) {
            row.use_yn = !!row.use_yn;

            return '<pa-ui-switch ng-model="row.entity.use_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    $scope.orderList = data.select_list;
    $scope.applyRange = data.select_list.length ? 'select' : 'all'; // 적용범위
    $scope.applyOpt = 'custom'; // 적용 옵션

    /**
     * 적용가능 규칙 수 계산
     */
    $scope.applyRuleCnt = (type) => {
      return type === 'all' ? $scope.grid.methods.selectedData('all').length : $scope.grid.methods.selectedData('use_yn').filter(r => r).length;
    };

    /**
     * 설명 Div 접기 변경
     */
    $scope.changeShowDesc = () => {
      $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
      localStorageService.set('prodNameShowDescYn', $scope.showDesc);

      // 테이블 크기 재계산 위해서 새로고침
      $scope.searchDo(false, true);
    };

    /**
     * 리스트에서 사용여부 변경시
     */
    $scope.changeDo = async ({ ord_name_rule_no, use_yn }) => {
      try {
        await shipmentModel.prodNameRuleEdit({ ord_name_rule_no, use_yn, change_use_yn: true });

        commonSVC.showToaster('success', '', '규칙 사용여부 수정에 성공하였습니다.');
      } catch (error) {
        commonSVC.showToaster('error', '', '규칙 사용여부 수정에 실패하였습니다.');

        return false;
      }
    };

    /**
		 * 테이블 검색
		 */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);

      // 커스텀 규칙 전체 적용시 검색 기준 설정
      applySearchData = {
        search_key: $scope.searchForm.search_key,
        search_word: $scope.searchForm.search_word,
        select_shop: $scope.searchForm.select_shop
      };
    };

    /**
		 * 테이블 검색 초기화
		 */
    $scope.resetDo = function() {
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchData.search_key_name = '전체';
      $('#shop_multi_select_prodName').multiselect('dataprovider', options);
      $scope.searchDo(true, true);
    };

    /**
		 * 테이블 pageLength 조절
		 */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.selectSearchKey = function (value, key) {
      $scope.searchForm.search_key = value;
      $scope.searchData.search_key_name = key;
    };

    /**
     * 취소
     */
    $scope.cancel = function() {
      $uibModalInstance.close({ isApplied: isApplied, changeField: selectedRule });
    };

    /**
     * 규칙 적용
     */
    $scope.apply = async (customAllFlag) => {
      if (customAllFlag) {
        const confirm = await commonSVC.showConfirmCustom({
          title: '상품명 규칙 전체 적용',
          text: `[규칙 전체 적용]은 ${$scope.targetState} 리스트에서 선택하신 상품에 대해 직접 추가하신 규칙을 일괄 적용하는 기능입니다.\n\n해당 규칙을 적용하시겠습니까?`,
          confirmButtonText: '규칙적용'
        });

        if (!confirm) {
          return;
        }
      }

      const order_list = $scope.orderList || [];
      const selectedDataAll = $scope.grid.methods.selectedData('all') || [];

      const params = {
        applyRange: $scope.applyRange,
        targetState: $scope.targetState,
        applyOpt: $scope.applyOpt,
        uniqList: order_list.map(({ uniq }) => uniq),
        ruleList: customAllFlag ? [] : selectedDataAll.filter(r => r.use_yn).map(r => r.ord_name_rule_no),
        dupChecked: false,
        ...(customAllFlag && { searchForm: applySearchData })
      };

      selectedRule = selectedDataAll.map((rule) => rule.search_type2);

      try {
        const re = await shipmentModel.applyProdNameRule(params);
        const { data: { results } } = re;

        if ($scope.applyOpt === 'custom' && ((results.dupRules && results.dupRules[0].length) || (results.oppRules && results.oppRules[0].length))) {
          const resolve = {
            data: {
              dupRules: results.dupRules[0].length ? results.dupRules : null,
              oppRules: results.oppRules[0].length ? results.oppRules : null,
              uniqRules: results.uniqRules,
              uniqList: $scope.orderList.map(({ uniq }) => uniq),
              applyOpt: $scope.applyOpt,
              applyRange: $scope.applyRange,
              targetState: $scope.targetState,
            }
          };

          const modal = commonSVC.openModal('xg', resolve, 'ProdNameDupRuleCtrl', 'views/order/shipment/modals/prod_name_dup_rule.html');

          return modal.result.then(function (re) {
            // 규칙 사용여부 수정했을 경우 테이블에 새로고침
            if (re.changeUseYn) {
              $scope.searchDo();
            }
            if (re.ordCnt) {
              const contents = `<span style="font-size:20px;font-weight:700;">규칙 <span class="text-primary-700">${re.applyRuleCnt}</span>건, <span class="text-primary-700">${re.ordCnt}</span>건의 상품명 관리 완료</span>`;
              const buttons = [
                {
                  name: '상품명 관리로 이동',
                  next: () => {}
                },
                {
                  name: '결과 확인',
                  next: () => {
                    const newWin = window.open(re.url);

                    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                      commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
                    } else {
                      commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
                    }
                  }
                }
              ];

              commonSVC.showMultiButton('', contents, buttons);
              isApplied = true;
            }

            if (re.isApplied) {
              $scope.searchDo();
              $scope.showDetail = false;
            }

            return !!re.isApplied;
          }).catch((err) => {
            throw err;
          });

        } else {
          const contents = `<span style="font-size:20px;font-weight:700;">${params.applyOpt === 'custom' ? `규칙 <span class="text-primary-700">${results.applyRuleCnt}</span>건, ` : ''}
<span class="text-primary-700">${results.ordCnt}</span>건의 상품명 관리 완료</span>`;
          const buttons = [
            {
              name: '상품명 관리로 이동',
              next: () => {}
            },
            {
              name: '결과 확인',
              next: () => {
                const newWin = window.open(results.url);

                if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                  commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
                } else {
                  commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
                }
              }
            }
          ];

          commonSVC.showMultiButton('', contents, buttons);
          isApplied = true;

          $scope.searchDo();
          $scope.showDetail = false;

          return !!isApplied;
        }
      } catch (err) {
        const errMsg = errorSVC.getError('order', err.data?.error);

        commonSVC.showToaster('error', gettextCatalog.getString('실패'), errMsg);

        return false;
      }
    };

    /**
     * 규칙 삭제
     */
    $scope.removeRule = () => {
      commonSVC.showConfirm('규칙삭제', '정말 규칙을 삭제하시겠습니까?', function (re) {
        if (re) {
          shipmentModel.prodNameRuleRemove({ ruleList: $scope.grid.methods.selectedData('ord_name_rule_no') })
            .then(() => {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙이 삭제되었습니다'));
              $scope.showDetail = false;
              $scope.isEdit = false;
              $scope.searchDo();
            })
            .catch(() => {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙삭제 실패'));
            });
        }
      });
    };

    /**
     * 규칙 복사
     */
    $scope.copyRule = () => {
      shipmentModel.prodNameRuleCopy({ ruleList: $scope.grid.methods.selectedData('ord_name_rule_no') })
        .then(re => {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙이 복사되었습니다.'));
          $scope.showDetail = false;
          $scope.isEdit = false;
          $scope.searchDo();
        })
        .catch(err => {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙복사 실패'));
        });
    };

    /**
     * 규칙저장
     */
    $scope.saveRule = () => {
      const params = {
        site_list: $scope.ruleData.site_list,
        search_type1: $scope.ruleData.search_type1,
        search_type2: $scope.ruleData.search_type2,
        mod_type: $scope.ruleData.mod_type,
        tar_str: $scope.ruleData.tar_str,
        mod_str: $scope.ruleData.mod_str,
        ord_name_rule_no: $scope.ruleData.ord_name_rule_no
      };

      if ($scope.isEdit) {
        params.shop_cd = $scope.ruleData.shopUniq === 'ALL' ? null : $scope.ruleData.shopUniq.split('/')[0];
        params.shop_id = $scope.ruleData.shopUniq === 'ALL' ? null : $scope.ruleData.shopUniq.split('/')[1];
      }

      shipmentModel[$scope.isEdit ? 'prodNameRuleEdit' : 'prodNameRuleAdd'](params)
        .then(() => {
          commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString(`규칙이 ${$scope.isEdit ? '수정' : '등록'}되었습니다`));
          $scope.showDetail = false;
          $scope.isEdit = false;
          $scope.searchDo();
        })
        .catch(() => {
          commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString(`규칙${$scope.isEdit ? '수정' : '등록'}에 실패하였습니다`));
        });
    };

    /**
     * 엑셀 업로드
     */
    $scope.excelUpload = function () {
      const modal = commonSVC.openModal('xxs', '', 'prodNameExcelUpload', 'views/order/shipment/modals/prod_name_excel_upload.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo();
        }
      });
    };

    /**
     * 엑셀 다운로드
     */
    $scope.excelDownload = function () {
      const ruleList = $scope.grid.methods.selectedData('ord_name_rule_no');
      const resolve = {};

      resolve.data = {
        type: ruleList.length ? 'select' : 'all',
        excelFieldList: [],
        description: '다운로드 받으신 엑셀은 수정 후 수정 업로드하시면 솔루션에 수정 반영됩니다.',
        title: '상품명 정리 규칙',
        url: '/app/order/productNameRule/excel',
        searchForm: $scope.pageData,
        page: 'order',
        orderby: $scope.pageData.orderby,
      };

      if (!ruleList.length) {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        resolve.data.uniq = ruleList;
        resolve.data.count = ruleList.length;
        resolve.data.isAll = false;
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
    };

    /**
     * 검색어 삭제기능 클릭
     */
    $scope.clickEraseBtn = () => {
      $scope.eraseSelected = !$scope.eraseSelected;

      // 검색어 삭제기능 활성화시 변경문자 공란, 변경방법 부분변경으로 강제 변환
      if ($scope.eraseSelected) {
        $('.prodNameRule .eraseBtn').addClass('active');
        $('.modType').addClass('eraseSelected');
        $('.modStr').attr({ placeholder: '검색어를 조건에 따라 상품명 일부를 삭제합니다', disabled: true });

        $scope.ruleData.mod_type = '부분변경';
        $scope.ruleData.mod_str = '';
      } else {
        $('.prodNameRule .eraseBtn').removeClass('active');
        $('.modType').removeClass('eraseSelected');
        $('.modStr').attr({ placeholder: '변경 문자를 입력해주세요', disabled: false });
      }
    };

    /**
     * 변경방법 변경
     */
    $scope.changeModType = () => {
      if ($scope.eraseSelected && $scope.ruleData.mod_type !== '부분변경') {
        $scope.clickEraseBtn();
      }
    };

    /**
     * 규칙 상세 조회
     */
    async function getDetail(ord_name_rule_no) {
      try {
        const re = (await shipmentModel.prodNameRuleDetail(ord_name_rule_no)).data;
        const data = re.results;

        $scope.ruleData.search_type1 = data.search_type1;
        $scope.ruleData.search_type2 = data.search_type2;
        $scope.ruleData.mod_type = data.mod_type;
        $scope.ruleData.tar_str = data.tar_str;
        $scope.ruleData.mod_str = data.mod_str;
        $scope.ruleData.shopUniq = data.shop_cd ? `${data.shop_cd}/${data.shop_id || ''}` : 'ALL';
        $scope.ruleData.shop_cd = data.shop_cd;
        $scope.ruleData.shop_id = data.shop_id;
        $scope.ruleData.ord_name_rule_no = ord_name_rule_no;

        // 변경문자가 빈문자열일 경우 검색어삭제기능 활성화
        if (!$scope.ruleData.mod_str) {
          $scope.clickEraseBtn();
        }
      } catch (err) {
        commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙조회 실패'));
      }
    }

    /**
     * 규칙상세 초기화
     */
    function detailInit() {
      $scope.ruleData = angular.copy(ruleData);
      if ($scope.eraseSelected) {
        $scope.clickEraseBtn();
      }
      $('#shopListMulti').val(null).trigger('change');
    }

    /**
     * 테이블에서 선택 변경 시
     */
    $scope.$on('OnSelectChangeBefore', () => {
      // 규칙이 1개 선택되었을때만 상세 노출
      if ($scope.grid.methods.selectedData('all').length !== 1) {
        $scope.showDetail = false;
        $scope.isEdit = false;

        return;
      }

      const [ord_name_rule_no] = $scope.grid.methods.selectedData('ord_name_rule_no');

      $scope.showDetail = true;
      $scope.isEdit = true;
      detailInit();
      getDetail(ord_name_rule_no);

      $timeout(() => {
        $('#shopList').trigger('focus');
      });
    });

    // select2 : (쇼핑몰 상품 등록 > 쇼핑몰 계정 선택) 형태의 특수한 select box
    function setSelet2() {
      $('.select-fixed-multiple').select2({
        minimumResultsForSearch: Infinity,
        width: '100%',
        placeholder: '쇼핑몰(계정)선택',
        closeOnSelect: false
      });

      // 쇼핑몰 선택시
      $('.reg-channel-select').on('select2:select', function (e) {
        const selected = $(this).val(),
              site_list = [];

        // 선택된 데이터
        const lastSelected = e.params.data.id;

        // '전체 쇼핑몰(ALL)' 선택했을경우
        if (lastSelected === 'ALL') {
          // 'ALL'만 남기고 선택해제
          $(this).val('ALL').trigger('change');
          $(this).select2('close');

          $scope.ruleData.site_list = ['ALL'];

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }

          return;
        }

        // '전체 쇼핑몰(ALL)' 선택되어있는 상태에서 다른 아이디 클릭했을 경우
        if (lastSelected !== 'ALL' && selected[0] === 'ALL') {
          // 'ALL' 선택해제
          selected.splice(0, 1);
          $(this).val(selected).trigger('change');
          $(this).select2('close');
        }

        for (const s of selected) {
          const idx = parseInt(s),
                row = $scope.channelList[idx];

          site_list.push({ shop_cd: row.shop_cd, shop_id: row.shop_id });
        }

        $scope.ruleData.site_list = site_list;

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
      });

      // 쇼핑몰 선택해제시
      $('.reg-channel-select').on('select2:unselect', function (e) {
        const selected = $(this).val(),
              site_list = [];

        if (!selected.length) {
          $scope.ruleData.site_list = [];
          $scope.$apply();

          return;
        }

        for (const s of selected) {
          const idx = parseInt(s),
                row = $scope.channelList[idx];

          site_list.push({ shop_cd: row.shop_cd, shop_id: row.shop_id });
        }

        $scope.ruleData.site_list = site_list;

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
      });
    }
  });
