/**
 * 바코드출고매니저
 * 2019-05-16 rony
 */
'use strict';

angular.module('gmpApp')
  .controller('barcodeManagerCtrl', function ($scope, $rootScope, $uibModalInstance, $timeout, $filter,
    userInfo, settings,
    shipmentModel, systemModel,
    warehouseList, systemList,
    commonSVC, localStorageService, workSVC) {

    $scope.viewTab = 'action'; // action: 출고처리, complete: 출고완료 주문// 별칭정보 담기

    // 별칭정보 리스트
    const seller_nick_info = [];
    // 주문금액 분할 모듈 사용여부
    const isCalStatePerProd = $rootScope.userProfileCheck('sol_ser', 'cal_state_per_prod', 'like') && $rootScope.user_profile.auth_type !== '배송처';

    $rootScope.use_channel_list.forEach(chRow => {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    // 스캔 설정
    let barcodeSettings = {
      // 스캔할 상품 바코드 설정 (sku_set_cd: SKU(세트)코드, barcode: 바코드, stock_cd: 재고관리코드)
      barcode_type: 'sku_set_cd',
      // 매칭된 주문만 출고가능 여부
      only_match_complete_yn: false,
      // 효과음 설정 여부
      sound_yn: true,
      // 추가구매옵션 / 규칙사은품 구분 사용 여부
      addopt_gift_prefix_yn: false,
      ...userInfo.user.barcode_out_conf
    };
    $scope.settingsTemp = angular.copy(barcodeSettings); // 기본설정 저장용 임시 변수

    // 스캔 작업 모드 (1: 운송장-상품, 2: 운송장-상품-운송장, 3: 운송장 스캔시 강제 출고완료)
    $scope.scanMode = localStorageService.get(`barcodeScanMode_${userInfo.user.m_no}`) || 1;
    // 스캔 검색 결과
    $scope.scanResult = null;
    // 스캔된 주문건
    $scope.scanOrder = null;
    // 스캔 주문건의 sku상품 리스트
    $scope.scanOrdProds = [];
    // 스캔 주문건의 미매칭 리스트
    $scope.scanOrdNoProds = [];
    // 스캔한 수량
    $scope.scanCnt = 0;
    // 출고완료된 주문건의 sku상품 리스트
    $scope.completeOrdProds = [];
    // 스캔 마지막 성공 결과 저장용 변수
    const scanSuccessResult = {
      scanResult: null,
      scanOrdProds: [],
      scanOrdNoProds: [],
    };
    // 스캔 주문건의 sku상품 리스트 원본
    let scanOrdProdsOri = [];

    let ordProds = []; // 주문상품 리스트

    /**
     * 스캔 작업모드 변경.
     */
    $scope.changeType = function () {
      localStorageService.set(`barcodeScanMode_${userInfo.user.m_no}`, $scope.scanMode);

      init();
    };

    /**
     * 해당 컨트롤러에 키다운 이벤트 발생시 처리.
     */
    $(document).bind('keydown', function (e) {

      // // keydown 이벤트가 발생되면 검색 input 에 포커스 줌.
      // $('#search').focus();

      // Ctrl + j 막기 ( 바코드 인식할떄 ctrl + j 가 눌리면서 크롬다운로드 창이 열림 )
      if (e.ctrlKey && (e.which == 74)) {
        e.preventDefault();

        return false;
      }
    });

    /**
     * 한글 영어로 치환
     */
    $scope.ko2en = _.debounce(input => {
      // 재고관리코드는 한글 입력 가능하므로 제외
      if (barcodeSettings.barcode_type !== 'stock_cd') {
        $scope.barcodeActionGrid.searchForm.search_word = commonSVC.ko2en(input);
        $timeout(() => {}, 10);
      }
    }, 50);

    /**
     * 검색
     */
    $scope.searchBarcode = async () => {
      let ordScanResult = { result: false, ttsMsg: '' },
          prodScanResult = { result: false, ttsMsg: '' };

      // 인풋 영문자 치환 디바운스 걸려있어서 약간 딜레이 줌
      await $timeout(() => {}, 100);

      try {
        // 상품 스캔 하나라도 진행된 상태에서는 송장 검색x / 출고완료나 보류한 상태 & 운송장-상품-운송장 스캔모드일때는 송장 검색o
        if (!$scope.scanCnt
          || ($scope.scanResult?.outYn || $scope.scanResult?.holdYn)
          || ($scope.scanMode === 2 && $scope.barcodeActionGrid.searchData.totalCount === $scope.scanCnt)) {
          ordScanResult = await $scope.searchOrder($scope.barcodeActionGrid.searchForm.search_word?.toLowerCase());
        }

        // 송장 스캔결과가 없고 기존 송장스캔결과(상품리스트)가 있는 경우 상품리스트에서 확인
        if (!ordScanResult.result && $scope.scanOrdProds.length && !$scope.scanResult?.outYn && !$scope.scanResult?.holdYn) {
          prodScanResult = await $scope.searchProd($scope.barcodeActionGrid.searchForm.search_word?.toLowerCase());
        }

        if (!ordScanResult.result && !prodScanResult.result && $scope.barcodeActionGrid.searchForm.search_word) {
          $scope.scanOrdProds = [];
          $scope.scanOrdNoProds = [];
          $scope.searchDo(true, true, 'barcodeActionGrid');
        }

        // 키워드 초기화
        $scope.barcodeActionGrid.searchForm.search_word = '';
        $('#search').trigger('focus');

        $timeout(() => {});
        if (prodScanResult.ttsMsg === 'soundEffect') {
          // 효과음
          soundEffect();
        } else {
          // tts 메시지
          tts(prodScanResult.ttsMsg || ordScanResult.ttsMsg);
        }
      } catch (err) {
        // 키워드 초기화
        $scope.barcodeActionGrid.searchForm.search_word = '';
        $('#search').trigger('focus');
        tts(err);
      }
    };

    /**
     * 운송장번호로 주문검색.
     */
    $scope.searchOrder = async keyword => {
      if (!keyword) {
        if ($scope.scanResult?.outYn || $scope.scanResult?.holdYn) {
          $scope.scanOrdProds = [];
          $scope.scanOrdNoProds = [];
          $scope.searchDo(true, true, 'barcodeActionGrid');
          $scope.scanResult = {
            status: 'error',
            statusMsg: '송장번호를 찾을 수 없습니다.',
          };
        }

        return { result: false, ttsMsg: '' };
      }

      try {
        const ordProdResult = await shipmentModel.barcodeOrdProdList($scope.barcodeActionGrid.searchForm);

        ordProds = ordProdResult.data.results;

        if (!ordProds.length) {
          $scope.scanResult = {
            status: 'error',
            statusMsg: '송장번호를 찾을 수 없습니다.',
            outYn: $scope.scanResult?.outYn,
            holdYn: $scope.scanResult?.holdYn,
          };

          return { result: false, ttsMsg: '없는 송장' };
        } else if (!ordProds.every(o => o.ship_avail_yn)) {
          $scope.scanResult = { ...ordProds[0], ship_avail_yn: ordProds.every(o => o.ship_avail_yn), status: 'error', statusMsg: '출고 불가한 송장번호 입니다.' };
          $scope.scanOrder = ordProds[0];
          $scope.scanOrdProds = [];
          $scope.scanOrdNoProds = [];
          $scope.searchDo(true, true, 'barcodeActionGrid');

          return { result: true, ttsMsg: '불가' };
        } else {
          let ttsMsg = '정상';

          // 주문상품 리스트 가공
          const setResult = setOrderProdList(ordProds);
          const matchedProds = setResult.matchedProds,
                unmatchedProds = setResult.unmatchedProds;

          if ($scope.scanMode === 2
            && !$scope.scanResult?.outYn
            && $scope.scanOrder?.invoice_no
            && $scope.barcodeActionGrid.searchData.totalCount === $scope.scanCnt
          ) { // 스캔 작업모드: 운송장-상품-운송장
            $scope.scanResult.status = 'success';
            $scope.scanResult.statusMsg = '출고완료 처리';
            $scope.scanResult.outYn = true;
            ttsMsg = '출고완료';

            if ($scope.scanOrder?.invoice_no !== ordProds[0].invoice_no) {
              return { result: false };
            }

            if ($scope.scanOrdNoProds.length) {
              if (barcodeSettings.only_match_complete_yn) {
                $scope.scanResult.status = 'error';
                $scope.scanResult.statusMsg = 'SKU 미매칭 주문 : 출고불가';
                ttsMsg = '미매칭출고불가';

                return { result: true, ttsMsg };
              } else {
                $scope.scanResult.statusMsg = 'SKU 미매칭 주문 : 출고완료';
                ttsMsg = '미매칭출고완료';
              }
            }

            // 출고완료 처리
            const re = await $scope.setOrdComplete(ordProds[0].bundle_no);
            if (!re) {
              return { result: false, ttsMsg: '출고실패' };
            }
          } else {
            $scope.scanResult = { ...ordProds[0], status: 'success', statusMsg: '송장번호 정상' };
            $scope.scanOrder = ordProds[0];

            // 스캔한 상품의 sku상품 리스트
            $scope.scanOrdProds = matchedProds.map(o => ({ ...o, sale_cnt: $scope.scanResult.sale_cnt }));
            // 스캔한 상품의 미매칭 리스트
            $scope.scanOrdNoProds = unmatchedProds;
            $scope.searchDo(true, true, 'barcodeActionGrid');

            $scope.barcodeActionGrid.searchData.totalCount = $scope.scanOrdProds.reduce((acc, cur) => {
              return acc + (!cur.set_yn ? +cur.total_pack_unit : 0);
            }, 0);

            // 미매칭이 있는 경우
            if ($scope.scanOrdNoProds.length) {
              $scope.scanResult.status = 'error';
              $scope.scanResult.statusMsg = 'SKU 미매칭 주문';
              ttsMsg = '미매칭';
            }

            // 스캔 초기화용 값 저장
            scanSuccessResult.scanResult = { ...$scope.scanResult };
            scanSuccessResult.scanOrdProds = angular.copy($scope.scanOrdProds);
            scanSuccessResult.scanOrdNoProds = angular.copy($scope.scanOrdNoProds);
            $scope.scanCnt = 0;

            // 초기화용 원본 저장
            scanOrdProdsOri = angular.copy($scope.scanOrdProds);

            if ($scope.scanMode === 3) { // 스캔 작업모드: 운송장 스캔시 강제 출고완료)
              if (barcodeSettings.only_match_complete_yn && $scope.scanOrdNoProds.length) {
                $scope.scanResult.status = 'error';
                $scope.scanResult.statusMsg = 'SKU 미매칭 주문 : 출고불가';
                ttsMsg = '미매칭출고불가';

                return { result: true, ttsMsg };
              }

              $scope.scanResult.status = 'error';
              $scope.scanResult.statusMsg = '강제출고 완료';
              ttsMsg = '강제출고 완료';

              // 출고완료 처리
              const re = await $scope.setOrdComplete($scope.scanOrder.bundle_no);
              if (!re) {
                return { result: false, ttsMsg: '출고실패' };
              }
            }
          }

          return { result: true, ttsMsg };
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '운송장/상품 검색에 실패했습니다');
        throw '검색 실패';
      }
    };

    /**
     * 스캔 초기화
     */
    $scope.resetScan = () => {
      // 상품 스캔 상태인 경우 상품 스캔만 초기화
      if ($scope.scanCnt) {
        $scope.scanCnt = 0;
        $scope.scanOrdProds = angular.copy(scanOrdProdsOri);
        $scope.scanResult.status = '';
        $scope.scanResult.statusMsg = '';
        $scope.searchDo(true, true, 'barcodeActionGrid');
      }
      // 정상 송장 스캔상태면 아무 작업x
      else if ($scope.scanResult?.status === 'success') {
        return false;
      }
      // 상품 스캔 전 실패 상태인 경우 마지막 성공 결과로 초기화
      else if (scanSuccessResult.scanOrdProds.length) {
        $scope.scanResult = { ...scanSuccessResult.scanResult };
        $scope.scanOrdProds = angular.copy(scanSuccessResult.scanOrdProds);
        $scope.scanOrdNoProds = angular.copy(scanSuccessResult.scanOrdNoProds);
        $scope.searchDo(true, true, 'barcodeActionGrid');
      }
    };

    /**
     * 바코드로 상품검색.
     */
    $scope.searchProd = async keyword => {
      if (!keyword) {
        return { result: false, ttsMsg: '' };
      }

      // 스캔한 바코드와 일치하는 상품 리스트
      const matchingProds = $scope.scanOrdProds.filter(prod => {
        if (barcodeSettings.barcode_type === 'sku_set_cd') {
          return prod.sku_cd?.toLowerCase() === keyword || (prod.set_yn && prod.set_cd.toLowerCase() === keyword);
        } else {
          return prod[barcodeSettings.barcode_type]?.toLowerCase() === keyword;
        }
      });

      // 스캔수량 초과하지 않은 상품 선택. 없으면 첫번째 상품 선택
      const selectProd = matchingProds.find(prod => prod.scan_cnt < prod.total_pack_unit) || matchingProds[0];

      if (!selectProd) {
        const msg = !$scope.scanCnt ? '송장번호를 찾을 수 없습니다.' : '진행중인 작업이 있습니다. 스캔 초기화 후 작업해주세요.';
        $scope.scanResult = { status: 'error', statusMsg: msg };

        return { result: true, ttsMsg: !$scope.scanCnt ? '없는송장' : '진행중인 작업이 있습니다.' };
      } else {
        let ttsMsg = 'soundEffect';
        const targetProds = selectProd.set_yn ? $scope.scanOrdProds.filter(prod => prod.groupId === selectProd.groupId && !prod.set_yn) : [selectProd],
              scanPlusCnt = selectProd.set_yn ? targetProds.reduce((acc, cur) => acc += cur.pack_unit, 0) : 1;

        $scope.scanResult = { ...$scope.scanOrder, status: 'success', statusMsg: `상품코드 정상 ${keyword}` };

        // 스캔수량 초과 확인
        if (targetProds.some(e => e.scan_cnt + (selectProd.set_yn ? e.pack_unit : 1) > e.total_pack_unit)) {
          $scope.scanResult.status = 'error';
          $scope.scanResult.statusMsg = '상품 초과포장';

          return { result: true, ttsMsg: '초과' };
        }

        // 상품 스캔 카운트 증가
        targetProds.forEach(prod => prod.scan_cnt += (selectProd.set_yn ? prod.pack_unit : 1));
        $scope.scanCnt += scanPlusCnt;
        if (selectProd.set_yn) {
          // 세트row도 스캔 카운트 증가
          selectProd.scan_cnt += scanPlusCnt;
        } else if (selectProd.set_cd) {
          $scope.scanOrdProds.find(p => p.groupId === selectProd.groupId && p.set_yn).scan_cnt++;
        }
        $scope.searchDo(true, true, 'barcodeActionGrid');

        if (selectProd.scan_cnt < selectProd.total_pack_unit) {
          ttsMsg = '하나더';
        } else if ($scope.scanCnt === $scope.barcodeActionGrid.searchData.totalCount) {
          if ($scope.scanMode === 1) { // 스캔 작업모드: 운송장-상품
            // 상품스캔 메세지 노출 후에 출고완료 처리
            $timeout(async () => {
              if (barcodeSettings.only_match_complete_yn && $scope.scanOrdNoProds.length) {
                $scope.scanResult.status = 'error';
                $scope.scanResult.statusMsg = 'SKU 미매칭 주문 : 출고불가';

                tts('미매칭출고불가');
              } else {
                $scope.scanResult.status = 'success';
                $scope.scanResult.statusMsg = '매칭상품 모두 스캔완료 : 출고완료 처리';
                $scope.scanResult.outYn = true;

                if ($scope.scanOrdNoProds.length) {
                  $scope.scanResult.statusMsg = 'SKU 미매칭 주문 : 출고완료';
                }

                // 출고완료 처리
                const re = await $scope.setOrdComplete($scope.scanOrder.bundle_no);

                if (re) {
                  tts($scope.scanOrdNoProds.length ? '미매칭출고완료' : '출고완료');
                }
              }

            }, 100);
          } else { // 스캔 작업모드: 운송장-상품-운송장
            ttsMsg = '스캔완료';
          }
        }

        return { result: true, ttsMsg };
      }
    };

    /**
     * 주문상세
     */
    $scope.viewOrderDetail = function (uniq) {
      // 조회된 주문이 없는경우 에러.
      if (!uniq) {
        commonSVC.showMessage('조회된 주문데이터가 없습니다.');

        return false;
      }

      const resolve = {
        data: {
          fromPage: 'barcode',
          uniq: uniq,
          warehouseList: warehouseList.data.result || [],
          systemList: systemList.data || []
        }
      };

      const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchOrder();
        }
      });
    };

    /**
     * 출고보류 처리.
     */
    $scope.orderHold = async () => {
      if ($scope.scanResult?.outYn || $scope.scanResult?.holdYn) {
        $scope.searchBarcode();

        return false;
      }

      if (!$scope.scanResult?.bundle_no) {
        $scope.scanResult = { status: 'error', statusMsg: '송장번호를 찾을 수 없습니다.' };
        tts('없는송장');

        return false;
      }

      try {
        const uniqList = $scope.scanOrdProds.filter(o => o.bundle_no === $scope.scanResult.bundle_no).map(o => o.uniq);
        const result = await shipmentModel.setStatus({ status: '출고보류', uniqList, fromBarcode: true });

        if (result.data === 'success') {
          scanSuccessResult.scanResult = null;
          scanSuccessResult.scanOrdProds = [];
          scanSuccessResult.scanOrdNoProds = [];

          $scope.scanResult = { status: 'error', statusMsg: '출고보류 완료', holdYn: true };
          commonSVC.showToaster('success', '성공', '출고보류에 성공하였습니다.');
          tts('출고보류 완료');

          // 상태 문구 변경이 바로 반영안돼서 추가
          $timeout(() => {});

          return true;

        } else {
          commonSVC.showToaster('error', '실패', '출고보류에 실패하였습니다');
          tts('오류');

          return false;
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '출고보류에 실패하였습니다');
        tts('오류');

        return false;
      }

    };

    /**
     * 주문상품리스트 가공
     */
    const setOrderProdList = ordProds => {
      let matchedProds = ordProds.filter(o => o.prod_no);
      const unmatchedProds = ordProds.filter(o => !o.prod_no);

      // 묶음주문 내 같은 sku상품끼리 묶고 출고수량 합산 (세트에 들어있는 경우 세트상품내에서만 합산)
      if (!barcodeSettings.addopt_gift_prefix_yn) {
        matchedProds = matchedProds.reduce((acc, cur) => {
          const prod = acc.find(({ set_cd, sku_cd }) => set_cd === cur.set_cd && sku_cd === cur.sku_cd);

          if (prod) {
            prod.total_pack_unit += cur.total_pack_unit;
          } else {
            acc.push(cur);
          }

          return acc;
        }, []);
      }

      // 세트상품 처리
      if (matchedProds.length) {
        // 세트끼리 그룹핑 && 데이터테이블 그룹핑용 아이디 부여
        const setProds = matchedProds.reduce((acc, cur, idx) => {
          if (cur.set_cd) {
            const key = barcodeSettings.addopt_gift_prefix_yn ? `${cur.set_cd}|${cur.bundle_no}|${cur.uniq}|${cur.ord_opt_seq}` : cur.set_cd;

            if (!acc[key]) {
              acc[key] = [];
            }
            cur.groupId = `##${key}`;
            acc[key].push(cur);
          } else {
            cur.groupId = `##${idx}`;
          }

          // 스캔수량 초기화
          cur.scan_cnt = 0;

          return acc;
        }, {});

        if (!_.isEmpty(setProds)) {
          // 세트상품 row 추가
          for (const key in setProds) {
            const [set_cd, bundle_no, uniq, ord_opt_seq] = key.split('|');
            const prods = matchedProds.filter(o => {
              if (barcodeSettings.addopt_gift_prefix_yn) {
                return o.set_cd === set_cd && o.bundle_no === +bundle_no && o.uniq === uniq && o.ord_opt_seq === +ord_opt_seq;
              } else {
                return o.set_cd === set_cd;
              } });

            if (prods.length) {
              const setProdInfo = {
                bundle_no: prods[0].bundle_no,
                uniq: prods[0].uniq,
                set_yn: true,
                ord_opt_seq: prods[0].ord_opt_seq,
                add_opt_yn: prods[0].add_opt_yn,
                set_cd: prods[0].set_cd,
                set_no: prods[0].set_no,
                set_name: prods[0].set_name,
                pack_unit: prods.reduce((acc, cur) => acc + cur.pack_unit, 0),
                opt_sale_cnt: prods[0].opt_sale_cnt,
                groupId: prods[0].groupId,
                scan_cnt: 0,
                total_pack_unit: prods.reduce((acc, cur) => acc + cur.total_pack_unit, 0),
                shop_sale_name: prods[0].shop_sale_name,
                shop_opt_name: prods[0].shop_opt_name,
                ord_opt_name: prods[0].ord_opt_name,
                sale_price: prods[0].set_sale_price,
              };

              matchedProds.push(setProdInfo);
            }
          }

          // 정렬
          matchedProds = _.orderBy(matchedProds, ['add_opt_yn', 'gift_yn', 'set_cd', 'ord_opt_seq', 'uniq', 'set_yn'], ['asc', 'desc', 'desc', 'desc', 'asc', 'asc']);
        }
      }

      if (unmatchedProds.length) {
        unmatchedProds.forEach(o => {
          o.opt_sale_cnt = o.opt_sale_cnt || o.sale_cnt;
        });
      }

      return { matchedProds, unmatchedProds };
    };

    /**
     * 출고완료 작업
     */
    $scope.setOrdComplete = async bundle_no => {
      const completeOrderInfo = angular.copy($scope.scanOrdProds.concat($scope.scanOrdNoProds));

      const selected = completeOrderInfo.filter(o => o.bundle_no === bundle_no);
      const bundle_codes = selected.map(o => o.bundle_no.toString());
      const uniqList = selected.map(o => o.uniq);

      selected.forEach(o => {
        const prods = $scope.scanOrdProds.filter(p => p.uniq === o.uniq);

        o.prod_list = prods.length ? prods : [{ sku_cd: '', prod_name: '' }];
      });
      const params = {
        selected,
        bundle_codes,
        uniqList,
        fromBarcode: true
      };

      try {
        const result = await shipmentModel.orderFinish(params);

        if (result.data.results === 'success') {
          scanSuccessResult.scanResult = null;
          scanSuccessResult.scanOrdProds = [];
          scanSuccessResult.scanOrdNoProds = [];

          commonSVC.showToaster('success', '성공', '출고완료 상태변경에 성공하였습니다.');

          return true;
        } else {
          commonSVC.showToaster('error', '실패', '출고완료 상태 변경에 실패했습니다.');
          tts('오류');

          return false;
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '출고완료 상태 변경에 실패했습니다.');
        tts('오류');

        return false;
      }
    };

    /**
     * 강제 출고완료
     */
    $scope.forceOrdComplete = async () => {
      if ($scope.scanResult?.outYn || $scope.scanResult?.holdYn) {
        $scope.searchBarcode();

        return false;
      }

      if (!$scope.scanResult?.bundle_no) {
        $scope.scanResult = { status: 'error', statusMsg: '송장번호를 찾을 수 없습니다.' };
        tts('없는송장');

        return false;
      }

      if (!$scope.scanResult?.ship_avail_yn) {
        tts('불가');

        return false;
      }

      const result = await $scope.setOrdComplete($scope.scanResult.bundle_no);

      if (result) {
        $scope.scanResult = { status: 'error', statusMsg: '강제출고 완료', outYn: true };
        tts('강제출고 완료');
        // 상태 문구 변경이 바로 반영안돼서 추가
        $timeout(() => {});
      }
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, type) {
      $scope[type].methods.reloadData(function () { }, refresh, noDelay, true);
    };

    /**
     * 검색 초기화
    */
    $scope.resetDo = type => {
      $scope[type].searchForm.search_word = '';
      $scope.searchDo(true, true, type);
    };

    /**
     * 바코드 출고처리 - 상품리스트 데이터테이블
     */
    $scope.barcodeActionGrid = {};
    $scope.barcodeActionGrid.searchForm = {
      type: 'barcode',
      search_word: '',
    };
    $scope.barcodeActionGrid.searchData = {
      keyword: '',
      type: 'invoice_no',
      totalCount: 0
    };
    $scope.barcodeActionGrid.methods = {
      searchDo: () => {
        $scope.searchDo(true, true, 'barcodeActionGrid');
      }
    };
    $scope.barcodeActionGrid.options = {
      modal: true,
      gridHeight: 400,
      selectOptions: {
        notClickable: true,
        checkbox: false,
      },
      pinningColumns: [],
      alignCenterColumns: ['set_cd', 'sku_cd', 'barcode', 'stock_cd', 'stock_cnt_real', 'opt_sale_cnt', 'total_pack_unit', 'scan_cnt', 'sale_price'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: 'ALL',
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: ['shop_sale_name', 'shop_opt_name', 'gift_name'],
      bundleOptions: {
        bundleCountKey: 'setCnt',
        bundleDataKey: 'groupId',
        bundleUniqKey: 'prod_no'
      },
      rowNgClass: `{
        'ui-grid-bundle-row-yellow': row.entity['scan_cnt'] && row.entity['scan_cnt'] == row.entity['total_pack_unit'],
        'ui-grid-bundle-row-red': row.entity['scan_cnt'] && row.entity['scan_cnt'] < row.entity['total_pack_unit'] }`,
      enablePaginationControls: false,
      externalRequestOptions: {
        isDirectInsert: true, // api 통신 없이 데이터 직접 입력 여부
        directData: $scope.scanOrdProds, // 직접 입력할 데이터
        requestWillAction: function (data) {
          $scope.barcodeActionGrid.methods.setDirectData($scope.scanOrdProds);

          return data;
        },
        requestDidAction: function (result) {
          $scope.prodGridData = $scope.barcodeActionGrid.methods.getResultDatas();

          return result.results;
        }
      },
      columns: [
        {
          key: 'prod_name',
          title: 'SKU(세트)상품명',
          width: 250,
          hideColVis: true,
          notCompile: true,
          template: function (row) {
            const img = !row.set_yn ? `<img src='${row.prod_img || '/assets/images/upload.png'}' width='25' height='25' class='mr-10'>` : '',
                  label = row.gift_yn ? '<span class="label label-default bg-indigo-300">사은</span> ' : (row.add_opt_yn ? '<span class="label label-default bg-teal-300">추가</span> ' : '');

            return `${img}${barcodeSettings.addopt_gift_prefix_yn ? label : ''}${row.set_yn ? row.set_name : row.prod_name.replace(/\ /g, '&nbsp')}`;
          }
        },
        {
          key: 'attri',
          title: '속성',
          width: 120,
          hideColVis: true,
          notCompile: true,
          template: function (row) {
            return row.attri || '';
          }
        },
        {
          key: 'set_cd',
          title: '세트코드',
          width: 90,
          hideColVis: true,
          notCompile: true,
          template: row => {
            return row.set_no ? row.set_cd : '';
          }
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 100,
          hideColVis: true,
        },
        {
          title: '바코드',
          key: 'barcode',
          width: 90
        },
        {
          title: '재고관리코드',
          key: 'stock_cd',
          width: 100
        },
        {
          title: '실재고',
          key: 'stock_cnt_real',
          width: 60,
          hideColVis: true,
          notCompile: true,
          template: function (row) {
            return row.stock_cnt_real < 0 ? 0 : row.stock_cnt_real;
          }
        },
        {
          key: 'opt_sale_cnt',
          title: '주문수량',
          width: 60,
          hideColVis: true,
          notCompile: true,
          template: row => {
            return row.set_yn || !row.set_no ? row.opt_sale_cnt : '';
          }
        },
        {
          key: 'total_pack_unit',
          title: '총 출고수량',
          width: 60,
          hideColVis: true,
        },
        {
          key: 'scan_cnt',
          title: '스캔수량',
          width: 60,
          hideColVis: true,
          notCompile: true,
          template: function (row) {
            return `<span class="text-primary">${row.scan_cnt || 0}</span>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 500,
          template: function(row) {
            const img = `<img src='${row.sale_img || '/assets/images/upload.png'}' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'></img>`;

            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            return `<span>${row.sale_img ? img : ''}${filteredShopSaleName}</span>`;
          }
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          notCompile: true,
          template: function(row) {
            return row.ord_opt_seq === 1 ? row.shop_opt_name : row.ord_opt_name;
          }
        },
        {
          key: 'gift_name',
          title: '사은품',
          tooltip: '쇼핑몰에서 수집된 사은품이 노출됩니다',
          width: 100
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 150,
          tooltip: 'SKU 수량 1개의 판매가 입니다. (출고수량에 비례하지 않음)',
          template: function(row) {
            let price = 0;
            if (row.gift_yn) {
              // 사은품의 경우 사은품에 매칭된 sku상품 판매가 노출
              if (row.sku_cd) {
                price = row.sale_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            }
            if (row.set_yn) {
              // 세트상품인 경우 세트상품 판매가 우선으로 노출해주고 세트상품 판매가 없으면 세트 내 매칭된 SKU판매가 총합 출력
              if (row.sale_price || row.set_sale_price) {
                price = row.set_sale_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || row.sale_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              } else {
                price = row.set_sale_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            } else {
              if ((row.sale_price || row.sale_price === 0) && row.sku_cd) {
                // sku_cd 존재하고 판매가 존재하는 조건
                price = row.sale_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            }

            return price;
          }
        }
      ]
    };

    /**
     * 바코드 출고처리 - 주문리스트 데이터테이블
     */
    $scope.barcodeCompleteGrid = {};
    $scope.barcodeCompleteGrid.searchData = {
      type: 'invoice_no',
      selectCount: 0,
      totalCount: 0,
      bundleCnt: 0,
      selectBundleCnt: 0,
    };

    $scope.barcodeCompleteGrid.searchForm = {
      type: 'complete',
      search_word: '',
    };
    $scope.barcodeCompleteGrid.methods = {};
    $scope.barcodeCompleteGrid.searchFn = {
      searchDo: () => {
        $scope.searchDo(true, true, 'barcodeCompleteGrid');
      },
      resetDo: () => {
        $scope.resetDo('barcodeCompleteGrid');
      }
    };
    $scope.barcodeCompleteGrid.options = {
      modal: true,
      gridHeight: 430,
      pinningColumns: [],
      alignCenterColumns: ['widget', 'set_cd', 'sku_cd', 'barcode', 'stock_cd', 'stock_cnt_real', 'opt_sale_cnt', 'total_pack_unit', 'sale_cnt'],
      bundleOptions: {
        bundleCountKey: 'selectBundleCnt',
        bundleDataKey: 'bundle_no',
        bundleUniqKey: ['uniq', 'ord_opt_seq', 'prod_no']
      },
      alignRightColumns: [],
      defaultSortingColumns: ['out_time'],
      notSortingColumns: ['widget', 'out_time', 'shop_sale_name', 'sku_cd', 'carr_name', 'invoice_no', 'sale_cnt', 'total_cnt', 'shop_opt_name', 'prod_name', 'attri', 'set_name', 'set_cd', 'bundle_no', 'shop_ord_no', 'shop_cd'],
      notResizingColumns: ['widget'],
      notMovingColumns: ['widget'],
      notVisibleColumns: ['shop_opt_name', 'prod_name', 'attri', 'set_name', 'set_cd', 'bundle_no', 'shop_ord_no', 'shop_cd', ],
      enablePaginationControls: false,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/order/barcode/listOfOrdProd`,
        requestWillAction: function (data) {
          data = angular.merge({}, data, $scope.barcodeCompleteGrid.searchForm);

          return data;
        },
        requestDidAction: function (result) {
          $scope.barcodeCompleteGrid.searchData.totalCount = result.results.length;
          $scope.barcodeCompleteGrid.searchData.bundleCnt = result.bundleTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          width: 65,
          title: '도구',
          template: function(row) {
            return `<button type="button" class="btn btn-default btn-xxs" ng-click="grid.appScope.viewOrderDetail('${row.uniq}')">주문상세</button>`;
          }
        },
        {
          key: 'out_time',
          title: '출고완료일',
          width: 200,
          filter: 'dateValid',
          hideColVis: true,
        },
        {
          key: 'shop_sale_name',
          title: '쇼핑몰 상품명',
          width: 500,
          template: function(row) {
            const img = `<img src='${row.sale_img || '/assets/images/upload.png'}' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'></img>`,
                  label = row.gift_yn ? '<span class="label label-default bg-indigo-300">사은</span> ' : (row.ord_opt_seq > 1 ? '<span class="label label-default bg-teal-300">추가</span> ' : '');

            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            return `<span>${row.sale_img ? img : ''}${barcodeSettings.addopt_gift_prefix_yn ? label : ''}${filteredShopSaleName}</span>`;
          }
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          requireStock: true,
          width: 200
        },
        {
          key: 'carr_name',
          title: '택배사',
          width: 150,
        },
        {
          key: 'invoice_no',
          title: '운송장번호',
          width: 250,
          template: function(row) {
            if ($rootScope.possibeTrackingView.includes(row.carr_no)) {
              return (
                `<span>${
                  row.invoice_no
                }<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shipmentTrackingView('${
                  row.carr_no
                }','${
                  row.invoice_no.replace(/-/gi, '')
                }')"></i></span>`
              );
            } else {
              return row.invoice_no;
            }
          }
        },
        {
          key: 'sale_cnt',
          title: '주문수량',
          width: 120,
        },
        {
          key: 'total_pack_unit',
          title: '총 출고수량',
          width: 150,
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 220,
          notCompile: true,
          template: function(row) {
            const label = row.gift_yn ? '<span class="label label-default bg-indigo-300">사은</span> ' : (row.ord_opt_seq > 1 ? '<span class="label label-default bg-teal-300">추가</span> ' : '');

            return `${barcodeSettings.addopt_gift_prefix_yn ? label : ''}${row.ord_opt_seq === 1 ? row.shop_opt_name : row.ord_opt_name}`;
          }
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 250,
          notCompile: true,
          template: function (row) {
            if (row.prod_name) {
              const img = `<img src='${row.prod_img || '/assets/images/upload.png'}' width='25' height='25' class='mr-10'>`;

              return `${img}${row.prod_name.replace(/\ /g, '&nbsp')}`;
            } else {
              return '';
            }
          }
        },
        {
          key: 'attri',
          title: '속성',
          width: 120,
          notCompile: true,
          template: function (row) {
            return row.attri || '';
          }
        },
        {
          key: 'set_name',
          title: '세트 상품명',
          width: 250,
        },
        {
          key: 'set_cd',
          title: '세트코드',
          width: 250,
        },
        {
          key: 'bundle_no',
          title: '묶음번호',
          width: 250,
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호',
          width: 190,
          filter: 'isNullHyphen'
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            let img = '<div>직접입력</div>';

            // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
            if (row.shop_cd !== 'A000') {
              const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

              img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                ${shop_info[1]}
              </span>`;
            }

            return img;
          }
        },
      ]
    };

    /**
     * tts 음성 출력
     */
    const tts = msg => {
      if (!barcodeSettings.sound_yn) {
        return false;

      }
      commonSVC.tts(msg);
    };

    /**
     * 효과음 출력
     */
    const soundEffect = () => {
      if (!barcodeSettings.sound_yn) {
        return false;
      }

      const audio = new Audio('/assets/sound/product_scan.wav');
      audio.play();
    };

    /**
     * 탭 변경.
     */
    $scope.selectTab = function (type) {
      $scope.viewTab = type;
      if (type === 'action') {
        $timeout(function () {
          $('#search').focus();
        });
      }
    };

    /**
     * 닫기
     */
    $scope.cancel = function () {
      $uibModalInstance.close('cancel');
    };

    /**
     * 초기화
     */
    const init = async () => {
      $scope.scanResult = null;
      $scope.scanOrdProds = [];
      $scope.searchDo(true, true, 'barcodeActionGrid');
      $scope.scanOrdNoProds = [];
      $scope.scanOrder = null;
      $scope.scanCnt = 0;
      $scope.barcodeActionGrid.searchData.totalCount = 0;
      scanSuccessResult.scanResult = null;
      scanSuccessResult.scanOrdProds = [];
      scanSuccessResult.scanOrdNoProds = [];
      scanOrdProdsOri = [];

      $scope.searchDo(true, true, 'barcodeCompleteGrid');
    };

    /**
     * 운송장 전송
     * 1초에 한번씩 작업 가능하도록 쓰로틀 추가
     */
    $scope.invoiceSender = _.throttle(function () {
      // 운송장 전송 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('order.roles.sendDelivNo', userInfo.permission) === false) {
        return false;
      }

      let selected = $scope.barcodeCompleteGrid.methods.selectedData('all');

      if (!selected.length) {
        $scope.barcodeCompleteGrid.methods.selectAllRows();
        selected = $scope.barcodeCompleteGrid.methods.selectedData('all');
      }

      const dataNone = _(selected)
        .groupBy('bundle_no')
        .filter(function (arr_d) {
          const imposCnt = _(arr_d)
            .filter(function (o) {
              return o.ord_status !== '출고완료' || o.shop_cd === 'A000' || o.shop_ord_no.includes('_copy') || o.shop_cd.startsWith('U') || o.ori_uniq || (o.misc_etc?.[0]?.is_addOpt_order && !selected.find(s => s.uniq === o.ori_uniq));
            })
            .size();

          return arr_d.length === imposCnt;
        })
        .value();

      const addText = [];
      // 롯데백화점(롯데닷컴) - 중동 - 네이버 운송장 전송 예외처리 추가 lucas
      // 주문금액 분할 모듈 켜져 있을 경우 안내문구 추가
      if (dataNone.length || selected.some(ord => ord.sol_no === 2747 && ord.shop_cd === '1009' && ord.etc2 === '1')) {
        addText.push(`쇼핑몰에서 수집한 주문건 중 '출고완료' 상태의 주문 및 복사되지 않은 주문만 전송이 가능합니다.${isCalStatePerProd ? '\nESM+(옥션, 지마켓) 주문의 "추가구매옵션"이 원주문과 분리되어 개별 발송되는 경우, 추가구매옵션의 운송장번호는 쇼핑몰로 전송되지 않습니다.' : ''}`);
      }
      // 롯데ON 주문 중 [교환주문] 플래그가 붙은 주문은 전송 불가
      if (selected.some(ord => ord.exchange_ord_yn === 1 && ord.shop_cd === 'A524')) {
        addText.push('롯데ON 주문 중 [교환주문] 플래그가 붙은 주문은 전송 불가능합니다');
      }

      if (addText.length) {
        const opt = {
          title: '운송장 전송 실패',
          text: `${addText.join('\n\n')}`,
          confirmButtonText: '운송장 전송 가능 상태 주문만 조회'
        };

        commonSVC.showConfirmCustom(opt, function () {
          const reselected_indexes = $scope.barcodeCompleteGrid.methods.doSelectByFilter(function (r) {
            return r.ord_status === '출고완료' && r.shop_cd !== 'A000' && !r.shop_ord_no.includes('_copy') && !r.shop_cd.startsWith('U') && (r.shop_cd === 'A524' ? r.exchange_yn !== 1 : true) && !r.ori_uniq && !(r.sol_no === 2747 && r.shop_cd === '1009' && r.etc2 === '1') && !r.misc_etc?.[0]?.is_addOpt_order;
          }, true);

          if (!reselected_indexes.length) {
            commonSVC.showToaster('error', '자동 선택 불가', '운송장 전송이 가능한 건이 없습니다.');
          }
        });

        return false;
      } else {
        // 묶음건중 출고완료건만 전송해야 하기때문에 출고완료건만 걸러냄 2018-07-11 rony
        const uniqs = _(selected)
          .filter(function (o) {
            return o.ord_status === '출고완료';
          })
          .map('uniq')
          .value();

        var params = { numbers: uniqs };

        commonSVC.showConfirmHtml('운송장 전송', `<b> 선택한 출고 완료 주문 (${selected.length})건의 운송장 정보를 쇼핑몰로 전송 합니다. </b> ` + '<br>전송 후 \'배송중\' 상태로 변경되며, 수정전송은 불가능하므로 주의바랍니다.', function (state) {
          if (state) {
            workSVC.addWorkSelect('SEND_INVOICE', params);
          }
        });
      }
    }, 1000);

    /**
     * 출고완료 취소
     */
    $scope.cancelOrdComplete = async () => {
      const uniqs = $scope.barcodeCompleteGrid.methods.selectedData('uniq');

      if (!uniqs.length) {
        commonSVC.showMessage('선택된 주문이 없습니다.');

        return false;
      }

      commonSVC.showConfirmHtml(
        '선택한 ' +
        '<span class="text-primary">' +
        uniqs.length +
        '</span>건의 주문을 출고완료 취소 하시겠습니까?',
        '* 선택한 주문은 <span class="text-success">[운송장출력]</span>상태로 변경됩니다.',
        async confirm => {
          if (confirm) {
            const params = {
              status: '운송장출력',
              uniqList: uniqs,
              fromBarcode: true
            };

            try {
              const result = await shipmentModel.setStatus(params);

              if (result.data === 'success') {
                $scope.searchDo(true, true, 'barcodeCompleteGrid');
                init();

                commonSVC.showToaster('success', '성공', '운송장출력 상태변경에 성공하였습니다.');

                return true;
              } else {
                commonSVC.showToaster('error', '실패', '운송장출력 상태 변경에 실패했습니다.');
                tts('오류');

                return false;
              }
            } catch (err) {
              commonSVC.showToaster('error', '실패', '운송장출력 상태 변경에 실패했습니다.');
              tts('오류');

              return false;
            }
          }
        }
      );
    };

    /**
     * 바코드 출고 매니저 설정 저장
     */
    $scope.saveSettings = async () => {
      try {
        const result = await systemModel.set({ change_val: { barcode_out_conf: JSON.stringify($scope.settingsTemp) } });

        if (result.data.result === 'success') {
          commonSVC.showToaster('success', '성공', '기본설정 저장 성공');
          barcodeSettings = angular.copy($scope.settingsTemp);
          userInfo.user.barcode_out_conf = $scope.settingsTemp;
          $scope.closeSettings();

          // 스캔 전부 초기화
          init();
        } else {
          commonSVC.showToaster('error', '실패', '기본설정 저장 실패');
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '기본설정 저장 실패');
      }
    };

    /**
     * 바코드 출고 매니저 설정 초기화
     */
    $scope.initSettings = () => {
      $scope.settingsTemp = angular.copy(barcodeSettings);
    };

    /**
     * 바코드 출고 매니저 설정 닫기
     */
    $scope.closeSettings = () => {
      $('.barcode-settings.dropdown-menu').parent().removeClass('open');
    };

    /**
     * 기본설정 드랍다운 내부 클릭시 닫힘 방지
     */
    $(document).on('click', '.barcode-settings.dropdown-menu', function (e) {
      $(this).parent().is('.open') && e.stopPropagation();
    });
  });
