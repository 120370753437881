/**
 *  ptgui-regex-mask
 *  2016-09-06 권윤학
 */
'use strict';

angular.module('ptgui.regexMask', [])
  .directive('ptguiRegexMask', function () {
    const regexs = {
      //한글 입력불가
      notKor: /^[^ㄱ-힣ㅏ-ㅣ]*$/,
      //영문 숫자만 가능
      alphanumeric: /^[a-zA-Z0-9]*$/,
      //영문 숫자 _ 만 가능
      alphanumeric_: /^[a-zA-Z0-9_,]*$/,
      //영문 숫자 공백 _ 만 가능
      alphanumeric_space_: /^[a-zA-Z0-9\s_]*$/,
      //영문 숫자 _ , .만 가능
      alphanumericdot: /^[a-zA-Z0-9,.\- ]*$/,
      //영문 숫자 _ - # + . / ( ) [ ] 만 가능
      alphanumeric_plus: /^[a-zA-Z0-9_~\-#+/[\]().]*$/,
      // 영문 숫자 _ - + . , / ( )
      alphanumeric_equal: /^[a-zA-Z0-9,./_+=()-]*$/,
      //한글 영문 숫자 공백
      alphanumkor_space: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s]*$/,
      //영문 숫자 공백
      alphanum_space: /^[a-zA-Z0-9\s]*$/,
      //영문 숫자 공백 -
      alphanumHyphenSpace: /^[-a-zA-Z0-9\s]*$/,
      //한글 영문 숫자 _ , / ~ - .
      alphakorspec: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9_~\-+()%/\s.,[\]{}]*$/,
      //영문 숫자 _ , / ~ - .
      alphaspec: /^[a-zA-Z0-9_~\-+()%/\s.,[\]{}]*$/,
      //영문 숫자 , _  공백 만 가능
      alphanumericcomma_: /^[a-zA-Z0-9_,\\-\s]*$/,
      //한글 영문 만 가능
      alphakor: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]*$/,
      //한글 영문 숫자 _ - + . , / ( )
      alphanumeric_equalkor: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9,./_+=()-]*$/,
      // 영문 숫자 특수문자 가능
      alphaNumEtc: /^[a-zA-Z0-9,.<>/?~()_[\]{}|;:!@#$%^&*+=-]*$/,
      // 영문 만 가능
      onlyEng: /^[a-zA-Z]*$/,
      // 숫자 만 가능
      onlyNum: /^[0-9]*$/,
      // 소수점 2자리까지
      onlyNum_two: /^[-]?[0-9]{0,10}([.]{1}[0-9]{0,2}|.{0})$/,
      // 마이너스값 혹은 숫자만 가능 숫자 length 15
      onlyNum_minus: /^[-]?[0-9]{0,15}$/,
      // 숫자 - 만 가능
      onlyNum_: /^[0-9-]*$/,
      // 0~100 숫자만 가능
      onlyNum_0to100: /^(100|[1-9]?[0-9])$/,
      // 한글 영문 숫자 공백 - . : 만 가능
      validSettDates: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s\-.:]+$/,
      // 한글 영문 숫자 공백 , - _ / ~ + , . ( ) { } [ ] % & : ' 만 가능
      validSett: /^[a-zA-Z0-9가-힣\s\-_/~+,.(){}[\]%&:‘]*$/,
      // 숫자 - ,만 가능
      onlyNumComma_: /^[0-9-,]*$/,
      // 숫자  ,만 가능
      onlyNumComma: /^[0-9,]*$/,
      //한글 영문 숫자 _ 공백만 가능
      alphanumericKorEtc: /^[a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣\\,\\_\\\-\\~\\+\\/\s]*$/,
      //한글 영문 숫자 _.()/공백만 가능
      validProdModel: /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\s`~!@#$%^&*()_+-=[\]{}|;:'",.<>/?\\]{0,100}$/,
      //한글 영문 숫자 _.()/공백만 가능
      validProdBrand: /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\s`~!@#$%^&*()_+-=[\]{}|;:'",.<>/?\\]{0,40}$/,
      //한글 영문 숫자 _.()/공백만 가능
      validProdMaker: /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣a-zA-Z0-9\s`~!@#$%^&*㈜()_+-=[\]{}|;:'",.<>/?\\]{0,40}$/,
      //한글 영문 숫자만 가능
      alphanumericKor: /^[a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]*$/,
      //한글 영문 숫자 = 띄어쓰기 가능 (원산지)
      alphanumericKorEqual: /^[a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣= ]*$/,
      // 판매자관리코드용, 쿼리 등에 문제되는 특수문자 제외 모든 문자 허용
      almostEveryChar: /^[^\\|'"`]*$/,
      // 1이상의 양수
      positiveNum: /^[1-9][0-9]*$/,
      // 소수점 1자리까지 양수
      positiveNum_one: /^[0-9]+([.]{1}[0-9]{0,1}|.{0})$/,
      //영문, 숫자, 하이픈
      alphanumhyp: /^[a-zA-Z0-9-]*$/,
      //온라인상품명, SKU상품명, 주문자명, 수령자명 한글, 영문, 숫자, 공백, 일부 특수문자
      nameAlphaNumKorEtc: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s-_/~+,.(){}[\]%&']+$/,
      //주문자영문명 영문, 숫자, 공백, 일부 특수문자
      nameAlphaNumEtc: /^[a-zA-Z0-9\s-_/~+,.(){}[\]%&']+$/,
      //한글, 영문, 숫자, 공백, (, ), [, ], _, - 사용가능
      alphaKorNumEtc: /^[a-zA-Z0-9가-힣|ㄱ-ㅎ|ㅏ-ㅣ()[\]\s_-]+$/,
      //한글 영문 숫자 _.-만 가능
      validateModelno: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9-_.]+$/,
      // 모든 외국어 character set 및 - _ / ~ + , . ( ) { } [ ] % & ' 입력가능
      allLanguageEtc: /^[\p{L}\p{M}\p{N}\p{Pc}\p{Pd}\p{Pe}\p{Pf}\p{Pi}\p{Po}\p{Ps}\p{S}/~+,.()[\]{}%&' \t\r\n\f\v]+$/u,
      //온라인상품명 - 한글, 영문, 숫자, 공백, 일부 특수문자(└포함)
      shopSaleNameAlphaNumKorEtc: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s-_/~+,.(){}[\]%&└':]+$/,
      // 양식명 - 한글, 영문, 숫자, 공백, 일부 특수문자 가능 (+ # ? 제외)
      templateNameAlphaNumKorEtc: /^[a-zA-Z0-9,.<>/~()_[\]{}|;:!@$%^&*=\sㄱ-ㅎㅏ-ㅣ가-힣]*$/,
      // 4바이트 이모지를 제외한 문자 허용
      alphaNumEtcExceptEmoji: /^(?:(?![\uD800-\uDFFF]).[\r\n]*)*$/,
      // 영문, 숫자, 공백 ( ) - _ = + . / ,
      alphaKorNumBarcode: /^[a-zA-Z0-9()\s-_=./,+]+$/,
      // 한글 영문소문자 숫자만 가능
      smallAlphanumericKor: /^[a-z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]*$/,
      // 한글, 영문, 숫자, 공백 - _ / ~ + , . ( ) { } [ ] % & '(알림톡 템플릿 명)
      alphaKorNumSpaceEtc: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s-_/~+,.(){}[\]%&']+$/,
      // 한글, 영문, 숫자 공백- _ / ~ + , . ( ) { } [ ] % & ' # (알림톡 템플릿 강조제목)
      alphaKorNumSpaceEtcSharp: /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s-_/~+,.(){}[\]%&'#]+$/,
      // 사은품 규칙명 | 문자만 입력 불가
      giftRuleNameExceptVerticalBar: /^[^|]*$/,
      // 한글, 영문, 숫자 공백 - _ 가능
      alphanumHyphenSpaceUnderBar: /^[-a-zA-Z0-9_\s]*$/,
      // 한글, 영문, 숫자 ,만 가능
      alphaNumKorComma: /^[a-zA-Z0-9ㄱ-힣,]*$/,
      // 숫자 - , *만 가능
      onlyNumCommaAst_: /^[0-9,*-]*$/
    };

    function getRegex(name) {
      return regexs[name] || new RegExp(name);
    }

    function pre($scope, element, attrs, ngModelCtrl) {
      // angular 자체적으로 validation 체크 시 빈값일 경우
      // undefined 처리되어 아래와 같이 처리.
      // ngModelCtrl.$$setOptions({
      //   allowInvalid: true,
      //   updateOnDefault: true
      // });
      ngModelCtrl.$options.$$options.allowInvalid = true;
      ngModelCtrl.$options.$$options.updateOnDefault = true;
    }

    function link($scope, element, attrs, ngModelCtrl) {

      let lastValidViewValue;

      if (!attrs.ptguiRegexMask) {
        return;
      }

      const regexName = attrs.ptguiRegexMask;

      const REGEX = getRegex(regexName);

      function changeViewValue(value) {
        ngModelCtrl.$viewValue = value;
        ngModelCtrl.$commitViewValue();
        ngModelCtrl.$render();
      }

      ngModelCtrl.$parsers.push(function (value) {
        // 2018-07-12 chris ng-model 변수에 값을 강제로 넣은경우 마지막 입력값 체크가 안되서 추가
        if (!lastValidViewValue) {
          lastValidViewValue = ngModelCtrl.$modelValue;
        }

        if (ngModelCtrl.$isEmpty(value)) {
          lastValidViewValue = value;

          return '';
        }

        if (REGEX.test(value)) {
          // 올바른 값일 경우
          lastValidViewValue = value;

          return value;
        } else {
          // 처음 값이 없는 경우 undefined -> "" 로 변경해서 리턴
          if (lastValidViewValue == undefined) { lastValidViewValue = ''; }

          // 글자수를 줄이는경우에는 마지막값을 현재 변경된 값으로 설정.
          if (value.length < lastValidViewValue.length) {
            let matchValue = '';

            for (const i of value) {
              if (REGEX.test(i)) {
                matchValue += i;
              }
            }

            lastValidViewValue = matchValue;
          }

          // 올바르지 않은 값일 경우 마지막 값으로 render
          changeViewValue(lastValidViewValue);

          return lastValidViewValue;
        }
      });
    }

    return {
      restrict: 'A',
      require: 'ngModel',
      compile: function(element) {
        return {
          pre: pre,
          post: link
        };
      }
    };
  });
