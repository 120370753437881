'use strict';

angular.module('gmpApp')
  .service('onlineProductModel', function (settings, commonSVC, userInfo, $rootScope) {

    let _oldData = {};

    return {
      setOld: function (old) {
        _oldData = old;
      },

      getOld: function () {
        return _oldData;
      },
      add: function (param, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online`;
        const vdata = {
          c_sale_cd_auto_yn: param.adminAuto,
          depot_no: param.depot_no,
          esm_depot_no: param.esm_depot_no,
          cpang_depot_no: param.cpang_depot_no,
          wmp_depot_no: param.wmp_depot_no,
          main_prod_no: param.main_prod_no,
          model_prod_no: param.model_prod_no,
          main_sale_opt_seq: param.main_sale_opt_seq,
          sol_cate_no: param.sol_cate_no,
          prodall: param.prodall,
          master_prod: param.master_prod,
          site_list: param.site_list,
          cert_info: param.cert_info,
          prod_noti_data_new: param.prod_noti_data_new,
          etcMadein: param.etcMadein,
          madein_no: param.madein_no,
          multi_madein_yn: param.multi_madein_yn,
          tax_type: param.tax_type,
          minor: param.minor,
          barcode: param.barcode,
          isbn: param.isbn,
          global_barcode_type: param.global_barcode_type,
          global_barcode: param.global_barcode,
          c_sale_cd: param.c_sale_cd,
          ship_price_type: param.ship_price_type,
          ship_price_free_criteria: param.ship_price_free_criteria,
          ship_price: param.ship_price,
          gift_name: param.gift_name,
          hscd: param.hscd,
          addcontent_no: param.addcontent_no,
          shop_sale_name_addcontent_no: param.shop_sale_name_addcontent_no,
          mas_addcontent_no: param.mas_addcontent_no,
          over_delivery: param.over_delivery,
          prod_weight: param.prod_weight,
          add_opt: param.add_opt,
          img_objects: param.img_objects,
          detail_desc: param.detail_desc.trim().replaceAll('​', '').replaceAll(' ', ''),
          detail_desc_ad: param.detail_desc_ad.trim().replaceAll('​', '').replaceAll(' ', ''),
          detail_desc_add: param.detail_desc_add.trim().replaceAll('​', '').replaceAll(' ', ''),
          detail_desc_eng: param.detail_desc_eng.trim().replaceAll('​', '').replaceAll(' ', ''),
          detail_desc_chn: param.detail_desc_chn.trim().replaceAll('​', '').replaceAll(' ', ''),
          detail_desc_jpn: param.detail_desc_jpn.trim().replaceAll('​', '').replaceAll(' ', ''),
          checked: param.checked,
          match_opt_type: param.match_opt_type,
          add_opt_type: param.add_opt_type,
          input_opt_name: param.input_opt_name,
          esm_opt_name: param.esm_opt_name,
          std_opt_name: param.std_opt_name,
          wmp_opt_name: param.wmp_opt_name,
          hplus_opt_name: param.hplus_opt_name,
          opt_sort1_cd: param.opt.esm_opt[0] ? param.opt.esm_opt[0].name_cd : '', //옵션명 코드
          opt_sort2_cd: param.opt.esm_opt[1] ? param.opt.esm_opt[1].name_cd : '',
          keyword: param.keyword,
          matchImg: param.matchImg,
          made_date: param.made_date,
          expire_date: param.expire_date,
          expiration_month: param.expiration_month,
          certDataList: param.certDataList,
          nextday_ship_ol_yn: param.nextday_ship_ol_yn,
          nextday_ship_avail_yn: param.nextday_ship_avail_yn,
        };

        const masterProdBase = param.site_list.find(({ pa_shop_cd }) => pa_shop_cd === param.master_prod.pa_shop_cd);
        try {
          if (!param.prod_noti_data_new[0]) {
            throw new Error('상품정보제공고시-공통을 입력해주세요.');
          }
          angular.forEach(param.img_objects, function (v, k) {
            vdata[`img_file${k}`] = v.files;
          });

          //쇼핑몰별 개별 이미지 등록 여부
          angular.forEach(param.site_list, function(v, k) {
            const flag = _.filter(v.sub_img_objects, function(row) {
              return row.sub_img_url;
            });

            if (flag.length) {
              vdata.site_list[k].sub_img = true;
            } else {
              false;
            }
          });

          param.master_prod.sub_img = masterProdBase.sub_img;

          // 쇼핑몰정보 이미지
          angular.forEach(param.site_list, function (v) {
            angular.forEach(v.set_info_add_img, function (val, key) {
              if (val) {
                vdata[`${v.shop_cd}_add_img_${key}`] = val;
              }
            });
          });

          commonSVC.sendUrlFile('POST', url, vdata, function (state, data) {
            next(state, data);
          });
        } catch (error) {
          commonSVC.showToaster('error', '실패', error.message);
        }

      },

      edit: function (param) {
        try {
          const url = `${settings.pa20ApiUrl}/app/product/online`;

          const vdata = {
            sol_no: param.sol_no,
            sale_no: param.sale_no,
            shop_cd: param.shop_cd,
            pa_shop_cd: param.pa_shop_cd,
            shop_id: param.shop_id,
            std_ol_yn: param.std_ol_yn,
            ol_shop_no: param.ol_shop_no,
            add_ol_shop_no: param.add_ol_shop_no || '',
            add_shop_cd: param.add_shop_cd || '',
            add_shop_id: param.add_shop_id || '',
            add_std_ol_yn: param.add_std_ol_yn || '',
            add_sale_status: param.add_sale_status || '',
            add_editOption: param.add_editOption || '',
            add_selected_shop: param.add_selected_shop || '',
            depot_no: param.depot_no,
            main_prod_no: param.main_prod_no,
            model_prod_no: param.model_prod_no,
            cert_info: param.cert_info,
            sol_cate_no: param.sol_cate_no,
            shop_cate_no: param.shop_cate_no,
            shop_sale_name: param.shop_sale_name,
            shop_promotion_name: param.shop_promotion_name,
            sale_price: param.sale_price,
            supply_price: param.supply_price,
            cost_price: param.cost_price,
            street_price: param.street_price,
            c_sale_cd: param.c_sale_cd,
            edit_c_sale_cd: param.edit_c_sale_cd,
            ship_price_type: param.ship_price_type,
            ship_price_free_criteria: param.ship_price_free_criteria,
            ship_price: param.ship_price,
            prod_stock: param.sale_cnt_limit,
            sale_status: param.sale_status,
            typing_model: param.typing_model,
            model_name: param.search_model.model,
            brand_name: param.search_model.brand,
            maker_name: param.search_model.maker,
            search_model_yn: param.search_model_yn,
            search_brand_yn: param.search_brand_yn,
            search_maker_yn: param.search_maker_yn,
            product_brand: param.ProductBrandName || '',
            gift_name: param.gift_name,
            prod_status: param.prod_status,
            prod_noti_data_new: param.prod_noti_data_new,
            multi_madein_yn: param.multi_madein_yn ? 1 : 0,
            madein_no: param.madein_no,
            madein_type: param.madein_type,
            madein_group: param.madein_group,
            madein_name: param.madein_name,
            etcMadein: param.etcMadein,
            tax_type: param.tax_type,
            isbn: param.isbn,
            barcode: param.barcode,
            global_barcode: param.global_barcode,
            global_barcode_type: param.global_barcode_type,
            minor: param.minor,
            over_delivery: param.over_delivery,
            prod_weight: param.prod_weight,
            opt_type: param.opt_type,
            opt_name1: param.opt_type === '옵션없음' ? '' : (param.opt[0]?.name || ''),
            opt_name2: param.opt_type === '옵션없음' ? '' : (param.opt[1]?.name || ''),
            opt_name3: param.opt_type === '옵션없음' ? '' : (param.opt[2]?.name || ''),
            opt_desc1: param.opt_type === '옵션없음' ? '' : (param.opt[0]?.attr || ''),
            opt_desc2: param.opt_type === '옵션없음' ? '' : (param.opt[1]?.attr || ''),
            opt_desc3: param.opt_type === '옵션없음' ? '' : (param.opt[2]?.attr || ''),
            opt_unit1: param.opt_type === '옵션없음' ? '' : (param.opt[0]?.unit || param.opt[0]?.stdOpt || ''),
            opt_unit2: param.opt_type === '옵션없음' ? '' : (param.opt[1]?.unit || param.opt[1]?.stdOpt || ''),
            opt_unit3: param.opt_type === '옵션없음' ? '' : (param.opt[2]?.unit || param.opt[2]?.stdOpt || ''),
            opt_sort1_cd: param.opt_type === '옵션없음' ? '' : (param.opt[0]?.name_cd || ''), //옵션명 코드
            opt_sort2_cd: param.opt_type === '옵션없음' ? '' : (param.opt[1]?.name_cd || ''),
            opt_sort3_cd: param.opt_type === '옵션없음' ? '' : (param.opt[2]?.name_cd || ''),
            opt_sub_table: param.opt_sub_table.row,
            img_objects: param.img_objects,
            sale_img_url: param.sale_img_url,
            detail_desc: param.detail_desc ? param.detail_desc.trim().replaceAll('​', '').replaceAll(' ', '') : '',
            detail_desc_ad: (param.detail_desc_ad || '').trim().replaceAll('​', '').replaceAll(' ', ''),
            detail_desc_add: (param.detail_desc_add || '').trim().replaceAll('​', '').replaceAll(' ', ''),
            detail_desc_eng: (param.detail_desc_eng || '').trim().replaceAll('​', '').replaceAll(' ', ''),
            detail_desc_chn: (param.detail_desc_chn || '').trim().replaceAll('​', '').replaceAll(' ', ''),
            detail_desc_jpn: (param.detail_desc_jpn || '').trim().replaceAll('​', '').replaceAll(' ', ''),
            set_info: param.set_info,
            set_info_add_img: param.set_info_add_img,
            img_del_obj: param.img_del_obj,
            log_contents: param.log_contents,
            oldData: param.oldData,
            detailDescEtc: param.detailDescEtc,
            options_table: param.options_table,
            optMakeYn: param.optMakeYn,
            addOptMakeYn: param.addOptMakeYn,
            add_opt_type: param.add_opt_type,
            template_cate_no: param.template_cate_no,
            match_cate_name: param.match_cate_name,
            match_opt_type: param.match_opt_type,
            hscd: param.hscd,
            template_no: param.template_no,
            addcontent_no: param.addcontent_no,
            shop_sale_name_addcontent_no: param.shop_sale_name_addcontent_no,
            mas_addcontent_no: param.mas_addcontent_no,
            input_opt_name: param.input_opt_name,
            options: param.options,
            ctlgNo: param.ctlgNo,
            CatalogId: param.CatalogId,
            BrandSeq: param.BrandSeq,
            MakerSeq: param.MakerSeq,
            ProductBrandSeq: param.ProductBrandSeq,
            keyword: param.keyword,
            shop_cate_no_add_info: typeof param.shop_cate_no_add_info === 'object' && Object.keys(param.shop_cate_no_add_info).length ? param.shop_cate_no_add_info : null,
            made_date: param.made_date,
            expire_date: param.expire_date,
            expiration_month: param.expiration_month,
            additional_images: param.additional_images, //쇼핑몰별 추가이미지
            desc_img_url: param.desc_img_url,
            detail_desc_img_yn: param.detail_desc_img_yn,
            certDataList: param.certDataList,   //인증정보
            mobile_detail_img: param.mobile_detail_img, // 11번가 모바일 상세설명 이미지
            model_no: param.model_no,
            nextday_ship_ol_yn: param.nextday_ship_ol_yn,
            nextday_ship_avail_yn: param.nextday_ship_avail_yn,
            ebay_shop_mas_no: param.ebay_shop_mas_no || '',
            isEsmProdEdit: param.isEsmProdEdit || 0,
            growthProdType: param.growthProdType || ''
          };

          if (!param.prod_noti_data_new[0] && !['A093', 'X094'].includes(param.pa_shop_cd)) {
            throw new Error('상품정보제공고시-공통을 입력해주세요.');
          }
          if (param.product) {
            vdata.product = param.product;
          }

          angular.forEach(param.img_objects, function (v, k) {
            if (v.files && v.files != '' && v.files != v.old_files && v.old_files != '' && v.old_files != undefined) {
              vdata.img_del_obj.push(v.old_files);
            }
            vdata[`img_file${k}`] = v.files;
          });

          angular.forEach(param.site_list, function (v) {
            angular.forEach(v.set_info_add_img, function (val, key) {
              if (val) {
                vdata[`${v.shop_cd}_add_img_${key}`] = val;
              }
            });
          });

          if (!commonSVC.checkRole('stock', userInfo)) {
            if (vdata.opt_edit_flag) {
              angular.forEach(vdata.opt_sub_table, function (v, k) {
                if (typeof (v.image) === 'object') {
                  vdata[`opt_img_file${k}`] = v.image;
                }
              });

            } else {
              if (!(param.opt_type == '옵션없음' && param.opt_sub_table_bak && param.opt_sub_table_bak.row && param.opt_sub_table_bak.row.length > 0)) {
                angular.forEach(vdata.opt_sub_table, function (v, k) {
                  if (typeof (v.image) === 'object') {
                    vdata[`opt_img_file${k}`] = v.image;
                  }
                });
              }
            }
          }

          return commonSVC.sendUrlFile('PATCH', url, vdata);
        } catch (error) {
          throw Error(error.message);
        }

      },

      delete: function(shop_cds, shop_ids, sale_nos, ol_shop_nos, tab_index, next) {

        const url = `${settings.pa20ApiUrl}/app/product/online`,
              vdata = {
                shop_cds: shop_cds,
                shop_ids: shop_ids,
                sale_nos: sale_nos,
                ol_shop_nos: ol_shop_nos,
                tab_index: tab_index
              };

        commonSVC.sendUrl('DELETE', url, vdata, function (state, data) {
          next(state, data);
        });
      },

      deleteMaster: function(c_sale_cds, next) {

        const url = `${settings.pa20ApiUrl}/app/product/online/master`;

        commonSVC.sendUrl('DELETE', url, { c_sale_cds }, function (state, data) {
          next(state, data);
        });
      },

      temporarySaveAdd: function(save_data, next) {
        const url = `${settings.pa20ApiUrl}/app/product/temporary-save`;

        commonSVC.sendUrl('POST', url, { save_data: save_data }, function (state, data) {
          next(state, data);
        });
      },

      temporarySaveDelete: function(tmpsave_no, next) {
        commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/product/temporary-save`, { tmpsave_no: tmpsave_no }, function (state, data) {
          next(state, data);
        });
      },

      temporarySaveList: function() {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/temporary-save/list`, {});
      },

      temporarySaveDetail: function(tmpsave_no, next) {
        commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/temporary-save`, { tmpsave_no: tmpsave_no }, function (state, data) {
          next(state, data);
        });
      },

      getSlaveLinkedMaster: function (sale_nos) {
        const url = `${settings.pa20ApiUrl}/app/product/online/sub-link-master`,
              params = { sale_nos: sale_nos };

        return commonSVC.sendUrl('POST', url, params);
      },

      quickOnlineProductAdd: params => {
        const url = `${settings.pa20ApiUrl}/app/product/online/quick-add`;

        return commonSVC.sendUrl('POST', url, params);
      },

      quickOnlineProductAddCount: params => {
        const url = `${settings.pa20ApiUrl}/app/product/online/quick-add-count`;

        return commonSVC.sendUrl('GET', url, params);
      },

      copy: function (selectedOlShopList) {
        const url = `${settings.pa20ApiUrl}/app/product/online/copy`,
              params = { olShopList: selectedOlShopList };

        return commonSVC.sendUrl('POST', url, params);
      },

      addSlaveToMaster: function(origin, add, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online/sub`,
              vdata = { origin_shop_info: origin, add_shop_info: add };

        // 1분30초 이상일때만 timeout 주는걸로 변경. 기존처리는 유지하겠음
        const timeout = (origin.length * add.length) * 500;
        const setTimeout = timeout > (1000 * 90);

        if (setTimeout) {
          vdata.timeout = timeout;
        }

        if (next) {
          commonSVC.sendUrl('POST', url, vdata, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, vdata);
        }
      },

      detail: function (shop_cd, shop_id, ol_shop_no, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online/detail`;

        // 2019-11-22 jacob 상품상세는 모달띄우는 도중에 로드하는 방식이고, 카운트 사용내역에서는
        // 모달 띄우기 전에 확인하고 상품이 없으면 에러메세지를 띄우는 방식으로 설정하면서 콜백함수 넣음.
        if (next) {
          commonSVC.sendUrl('GET', url, { shop_cd, shop_id, ol_shop_no }, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, { shop_cd, shop_id, ol_shop_no });
        }

      },

      //2017-09-15 ally
      setShopLinkInfo: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/setShopLinkInfo`;

        commonSVC.sendUrl('PATCH', url, params, function (state, data) {
          next(state, data);
        });
      },

      //2018-07-19 Alvin
      getCert: function(ol_shop_no, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online/cert`;
        const params = { ol_shop_no };

        commonSVC.sendUrl('GET', url, params, function(state, data) {
          next(state, data);
        });
      },

      getMadeInfo: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online/made-info`;

        if (next) {
          commonSVC.sendUrl('POST', url, params, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('POST', url, params);
        }
      },

      getShopUrlInfo: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/common/shop-url-info`;
        const method = 'GET';
        commonSVC.sendUrl(method, url, params, function(state, data) {
          next(state, data);
        });
      },

      /**
       * 2018-01-31 ally
       * 그룹상품 데이터 가져오기*/
      listOfAvailGroup: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online/list-of-avail-group`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      /**
       * 2018-03-14 ally
       * 조건없는 단일상품 카운트
       * */
      getCntOfAllSingle: function(params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online/single-count-all`;

        if (next) {
          commonSVC.sendUrl('GET', url, params, function(state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrl('GET', url, params);
        }
      },

      /**
       * 2018-01-14 chris
       * 상태 강제 변경
       * */
      setState: function(data) {
        const url = `${settings.pa20ApiUrl}/app/product/online/state`;

        return commonSVC.sendUrl('PATCH', url, data);
      },

      /**
       * 템플릿 수정시 해당 템플릿에 묶여있는 온라인상품상태 수정대기로 변경.
       * 2018-04-03 rony
       */
      setStateByTemplate: function(data) {
        const url = `${settings.pa20ApiUrl}/app/product/online/state-by-template`;

        return commonSVC.sendUrl('PATCH', url, data);
      },

      /**
       * 온라인상품매칭
       */
      matchMaster: function (params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/matching/master`, params);
      },

      /**
       * SKU상품 신규 추가 및 쇼핑몰상품과 SKU 매칭 API
       */
      matchBase: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/matching/base`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 쇼핑몰상품과 SKU매칭취소 API
       */
      cancelMatch: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/matching/prod/cancel`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * 쇼핑몰상품과 SKU(세트)매칭 API
       */
      matchProd: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/matching/prod`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 매칭 취소
      setScrapPhase: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/matching/scrap`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      // 상품삭제(상품가져오기)
      scrapProdDataDelete: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/matching`;

        return commonSVC.sendUrl('DELETE', url, params);
      },

      // 상품저장(상품가져오기)
      // 무거운 작업이므로 백그라운드 서버로 돌림
      scrapProdDataSave: function (params) {
        const url = `${settings.pa20BackgroundApiUrl}/app/product/matching/add-online`;

        return commonSVC.sendUrl('POST', url, params);
      },

      //선택상품 옵션 불러오기
      getOption: function (params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/online/option`, params);
      },

      // 추가구매옵션 템플릿 등록
      addoptionTemplateAdd: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/add-option-templates/add`, params);
      },

      // 추가구매옵션 템플릿 수정
      addoptionTemplateEdit: function(params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/add-option-templates/edit`, params);
      },

      // 추가구매옵션 템플릿 정보 불러오기
      addoptionTemplateDetail: function(params) {
        return commonSVC.sendUrl('GET',
          `${settings.pa20ApiUrl}/app/product/add-option-templates/${params.template_no}`,
          {});
      },

      //추가구매옵션 템플릿 삭제
      addoptionTemplateDelete: function (params) {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/product/add-option-templates`, params);
      },

      //추가구매옵션 템플릿 복사
      addoptionTemplateCopy: function (params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/add-option-templates/copy`, params);
      },

      /**
       * 추가항목 기본값 조회
       */
      getSetInfo: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/online/set-info`, params);
      },
      /**
       * 추가항목 템플릿 리스트
       */
      setInfoTemplateList: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/set-info/list`, params);
      },
      /**
       * 추가항목 템플릿 상세
       */
      setInfoTemplateDetail: function(params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/set-info/${params.template_no}`);

      },
      /**
       * 추가항목 템플릿 수정
       */
      setInfoTemplateEdit: function(params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/set-info`, params);
      },
      /**
       * 추가항목 템플릿 삭제
       */
      setInfoTemplateDelete: function(params) {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/product/set-info`, params);
      },
      /**
       * 추가항목 템플릿명 중복조회
       */
      setInfoTemplateDupCheck: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/set-info/check-duplicate`, params);
      },

      /**
       * 추가항목 템플릿 > 카테고리 추가정보 조회
       */
      getCateAddInfo: function(params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/set-info/cate-add-info`, params);
      },

      // 온라인 상품 정보 일괄 수정
      editBatch: function (params, next) {
        const url = `${settings.pa20ApiUrl}/app/product/online/batch`;

        if (next) {
          commonSVC.sendUrlFile('PATCH', url, params, function (state, data) {
            next(state, data);
          });
        } else {
          return commonSVC.sendUrlFile('PATCH', url, params);
        }
      },

      // 머리말/꼬리말 적용 리스트 조회
      addcontentlistOfAppliedToOnline: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents/list/applied`;

        return commonSVC.sendUrl('GET', url, params);
      },

      // 머리말/꼬리말 조회
      addcontentDetail: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents/detail?no=${params.no}`;

        return commonSVC.sendUrl('GET', url, {});
      },

      // 머리말/꼬리말 등록
      addcontentAdd: function (params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/add-contents`, params);
      },

      // 머리말/꼬리말 수정
      addcontentEdit: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      // 머리말/꼬리말 복사
      addcontentCopy: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents/copy`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 머리말/꼬리말 삭제
      addcontentDelete: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents`;

        return commonSVC.sendUrl('DELETE', url, params);
      },

      // 머리말/꼬리말 적용/해제
      addcontentApply: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents/apply`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 해더 푸터 템플릿 적용/해제
      masAddcontentApply: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents/mas-apply`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 해더 푸터 템플릿 적용/해제
      masAddcontentRelease: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents/mas-release`;

        return commonSVC.sendUrl('POST', url, params);
      },

      // 머리말/꼬리말 선택상품 해제
      addcontentRelease: function (params) {
        const url = `${settings.pa20ApiUrl}/app/product/add-contents/release`;

        return commonSVC.sendUrl('POST', url, params);
      },

      /**
       * 판매자관리코드 중복확인.
       * 판매자관리코드 중복 확인시 shop_cd + shop_id 도 추가되어 기존 overlap 를 사용하지않도 별도로 뺌.
       * 2018-10-04 rony
       */
      checkDuplicateMasterCd: function(params, next) {
        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/online/checkDuplicateMasterCd`, params, function (state, data) {
          next(state, data);
        });
      },
      /**
       * 판매자관리코드 중복확인.
       * 판매자관리코드 중복 확인시 shop_cd + shop_id 도 추가되어 기존 overlap 를 사용하지않도 별도로 뺌.
       * 2022-08-19 aiden
       */
      checkDuplicateCSaleCd: async function (params, data) {
        const url = `${settings.pa20ApiUrl}/app/product/online/checkDuplicateCSaleCd`;

        return commonSVC.sendUrl('POST', url, params, data);
      },

      // 판매자관리코드 일괄 수정
      editCSaleCd: async function (params, data) {
        const url = `${settings.pa20ApiUrl}/app/product/online/cSaleCd`;

        return commonSVC.sendUrl('PATCH', url, params, data);
      },

      /**
       * 마스터 상품 솔루션 카테고리 설정
       */
      setSolCate: function(params) {
        const url = `${settings.pa20ApiUrl}/app/product/category/sol-category`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      /**
       * 쇼핑몰 상품 쇼핑몰 카테고리 설정
       */
      setShopCategory: function(params) {
        const url = `${settings.pa20ApiUrl}/app/product/category/shop-category`;

        return commonSVC.sendUrl('PATCH', url, params);
      },

      // 단일상품 옵션사용 엑셀양식 생성
      getSingleOptionExcel: function (params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/online/single-opt-excel-down`, params);
      },

      /**
       * 온라인상품 리스트조회
       */
      onlineProductList: function (params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/online/list`, params);
      },

      /**
       * 임의분류 리스트
       */
      olCateList: function() {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/category/online`, {});
      },

      /**
       * 임의분류 추가
       */
      olCateAdd: function(params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/category/online`, params);
      },

      /**
       * 임의분류 분류명 수정
       */
      olCateNameEdit: function(params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/category/online/name`, params);
      },

      /**
       * 임의분류 정렬 수정
       */
      olCateSort: function (params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/category/online/sort`, params);
      },

      /**
       * 임의분류 삭제
       */
      olCateDelete: function (params) {
        return commonSVC.sendUrl('DELETE', `${settings.pa20ApiUrl}/app/product/category/online`, params);
      },

      /**
       * 임의분류 적용
       */
      olCateApply: function (params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/category/online/apply`, params);
      },

      /**
       * 임의분류 해제
       */
      olCateRelease: function (params) {
        return commonSVC.sendUrl('PATCH', `${settings.pa20ApiUrl}/app/product/category/online/release`, params);
      },

      /**
       * 마스터상퓸 리스트 불러오기
       */
      masterProdList: function (params) {
        return commonSVC.sendUrl('GET', `${settings.pa20ApiUrl}/app/product/masterProdList`, params);
      },

      /**
       * 마스터상퓸 리스트 불러오기
       */
      translate: function (params) {
        return commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/product/online/translate`, params);
      },
    };

  });
