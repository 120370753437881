'use strict';

angular.module('gmpApp')
  .controller('OnlineProductListCtrl', function ($rootScope, $scope, $filter, $timeout, $window, $state, $stateParams, $q, commonSVC, onlineProductSVC, workSVC, errorSVC,
    commonModel, onlineProductModel, categoryModel, productModel, shopAccountModel, systemModel, templateModel,
    categoryList, templateList, supplierList, warehouseList, addcontentlistOfAppliedToOnline, systemList, madeinList, gettextCatalog, userInfo, settings, localStorageService, memoTemplateList, countryList) {
    // 사용중인 쇼핑몰
    const channelListArr = $rootScope.useChannelList({ site_action: 'SyncProdState' });

    // 엘지 커스텀 여부
    const viewModelNo = $rootScope.affName === 'LG전자' && !!$rootScope.user_profile.pa_sol_no;

    $scope.selectedShopType = systemList.data.main_use_mode === '국내' ? 'domestic' : 'global';

    const dataLayer = $window.dataLayer = $window.dataLayer || [];
    // 별칭정보 담기
    const seller_nick_info = [];

    _.forEach(channelListArr, function (chRow) {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    //2018-02-06 ally ip확인 임시추가
    $scope.isOurComp = false;
    $scope.selectedNode = {};
    $rootScope.isOnlineProdLoading = localStorage.getItem(`isOnlineListLoading_${userInfo.user.sol_no}`) === 'true';

    // 새로고침 하는 경우 카운트 0/0 으로 출력되고 있어 로컬스토리지에서 값 가져옴
    $rootScope.autoSettingTotal = localStorageService.get(`autoSettingTotal_${userInfo.user.sol_no}`);
    $rootScope.autoSettingSuccess = localStorageService.get(`autoSettingSuccess_${userInfo.user.sol_no}`);
    $rootScope.loadingPercent = localStorageService.get(`loadingPercent_${userInfo.user.sol_no}`);

    // 카테고리 리스트
    const categoryListArr = categoryList.data.all || [];

    if (_.findIndex(categoryListArr, { id: 'null' }) < 0) {
      categoryListArr.unshift({
        id: 'null',
        name: '미지정',
      });
    }

    // 배송처 리스트
    const warehouse_list = warehouseList.data.result || [];

    // 템플릿 리스트
    const templateListArr = templateList.data.results || [];

    if (_.findIndex(templateListArr, { template_no: 'null' }) < 0) {
      templateListArr.unshift({
        template_name: '미지정',
        template_no: 'null'
      });
    }

    // 머리말/꼬리말 리스트
    const addcontentListArr = addcontentlistOfAppliedToOnline.data.results || [];

    if (_.findIndex(addcontentListArr, { addcontent_no: 'null' }) < 0) {
      addcontentListArr.unshift({
        addcontent_name: '미지정',
        addcontent_no: 'null'
      });
    }
    // 메모 템플릿 리스트
    $scope.memoTemplateList = memoTemplateList.data.results || [];

    //정렬 데이터
    let init_order = true;

    //리스트 결과값
    let ol_list = [];

    $scope.tabIndex = 0;
    $scope.isSingleProd = false; // 단일상품보기
    $scope.singleViewType = 'master'; // 단일상품보기 타입
    $scope.olCntList = { std_ol_y: 0, std_ol_n: 0, std_ol_s: 0 }; // 검색 카운트
    $scope.prodList = {};
    $scope.checkboxValue = {};

    $scope.masterInit = false;
    $scope.shopInit = true;
    $scope.groupInit = false;
    // 좌측 상단 상태 선택값
    $scope.selectedNodes = ['전체'];

    // 단일상품 탭 노출여부
    $scope.singleTabYn = channelListArr.some(e => e.std_ol_avail_yn);

    // 물류상품 탭 노출여부
    $scope.smileTabYn = channelListArr.filter(site => ['A001', 'A006'].includes(site.pa_shop_cd)).length && $rootScope.userProfileCheck('sol_ser', 'smile', 'like');

    //로그 미리보기 데이터
    $scope.prodLogInfo = {};

    /* 검색 및 버튼 관련 변수 */
    $scope.searchData = {
      totalCount: 0,        // 검색전체 건수
      totalMasterCount: 0,  // 검색마스터 전체 건수
      totalSlaveCount: 0,    // 검색쇼핑몰 전체 건수
      selectCount: 0,       // 선택한 건수
      selectMasterCount: 0, // 선택한 마스터 건수
      selectSlaveCount: 0,   // 선택한 쇼핑몰 건수
      search_key_items: [   // 검색영역 키값
        { label: gettextCatalog.getString('전체'), value: 'all' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('상품명'), value: 'shop_sale_name' },
        { label: gettextCatalog.getString('브랜드'), value: 'brand' },
        { label: gettextCatalog.getString('바코드'), value: 'barcode' },
        { label: gettextCatalog.getString('UPC,EAN코드'), value: 'global_barcode' }, // barcode_type + global_barcode
        { label: gettextCatalog.getString('ISBN코드'), value: 'isbn' },
        { label: gettextCatalog.getString('모델명'), value: 'model' },
        { label: gettextCatalog.getString('키워드'), value: 'keyword' },
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
        { label: gettextCatalog.getString('메모'), value: 'memo' },
        { label: gettextCatalog.getString('그룹상품코드'), value: 'std_ol_group_cd' },
        { label: gettextCatalog.getString('그룹상품명'), value: 'std_ol_group_name' },
        { label: gettextCatalog.getString('프로모션용 상품명'), value: 'shop_promotion_name' }
      ],
      search_date_type: [
        { label: gettextCatalog.getString('쇼핑몰 상품 등록일'), value: 'wdate' },
        { label: gettextCatalog.getString('마스터상품 등록일'), value: 'mas_wdate' },
        { label: gettextCatalog.getString('수정일'), value: 'mdate' },
        { label: gettextCatalog.getString('판매종료일'), value: 'sale_etime' }
      ],
      search_multi_items: [
        { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
        { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
        { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
        { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
        { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
        { label: gettextCatalog.getString('쇼핑몰(계정)'), value: 'shop' }
      ],
      single_group: 'single_group_all',
      search_date_key_names: commonSVC.searchKeyNames[systemList.data.search_date] || '1MONTH' // 초기화 시 날짜검색 키 버튼이 선택되지 않아서 해당 값으로 선택처리
    };

    if (viewModelNo) {
      $scope.searchData.search_key_items.push({ label: gettextCatalog.getString('모델번호'), value: 'model_no' });
    }

    $scope.searchForm = angular.copy(onlineProductSVC.searchForm);
    if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
      $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
    }

    $scope.searchForm.search_key = localStorage.getItem(`ol_search_key_${userInfo.user.m_no}`) || 'all';

    const searchDetail = [
      {
        title: gettextCatalog.getString('솔루션 카테고리 전체'),
        search_name: 'sol_cate_no',
        item_list: categoryListArr,
        item_key: 'name',
        item_value: 'id',
        search_word: '',
        add_class: 'select-search',
        search_input_placeholder: '분류명/카테고리명/카테고리 코드를 입력해 주세요.'
      },
      {
        title: gettextCatalog.getString('옵션 전체'),
        search_name: 'opt_yn',
        item_list: [{ name: '옵션있음', id: '1' }, { name: '옵션없음', id: '0' }],
        item_key: 'name',
        item_value: 'id',
        search_word: ''
      },
      {
        title: gettextCatalog.getString('쇼핑몰 전체'),
        search_name: 'shop_cd',
        item_list: _.unionBy(channelListArr, 'shop_cd'),
        item_key: 'shop_name',
        item_sub_key: 'shop_name_kr',
        item_value: 'shop_cd',
        search_word: '',
        add_class: 'select-search'
      },
      {
        title: gettextCatalog.getString('쇼핑몰(ID) 전체'),
        search_name: 'shop_id',
        item_list: channelListArr,
        item_key: 'shop_id',
        item_value: 'shop_id',
        search_word: '',
        add_class: 'select-search',
        filter: function (option) {
          // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
          return option.shop_cd == $scope.searchForm.shop_cd;
        }
      },
      {
        title: gettextCatalog.getString('템플릿 전체'),
        search_name: 'template_no',
        item_list: angular.copy(templateListArr),
        item_key: 'template_name',
        item_value: 'template_no',
        search_word: '',
        add_class: 'select-search',
        filter: function (option) {
          //2017-08-18 roy 상세검색 쇼핑몰, 계정 선택한 템플릿만 필터
          return ((option.shop_id == $scope.searchForm.shop_id) && (option.shop_cd == $scope.searchForm.shop_cd)) || (option.template_no == 'null');
        }
      },
      {
        title: gettextCatalog.getString('머리말/꼬리말 템플릿 전체'),
        search_name: 'addcontent_no',
        item_list: angular.copy(addcontentListArr),
        item_key: 'addcontent_name',
        item_value: 'addcontent_no',
        search_word: '',
        add_class: 'select-search'
      },
      {
        title: gettextCatalog.getString('배송처 전체'),
        search_name: 'depot_no',
        item_list: warehouse_list,
        item_key: 'warehouse_name',
        item_value: 'code',
        search_word: '',
        add_class: 'select-search'

      },
      {
        title: gettextCatalog.getString('매입처 전체'),
        search_name: 'supp_vendor',
        item_list: supplierList.data.result || [],
        item_key: 'supp_name',
        item_value: 'supp_no',
        select_value: '',
        add_class: 'select-search'
      },
      {
        title: gettextCatalog.getString('옵션 상태'),
        search_name: 'opt_status',
        item_list: [{ name: '정상', id: '정상' }, { name: '품절', id: '품절' }],
        item_key: 'name',
        item_value: 'id',
        search_word: ''
      },
      {
        title: gettextCatalog.getString('메모여부'),
        search_name: 'memo_yn',
        item_list: [{ name: '메모 있음', id: '1' }, { name: '메모 없음', id: '0' }],
        item_key: 'name',
        item_value: 'id',
        search_word: ''
      },
      {
        title: gettextCatalog.getString('선택한 쇼핑몰 연동상태'),
        search_name: 'peris_status',
        item_list: [{ name: '연동됨', id: '연동됨' }, { name: '연동안됨', id: '연동안됨' }],
        item_key: 'name',
        item_value: 'id',
        search_word: '',
        ngIfFunc: function () {
          return $scope.tabIndex === 3 ? 'y' : 'n';
        }
      }
    ];

    const searchDetailMulti = [
      {
        title: gettextCatalog.getString('쇼핑몰(계정) 선택'),
        search_name: 'multi_shop_id',
        item_list: angular.copy(channelListArr).sort(function(prev, curr) {
          return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
        }),
        item_key: 'shop_id',
        item_value: 'shop_id',
        search_word: ''
      }
    ];

    // 상세 조건검색 리스트
    $scope.searchDetail = angular.copy(searchDetail);
    $scope.searchDetailMulti = angular.copy(searchDetailMulti);

    // 상세조건 버튼
    $scope.searchBtn = {
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_group: [
            [
              {
                label: '상품 복사',
                action: function () {
                  $scope.copyProds();
                },
                perm_only: ['online.product+write']
              },
              {
                label: '상품 삭제',
                action: function () {
                  if ($scope.tabIndex === 3) {
                    $scope.deleteMasterProds();
                  } else {
                    $scope.deleteProds();
                  }
                },
                perm_only: ['online.product+admin']
              },
              {
                label: '정보 일괄 수정',
                action: function () {
                  $scope.editProds();
                },
                perm_only: ['online.product+write']
              }
            ],
            [
              {
                label: '카테고리 템플릿 설정',
                action: function () {
                  $scope.categorySet();
                },
                perm_only: ['online.product+admin'],
                contextVisible: false
              },
              {
                label: '템플릿 설정',
                action: function () {
                  $scope.setTemplate();
                },
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                },
                perm_only: ['online.product+write'],
                contextVisible: false
              },
              {
                label: '추가항목 입력',
                action: function () {
                  $scope.setSetInfo();
                },
                ngIfFunc: function () {
                  if (!$scope.grid.methods?.selectedData) { return 'n'; }

                  const selectedProds = $scope.grid.methods.selectedData('all');

                  // 동일 쇼핑몰 & 동일 카테고리만 벌크 작업 가능
                  const diffShopCdYn = selectedProds.some(({ pa_shop_cd }) => pa_shop_cd !== selectedProds[0].pa_shop_cd),
                        diffCateYn = selectedProds.some(prod => [1, 2, 3, 4, 5].some(i => prod[`shop_cate${i}`] !== selectedProds[0][`shop_cate${i}`]));

                  return diffShopCdYn || diffCateYn ? 'n' : 'y';
                },
                perm_only: ['online.product+write'],
                contextVisible: false
              }
            ],
            [
              {
                label: '머리말/꼬리말 적용',
                action: function () {
                  $scope.applyAddcontent();
                },
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                },
                perm_only: ['online.product+admin'],
                contextVisible: false
              },
              {
                label: '머리말/꼬리말 해제',
                action: function () {
                  $scope.releaseAddcontent();
                },
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                },
                perm_only: ['online.product+admin'],
                contextVisible: false
              },
              {
                label: '마스터상품 임의분류 설정',
                action: function () {
                  $scope.applyOnlineCategory();
                },
                perm_only: ['online.product+admin'],
                contextVisible: false
              },
              {
                label: '마스터상품 임의분류 해제',
                action: function () {
                  $scope.releaseOnlineCategory();
                },
                perm_only: ['online.product+admin'],
                contextVisible: false
              },
              {
                label: `판매자관리코드 선택 수정
<i class="icon-help fl-r" uib-tooltip="쇼핑몰 상품의 판매자 관리코드를 수정하여 다른 마스터 상품 하위로
이동하거나 새로운 마스터상품으로 분리할 수 있습니다." tooltip-placement="right" style="line-height: inherit; padding-right: 3px;">
                `,
                action: function () {
                  $scope.editCSaleCd();
                },
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                },
                perm_only: ['online.product+write'],
                contextVisible: false
              },
              {
                label: '연동수정',
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                },
                action: function () {
                  if ($scope.tabIndex !== 3) {
                    $scope.prodAction('prodLinkageEdit');
                  }
                },
                contextVisible: false
              },
              {
                label: '연동해제',
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                },
                action: function () {
                  if ($scope.tabIndex !== 3) {
                    $scope.prodAction('prodLinkageStop');
                  }
                },
                contextVisible: false
              },
            ],
            [
              {
                label: '그룹상품 생성',
                action: function () {
                  $scope.manageGrouping('create');
                },
                perm_only: ['online.product+write'],
                ngIfFunc: function () {
                  return $scope.isSingleProd && $scope.tabIndex === 1 ? 'y' : 'n';
                },
                contextVisible: false
              },
              {
                label: '그룹상품 동기화',
                action: function () {
                  $scope.groupSync();
                },
                perm_only: ['online.product+write'],
                ngIfFunc: function () {
                  return $scope.isSingleProd && $scope.tabIndex === 1 ? 'y' : 'n';
                },
                contextVisible: false
              }
            ]
          ]
        },
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
          test_id: 'btn-excel',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          ngIfFunc: function () {
            return $scope.tabIndex == 3 ? 'n' : 'y';
          },
          item_group: [
            [
              {
                label: '쇼핑몰상품({{isSmileProd ? "물류" : (isSingleProd ? "단일" : "일반")}}) 다운로드',
                action: function () {
                  $scope.excelDown('shop');
                },
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                }
              },
              {
                label: '쇼핑몰상품({{isSmileProd ? "물류" : (isSingleProd ? "단일" : "일반")}}) 옵션 다운로드',
                add_class: 'pr-20',
                action: function () {
                  $scope.excelDown('option');
                },
                ngIfFunc: function () {
                  return $scope.tabIndex == 3 ? 'n' : 'y';
                }
              }
            ],
          ]
        },
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>',
          test_id: 'btn-excel',
          add_class: 'btn-default',
          action: function () {
            $scope.excelDown('master');
          },
          ngIfFunc: function () {
            return $scope.tabIndex == 3 ? 'y' : 'n';
          }
        },
        {
          label: '쇼핑몰 상품(일반) 추가',
          add_class: 'a-btn-blue',
          tooltip: '선택한 마스터상품에 판매할 쇼핑몰을 추가합니다.\n(옥션/지마켓-ESM2.0, 11번가 신규상품, 위메프2.0, 홈플러스, 롯데슈퍼, 알리 익스프레스, 알리바바, 스마트스토어 그룹상품은\n\'쇼핑몰 상품(단일)\' 탭에서 추가할 수 있습니다.)',
          tooltipPlacement: 'top',
          action: function () {
            $scope.prodAction('generalProdAdd');
          },
          ngIfFunc: function () {
            return $scope.tabIndex === 3 || $scope.tabIndex === 0 ? 'y' : 'n';
          }
        }, {
          label: '쇼핑몰 상품(단일) 추가',
          add_class: 'a-btn-blue',
          tooltip: '선택한 마스터상품에 판매할 쇼핑몰을 추가합니다.\n(옥션/지마켓-ESM2.0, 11번가 신규상품, 위메프2.0, 홈플러스, 롯데슈퍼, 알리 익스프레스, 알리바바, 스마트스토어가 해당)\n솔루션 카테고리가 설정되어 있는 상품을 선택 후 쇼핑몰을 추가할 수 있습니다.',
          tooltipPlacement: 'top',
          action: function () {
            $scope.prodAction('addSingleProd');
          },
          ngIfFunc: function () {
            return [1, 3].includes($scope.tabIndex) ? 'y' : 'n';
          },
          ngDisableFunc: () => {
            return $scope.tabIndex === 1 && $scope.searchForm.std_ol_type === 'single_group_all';
          }
        }, {
          label: '쇼핑몰 상품(물류) 추가',
          add_class: 'a-btn-blue',
          tooltip: '선택한 마스터상품에 판매할 쇼핑몰을 추가합니다. (스마일배송 상품)\n솔루션 카테고리가 설정되어 있는 상품을 선택 후 쇼핑몰을 추가할 수 있습니다',
          tooltipPlacement: 'top',
          action: function () {
            $scope.prodAction('addSmileProd');
          },
          ngIfFunc: function () {
            return $scope.smileTabYn && ($scope.tabIndex === 3 || $scope.tabIndex === 2) ? 'y' : 'n';
          }
        }
      ],
      table_actions_top: [
        {
          label: '상품전송',
          add_class: 'pui-order-btn',
          sub_class: 'pui-order-disable-btn',
          test_id: 'btn-product-match',
          icon_class: 'pui-forward',
          action: function () {
            if ($scope.tabIndex !== 3 && !$rootScope.isOnlineProdLoading) {
              $scope.prodAction('prodSend');
            } else if ($rootScope.isOnlineProdLoading) {
              commonSVC.showMessageHtml('',
                '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
            }
          },
        }, {
          label: '부분수정',
          add_class: 'pui-order-btn',
          sub_class: 'pui-order-disable-btn',
          icon_class: 'pui-part',
          action: function () {
            if ($scope.tabIndex !== 3 && !$rootScope.isOnlineProdLoading) {
              $scope.prodAction('prodEdit');
            } else if ($rootScope.isOnlineProdLoading) {
              commonSVC.showMessageHtml('',
                '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
            }
          },
        }, {
          label: '판매중지',
          add_class: 'pui-order-btn',
          sub_class: 'pui-order-disable-btn',
          icon_class: 'pui-cancel',
          action: function () {
            if ($scope.tabIndex !== 3 && !$rootScope.isOnlineProdLoading) {
              $scope.prodAction('prodStop');
            } else if ($rootScope.isOnlineProdLoading) {
              commonSVC.showMessageHtml('',
                '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
            }
          },
        }, {
          label: '일시품절',
          add_class: 'pui-order-btn',
          sub_class: 'pui-order-disable-btn',
          icon_class: 'pui-soldout',
          action: function () {
            if ($scope.tabIndex !== 3 && !$rootScope.isOnlineProdLoading) {
              $scope.prodAction('prodSoldOut');
            } else if ($rootScope.isOnlineProdLoading) {
              commonSVC.showMessageHtml('',
                '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
            }
          },
        }, {
          label: '재판매',
          add_class: 'pui-order-btn',
          sub_class: 'pui-order-disable-btn',
          icon_class: 'pui-resale',
          action: function () {
            if ($scope.tabIndex !== 3 && !$rootScope.isOnlineProdLoading) {
              $scope.prodAction('prodStopCancel');
            } else if ($rootScope.isOnlineProdLoading) {
              commonSVC.showMessageHtml('',
                '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
            }
          },
        }, {
          label: '판매기간변경',
          add_class: 'pui-order-btn',
          sub_class: 'pui-order-disable-btn',
          icon_class: 'pui-calendar_add',
          action: function () {
            if ($scope.tabIndex !== 3 && !$rootScope.isOnlineProdLoading) {
              $scope.prodAction('prodExtension');
            } else if ($rootScope.isOnlineProdLoading) {
              commonSVC.showMessageHtml('',
                '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
            }
          },
        }, {
          label: '상품동기화',
          add_class: 'pui-order-btn',
          sub_class: 'pui-order-disable-btn',
          icon_class: 'pui-sync',
          action: function () {
            if ($scope.tabIndex !== 3 && !$rootScope.isOnlineProdLoading) {
              $scope.prodAction('prodSync');
            } else if ($rootScope.isOnlineProdLoading) {
              commonSVC.showMessageHtml('',
                '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
            }
          },
        },
        // {
        //   label: '이미지호스팅',
        //   add_class: 'pui-order-btn',
        //   sub_class: 'pui-order-disable-btn',
        //   icon_class: 'pui-sync',
        //   action: function () {
        //     $rootScope.openImageHosting('url');
        //   },
        // }
      ],
      table_states_top: [
        {
          label: `<span class="label label-default border-on border-primary-300 bg-white text-primary-300">${'전체'}</span>`,
          test_id: 'btn-state-total',
          icon_class: 'pui-storefront',
          count_name: '전체'
        },
        {
          label: `<span class="label label-default bg-orange-300">${'판매대기'}</span>`,
          test_id: 'btn-state-new',
          icon_class: 'pui-add_shopping_cartsvg',
          count_name: '판매대기'
        },
        {
          label: `<span class="label label-default bg-orange-800">${'판매중'}</span>`,
          test_id: 'btn-state-collect',
          icon_class: 'pui-calendar_today',
          count_name: '판매중'
        },
        {
          label: `<span class="label label-default bg-success-300">${'수정대기'}</span>`,
          test_id: 'btn-state-pending',
          icon_class: 'pui-layers',
          count_name: '수정대기'
        },
        {
          label: `<span class="label label-default bg-danger-800">${'종료대기'}</span>`,
          test_id: 'btn-state-pending',
          icon_class: 'pui-layers',
          count_name: '종료대기'
        },
        {
          label: `<span class="label label-default border-on border-orange-300 bg-white text-orange-300">${'승인대기'}</span>`,
          test_id: 'btn-state-pending',
          icon_class: 'pui-layers',
          count_name: '승인대기'
        },
        {
          label: `<span class="label label-default border-on border-danger-300 bg-white text-danger-300">${'반려'}</span>`,
          test_id: 'btn-state-pending',
          icon_class: 'pui-layers',
          count_name: '반려'
        },
        {
          label: `<span class="label label-default bg-slate-300">${'일시품절'}</span>`,
          test_id: 'btn-state-pending',
          icon_class: 'pui-layers',
          count_name: '일시품절'
        }, {
          label: `<span class="label label-default bg-grey-300">${'판매중지'}</span>`,
          test_id: 'btn-state-pending',
          icon_class: 'pui-layers',
          count_name: '판매중지'
        }
      ],
      actions_right: [
        {
          label: `<i class="icon-plus2"></i><span> ${'쇼핑몰 상품 등록'}</span><span class="caret"></span>`,
          small_label: '상품등록',
          sub_label: '',
          btn_type: 'dropdown',
          item_list: [
            {
              label: $scope.smileTabYn ? '건별 등록(일반/단일/물류 공통)' : '건별 등록(일반/단일 공통)',
              hotkey: '',
              perm_only: ['online.product+write'],
              action: function () {
                if (!$rootScope.isOnlineProdLoading) {
                  $state.go('main.online_product_add');
                  $state.reload();
                } else {
                  commonSVC.showMessageHtml('',
                    '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
                }
              }
            },
            {
              label: '일반상품 엑셀 일괄 등록',
              hotkey: '',
              perm_only: ['online.product+write'],
              action: function () {
                // 엑셀 업/다운로드 권한 확인. 2022-03-21 Aiden
                if (!commonSVC.checkPermission('online.roles.excelProd', userInfo.permission)) {
                  return false;
                }
                if (!$rootScope.isOnlineProdLoading) {
                  $scope.openExcelModal('add:normal');
                } else {
                  commonSVC.showMessageHtml('',
                    '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
                }
              }
            },
            {
              label: '단일상품 엑셀 일괄 등록',
              hotkey: '',
              perm_only: ['online.product+write'],
              action: function () {
                if (!commonSVC.checkPermission('online.roles.excelProd', userInfo.permission)) {
                  return false;
                }
                if (!$rootScope.isOnlineProdLoading) {
                  $scope.openExcelModal('add:single');
                } else {
                  commonSVC.showMessageHtml('',
                    '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
                }
              }
            },
            {
              label: '물류상품 엑셀 일괄 등록',
              hotkey: '',
              perm_only: ['online.product+write'],
              action: function () {
                if (!commonSVC.checkPermission('online.roles.excelProd', userInfo.permission)) {
                  return false;
                }
                if (!$rootScope.isOnlineProdLoading) {
                  $scope.openExcelModal('add:smile');
                } else {
                  commonSVC.showMessageHtml('',
                    '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
                }
              },
              ngIfFunc: () => $scope.smileTabYn ? 'y' : 'n'
            },
            {
              label: '옵션전용 엑셀 일괄 수정',
              hotkey: '',
              perm_only: ['online.product+write'],
              action: function () {
                if (!commonSVC.checkPermission('online.roles.excelProd', userInfo.permission)) {
                  return false;
                }
                if (!$rootScope.isOnlineProdLoading) {
                  $scope.openExcelModal('add:option');
                } else {
                  commonSVC.showMessageHtml('',
                    '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
                }
              }
            },
            {
              label: '판매자관리코드 엑셀 일괄 수정',
              hotkey: '',
              perm_only: ['online.product+write'],
              action: function () {
                if (!commonSVC.checkPermission('online.roles.excelProd', userInfo.permission)) {
                  return false;
                }
                if (!$rootScope.isOnlineProdLoading) {
                  $scope.openExcelModal('edit:masterCd');
                } else {
                  commonSVC.showMessageHtml('',
                    '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
                }
              }
            },
            {
              label: '엑셀 일괄 수정',
              hotkey: '',
              perm_only: ['online.product+write'],
              action: function () {
                if (!commonSVC.checkPermission('online.roles.excelProd', userInfo.permission)) {
                  return false;
                }
                if (!$rootScope.isOnlineProdLoading) {
                  $scope.openExcelModal('edit');
                } else {
                  commonSVC.showMessageHtml('',
                    '현재 고객의 상품과 주문 데이터를 수집하여 설정하고 있어 종료 전까지 다른 작업이 불가능합니다.');
                }
              }
            }
          ]
        }
      ],
      actions_right_bottom: []
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function () {
        $scope.searchDo(true, true);
      },
      resetDo: function () {
        $scope.resetDo();
      },
      changeLen: function (count) {
        $scope.changeCount(count);
      },
      // 검색영역 탭 변경 이벤트
      leftContentsTabChange: function (tab_no) {
        // 상품 집계탭 선택시 트리갱신
        if (tab_no == 4) {

          $scope.$broadcast('olTreeUdateEvent', {
            act: 'olTreeUdate',
            channelListArr: channelListArr,
            onlineListResults: $scope.onlineListResults,
            onlineCateResults: $scope.onlineCateResults
          });
        }
      }
    };

    // 마우스 우클릭시 상품상태 수동변경용
    $scope.customContext = [
      {
        line: true,
        contextList: [
          {
            label: '카테고리 템플릿 설정',
            action: function () {
              $scope.categorySet();
            },
          },
          {
            label: '템플릿 설정',
            action: function () {
              $scope.setTemplate();
            },
            filter: function () {
              return $scope.tabIndex !== 3;
            }
          },
        ],
      },
      {
        line: true,
        contextList: [
          {
            label: '추가항목 입력',
            action: function () {
              $scope.setSetInfo();
            },
            filter: function () {
              const selectedProds = $scope.grid.methods?.selectedData('all');

              // 동일 쇼핑몰 & 동일 카테고리만 벌크 작업 가능
              const diffShopCdYn = selectedProds.some(({ pa_shop_cd }) => pa_shop_cd !== selectedProds[0].pa_shop_cd),
                    diffCateYn = selectedProds.some(prod => [1, 2, 3, 4, 5].some(i => prod[`shop_cate${i}`] !== selectedProds[0][`shop_cate${i}`]));

              return !diffShopCdYn && !diffCateYn;
            },
            perm_only: ['online.product+write'],
            contextVisible: false
          },
          {
            label: '머리말/꼬리말 적용',
            action: function () {
              $scope.applyAddcontent();
            },
            filter: function () {
              return $scope.tabIndex !== 3;
            }
          },
          {
            label: '머리말/꼬리말 해제',
            action: function () {
              $scope.releaseAddcontent();
            },
            filter: function () {
              return $scope.tabIndex !== 3;
            }
          },
          {
            label: '마스터상품 임의분류 설정',
            action: function () {
              $scope.applyOnlineCategory();
            },
          },
          {
            label: '마스터상품 임의분류 해제',
            action: function () {
              $scope.releaseOnlineCategory();
            },
          },
          {
            label: `${'판매자관리코드 선택 수정'}<i class="icon-help menu-tooltip fl-r" tooltip-placement="right" style="margin: 2px 0 0 7px !important;">`,
            menu_tooltip: `쇼핑몰 상품의 판매자 관리코드를 수정하여 다른 마스터 상품 하위로
이동하거나 새로운 마스터상품으로 분리할 수 있습니다.`,
            action: function () {
              $scope.editCSaleCd();
            },
            filter: function () {
              return $scope.tabIndex !== 3;
            }
          },
          {
            label: '연동수정',
            filter: function () {
              return $scope.tabIndex !== 3;
            },
            action: function () {
              if ($scope.tabIndex !== 3) {
                $scope.prodAction('prodLinkageEdit');
              }
            },
          },
          {
            label: '연동해제',
            filter: function () {
              return $scope.tabIndex !== 3;
            },
            action: function () {
              if ($scope.tabIndex !== 3) {
                $scope.prodAction('prodLinkageStop');
              }
            },
          },
          {
            label: '그룹상품 생성',
            action: function () {
              $scope.manageGrouping('create');
            },
            filter: function (row) {
              return row.std_ol_yn == 1 && row.shop_sale_no && !row.std_ol_group_cd;
            }
          }
        ]
      },
      {
        line: true,
        isShow: function () {
          return $scope.tabIndex === 3;
        },
        contextList: [
          {
            label: '상태 수동변경<strong style="font-family:monospace;font-weight:bold;float:right;">&gt;</strong>',
            filter: function () {
              return $scope.tabIndex !== 3;
            }
          },
        ],
      },
      {
        isSubMenu: true,
        line: true,
        isShow: function () {
          return $scope.tabIndex !== 3;
        },
        subMemuName: '상태 수동변경',
        contextList: [
          {
            label: '판매대기로 상태변경',
            action: function () {
              userChangeStatus('판매대기');
            },
            filter: function () {
              return false;
            }
          },
          {
            label: '종료대기로 상태변경',
            action: function () {
              userChangeStatus('종료대기');
            },
            filter: function (row) {
              return ['판매중'].indexOf(row.sale_status) > -1;
            }
          },
          {
            label: '판매중으로 상태변경',
            action: function () {
              userChangeStatus('판매중');
            },
            filter: function (row) {
              return ['수정대기'].indexOf(row.sale_status) > -1;
            }
          },
          {
            label: '수정대기로 상태변경',
            action: function () {
              userChangeStatus('수정대기');
            },
            filter: function (row) {
              return ['종료대기'].indexOf(row.sale_status) > -1 || ['판매중'].indexOf(row.sale_status) > -1;
            }
          }
        ]
      }

    ];

    $scope.selectRowUniqList = [];

    $scope.isMemoDisabled = (ol_shop_no) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(ol_shop_no);

    // 데이터 테이블에서 선택한 rowList 정보 받아옴
    $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
      $scope.selectRowUniqList = data.map(({ ol_shop_no }) => ol_shop_no);
      $scope.$apply();
    }, 200));

    // 쇼핑몰상품(마스터) 그리드
    $scope.grid = $scope.shop = {};
    $scope.shop.methods = {};
    $scope.shop.options = {
      simplifyScroll: true,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'sale_img1', 'sale_status', 'expire_date'],
      alignRightColumns: ['sale_price', 'cost_price', 'supply_price', 'street_price'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'sale_img1', 'sol_cate_name_first', 'shop_cd', 'sale_status', 'shop_sale_no', 'opt_type', 'cost_price', 'supply_price', 'street_price', 'addcontent_name', 'sale_etime', 'expire_date', 'ebay_shop_mas_no'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['cost_price', 'supply_price', 'street_price', 'model_no', 'expire_date', 'ebay_shop_mas_no'],
      selectOptions: {
        notClickableOption: (row) => {
          if (['A001', 'A006'].includes(row.pa_shop_cd) && row.ebay_shop_mas_no) {
            if (!channelListArr.some(ch => ch.shop_cd === (row.pa_shop_cd === 'A001' ? 'A006' : 'A001') && ch.shop_id === row.ebay_shop_mas_other_id)) {
              return true;
            }
          }

          return false;
        }
      },
      familyOptions: {
        familyCountKey: 'masterCnt',
        familyDataKey: 'sale_no',
        parentDataKey: 'shop_cd',
        parentDataValue: 'Z000',
        parentRowTemplate: (row) => {
          let addMasterInfo = '';

          if (row.ol_cate_name) {
            addMasterInfo += `<div class="ml-10" style="display:inline-block;"><span style="color: #aaa; display: inline-block; width: 45px;">임의분류:</span> <span style="font-weight: bold; display: inline-block; max-width: calc(100% - 45px)" uib-tooltip="${row.ol_cate_name}" tooltip-append-to-body="true" tooltip-placement="right">${row.ol_cate_name}</span></div>`;
          }

          return `
            <div style="display: inline-block; line-height: 22px;">
              <i class="fa fa-folder ml-5 mr-10" style="color: #F8D79F"></i>
            </div>
            <div style="display:inline-block; width: 130px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: middle;"><span style="font-weight: bold;">${row.c_sale_cd}</span></div>
            <div class="ml-10" style="display:inline-block; width: 200px; white-space: nowrap; overflow: hidden; vertical-align: middle;"><span style="color: #aaa; display:inline-block; ">상품명:</span> <span style="font-weight: bold; text-overflow: ellipsis; overflow: hidden; display: inline-block; max-width: calc(100% - 40px); vertical-align: middle;" uib-tooltip="${row.shop_sale_name}" tooltip-append-to-body="true" tooltip-placement="right">${row.shop_sale_name}</span></div>
            <div class="ml-10" style="display:inline-block; width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: middle;"><span style="color: #aaa; display:inline-block; ">판매가:</span> <span style="font-weight: bold; display: inline-block; max-width: calc(100% - 35px);" uib-tooltip="${row.sale_price}원" tooltip-append-to-body="true" tooltip-placement="right">${row.sale_price}원</span></div>
            <div class="ml-10" style="display:inline-block; width: 300px; white-space: nowrap; overflow: hidden; vertical-align: middle;"><span style="color: #aaa; display:inline-block; ">카테고리:</span> <span style="font-weight: bold; text-overflow: ellipsis; overflow: hidden; display: inline-block; max-width: calc(100% - 55px); vertical-align: middle;" uib-tooltip="${row.sol_cate_name || '-'}" tooltip-append-to-body="true" tooltip-placement="right">${row.sol_cate_name || '-'}</span></div>
            <div class="ml-10" style="display:inline-block; width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: middle;"><span style="color: #aaa; display:inline-block; ">카테고리 코드:</span><span style="font-weight: bold;">${row.sol_cate_no || '-'}</span></div>
            ${addMasterInfo}
          `;
        }
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/online/list`,
        requestWillAction: (data) => {
          // 메모관리에서 넘어오는경우
          if ($rootScope.memo_etc_search.page === 'online' && $rootScope.memo_etc_search.search) {
            otherPageCalled('memo_etc', false);
          }

          // 작업관리에서 넘어오는경우
          if ($rootScope.work_search.page === 'online' && $rootScope.work_search.search) {
            otherPageCalled('work', false);
          }

          // 대시보드에서 링크 타고 들어왔을 때
          if ($rootScope.side_search.page === 'online' && $rootScope.side_search.search) {
            otherPageCalled('dashboard', false);
          }

          // 주문관리에서 넘어오는 경우
          if ($rootScope.order_search.page === 'online' && $rootScope.order_search.search) {
            otherPageCalled('ord', false);
          }
          $scope.esmMasSearchForm = angular.merge({}, data, $scope.searchForm);
          data = angular.merge({}, data, $scope.searchForm);

          // 검색 초기화용 정렬정보 백업
          if (init_order == true) {
            init_order = false;
          }

          data.onlyNormal = true;

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: (result) => {
          ol_list = result.results;

          let return_arr = [];
          const std_ol_n = _.find(result.stdCount, { std_ol_yn: 0 });
          const std_ol_y = _.find(result.stdCount, { std_ol_yn: 1 });
          const std_ol_m = _.find(result.stdCount, { std_ol_yn: null });
          const std_ol_s = result.stdCount.find(o => o.std_ol_yn === 2);   // 물류 상품

          $scope.olCntList = {
            std_ol_n: std_ol_n ? std_ol_n.cnt : 0,
            std_ol_y: std_ol_y ? std_ol_y.cnt : 0,
            std_ol_m: std_ol_m ? std_ol_m.cnt : 0,
            std_ol_s: std_ol_s ? std_ol_s.cnt : 0
          };

          switch ($scope.searchForm.std_ol_type) {
            case 'normal':
              $scope.searchData.totalCount = $scope.olCntList.std_ol_n;
              break;
            case 'smile_group_all':
              $scope.searchData.totalCount = $scope.olCntList.std_ol_s;
              break;
            default:
              $scope.searchData.totalCount = $scope.olCntList.std_ol_y;
              break;
          }

          $scope.olProdMasterCount = result.recordsTotal;
          // $scope.searchData.totalMasterCount = result.olCount || 0;
          // $scope.searchData.totalSlaveCount = $scope.searchForm.std_ol_type === 'normal' ? $scope.olCntList.std_ol_n : $scope.olCntList.std_ol_y;

          $scope.productList = result.results;

          angular.forEach(result.results, function (v) {
            return_arr = return_arr.concat(v);
          });

          setCountList(result);

          $scope.productList = return_arr;

          return return_arr;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 135,
          template: function(row) {
            //도구
            const location = 'KR';
            let addGroup = '';
            let addGroup2 = '';

            // 메모가 있는경우 primary 컬러로 변경
            const btnColor = row.shop_cd === 'Z000' ? 'btn-info' : 'btn-default';

            let chkCantEsmDetail = false;

            if (['A001', 'A006'].includes(row.pa_shop_cd) && row.ebay_shop_mas_no) {
              if (!channelListArr.some(ch => ch.shop_cd === (row.pa_shop_cd === 'A001' ? 'A006' : 'A001') && ch.shop_id === row.ebay_shop_mas_other_id)) {
                chkCantEsmDetail = true;
              }
            }

            addGroup2 = `<button ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1, 'disabled':${chkCantEsmDetail}}" class="btn btn-xxs dt-select-do"
                ng-click="${!chkCantEsmDetail ? 'grid.appScope.leftTabAction(2)' : ''}"
                uib-tooltip="{{'메모'|translate}}" 
                tooltip-append-to-body="true" 
                tooltip-placement="right">메모</button>`;

            if ($scope.isSingleProd && !row.std_ol_group_code && row.shop_cd !== 'Z000') {
              row.canGrouping = !['B719', 'B502', 'P020', 'A112', 'A092', 'A093', 'A077', 'X094'].includes(row.pa_shop_cd)
                && row.shop_sale_no
                && (!onlineProductSVC.impossibleGroup[row.pa_shop_cd] || (onlineProductSVC.impossibleGroup[row.pa_shop_cd] && onlineProductSVC.impossibleGroup[row.pa_shop_cd].indexOf(row.shop_cate2_cd) < 0))
                && row.std_ol_group_cd === null
                && row.template_no;

              addGroup = `<button class="btn btn-default btn-xxs mr-5"
              ng-class="{disabled:${!row.canGrouping}}" tooltip-enable="${!row.canGrouping}"
              ng-click="${row.canGrouping ? `grid.appScope.manageGrouping('add','','','${row.shop_sale_no}','${row.pa_shop_cd}')` : ''}" uib-tooltip="{{'그룹 기능 미지원 쇼핑몰: 11번가 신규상품, 위메프2.0, 홈플러스, 알리 익스프레스, 스마트스토어 그룹상품\n① 이미 그룹인 상품\n② 카테고리가 없거나 그룹이 지원되지 않는 카테고리 상품\n③ 쇼핑몰 상품코드 또는 템플릿이 없는 상품\n④ 선택한 상품이 추가될 그룹이 없는 상품은 그룹상품으로 추가 할 수 없습니다'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">추가</button>`;
            }

            return `
              ${addGroup}
              <button class="btn ${btnColor} btn-xxs mr-5" ng-class="{disabled:${chkCantEsmDetail}}" ng-click="${!chkCantEsmDetail ? `grid.appScope.onlineProductEdit('${row.shop_cd}','${row.shop_id}',${row.ol_shop_no},'${location}')` : ''}" uib-tooltip="{{'상세보기'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">상세</button>
              ${addGroup2}
            `;
          }
        },
        {
          key: 'sale_img1',
          title: '이미지',
          width: 60,
          important: true,
          notCompile: true,
          template: function(row) {
            //이미지
            let img = '';

            if (row.sale_img_url) {
              img += row.sale_img_url || '';
              img += row.sale_img1 || '';
            } else {
              img = '/assets/images/noimage.png';
            }

            return `<img src='${img}' width='20' height='20' onerror='this.src="/assets/images/noimage.png"'>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120,
          filter: 'dateValid'
        },
        {
          key: 'sol_cate_name_first',
          title: '카테고리',
          width: 120,
          template: function(row) {
            if (row.shop_cd === 'Z000') {
              return row.sol_cate_no ? `${row.sol_cate_no}: ${row.sol_cate_name}` : '-';
            }

            // 실제 등록 사이트 카테고리 2018-06-15 Daniel update
            const cate = [];
            let cate_name = row.sol_cate_name_first;

            for (let i = 1; i <= 5; i++) {
              const c = row[`shop_cate${i}`];

              if (c) {
                cate.push(c);
              }
            }

            if (
              !row.shop_cate1 ||
              row.shop_cate1 == '임시' ||
              row.sol_cate_name_first == '임시'
            ) {
              cate_name =
                '<span uib-tooltip="{{\'상품상세에서 카테고리를 지정해주세요.\'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">-</span>';
            } else if (cate.length > 0) {
              cate_name = cate.join(' > ');
            }

            return cate_name;
          }
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 150,
          important: true,
          template: function(row) {
            const shop_info = commonSVC.getShopIdViewText(
              systemList.data.shop_id_view_type,
              seller_nick_info,
              row.shop_cd,
              row.shop_id
            );

            return row.shop_cd === 'Z000'
              ? '마스터상품'
              : `<span uib-tooltip="${row.shop_name}(${shop_info[0]})${['A001', 'A006'].includes(row.shop_cd) && row.ebay_shop_mas_other_id ? ` / ${row.shop_cd === 'A001' ? '지마켓' : '옥션'}(${row.ebay_shop_mas_other_id})` : ''}` +
                  `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${
                    ['A001', 'A006', 'B378'].includes(row.shop_cd) && row.ebay_shop_mas_no ? (row.shop_cd === 'A001' ? 'ESMA' : (row.shop_cd === 'A006' ? 'ESMG' : 'CPRG')) : (row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd)
                  }.png" style="width: 50px;">${shop_info[1]}</span>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 250,
          important: true,
          notCompile: true,
          template: function(row) {
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            // 알리바바, 쇼피파이(글로벌), 알리익스프레스(글로벌) 번역 기능 라벨 추가
            // 일반상품인데 DB에 프로모션 상품명이 들어가있는 경우가 있어서 단일일때만 프로모션명 보여주도록 변경
            return `<span>${['A092', 'X093', 'X094'].includes(row.pa_shop_cd) ? '<span class="label label-default bg-green">번역지원</span>' : ''}
                    ${filteredShopSaleName}
                  ${row.shop_promotion_name && row.std_ol_yn ? `<strong>${row.shop_promotion_name}</strong>` : ''}</span>`;
          }
        },
        {
          key: 'sale_status',
          title: '상품상태',
          width: 100,
          important: true,
          notCompile: true,
          tooltip: '상품등록 이후, 반드시 [상품전송] 버튼을 눌러 \'판매중\'으로 변경되어야 사이트에 반영됩니다.',
          template: function(row) {
            if (row.shop_cd === 'Z000') {
              return '';
            }

            return $filter('statusColorOl')(row.sale_status);
          }
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          tooltip: '스마트스토어 그룹상품인 경우 그룹 상품번호가 노출됩니다',
          width: 140,
          template: function(row) {
            const { pa_shop_cd, shop_cd, shop_id } = row;

            const sale_no = row.shop_sale_no || '';
            let sale_no_link = '';

            if (shop_cd === 'Z000') {
              return '';
            }

            if (pa_shop_cd == 'B378') {
              if (row.shop_sale_no_sub) {
                sale_no_link = `${sale_no}?vendorItemId=${row.shop_sale_no_sub}`;
              } else {
                sale_no_link = row.shop_sale_no_sub;
              }
            } else if (pa_shop_cd === 'A077' && row.std_ol_yn === 1) {
              sale_no_link = row.shop_sale_no_sub;
            } else {
              sale_no_link = sale_no;
            }

            if (
              sale_no &&
              !_.includes(onlineProductSVC.impossibeDetailView, shop_cd)
            ) {
              return `<span>${sale_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${shop_cd}','${shop_id}','${sale_no_link}')"></i></span>`;
            } else {
              return sale_no;
            }
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 140,
          important: true,
        },
        {
          key: 'opt_type',
          title: '옵션',
          width: 100
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 100,
          important: true,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.sale_price, '', decimalPlaces);
          }
        },
        {
          key: 'cost_price',
          title: '원가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.cost_price, '', decimalPlaces);
          }
        },
        {
          key: 'supply_price',
          title: '공급가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.supply_price, '', decimalPlaces);
          }
        },
        {
          key: 'street_price',
          title: '시중가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.street_price, '', decimalPlaces);
          }
        },
        {
          key: 'template_name',
          title: '적용 템플릿',
          width: 120,
          template: function(row) {
            //적용템플릿
            if (row.shop_cd === 'Z000') {
              return '';
            }

            const registedTemplateInfo = row.template_info && Object.keys(row.template_info).length
              ? `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.templateDetail(${row.ol_shop_no}, '${row.template_no}','${row.shop_id}','${row.shop_cd}','${row.pa_shop_cd}','${row.shop_name}', true)" uib-tooltip="기존 템플릿은 [쇼핑몰 상품 가져오기] 또는 [상품전송]을 진행한 상품에만 노출됩니다.\n상품 가져오기 진행 시 가져온 템플릿 정보이며, 상품 전송 시 쇼핑몰에 반영된 최종 템플릿 정보입니다." tooltip-append-to-body="true" tooltip-placement="right">기존</button> `
              : '';

            const templateInfo = row.template_name
              ? `<a ng-click="grid.appScope.templateDetail(${row.ol_shop_no}, '${row.template_no}','${row.shop_id}','${row.shop_cd}','${row.pa_shop_cd}','${row.shop_name}', false)">${row.template_name}</a>`
              : '';

            return registedTemplateInfo || templateInfo
              ? `${registedTemplateInfo}${templateInfo}`
              : '<span uib-tooltip="{{\'상품상세에서 템플릿을 지정해주세요.\'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">미지정</span>';
          }
        },
        {
          key: 'addcontent_name',
          title: '적용 머리말/꼬리말',
          width: 130,
          tooltip: '머리말/꼬리말 템플릿은 상품명/상세설명 순으로 기재됩니다',
          template: function(row) {
            //적용템플릿
            if (row.shop_cd === 'Z000') {
              return '';
            }

            return `${
              row.shop_sale_name_addcontent_name ?
                `<a ng-click="grid.appScope.addcontentRule(${row.shop_sale_name_addcontent_no})">${row.shop_sale_name_addcontent_name}</a>` : '미지정'
            } / ${
              row.addcontent_name ?
                `<a ng-click="grid.appScope.addcontentRule(${row.addcontent_no})">${row.addcontent_name}</a>` : '미지정'}`;
          }
        },
        {
          key: 'sale_etime',
          title: '판매종료일',
          width: 120,
          notCompile: true,
          template: function(row) {
            //판매종료일
            const date = moment(row.sale_etime).format('YYYY-MM-DD HH:mm:ss');

            if (
              // date.charAt(0) === '0' ||
              // date.charAt(1) !== '0' ||
              // date.charAt(2) === '9' ||
              date === 'Invalid date'
            ) {
              return '-';
            } else {
              return date;
            }
          }
        },
        {
          key: 'mdate',
          title: '수정일',
          width: 120,
          filter: 'dateValid'
        },
        {
          key: 'expire_date',
          title: '유효일자',
          width: 120,
          filter: 'isNullHyphen'
        },
        {
          key: 'ebay_shop_mas_no',
          title: '쇼핑몰 마스터코드',
          width: 140,
          notCompile: true,
          tooltip: 'ESM마스터상품에 노출되는 마스터상품번호 입니다.',
          template: function(row) {
            return ['A001', 'A006'].includes(row.shop_cd) && !row.ebay_shop_mas_no?.includes('TEMP') ? row.ebay_shop_mas_no : '';
          }
        },
      ]
    };

    // 쇼핑몰상품(그룹) 그리드
    $scope.group = {};
    $scope.group.methods = {};
    $scope.group.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'sale_img1', 'sale_status'],
      alignRightColumns: ['sale_price', 'cost_price', 'supply_price', 'street_price'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'sale_img1', 'sol_cate_name_first', 'shop_cd', 'shop_sale_name', 'sale_status', 'shop_sale_no', 'opt_type', 'cost_price', 'supply_price', 'street_price', 'addcontent_name', 'sale_etime', 'ebay_shop_mas_no'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['cost_price', 'supply_price', 'street_price', 'model_no', 'ebay_shop_mas_no'],
      groupOptions: {
        groupCountKey: 'groupCnt',
        groupExceptionKey: 'groupRowYn',
        groupDataKey: 'std_ol_group_no',
        groupRowHeaderTemplate: row => {
          row.check = false;
          if (row.std_ol_group_cd) {
            return `
            <div style="display: table; width: 100%;">
              <div class="ui-grid-selection-row-header-buttons ui-grid-icon-ok clickable ng-pristine ng-valid ng-not-empty ng-touched" ng-class="{'ui-grid-row-selected': row.entity.check}" tabindex="0" ng-click="grid.appScope.groupSelectClick(row)"  role="checkbox"  style="display: table-cell; max-width: 30px; min-width: 30px; width: 30px; position: relative; padding-left: 2px;" >&nbsp;
                <span style="width: 1px; height: 30px; background-color: #E0E3E6; position: absolute; top: -5px; right: 0"></span>
              </div>
              <div style="display: table-cell; text-align: center">
                <button class="btn btn-info btn-xxs" ng-click="grid.appScope.manageGrouping('edit','${row.std_ol_group_cd}',${row.std_ol_group_no})" ng-disabled="${row.pa_shop_cd === 'A112'}">편집</button>
                <button class="btn btn-info btn-xxs" ng-click="grid.appScope.manageGrouping('delete','${row.std_ol_group_cd}',${row.std_ol_group_no})" ng-disabled="${row.pa_shop_cd === 'A112'}">삭제</button>
              </div>
            </div>
            `;
          } else {
            return '<div class="ui-grid-selection-row-header-buttons ui-grid-icon-ok clickable ng-pristine ng-valid ng-not-empty ng-touched" ng-class="{\'ui-grid-row-selected\': row.entity.check}" tabindex="0" ng-click="grid.appScope.groupSelectClick(row)"  role="checkbox" style="position: relative; min-width: 30px; max-width: 30px; width: 30px; display: block; padding-left: 2px;">&nbsp;<span style="width: 1px; height: 30px; background-color: #E0E3E6; position: absolute; top: -5px; right: 0"></span></div>';
          }
        },
        groupRowTemplate: row => {
          if (row.std_ol_group_no) {
            return `
              <div class="ml-5 mr-5" style="line-height: 22px; display:inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: middle;">
                <span style="color: #aaa; display:inline-block; width: 65px;">쇼핑몰(계정):</span>
                <span style="font-weight: bold; display: inline-block; max-width: calc(100% - 35px);" uib-tooltip="${row.shop_name}(${row.shop_id})" tooltip-append-to-body="true" tooltip-placement="right">${row.shop_name}(${row.shop_id})</span>
              </div>
              <div style="display:inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: middle;">
                <span style="color: #aaa; display:inline-block; width: 45px;">그룹코드:</span>
                <span style="font-weight: bold; display: inline-block; max-width: calc(100% - 35px);" uib-tooltip="${row.std_ol_group_cd}" tooltip-append-to-body="true" tooltip-placement="right">${row.std_ol_group_cd}</span>
              </div>
              <div style="display:inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: middle;">
                <span style="color: #aaa; display:inline-block; width: 35px;">그룹명:</span>
                <span style="font-weight: bold; display: inline-block; max-width: calc(100% - 35px);" uib-tooltip="${row.std_ol_group_name}" tooltip-append-to-body="true" tooltip-placement="right">${row.std_ol_group_name}</span>
              </div>
            `;
          } else {
            return `
              <div class="ml-5 mr-5" style="line-height: 22px; display:inline-block; width: 200px;"><span style="font-weight: bold;">미그룹</span></div>
              <div style="display:inline-block; width: 200px; overflow: hidden;"/>
            `;
          }
        }
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/online/list`,
        requestWillAction: (data) => {
          // 메모관리에서 넘어오는경우
          if ($rootScope.memo_etc_search.page === 'online' && $rootScope.memo_etc_search.search) {
            otherPageCalled('memo_etc', false);
          }
          // 작업관리에서 넘어오는경우
          if ($rootScope.work_search.page === 'online' && $rootScope.work_search.search) {
            otherPageCalled('work', false);
          }

          // 대시보드에서 링크 타고 들어왔을 때
          if ($rootScope.side_search.page === 'online' && $rootScope.side_search.search) {
            otherPageCalled('dashboard', false);
          }

          // 주문관리에서 넘어오는 경우
          if ($rootScope.order_search.page === 'online' && $rootScope.order_search.search) {
            otherPageCalled('ord', false);
          }

          data = angular.merge({}, data, $scope.searchForm);

          // 검색 초기화용 정렬정보 백업
          if (init_order == true) {
            init_order = false;
          }

          data.onlyNormal = true;

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: (result) => {
          ol_list = result.results;

          let return_arr = [];

          $scope.groupProdCount = result.recordsTotal;
          $scope.searchData.totalCount = result.recordsTotal;

          // 그룹상품 기준 > 그룹상품 조회 시 totalCount 가 그룹 상품의 개수로 출력되고 있어 변경 2020-04-14 Tim
          if ($scope.searchForm.std_ol_type === 'single_group_y') {
            $scope.groupProdCount = result.std_ol_group_cnt;
          }

          angular.forEach(result.results, function (v) {
            return_arr = return_arr.concat(v);
          });

          $scope.productList = return_arr;

          return return_arr;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 135,
          template: function(row) {
            //도구
            const location = 'KR';
            let addGroup, addGroup2 = '';

            // 메모가 있는경우 primary 컬러로 변경

            addGroup2 = `<button ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs dt-select-do"
                ng-click="grid.appScope.leftTabAction(2)"
                uib-tooltip="{{'메모'|translate}}"
                tooltip-append-to-body="true"
                tooltip-placement="right">메모</button>`;

            if (
              $scope.isSingleProd &&
              !row.std_ol_group_code &&
              row.shop_cd !== 'Z000'
            ) {
              row.canGrouping = !['B719', 'B502', 'P020', 'A112', 'A092', 'A093', 'A077', 'X094'].includes(row.pa_shop_cd)
                && row.shop_sale_no
                && (!onlineProductSVC.impossibleGroup[row.pa_shop_cd] || (onlineProductSVC.impossibleGroup[row.pa_shop_cd] && onlineProductSVC.impossibleGroup[row.pa_shop_cd].indexOf(row.shop_cate2_cd) < 0))
                && row.std_ol_group_cd === null
                && row.template_no;

              addGroup = `<button class="btn btn-default btn-xxs mr-5"
                ng-class="{disabled:${!row.canGrouping}}" tooltip-enable="${!row.canGrouping}"
                ng-click="${row.canGrouping ? `grid.appScope.manageGrouping('add','','','${row.shop_sale_no}','${row.pa_shop_cd}')` : ''}" uib-tooltip="{{'그룹 기능 미지원 쇼핑몰: 11번가 신규상품, 위메프2.0, 홈플러스, 알리 익스프레스, 스마트스토어 그룹상품\n① 이미 그룹인 상품\n② 카테고리가 없거나 그룹이 지원되지 않는 카테고리 상품\n③ 쇼핑몰 상품코드 또는 템플릿이 없는 상품\n④ 선택한 상품이 추가될 그룹이 없는 상품은 그룹상품으로 추가 할 수 없습니다'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">추가</button>`;
            }

            return (
              `${addGroup}
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.onlineProductEdit('${row.shop_cd}','${row.shop_id}',${row.ol_shop_no},'${location}')" uib-tooltip="{{'상세보기'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">상세</button>
              ${addGroup2}`
            );
          }
        },
        {
          key: 'sale_img1',
          title: '이미지',
          width: 60,
          notCompile: true,
          template: function(row) {
            //이미지
            let img = '';

            if (row.sale_img_url) {
              img += row.sale_img_url || '';
              img += row.sale_img1 || '';
            } else {
              img = '/assets/images/noimage.png';
            }

            return `<img src='${img}' width='20' height='20' onerror='this.src="/assets/images/noimage.png"'>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 120,
          notCompile: true,
          template: function(row) {
            // 등록일
            const date = moment(row.wdate).format('YYYY-MM-DD HH:mm:ss');

            return date != 'Invalid date' ? date : '-';
          }
        },
        {
          key: 'sol_cate_name_first',
          title: '카테고리',
          width: 120,
          template: function(row) {
            // 실제 등록 사이트 카테고리 2018-06-15 Daniel update
            const cate = [];
            let cate_name = row.sol_cate_name_first;

            for (let i = 1; i <= 5; i++) {
              const c = row[`shop_cate${i}`];

              if (c) {
                cate.push(c);
              }
            }

            if (
              !row.shop_cate1 ||
              row.shop_cate1 == '임시' ||
              row.sol_cate_name_first == '임시'
            ) {
              cate_name =
                '<span uib-tooltip="{{\'상품상세에서 카테고리를 지정해주세요.\'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">임시</span>';
            } else if (cate.length > 0) {
              cate_name = cate.join(' > ');
            }

            return cate_name;
          }
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 150,
          template: function(row) {
            const shop_info = commonSVC.getShopIdViewText(
              systemList.data.shop_id_view_type,
              seller_nick_info,
              row.shop_cd,
              row.shop_id
            );

            return row.shop_cd === 'Z000'
              ? '마스터상품'
              : `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` +
                  `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${
                    ['A001', 'A006'].includes(row.shop_cd) && row.ebay_shop_mas_no ? (row.shop_cd === 'A001' ? 'ESMA' : 'ESMG') : (row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd)
                  }.png" style="width: 50px;">${shop_info[1]}</span>`;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 250,
          notCompile: true,
          template: function(row) {
            const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);

            // 일반상품인데 DB에 프로모션 상품명이 들어가있는 경우가 있어서 단일일때만 프로모션명 보여주도록 변경
            const sale_name =
              row.shop_promotion_name && row.std_ol_yn
                ? `${filteredShopSaleName} ${row.shop_promotion_name}`
                : filteredShopSaleName;

            return sale_name;
          }
        },
        {
          key: 'sale_status',
          title: '상품상태',
          width: 100,
          notCompile: true,
          template: function(row) {
            if (row.shop_cd === 'Z000') {
              return '';
            }

            return $filter('statusColorOl')(row.sale_status);
          }
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          tooltip: '스마트스토어 그룹상품인 경우 그룹 상품번호가 노출됩니다',
          width: 140,
          template: function(row) {
            const { pa_shop_cd, shop_cd, shop_id } = row;

            const sale_no = row.shop_sale_no || '';
            let sale_no_link = '';

            if (shop_cd === 'Z000') {
              return '';
            }

            if (pa_shop_cd == 'B378') {
              if (row.shop_sale_no_sub && row.shop_sale_no_sub.length === 10) {
                sale_no_link = `${sale_no}?vendorItemId=${row.shop_sale_no_sub}`;
              } else {
                sale_no_link = row.shop_sale_no_sub;
              }
            } else if (pa_shop_cd === 'A077' && row.std_ol_yn === 1) {
              sale_no_link = row.shop_sale_no_sub;
            } else {
              sale_no_link = sale_no;
            }

            if (
              sale_no &&
              !_.includes(onlineProductSVC.impossibeDetailView, shop_cd)
            ) {
              return `<span>${sale_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${shop_cd}','${shop_id}','${sale_no_link}')"></i></span>`;
            } else {
              return sale_no;
            }
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 140
        },
        {
          key: 'opt_type',
          title: '옵션',
          width: 100
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.sale_price, '', decimalPlaces);
          }
        },
        {
          key: 'cost_price',
          title: '원가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.cost_price, '', decimalPlaces);
          }
        },
        {
          key: 'supply_price',
          title: '공급가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.supply_price, '', decimalPlaces);
          }
        },
        {
          key: 'street_price',
          title: '시중가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.street_price, '', decimalPlaces);
          }
        },
        {
          key: 'template_name',
          title: '적용 템플릿',
          width: 120,
          template: function(row) {
            //적용템플릿
            if (row.shop_cd === 'Z000') {
              return '';
            }

            const registedTemplateInfo = row.template_info && Object.keys(row.template_info).length
              ? `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.templateDetail(${row.ol_shop_no}, '${row.template_no}','${row.shop_id}','${row.shop_cd}','${row.pa_shop_cd}','${row.shop_name}', true)" uib-tooltip="기존 템플릿은 [쇼핑몰 상품 가져오기] 또는 [상품전송]을 진행한 상품에만 노출됩니다.\n상품 가져오기 진행 시 가져온 템플릿 정보이며, 상품 전송 시 쇼핑몰에 반영된 최종 템플릿 정보입니다." tooltip-append-to-body="true" tooltip-placement="right">기존</button> `
              : '';

            const templateInfo = row.template_name
              ? `<a ng-click="grid.appScope.templateDetail(${row.ol_shop_no}, '${row.template_no}','${row.shop_id}','${row.shop_cd}','${row.pa_shop_cd}','${row.shop_name}', false)">${row.template_name}</a>`
              : '';

            return registedTemplateInfo || templateInfo
              ? `${registedTemplateInfo}${templateInfo}`
              : '<span uib-tooltip="{{\'상품상세에서 템플릿을 지정해주세요.\'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">미지정</span>';
          },
        },
        {
          key: 'addcontent_name',
          title: '적용 머리말/꼬리말',
          width: 130,
          tooltip: '머리말/꼬리말 템플릿은 상품명/상세설명 순으로 기재됩니다',
          template: function(row) {
            //적용템플릿
            if (row.shop_cd === 'Z000') {
              return '';
            }

            return `${
              row.shop_sale_name_addcontent_name ?
                `<a ng-click="grid.appScope.addcontentRule(${row.shop_sale_name_addcontent_no})">${row.shop_sale_name_addcontent_name}</a>` : '미지정'
            } / ${
              row.addcontent_name ?
                `<a ng-click="grid.appScope.addcontentRule(${row.addcontent_no})">${row.addcontent_name}</a>` : '미지정'}`;
          }
        },
        {
          key: 'sale_etime',
          title: '판매종료일',
          width: 120,
          notCompile: true,
          template: function(row) {
            //판매종료일
            const date = moment(row.sale_etime).format('YYYY-MM-DD HH:mm:ss');

            if (
              // date.charAt(0) === '0' ||
              // date.charAt(1) !== '0' ||
              // date.charAt(2) === '9' ||
              date === 'Invalid date'
            ) {
              return '-';
            } else {
              return date;
            }
          }
        },
        {
          key: 'mdate',
          title: '수정일',
          width: 120,
          notCompile: true,
          template: function(row) {
            // 수정일
            const date = moment(row.mdate).format('YYYY-MM-DD HH:mm:ss');

            return date != 'Invalid date' ? row.mdate : '-';
          }
        },
        {
          key: 'ebay_shop_mas_no',
          title: '쇼핑몰 마스터코드',
          width: 120,
          notCompile: true,
          tooltip: 'ESM마스터상품에 노출되는 마스터상품번호 입니다.',
          template: function(row) {
            return ['A001', 'A006'].includes(row.shop_cd) && !row.ebay_shop_mas_no?.includes('TEMP') ? row.ebay_shop_mas_no : '';
          }
        }
      ]
    };

    // 마스터  상품 그리드
    $scope.master = {};
    $scope.master.methods = {};
    $scope.master.options = {
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'sale_img1', 'link_product'],
      alignRightColumns: ['sale_price', 'cost_price', 'supply_price', 'street_price'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'sale_img1', 'shop_sale_name', 'opt_type', 'cost_price', 'supply_price', 'link_product', 'street_price'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: ['model_no'],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/online/list`,
        requestWillAction: function(data) {
          // 메모관리에서 넘어오는경우
          if ($rootScope.memo_etc_search.page === 'online' && $rootScope.memo_etc_search.search) {
            otherPageCalled('memo_etc', false);
          }
          // 작업관리에서 넘어오는경우
          if ($rootScope.work_search.page === 'online' && $rootScope.work_search.search) {
            otherPageCalled('work', false);
          }

          // 대시보드에서 링크 타고 들어왔을 때
          if ($rootScope.side_search.page === 'online' && $rootScope.side_search.search) {
            otherPageCalled('dashboard', false);
          }

          // 주문관리에서 넘어오는경우
          if ($rootScope.order_search.page === 'online' && $rootScope.order_search.search) {
            otherPageCalled('ord', false);
          }

          data = angular.merge({}, data, $scope.searchForm);

          // 검색 초기화용 정렬정보 백업
          if (init_order == true) {
            init_order = false;
          }

          data.onlyNormal = true;

          //엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function(result) {
          ol_list = result.results;
          let return_arr = [];

          //2018-01-26 ally 검색결과 카운트
          // 2018-05-16 Daniel 위메프2.0 추가
          // 2018-09-07 Amelia 위메프2.0 단일상품탭에 통합
          const std_ol_n = _.find(result.stdCount, { std_ol_yn: 0 });
          const std_ol_y = _.find(result.stdCount, { std_ol_yn: 1 });
          const std_ol_m = _.find(result.stdCount, { std_ol_yn: null });
          const std_ol_s = result.stdCount.find(o => o.std_ol_yn === 2);

          $scope.olCntList = {
            std_ol_n: std_ol_n ? std_ol_n.cnt : 0,
            std_ol_y: std_ol_y ? std_ol_y.cnt : 0,
            std_ol_m: std_ol_m ? std_ol_m.cnt : 0,
            std_ol_s: std_ol_s ? std_ol_s.cnt : 0
          };

          $scope.searchData.totalCount = result.recordsTotal;
          $scope.searchData.totalMasterCount = $scope.olCntList.std_ol_m;
          $scope.searchData.totalSlaveCount = $scope.searchForm.std_ol_type === 'normal' ? $scope.olCntList.std_ol_n : $scope.olCntList.std_ol_y;

          angular.forEach(result.results, function (v) {
            return_arr = return_arr.concat(v);
          });

          $scope.productList = return_arr;

          return return_arr;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 135,
          template: function(row) {
            //도구
            const location = 'KR';
            let addGroup = '';

            if (
              $scope.isSingleProd &&
              !row.std_ol_group_code &&
              row.shop_cd !== 'Z000'
            ) {
              addGroup = `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.manageGrouping('add','','','${row.shop_sale_no}','${row.pa_shop_cd}')" uib-tooltip="{{'그룹상품추가'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">추가</button>`;
            }

            return (
              `${addGroup}<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.onlineProductEdit('${row.shop_cd}','${row.shop_id}',${row.ol_shop_no},'${location}')" uib-tooltip="{{'상세보기'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">상세</button>` +
              '<button ng-class="{\'bg-orange-300\': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, \'btn-default\': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, \'btn-success\': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs dt-select-do" ng-click="grid.appScope.leftTabAction(2)" uib-tooltip="{{\'메모\'|translate}}" tooltip-append-to-body="true" tooltip-placement="right">메모</button>'
            );
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 140
        },
        {
          key: 'sale_img1',
          title: '이미지',
          width: 80,
          notCompile: true,
          template: function(row) {
            //이미지
            let img = '';

            if (row.sale_img_url) {
              img += row.sale_img_url || '';
              img += row.sale_img1 || '';
            } else {
              img = '/assets/images/noimage.png';
            }

            return `<img src='${img}' width='25' height='25' onerror='this.src="/assets/images/noimage.png"'>`;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 130,
          notCompile: true,
          template: function(row) {
            // 등록일
            const date = moment(row.wdate).format('YYYY-MM-DD HH:mm:ss');

            return date != 'Invalid date' ? date : '-';
          }
        },
        {
          key: 'ol_cate_name',
          title: '임의분류',
          width: 120
        },
        {
          key: 'sol_cate_name',
          title: '카테고리',
          width: 120,
          notCompile: true,
          template: function(row) {
            return row.sol_cate_name
              ? `${row.pa_sol_cate_name} : ${row.sol_cate_name}`
              : '';
          }
        },
        {
          key: 'shop_sale_name',
          title: '마스터 상품명',
          width: 250
        },
        {
          key: 'opt_type',
          title: '옵션',
          width: 100
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.sale_price, '', decimalPlaces);
          }
        },
        {
          key: 'cost_price',
          title: '원가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.cost_price, '', decimalPlaces);
          }
        },
        {
          key: 'supply_price',
          title: '공급가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.supply_price, '', decimalPlaces);
          }
        },
        {
          key: 'street_price',
          title: '시중가',
          width: 100,
          notCompile: true,
          template: function(row) {
            const decimalPlaces = ['P060', 'X094'].includes(row.pa_shop_cd) ? 2 : 0;

            return $filter('currency')(row.street_price, '', decimalPlaces);
          }
        },
        {
          key: 'link_product',
          title: '연동상품',
          width: 60,
          template: function(row) {
            // 원가
            return `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.searchCsaleCd('${row.c_sale_cd}')">조회</button>`;
          }
        }
      ]
    };

    if (viewModelNo) {
      const addModelnoColumn = {
        key: 'model_no',
        title: '모델번호',
        width: 100
      };
      $scope.shop.options.columns.push(addModelnoColumn);
      $scope.group.options.columns.push(addModelnoColumn);
      $scope.master.options.columns.push(addModelnoColumn);
    }

    /**
     * 조회
     */
    $scope.searchDo = function (refresh, noDelay, resizingHeight, callback) {
      $scope.all_checked = false;
      $rootScope.multi_search_cds = null;
      $scope.checkboxValue = {};

      localStorage.setItem(`ol_search_key_${userInfo.user.m_no}`, $scope.searchForm.search_key);

      if (($scope.grid.methods && Object.keys($scope.grid.methods).length) && !$rootScope.isOnlineProdLoading) {
        $scope.grid.methods.reloadData(callback, refresh, noDelay, resizingHeight);
      }
    };

    /**
     * 검색 초기화
     *
     * throttle 붙여 1초에 한번씩만 조회도록 처리 2018-10-12 rony
     */
    $scope.resetDo = _.throttle(function () {
      $scope.search_site = '';
      $scope.shop_cd = '';
      $scope.shop_id = '';
      const tmpMode = $scope.searchForm.std_ol_type;

      $scope.searchForm = angular.copy(onlineProductSVC.searchForm);
      if (!_.isUndefined(systemList.data.search_date) && systemList.data.search_date > 0) {
        $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
      }
      if ($scope.tabIndex === 3) {
        // 마스터 상품만 조회
        $scope.searchForm.prod_type = 'master';
      }
      $scope.searchData.search_key_name = '전체';     // 검색키 초기화 되어있지않아 추가 2018-03-22 rony
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.searchDetailMulti = angular.copy(searchDetailMulti);
      $scope.searchForm.std_ol_type = tmpMode;
      $scope.searchForm.search_key = localStorage.getItem(`ol_search_key_${userInfo.user.m_no}`) || 'all';

      $scope.searchDo(true, true);

      $scope.$broadcast('olListReset');
      $scope.selectedNodes = ['전체'];
    }, 1000);

    /**
     * 데이터테이블 pageLength 조절
     * @param number
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 카테고리 설정 함수
     */
    $scope.categorySet = async () => {
      // 스테이트 파라미터로 들어올시 어차피 선택못하고 테이터테이블오류도나서 에러나도 넘어가도록 수정
      let selected_prod = [];

      try {
        // 마스터 상품 탭이 아닌곳에서 카테고리 설정 할 수 있게 되어 중복 확인 처리 추가
        selected_prod = $scope.grid.methods.selectedData('all').filter((prod, idx, arr) => idx === arr.findIndex(sel => sel.sale_no === prod.sale_no));
      } catch (err) {
        selected_prod = [];
      }

      // 선택한 것이 없으면 return
      if (!selected_prod.length) {
        commonSVC.showMessage('선택된 마스터상품이 없습니다.');

        return;
      }

      const selected_sale_no = selected_prod.map(prod => prod.sale_no).join(',');

      const resolve = {
        data: { selectedData: selected_prod },
        categoryList: function () {
          return categoryModel.categorySelectList();
        },
        selectedOnlineProd: function () {
          return onlineProductModel.getSlaveLinkedMaster(selected_sale_no);
        }
      };

      try {
        const result = await commonSVC.openModal('full', resolve, 'SetCategoryCtrl', 'views/online/product/modals/set_category.html').result;

        if (result === 'success') {
          $scope.searchDo(true);
        }
      } catch (err) {
      }
    };

    /**
     * 상품 기준 변경
     */
    $scope.prodViewTypeChange = function(index, type) {
      const prodType = index === 3 ? 'master' : '';

      $scope.selectRowUniqList = [];
      $scope.tabIndex = index;
      $scope.isSingleProd = !!index;
      $scope.isSmileProd = index === 2;
      $scope.searchForm.std_ol_type = type;
      $scope.searchData.single_group = 'single_group_all';

      $scope.grid = $scope[prodType || 'shop'];
      $scope[`${prodType || 'shop'}Init`] = true;

      if (Object.keys($scope.grid.methods).length) {
        $scope.searchDo(true, true, true);
        $scope.grid.methods.setShowCountByGridPageSize();
      }

      $scope.searchForm.prod_type = prodType;
      $scope.singleViewType = 'master';
    };

    /**
     * 단일상품 기준 변경
     */
    $scope.singleViewTypeChange = function(type) {
      $scope.singleViewType = type;
      $scope.searchData.single_group = 'single_group_all';
      if (type === 'master') {
        $scope.searchForm.std_ol_type = 'single_all';
      } else {
        $scope.searchForm.std_ol_type = $scope.searchData.single_group;

        $scope.groupInit = true;
      }

      $scope.grid = type === 'group' ? $scope.group : $scope.shop;

      if (Object.keys($scope.grid.methods).length) {
        $scope.searchDo(true, true);
        $scope.grid.methods.setShowCountByGridPageSize();
      }
    };

    /***
     * 상품관리 보기 모드 변경
     * */
    $scope.changeProdViewMode = function (isSingle, mode, doSearch, tabIndex) {
      $scope.tabIndex = typeof tabIndex === 'undefined' ? $scope.tabIndex : tabIndex;
      $scope.isSingleProd = isSingle;
      $scope.searchForm.std_ol_type = mode;

      if (doSearch && !$rootScope.isOnlineProdLoading) {
        $scope.searchDo(true, true);
      }
    };

    /***
     * 그룹상품 생성&추가&수정&삭제
     * */
    $scope.manageGrouping = function (mode, group_cd, group_no, shop_sale_no, pa_shop_cd) {

      let select_prod = _.uniqBy($scope.grid.methods.getResultDatas()
        .filter(d => d.std_ol_group_no === group_no), 'ol_shop_no');

      //생성, 수정
      if (mode === 'create' || mode === 'add') {
        if (mode === 'create') {
          select_prod = $scope.grid.methods.selectedData('all');
        }

        if (mode === 'add') {
          select_prod = [_.find(ol_list, { shop_sale_no: shop_sale_no })];
        }

        const supportPlannedGroupProd = pa_shop_cd ? ['B719', 'B502', 'P020'].includes(pa_shop_cd) : select_prod.some(prod => ['B719', 'B502', 'P020'].includes(prod.pa_shop_cd)),
              noSupportGroupProd = pa_shop_cd ? ['A112', 'A093', 'A077', 'X094'].includes(pa_shop_cd) : select_prod.some(prod => ['A112', 'A093', 'A077', 'X094'].includes(prod.pa_shop_cd));

        // 지원예정
        if (supportPlannedGroupProd) {
          commonSVC.showMessage('해당 쇼핑몰의 그룹상품 기능은 지원 예정입니다.', '[ 위메프2.0, 홈플러스 ]');

          return false;
        }

        // 미지원
        if (noSupportGroupProd) {
          const targetShopCode = pa_shop_cd || select_prod.find(prod => prod.pa_shop_cd)?.pa_shop_cd;
          const noticeLink = targetShopCode === 'A112'
            ? '<a href="https://app.playauto.io/#!/notice/system/detail/2581" class="button-a button-a-background">[관련 공지사항 보기]</a>'
            : '';

          commonSVC.showMessageHtml('해당 쇼핑몰은 그룹상품 생성을 지원하지 않습니다.', `그룹상품 생성 지원 쇼핑몰: 옥션, 지마켓<br>${noticeLink}`);

          return false;
        }

        if (select_prod.length !== 1) {
          commonSVC.showMessage('', '그룹 상품 생성은 기준이 되는 한 개의 상품만 선택이 가능합니다.\n(ESM: 판매중, 판매중지 상품)');

          return false;
        }

        if (!select_prod[0] || !select_prod[0].shop_sale_no) {
          commonSVC.showMessage('', '쇼핑몰에 등록되어 쇼핑몰 상품코드가 있는 경우에만 그룹상품 작업이 가능합니다.');

          return false;
        }

        //그룹상품 지원안되는 카테고리 검수
        const filterList = _.filter(select_prod, function (prod) {
          return (!onlineProductSVC.impossibleGroup[prod.pa_shop_cd] || (onlineProductSVC.impossibleGroup[prod.pa_shop_cd] && onlineProductSVC.impossibleGroup[prod.pa_shop_cd].indexOf(prod.shop_cate2_cd) < 0)) && prod.std_ol_group_cd === null;
        });

        if (filterList.length !== select_prod.length) {
          commonSVC.showMessage('', '이미 그룹상품이거나 그룹상품 불가 카테고리의 상품이 포함되어있습니다.');

          return false;
        }
      }

      //삭제
      if (mode === 'delete') {
        commonSVC.showConfirm('그룹삭제', '선택하신 그룹을 쇼핑몰에서 삭제하는 작업이 바로 실행됩니다. 단일상품은 삭제되지 않으며 해당 그룹정보만 삭제됩니다. 삭제하시겠습니까?', function () {
          const group_info = _.map(_.filter(ol_list, { std_ol_group_no: group_no }), function (groupInfo, idx) {
            return {
              state: '', //엔진 데이터 담는 변수
              msg: '', //엔진 데이터 담는 변수
              shop_cd: groupInfo.shop_cd,
              shop_id: groupInfo.shop_id,
              group_cd: groupInfo.std_ol_group_cd,  // 신규생성이면 '' , 수정이면 원래 그룹코드
              group_no: groupInfo.std_ol_group_no,  // 신규생성이면 null, 수정이면 원래 그룹넘버
              group_name: groupInfo.std_ol_group_name, //그룹이름
              group_img: groupInfo.group_img, //그룹보기방법 (img :이미지형 , list :리스트형 )
              std_ol_group_seq: idx, // 그룹내 상품 순번
              shop_cate_cd: groupInfo.shop_cate_cd, //싱품카테고리코드
              ol_shop_no: groupInfo.ol_shop_no, //상품로그용번호
              sale_no: groupInfo.sale_no, //상품번호
              shop_sale_no: groupInfo.shop_sale_no_sub //쇼핑몰상품번호(서브)
            };
          });
          const param = {
            site_code: group_info[0].shop_cd,
            site_id: group_info[0].shop_id,
            group_list: group_info,
            std_ol_yn: true
          };

          workSVC.addWork('DeleteGroupProd', param)
            .then(function(result) {
              if (result.status == 200) {
                commonSVC.showToaster('success', '성공', '작업이 등록 되었습니다.\n상세 내용은 좌측 [작업] 탭에서 반드시 확인해주세요.');
              } else {
                throw result.data.error;
              }
            })
            .catch(function(err) {
              const errMsg = errorSVC.getError('work', err.data.error);
              commonSVC.showToaster('error', '실패', errMsg);

              return err;
            });
        });

        return false;
      }

      const resolve = {
        data: {
          mode: mode,
          group_cd: group_cd
        },
        groupProdList () {
          const type = mode;
          const value = mode === 'create' || mode === 'add' ? (['A001', 'A006'].includes(select_prod[0].pa_shop_cd) && select_prod[0].shop_sale_no_sub ? select_prod[0].shop_sale_no_sub : select_prod[0].shop_sale_no) : group_no;

          if (!value) {
            commonSVC.showMessage('실패', '그룹 생성에 필요한 정보가 없습니다.\n연동상품 수정으로 상품을 판매중으로 변경하셨다면 상품상태 동기화를 진행해 주세요.');

            return;
          }

          return onlineProductModel.listOfAvailGroup({ type: type, value: value, shop_cd: select_prod[0].shop_cd });
        }
      };

      if (mode === 'add') {
        resolve.data.selectData = select_prod;
      } else if (mode === 'create') {
        resolve.data.selectData = $scope.grid.methods.selectedData('all')[0];
      }

      // 탬플릿 정보 없으면 안내팝업 출력 2018-07-09 Gargamel (https://playautogmpproject.atlassian.net/browse/GMPKR-4201)
      // resolve.data.selectData 없으면 mode 가 edit 일때 안나와서 수정
      if (resolve.data.selectData) {
        if ((resolve.data.selectData.length > 0 && resolve.data.selectData[0].template_no == null) || (!resolve.data.selectData.length && resolve.data.selectData.template_no == null)) {
          commonSVC.showMessage('실패', '선택한 상품에 템플릿이 설정되지 않아 그룹상품 생성이(추가가) 불가합니다.\n상품 상세에서 해당 상품의 템플릿을 설정 후 그룹상품 생성(추가) 작업 진행이 가능합니다.');

          return false;
        }
      }

      const controller = 'onlineGroupProdCtrl';
      const template = 'views/online/product/modals/group_prod.html';

      const modal = commonSVC.openModal('full', resolve, controller, template, false);

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo();
        }
      });
    };

    /***
     * 그룹상품 동기화
     * */
    $scope.groupSync = function () {
      const resolve = {
        data: function () {
          return onlineProductModel.getCntOfAllSingle();
        }
      };

      commonSVC.openModal('', resolve, 'onlineGroupProdSyncCtrl', 'views/online/product/modals/group_sync.html', false);
    };

    /**
     * 작업버튼
     *
     * 1초에 한번씩만 처리되도록 쓰로틀 적용.
     * @param type
     */
    $scope.prodAction = _.throttle(async (type) => {
      const is_solProdAction = ['generalProdAdd', 'addSingleProd', 'addSmileProd'].includes(type);

      // 상품작업 권한 체크 2019-01-04 rony
      // 상품연동 해제/수정 권한설정 추가 2022-03-22 aiden

      let actType = '';

      switch (type) {
        case 'prodSend':
          actType = 'online.roles.registProd';
          break;
        case 'prodEdit':
        case 'prodStop':
        case 'prodSoldOut':
        case 'prodExtension':
          actType = 'online.roles.editProd';
          break;
        case 'prodStopCancel':
          actType = 'online.roles.restoreProd';
          break;
        case 'prodLinkageStop':
        case 'prodLinkageEdit':
          actType = 'online.roles.linkageProd';
          break;
      }
      if (actType && !commonSVC.checkPermission(actType, userInfo.permission)) {
        return false;
      }

      // 스테이트 파라미터로 들어올시 어차피 선택못하고 테이터테이블오류도나서 에러나도 넘어가도록 수정
      let select_prod = [];

      try {
        select_prod = $scope.grid.methods.selectedData('all', true);
      } catch (err) {
        select_prod = [];
      }

      // 선택한 것이 없으면 return
      if (!select_prod.length && type !== 'prodLinkageEdit') {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return;
      } else if (select_prod.length > 1 && ['addSingleProd', 'addSmileProd'].includes(type) && Array.from(new Set(select_prod.map(o => o.sol_cate_no))).length > 1) {
        commonSVC.showMessage(`${type === 'addSingleProd' ? '단일' : '물류'}상품 추가 안내`, '솔루션 카테고리가 동일한 상품만 선택할 수 있습니다.');

        return;
      }

      if (!is_solProdAction) {
        select_prod = _.filter(select_prod, function(row) { return row.shop_cd !== 'Z000'; });
      } else {
        // 상품 추가가 마스터 상품탭이 아닌곳에서도 가능하게 변경되어 중복 확인 처리 추가
        // 상품 추가 시 마스터 상품이 기준이 되어야 해서 마스터 상품의 배열로 변경
        select_prod = select_prod.filter((prod, idx) => idx === select_prod.findIndex(sel => sel.sale_no === prod.sale_no)).map(prod => ol_list.find(ol => ol.sale_no === prod.sale_no && ol.shop_cd === 'Z000'));
      }

      if (!select_prod.length && type !== 'prodLinkageEdit') {
        commonSVC.showMessage('마스터 상품은 작업 등록 불가합니다. 쇼핑몰상품을 선택하여 작업하시기 바랍니다.');

        return;
      }

      // 티몬(특가) 작업등록 제한
      if (select_prod.some((prod) => prod.pa_shop_cd === 'B851')) {
        commonSVC.showConfirmCustom({
          title: '작업등록 실패',
          text: '티몬(특가) 상품은 작업 등록이 불가합니다.',
          confirmButtonText: '작업등록 가능한 상품만 선택'
        }, (confirm) => {
          if (confirm) {
            select_prod = $scope.grid.methods.doSelectByFilter((r) => { // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
              return r.pa_shop_cd !== 'B851';
            }, true);

            if (!select_prod.length) {
              commonSVC.showToaster('error', gettextCatalog.getString('자동 선택 불가'), gettextCatalog.getString('작업등록 가능한 건이 없습니다.'));
            }
          }
        });

        return;
      }

      // 전송할 data , open할 모달 컨트롤러, 뷰
      const prodData = {
        isOpen: true,
        size: '',
        ctrl: null,
        html: null,
        data: {
          result: select_prod
        }
      };
      let isTemplate = [], iscate = [];

      //상품 상태동기화 작업 시 템플릿 체크 2020-01-03 Tim
      if (['prodSync', 'prodStopCancel'].indexOf(type) !== -1) {
        isTemplate = select_prod.filter(row => row.sale_img1 && !row.template_no && row.template_info?.registType !== 'scrap');
      }
      //상품 재판매 작업 시 카테고리 체크 2020-12-01 Tim
      if (['prodStopCancel'].indexOf(type) !== -1) {
        iscate = _.filter(select_prod, function(row) { return !row.shop_cate1 || row.shop_cate1 === '임시' || row.sol_cate_name_first === '임시'; });
      }

      // 카테고리, 템플릿 체크 리스트 확인 2020-12-01 Tim
      // 템플릿 카테고리 모두 확인 하는 경우
      // 상품 전송의 경우 없는 경우 빼고 작업해주는 처리가 있어 제외
      if (type === 'prodStopCancel') {
        const errProd = _.uniqBy(isTemplate.concat(iscate), 'ol_shop_no');

        if (errProd.length) {

          commonSVC.showMessage('상품 재판매 실패', `판매자관리코드가${errProd.map(x => ` ${x.c_sale_cd}`)} 인 상품에 카테고리 또는 탬플릿이 없어 상품 재판매 할 수 없습니다.`);

          return false;
        }
      }
      if (isTemplate.length) {
        commonSVC.showMessageHtml('상품 상태 동기화 실패',
          `선택한 상품에 템플릿이 적용되어 있지 않습니다. <br/>
          템플릿을 상품에 적용 후 상품 전송 작업을 진행해 주시기 바랍니다. <br/>
          <a href="https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=8436" target="_blank">도움말 바로가기 <i class="icon-new-tab"></i><a>`);

        // commonSVC.showMessage('상품 상태 동기화 실패', `판매자관리코드가${isTemplate.map(x => ` ${x.c_sale_cd}`)} 인 상품에 템플릿이 없어 상품 상태 동기화 할 수 없습니다.`);

        return false;
      }

      // type 별로 모달창 open
      switch (type) {
        // 상품등록
        case 'prodSend': {
          const impossible_prod = select_prod.filter(prod => !prod.ebay_shop_mas_no)
            .filter(prod =>
              ((!prod.shop_cate1 || prod.shop_cate1 === '임시' || prod.sol_cate_name_first === '임시') || (!prod.template_no && prod.template_info?.registType !== 'scrap'))
            );

          // 아래의 경우 전송 불가능한 항목
          // 1. 해당 상품이 카테고리 OR 템플릿이 적용되어 있지 않은 경우
          // 2. 묶여있는 ESM 상품이 카테고리 OR 템플릿이 적용되어 있지 않은 경우
          // 3. ESM 묶인 상품(옥션, 지마켓)을 모두 선택하지 않은 경우
          const impossible_esm_prod = select_prod.filter(prod => ['A001', 'A006'].includes(prod.pa_shop_cd) && prod.ebay_shop_mas_no)
            .filter(prod =>
              ((!prod.shop_cate1 || prod.shop_cate1 === '임시' || prod.sol_cate_name_first === '임시') || (!prod.template_no && prod.template_info?.registType !== 'scrap')) ||
              select_prod.some(ol => ((ol.shop_cd !== prod.shop_cd) && (ol.ebay_shop_mas_no === prod.ebay_shop_mas_no) && ((!ol.shop_cate1 || ol.shop_cate1 === '임시' || ol.sol_cate_name_first === '임시') || (!ol.template_no && ol.template_info?.registType !== 'scrap'))) ||
              !select_prod.some(ol => (ol.shop_cd !== prod.shop_cd) && (ol.ebay_shop_mas_no === prod.ebay_shop_mas_no))
              )
            );

          const possible_prod = select_prod.filter(prod => !prod.ebay_shop_mas_no || prod.pa_shop_cd === 'B378' && prod.ebay_shop_mas_no)
            .filter(prod =>
              (prod.shop_cate1 && prod.shop_cate1 !== '임시' && prod.sol_cate_name_first !== '임시') && (prod.template_no || prod.template_info?.registType === 'scrap')
            );

          // 아래의 경우 전송 가능 항목
          // 해당 상품이 카테고리 OR 템플릿이 적용되어 있고
          // 묶여있는 ESM 상품이 카테고리 OR 템플릿이 적용되어 있으며,
          // ESM 묶인 상품(옥션, 지마켓)을 모두 선택한 경우
          const possible_esm_prod = select_prod.filter(prod => ['A001', 'A006'].includes(prod.pa_shop_cd) && prod.ebay_shop_mas_no)
            .filter(prod =>
              (prod.shop_cate1 && prod.shop_cate1 !== '임시' && prod.sol_cate_name_first !== '임시') && (prod.template_no || prod.template_info?.registType === 'scrap') &&
              select_prod.some(ol => ((ol.shop_cd !== prod.shop_cd) && (ol.ebay_shop_mas_no === prod.ebay_shop_mas_no) && (ol.shop_cate1 && ol.shop_cate1 !== '임시' && ol.sol_cate_name_first !== '임시') && (ol.template_no || ol.template_info?.registType === 'scrap'))) &&
              select_prod.some(ol => (ol.shop_cd !== prod.shop_cd) && (ol.ebay_shop_mas_no === prod.ebay_shop_mas_no))
            );

          impossible_prod.push(...impossible_esm_prod);
          possible_prod.push(...possible_esm_prod);

          if (!possible_prod.length) {

            commonSVC.showMessageHtml('실패',
              `<div style="font-size: 15px">
                선택한 상품에 카테고리 또는 템플릿이 적용되어 있지 않습니다. <br/>
                카테고리와 템플릿을 상품에 적용 후 상품 전송 작업을 진행해 주시기 바랍니다. <br/>
                <font color="red">ESM마스터상품은 지마켓, 옥션 상품을 모두 선택해야 전송이 가능합니다.</font> <br/>
                <a href="https://www.plto.com/customer/HelpDesc/?sol=gmp&guide_no=8436" target="_blank">도움말 바로가기 <i class="icon-new-tab"></i><a>
              </div>`);

            return;
          }

          prodData.ctrl = 'OnlineProductListSendCtrl';
          prodData.html = 'views/online/product/modals/prod_send.html';
          prodData.size = 'md';
          prodData.isOpen = checkStatus(['판매대기', '수정대기'], type); // 상품전송 수정전송 합쳐짐 2018-05-24
          //prodData.html = 'views/online/product/modals/send.html';
          prodData.data.siteInfo = checkSiteCode(); // 채널 구분 필요한 작업들
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.isSmileProd = $scope.isSmileProd;   // 물류상품여부
          prodData.data.impossible_count = impossible_prod.length;
          prodData.data.result = possible_prod;
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });

          break;
        }

        // 상품부분수정
        case 'prodEdit':
          prodData.size = 'lg';
          prodData.data.mode = 'prodEdit';
          prodData.isOpen = checkStatus(['수정대기'], type);
          // prodData.ctrl = 'OnlineProductListEditCtrl';
          prodData.ctrl = 'OnlineProductListAllEditCtrl';
          prodData.html = 'views/online/product/modals/edit.html';
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.isSmileProd = $scope.isSmileProd;   // 물류상품여부
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
          break;

        // 판매중지
        case 'prodStop': {
          prodData.isOpen = checkStatus(['판매중', '종료대기', '일시품절', '수정대기'], type);
          prodData.ctrl = 'OnlineProductListCancelCtrl';
          prodData.html = 'views/online/product/modals/cancel.html';
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.isSmileProd = $scope.isSmileProd;   // 물류상품여부
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
          break;
        }

        // 일시품절
        case 'prodSoldOut': {
          prodData.isOpen = checkStatus(['판매중', '종료대기', '수정대기'], type);
          prodData.ctrl = 'OnlineProductListStopCtrl';
          prodData.html = 'views/online/product/modals/stop.html';
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.isSmileProd = $scope.isSmileProd;   // 물류상품여부
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
          break;
        }

        // 재판매
        case 'prodStopCancel':
          prodData.isOpen = checkStatus(['판매중지', '일시품절'], type);
          prodData.size = 'lg';
          prodData.ctrl = 'OnlineProductListCancelStopCtrl';
          prodData.html = 'views/online/product/modals/cancel_stop.html';
          prodData.data.siteInfo = checkSiteCode(); // 채널 구분 필요한 작업들
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.isSmileProd = $scope.isSmileProd;   // 물류상품여부
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
          break;

        // 판매기간 변경
        case 'prodExtension': {
          const imposProd = _.find(select_prod, function (prod) {
            return !_.includes(['A001', 'A006', 'A027', 'A077', 'A112', 'B700', 'B720', 'A175', 'A178', 'A179', 'B693'], prod.pa_shop_cd);
          });

          if (imposProd || $scope.isSmileProd) {
            commonSVC.showMessage('판매기간 변경 실패', '판매기간 변경은 [옥션(일반/단일), 지마켓(일반/단일), 인터파크, 스마트스토어, 11번가, 카카오 선물하기]만 가능합니다.');

            return false;
          }

          prodData.isOpen = checkStatus(['판매중', '종료대기'], type);
          // prodData.data.siteInfo = checkSiteCode(); // 채널 구분 필요한 작업들
          prodData.ctrl = 'OnlineProductListExtensionCtrl';
          prodData.html = 'views/online/product/modals/extension.html';
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
          break;
        }

        // 상품 상태 동기화
        case 'prodSync': {

          // 승인대기 상태 추가 2018-03-21 rony
          prodData.isOpen = checkStatus(['승인대기', '판매중', '수정대기', '종료대기', '일시품절', '판매중지', '반려'], type);
          // prodData.data.siteInfo = checkSiteCode(); // 채널 구분 필요한 작업들
          prodData.ctrl = 'OnlineProductListSyncCtrl';
          prodData.html = 'views/online/product/modals/sync.html';
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.isSmileProd = $scope.isSmileProd;   // 물류상품여부
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
          break;
        }

        // 연동 해제
        case 'prodLinkageEdit':
        case 'prodLinkageStop': {
          if ($scope.isSingleProd && select_prod.some(prod => prod.ebay_shop_mas_no)) {
            let matchEsmList = [];
            // ESM 마스터 상품 중 한개만 선택했을 시 리스트에 함께 올려줌
            const ebayMasterShopList = select_prod.filter(shop => shop.ebay_shop_mas_no).map(shop => shop.c_sale_cd).join('\n');

            if (ebayMasterShopList.length) {
              delete $scope.esmMasSearchForm.shop_cd;
              matchEsmList = (await onlineProductModel.onlineProductList({ ...$scope.esmMasSearchForm, multi_type: 'c_sale_cd', multi_search_word: ebayMasterShopList })).data.results;
            }
            select_prod = [
              ...select_prod.filter(shop => !shop.ebay_shop_mas_no),
              ...matchEsmList.filter(shop => select_prod.map(shop => shop.ebay_shop_mas_no).filter(Boolean).includes(shop.ebay_shop_mas_no))
            ];
          }

          prodData.isOpen = checklinkageStopStatus(type === 'prodLinkageEdit' ? '연동수정' : '연동해제');
          prodData.size = 'xg';
          prodData.ctrl = 'OnlineProductListLinkageCtrl';
          prodData.html = 'views/online/product/modals/linkage.html';
          prodData.data.linkage_type = type === 'prodLinkageEdit' ? 'edit' : 'stop';
          prodData.data.isSingleProd = $scope.isSingleProd; // 단일상품여부
          prodData.data.isSmileProd = $scope.isSmileProd;   // 물류상품여부
          prodData.data.result = _.filter(select_prod, function(row) { return row.shop_cd !== 'Z000'; });
          prodData.data.channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState' });
          break;
        }

        case 'generalProdAdd': {
          prodData.size = 'full';
          prodData.ctrl = 'GeneralProductAddCtrl';
          prodData.html = 'views/online/product/modals/general_product_add.html';

          const channelUseList = $rootScope.useChannelList({ site_action: 'SyncProdState', func: shop => !!shop.prod_use_yn });
          const slaveProdList = await onlineProductModel.getSlaveLinkedMaster(prodData.data.result.map(prod => prod.sale_no).join(','));

          const slaveProdGroup = _.groupBy(slaveProdList.data.results.filter(o => !o.std_ol_yn), 'sale_no');
          const use_engines = _.filter(channelUseList, ({ pa_shop_cd }) => pa_shop_cd !== 'B719');
          const saleNos = [];

          for (let idx = 0; idx < Object.keys(slaveProdGroup).length; idx++) {
            const saleNo = Object.keys(slaveProdGroup)[idx];
            const slaveProds = slaveProdGroup[saleNo].filter(prod => prod.shop_cd !== 'A076'); // SSG오픈마켓의 경우 상품 신규 등록 지원 안함

            if (slaveProds.length >= use_engines.length) {
              saleNos.push(saleNo);
            }
          }

          if (saleNos.length) {
            if (select_prod.length === saleNos.length) {
              commonSVC.showMessage('일반상품 추가 안내', '선택하신 상품은 모든 쇼핑몰에 온라인 상품이 등록되어 있습니다.\n일반상품 탭에서 상품의 정보를 확인해주세요.');
            } else {
              commonSVC.showConfirmCustom({ title: '일반상품 추가 안내', text: '일반상품이 이미 추가된 마스터 상품이 있습니다.\n선택한 마스터 상품에 연동되지 않은 쇼핑몰에만 일반상품 추가가 가능합니다.', confirmButtonText: '일반상품 추가 가능한 상품만 선택' }, function (Confirm) {
                if (Confirm) {
                  $scope.grid.methods.doSelectByFilter(function (r) {
                    return !saleNos.includes(r.sale_no.toString());
                  }, true);
                }
              });
            }

            return false;
          }

          prodData.resolve = {
            data: {
              result: prodData.data.result,
              isMaster: $scope.tabIndex === 3
            },
            channelUseList: function () {
              return channelUseList;
            },
            templateGroupList: function () {
              return templateModel.listGroup();
            }
          };
          break;
        }

        case 'addSmileProd':
        case 'addSingleProd': {
          const prodType = type === 'addSingleProd' ? '단일상품' : '물류상품';

          if (!prodData.data.result[0].sol_cate_no || prodData.data.result[0].sol_cate_no == 'null') {
            commonSVC.showMessage(`${prodType} 추가 안내`, '솔루션 카테고리가 설정된 상품만 추가할 수 있습니다.\n- 상품선택 > 리스트 좌측 상단 [작업] > \'카테고리 설정\' 메뉴에서 등록해 두신 카테고리를 설정해주세요.');

            return;
          }

          let channelUseList_single = $rootScope.useChannelList({ site_action: 'RegistProd', func: shop => shop.std_ol_avail_yn == 1 });
          const slaveProdList_single = await onlineProductModel.getSlaveLinkedMaster(_.map(prodData.data.result, 'sale_no').join(','));
          const slaveProdGroup_single = _.groupBy(slaveProdList_single.data.results.filter(o => {
            if (type === 'addSingleProd') {
              return o.std_ol_yn === 1;
            } else {
              return o.std_ol_yn === 2;
            }
          }), 'sale_no');
          const saleNos_single = [];

          if (type === 'addSmileProd') {
            channelUseList_single = channelUseList_single.filter(o => ['A001', 'A006'].includes(o.pa_shop_cd));
          }

          prodData.size = 'full';
          prodData.ctrl = 'addSingleProdCtrl';
          prodData.html = 'views/online/product/modals/add_single_prod.html';

          for (let idx = 0; idx < select_prod.length; idx++) {
            const saleNo = select_prod[idx].sale_no;
            const slaveProds = slaveProdGroup_single[saleNo];

            if (slaveProds && slaveProds.length >= channelUseList_single.length) {
              saleNos_single.push(saleNo);
            }
          }

          if (saleNos_single.length) {
            if (select_prod.length === saleNos_single.length) {
              commonSVC.showMessage(`${prodType} 추가 안내`, `선택하신 상품은 모든 쇼핑몰에 온라인 상품이 등록되어 있습니다.\n${prodType} 탭에서 상품의 정보를 확인해주세요.`);
            } else {
              commonSVC.showConfirmCustom({ title: `${prodType} 추가 안내`, text: `${prodType}이 이미 추가된 마스터 상품이 있습니다.\n선택한 마스터 상품에 연동되지 않은 쇼핑몰에만 ${prodType} 추가가 가능합니다.`, confirmButtonText: `${prodType} 추가 가능한 상품만 선택` }, function (Confirm) {
                if (Confirm) {
                  $scope.grid.methods.doSelectByFilter(function (r) {
                    return !saleNos_single.includes(r.sale_no);
                  }, true);
                }
              });
            }

            return false;
          }
          // data: prodData.data.result.filter(o => o.shop_cd === 'Z000').map(o => {
          //   return {
          //     sale_no: o.sale_no,
          //     ol_shop_no: o.ol_shop_no,
          //     sol_cate_no: o.sol_cate_no,
          //     c_sale_cd: o.c_sale_cd
          //   };
          // }),
          prodData.resolve = {
            data: {
              result: prodData.data.result,
              isMaster: $scope.tabIndex === 3,
              addType: type,
              warehouseList
            },
            channelUseList: function () {
              return channelUseList_single;
            },
            slaveProdList: slaveProdList_single,
            templateGroupList: function () {
              return templateModel.listGroup();
            },
            systemList: function(systemModel) {
              return systemModel.load();
            }
          };
        }
      }

      if (prodData.isOpen) {
        const resolve = { data: prodData.data };

        if (prodData.resolve) {
          _.assign(resolve, prodData.resolve);
        }

        const modal = commonSVC.openModal(prodData.size, resolve, prodData.ctrl, prodData.html, false, true, true);

        modal.result
          .then(function (re) {
            if (re === 'refresh') {
              $scope.searchDo(true);
            }
          });
      }

    }, 1000);

    /**
     * 템플릿 설정 함수
     */
    $scope.setTemplate = function () {
      let select_prod = $scope.grid.methods.selectedData('all');

      // 선택한 것이 없으면 return
      if (!select_prod.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return;
      }

      select_prod = _.filter(select_prod, function(row) {
        return row.shop_cd !== 'Z000';
      });

      if (!select_prod.length) {
        commonSVC.showMessage('마스터 상품은 템플릿 설정이 불가합니다. 쇼핑몰상품을 선택하여 작업하시기 바랍니다.');

        return;
      }

      const resolve = {
        data: {
          selectedData: select_prod,
        },
        templateGroupList: function () {
          return templateModel.listGroup();
        }
      };

      const modal = commonSVC.openModal('x3g', resolve, 'SetTemplateCtrl', 'views/online/product/modals/set_template.html');

      modal.result
        .then(function (re) {
          if (re == 'success') {
            $scope.searchDo(true);
          } });
    };

    /**
     * 추가항목 입력
     */
    $scope.setSetInfo = async () => {
      let selectedProds = $scope.grid.methods.selectedData('all');

      // 선택한 것이 없으면 return
      if (!selectedProds.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return;
      }

      selectedProds = selectedProds.filter(row => row.shop_cd !== 'Z000');

      if (!selectedProds.length) {
        commonSVC.showMessage('마스터 상품은 추가항목 설정이 불가합니다. 쇼핑몰상품을 선택하여 작업하시기 바랍니다.');

        return;
      }

      // 다른 shop_id가 섞여있는지 여부
      const diffShopIdYn = selectedProds.some(({ shop_id }) => shop_id !== selectedProds[0].shop_id);

      const resolve = {
        data: {
          shop_cd: selectedProds[0].shop_cd,
          shop_id: !diffShopIdYn ? selectedProds[0].shop_id : '',
          pa_shop_cd: selectedProds[0].pa_shop_cd,
          shop_cate_no: selectedProds[0].shop_cate_no || 0,
          seller_nick: !diffShopIdYn ? seller_nick_info[selectedProds[0].shop_cd + selectedProds[0].shop_id] : '',
          shop_data: {
            pa_shop_cd: selectedProds[0].pa_shop_cd,
            shop_cd: selectedProds[0].shop_cd,
            shop_id: selectedProds[0].shop_id,
            shop_name: selectedProds[0].shop_name,
            set_info: {}
          },
          openTemplate: 0,
          madeinList: madeinList.data.result,
          templateList: { data: { results: templateListArr } },
          data: { ...selectedProds[0], site_list: channelListArr.filter(({ shop_cd }) => shop_cd === selectedProds[0].shop_cd) },
          detailType: 'edit'
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(async re => {
        try {
          await onlineProductModel.editBatch({
            ol_product_list: JSON.stringify(selectedProds.map(prod => ({ ol_shop_no: prod.ol_shop_no }))),
            std_ol_yn: selectedProds[0].std_ol_yn,
            set_info_yn: true,
            set_info: re
          });

          commonSVC.showToaster('success', '성공', '추가항목 일괄 적용에 성공했습니다.');
          $scope.searchDo();
        } catch (err) {
          commonSVC.showToaster('error', '실패', '추가항목 일괄 적용에 실패했습니다.');
        }
      });

    };

    /**
     * 정보 일괄 수정
     */
    $scope.editProds = function () {
      const rows = $scope.grid.methods.selectedData('all');
      rows.map((v) => {
        v.update_price = [];
        v.supply_price = v.supply_price || '';
        v.cost_price = v.cost_price || '';
        v.street_price = v.street_price || '';
      });
      const ol_products = _.map(rows, function (row) {
        return _.pick(row, ['ol_shop_no', 'pa_shop_cd', 'shop_cd', 'shop_id', 'sale_no', 'sale_price', 'supply_price', 'cost_price', 'street_price', 'update_price', 'ebay_shop_mas_no']);
      });

      if (!ol_products.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      const resolve = {};

      resolve.data = {
        ol_products: ol_products,
        std_ol_yn: $scope.isSingleProd,
        is_master_prod: $scope.tabIndex === 3,
        sol_stock: $rootScope.user_profile.sol_stock
      };

      // 원산지 리스트 조회
      resolve.madeinList = function () {
        return productModel.getMadein();
      };
      resolve.warehouseList = warehouseList;

      const modal = commonSVC.openModal('', resolve, 'OnlineProductPackageEditCtrl', 'views/online/product/modals/modify_online.html');

      modal.result.then(function (re) {
        if (re == 'success') {
          $scope.searchDo(true);
        }
      });
    };
    /**
     * 상품복사
     */
    $scope.copyProds = async () => {
      const selectedOlShopList = $scope.grid.methods.selectedData('all');

      if (!selectedOlShopList.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      if (selectedOlShopList.every(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && !ol.std_ol_yn)) {
        commonSVC.showMessage('옥션, 지마켓 상품은 일반상품으로 등록할 수 없습니다.\n단일상품 탭에서 상품을 생성해 주세요.');

        return false;
      }

      const copyMsg = selectedOlShopList[0].shop_cd === 'Z000'
        ? '선택한 마스터 상품에 연동된 쇼핑몰상품도 모두 함께 복사됩니다. 상품을 복사하시겠습니까?'
        : `선택한 쇼핑몰상품과 해당 마스터 상품이 함께 복사됩니다. 상품을 복사하시겠습니까? ${selectedOlShopList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && !ol.std_ol_yn) ? '\n(일반상품 등록이 불가한 옥션, 지마켓, 위메프2.0, 홈플러스 상품 제외)' : ''}`;

      const confirm = await commonSVC.showConfirm('상품 복사', copyMsg);

      if (confirm) {
        try {
          const params = selectedOlShopList.filter(ol => !(['A001', 'A006'].includes(ol.pa_shop_cd) && !ol.std_ol_yn));

          const res = await onlineProductModel.copy(params);

          if (res.data?.results === 'success') {
            commonSVC.showToaster('success', '성공', '상품이 복사 되었습니다.');
          } else {
            commonSVC.showToaster('success', '실패', '상품 복사가 실패하였습니다');
          }
          $scope.searchDo(true);
        } catch (err) {
          const errMsg = errorSVC.getError('prod', err.data?.messages[0], err.data?.messages[0] || '상품 복사가 실패하였습니다.');

          commonSVC.showToaster('error', '실패', errMsg);

          if (errMsg === '쇼핑몰 상품 등록 DB 한도 초과') {
            commonSVC.showMessage('LITE버전 쇼핑몰 상품 DB 한도 초과', '쇼핑몰 상품은 5,000개 이하로 관리해 주세요.\n초과되는 상품은 삭제 또는 버전 업그레이드 후 이용하실 수 있습니다.\n(업그레이드 시 더 이상 무료 버전은 이용할 수 없습니다.)');
          } else if (err.data.error_code === 'e4029') {
            commonSVC.showMessage('상품 복사 실패', `이미지를 확인해 주세요.\n${err.data.error.join('\n')}`);
          }
        }
      }
    };

    /**
     * 엑셀 업로드 모달창
     * */
    $scope.openExcelModal = function (mode) {
      let controller, template;
      const resolve = {
        data: {}
      };

      switch (mode) {
        case 'add:normal':
          controller = 'OnlineProductExcelUploadCtrl';
          template = 'views/online/product/modals/excel_upload.html';
          resolve.data.mode = 'add';
          break;

        case 'add:single':
          controller = 'OnlineProductExcelUploadSingleCtrl';
          template = 'views/online/product/modals/excel_upload_single.html';
          resolve.data.mode = 'add';
          resolve.shopList = function () {
            return $rootScope.useChannelList({ func: shop => shop.std_ol_avail_yn == 1 && !['B719', 'B502', 'P020', 'A112'].includes(shop.shop_cd) });
          };
          resolve.cateLists = function() {
            return categoryModel.categorySelectList();
          };
          resolve.addClass = 'width1000';
          break;

        case 'add:smile':
          controller = 'OnlineProductExcelUploadSmileCtrl';
          template = 'views/online/product/modals/excel_upload_smile.html';
          resolve.data.mode = 'add';
          break;

        case 'add:option':
          controller = 'OnlineOptionExcelUploadCtrl';
          template = 'views/online/product/modals/excel_upload_option.html';
          resolve.data.mode = 'add';
          break;
        case 'edit:masterCd':
          controller = 'OnlineMasterCdExcelUploadCtrl';
          template = 'views/online/product/modals/excel_upload_masterCd.html';
          resolve.data.mode = 'edit';
          break;
        case 'edit':
          controller = 'OnlineProductExcelEditAllCtrl';
          template = 'views/online/product/modals/excel_edit_all.html';
          resolve.data.mode = 'edit';
          break;
      }

      commonSVC.openModal('lg', resolve, controller, template);
    };

    $scope.mail_test = function () {
      onlineProductModel.mail_test({}, function () { });
    };

    /**
     * 채널상품 삭제
     * */
    $scope.deleteProds = async function () {

      // 상품삭제 권한 확인. 2019-01-04 rony
      if (commonSVC.checkPermission('online.roles.deleteProd', userInfo.permission) === false) {
        return false;
      }

      const rows = $scope.grid.methods.selectedData();
      const { pa_shop_cds, shop_cds, shop_ids, sale_nos, ol_shop_nos } = rows.reduce((prev, curr) => {
        if (!curr.ebay_shop_mas_no) {
          prev.pa_shop_cds.push(curr.pa_shop_cd);
          prev.shop_cds.push(curr.shop_cd);
          prev.shop_ids.push(curr.shop_id);
          prev.sale_nos.push(curr.sale_no);
          prev.ol_shop_nos.push(curr.ol_shop_no);
        }

        return prev;
      }, { pa_shop_cds: [], shop_cds: [], shop_ids: [], sale_nos: [], ol_shop_nos: [] });

      if (rows.some(ol => ol.ebay_shop_mas_no)) {

        // ESM 마스터 상품 중 한개만 선택했을 시 리스트에 함께 올려줌
        const ebayMasterShopList = rows.filter(shop => shop.ebay_shop_mas_no).map(shop => shop.c_sale_cd).join('\n');
        let matchEsmList = [];

        if (ebayMasterShopList.length) {
          delete $scope.esmMasSearchForm.shop_cd;
          matchEsmList = (await onlineProductModel.onlineProductList({ ...$scope.esmMasSearchForm, multi_type: 'c_sale_cd', multi_search_word: ebayMasterShopList })).data.results;
        }

        matchEsmList = matchEsmList.filter(shop => rows.map(shop => shop.ebay_shop_mas_no).filter(Boolean).includes(shop.ebay_shop_mas_no));

        matchEsmList.forEach(prod => {
          shop_cds.push(prod.shop_cd);
          shop_ids.push(prod.shop_id);
          sale_nos.push(prod.sale_no);
          ol_shop_nos.push(prod.ol_shop_no);
        });
      }

      if (shop_cds.length === 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return;
      }

      let msg = _.includes(shop_cds, 'B579') ? '72TIME의 경우 쇼핑몰에서 판매중인 상품을 솔루션에서 삭제하면 쇼핑몰에서 상품이미지가 노출되지 않습니다. 그래도 삭제하시겠습니까?'
        : '쇼핑몰 상품 삭제 시 솔루션에서 해당 상품을 관리할 수 없습니다.<br>정말 삭제하시겠습니까?<br><font color="red">(ESM마스터상품은 한 상품만 선택 삭제할 경우 묶인 상품도 같이 삭제됩니다.)</font>';

      // 에픽카몰의 경우 상품 삭제 시 실제로 어드민에 상품 삭제 작업 실행
      let filteredData = [];
      const delWorkDatas = [];

      if (pa_shop_cds.includes('P051')) {
        msg += '\n\n※ 에픽카 상품은 삭제 시 에픽카몰에서도 삭제됩니다.';

        filteredData = rows.filter(prod => prod.pa_shop_cd === 'P051' && ['승인대기', '판매중', '수정대기', '판매중지', '반려', '일시품절', '종료대기'].includes(prod.sale_status) && prod.shop_sale_no);

        if (filteredData.length) {

          const shopGroupProds = _.groupBy(filteredData, (data) => {
            return data.shop_cd + '|' + data.shop_id;
          });

          for (const [key, value] of Object.entries(shopGroupProds)) {
            delWorkDatas.push({
              shop_cd: key.split('|')[0],
              shop_id: key.split('|')[1],
              shop_sale_nos: value.map(prod => prod.shop_sale_no),
              ol_shop_nos: value.map(prod => prod.ol_shop_no)
            });
          }
        }
      }
      commonSVC.showConfirmHtml(`선택한 <span class="text-orange-600">${$scope.selectRowUniqList.length}개</span> 상품을 삭제하시겠습니까?`, msg, function () {

        onlineProductModel.delete(shop_cds, shop_ids, sale_nos, ol_shop_nos, $scope.tabIndex, function (state) {
          if (state == 'success') {
            commonSVC.showToaster('success', '성공', '상품이 삭제 되었습니다.');

            if (filteredData.length) {
              addWorkDel(delWorkDatas);

            }
            $scope.searchDo(true);
          } else {
            commonSVC.showToaster('error', '실패', '상품 삭제가 실패하였습니다.');
          }
        });
      });
    };

    // 상품 삭제 용 작업 함수 (에픽카)
    function addWorkDel(workDatas) {
      const reCnt = {
        success: 0,
        error: 0,
        errSite: [],
        errReason: []
      };

      const promiseArr = [];

      _.each(workDatas, function(data) {
        const param = {
          site_code: data.shop_cd,
          site_id: data.shop_id,
          shop_sale_nos: data.shop_sale_nos,
          ol_shop_nos: data.ol_shop_nos,
          std_ol_yn: 0
        };

        const anHttpPromise = workSVC.addWork('DeleteProd', param)
          .then(function(result) {
            if (result.status == 200) {
              reCnt.success++;
            } else {
              reCnt.error++;
              reCnt.errSite.push(`${data.shop_name}(${data.shop_id})`);
              reCnt.errReason.push(result.data.error || '');
            }

            return result;
          })
          .catch(function(err) {
            reCnt.error++;
            reCnt.errSite.push(`${data.shop_name}(${data.shop_id})`);
            reCnt.errReason.push('');

            return err;
          });

        promiseArr.push(anHttpPromise);
      });

      $q.all(promiseArr)
        .then(async re => {
          if (!reCnt.success) {
            throw re;
          } else {
            const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
            commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
          }
        })
        .catch(function(err) {
          const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
          commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
          $timeout(function() { $scope.waitBtnDisableFlag = false; });

          return err;
        });
    }

    /**
     * 마스터 상품 삭제
     * */
    $scope.deleteMasterProds = function () {
      if (commonSVC.checkPermission('online.roles.deleteProd', userInfo.permission) === false) {
        return false;
      }

      const c_sale_cds = $scope.grid.methods.selectedData('c_sale_cd');

      if (c_sale_cds.length === 0) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return;
      }

      commonSVC.showConfirmHtml(`선택한 <span class="text-orange-600">${$scope.selectRowUniqList.length}개</span> 상품을 삭제하시겠습니까?`, '마스터상품 삭제 시 연동된 쇼핑몰상품도 모두 삭제되며, 솔루션에서 해당 상품을 관리할 수 없습니다. 정말 삭제하시겠습니까?', function () {
        onlineProductModel.deleteMaster(c_sale_cds, function (state) {
          if (state == 'success') {
            commonSVC.showToaster('success', '성공', '마스터 상품이 삭제 되었습니다.');
            $scope.searchDo(true);
          } else {
            commonSVC.showToaster('error', '실패', '마스터 상품 삭제가 실패하였습니다.');
          }
        });
      });
    };

    /**
     * 머리말/꼬리말 적용
     */
    $scope.applyAddcontent = function () {
      let select_list = $scope.grid.methods.selectedData('all');

      select_list = _.filter(select_list, function(row) {
        return row.shop_cd !== 'Z000';
      });

      // ESM 마스터 상품은 지마켓 또는 두개 다 선택해야지 적용 가능
      if ($scope.isSingleProd && select_list.some(ol => ol.ebay_shop_mas_no)) {
        let chkEsm = false;

        Object.values(_.groupBy(select_list.filter(prod => prod.ebay_shop_mas_no), 'ebay_shop_mas_no')).forEach(prodList => {
          if (prodList.some(ol => ol.pa_shop_cd === 'A001') && !prodList.some(ol => ol.pa_shop_cd === 'A006')) {
            chkEsm = true;
          }
        });

        if (chkEsm) {
          commonSVC.showMessage('ESM마스터상품 공통 사용정보는 지마켓 상품으로 수정해 주세요.');

          return false;
        }

        // ESM 마스터 상품 중 한개(지마켓)만 선택했을 시 리스트에 함께 올려줌
        const matchEsmList = $scope.grid.methods.filteredData(function (row) {
          return select_list.map(p => p.ebay_shop_mas_no).filter(Boolean).includes(row.ebay_shop_mas_no);
        }, 'filter');

        select_list = [...select_list.filter(shop => !shop.ebay_shop_mas_no), ...matchEsmList];
      }

      const resolve = {
        data: {
          type: '삭제',
          title: '해당 상품에 적용된 머리말/꼬리말을 <span class="text-orange">삭제</span>하시겠습니까?',
          result: select_list,
          shopAccountModel: shopAccountModel,
          warehouseList: warehouse_list,
          olShopParams: angular.copy($scope.pageData),
          prodCounts: $scope.olCntList
        }
      };

      const modal = commonSVC.openModal('full', resolve, 'OnlineProductAddcontentCtrl', 'views/online/product/modals/addcontent.html');

      modal.result.then(re => {
        if (re === 'refresh') {
          $scope.searchDo(true);
        }
      });
    };

    /**
     * 머리말/꼬리말 해제
     */
    $scope.releaseAddcontent = async function () {
      let selectProds = $scope.grid.methods.selectedData('all');

      if (!selectProds.length) {
        commonSVC.showMessage('삭제 실패', '선택된 상품이 없습니다.');

        return false;
      }

      selectProds = _.filter(selectProds, function(row) {
        return row.shop_cd !== 'Z000';
      });

      if (!selectProds.length) {
        commonSVC.showMessage('삭제 실패', '마스터 상품은 머리말/꼬리말 작업이 불가합니다. 쇼핑몰상품을 선택하여 작업하시기 바랍니다.');

        return false;
      }

      // ESM 마스터 상품은 지마켓 또는 두개 다 선택해야지 해제 가능
      if ($scope.isSingleProd && selectProds.some(ol => ol.ebay_shop_mas_no)) {
        let chkEsm = false;

        Object.values(_.groupBy(selectProds.filter(prod => prod.ebay_shop_mas_no), 'ebay_shop_mas_no')).forEach(prodList => {
          if (prodList.some(ol => ol.pa_shop_cd === 'A001') && !prodList.some(ol => ol.pa_shop_cd === 'A006')) {
            chkEsm = true;
          }
        });

        if (chkEsm) {
          commonSVC.showMessage('ESM마스터상품 공통 사용정보는 지마켓 상품으로 수정해 주세요.');

          return false;
        }

        // ESM 마스터 상품 중 한개(지마켓)만 선택했을 시 리스트에 함께 올려줌
        const matchEsmList = $scope.grid.methods.filteredData(function (row) {
          return selectProds.map(p => p.ebay_shop_mas_no).filter(Boolean).includes(row.ebay_shop_mas_no);
        }, 'filter');

        selectProds = [...selectProds.filter(shop => !shop.ebay_shop_mas_no), ...matchEsmList];
      }

      if (!_.find(selectProds, function (prod) {
        return prod.addcontent_no || prod.shop_sale_name_addcontent_no;
      })) {
        commonSVC.showMessage('삭제 실패', '적용된 머리말/꼬리말이 없습니다.');

        return false;
      }

      const resolve = {
        data: {
          type: '해제',
          title: '해당 상품에 적용된 머리말/꼬리말을 <span class="text-orange">해제</span>하시겠습니까?'
        }
      };

      const { editYn, target } = await commonSVC.openModal('md', resolve, 'OnlineProductAddcontentConfirmCtrl', 'views/online/product/modals/addcontent_confirm.html').result;

      const params = {
        olShopProds: _(selectProds)
          .filter(function (prod) {
            switch (target) {
              case '상세설명': return prod.addcontent_no;
              case '상품명': return prod.shop_sale_name_addcontent_no;
            }

            return prod.addcontent_no || prod.shop_sale_name_addcontent_no;
          })
          .map(function (prod) {
            return {
              olShopNo: prod.ol_shop_no,
              addcontent_no: prod.addcontent_no,
              shop_sale_name_addcontent_no: prod.shop_sale_name_addcontent_no,
              std_ol_yn: prod.std_ol_yn
            };
          })
          .value(),
        editYn: editYn,
        target: target
      };

      if (!params.olShopProds.length) {
        commonSVC.showMessage('삭제 실패', '적용된 머리말/꼬리말이 없습니다.');

        return false;
      }

      try {
        const res = await onlineProductModel.addcontentRelease(params);
        if (res.status === 200) {
          commonSVC.showToaster('success', '성공', '머리말/꼬리말 해제에 성공했습니다.');
          $scope.searchDo(true);
        } else {
          commonSVC.showToaster('error', '실패', '머리말/꼬리말 해제에 실패했습니다.');
        }
      } catch (err) {
        if (err.data.error === 'addWorkFailed') {
          commonSVC.showToaster('error', '실패', '머리말/꼬리말 해제 성공,\n상품 작업에 실패하였습니다.');
        } else {
          commonSVC.showToaster('error', '실패', '머리말/꼬리말 해제에 실패했습니다.');
        }
      }
    };

    /**
     * 판매자관리코드 일괄 수정 적용
     */
    $scope.editCSaleCd = function () {
      let select_list = $scope.grid.methods.selectedData('all');

      select_list = select_list.filter((row) => {
        return row.shop_cd !== 'Z000';
      });

      if (!select_list.length) {
        commonSVC.showMessage('선택된 상품이 없습니다.');

        return false;
      }

      const resolve = {
        data: {
          result: select_list,
          shopAccountModel: shopAccountModel,
          warehouseList: warehouse_list,
          olShopParams: angular.copy($scope.pageData),
          prodCounts: $scope.olCntList
        }
      };

      const modal = commonSVC.openModal('xxg', resolve, 'OnlineProductEditCSaleCdCtrl', 'views/online/product/modals/edit_c_sale_cd.html');

      modal.result.then(re => {
        if (re === 'refresh') {
          $scope.searchDo(true);
        }
      });
    };

    // 마스터 상품에 연동된 슬레이브 로우 모두 체크하는 함수
    $scope.selectSlaveProduct = function (c_sale_cd, checked) {
      $scope.grid.methods.changeSelectByFilter(function (row) {
        return row.c_sale_cd === c_sale_cd && row.shop_cd !== 'Z000';
      }, checked);
    };

    /**
     * 수정 모달열기
     */
    $scope.onlineProductEdit = function (shop_cd, shop_id, ol_shop_no, location) {
      const resolve = {};
      const data = {};
      if ($scope.tabIndex === 0 && ['A001', 'A006'].includes(shop_cd)) {
        return commonSVC.showMessageHtml('상품 전환 안내', `
          <div>
            <div style="margin-bottom:25px;">
              ESM PLUS 내 상품관리가 통합되어 일반(ESM 1.0)상품 지원이 종료됩니다.
            </div>
            <div style="margin-bottom:10px;">
              일반상품 탭에서 해당 상품을 삭제 하신 후 아래와 같이 작업이 가능합니다.
            </div>
            <ul>
              <li>경로: 상품 > 쇼핑몰 상품 가져오기 메뉴</li>
            </ul>
            <div style="margin-left:11%">[1.쇼핑몰 상품 가져오기] > "단일" 상품 수집</div>
            <br/>
            <a href="https://app.playauto.io/#!/notice/system/detail/3011" target='_blank'>[공지 상세 확인하기]</a>
          </div>`);
      }
      data.shop_cd = shop_cd;
      data.shop_id = shop_id;
      // data.sale_no = sale_no;
      data.ol_shop_no = ol_shop_no;
      data.country = shop_cd === 'Z000' ? location : channelListArr.find(channel => channel.shop_cd === shop_cd).ctry_cd;
      data.warehouseList = warehouse_list;

      resolve.data = data;

      resolve.prodDetail = function () {
        return onlineProductModel.detail(shop_cd, shop_id, ol_shop_no);
      };
      // 원산지 리스트 조회
      resolve.madeinList = function () {
        return productModel.getMadein();
      };
      //환경설정 셋팃값
      resolve.systemList = function () {
        return systemModel.load();
      };

      const modal = commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html', false, true, false);

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });
    };

    /**
     * 마스터상품탭 연동상품 조회
     */
    $scope.searchCsaleCd = function (c_sale_cd) {
      if ($scope.searchForm.peris_status === '연동안됨') {
        $scope.searchForm = angular.copy(onlineProductSVC.searchForm);
        $scope.searchDetail = angular.copy(searchDetail);
      }
      $scope.searchForm.search_key = 'c_sale_cd';
      $scope.searchForm.search_word = c_sale_cd;

      $scope.searchDo(true, true, false, function() {
        // 단일상품만 있는 마스터일시 단일상품 탭으로 이동
        if (!$scope.olCntList.std_ol_n && $scope.olCntList.std_ol_y) {
          $scope.prodViewTypeChange(1, 'single_all');
        } else {
          $scope.prodViewTypeChange(0, 'normal');
        }

      });
    };

    /**
     * 작업선택 모달 열리기전 상품상태 체크
     * @param ableStatus
     * @returns {boolean}
     */
    function checkStatus(ableStatus, type) {
      let isOpen = true;
      const select_status_list = type === 'prodSend' ? $scope.grid.methods.selectedData(['sale_status', 'ebay_shop_mas_no']) : $scope.grid.methods.selectedData('sale_status');

      if (type === 'prodSend') {
      // ebay_shop_mas_no를 기준으로 그룹화
        const listByMasNo = _.groupBy(select_status_list, 'ebay_shop_mas_no');

        // 각 그룹에 대해 조건 검사
        // ESM 마스터 상품: 같은 마스터코드 내 판매대기, 수정대기 상품이 하나라도 있을 시 작업 가능
        // 일반 상품: 반드시 판매대기, 수정대기 상태여야 작업 가능
        isOpen = Object.values(listByMasNo).every(list => {
          const hasNonNull = list.every(item => item.ebay_shop_mas_no !== null);
          let chk = false;

          if (hasNonNull) {
            chk = list.some(item => ['판매대기', '수정대기'].includes(item.sale_status));
          } else {
            chk = list.every(item => ['판매대기', '수정대기'].includes(item.sale_status));
          }

          return chk;
        });
      } else {
        isOpen = select_status_list.every(list => ableStatus.includes(list));
      }

      if (!isOpen) {
        commonSVC.showMessage('', `[${ableStatus}] 상태의 상품만 작업이 가능합니다.`);
      }

      return isOpen;
    }

    /**
     * 연동해제 모달 열리기전 상품상태 체크
     * 모달에 다른 설명이 들어가야해서 따로 뻄
     */
    function checklinkageStopStatus() {
      const isOpen = true;
      // var select_status_list = $scope.grid.methods.selectedData('sale_status');

      // 2018-06-22 온라인상품 연동수정 조건 판매대기건도 가능하게 변경  alvin
      // if(select_status_list.indexOf('판매대기') > -1){
      //   isOpen = false;
      //   commonSVC.showMessage('[판매대기]상품은 '+txt+' 불가능합니다','*판매대기 : 쇼핑몰에 상품을 전송(연동)하기 전 상태');
      // }
      return isOpen;
    }

    /**
     * 선택한 상품들의 채널 그룹핑 ( return값이 '' 이면 섞여있는것 )
     * @returns {{code: null, id: null}}
     */
    function checkSiteCode() {
      const siteInfo = { code: null, id: null };
      const select_prod = $scope.grid.methods.selectedData('all');

      angular.forEach(select_prod, function (value, index) {
        if (index === 0) {
          siteInfo.code = value.shop_cd;
          siteInfo.id = value.shop_id;
        } else {
          // 선택한 상품중 사이트코드나 사이트아이디가 다른 게 껴있으면 '' 값
          if (siteInfo.code !== value.shop_cd || siteInfo.id !== value.shop_id) {
            siteInfo.code = '', siteInfo.id = '';

            return;
          }
        }
      });

      return siteInfo;
    }

    /**
     * 엑셀 다운 모달창
     * */
    $scope.excelDown = function (type) {
      // 엑셀 다운로드 권한 체크 2022-03-21 Aiden
      if (
        ((type === 'master' || type === 'shop' || type === 'option') && commonSVC.checkPermission('online.roles.excelProd', userInfo.permission) === false)
      ) {
        return false;
      }
      const resolve = {};

      resolve.data = {
        type: type,
        tabIndex: $scope.tabIndex,
        excelFieldList: [],
        description: '다운로드 받으신 엑셀은 수정 후 [엑셀 일괄 수정]에 업로드하시면 솔루션에 수정 반영됩니다.',
        title: type === 'master' ? '마스터상품' : '쇼핑몰상품',
        url: '/app/product/online/excelDown',
        searchForm: $scope.pageData,
        page: 'online',
        progress: true
      };

      if (type === 'option') {
        resolve.data.title = '쇼핑몰상품 옵션';
        resolve.data.url = '/app/product/online/opt-excel-down';
        resolve.data.description = '다운로드 받으신 엑셀은 수정 후 [옵션전용 엑셀 일괄 수정]에 업로드하시면 솔루션에 수정 반영됩니다.';
      }

      let selectList = $scope.grid.methods.selectedData('all');

      if (selectList.length) {
        const newArray = [];

        if (type === 'master') {
          selectList = _.filter(selectList, function(row) {
            return row.shop_cd === 'Z000';
          });
        } else {
          selectList = _.filter(selectList, function(row) {
            return row.shop_cd !== 'Z000';
          });
        }

        if (selectList.length == 0) {
          commonSVC.showMessage(`선택된 ${type === 'master' ? '마스터' : '쇼핑몰'} 상품이 없습니다.`);

          return false;
        }

        _.forEach(selectList, function (item) {
          newArray.push(_.pick(item, ['shop_cd', 'shop_id', 'sale_no', 'ol_shop_no', 'std_ol_yn', 'pa_shop_cd']));
        });
        resolve.data.select_list = newArray;
        resolve.data.count = selectList.length;
        resolve.data.isAll = false;
      } else {
        if ($scope.searchData.totalCount == 0) {
          commonSVC.showMessage(`다운로드 할 ${type === 'master' ? '마스터' : '쇼핑몰'} 상품이 없습니다.`);

          return false;
        }
        // resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
        resolve.data.type = type;
      }

      commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');

    };

    /**
     * 2018-01-14 chris
     * 상태 강제변경
     * */
    function userChangeStatus(status) {
      commonSVC.showConfirm('상태 강제변경', `${status}(으)로 상태를 변경하시겠습니까?`, function (re) {
        if (re) {
          const ol_shop_no = $scope.grid.methods.selectedData('ol_shop_no');

          onlineProductModel.setState({ status: status, ol_shop_no: ol_shop_no })
            .then(function () {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('상태변경에 성공하였습니다'));
              $scope.searchDo(false);
            })
            .catch(function () {
              commonSVC.showToaster('error', '실패', '상태변경에 실패하였습니다');
            });
        }
      });
    }

    // $scope.lastClicked = null;
    $scope.buttonClick = function ($event) {
      // $scope.lastClicked = node;
      $event.stopPropagation();
    };

    /**
     * 템플릿 상세 열기
     *
     * @param {number} ol_shop_no 온라인상품 고유번호
     * @param {number | null} template_no 템플릿 번호
     * @param {string} shop_id 쇼핑몰아이디
     * @param {string} shop_cd 사이트코드
     * @param {string} pa_shop_cd 부모사이트코드
     * @param {string} shop_name 사이트명
     * @param {boolean} isExistingTemplate 기존템플릿 여부
     */
    $scope.templateDetail = function (ol_shop_no, template_no, shop_id, shop_cd, pa_shop_cd, shop_name, isExistingTemplate = false) {
      const resolve = {
        data: {
          template_no,
          shop_cd,
          shop_id,
          pa_shop_cd,
          shop_name
        }
      };

      // 기존템플릿 열기인경우 기존 템플릿 등록정보 넘겨줌
      if (isExistingTemplate) {
        resolve.data.registed_template_info = ol_list.find(o => o.ol_shop_no === ol_shop_no).template_info;
        resolve.data.ol_shop_no = ol_shop_no;
      }

      const modal = commonSVC.openModal('full', resolve, 'setFormTemplateCtrl', 'views/online/setForm/setform_detail.html');

      if (modal !== '' && template_no) {
        modal.result.then(function (param) {
          if (param === 'success' || param.result === 'success') {
            $scope.searchDo(false, false);
          } else {
            commonSVC.showToaster('error', '실패', '처리 실패');
          }
        });
      }
    };

    /**
     * 템플랏 상세
     */
    $scope.addcontentRule = function (addcontentNo) {
      const size = 'lg-md',
            ctrl = 'OnlineProductAddcontentRuleCtrl',
            html = 'views/online/product/modals/addcontent_rule.html',
            resolve = {
              data: {
                addcontentNo: addcontentNo
              }
            };

      const modal = commonSVC.openModal(size, resolve, ctrl, html, false);

      modal.result.then(function () {
        $scope.searchDo(true);
      });
    };

    // 2017-06-15 chris 테이블 컬럼 사이즈 확인
    $scope.$on('$stateChangeSuccessForJqueryGlobal', function (evt, originEvt, toState) {
      if (toState.name == 'main.online_product_list') {
        const $wrapper = $('#online_prodlist_grid_wrapper');
        const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

        if ($tableHeader.width() < 1) {
          $timeout(function () {
            $scope.grid.methods.calcLayout('set_width');
          }, 0);
        }
      }
    });

    //테이블컬럼 보이기 설정
    $scope.$on('bundleRowChange', function (event, data) {
      $("td[class*='bundleColspan']").attr('colspan', data);
    });

    // 2018-01-14 chris 상품 등록 & 수정 성공시 리스트 새로고침 추가
    $scope.$on('reloadOlProductList', function () {
      $scope.searchDo(true);
    });

    // 2018-04-06 Daniel
    // 재고관리제외버전 추가
    // stockExceptionSVC.scopeExcept(_.last($state.current.url.split('/')), $scope);

    $scope.$on('$viewContentLoaded', function () {
      // 머리말/꼬리말 바로오픈 url
      if ($stateParams.modal === 'addcontent') {
        $timeout(function() {
          $scope.applyAddcontent();
        });
      }
    });

    $scope.$on('$stateChangeSuccessForJqueryGlobal', function (evt, originEvt, toState) {
      if (toState.name == 'main.settings_shop_account') {
        window.dispatchEvent(new Event('resize'));
      }
    });

    /**
     * 작업관리 멀티검색 클릭시
     * 2019-02-18 rony
     */
    $scope.$on('multiSearchWork', function() {
      otherPageCalled('work', true);

    });

    /**
     * 사이드바 집계 클릭시
     */
    $scope.$on('sidebarSearch_online_product', function() {
      otherPageCalled('dashboard', true);
    });

    /**
     * 주문리스트 쇼핑몰상품코드 클릭시
     */
    $scope.$on('ordListSearch', () => {
      otherPageCalled('ord', true);
    });

    /**
     * 다른 페이지에서 멀티검색으로 넘어왔을떄 셋팅.
     * 2019-02-22 rony
     * @param {호출된 페이지 (type: staging)} page
     * @param {이미 페이지가 열려있는 상태애서 호출되는지의 여부 (type: boolen)} aleady_open
     */
    function otherPageCalled(page, aleady_open) {
      // 작업관리에서 호출
      if (page === 'work') {
        $scope.searchForm.search_word = '';
        $scope.searchForm.multi_type = 'c_sale_cd';
        $scope.searchForm.multi_search_word = $rootScope.work_search.multi_search_cds.join('\n');

        // watchCollection 으로 갱신되기 때문에 select 값을 새로 채워주어야 한다. 2019-06-25 rony
        $scope.searchDetail[2].item_list = _.unionBy(channelListArr, 'shop_cd');

        $scope.searchForm.shop_cd = $rootScope.work_search.select_shop_cd || '';
        $scope.searchDetail[2].select_value = $scope.searchForm.shop_cd;
        $scope.searchForm.shop_id = $rootScope.work_search.select_shop_id || '';
        $scope.searchDetail[3].select_value = $scope.searchForm.shop_id;
        $scope.detailSearchFlag = true;
        $rootScope.work_search.search = false;

        if ($rootScope.work_search.mode.trim() === '단일') {
          $scope.changeProdViewMode(true, 'single_all', false, 1);
        } else {
          $scope.changeProdViewMode(false, 'normal', false, 0);
        }
      }
      // 대시보드 호출
      else if (page === 'dashboard') {
        $scope.selectedNodes = [$rootScope.side_search.sale_status];
        $scope.searchForm.sale_status = $scope.selectedNodes;
        // $scope.searchDetail[6].select_value = $rootScope.side_search.sale_status || '';
        $rootScope.side_search.search = false;
      }
      // 메모관리 호출
      else if (page === 'memo_etc') {
        const { date_type, key: search_key, value: search_word, sdate, edate } = $rootScope.memo_etc_search;

        $scope.searchForm = {
          ...$scope.searchForm,
          date_type,
          search_key,
          search_word,
          sdate,
          edate,
          search_type: 'exact'
        };
        $rootScope.memo_etc_search.search = false;

        // 마스터면 마스터 탭으로, 일반이면 일반탭으로
        if ($rootScope.memo_etc_search.is_master) {
          $scope.prodViewTypeChange(3, 'normal');
        } else if ($rootScope.memo_etc_search.is_single) {
          $scope.prodViewTypeChange(1, 'single_all');
        } else {
          $scope.prodViewTypeChange(0, 'normal');
        }
      }
      // 주문관리 호출
      else if (page === 'ord') {
        const { search_key, search_word } = $rootScope.order_search;

        $scope.searchForm = {
          ...$scope.searchForm,
          search_key,
          search_word,
          date_type: 'wdate',
          sdate: '2001-01-01',
          edate: moment().format('YYYY-MM-DD'),
          search_type: 'exact'
        };

        $rootScope.order_search.search = false;
      }
      // 엑셀작업에서 호출
      else if (page === 'excel_work') {
        const { date_type, key: search_key, value: search_word, sdate, edate } = $rootScope.excel_work_search;

        $scope.searchForm = {
          ...$scope.searchForm,
          date_type,
          search_key,
          search_word,
          sdate,
          edate,
          search_type: 'exact'
        };
        $rootScope.excel_work_search.search = false;

        // 일반이면 일반탭으로 단일이면 단일탭으로
        if ($rootScope.excel_work_search.is_single) {
          $scope.prodViewTypeChange(1, 'single_all');
        } else {
          $scope.prodViewTypeChange(0, 'normal');
        }
      }

      if (aleady_open) {
        $scope.searchDo(true, true);
      }
    }

    // 마스터상품 임의분류 설정
    $scope.applyOnlineCategory = function() {
      const selectedMasterProds = _.uniqBy($scope.grid.methods.selectedData(), 'c_sale_cd');

      if (!selectedMasterProds.length) {
        commonSVC.showMessage('실패', '선택된 마스터상품이 없습니다.');

        return;
      }

      const resolve = {
        olCateList: function() {
          return onlineProductModel.olCateList();
        },
        data: {
          masterProds: selectedMasterProds
        }
      };

      const modal = commonSVC.openModal('xg', resolve, 'OnlineCategoryCtrl', 'views/online/product/modals/online_category.html');

      modal.result
        .finally(function () {
          $scope.searchDo(false);
          $scope.searchFn.leftContentsTabChange(4);
        });
    };

    // 마스터상품 임의분류 해제
    $scope.releaseOnlineCategory = async () => {
      const selectedMasterProds = _.uniqBy($scope.grid.methods.selectedData(), 'c_sale_cd');

      if (!selectedMasterProds.length) {
        commonSVC.showMessage('실패', '선택된 마스터상품이 없습니다.');

        return;
      }

      const confirm = await commonSVC.showConfirm('주의', '정말 선택하신 마스터상품의 임의분류를 해제하시겠습니까?');

      try {
        if (confirm) {
          const result = await onlineProductModel.olCateRelease({ saleNos: _.map(selectedMasterProds, 'sale_no') });

          if (result.data === 'success') {
            commonSVC.showToaster('success', '성공', '임의분류 해제에 성공했습니다.');
            $scope.searchDo(false);
          } else {
            commonSVC.showToaster('error', '실패', '임의분류 해제에 실패했습니다.');
          }
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '임의분류 해제에 실패했습니다.');
      }
    };

    // 메모 첫 변경시 메뉴 버튼 색상 변경
    $scope.setMemoBtnActive = function (key, value, off) {
      const index = _.findIndex(ol_list, (obj) => {
        return obj[key] == value;
      });

      ol_list[index].memo_yn = off ? 0 : 1;
      $scope.grid.methods.reDraw();
    };

    // 메모 전부완료시 메뉴 버튼 색상 변경
    $scope.setMemoCompleteActive = function (key, value, off) {
      const index = _.findIndex(ol_list, (obj) => {
        return obj[key] == value;
      });

      ol_list[index].memo_complete_yn = off ? 0 : 1;
      $scope.grid.methods.reDraw();
    };

    // 메모관리에서 클릭시 검색
    $scope.$on('memoEtcLink_online', function() {
      otherPageCalled('memo_etc', true);
    });

    // 엑셀작업 상세에서 클릭시 검색
    $scope.$on('excelWorkDetail_online', function() {
      otherPageCalled('excel_work', true);
    });

    // 케이요청 dataLayer 추가
    $timeout(() => {
      $('#sale_status_icon').on('mouseenter', () => {
        dataLayer.push({
          'event': 'gtm.hover',
          'gtm.elementClasses': 'icon-help fl-r'
        });
      });
    });

    $scope.groupSelectClick = (row) => {
      const idx = row.grid.rows.findIndex(r => (row.uid === r.uid));
      let nextIdx = row.grid.rows.findIndex((r, i) => i > idx && r.entity.groupRowYn);
      nextIdx = nextIdx !== -1 ? nextIdx : row.grid.rows.length;

      const target = row.grid.rows.filter((r, i) => i > idx && i < nextIdx);

      const isSelected = target.some(t => !t.isSelected);
      target.forEach(t => t.isSelected = isSelected);

      $scope.searchData.selectCount = $scope.grid.methods.selectedData('all').length;
      row.entity.check = isSelected;
    };

    const setCountList = async (res) => {
      $scope.onlineListResults = res.onlineListResults;
      $scope.onlineCateResults = {
        list: res.onlineCateResults,
        count: res.olCount
      };
      $scope.countList = {};

      // 쇼핑몰별 카운트 정리
      res.onlineStatusCount.forEach(v => {
        $scope.countList[v.sale_status] = $scope.countList[v.sale_status] >= 0 ? $scope.countList[v.sale_status] + v.cnt : v.cnt;
      });

      // 전체상품 카운트
      let total = 0;
      Object.keys($scope.countList).forEach(label => total += $scope.countList[label] || 0);
      $scope.countList['전체'] = total;
    };

    // 상품 상태로 상품 검색
    $scope.countSearch = function (sel) {
      if ($scope.selectedNodes.includes(sel)) {
        $scope.selectedNodes = $scope.selectedNodes.filter((v) => v !== sel);
      } else {
        $scope.selectedNodes.push(sel);
      }

      // 전체상품 선택
      if (sel === '전체' || !$scope.selectedNodes.length) {
        $scope.selectedNodes = ['전체'];
        $scope.searchForm.sale_status = ['판매대기', '승인대기', '반려', '판매중', '종료대기', '수정대기', '판매중지', '일시품절'];
      }
      else {
        $scope.selectedNodes = $scope.selectedNodes.filter((v) => v !== '전체');
        $scope.searchForm.sale_status = $scope.selectedNodes;
      }
      $scope.searchDo(true);
    };

    // state parameter가 있을 경우
    if ($stateParams.action && $stateParams.search_word) {
      $scope[$stateParams.action]($stateParams.search_word);
    }

    /**
     * 상품 퀵등록 모달이 열릴때 선택된 상품데이터 전달
     */
    $scope.$on('sendSelectedOlProd', () => {
      $scope.$emit('selectedOlProdResult', $scope.grid.methods.selectedData('all'));
    });
  });